import React from 'react';
import { RequiredLabel } from 'components/FormElements/InputWrapper/Components';
import SearchIconButton from 'components/IconButtons/SearchIconButton/SearchIconButton';
import TrashIconButton from 'components/IconButtons/TrashIconButton/TrashIconButton';

import './SelectedItem.scss';

interface SelectedItemProps {
    onSelect: Function;
    onSearchDataChange: Function;
    buttonLabel: string;
    dataTestId: string;
    required: boolean;
    resetSearchResult: Function;
    selectedItemLabel: string | null;
    setSelectedPerson: Function;
    setSearchPanelOpen: Function;
    isDisabled?: boolean;
    selectedItemComponent?: JSX.Element;
}

const SelectedItem = ({
    onSelect,
    onSearchDataChange,
    buttonLabel,
    dataTestId,
    required,
    resetSearchResult,
    selectedItemLabel,
    setSelectedPerson,
    setSearchPanelOpen,
    isDisabled = false,
    selectedItemComponent,
}: SelectedItemProps): JSX.Element => {
    return (
        <>
            <div className="label">
                <RequiredLabel required={required} />
                {buttonLabel}
            </div>
            {selectedItemLabel ? (
                <div className="selected-item" data-testid={`${dataTestId}-selected-item`}>
                    {selectedItemComponent || (
                        <div className="selected-item-label" data-testid="selected-item-label">
                            {selectedItemLabel}
                        </div>
                    )}
                    <TrashIconButton
                        onClick={() => {
                            onSelect(null);
                            onSearchDataChange({ name: '' });
                            resetSearchResult();
                            setSelectedPerson(null);
                        }}
                        title=""
                        hideLabel
                        dataTestId="clear-selected"
                    />
                </div>
            ) : (
                <SearchIconButton
                    title={buttonLabel}
                    dataTestId={`${dataTestId}-button`}
                    hideLabel
                    isDisabled={isDisabled}
                    required={required}
                    onClick={() => {
                        setSearchPanelOpen(true);
                    }}
                />
            )}
        </>
    );
};

export default SelectedItem;
