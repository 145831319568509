import React, { memo, useEffect, useRef, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import BackgroundMask from 'components/BackgroundMask';
import BookingGrid from 'components/BookingGrid';
import { BookingClickedArgs } from 'components/BookingGrid/Models/BookingClickedArgs';
import { BookingCreatedArgs } from 'components/BookingGrid/Models/BookingCreatedArgs';
import { BookingMovedArgs } from 'components/BookingGrid/Models/BookingMovedArgs';
import { Event } from 'components/BookingGrid/Models/Event';
import Loading from 'components/Loading';
import { InfoSliderData } from 'components/ResourceInfo/ResourceInfoSlider';
import AuthService from 'features/Authentication/Services/AuthorizeService';
import * as constants from 'features/constants';
import { Dictionary } from 'lodash';
import { DateTime } from 'luxon';
import {
    BookedResource,
    BookedResourceSummary,
    CreateBookingCommand,
    UpdateBookedResourceCommand,
} from 'services/ApiClients/Booking';
import { UnavailableTime, UpdateSingleOccurrence, UpdateWholeSeries } from 'services/ApiClients/Booking/Models';
import { Configuration } from 'services/ApiClients/Configuration';
import { ExpandedResources, GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import {
    SpecifiedCompanyAttributesQuery,
    SpecifiedCompanyAttributesResponse,
} from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { ResourcesGS, ResourceTypeInterface } from 'services/ApiClients/Resource/Models';
import LoggingService from 'services/LoggingService';
import Guid from 'utilities/guid';

import { Permissions } from '../../../Authentication/types';
import { ConfigurationSettings } from '../Redux/Reducer/configuratioReducer';

import EventsMapper from './Mappers/EventsMapper';

interface GridDataProps {
    isLoading: boolean;
    operationInProgress: boolean;
    booking: BookedResourceSummary[];
    resourceTypeSiteConfiguration: Configuration | null;
    allSiteConfigurations: ConfigurationSettings;
    allTenantConfigurations: ConfigurationSettings;
    selectedSite: GeographicStructureItem | null;
    resources: ResourcesGS[];
    advancedFilters: any;
    selectedDate: DateTime;
    availableAreas: GeographicStructureItem[];
    resourceTypes: ResourceTypeInterface[];
    unavailableTimes: Dictionary<UnavailableTime[]>;
    hourFormat?: string;
    is24HourFormat?: boolean;
    infoSliderData: InfoSliderData;
}

interface GridProps {
    bookAResource: (param: CreateBookingCommand) => void;
    updateBookedResource: (param: UpdateBookedResourceCommand) => void;
    setDate: (param: DateTime) => void;
    updateSingleOccurrence: (param: UpdateSingleOccurrence) => void;
    gridData: GridDataProps;
    isFilterPanelOpen: boolean;
    userProfileId: string;
    permissions: Permissions;
    setExpandedResources: (expandedResources: ExpandedResources[]) => void;
    expandedResources: ExpandedResources[];
    getResourceImage: (imageId: Guid) => void;
    resetResourceImage: (data: object) => object;
    getUsers: (usersIds: string[]) => void;
    getRoles: (usersIds: string[]) => void;
    getAttributes: (param: SpecifiedCompanyAttributesQuery) => void;
    resetUsers: (param: []) => void;
    resetRoles: (param: []) => void;
    resetAttributes: (param: []) => void;
    updateWholeSeries: (param: UpdateWholeSeries) => void;
    tenantCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
}

const Grid = ({
    bookAResource,
    updateBookedResource,
    setDate,
    updateSingleOccurrence,
    gridData,
    isFilterPanelOpen,
    userProfileId,
    permissions,
    setExpandedResources,
    expandedResources,
    getResourceImage,
    resetResourceImage,
    getUsers,
    getRoles,
    getAttributes,
    resetUsers,
    resetRoles,
    resetAttributes,
    updateWholeSeries,
    tenantCompanyAttributes,
}: GridProps): JSX.Element => {
    const [events, setEvents] = useState<Event[]>([]);
    const navigate = useNavigate();

    const {
        isLoading,
        operationInProgress,
        booking,
        resourceTypeSiteConfiguration,
        allSiteConfigurations,
        selectedSite,
        resources,
        advancedFilters,
        selectedDate,
        availableAreas,
        resourceTypes,
        unavailableTimes,
        is24HourFormat,
        hourFormat,
        infoSliderData,
    } = gridData;

    const timezone = (selectedSite && selectedSite.timezone) || constants.DEFAULT_TIMEZONE;
    const configuration = useRef<Configuration | undefined>(undefined);
    configuration.current = resourceTypeSiteConfiguration as Configuration;

    useEffect(() => {
        LoggingService.Debug('Desks update useEffect called', booking);
        // @todo: Error handling wasn't working: `(deskBookings as ErrorDetails)` was always returning true
        // try {
        if (!booking) {
            LoggingService.Debug('Events reset');
            setEvents([]);
            return;
        }

        LoggingService.Debug('Post bookings check');

        const bookings = booking as BookedResourceSummary[];
        const mappedBookings = EventsMapper.MapBookedResourceSummaries(bookings);
        setEvents(mappedBookings);
        // } catch (e) {
        //     LoggingService.Error('Exception while mapping events', e);
        // }
    }, [booking]);

    const handleBookingCreated = async (args: BookingCreatedArgs): Promise<void> => {
        LoggingService.Debug(
            `Time range selected: ${args.startTime} - ${args.endTime} for resource ${args.resourceId}`
        );

        // Make an api call to try and reserve the desk and create the intial booking
        const startDateTime: string = args.startTime.setZone(timezone).toUTC().toISO();
        const endDateTime: string = args.endTime.setZone(timezone).toUTC().toISO();

        const bookedResource: BookedResource = {
            resourceId: args.resourceId,
            startDateTime,
            endDateTime,
            resourceType: args.resourceType,
            title: '',
        };
        const userProfile = await AuthService.getUser();

        bookAResource({
            bookingId: Guid.newGuid(),
            resources: [bookedResource],
            startDateTime,
            endDateTime,
            createdByDisplayName: userProfile?.name ?? '',
            createdByUserId: userProfile?.userProfileId ? new Guid(userProfile.userProfileId) : Guid.Empty,
        });
    };

    const handleBookingClicked = (args: BookingClickedArgs): void => {
        LoggingService.Debug('Booking clicked', args);

        // @todo: We will need to do more logic in here to determine if the user can actually click on this booking - e.g. is it a pending booking and not assigned to the user and the user doesn't have permission to view others pending bookings?
        const pathname = args.isPartOfRepeat ? `repeat/${args.bookingId.toString()}` : `${args.bookingId.toString()}`;
        const params = args.isPartOfRepeat
            ? `?selectedDate=${selectedDate.setZone(timezone).toFormat(constants.DEFAULT_DATE_FORMAT)}`
            : '';
        navigate({ pathname, search: `${createSearchParams(params)}` }, { replace: true });
    };

    const handleBookingMoved = (args: BookingMovedArgs): void => {
        LoggingService.Debug('Booking moved', args);
        updateBookedResource(args);
    };

    return (
        <>
            <BookingGrid
                selectedDate={selectedDate}
                resources={resources}
                bookings={booking}
                events={events}
                resourceTypeSiteConfiguration={configuration.current}
                allSiteConfigurations={allSiteConfigurations}
                onBookingCreated={handleBookingCreated}
                onBookingClicked={handleBookingClicked}
                onBookingMoved={handleBookingMoved}
                updateSingleOccurrence={updateSingleOccurrence}
                timezone={timezone}
                filterPanelOpen={isFilterPanelOpen}
                advancedFilters={advancedFilters}
                availableAreas={availableAreas}
                setDate={setDate}
                resourceTypes={resourceTypes}
                unavailableTimes={unavailableTimes}
                permissions={permissions}
                userProfileId={userProfileId}
                is24HourFormat={is24HourFormat}
                hourFormat={hourFormat}
                setExpandedResources={setExpandedResources}
                expandedResources={expandedResources}
                getResourceImage={getResourceImage}
                resetResourceImage={resetResourceImage}
                infoSliderData={infoSliderData}
                getUsers={getUsers}
                getRoles={getRoles}
                getAttributes={getAttributes}
                resetUsers={resetUsers}
                resetRoles={resetRoles}
                resetAttributes={resetAttributes}
                updateWholeSeries={updateWholeSeries}
                tenantCompanyAttributes={tenantCompanyAttributes}
            />

            <BackgroundMask className={classNames({ open: operationInProgress || isLoading })} />
            {(operationInProgress || isLoading) && <Loading />}
        </>
    );
};

export default memo(Grid);
