import { connect } from 'react-redux';
import { DEFAULT_TIMEZONE } from 'features/constants';
import { formatOccurrences } from 'features/Resources/Combined/Redux/utils';
import RepeatInfo from 'features/Resources/Common/EditBooking/RepeatInfo';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import { findResourceNameByValue } from 'services/ApiClients/Resource';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => {
    const searchParams = new URLSearchParams(state.router.location?.search);
    const { occurrences } = state.combined.edit.info;
    const timezone = state.filters.global.site?.timezone || DEFAULT_TIMEZONE;
    const selectedDate = searchParams.get('selectedDate') || '';

    const resourceType = findResourceNameByValue(occurrences[0]?.resourceType);
    const items = [
        ...formatOccurrences(occurrences, timezone, selectedDate, resourceType, state.authentication.user?.hourFormat),
    ]
        .filter(
            (item) =>
                DateTime.now().setZone(timezone).startOf('day') <=
                DateTime.fromISO(item.formattedDate, { zone: timezone }).startOf('day')
        )
        .sort((a, b) => a.sortIndex - b.sortIndex);

    return {
        resourceType,
        items,
        emptyMessage: 'Repeat_NoRepeats_Message',
    };
};

export default connect(mapStateToProps)(RepeatInfo);
