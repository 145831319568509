import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { UserReportFilterOptions } from 'services/ApiClients/Reports/Models/UserReportFilterOptions';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { PersonsResponse } from '../../../services/ApiClients/Models';
import { SearchData } from '../../../utilities/searchUtils';

export const reportsSearchInternalVisitors = createAsyncAction(
    'REPORTS_SEARCH_INTERNAL_VISITORS_REQUEST',
    'REPORTS_SEARCH_INTERNAL_VISITORS_SUCCESS',
    'REPORTS_SEARCH_INTERNAL_VISITORS_FAILURE'
)<SearchData, ApiResponse<PersonsResponse>, AxiosError | any>();

export const reportsSearchExternalVisitors = createAsyncAction(
    'REPORTS_SEARCH_EXTERNAL_VISITORS_REQUEST',
    'REPORTS_SEARCH_EXTERNAL_VISITORS_SUCCESS',
    'REPORTS_SEARCH_EXTERNAL_VISITORS_FAILURE'
)<SearchData, ApiResponse<PersonsResponse>, AxiosError | any>();

export const reportsSearchCompanies = createAsyncAction(
    'REPORTS_SEARCH_COMPANIES_REQUEST',
    'REPORTS_SEARCH_COMPANIES_SUCCESS',
    'REPORTS_SEARCH_COMPANIES_FAILURE'
)<string, ApiResponse<string[]>, AxiosError | any>();

export const reportsSearchUsers = createAsyncAction(
    'REPORTS_SEARCH_USERS_REQUEST',
    'REPORTS_SEARCH_USERS_SUCCESS',
    'REPORTS_SEARCH_USERS_FAILURE'
)<SearchData, ApiResponse<PersonsResponse>, AxiosError | ApiResponse>();

export const reportsGetCompanies = createAsyncAction(
    'REPORTS_GET_COMPANIES_REQUEST',
    'REPORTS_GET_COMPANIES_SUCCESS',
    'REPORTS_GET_COMPANIES_FAILURE'
)<void, ApiResponse<string[]>, AxiosError | any>();

export const reportsGetDepartments = createAsyncAction(
    'REPORTS_GET_DEPARTMENTS_REQUEST',
    'REPORTS_GET_DEPARTMENTS_SUCCESS',
    'REPORTS_GET_DEPARTMENTS_FAILURE'
)<void, ApiResponse<{ departments: string[] } | null>, AxiosError | any>();

export const getUserReportFilterOptions = createAsyncAction(
    'REPORTS_USER_REPORT_FILTERS_REQUEST',
    'REPORTS_USER_REPORT_FILTERS_SUCCESS',
    'REPORTS_USER_REPORT_FILTERS_FAILURE'
)<void, ApiResponse<UserReportFilterOptions | null>, AxiosError | any>();

export const reportsResetSearchCompaniesResult = createAction('REPORTS_RESET_SEARCH_COMPANIES')();

export const reportsResetCompaniesResult = createAction('REPORTS_RESET_COMPANIES')();

export const reportsResetSearchUsersResult = createAction('REPORTS_RESET_SEARCH_USERS')();

export const reportsResetSearchVisitorsResult = createAction('REPORTS_RESET_SEARCH_VISITORS')();

export const generateReport = createAsyncAction(
    'GENERATE_REPORT_REQUEST',
    'GENERATE_REPORT_SUCCESS',
    'GENERATE_REPORT_FAILURE'
)<string, any, AxiosError>();

export const generateReportForExport = createAsyncAction(
    'GENERATE_REPORT_FOR_EXPORT_REQUEST',
    'GENERATE_REPORT_FOR_EXPORT_SUCCESS',
    'GENERATE_REPORT_FOR_EXPORT_FAILURE'
)<string, any, AxiosError>();

export const resetReportData = createAction('RESET_REPORT_DATA')();

export const resetReportDataForExport = createAction('RESET_REPORT_DATA_FOR_EXPORT')();

export default {
    reportsSearchInternalVisitors,
    reportsSearchExternalVisitors,
    reportsResetSearchVisitorsResult,
    reportsSearchCompanies,
    reportsSearchUsers,
    reportsGetCompanies,
    reportsResetSearchCompaniesResult,
    reportsResetSearchUsersResult,
    reportsGetDepartments,
    reportsResetCompaniesResult,
    generateReport,
    resetReportData,
    getUserReportFilterOptions,
    generateReportForExport,
    resetReportDataForExport,
};
