import React from 'react';
import { ReactComponent as AreaIcon } from 'assets/icons/AreaIcon.svg';
import { ReactComponent as AttendeesCount } from 'assets/icons/floorPlan/AttendeesCount.svg';
import classNames from 'classnames';
import { ProfileCompanyAttributes } from 'features/Authentication/types';
// eslint-disable-next-line import/no-cycle
import { ResourceInfoSliderState } from 'features/Resources/Common/InfoSlider/InfoSlider';
// eslint-disable-next-line import/no-cycle
import InfoSliderTimeline from 'features/Resources/Common/InfoSliderTimeline';
import { isEmpty } from 'lodash';
import { UserRole } from 'services/ApiClients/Identity/Models';
import { SpecifiedCompanyAttributesResponse } from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { PersonById } from 'services/ApiClients/Person/Models/PersonById';
import { ResourceType } from 'services/ApiClients/Resource';

import AboutInfo from './AboutInfo';
import FixedResources from './FixedResources';
import ResourceAttributes from './ResourceAttributes';
// eslint-disable-next-line import/no-cycle
import SummaryIcons from './SummaryIcons';
import UserRestriction from './UserRestriction';

import './ResourceInfoContent.scss';

export interface ResourceInfoContentProps {
    resource: ResourceInfoSliderState;
    title: string;
    users?: PersonById[];
    userProfileId?: string;
    roles?: UserRole[];
    cbRoles?: string[];
    companyAttributes?: SpecifiedCompanyAttributesResponse[];
    cbCompanyAttributes?: ProfileCompanyAttributes[];
    isFromSliderView?: boolean;
    isFromFloorPlanPage?: boolean;
    label?: string;
    attendeesCount?: number;
    tenantCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
}

const ResourceInfoContent = ({
    resource,
    title,
    users,
    userProfileId,
    roles,
    cbRoles,
    companyAttributes,
    cbCompanyAttributes,
    isFromSliderView,
    isFromFloorPlanPage,
    label = '',
    attendeesCount,
    tenantCompanyAttributes,
}: ResourceInfoContentProps): JSX.Element => {
    const { description, equipment, imageUrl, capacity = 0, resourceType, attributes } = { ...resource };

    return (
        <div className={classNames('resourceInfoContent', { resourceInfoContentSlider: isFromSliderView })}>
            <div className="resourceImage">
                <img src={imageUrl} alt="resource" />
            </div>
            {isFromSliderView && !!label && (
                <div className="resourceArea">
                    <div className="resourceArea-container">
                        <AreaIcon />
                        <span>{label}</span>
                    </div>
                    {resourceType === ResourceType.ROOM.value && (
                        <div className="resourceArea-container">
                            <AttendeesCount />
                            {attendeesCount && <span className="attendeesCount">{attendeesCount}</span>}
                            <span className="capacity">{`(max ${capacity})`}</span>
                        </div>
                    )}
                </div>
            )}
            {isFromFloorPlanPage && (
                <div className="resourceTimeLine">
                    <InfoSliderTimeline resource={resource} />
                </div>
            )}
            <div className="resourceDetails">
                <SummaryIcons resource={resource} />
                {equipment && equipment.length > 0 && <FixedResources fixedResources={equipment} />}
                {(!isEmpty(users) || !isEmpty(roles) || !isEmpty(companyAttributes)) && (
                    <UserRestriction
                        users={users}
                        userProfileId={userProfileId}
                        roles={roles}
                        cbRoles={cbRoles}
                        companyAttributes={companyAttributes}
                        cbCompanyAttributes={cbCompanyAttributes}
                        isFromSliderView={isFromSliderView}
                    />
                )}
                {description && (
                    <AboutInfo title={title} description={description} showBottomBorder={attributes.length > 0} />
                )}
                {attributes.length > 0 && (
                    <ResourceAttributes
                        attributes={attributes}
                        isFromSliderView={isFromSliderView}
                        tenantCompanyAttributes={tenantCompanyAttributes}
                    />
                )}
            </div>
        </div>
    );
};

export default ResourceInfoContent;
