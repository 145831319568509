import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ResourceAvailabilityResponse } from 'services/ApiClients/Booking/Models';
import { isActionOf } from 'typesafe-actions';

import { getResourcesAvailabilityAsync } from './actions';

export const getResourcesAvailabilityEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getResourcesAvailabilityAsync.request)),
        switchMap((action) => {
            return from(api.booking.getResourceAvailability(action.payload)).pipe(
                map((response) =>
                    getResourcesAvailabilityAsync.success(response.payload as ResourceAvailabilityResponse)
                ),
                catchError((error) => of(getResourcesAvailabilityAsync.failure(error)))
            );
        })
    );
