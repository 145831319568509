import { connect } from 'react-redux';
import { TWENTY_FOUR_HOUR_FORMAT } from 'features/constants';
import { RootState } from 'PortalTypes';

import { SearchData } from '../../../utilities/searchUtils';
import {
    generateReport,
    generateReportForExport,
    reportsResetSearchUsersResult,
    reportsSearchUsers,
} from '../Redux/actions';

// eslint-disable-next-line import/no-cycle,
import LoginsReport from './LoginsReport';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    isReportGenerationInProgress: state.reports.isReportGenerationInProgress,
    reportData: state.reports.reportData?.logins,
    timeFormat: state.authentication.user?.extendedHourFormat || TWENTY_FOUR_HOUR_FORMAT,
    isGenerationReportForExportInProgress: state.reports.isGenerationReportForExportInProgress,
    reportDataForExport: state.reports.reportDataForExport?.logins,
    is24HoursTimeFormat: state.authentication.user?.is_24h_timeFormat,
    isSearchUsersInProgress: state.reports.isSearchUsersInProgress,
    searchUsersResult: state.reports.searchUsersResult,
});

const mapDispatchToProps = {
    generateReport: generateReport.request,
    generateReportForExport: generateReportForExport.request,
    resetUsersSearch: () => reportsResetSearchUsersResult(),
    searchUsers: (searchUsersRequest: SearchData) => reportsSearchUsers.request(searchUsersRequest),
};

export type LoginsReportProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(LoginsReport);
