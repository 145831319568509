import React from 'react';
import { ReactComponent as Fail } from 'assets/icons/Fail.svg';
import { ReactComponent as Warning } from 'assets/icons/RepeatInfoModifiedIcon.svg';
import { ReactComponent as Tick } from 'assets/icons/Tick.svg';
import { DEFAULT_DATE_FORMAT, EXCLUSION_TYPE } from 'features/constants';
import { DateTime } from 'luxon';
import { BookedResourceSummary, ConfirmCommand } from 'services/ApiClients/Booking';
import { Occurrence } from 'services/ApiClients/Booking/Models';

export enum SubstringUnitSlotError {
    START = 7,
    END = 5,
}

export const formatConfirmationRequest = (payload: any, bookings: BookedResourceSummary[]): ConfirmCommand => {
    const { bookingId } = payload;
    const { attendeesCount = null, bookingDisplayName = '' } = bookings.find(
        (booking: BookedResourceSummary) => booking.bookingId === bookingId
    ) as BookedResourceSummary;

    return {
        bookingData: {
            bookingId,
            displayName: bookingDisplayName,
            attendeesCount,
        },
    };
};

export interface RepeatData {
    icon: React.ReactNode;
    date: string;
    time: string | null;
    title?: string;
    resource?: string;
    sortIndex: number;
    formattedDate: string;
    isSelected?: boolean;
    occurrenceStatus: string;
}

const getOccurrenceInfo = (exclusionType: number | null): { icon: React.ReactNode; occurrenceStatus: string } => {
    switch (exclusionType) {
        case EXCLUSION_TYPE.EXCLUSION:
            return {
                icon: <Warning />,
                occurrenceStatus: 'Repeat_Occurrence_Status_Orange',
            };
        case EXCLUSION_TYPE.NOT_AVAILABLE:
            return {
                icon: <Fail />,
                occurrenceStatus: 'Repeat_Occurrence_Status_Red',
            };
        default:
            return {
                icon: <Tick />,
                occurrenceStatus: 'Repeat_Occurrence_Status_Green',
            };
    }
};

export const formatOccurrences = (
    occurrences: Occurrence[],
    timezone: string,
    selectedDate: string,
    resourceType: string,
    hourFormat = ''
): RepeatData[] =>
    occurrences
        .filter(({ exclusionType }) => EXCLUSION_TYPE.CANCELLED !== exclusionType)
        .map(({ startDateTime, endDateTime, title, exclusionType, displayName }) => {
            return {
                ...getOccurrenceInfo(exclusionType),
                date: DateTime.fromISO(startDateTime, { zone: timezone }).toFormat('dd MMM yyyy'),
                time: `${DateTime.fromISO(startDateTime, { zone: timezone }).toFormat(hourFormat)}-${DateTime.fromISO(
                    endDateTime,
                    { zone: timezone }
                ).toFormat(hourFormat)}`,
                title,
                [resourceType]: displayName,
                sortIndex: DateTime.fromISO(startDateTime).toMillis(),
                formattedDate: DateTime.fromISO(startDateTime, { zone: timezone }).toFormat(DEFAULT_DATE_FORMAT),
                isSelected: DateTime.fromISO(startDateTime)
                    .setZone(timezone)
                    .hasSame(DateTime.fromISO(selectedDate), 'day'),
            };
        });
