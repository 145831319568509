import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BackArrow } from 'assets/icons/siteSelector/backSelectorArrow.svg';
import classNames from 'classnames';
import { GEOGRAPHIC_STRUCTURE_TYPES } from 'features/constants';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';

import './SiteSelector.scss';

export interface SiteSelectorProps {
    tenantId: any;
    geographicStructures: GeographicStructureItem[];
    selectedGeographicStructure: GeographicStructureItem | null;
    selectedSiteGroup: GeographicStructureItem | null;
    onGeographicStructureClick: (e: any) => void;
    onBreadcrumbClick: (e: any) => void;
    isFromVisitorBookingPage?: boolean;
    noOfChildrenVisitorBooking?: { [key: string]: number };
}

const SiteSelector = ({
    tenantId,
    geographicStructures,
    selectedGeographicStructure,
    selectedSiteGroup,
    onGeographicStructureClick,
    onBreadcrumbClick,
    isFromVisitorBookingPage,
    noOfChildrenVisitorBooking,
}: SiteSelectorProps): JSX.Element => {
    const { t } = useTranslation();
    const sortedStructures = geographicStructures.sort((a: GeographicStructureItem, b: GeographicStructureItem) =>
        a.name.localeCompare(b.name)
    );

    const isTopLevelGeographicStructure = geographicStructures[0]?.parentId === tenantId;

    return (
        <div className="siteSelector">
            {!isTopLevelGeographicStructure && (
                <div className="breadcrumb">
                    <button type="button" className="backToTop" onClick={onBreadcrumbClick}>
                        <BackArrow /> {t('SiteSelector_BackToTop')}
                    </button>
                    <span>{'>'}</span>
                    <p className="currentStructure">{selectedSiteGroup?.name}</p>
                </div>
            )}
            <div className="selectorWrap">
                <ul>
                    {sortedStructures.map((structure: any) => {
                        const noOfChildrenVB = noOfChildrenVisitorBooking || {};
                        const groupId = Object.keys(noOfChildrenVB).find((id) => id === structure.id);

                        let visitorBookingStructure = {
                            ...structure,
                            noOfChildren: structure.type === GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP ? 0 : 1,
                        };

                        if (isFromVisitorBookingPage && visitorBookingStructure.id === groupId) {
                            visitorBookingStructure = {
                                ...visitorBookingStructure,
                                noOfChildren:
                                    structure.type === GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP && groupId
                                        ? noOfChildrenVB[groupId]
                                        : visitorBookingStructure.noOfChildren,
                            };
                        }
                        const classes = classNames({
                            structureHasChildren:
                                structure.type !== GEOGRAPHIC_STRUCTURE_TYPES.SITE && structure.noOfChildren > 0,
                            disabled:
                                structure.type === GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP &&
                                structure.noOfChildren === 0,

                            selected: structure.id === selectedGeographicStructure?.id,
                        });

                        const classesVB = classNames({
                            structureHasChildren:
                                visitorBookingStructure.type !== GEOGRAPHIC_STRUCTURE_TYPES.SITE &&
                                visitorBookingStructure.noOfChildren > 0,

                            selected: visitorBookingStructure.id === selectedGeographicStructure?.id,
                        });

                        return (
                            <React.Fragment key={structure.id || visitorBookingStructure.id}>
                                {isFromVisitorBookingPage && visitorBookingStructure.noOfChildren > 0 && (
                                    <li>
                                        <button
                                            className={classesVB}
                                            type="button"
                                            value={visitorBookingStructure.id}
                                            name={visitorBookingStructure.type}
                                            onClick={onGeographicStructureClick}
                                            disabled={
                                                visitorBookingStructure.type ===
                                                    GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP &&
                                                visitorBookingStructure.noOfChildren === 0
                                            }
                                        >
                                            {visitorBookingStructure.name}
                                            {visitorBookingStructure.type !== GEOGRAPHIC_STRUCTURE_TYPES.SITE && (
                                                <span>({visitorBookingStructure.noOfChildren})</span>
                                            )}
                                        </button>
                                    </li>
                                )}

                                {!isFromVisitorBookingPage && (
                                    <li>
                                        <button
                                            className={classes}
                                            type="button"
                                            value={structure.id}
                                            name={structure.type}
                                            onClick={onGeographicStructureClick}
                                            disabled={
                                                structure.type === GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP &&
                                                structure.noOfChildren === 0
                                            }
                                        >
                                            {structure.name}
                                            {structure.type !== GEOGRAPHIC_STRUCTURE_TYPES.SITE &&
                                            structure.noOfChildren > 0 ? (
                                                <span>({structure.noOfChildren})</span>
                                            ) : (
                                                ''
                                            )}
                                        </button>
                                    </li>
                                )}
                            </React.Fragment>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default SiteSelector;
