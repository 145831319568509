import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SidePanelBackArrowIcon } from 'assets/icons/sidePanel/SidePanelBackArrow.svg';
import { Person } from 'services/ApiClients/Models';
import { v4 as uuidv4 } from 'uuid';

import { CountryCodes } from '../../constants/CountryCodes';
import { UkCountryCode } from '../../features/constants';
import Button from '../FormElements/Button';
import { MobileInput, Select, TextBox } from '../Forms';
import WithLoading from '../HOC/WithLoading';
import SlidingPanel from '../SlidingPanel';

import './VisitorPanel.scss';

interface VisitorPanelProps {
    onSave?: ((data: any) => void) | null;
    setPanelOpen: Function;
    isOpen: boolean;
    creationOrUpdatingInProgress?: boolean;
    person?: Person;
    isEditMode?: boolean;
    showBackgroundMask?: boolean;
}

export const VisitorPanel = ({
    onSave = null,
    setPanelOpen,
    isOpen,
    creationOrUpdatingInProgress = false,
    person,
    isEditMode = false,
    showBackgroundMask = false,
}: VisitorPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const { register, control, handleSubmit, errors, reset, setValue, clearErrors } = useForm({
        defaultValues: person,
    });

    const [formKey, setFormKey] = useState<string>(uuidv4());

    useEffect(() => {
        setFormKey(uuidv4());
    }, [isOpen]);

    useEffect(() => {
        reset(person);
    }, [person]);

    const customCornerButton = (
        <Button
            className="angle-button"
            type="button"
            iconOnly
            icon={SidePanelBackArrowIcon}
            onClick={() => {
                setPanelOpen(false);
            }}
            dataTestId="visitor-booking-back-button"
        />
    );

    const titles = [
        { label: '-', value: '' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Mr', value: 'Mr' },
        { label: 'Mrs', value: 'Mrs' },
        { label: 'Ms', value: 'Ms' },
    ];

    return (
        <div className="visitor-panel">
            <SlidingPanel
                title={isEditMode ? t('PersonDetails_VisitorDetails') : t('VisitorBooking_NewVisitor')}
                className="visitor-create-edit-panel"
                open={isOpen}
                onClose={() => {
                    setPanelOpen(false);
                }}
                customAngleButton={isEditMode ? null : customCornerButton}
                showBackgroundMask={showBackgroundMask}
            >
                <WithLoading isLoading={creationOrUpdatingInProgress}>
                    <div className="new-visitor-form" key={formKey}>
                        <div className="visitor-booking-form-wrapper">
                            <Select
                                control={control}
                                label={t('VisitorBooking_NewVisitorTitle')}
                                name="title"
                                dataTestId="visitor-booking-title"
                                className="title"
                                error={errors.title?.message}
                                horizontal
                                items={titles}
                                placeholder=""
                                initialValue={person?.title || ''}
                            />

                            <TextBox
                                label={t('VisitorBooking_NewVisitorFirstName')}
                                name="firstname"
                                dataTestId="visitor-booking-first-name"
                                {...register('firstname', { required: t('VisitorBooking_RequiredError') })}
                                error={errors.firstname?.message}
                                initialValue={person?.firstname ?? ''}
                                maxLength={50}
                                horizontal
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />

                            <TextBox
                                label={t('VisitorBooking_NewVisitorLastName')}
                                name="surname"
                                dataTestId="visitor-booking-last-name"
                                {...register('surname', {
                                    required: t('VisitorBooking_RequiredError'),
                                })}
                                error={errors.surname?.message}
                                initialValue={person?.surname ?? ''}
                                maxLength={50}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                horizontal
                            />

                            <TextBox
                                label={t('VisitorBooking_NewVisitorCompany')}
                                name="company"
                                dataTestId="visitor-booking-company"
                                {...register('company', { required: t('VisitorBooking_RequiredError') })}
                                error={errors.company?.message}
                                initialValue={person?.company ?? ''}
                                maxLength={100}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                horizontal
                            />

                            <MobileInput
                                codeName="countryCode"
                                numberName="mobile"
                                name="mobile"
                                label={t('VisitorBooking_NewVisitorTelephone')}
                                initialValue={person?.countryCode || UkCountryCode}
                                dataTestId="visitor-booking-mobile-field"
                                maxLength={20}
                                register={register}
                                horizontal
                                items={CountryCodes}
                                control={control}
                                error={errors.mobile?.message}
                                validation={{
                                    pattern: {
                                        value: /^[0-9 ]*$/,
                                        message: t('VisitorBooking_TelephoneError'),
                                    },
                                }}
                                initialInputValue={person?.mobile ?? ''}
                            />

                            <TextBox
                                label={t('VisitorBooking_NewVisitorEmail')}
                                name="email"
                                dataTestId="visitor-booking-email"
                                {...register('email', {
                                    required: t('VisitorBooking_RequiredError'),
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: t('VisitorBooking_EmailError'),
                                    },
                                })}
                                error={errors.email?.message}
                                initialValue={person?.email ?? ''}
                                maxLength={100}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                horizontal
                            />
                        </div>

                        <div className="buttons">
                            {isEditMode && (
                                <Button
                                    text={t('Button_Close')}
                                    className="close-btn"
                                    dataTestId="visitor-booking-close-btn"
                                    onClick={() => {
                                        reset();
                                        setPanelOpen(false);
                                    }}
                                    secondary
                                />
                            )}

                            <Button
                                text={t('Button_Save')}
                                onClick={() => {
                                    handleSubmit(async (values) => {
                                        if (onSave) {
                                            onSave(values);
                                        }
                                    })();
                                }}
                                className="save-btn"
                                dataTestId="visitor-booking-save-btn"
                            />
                        </div>
                    </div>
                </WithLoading>
            </SlidingPanel>
        </div>
    );
};
