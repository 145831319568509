import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as rax from 'retry-axios';
import LoggingService from 'services/LoggingService';

import './i18n';
import 'tslib';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

import 'scss/index.scss';

axios.defaults.headers.post['Content-Type'] = 'application/json';

rax.attach();
axios.defaults.raxConfig = {
    retry: 3,
    retryDelay: 250,
    noResponseRetries: 2,
    httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST', 'GET'],
    backoffType: 'exponential',
    statusCodesToRetry: [
        [100, 199],
        [428, 430],
        [500, 599],
    ],
    onRetryAttempt: (err) => {
        const cfg = rax.getConfig(err);
        if (!cfg) return;

        LoggingService.Debug('Retry attempt #', cfg.currentRetryAttempt);
    },
};

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
