import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';

import { SubscribeToSignalRGroupRequest, UnsubscribeFromSignalRGroupRequest } from './Models';

export class BookingSignalRApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().BOOKING_SIGNALR_API;

        return `${baseUrl}/api`;
    };

    public static subscribe = async (subscribeRequest: SubscribeToSignalRGroupRequest): Promise<ApiResponse> => {
        LoggingService.Debug('Sending BookingSignalR.Subscribe command. Request: ', subscribeRequest);
        LoggingService.Debug('Sending BookingSignalR.Subscribe command. Headers: ', BookingSignalRApi.PersistedHeaders);

        return BookingSignalRApi.post<SubscribeToSignalRGroupRequest>(
            `${this.BuildBaseUrl()}/subscribe`,
            subscribeRequest
        );
    };

    public static unsubscribe = async (
        unsubscribeRequest: UnsubscribeFromSignalRGroupRequest
    ): Promise<ApiResponse> => {
        LoggingService.Debug('Sending BookingSignalR.Unsubscribe command. Request: ', unsubscribeRequest);
        LoggingService.Debug(
            'Sending BookingSignalR.Unsubscribe command. Headers: ',
            BookingSignalRApi.PersistedHeaders
        );

        return BookingSignalRApi.put<UnsubscribeFromSignalRGroupRequest>(
            `${this.BuildBaseUrl()}/unsubscribe`,
            unsubscribeRequest
        );
    };
}
