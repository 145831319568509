import React from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import './TimeSlotCard.scss';

export interface CardProps {
    label: string;
    startTime: DateTime;
    endTime: DateTime;
}

interface TimeSlotCardProps {
    timeSlot: CardProps;
    onClick: (value: any) => void;
    itemId: string;
    cardWidth: number;
    selected: boolean;
}

const TimeSlotCard = ({ timeSlot, onClick, itemId, cardWidth, selected }: TimeSlotCardProps): JSX.Element => {
    return (
        <div
            className={classNames('time-slot-card', { selected })}
            onClick={() => onClick(timeSlot.label)}
            key={itemId}
            style={{ width: cardWidth }}
        >
            <span className="time-slot">{timeSlot.label}</span>
        </div>
    );
};

export default TimeSlotCard;
