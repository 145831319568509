import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnFilterInterface } from '../../features/Reports/interfaces';
import SlidingPanel from '../SlidingPanel';

import ReportFilterPanelCount from './ReportFilterPanelCount/ReportFilterPanelCount';
import ReportFilterPanelMultiple from './ReportFilterPanelMultiple/ReportFilterPanelMultiple';
import ReportFilterPanelSearch from './ReportFilterPanelSearch/ReportFilterPanelSearch';

import './ReportFilterPanel.scss';

interface ReportFilterPanelProps {
    isFiltersOpened: boolean;
    activeFilterHeaderName: string;
    setIsFiltersOpened: Function;
    activeFilter: string;
    activeColumnsFilterArray: any[];
    columnsFilter: ColumnFilterInterface;
    setColumnsFilter: Function;
    generateReportWithColumnsFilter: () => void;
    isLoading?: boolean;
    countFieldName?: string;
}

const ReportFilterPanel = ({
    isFiltersOpened,
    activeFilterHeaderName,
    setIsFiltersOpened,
    activeFilter,
    activeColumnsFilterArray,
    setColumnsFilter,
    columnsFilter,
    generateReportWithColumnsFilter,
    isLoading,
    countFieldName,
}: ReportFilterPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const scrollContainerRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isFiltersOpened) {
            if (scrollContainerRef?.current) {
                scrollContainerRef.current.scrollTop = 0;
            }
        }
    }, [isFiltersOpened]);

    const updateColumnsFilter = (values: any): void => {
        setColumnsFilter({
            ...columnsFilter,
            [activeFilter]: {
                ...columnsFilter[activeFilter],
                values,
            },
        });
    };

    const toggleAll = (): void => {
        const columns = columnsFilter[activeFilter]?.values;
        const activeColumns = activeColumnsFilterArray.map((item) => item.value);

        if (columns?.length < activeColumns.length) {
            updateColumnsFilter([...activeColumns]);
        } else {
            updateColumnsFilter([]);
        }
    };

    const handleChange = (event: SyntheticEvent): void => {
        const element = event.target as HTMLInputElement;
        const selectedValue = element.value;

        if (columnsFilter[activeFilter]?.values.includes(selectedValue)) {
            updateColumnsFilter(columnsFilter[activeFilter].values.filter((value) => value !== selectedValue));
        } else {
            updateColumnsFilter([...columnsFilter[activeFilter].values, selectedValue]);
        }
    };

    const renderContent = (): JSX.Element => {
        switch (activeFilter) {
            case 'department': {
                return (
                    <ReportFilterPanelSearch
                        activeFilter={activeFilter}
                        activeColumnsFilterArray={activeColumnsFilterArray}
                        columnsFilter={columnsFilter}
                        generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                        isLoading={isLoading}
                        handleChange={handleChange}
                        toggleAll={toggleAll}
                        scrollContainerRef={scrollContainerRef}
                    />
                );
            }
            case 'cumulativeVisits':
            case 'bookingsCount':
            case 'attendeesCount': {
                return (
                    <ReportFilterPanelCount
                        activeFilter={activeFilter}
                        columnsFilter={columnsFilter}
                        generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                        isLoading={isLoading}
                        updateColumnsFilter={updateColumnsFilter}
                        scrollContainerRef={scrollContainerRef}
                        countFieldName={countFieldName}
                    />
                );
            }
            default: {
                return (
                    <ReportFilterPanelMultiple
                        activeFilter={activeFilter}
                        activeColumnsFilterArray={activeColumnsFilterArray}
                        columnsFilter={columnsFilter}
                        generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                        isLoading={isLoading}
                        handleChange={handleChange}
                        toggleAll={toggleAll}
                        scrollContainerRef={scrollContainerRef}
                    />
                );
            }
        }
    };

    return (
        <SlidingPanel
            open={isFiltersOpened}
            title={t('Reports_FilterTitle')}
            subTitle={activeFilterHeaderName}
            className="report-filter-panel"
            onClose={() => setIsFiltersOpened(false)}
            showBackgroundMask
        >
            {renderContent()}
        </SlidingPanel>
    );
};

export default ReportFilterPanel;
