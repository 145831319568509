import React from 'react';
import LanguageSelectorComponent from 'components/LanguageSelector';
import { mapListItems } from 'utilities/selectList';

// eslint-disable-next-line import/no-cycle
import { LanguageSelectorProps } from '.';

const LanguageSelector = ({ language, options, setLanguage }: LanguageSelectorProps): JSX.Element => {
    const availableLanguages = mapListItems(
        options,
        (item) => item.locale,
        (item) => item.displayName
    );

    return (
        <LanguageSelectorComponent
            value={{ value: language.locale, label: language.displayName }}
            availableLanguages={availableLanguages}
            onLanguageSelect={setLanguage}
        />
    );
};

export default LanguageSelector;
