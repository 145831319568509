import React from 'react';

import './BookingsHeader.scss';

export interface BookingsHeaderItems {
    label: string;
}

interface BookingsHeaderProps {
    headerItems: BookingsHeaderItems[];
}

const BookingsHeader = ({ headerItems }: BookingsHeaderProps): JSX.Element => {
    return (
        <div className="bookings-header">
            {headerItems.map((item) => (
                <div key={item.label} className="header-item">
                    {item.label}
                </div>
            ))}
        </div>
    );
};

export default BookingsHeader;
