import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createPrimaryButton, createSecondaryButton } from 'components/ActionButtons/Models/ActionButton';
import { EditVisitorsLocationState } from 'services/ApiClients/Models';

import Dialog from '../../../components/Dialog';

export interface VisitorBookingDialogsProps {
    locationState: EditVisitorsLocationState;
    cancelMultiDayDialogOpen: boolean;
    cancelDialogOpen: boolean;
    closeDialogOpen: boolean;
    setCancelDialogOpen: (value: boolean) => void;
    setCancelMultiDayDialogOpen: (value: boolean) => void;
    setCloseDialogOpen: (value: boolean) => void;
    cancelEditSiteVisit: (siteVisitId: string) => void;
    cancelEditMultiDaySiteVisit: (siteVisitId: string) => void;
}

const VisitorBookingDialogs = ({
    locationState,
    cancelMultiDayDialogOpen,
    cancelDialogOpen,
    closeDialogOpen,
    setCancelDialogOpen,
    cancelEditSiteVisit,
    setCancelMultiDayDialogOpen,
    cancelEditMultiDaySiteVisit,
    setCloseDialogOpen,
}: VisitorBookingDialogsProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const cancelConfirmDialog = (): JSX.Element => {
        const buttonsForSingle = [
            createSecondaryButton('no', t('Button_No'), () => setCancelDialogOpen(false)),
            createPrimaryButton('yes', t('Button_Yes'), () => {
                cancelEditSiteVisit(locationState.siteVisitId);
                setCancelDialogOpen(false);
            }),
        ];

        const message = t('VisitorBooking_CancelConfirmationDialog');

        return <Dialog message={message} buttons={buttonsForSingle} description="" />;
    };

    const cancelMultiDayConfirmDialog = (): JSX.Element => {
        const buttonsForSingle = [
            createSecondaryButton('Keep series', t('Button_KeepSeries'), () => {
                setCancelMultiDayDialogOpen(false);
            }),
            createPrimaryButton('Cancel series', t('Button_CancelSeries'), () => {
                cancelEditMultiDaySiteVisit(locationState.multiDaySiteVisitId);
                setCancelMultiDayDialogOpen(false);
            }),
        ];

        const message = t('CancelConfirmationMultiDay');
        const description = t('CancelConfirmationMultiDayDescription');

        return (
            <Dialog
                showCloseIcon
                closeDialog={() => setCancelMultiDayDialogOpen(false)}
                message={message}
                buttons={buttonsForSingle}
                description={description}
            />
        );
    };

    const closeConfirmDialog = (): JSX.Element => {
        const buttonsForSingle = [
            createSecondaryButton('no', t('Button_No'), () => setCloseDialogOpen(false)),
            createPrimaryButton('yes', t('Button_Yes'), () => {
                navigate(locationState.previousPath);
                setCloseDialogOpen(false);
            }),
        ];

        const message = t('VisitorBooking_CloseConfirmationDialog');

        return <Dialog message={message} buttons={buttonsForSingle} description="" />;
    };
    return (
        <>
            {cancelMultiDayDialogOpen && locationState.multiDaySiteVisitId && cancelMultiDayConfirmDialog()}
            {cancelDialogOpen && !locationState.multiDaySiteVisitId && cancelConfirmDialog()}
            {closeDialogOpen && closeConfirmDialog()}
        </>
    );
};

export default VisitorBookingDialogs;
