import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as constants from 'features/constants';
import { BOOKING_STATUS } from 'features/constants';
import useSignalR from 'hooks/useSignalR';
import { DateTime } from 'luxon';
import { BookedResourceSummary, Booking } from 'services/ApiClients/Booking';
import { BookingUnavailableTimes } from 'services/ApiClients/Booking/Models';
import { Configuration } from 'services/ApiClients/Configuration';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { ResourcesGS } from 'services/ApiClients/Resource/Models';
import BookingSignalRService from 'services/BookingSignalRService';
import LoggingService from 'services/LoggingService';
import ToastService from 'services/ToastService';
import { getConfiguration } from 'utilities/appConfigsUtils';

interface ProviderProps {
    selectedSite: GeographicStructureItem | null;
    areas: GeographicStructureItem[];
    resources: ResourcesGS[];
    configuration: Configuration;
    updateBookings: (param: BookedResourceSummary[]) => void;
    refreshBookingUnavailability: (bookingUnavailableTimes: BookingUnavailableTimes) => void;
    selectedDate: DateTime;
}

const Provider = ({
    children,
    selectedSite,
    areas,
    resources,
    configuration,
    updateBookings,
    refreshBookingUnavailability,
    selectedDate,
}: React.PropsWithChildren<ProviderProps>): JSX.Element => {
    const timezone = (selectedSite && selectedSite.timezone) || constants.DEFAULT_TIMEZONE;
    const areaIds = areas.map((item) => item.id);
    const { t } = useTranslation();

    /* eslint-disable @typescript-eslint/no-use-before-define */
    const handleSignalRUpdate = (booking: Booking): void => {
        const releventBookedResources = BookingSignalRService.handleReceivedUpdate(
            booking,
            resources,
            configuration,
            selectedDate,
            timezone
        );

        if (!releventBookedResources.length) {
            return;
        }

        LoggingService.Debug('Dispatching action to update booked resources');
        updateBookings(releventBookedResources);

        const newUnavailability = {
            bookingId: booking.id.toString(),
            unavailableResources: booking.unavailableResources,
            abandoned:
                booking.bookingStatus === BOOKING_STATUS.ABANDONED ||
                booking.bookingStatus === BOOKING_STATUS.CANCELLED,
        };
        refreshBookingUnavailability(newUnavailability);
    };

    const handleSignalRConnected = async (connectionId: string | null): Promise<void> => {
        LoggingService.Debug('Connected to SignalR');

        if (connectionId) {
            LoggingService.Debug('Connection Id', connectionId);
        }

        hubConnection.on('updated', (message: Booking) => {
            LoggingService.Debug('Update received pre processing', message);
            handleSignalRUpdate(message);
        });

        LoggingService.Debug('Subscribing to group');
        await BookingSignalRService.subscribeToGroup(connectionId, areaIds);
    };

    const handleSignalRReconnecting = (error: Error): void => {
        LoggingService.Debug('SingalR Reconnecting - Error', error);
    };

    const handleSignalRReconnected = async (connectionId?: string): Promise<void> => {
        LoggingService.Debug('SignalR reconnected - Connection id', connectionId);

        if (!connectionId) {
            return;
        }
        await BookingSignalRService.subscribeToGroup(connectionId, areaIds);
    };

    const handleSignalRDisconnected = (): void => {};

    const handleSignalRClosed = async (): Promise<void> => {
        const message = t('Error_SignalRClosed');
        // Display toast notifcation warning of error
        ToastService.Error({ message });

        try {
            if (hubConnection?.connectionId) {
                await BookingSignalRService.unsubscribeFromGroup(hubConnection.connectionId, areaIds);
            }
        } catch (error) {
            // If the SignalR service is truly down and the azure functions cannot communicate with it this is going to result in an unhandled exception being thrown from the promise. We do not need to display an error in this case as if the service is truly down, then we are not going to be able to unsubscribe and the when the service resumes it will lose all of its groups anyway.
            LoggingService.Debug(
                'Error whilst trying to unsubscribe from group. Suspected Azure SignalR Service is down',
                error
            );
        }
    };

    const handleSignalRUnmount = async (): Promise<void> => {
        await BookingSignalRService.unsubscribeFromGroup(hubConnection.connectionId, areaIds);
        hubConnection?.stop();
    };

    const handleSignalRError = (): void => {
        // Display toast for signalR connection error
        const message = t('Error_SignalRConnectionError');
        ToastService.Error({ message });
    };

    const hubConnection = useSignalR({
        baseApiUrl: `${getConfiguration().BOOKING_SIGNALR_API}/api`,
        onConnected: handleSignalRConnected,
        onReconnecting: handleSignalRReconnecting,
        onReconnected: handleSignalRReconnected,
        onDisconnected: handleSignalRDisconnected,
        onClosed: handleSignalRClosed,
        onUnmount: handleSignalRUnmount,
        onError: handleSignalRError,
    });
    /* eslint-enable @typescript-eslint/no-use-before-define */

    return <>{children}</>;
};

export default memo(Provider);
