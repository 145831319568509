import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ClosePanel.svg';
import classNames from 'classnames';
import Button from 'components/FormElements/Button';
import { isNil } from 'utilities/ts';

import './ErrorMessage.scss';

export interface ErrorMessageProps {
    title: string;
    message: string;
    showClose?: boolean;
    onClose?(): void;
}

const ErrorMessage = React.forwardRef<HTMLDivElement, ErrorMessageProps>(
    ({ title, message, showClose = false, onClose = () => null }: ErrorMessageProps, ref): JSX.Element => {
        return (
            <div className="errorMessage" ref={ref}>
                <h1>{isNil(title) ? 'Error' : title}</h1>
                <p>{message}</p>
                {showClose && (
                    <Button
                        className={classNames('close')}
                        type="button"
                        iconOnly
                        icon={CloseIcon}
                        onClick={onClose}
                        text="Close"
                        dataTestId="close-button"
                    />
                )}
            </div>
        );
    }
);

export default ErrorMessage;
