import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { PersonSearchData } from '../../../components/PersonSearchPanels/interfaces/PersonSearchData';
import { VmsPersonSearch } from '../../../components/PersonSearchPanels/VmsPersonSearch/VmsPersonSearch';
import { PersonType } from '../../../enums/PersonType';
import { Person } from '../../../services/ApiClients/Models';
import { AllListItemOption } from '../../constants';
import { onUserSearch } from '../utils';

interface LoginsReportFilterFormProps {
    setReportsFilter: Function;
    reportsFilter: any;
    searchUsers: Function;
    searchUsersResult: Person[] | null;
    resetUsersSearch: Function;
    isSearchUsersInProgress: boolean;
}

const LoginsReportFilterForm = ({
    setReportsFilter,
    reportsFilter,
    searchUsers,
    searchUsersResult,
    resetUsersSearch,
    isSearchUsersInProgress,
}: LoginsReportFilterFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [userSearchData, setUseSearchData] = useState<PersonSearchData>({} as PersonSearchData);

    useEffect(() => {
        setReportsFilter({ dateRange: { from: DateTime.now(), to: DateTime.now() }, user: AllListItemOption.label });
    }, []);

    return (
        <VmsPersonSearch
            className="user-search"
            dataTestId="user-search"
            buttonLabel={t('Reports_User')}
            panelLabel={t('Reports_SearchUsers')}
            resultPlaceHolder={t('Reports_SearchForUsers')}
            onSelect={(selectedPerson: Person) => {
                setReportsFilter({
                    ...reportsFilter,
                    user: selectedPerson !== null ? selectedPerson : AllListItemOption.label,
                });
            }}
            onSearch={(searchData: PersonSearchData) => onUserSearch(searchData, searchUsers)}
            onSearchDataChange={(searchValue: object) => {
                setUseSearchData(searchValue as PersonSearchData);
            }}
            searchResult={searchUsersResult}
            selectedItem={reportsFilter.user !== AllListItemOption.label ? reportsFilter.user : null}
            iaSearchInProgress={isSearchUsersInProgress}
            resetSearchResult={resetUsersSearch}
            searchData={userSearchData}
            personType={PersonType.Internal}
            companySearch={false}
        />
    );
};

export default LoginsReportFilterForm;
