import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronRight } from 'assets/icons/ChevronRight.svg';
import { ReactComponent as ConfigSites } from 'assets/icons/ConfigSites.svg';
import { ReactComponent as Folder } from 'assets/icons/Folder.svg';
import classNames from 'classnames';

import { GeographicStructureItem } from '../../services/ApiClients/OrganisationStructure';
import Guid from '../../utilities/guid';

import './MultiResourceBookingSiteSelector.scss';

interface SiteGroupSelectorProps {
    selectedSiteIds: string[];
    selectedSiteGroup: GeographicStructureItem | null;
    sites: GeographicStructureItem[];
    siteGroups: { [k: string]: GeographicStructureItem[] };
    tenantId: any;
    setSelectedSiteIds: Function;
    setSelectedSiteGroup: Function;
    selectedSiteGlobalId?: Guid;
    isTopLevelGeographicStructure: boolean;
}

const MultiResourceBookingSiteSelector = ({
    selectedSiteIds,
    setSelectedSiteIds,
    setSelectedSiteGroup,
    selectedSiteGroup,
    sites,
    siteGroups,
    tenantId,
    selectedSiteGlobalId,
    isTopLevelGeographicStructure,
}: SiteGroupSelectorProps): JSX.Element => {
    const { t } = useTranslation();

    const filteredSites = useMemo(() => {
        if (!isTopLevelGeographicStructure) {
            return sites.filter((s) => s.parentId === selectedSiteGroup?.id);
        }
        return sites;
    }, [sites, selectedSiteGroup?.id]);

    const onSiteSelect = (siteId: string): void => {
        if (selectedSiteIds.includes(siteId)) {
            setSelectedSiteIds([...selectedSiteIds.filter((a) => a !== siteId)]);
        } else {
            setSelectedSiteIds([...selectedSiteIds, siteId]);
        }
    };

    return (
        <div className="mrb-site-selector">
            {siteGroups[tenantId] && siteGroups[tenantId].length > 0 && (
                <div className="site-groups">
                    <div className="site-groups-header">
                        <Folder /> <div className="title">{t('MultiResourceBooking_SiteGroups')}</div>
                    </div>

                    <div className="site-groups-items">
                        {siteGroups[tenantId].map((group) => {
                            const isSelected = selectedSiteGroup?.id === group.id;
                            if (sites.filter((s) => s.parentId === group.id).length > 0) {
                                return (
                                    <div
                                        key={group.id.toString()}
                                        className={classNames('site-group-item', { selected: isSelected })}
                                        onClick={() => {
                                            setSelectedSiteGroup(group);
                                        }}
                                    >
                                        <div className="name">{group.name}</div>
                                        <ChevronRight />
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            )}
            {filteredSites.length > 0 && (
                <div className="sites">
                    <div className="sites-header">
                        <ConfigSites /> <div className="title">{t('MultiResourceBooking_SelectSites')}</div>
                    </div>
                    <div className="site-items">
                        {filteredSites.map((site) => {
                            const isSelected = selectedSiteIds.includes(site.id.toString());
                            const isDisabled = selectedSiteGlobalId === site.id;

                            return (
                                <div
                                    className={classNames('site-item', { selected: isSelected, disabled: isDisabled })}
                                    key={site.id.toString()}
                                >
                                    <div
                                        className="name"
                                        onClick={() => {
                                            if (!isDisabled) {
                                                onSiteSelect(site.id.toString());
                                            }
                                        }}
                                    >
                                        {site.name}
                                    </div>
                                    <input
                                        className="check-box"
                                        type="checkbox"
                                        disabled={isDisabled}
                                        checked={isSelected}
                                        onChange={() => {
                                            onSiteSelect(site.id.toString());
                                        }}
                                        value={site.id.toString()}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiResourceBookingSiteSelector;
