import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import i18n from 'i18next';

import './RepeatInfo.scss';

export const getRepeatInfoColumns = (resourceType: string): GridColDef[] => [
    {
        field: 'icon',
        headerName: '',
        minWidth: 50,
        sortable: false,
        align: 'center',
        renderCell: (params: GridCellParams) => (
            <div className="repeat-info-icon" title={i18n.t(params.row.occurrenceStatus)}>
                {params.row.icon}
            </div>
        ),
    },
    {
        field: 'date',
        headerName: i18n.t('Repeat_Date'),
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'time',
        headerName: i18n.t('Repeat_Time'),
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'title',
        headerName: i18n.t('Repeat_Title'),
        minWidth: 300,
        flex: 1,
        sortable: false,
    },
    {
        field: resourceType,
        headerName: i18n.t('Repeat_ResourceType', { resourceType }),
        minWidth: 300,
        flex: 1,
        sortable: false,
    },
];
