import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import deskImage from 'assets/desk-default-img.png';
import { ReactComponent as ConfigSites } from 'assets/icons/ConfigSites.svg';
import { ReactComponent as Time } from 'assets/icons/DatePickerTime.svg';
import { ReactComponent as RestrictedIcon } from 'assets/icons/deskInfo/RestrictedColor.svg';
import { ReactComponent as WheelchairIcon } from 'assets/icons/deskInfo/WheelchairColor.svg';
import { ReactComponent as InfoWithoutBorder } from 'assets/icons/InfoWithoutBorder.svg';
import { ReactComponent as RoomCapacity } from 'assets/icons/RoomCapacity.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import roomImage from 'assets/room-default-img.png';

import Accordion from '../../../../../../components/Accordion';
import DateTimePickerValue from '../../../../../../components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import ResourceInfoSlider from '../../../../../../components/ResourceInfo/ResourceInfoSlider';
import { GeographicStructureItem } from '../../../../../../services/ApiClients/OrganisationStructure';
import { CombinedResource, ResourceType } from '../../../../../../services/ApiClients/Resource/Models';
import Guid from '../../../../../../utilities/guid';
import { DEFAULT_TIMEZONE } from '../../../../../constants';

import './SelectedSitesPanel.scss';

interface SelectedSitesPanelProps {
    sites: GeographicStructureItem[];
    siteGroups: GeographicStructureItem[] | null;
    selectedResourceIds: string[];
    selectedDate: DateTimePickerValue;
    isTopLevelGeographicStructure: boolean;
    resources: CombinedResource[];
    selectedSiteGlobalId: string;
    areas: { [p: string]: GeographicStructureItem[] };
}

const AccordionItemHeader = (
    siteName: string,
    time: string,
    timezone?: string,
    siteGroupName?: string
): JSX.Element => {
    return (
        <div className="site-accordion-header-item">
            <div className="site-icon">
                <ConfigSites />
            </div>
            <div className="site-info">
                {siteGroupName && <div className="site-group-name">{siteGroupName}</div>}
                <div className="site-name">{siteName}</div>
                <div className="time-block">
                    <div className="time-icon">
                        <Time />
                    </div>
                    <div className="time">{time}</div>
                    <div>({timezone})</div>
                </div>
            </div>
        </div>
    );
};

const ResourceInfoIcons = (disabledAccess: boolean, enabled: boolean): JSX.Element => {
    return (
        <>
            {disabledAccess && <WheelchairIcon />}
            {!enabled && <RestrictedIcon />}
        </>
    );
};

const AccordionItemContent = (
    resourceType: number,
    resourceName: string,
    onInfoClick: Function,
    areaName?: string,
    disabledAccess = false,
    enabled = true,
    capacity?: number
): JSX.Element => {
    return (
        <div className="resource-accordion-content-item">
            <div className="resource-image" onClick={() => onInfoClick()}>
                <div className="info-icon">
                    <InfoWithoutBorder />
                </div>
                <img src={resourceType === ResourceType.DESK.value ? deskImage : roomImage} alt="resource" />
            </div>

            <div className="resource-info">
                <div className="resource-name">{resourceName}</div>
                <div className="area-name">{areaName}</div>
                <div className="resource-info-icons">
                    <div className="room-capacity">
                        <RoomCapacity />
                        <div className="room-capacity-value">{capacity}</div>
                    </div>
                    {ResourceInfoIcons(disabledAccess, enabled)}
                </div>
            </div>
            <div className="trash-icon">
                <TrashIcon />
            </div>
        </div>
    );
};

const AccordionContent = (
    siteId: Guid,
    resources: CombinedResource[],
    siteAreas: GeographicStructureItem[],
    selectedResourceIds: string[],
    onResourceClick: Function,
    noResourceLabel: string
): JSX.Element => {
    return (
        <div>
            {resources?.map((r) => {
                const areaName = siteAreas?.find((a) => a.id === r.geographicStructureId)?.name;

                return selectedResourceIds.includes(r.id.toString()) && r.siteId === siteId ? (
                    AccordionItemContent(
                        r.resourceType,
                        r.name,
                        () =>
                            onResourceClick(
                                siteAreas?.find((a) => a.id === r.geographicStructureId),
                                r
                            ),
                        areaName,
                        r.disabledAccess,
                        r.enabled,
                        r.capacity
                    )
                ) : (
                    <div className="no-resource-selected-placeholder">{noResourceLabel}</div>
                );
            })}
        </div>
    );
};

const SelectedSitesPanel = ({
    sites,
    selectedResourceIds,
    selectedDate,
    siteGroups,
    isTopLevelGeographicStructure,
    resources,
    selectedSiteGlobalId,
    areas,
}: SelectedSitesPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const [infoSliderData, setInfoSliderData] = useState<any | null>(null);

    const onResourceClick = useCallback((geoStructure: any, resource: any): void => {
        const img = resource.resourceType === ResourceType.DESK.value ? deskImage : roomImage;
        setInfoSliderData({ geoStructure, resource: { ...resource, imageUrl: img } });
    }, []);

    return (
        <div className="selected-sites-panel">
            <div className="panel-title">{t('MultiResourceBooking_BookingSelections')}</div>
            <div className="panel-content">
                <Accordion
                    items={sites.map((s) => {
                        const siteGroupName = isTopLevelGeographicStructure
                            ? ''
                            : siteGroups?.find((g) => g.id === s.parentId)?.name;

                        const timeRange = `${selectedDate.startDateTime
                            .setZone(s.timezone || DEFAULT_TIMEZONE)
                            .toFormat('HH:mm')} - ${selectedDate.endDateTime
                            .setZone(s.timezone || DEFAULT_TIMEZONE)
                            .toFormat('HH:mm')}`;

                        const siteAreas = areas[s.id.toString()];
                        const isExpanded = selectedSiteGlobalId === s.id.toString();
                        return {
                            key: s.id.toString(),
                            isExpanded,
                            header: AccordionItemHeader(s.name, timeRange, s.timezone, siteGroupName),
                            content: AccordionContent(
                                s.id,
                                resources,
                                siteAreas,
                                selectedResourceIds,
                                onResourceClick,
                                t('MultiResourceBooking_NoResourceSelected')
                            ),
                        };
                    })}
                />
            </div>

            <ResourceInfoSlider
                isOpen={!!infoSliderData}
                isLoading={false}
                geoStructureName={infoSliderData?.geoStructure?.name || ''}
                title={t('Resource_About_Label')}
                onClose={() => {
                    setInfoSliderData(null);
                }}
                resource={infoSliderData?.resource || {}}
            />
        </div>
    );
};

export default SelectedSitesPanel;
