import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import RepeatContainer from 'features/Resources/Combined/Repeat/RepeatContainer';
import { RootState } from 'PortalTypes';
import {
    CancelOrCurtailBookingCommand,
    CheckInOrCheckOutSingleOccurrence,
    ConfirmCommand,
    ConfirmSingleOccurrence,
    UpdateCommand,
} from 'services/ApiClients/Booking';
import {
    AbandonSingleBooking,
    AbandonSingleOccurrence,
    CancelSingleOccurrence,
    GetBookingQuery,
    SearchBookedResourcesQuery,
    UpdateSingleOccurrenceFromBookingInfo,
} from 'services/ApiClients/Booking/Models';

import * as actions from '../../Common/EditBooking/Redux/actions/info';
import { getContainerData, getRepeatContainerData } from '../../Common/EditBooking/Redux/selectors';
import {
    getBookingsByResourcesIdsAsync,
    resetBookingsByResourcesIds,
    updateSingleOccurrenceForBookingInfoAsync,
} from '../Redux/actions';

const mapStateToProps = (state: RootState): any => ({
    ...getContainerData(state),
    ...getRepeatContainerData(state),
});

const dispatchProps = {
    confirmSingleOccurrence: (bookingRequest: ConfirmSingleOccurrence) =>
        actions.bookASingleOccurrenceFromBookingInfoAsync.request(bookingRequest),
    checkIn: (bookingRequest: CheckInOrCheckOutSingleOccurrence) =>
        actions.checkInSingleOccurrence.request(bookingRequest),
    checkOut: (bookingRequest: CheckInOrCheckOutSingleOccurrence) =>
        actions.checkOutSingleOccurrence.request(bookingRequest),
    dismissError: () => actions.combinedBookingDismissError(),
    handleClose: () => actions.navigateFromInfoPage(),
    resetNotSavedBookingInfo: () => actions.resetNotSavedBookingInfo(),
    abandonSingleOccurrence: (request: AbandonSingleOccurrence) =>
        actions.abandonSingleOccurrenceAsync.request(request),
    deleteRepeatSeries: (bookingRequest: CancelOrCurtailBookingCommand) =>
        actions.deleteRepeatSeriesAsync.request(bookingRequest),
    updateSingleOccurrence: (updateRequest: UpdateSingleOccurrenceFromBookingInfo) =>
        updateSingleOccurrenceForBookingInfoAsync.request(updateRequest),
    cancelSingleOccurrence: (request: CancelSingleOccurrence) => actions.cancelSingleOccurrenceAsync.request(request),
    updateWholeSeries: (bookingRequest: UpdateCommand) => actions.updateCombinedBookingAsync.request(bookingRequest),
    confirmBooking: (bookingRequest: ConfirmCommand) =>
        actions.bookACombinedConfirmFromBookingInfoAsync.request(bookingRequest),
    abandonBooking: (bookingRequest: AbandonSingleBooking) => actions.abandonBookingAsync.request(bookingRequest),
    getParentBooking: (bookingQuery: GetBookingQuery) => actions.getParentBooking(bookingQuery),
    getBookingsByResourcesIds: (params: SearchBookedResourcesQuery) => getBookingsByResourcesIdsAsync.request(params),
    resetBookingsByResourcesIds,
};

export type RepeatContainerProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export default connect(mapStateToProps, dispatchProps)(RepeatContainer);
