import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import {
    UpdateCompanyAttributesCommand,
    UpdateUserDefaultSiteCommand,
    UpdateUserProfileCommand,
    UserProfile,
} from 'services/ApiClients/Identity/Models';
import { SpecifiedCompanyAttributesResponse } from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const myAccountUserProfileReset = createAction('GET_MYACCOUNT_USER_PROFILE_RESET')<void>();

export const getMyAccountUserProfileAsync = createAsyncAction(
    'GET_MYACCOUNT_USER_PROFILE_REQUEST',
    'GET_MYACCOUNT_USER_PROFILE_SUCCESS',
    'GET_MYACCOUNT_USER_PROFILE_FAILURE'
)<void, ApiResponse<UserProfile>, AxiosError | ApiResponse>();

export const updateMyAccountUserProfileAsync = createAsyncAction(
    'UPDATE_MYACCOUNT_USER_PROFILE_REQUEST',
    'UPDATE_MYACCOUNT_USER_PROFILE_SUCCESS',
    'UPDATE_MYACCOUNT_USER_PROFILE_FAILURE'
)<UpdateUserProfileCommand, ApiResponse<void>, AxiosError | ApiResponse>();

export const updateUserProfileCompanyAttributesAsync = createAsyncAction(
    'UPDATE_USER_PROFILE_ATTRIBUTES_REQUEST',
    'UPDATE_USER_PROFILE_ATTRIBUTES_SUCCESS',
    'UPDATE_USER_PROFILE_ATTRIBUTES_FAILURE'
)<UpdateCompanyAttributesCommand, ApiResponse<void>, AxiosError | ApiResponse>();

export const updateUserDefaultSite = createAsyncAction(
    'UPDATE_USER_DEFAULT_SITE_REQUEST',
    'UPDATE_USER_DEFAULT_SITE_SUCCESS',
    'UPDATE_USER_DEFAULT_SITE_FAILURE'
)<UpdateUserDefaultSiteCommand, void, void>();

export const getTenantCompanyAttributesAsync = createAsyncAction(
    'GET_TENANT_ATTRIBUTES_REQUEST',
    'GET_TENANT_ATTRIBUTES_SUCCESS',
    'GET_TENANT_ATTRIBUTES_FAILURE'
)<void, ApiResponse<SpecifiedCompanyAttributesResponse[]>, AxiosError | ApiResponse>();

export default {
    myAccountUserProfileReset,
    getMyAccountUserProfileAsync,
    updateMyAccountUserProfileAsync,
    updateUserDefaultSite,
    getTenantCompanyAttributesAsync,
};
