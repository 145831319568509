import { RootAction, RootState, Services } from 'PortalTypes';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import services from 'services';

import { createReduxHistory, routerMiddleware } from './reduxHistoryContext';
import rootEpic from './rootEpic';
import rootReducer from './rootReducer';

// configure epic middleware
export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: services,
});

// Configure middlewares
const middlewares = [routerMiddleware, epicMiddleware];

// Create persisted reducer
// Note: We may want to use nested persists when our requirements get more complex: https://github.com/rt2zz/redux-persist
const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
};
const persistedReducer = persistReducer(persistConfig, rootReducer());

// Create store
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
export const persistor = persistStore(store);
export const history = createReduxHistory(store);

// Execute epic middleware
epicMiddleware.run(rootEpic);
