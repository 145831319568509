import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';
import DataTable from 'components/DataTable';
import WithLoading from 'components/HOC/WithLoading';
import Header from 'components/SiteSubHeader';
import { DEFAULT_DATE_FORMAT, LOAD_STATUSES } from 'features/constants';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { MyBookingCommand } from 'services/ApiClients/Booking/Models';
import { FormattedBooking } from 'services/ApiClients/Booking/Models/MyBooking';
import { Person } from 'services/ApiClients/Models';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { Visitor } from 'services/ApiClients/VisitorManagement/Models/Visitor';
import ToastService from 'services/ToastService';
import { v4 as uuidv4 } from 'uuid';

import { getMyBookingColumns } from './utils';

import './MyBookings.scss';

interface MyBookingsProps {
    bookingsAndSiteVisits: FormattedBooking[];
    siteVisitsLoadStatus: string;
    loadStatus: string;
    range: MyBookingCommand;
    getMyBookings: (param: MyBookingCommand) => void;
    getMySiteVisits: (param: MyBookingCommand) => void;
    setMyBookingsLoadStatus: (param: string) => void;
    setMySiteVisitsLoadStatus: (param: string) => void;
    timezone: string;
}

interface RowData {
    createdByDisplayName: string;
    createdDateTime: string;
    detail: string;
    dueDateTime: string;
    endDateTime: string;
    hostInfo: Person;
    id: any;
    isPartOfRepeat: boolean;
    onBehalfOfName: string;
    resourceType: string;
    site: string;
    sortIndex: number;
    startDateTime: string;
    title: string;
    visitorInfo: Visitor;
    multiDaySiteVisitId: string;
    isMultiDaySeriesCancelable: boolean | null;
    siteInformation: GeographicStructureItem;
    visitorCheckedIn: boolean;
    visitorCheckedOut: boolean;
    isCanceled: boolean;
}

const MyBookings = ({
    bookingsAndSiteVisits,
    siteVisitsLoadStatus,
    loadStatus,
    timezone,
    getMyBookings,
    getMySiteVisits,
    range,
    setMyBookingsLoadStatus,
    setMySiteVisitsLoadStatus,
}: MyBookingsProps): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const columns = useMemo(() => getMyBookingColumns(), [bookingsAndSiteVisits]);

    const isLoading = useMemo(
        () =>
            (loadStatus === LOAD_STATUSES.LOADING && siteVisitsLoadStatus === LOAD_STATUSES.LOADING) ||
            (bookingsAndSiteVisits.length !== 0 && isEmpty(bookingsAndSiteVisits[0]?.site)),
        [loadStatus, bookingsAndSiteVisits]
    );

    useEffect(() => {
        if (loadStatus === LOAD_STATUSES.REQUIRED) {
            getMyBookings(range);
        }

        if (siteVisitsLoadStatus === LOAD_STATUSES.REQUIRED) {
            getMySiteVisits(range);
        }

        return () => {
            setMyBookingsLoadStatus(LOAD_STATUSES.REQUIRED);
            setMySiteVisitsLoadStatus(LOAD_STATUSES.REQUIRED);
        };
    }, []);

    const onRowClickHandler = (rowData: RowData): void => {
        const {
            detail,
            id,
            visitorInfo,
            dueDateTime,
            multiDaySiteVisitId,
            isMultiDaySeriesCancelable,
            isPartOfRepeat,
            createdDateTime,
            resourceType,
            siteInformation,
            visitorCheckedIn,
            visitorCheckedOut,
            isCanceled,
            hostInfo,
        } = rowData;

        if (isCanceled) {
            return ToastService.Warning({ message: t('Toast_MyBookingsHasBeenCanceled') });
        }
        if (visitorCheckedOut) {
            return ToastService.Warning({ message: t('Toast_MyBookingsHasBeenCheckedOut') });
        }
        if (visitorCheckedIn) {
            return ToastService.Warning({ message: t('Toast_MyBookingsHasBeenCheckedIn') });
        }

        if (resourceType !== t('MyBookings_VisitorType')) {
            const pathname = isPartOfRepeat ? `/bookings/repeat/${id.toString()}` : `/bookings/${id.toString()}`;
            const search = isPartOfRepeat
                ? `?selectedDate=${DateTime.fromFormat(createdDateTime, 'dd MMM yyyy', { zone: timezone }).toFormat(
                      DEFAULT_DATE_FORMAT
                  )}`
                : '';
            return navigate({ pathname, search: `${createSearchParams(search)}` });
        }

        const editVisitorLocationState = {
            isEdit: true,
            visitorInfo: `${visitorInfo.firstname} ${visitorInfo.surname}`,
            visitorType: detail,
            siteVisitId: id,
            visitor: visitorInfo,
            dueDateTime,
            previousPath: location.pathname,
            multiDaySiteVisitId,
            isMultiDaySeriesCancelable,
            siteInformation,
            visitorCheckedIn,
            visitorCheckedOut,
            isCanceled,
            hostInfo,
        };
        return navigate(`/visitor-booking/${id.toString()}`, { state: editVisitorLocationState, replace: true });
    };

    return (
        <main className="myBookingsPage">
            <WithLoading isLoading={isLoading}>
                <Header title={t('MyBookings_Title')} dataTestId="pageMyBookings-title" />
                {bookingsAndSiteVisits.length ? (
                    <DataTable
                        columns={columns}
                        rowsData={bookingsAndSiteVisits || []}
                        onRowClick={(params: GridRowParams) => {
                            onRowClickHandler(params.row);
                        }}
                        getRowId={() => uuidv4()}
                    />
                ) : (
                    <span className="noBookingsMessage">{t('MyBookings_NoBookings')}</span>
                )}
            </WithLoading>
        </main>
    );
};

export default memo(MyBookings);
