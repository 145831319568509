import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';

import './CheckboxButtonSelector.scss';

interface CheckboxButtonSelectorProps {
    label: string;
    value: string;
    checked: boolean;
    handleChange: (event: SyntheticEvent) => void;
}

const CheckboxButtonSelector = ({ label, value, checked, handleChange }: CheckboxButtonSelectorProps): JSX.Element => {
    return (
        <div className="option" key={value}>
            <input type="checkbox" checked={checked} onChange={handleChange} value={value} />
            <span className={classNames('optionName', checked ? 'checked' : '')}>{label}</span>
        </div>
    );
};

export default CheckboxButtonSelector;
