import React from 'react';
import { Controller } from 'react-hook-form';
import Select, { components, createFilter, IndicatorsContainerProps } from 'react-select';
import classnames from 'classnames';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import './MultiSelect.scss';

export interface ListItem {
    dataTestId?: string;
    label: string;
    value: string;
}

export interface MultiSelectProps extends InputWrapperProps {
    validation?: any;
    error?: string;
    control?: any;
    initialValue?: any;
    items: ListItem[];
    isDisabled?: boolean;
    placeholder?: string;
    id?: string;
    horizontal?: boolean;
    onChange?: (value: any) => void;
    styles?: any;
    isSearchable?: boolean;
    dataTestId?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const IndicatorsContainer = (props: IndicatorsContainerProps) => {
    return (
        <div style={{ color: '#1996c7', alignItems: 'center' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};

const labelFilterOption = createFilter({
    stringify: (option) => option.label, // Default is `${option.label} ${option.value}`
});

export default (props: MultiSelectProps): JSX.Element | null => {
    const {
        label,
        name,
        className,
        hideLabel,
        validation,
        items,
        initialValue,
        isDisabled = false,
        error,
        control,
        placeholder = 'Select...',
        id,
        horizontal = false,
        onChange,
        styles,
        isSearchable,
        dataTestId = '',
    } = props;

    if (!items.length) {
        return null;
    }
    const [hasValue, setHasValue] = React.useState<boolean>(!!initialValue);

    const defaultValue = initialValue || null;

    return (
        <InputWrapper
            label={label}
            name={name}
            className={className}
            hideLabel={hideLabel}
            required={validation?.required}
            error={error}
            horizontal={horizontal}
            data-testid={dataTestId}
        >
            {control ? (
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    data-testid={dataTestId}
                    rules={validation}
                    render={({ onChange: onChangeEvent, name: subName }) => (
                        <Select
                            name={subName}
                            onChange={(event) => {
                                onChangeEvent(event);
                                setHasValue(true);
                            }}
                            components={{ IndicatorsContainer }}
                            className={classnames(
                                'multiSelect-container',
                                hasValue && 'multiSelect-container--has-value',
                                isDisabled && 'multiSelect-container--disabled',
                                { error: !!error }
                            )}
                            classNamePrefix="multiSelect"
                            options={items}
                            value={initialValue}
                            placeholder={placeholder}
                            isDisabled={isDisabled}
                            id={id}
                            filterOption={labelFilterOption}
                            isMulti
                            styles={styles}
                            isSearchable={isSearchable}
                            data-testid={dataTestId}
                        />
                    )}
                />
            ) : (
                <Select
                    onChange={onChange}
                    className={classnames(
                        'multiSelect-container',
                        hasValue && 'multiSelect-container--has-value',
                        isDisabled && 'multiSelect-container--disabled',
                        { error: !!error }
                    )}
                    components={{ IndicatorsContainer }}
                    classNamePrefix="multiSelect"
                    options={items}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    id={id}
                    filterOption={labelFilterOption}
                    isMulti
                    menuPlacement="auto"
                    value={initialValue}
                    styles={styles}
                    isSearchable={isSearchable}
                    data-testid={dataTestId}
                />
            )}
        </InputWrapper>
    );
};
