import * as constants from 'features/constants';
import { combineReducers } from 'redux';
import { ConfigurationResponse, DaysConfigurationResponse } from 'services/ApiClients/Configuration';
import { createReducer } from 'typesafe-actions';

import { Person, PersonsResponse } from '../../../services/ApiClients/Models';
import { PersonById } from '../../../services/ApiClients/Person/Models/PersonById';

import {
    cancelEditingSiteVisit,
    cancelEditMultiDaySiteVisit,
    createExternalVisitor,
    createMultiDaySiteVisit,
    createSiteVisit,
    editExternalVisitor,
    editSiteVisit,
    getBookableDays,
    getPersonById,
    resetSearchHostsResult,
    resetSearchVisitorsResult,
    searchExternalVisitors,
    searchHosts,
    searchInternalVisitors,
    setMySiteVisitsLoadStatus,
} from './actions';

interface VisitorBookingState {
    searchVisitorsResult: Person[] | null;
    searchHostsResult: Person[] | null;
    isSearchVisitorsInProgress: boolean;
    isSearchHostsInProgress: boolean;
    internalPersonInfo: PersonById | null;
    isVisitorCreationOrEditingInProgress: boolean;
    isActionInProgress: boolean;
    isSiteVisitCreationInProgress: boolean;
    isVisitorCreated: boolean;
    isVisitorUpdated: boolean;
    bookableDays: DaysConfigurationResponse | null;
    cancelEditingSiteVisitLoadStatus: string;
    cancelEditingMultiDaySiteVisitLoadStatus: string;
    editSiteVisitLoadStatus: string;
}

export default combineReducers<VisitorBookingState>({
    searchVisitorsResult: createReducer<Person[] | null>(null)
        .handleAction(
            [searchInternalVisitors.success, searchExternalVisitors.success],
            (state, action) => (action.payload.payload as PersonsResponse).items
        )
        .handleAction([searchInternalVisitors.failure, searchExternalVisitors.failure], () => [])
        .handleAction(
            [resetSearchVisitorsResult, searchInternalVisitors.request, searchExternalVisitors.request],
            () => null
        ),

    searchHostsResult: createReducer<Person[] | null>(null)
        .handleAction([searchHosts.success], (state, action) => (action.payload.payload as PersonsResponse).items)
        .handleAction([searchHosts.failure], () => [])
        .handleAction([resetSearchHostsResult, searchHosts.request], () => null),

    isSearchVisitorsInProgress: createReducer<boolean>(false)
        .handleAction([searchInternalVisitors.request, searchExternalVisitors.request], () => true)
        .handleAction(
            [
                searchInternalVisitors.success,
                searchInternalVisitors.failure,
                searchExternalVisitors.success,
                searchExternalVisitors.failure,
            ],
            () => false
        ),

    isSearchHostsInProgress: createReducer<boolean>(false)
        .handleAction([searchHosts.request], () => true)
        .handleAction([searchHosts.success, searchHosts.failure], () => false),

    internalPersonInfo: createReducer<PersonById | null>(null)
        .handleAction([getPersonById.success], (state, action) => action.payload.payload as PersonById)
        .handleAction([getPersonById.failure], () => null)
        .handleAction([getPersonById.request], () => null),

    bookableDays: createReducer<DaysConfigurationResponse | null>(null)
        .handleAction(
            [getBookableDays.success],
            (state, action) => (action.payload.payload as ConfigurationResponse).configurationSettings
        )
        .handleAction([getBookableDays.failure], () => null),

    isActionInProgress: createReducer<boolean>(false)
        .handleAction([getPersonById.request, getBookableDays.request], () => true)
        .handleAction(
            [getPersonById.success, getPersonById.failure, getBookableDays.success, getBookableDays.failure],
            () => false
        ),

    isSiteVisitCreationInProgress: createReducer<boolean>(false)
        .handleAction([createSiteVisit.request, createMultiDaySiteVisit.request], () => true)
        .handleAction(
            [
                createSiteVisit.success,
                createSiteVisit.failure,
                createMultiDaySiteVisit.success,
                createMultiDaySiteVisit.failure,
            ],
            () => false
        ),

    isVisitorCreationOrEditingInProgress: createReducer<boolean>(false)
        .handleAction([createExternalVisitor.request, editExternalVisitor.request], () => true)
        .handleAction(
            [
                createExternalVisitor.success,
                createExternalVisitor.failure,
                editExternalVisitor.success,
                editExternalVisitor.failure,
            ],
            () => false
        ),

    isVisitorCreated: createReducer<boolean>(false)
        .handleAction([createExternalVisitor.success], () => true)
        .handleAction([createExternalVisitor.failure], () => false),

    isVisitorUpdated: createReducer<boolean>(false)
        .handleAction([editExternalVisitor.success], () => true)
        .handleAction([editExternalVisitor.failure], () => false),

    cancelEditingSiteVisitLoadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setMySiteVisitsLoadStatus], () => constants.LOAD_STATUSES.REQUIRED)
        .handleAction([cancelEditingSiteVisit.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([cancelEditingSiteVisit.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([cancelEditingSiteVisit.failure], () => constants.LOAD_STATUSES.FAILED),

    cancelEditingMultiDaySiteVisitLoadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setMySiteVisitsLoadStatus], () => constants.LOAD_STATUSES.REQUIRED)
        .handleAction([cancelEditMultiDaySiteVisit.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([cancelEditMultiDaySiteVisit.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([cancelEditMultiDaySiteVisit.failure], () => constants.LOAD_STATUSES.FAILED),

    editSiteVisitLoadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setMySiteVisitsLoadStatus], () => constants.LOAD_STATUSES.REQUIRED)
        .handleAction([editSiteVisit.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([editSiteVisit.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([editSiteVisit.failure], () => constants.LOAD_STATUSES.FAILED),
});
