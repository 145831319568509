import React, { useEffect, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BackgroundMask, Loading, SlidingPanel } from 'components';
import { MY_ACCOUNT_24_FORMAT_VALUE } from 'features/constants';
import { CompanyAttributesModel } from 'services/ApiClients/Identity/Models';
import { getSubjects } from 'services/ApiClients/Identity/Subjects/updatePassword';
import ToastService from 'services/ToastService';
import Guid from 'utilities/guid';

import UpdatePassword from './Components/UpdatePassword';
import { PersonalDetailsFormState } from './Models/PersonalDetailsFormState';
import AccountComponent from './Components';
import { useFetchSalutations } from './Hooks';
// eslint-disable-next-line import/no-cycle
import { AccountProps } from '.';

const Account = (props: AccountProps): JSX.Element => {
    const {
        requiresLoading,
        isLoading,
        operationInProgress,
        userProfile,
        sites,
        loadReset,
        loadUserProfile,
        getSites,
        handleUpdate,
        handleCompanyAttributesUpdate,
        assignedCompanyAttributes,
        loadTenantCompanyAttributes,
        availableCompanyAttributes,
        allowEditCompanyAttributes,
    } = props;

    const [isPasswordPanelOpen, setIsPasswordPanelOpen] = React.useState<boolean>(false);
    const [isUpdatingPassword, setIsUpdatingPassword] = React.useState<boolean>(false);
    const [updatePasswordErrorMessage, setUpdatePasswordErrorMessage] = React.useState<string>();
    const [selectedCompanyAttributes, setSelectedCompanyAttributes] = React.useState<CompanyAttributesModel[]>([]);

    const { t } = useTranslation();

    const updatePassword: any = React.useRef(getSubjects());
    const { request: updatePasswordRequest } = updatePassword.current;
    useLayoutEffect(() => {
        const {
            response: updatePasswordResponse,
            error: updatePasswordError,
            dispose: updatePasswordDispose,
        } = updatePassword.current;

        updatePasswordResponse.subscribe(() => {
            setIsUpdatingPassword(false);
            setIsPasswordPanelOpen(false);
            return ToastService.Success({ message: t('MyAccount_PasswordUpdateSuccess') });
        });
        updatePasswordError.subscribe((error: any) => {
            setIsUpdatingPassword(false);
            const { status, title } = error;

            // failed - complexity policy / else current password incorrect
            if (status === 400) {
                if (title.includes('validation errors')) {
                    return setUpdatePasswordErrorMessage(t('MyAccount_PasswordRulesNotMet'));
                }
                return setUpdatePasswordErrorMessage(t('MyAccount_CurrentPasswordDoesNotMatch'));
            }
            // failed - history policy
            if (status === 409) {
                return setUpdatePasswordErrorMessage(t('MyAccount_PasswordHasBeenUsedPreviously'));
            }
            // failed - user not found
            if (status === 404) {
                return setUpdatePasswordErrorMessage(t('MyAccount_PasswordUpdateFailed'));
            }

            return ToastService.Error({ message: t('MyAccount_PasswordUpdateFailed') });
        });
        return () => {
            updatePasswordResponse.unsubscribe();
            updatePasswordError.unsubscribe();
            updatePasswordDispose();
        };
    }, []);

    const { register, getValues, handleSubmit, errors, reset, setValue, clearErrors } = useForm({
        mode: 'onBlur',
    });

    useEffect(() => {
        return () => {
            loadReset();
        };
    }, []);

    useEffect(() => {
        if (requiresLoading) {
            loadUserProfile();
            loadTenantCompanyAttributes();
            getSites();
        }
    }, [requiresLoading]);

    useEffect(() => {
        const newArr: CompanyAttributesModel[] = assignedCompanyAttributes.map((att) => {
            const tempAtt: CompanyAttributesModel = {
                companyAttributeId: att.id,
                companyAttributeValues: att.values.map((val) => {
                    return val.id;
                }),
            };
            return tempAtt;
        });
        setSelectedCompanyAttributes(newArr);
    }, [assignedCompanyAttributes]);

    const { email: userEmail } = userProfile || {};

    const onUpdatePassword = (oldPassword: string, newPassword: string): void => {
        updatePasswordRequest.next({ oldPassword, newPassword, email: userEmail });
        setUpdatePasswordErrorMessage('');
    };

    const onOpenPasswordPanel = (): void => {
        setIsPasswordPanelOpen(true);
    };

    // @todo: refactor: https://cloudbooking.atlassian.net/browse/CBR-1999
    const salutations = useFetchSalutations();

    if (isLoading || operationInProgress || !userProfile) {
        return (
            <>
                <BackgroundMask className="open" />
                <Loading />
            </>
        );
    }

    const onClose = (): void => {
        reset();
        setUpdatePasswordErrorMessage('');
        setIsPasswordPanelOpen(false);
    };

    const onSave = (data: PersonalDetailsFormState): void => {
        const {
            title: { value: selectedTitle },
            firstname,
            surname,
            telephone,
            mobile,
            countryCodeMobile,
            countryCodeTelephone,
            defaultSite: { value: gsId },
        } = data;

        const is24HoursTimeFormat = MY_ACCOUNT_24_FORMAT_VALUE === data.timeFormat.value;

        const isHourFormatChanged = is24HoursTimeFormat !== userProfile?.is24HoursTimeFormat;

        if (isHourFormatChanged) {
            ToastService.Success({ message: t('MyAccount_TimeFormatChanged') });
        }

        const updateRequest = {
            userProfileId: userProfile.userProfileId,
            title: selectedTitle,
            firstname,
            surname,
            telephone,
            mobile,
            siteId: new Guid(gsId),
            is24HoursTimeFormat,
            countryCodeMobile: countryCodeMobile.value.slice(1),
            countryCodeTelephone: countryCodeTelephone.value.slice(1),
        };

        const companyAttributesUpdateRequest = {
            userProfileId: userProfile.userProfileId.toString(),
            companyAttributes: selectedCompanyAttributes,
        };

        handleUpdate(updateRequest);
        handleCompanyAttributesUpdate(companyAttributesUpdateRequest);
    };

    const backgroundMaskClass = classNames({ open: isPasswordPanelOpen });

    const {
        title,
        firstname,
        surname,
        email,
        mobile,
        telephone,
        company,
        department,
        isSso,
        siteId,
        countryCodeMobile,
        countryCodeTelephone,
    } = userProfile || {};

    const initialValues = {
        title,
        firstname,
        surname,
        email,
        mobile,
        telephone,
        company,
        department,
        siteId,
        countryCodeMobile,
        countryCodeTelephone,
        jobTitle: 'Director of Human Resources',
        preferredLanguage: 'en-GB',
        password: '',
        confirm: '',
    };

    return (
        <>
            <AccountComponent
                initialValues={initialValues}
                salutations={salutations}
                onSave={onSave}
                onOpenPasswordPanel={onOpenPasswordPanel}
                isSso={isSso}
                sites={sites}
                assignedCompanyAttributes={assignedCompanyAttributes}
                availableCompanyAttributes={availableCompanyAttributes}
                setSelectedCompanyAttributes={setSelectedCompanyAttributes}
                selectedCompanyAttributes={selectedCompanyAttributes}
                allowEditCompanyAttributes={allowEditCompanyAttributes}
            />

            {isPasswordPanelOpen && <BackgroundMask className={backgroundMaskClass} onClick={onClose} />}

            <SlidingPanel open={isPasswordPanelOpen} title={t('MyAccount_ChangePassword')} onClose={onClose}>
                {isPasswordPanelOpen && (
                    <UpdatePassword
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        getValues={getValues}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        onUpdatePassword={onUpdatePassword}
                        isUpdatingPassword={isUpdatingPassword}
                        errorMessage={updatePasswordErrorMessage}
                    />
                )}
            </SlidingPanel>
        </>
    );
};

export default Account;
