import { combineReducers } from 'redux';

import configuration, { ConfigurationState } from './configuratioReducer';
import grid, { GridState } from './gridReducer';

interface ResourceState {
    configuration: ConfigurationState;
    grid: GridState;
}

export default combineReducers<ResourceState>({
    configuration,
    grid,
});
