import { Subject } from 'rxjs';
import { IdentityApi } from 'services/ApiClients/Identity';

export interface Request {
    oldPassword: string;
    newPassword: string;
    email: string;
}

export interface Response {
    statusCode: number;
}

export interface Error {
    message: string;
}

export interface Subjects {
    request: Subject<Request>;
    response: Subject<Response>;
    error: Subject<Error>;
    dispose: () => void;
}

export const getSubjects = (): Subjects => {
    const request = new Subject<Request>();
    const response = new Subject<Response>();
    const error = new Subject<Error>();

    const service = request.subscribe(async (req: Request) => {
        try {
            const result = await IdentityApi.updateUserPassword({ ...req });
            response.next({ statusCode: result.statusCode });
        } catch (err) {
            error.next(err.response.data);
        }
    });

    const dispose = (): void => {
        service.unsubscribe();
    };

    return { request, response, error, dispose };
};
