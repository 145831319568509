import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanySearchPanel } from '../../../components/CompanySearchPanel';
import { PersonSearchData } from '../../../components/PersonSearchPanels/interfaces/PersonSearchData';
import { VisitorSearch } from '../../../components/PersonSearchPanels/VIsitorsSearch/VisitorSearch';
import { PersonType } from '../../../enums/PersonType';
import { Person } from '../../../services/ApiClients/Models';
import { buildNameSearchQueryItems, SearchData, SearchItem } from '../../../utilities/searchUtils';
import { AllListItemOption } from '../../constants';

interface VisitorProfileFilterFormProps {
    setReportsFilter: Function;
    reportsFilter: any;
    searchInternalVisitors: Function;
    searchExternalVisitors: Function;
    searchVisitorsResult: Person[] | null;
    resetVisitorSearch: Function;
    isSearchVisitorsInProgress: boolean;
    searchCompaniesResult: string[] | null;
    searchCompanies: Function;
    isSearchCompaniesInProgress: boolean;
    resetCompaniesSearch: Function;
}

const VisitorProfileFilterForm = ({
    setReportsFilter,
    reportsFilter,
    searchInternalVisitors,
    searchExternalVisitors,
    searchVisitorsResult,
    resetVisitorSearch,
    isSearchVisitorsInProgress,
    searchCompaniesResult,
    searchCompanies,
    isSearchCompaniesInProgress,
    resetCompaniesSearch,
}: VisitorProfileFilterFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [visitorSearchData, setVisitorSearchData] = useState<any>({});
    const [companySearchData, setCompanySearchData] = useState<any>({});
    const [personType, setPersonType] = useState<PersonType>(PersonType.Internal);

    useEffect(() => {
        resetVisitorSearch();
        setVisitorSearchData({});
    }, [personType]);

    useEffect(() => {
        setReportsFilter({ companyName: AllListItemOption.label, visitor: AllListItemOption.label });
    }, []);

    const onVisitorSearch = useCallback(
        (personSearchData: PersonSearchData): void => {
            let searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>> = [];
            if (personSearchData.name) {
                searchFieldsConditionsOrUnitedByAnd = buildNameSearchQueryItems(personSearchData.name);
            }
            const searchFieldsConditionAND = [];

            if (personSearchData.email) {
                searchFieldsConditionAND.push({ field: 'Email', value: personSearchData.email });
            }

            if (reportsFilter.companyName && reportsFilter.companyName !== AllListItemOption.label) {
                searchFieldsConditionAND.push({ field: 'Company', value: reportsFilter.companyName });
            }

            const searchData: SearchData = {
                searchFieldsConditionAND,
                searchFieldsConditionsOrUnitedByAnd,
                top: 100,
                orderBy: 'Surname',
            };
            if (personType === PersonType.Internal) {
                searchInternalVisitors(searchData);
            } else {
                searchExternalVisitors(searchData);
            }
        },
        [personType, reportsFilter.companyName]
    );

    return (
        <>
            <CompanySearchPanel
                dataTestId="company"
                buttonLabel={t('Reports_Company')}
                panelLabel={t('Reports_SearchCompanies')}
                resultPlaceHolder={t('Reports_SearchForCompanies')}
                onSelect={(companyName: string) => {
                    setReportsFilter({
                        ...reportsFilter,
                        companyName: companyName !== null ? companyName : AllListItemOption.label,
                    });
                    setVisitorSearchData({ ...visitorSearchData, company: companyName });
                }}
                onSearch={(searchData: any) => searchCompanies(searchData.name)}
                onSearchDataChange={(searchValue: object) => {
                    setCompanySearchData({ ...companySearchData, ...searchValue });
                }}
                searchResult={searchCompaniesResult}
                selectedItem={reportsFilter.companyName !== AllListItemOption.label ? reportsFilter.companyName : ''}
                iaSearchInProgress={isSearchCompaniesInProgress}
                resetSearchResult={resetCompaniesSearch}
                searchData={companySearchData}
                searchFieldPlaceholder={t('Reports_Company')}
            />

            <VisitorSearch
                dataTestId="visitor"
                buttonLabel={t('Reports_Visitor')}
                panelLabel={t('Reports_SearchVisitors')}
                resultPlaceHolder={t('Reports_SearchForVisitors')}
                onSelect={(selectedPerson: Person) => {
                    setReportsFilter({
                        ...reportsFilter,
                        visitor: selectedPerson !== null ? selectedPerson : AllListItemOption.label,
                    });
                }}
                onSearch={onVisitorSearch}
                onSearchDataChange={(searchValue: object) => {
                    setVisitorSearchData({ ...visitorSearchData, ...searchValue });
                }}
                searchResult={searchVisitorsResult}
                selectedItem={reportsFilter.visitor !== AllListItemOption.label ? reportsFilter.visitor : null}
                iaSearchInProgress={isSearchVisitorsInProgress}
                resetSearchResult={resetVisitorSearch}
                searchData={visitorSearchData as PersonSearchData}
                personType={personType}
                onPersonTypeChange={setPersonType}
                showNewVisitorButton={false}
            />
        </>
    );
};

export default VisitorProfileFilterForm;
