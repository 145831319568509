import * as constants from 'features/constants';
import { combineReducers } from 'redux';
import { MyBooking } from 'services/ApiClients/Booking/Models/MyBooking';
import { createReducer } from 'typesafe-actions';

import { SiteVisit } from '../../../services/ApiClients/VisitorManagement/Models/SiteVisit';

import {
    getMyBookingsAsync,
    getMySiteVisitsAsync,
    setMyBookingsLoadStatus,
    setMySiteVisitsLoadStatus,
} from './actions';

interface MyBookingsState {
    loadStatus: string;
    bookings: MyBooking[];
    siteVisits: SiteVisit[];
    siteVisitsLoadStatus: string;
}

export default combineReducers<MyBookingsState>({
    loadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setMyBookingsLoadStatus], () => constants.LOAD_STATUSES.REQUIRED)
        .handleAction([getMyBookingsAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([getMyBookingsAsync.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([getMyBookingsAsync.failure], () => constants.LOAD_STATUSES.FAILED),

    bookings: createReducer<MyBooking[]>([])
        .handleAction([getMyBookingsAsync.success], (state: MyBooking[], action) => {
            return action.payload.payload as MyBooking[];
        })
        .handleAction([getMyBookingsAsync.failure], () => []),

    siteVisits: createReducer<SiteVisit[]>([])
        .handleAction(
            [getMySiteVisitsAsync.success],
            (state: SiteVisit[], action) => (action.payload.payload as { siteVisits: SiteVisit[] }).siteVisits
        )
        .handleAction([getMySiteVisitsAsync.failure], () => []),

    siteVisitsLoadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setMySiteVisitsLoadStatus], () => constants.LOAD_STATUSES.REQUIRED)
        .handleAction([getMySiteVisitsAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([getMySiteVisitsAsync.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([getMySiteVisitsAsync.failure], () => constants.LOAD_STATUSES.FAILED),
});
