import * as constants from 'features/constants';
import { combineReducers } from 'redux';
import { HistoryDataLog } from 'services/ApiClients/Booking';
import { HistoryDataLogs } from 'services/ApiClients/Booking/Models/HistoryDataLogs';
import { createReducer } from 'typesafe-actions';

import actions from '../actions';

export interface HistoryState {
    loadStatus: string;
    logs: HistoryDataLog[];
}

export default combineReducers<HistoryState>({
    loadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([actions.setCombinedBookingHistoryLoadStatus], (state, action) => action.payload)
        .handleAction([actions.getCombinedBookingHistoryDataAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([actions.getCombinedBookingHistoryDataAsync.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([actions.getCombinedBookingHistoryDataAsync.failure], () => constants.LOAD_STATUSES.FAILED),

    logs: createReducer<HistoryDataLog[]>([])
        .handleAction([actions.getCombinedBookingHistoryDataAsync.success], (state, action) => {
            const auditLogsResponse = action.payload.payload as HistoryDataLogs;
            return auditLogsResponse.auditLogs;
        })
        .handleAction([actions.getCombinedBookingHistoryDataAsync.failure], () => []),
});
