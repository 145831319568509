import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Back } from 'assets/icons/pagination/page-back.svg';
import { ReactComponent as Fastforward } from 'assets/icons/pagination/page-fastforward.svg';
import { ReactComponent as Forward } from 'assets/icons/pagination/page-forward.svg';
import { ReactComponent as Rewind } from 'assets/icons/pagination/page-rewind.svg';
import { ListItem, Select } from 'components/Forms';

import './Pagination.scss';

interface PaginationProps {
    pageString: string;
    total: number;
    resultPerPageOptions: ListItem[];
    selectedResultPerPage: ListItem;
    onPerPageChange: Function;
    onPageChange: Function;
}

const getItemsPageRange = (activePage: number, itemsPerPageAmount: number, itemsAmount: number): any => {
    const from = itemsAmount === 0 ? 0 : itemsPerPageAmount * (activePage - 1) + 1;

    let to = from + itemsPerPageAmount - 1;
    to = to > itemsAmount ? itemsAmount : to;

    return { from, to };
};

const customStyles = {
    control: (base: any) => ({
        ...base,
        minHeight: 24,
        height: 24,
        width: 75,
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
    }),
    menuList: (base: any) => ({
        ...base,
        width: 75,
    }),
    menu: (base: any) => ({
        ...base,
        width: 75,
    }),

    valueContainer: (base: any) => ({
        ...base,
        height: 24,
        minHeight: 24,
    }),
    singleValue: (base: any) => ({
        ...base,
        height: 24,
        minHeight: 39,
        maxWidth: 'none',
    }),
};

export default function Pagination({
    pageString,
    total,
    resultPerPageOptions,
    selectedResultPerPage,
    onPerPageChange,
    onPageChange,
}: PaginationProps): JSX.Element {
    const page = Number(pageString);
    const { t } = useTranslation();
    const totalPagesAmount = useMemo(
        () => Math.ceil(total / Number(selectedResultPerPage.value)) || 1,
        [total, selectedResultPerPage.value]
    );
    const itemsPageRange = getItemsPageRange(page || 1, Number(selectedResultPerPage.value), total);

    return (
        <div className="data-table-pagination">
            <div className="result-per-page">
                <div className="label">{t('Pagination_ResultsPerPage')}:</div>
                <Select
                    name=""
                    styles={customStyles}
                    id="per-page"
                    value={selectedResultPerPage}
                    items={resultPerPageOptions}
                    className="per-page"
                    onChange={(value) => onPerPageChange(value)}
                    isSearchable={false}
                />
            </div>
            <div className="page-navigation">
                <IconButton className="first-page-btn" onClick={() => onPageChange(1)} disabled={!page || page === 1}>
                    <Rewind />
                </IconButton>
                <IconButton
                    className="prev-page-btn"
                    onClick={() => onPageChange(page - 1)}
                    disabled={!page || page === 1}
                >
                    <Back />
                </IconButton>
                <input
                    className="page-input"
                    type="text"
                    value={pageString}
                    onChange={() => {}}
                    onInput={(e: any) => {
                        const re = /^[1-9\b]+$/;
                        if (
                            e.target.value === '' ||
                            (re.test(e.target.value) && Number(e.target.value) <= totalPagesAmount)
                        ) {
                            onPageChange(e.target.value);
                        }
                    }}
                />
                <div className="total">
                    <ReactMarkdown source={`${t('Pagination_Of')} **${totalPagesAmount}**`} />
                </div>
                <IconButton
                    className="next-page-btn"
                    onClick={() => onPageChange(page + 1)}
                    disabled={!page || page === totalPagesAmount}
                >
                    <Forward />
                </IconButton>
                <IconButton
                    className="last-page-btn"
                    onClick={() => onPageChange(totalPagesAmount)}
                    disabled={!page || page === totalPagesAmount}
                >
                    <Fastforward />
                </IconButton>
            </div>

            <div className="result">
                <ReactMarkdown
                    source={`${t('Pagination_Showing')} **${itemsPageRange.from}**-**${itemsPageRange.to}** ${t(
                        'Pagination_Of'
                    )} ${total} ${t('Pagination_Results')}`}
                />
            </div>
        </div>
    );
}
