import React from 'react';
import GeographicStructureSelector from 'features/GeographicStructures/Components/GeographicStructureSelector';
import LanguageSelector from 'features/Languages/Components/LanguageSelector';

import Logo from './Components/Logo';
import UserProfileSummary from './Components/UserProfileSummary';

import './SiteHeader.scss';

const SiteHeader = (): JSX.Element => {
    return (
        <header className="siteHeader">
            <Logo />
            <GeographicStructureSelector />
            <LanguageSelector />
            <UserProfileSummary />
        </header>
    );
};

export default SiteHeader;
