import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { TENANT_IDENTIFIER_KEY } from '../../constants';

interface TenantRedirectRouteProps {
    children?: JSX.Element;
}

const TenantRedirectRoute = ({ children }: TenantRedirectRouteProps): JSX.Element | null => {
    const tenant = localStorage.getItem(TENANT_IDENTIFIER_KEY);

    const location = useLocation();

    const matched = !!tenant && `/${tenant.toLocaleLowerCase()}` === location.pathname.toLocaleLowerCase();

    return (
        <>
            {children}
            {matched && <Navigate to="/" replace />}
        </>
    );
};

export default TenantRedirectRoute;
