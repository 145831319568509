import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/FormElements/Button';
import { Select } from 'components/Forms';
import { TIME_FORMATS } from 'features/constants';

// eslint-disable-next-line import/no-cycle
import { SettingsProps } from '.';

import './Settings.scss';

const Settings = ({
    isSso,
    control,
    initialValues,
    defaultHourFormat,
    onOpenPasswordPanel,
    sites,
}: SettingsProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="account_settings">
            <h2>{t('MyAccount_SettingsTitle')}</h2>
            <div className="row">
                <Select
                    label={t('MyAccount_DefaultSite')}
                    name="defaultSite"
                    items={sites}
                    initialValue={initialValues.siteId}
                    className="defaultSite"
                    control={control}
                    id="myAccount-defaultSite"
                />
            </div>
            <div className="row">
                <Select
                    label={t('MyAccount_TimeFormat')}
                    name="timeFormat"
                    items={TIME_FORMATS}
                    initialValue={defaultHourFormat}
                    className="timeFormat"
                    control={control}
                    id="myAccount-timeFormat"
                />
            </div>

            {isSso === 'False' && (
                <>
                    <hr />
                    <div className="account_password">
                        <h2>{t('MyAccount_Password')}</h2>
                        <div className="row">
                            <div className="inputWrapper">
                                <Button
                                    type="button"
                                    className="openUpdatePassword"
                                    dataTestId="open-updatePassword"
                                    onClick={onOpenPasswordPanel}
                                    text={t('MyAccount_ChangePassword')}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Settings;
