import audioConferencingIcon from './AudioConferencing.svg';
import networkingIcon from './Networking.svg';
import otherIcon from './Other.svg';
import pcIcon from './PC.svg';
import restrictedIcon from './Restricted.svg';
import restrictedToIcon from './RestrictedTo.svg';
import settingsIcon from './Settings.svg';
import telephoneIcon from './Telephone.svg';
import videoConferencingIcon from './VideoConferencing.svg';
import wheelchairIcon from './Wheelchair.svg';

export default {
    audioConferencingIcon,
    networkingIcon,
    otherIcon,
    pcIcon,
    restrictedIcon,
    restrictedToIcon,
    settingsIcon,
    telephoneIcon,
    videoConferencingIcon,
    wheelchairIcon,
};
