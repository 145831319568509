import React from 'react';
import cn from 'classnames';

import './SearchResultItem.scss';

interface SearchResultItemProps {
    onSelect: Function;
    item: string;
}

const SearchResultItem = ({ onSelect, item }: SearchResultItemProps): JSX.Element => {
    return (
        <div
            className={cn('search-result-item')}
            onClick={() => (() => onSelect(item))()}
            data-testid="search-result-item"
        >
            <div className="search-item">
                <div className="search-item-info">{item}</div>
            </div>
        </div>
    );
};

export default SearchResultItem;
