import React, { useEffect, useState } from 'react';
import { TextBox } from 'components/FormElements';

import Header from '../../components/SiteSubHeader';

import './Analytics.scss';

const Analytics = (): JSX.Element => {
    const [dashboardUrl, setDashboardUrl] = useState<string>(
        'https://cloudbooking.sisensepoc.com/app/main/dashboards/638e7124fd753f003734c3a6?embed=true'
    );
    const [showURLField, setShowURLField] = useState<boolean>(false);
    const [initialLoad, setInitialLoad] = useState<boolean>(true);

    const toggleURLField = (): void => {
        setShowURLField(!showURLField);
    };

    useEffect(() => {
        const storedURL = window.localStorage.getItem('analyticsDashboardUrl')?.toString();
        if (!initialLoad) {
            setDashboardUrl(
                'https://cloudbooking.sisensepoc.com/app/main/dashboards/638e7124fd753f003734c3a6?embed=true'
            );
        } else {
            // eslint-disable-next-line no-lonely-if
            if (storedURL !== undefined) {
                setDashboardUrl(storedURL);
            }
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem('analyticsDashboardUrl', dashboardUrl);
        setInitialLoad(false);
    }, [dashboardUrl]);

    return (
        <main className="reports-page">
            <Header title="Analytics" dataTestId="analytics-title" onClick={toggleURLField} />
            {showURLField && (
                <div className="type-n-date">
                    <TextBox
                        label="Dashboard URL"
                        value={dashboardUrl}
                        dataTestId="analytics-dashboardurl"
                        className="dashboardurl"
                        maxLength={500}
                        onChange={(value: string) => {
                            setDashboardUrl(value);
                        }}
                    />
                </div>
            )}
            <div className="report-filters">
                <iframe title="dashboard" width="100%" height="100%" src={dashboardUrl} />
            </div>
        </main>
    );
};

export default Analytics;
