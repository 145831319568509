import React from 'react';
import Home from 'features/Home';
import MainLayout from 'layouts/MainLayout/MainLayout';

// @todo: These route files are responsible for connected the feature components to redux stores, and do
// anything that's required to pass models into the view(s)
const HomeRoute = (): JSX.Element => {
    return (
        <MainLayout>
            <Home />
        </MainLayout>
    );
};

export default HomeRoute;
