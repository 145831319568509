import DateTimePickerValue from 'components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import {
    CHECK_IN_STATUS,
    DEFAULT_DATE_FORMAT,
    LUXON_DEFAULT_DATE_TIME_FORMAT,
    MONTH_REPEAT_INDEXES,
    MONTH_REPEAT_TYPE,
    REPEAT,
    REPEAT_ON_DAY,
} from 'features/constants';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import { DateTime as DateTimeLuxon, Interval } from 'luxon';
import {
    BookedResource,
    BookedResourceSummary,
    Booking,
    Exclusion,
    PendingResourceChanges,
    RepeatSchedule,
} from 'services/ApiClients/Booking';
import DateTimeService from 'services/DateTimeService';
import Guid from 'utilities/guid';
import { isNil } from 'utilities/ts';

import { getDayInMonthNumber } from '../../../../../utilities/dateTimeUtils';

export interface RepeatValidationObject {
    message: string;
    className: string;
}

export const DATE_TIME_DROPDOWN_CLASS_NAME_ERROR = 'date-time-error';
export const DATE_DROPDOWN_CLASS_NAME_ERROR = 'date-error';
export const DAYS_CLASS_NAME_ERROR = 'days-error';

export type DateTime = {
    startDate: DateTimeLuxon;
    endDate: DateTimeLuxon;
};

export enum monthRadioOptions {
    absolute = 0,
    relativeFirst,
    relativeSecond,
}

export const validateRepeatData = (dateTime: DateTime, daysOfWeek: number[] | null): RepeatValidationObject | null => {
    if (
        DateTimeService.getTimeSlotFromDate(dateTime.startDate) >
        DateTimeService.getTimeSlotFromDate(dateTime.endDate, 15, true)
    ) {
        return {
            message: 'The end time cannot be before the start time',
            className: DATE_TIME_DROPDOWN_CLASS_NAME_ERROR,
        };
    }
    if (
        DateTimeService.getTimeSlotFromDate(dateTime.startDate) ===
        DateTimeService.getTimeSlotFromDate(dateTime.endDate, 15, true)
    ) {
        return {
            message: 'The start time must be before the end time',
            className: DATE_TIME_DROPDOWN_CLASS_NAME_ERROR,
        };
    }

    if (dateTime.endDate.toFormat(DEFAULT_DATE_FORMAT) === dateTime.startDate.toFormat(DEFAULT_DATE_FORMAT)) {
        return {
            message: 'A repeat series needs to be longer than one day. Please review and try again. ',
            className: DATE_DROPDOWN_CLASS_NAME_ERROR,
        };
    }

    if (dateTime.endDate.toFormat(DEFAULT_DATE_FORMAT) < dateTime.startDate.toFormat(DEFAULT_DATE_FORMAT)) {
        return { message: 'The end date cannot be before the start date', className: DATE_DROPDOWN_CLASS_NAME_ERROR };
    }

    if (isEmpty(daysOfWeek) && !isNil(daysOfWeek)) {
        return { message: 'At least one day should be selected', className: DAYS_CLASS_NAME_ERROR };
    }

    return null;
};

export type RepeatState = {
    repeat: { [k: string]: any };
    everyRepeat: number;
    isStartDatePicked: boolean;
    isEndDatePicked: boolean;
    date: DateTime;
    checkedDays: string[] | null;
    validationObject: RepeatValidationObject | null;
    monthRepeatSubType: MONTH_REPEAT_TYPE | null;
    monthRepeatIndex: MONTH_REPEAT_INDEXES | null;
    monthRepeatDayInWeek: number | null;
    monthRepeatRadioOption: monthRadioOptions;
};

const getMonthlyRadioButtonValue = (dayOfWeekInMonthNumber: number): monthRadioOptions => {
    if (dayOfWeekInMonthNumber !== 5) {
        return monthRadioOptions.relativeFirst;
    }

    if (dayOfWeekInMonthNumber >= 4) {
        return monthRadioOptions.relativeSecond;
    }

    return monthRadioOptions.absolute;
};

export const formatScheduleState = (schedule: RepeatSchedule, dateTime: DateTime): RepeatState => {
    if (schedule) {
        const type = schedule.pattern.type.toString();
        const repeatKey =
            Object.keys(REPEAT).find(
                (key) =>
                    REPEAT[key].type === type || (Array.isArray(REPEAT[key].type) && REPEAT[key].type.includes(type))
            ) || '';

        const checkedDays = isEmpty(schedule.pattern.daysOfWeek)
            ? null
            : Object.keys(REPEAT_ON_DAY).reduce((acc: string[], day: string, index: number) => {
                  return schedule.pattern.daysOfWeek?.includes(index) ? [...acc, day] : [...acc];
              }, []);

        const dayOfWeekInMonthNumber = getDayInMonthNumber(DateTimeLuxon.fromISO(schedule.range.start));

        return {
            repeat: REPEAT[repeatKey],
            everyRepeat: schedule.pattern.interval,
            isStartDatePicked: false,
            isEndDatePicked: false,
            date: {
                startDate: DateTimeLuxon.fromISO(schedule.range.start),
                endDate: DateTimeLuxon.fromISO(schedule.range.end),
            },
            checkedDays,
            validationObject: null,
            monthRepeatSubType: REPEAT.MONTH.type.includes(type) ? Number(type) : null,
            monthRepeatIndex: schedule.pattern.index as MONTH_REPEAT_INDEXES,
            monthRepeatDayInWeek: null,
            monthRepeatRadioOption: getMonthlyRadioButtonValue(dayOfWeekInMonthNumber),
        };
    }

    return {
        repeat: REPEAT.WEEK,
        everyRepeat: 1,
        isStartDatePicked: false,
        isEndDatePicked: false,
        date: dateTime,
        checkedDays: [dateTime.startDate.toFormat('cccc')],
        validationObject: null,
        monthRepeatSubType: MONTH_REPEAT_TYPE.ABSOLUTE,
        monthRepeatIndex: null,
        monthRepeatDayInWeek: null,
        monthRepeatRadioOption: monthRadioOptions.absolute,
    };
};

export const formatMonthRadioButtons = (dateTime: DateTimeLuxon, t: TFunction): any => {
    const dayNumber = dateTime.toFormat('d');
    const dayOfWeekInMonthNumber = getDayInMonthNumber(dateTime);

    const absoluteLabel = `${t('Repeat_MonthOnDay')} ${dayNumber}`;
    const dayName = dateTime.toFormat('cccc');
    let relativeLabel = '';

    switch (dayOfWeekInMonthNumber) {
        case 1:
            relativeLabel = `${t('Repeat_OnThe')} ${t('Repeat_First')} ${dayName}`;
            break;
        case 2:
            relativeLabel = `${t('Repeat_OnThe')} ${t('Repeat_Second')} ${dayName}`;
            break;
        case 3:
            relativeLabel = `${t('Repeat_OnThe')} ${t('Repeat_Third')} ${dayName}`;
            break;
        case 4:
            relativeLabel = `${t('Repeat_OnThe')} ${t('Repeat_Fourth')} ${dayName}`;
            break;
        default:
            break;
    }

    const thirdOptionLabel = `${t('Repeat_OnThe')} ${t('Repeat_Last')} ${dayName}`;

    const radioButtons = [
        {
            value: monthRadioOptions.absolute,
            label: absoluteLabel,
            dataTestId: 'radio-btn-absolute',
        },
    ];

    if (dayOfWeekInMonthNumber !== 5) {
        radioButtons.push({
            value: monthRadioOptions.relativeFirst,
            label: relativeLabel,
            dataTestId: 'radio-btn-relative',
        });
    }

    if (dayOfWeekInMonthNumber >= 4) {
        radioButtons.push({
            value: monthRadioOptions.relativeSecond,
            label: thirdOptionLabel,
            dataTestId: 'radio-btn-relative',
        });
    }

    return radioButtons;
};

export const getMonthRepeatIndex = (dateTime: DateTimeLuxon): MONTH_REPEAT_INDEXES | null => {
    const dayOfWeekInMonthNumber = getDayInMonthNumber(dateTime);

    switch (dayOfWeekInMonthNumber) {
        case 1:
            return MONTH_REPEAT_INDEXES.first;
        case 2:
            return MONTH_REPEAT_INDEXES.second;
        case 3:
            return MONTH_REPEAT_INDEXES.third;
        case 4:
            return MONTH_REPEAT_INDEXES.fourth;
        default:
            return null;
    }
};

export const getBookedResource = (
    booking: Booking | null,
    selectedDate: DateTimeLuxon,
    timezone: string
): BookedResource | undefined => {
    // TODO: Temporary fix
    return booking?.isRepeat && selectedDate.isValid
        ? booking?.resources.find((resource) => {
              return DateTimeLuxon.fromISO(resource.startDateTime).setZone(timezone).hasSame(selectedDate, 'day');
          }) || booking?.resources[0]
        : booking?.resources[0];
};

export const getDateTime = (
    isPendingChange: boolean,
    pendingResourceChange?: PendingResourceChanges,
    exclusion?: Exclusion,
    bookedResource?: BookedResource
): any => {
    if (isPendingChange) {
        return {
            startDateTime: pendingResourceChange?.startDateTime || bookedResource?.startDateTime,
            endDateTime: pendingResourceChange?.endDateTime || bookedResource?.endDateTime,
        };
    }

    if (!isNil(exclusion)) {
        if (!isNil(exclusion?.updatedTimeRange)) {
            return {
                startDateTime: exclusion?.updatedTimeRange?.start,
                endDateTime: exclusion?.updatedTimeRange?.end,
            };
        }

        if (!isNil(exclusion?.timeRange)) {
            return {
                startDateTime: exclusion?.timeRange?.start,
                endDateTime: exclusion?.timeRange?.end,
            };
        }
    }

    return {
        startDateTime: bookedResource?.startDateTime,
        endDateTime: bookedResource?.endDateTime,
    };
};

export const formatDateTimePickerValue = (
    booking: Booking | null,
    timezone: string,
    selectedDate: DateTimeLuxon
): DateTimePickerValue => {
    const pendingResourceChange = booking?.pendingResourceChanges[0];
    const bookedResource = getBookedResource(booking, selectedDate, timezone);
    const exclusion = booking?.repeatSchedule?.exclusions?.find(
        (item) =>
            DateTimeLuxon.fromISO(item?.timeRange?.start).setZone(timezone).hasSame(selectedDate, 'day') ||
            (item?.updatedTimeRange &&
                DateTimeLuxon.fromISO(item?.updatedTimeRange?.start).setZone(timezone).hasSame(selectedDate, 'day'))
    );
    const isPendingChange = !isEmpty(pendingResourceChange);
    const dateTime = getDateTime(isPendingChange, pendingResourceChange, exclusion, bookedResource);

    return {
        startDateTime: DateTimeLuxon.fromISO(dateTime.startDateTime, { zone: timezone }),
        endDateTime: DateTimeLuxon.fromISO(dateTime.endDateTime, {
            zone: timezone,
        }),
        isAM: false,
        isPM: false,
        isAllDay: false,
    };
};

export const formatRepeatScheduleRange = (schedule: RepeatSchedule): RepeatSchedule | null => {
    const startHours = DateTimeLuxon.fromISO(schedule?.bookedTimeRange?.start).hour;
    const startMinutes = DateTimeLuxon.fromISO(schedule?.bookedTimeRange?.start).minute;
    const endHours = DateTimeLuxon.fromISO(schedule?.bookedTimeRange?.end).hour;
    const endMinutes = DateTimeLuxon.fromISO(schedule?.bookedTimeRange?.end).minute;

    return schedule
        ? {
              ...schedule,
              range: {
                  start: DateTimeLuxon.fromISO(schedule?.range.start, { setZone: true })
                      .plus({ hours: startHours, minutes: startMinutes })
                      .toFormat(LUXON_DEFAULT_DATE_TIME_FORMAT),
                  end: DateTimeLuxon.fromISO(schedule?.range.end, { setZone: true })
                      .plus({ hours: endHours, minutes: endMinutes })
                      .toFormat(LUXON_DEFAULT_DATE_TIME_FORMAT),
              },
          }
        : null;
};

export const getEarlyCheckInBookings = (
    bookings: BookedResourceSummary[],
    now: DateTimeLuxon,
    timezone: string
): BookedResourceSummary[] | [] => {
    const mappedEarlyCheckInBookingWithCleardown = bookings.map((booking) => {
        const endDate = DateTimeLuxon.fromISO(booking.endDateTime);
        return {
            ...booking,
            endDateTime: endDate.plus({ seconds: booking?.cleardownTimeInSeconds || 0 }).toString(),
        };
    });

    const filteredEarlyCheckInBookings = mappedEarlyCheckInBookingWithCleardown.filter(
        (booking: BookedResourceSummary) => {
            const startDate = DateTimeLuxon.fromISO(booking.startDateTime).setZone(timezone);
            const endDate = DateTimeLuxon.fromISO(booking.endDateTime).setZone(timezone);
            const earlyCheckedInBookingStart = startDate.minus({ minutes: 30 });

            return (
                (Interval.fromDateTimes(startDate, endDate).contains(now) ||
                    Interval.fromDateTimes(earlyCheckedInBookingStart, startDate).contains(now)) &&
                booking.checkInStatus !== CHECK_IN_STATUS.CHECKED_OUT
            );
        }
    );
    const sortedEarlyCheckInBooking =
        filteredEarlyCheckInBookings.length > 1
            ? filteredEarlyCheckInBookings.sort(
                  (a, b) =>
                      DateTimeLuxon.fromISO(a.startDateTime).toMillis() -
                      DateTimeLuxon.fromISO(b.startDateTime).toMillis()
              )
            : filteredEarlyCheckInBookings;
    return sortedEarlyCheckInBooking;
};

export const getIsShowCheckInOutBookingButtons = (
    isEarlyCheckedInBooking: boolean,
    isBookingInProgress: boolean,
    bookings: BookedResourceSummary[],
    now: DateTimeLuxon,
    timezone: string,
    currentBookingId: Guid,
    startDateTime: string
): boolean => {
    if (isEarlyCheckedInBooking || isBookingInProgress) {
        const earlyBookings = getEarlyCheckInBookings(bookings, now, timezone);

        if (earlyBookings?.length < 2 || earlyBookings[0]?.bookingId === currentBookingId) {
            return true;
        }

        const firstBookingEndInMillis = DateTimeLuxon.fromISO(earlyBookings[0]?.endDateTime).toMillis();
        const currentBookingStartInMillis = DateTimeLuxon.fromISO(startDateTime).toMillis();

        return !(currentBookingStartInMillis >= firstBookingEndInMillis);
    }
    return false;
};
