import { BOOKING_STATUS } from 'features/constants';

enum BookMode {
    Create,
    Edit,
    UpdatePending,
}

export const getBookMode = (status: string | undefined): BookMode => {
    if (status === BOOKING_STATUS.CONFIRMED || status === BOOKING_STATUS.CURTAILED) {
        return BookMode.Edit;
    }

    if (status === BOOKING_STATUS.UPDATE_PENDING) {
        return BookMode.UpdatePending;
    }

    return BookMode.Create;
};

export default BookMode;
