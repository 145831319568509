import React from 'react';
import { ReactComponent as DangerIcon } from 'assets/icons/DangerIcon.svg';
import classNames from 'classnames';
import ActionButtons from 'components/ActionButtons';
import ActionButton from 'components/ActionButtons/Models/ActionButton';
import BackgroundMask from 'components/BackgroundMask';

import { ReactComponent as CloseIcon } from '../../assets/icons/ClosePanel.svg';
import Button from '../FormElements/Button';

import './Dialog.scss';

export interface DialogProps {
    message: string;
    className?: string;
    description?: string;
    buttons?: ActionButton[];
    customButtons?: JSX.Element | null;
    closeDialog?: Function;
    showCloseIcon?: boolean;
    buttonsClassName?: string;
}

const Dialog = ({
    message,
    className,
    buttons,
    description,
    customButtons,
    closeDialog,
    showCloseIcon,
    buttonsClassName,
}: DialogProps): JSX.Element => {
    return (
        <>
            <div className={classNames('dialog', className)} role="dialog" aria-labelledby="dialogText">
                {showCloseIcon && (
                    <Button
                        className="close"
                        type="button"
                        iconOnly
                        icon={CloseIcon}
                        onClick={() => {
                            if (closeDialog) {
                                closeDialog();
                            }
                        }}
                        dataTestId="close-dialog"
                    />
                )}
                <DangerIcon />
                <div className="message">
                    <h1 id="dialogText">{message}</h1>
                    {description && (
                        <h2 id="dialogDescription" className="description">
                            {description}
                        </h2>
                    )}
                </div>
                <div className="buttons">
                    {customButtons || (
                        <ActionButtons buttonsClassName={buttonsClassName} buttons={buttons} disabled={false} />
                    )}
                </div>
            </div>
            <BackgroundMask className="open" />
        </>
    );
};

export default Dialog;
