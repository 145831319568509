import { connect } from 'react-redux';
import { AccountState } from 'features/Account/Models/AccountState';
import { RootState } from 'PortalTypes';
import {
    CompanyAttributesErrorModel,
    CompanyAttributesModel,
} from 'services/ApiClients/Identity/Models/UpdateUserProfileCommand';
import { SpecifiedCompanyAttributesResponse } from 'services/ApiClients/Organization/Models/CompanyAttributes';

// eslint-disable-next-line import/no-cycle
import CompanyAttributes from './CompanyAttributes';

interface PassedProps {
    initialValues: AccountState;
    assignedCompanyAttributes: SpecifiedCompanyAttributesResponse[];
    availableCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
    selectedCompanyAttributes: CompanyAttributesModel[];
    setSelectedCompanyAttributes: any;
    setCompanyAttributeErrors: any;
    companyAttributeErrors: CompanyAttributesErrorModel[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps: PassedProps) => ({
    ...ownProps,
    allowEditCompanyAttributes: state.referenceData.tenantInfo?.areUsersAllowedToEditTheirAttributes,
});

const mapDispatchToProps = {};

export type CompanyAttributesProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(CompanyAttributes);
