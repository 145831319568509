import React from 'react';
import { Route } from 'react-router-dom';
import AuthenticationLayout from 'layouts/AuthenticationLayout';

import Login from './Components/Login';
import Logout from './Components/Logout';
import { ApplicationPaths, LoginActions, LogoutActions } from './constants';

const routes: JSX.Element[] = [
    <Route
        path={ApplicationPaths.Login}
        element={
            <AuthenticationLayout>
                <Login action={LoginActions.Login} />
            </AuthenticationLayout>
        }
        key={LoginActions.Login}
    />,
    <Route
        path={ApplicationPaths.LoginFailed}
        element={
            <AuthenticationLayout>
                <Login action={LoginActions.LoginFailed} />
            </AuthenticationLayout>
        }
        key={LoginActions.LoginFailed}
    />,
    <Route
        path={ApplicationPaths.LoginCallback}
        element={
            <AuthenticationLayout>
                <Login action={LoginActions.LoginCallback} />
            </AuthenticationLayout>
        }
        key={LoginActions.LoginCallback}
    />,
    <Route
        path={ApplicationPaths.Profile}
        element={
            <AuthenticationLayout>
                <Login action={LoginActions.Profile} />
            </AuthenticationLayout>
        }
        key={LoginActions.Profile}
    />,
    <Route
        path={ApplicationPaths.Register}
        element={
            <AuthenticationLayout>
                <Login action={LoginActions.Register} />
            </AuthenticationLayout>
        }
        key={LoginActions.Register}
    />,
    <Route
        path={ApplicationPaths.LogOut}
        element={
            <AuthenticationLayout>
                <Logout action={LogoutActions.Logout} />
            </AuthenticationLayout>
        }
        key={LogoutActions.Logout}
    />,
    <Route
        path={ApplicationPaths.LogOutCallback}
        element={
            <AuthenticationLayout>
                <Logout action={LogoutActions.LogoutCallback} />
            </AuthenticationLayout>
        }
        key={LogoutActions.LogoutCallback}
    />,
    <Route
        path={ApplicationPaths.LoggedOut}
        element={
            <AuthenticationLayout>
                <Logout action={LogoutActions.LoggedOut} />
            </AuthenticationLayout>
        }
        key={LogoutActions.LoggedOut}
    />,
];

export default routes;
