import React, { memo } from 'react';
import Navigation from 'components/SiteNavigation';
import { history } from 'store';

import { ComponentProps } from './types';

const SiteNavigation = ({
    isOpen,
    initialised,
    access,
    tenantInfo,
    selectedSite,
    toggle,
    initialise,
    permissions,
}: ComponentProps): JSX.Element => {
    if (!initialised) {
        toggle(history.location.pathname === '/');
        initialise();
    }

    return (
        <>
            <Navigation
                isOpen={isOpen}
                onToggle={() => toggle(!isOpen)}
                access={access}
                tenantInfo={tenantInfo}
                selectedSite={selectedSite}
                permissions={permissions}
            />
        </>
    );
};

export default memo(SiteNavigation);
