import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';
import Guid from 'utilities/guid';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';

// eslint-disable-next-line import/no-cycle
import { GetBookingResourceQuery, Resource, ResourceImage } from './Models/Resource';
// eslint-disable-next-line import/no-cycle
import { ResourcesGS } from './Models/ResourcesGS';

export class ResourceApi extends BaseApiClient {
    public static BuildBaseUrl = (version: number): string => {
        const baseUrl = getConfiguration().RESOURCE_API;

        return `${baseUrl}/api/v${version}`;
    };

    /**
     * Retrives details of a single specific resource
     *
     * @param {Guid} resourceId
     * @returns {Promise<Resource>}
     */
    public static getResource = async (resourceId: Guid): Promise<ApiResponse<Resource>> => {
        return ResourceApi.get<Resource>(`${this.BuildBaseUrl(1)}/Resource/${resourceId.toString()}`);
    };

    /**
     * Retrives details of a single specific resource
     *
     * @param {GetBookingResourceQuery} query
     * @returns {Promise<Resource>}
     */
    public static getBookingResource = async (query: GetBookingResourceQuery): Promise<ApiResponse<Resource>> => {
        return ResourceApi.get<Resource>(
            `${this.BuildBaseUrl(1)}/Resource/${query.resourceType}/${query.resourceId.toString()}`
        );
    };

    /**
     * Retrives details of a single specific resource image
     *
     * @param {Guid} resourceImageId
     * @returns {Promise<ResourceImage>}
     */
    public static getResourceImage = async (resourceImageId: Guid): Promise<ApiResponse<ResourceImage>> => {
        return ResourceApi.get<ResourceImage>(`${this.BuildBaseUrl(2)}/ResourceImage/${resourceImageId.toString()}`);
    };

    /**
     * Retrives details of a set of resources that exist within a specific geogrpahic structure id or its immediate children
     *
     * @param {String} resourceType
     * @param {Guid} resourceId
     * @returns {Promise<Resource>}
     */
    public static getResourcesByGeographicStructureId = async (
        geographicStructureIds: Guid[],
        resourceTypes: number[]
    ): Promise<ApiResponse<ResourcesGS[]>> => {
        const areaIds = geographicStructureIds.map((areaId) => `areaId=${areaId.toString()}`).join('&');
        const resourceTypeIds = resourceTypes.map((id) => `resourceType=${id.toString()}`).join('&');

        LoggingService.Debug('Sending Resource.getByGeographicStructureId query. Request: ', {
            areaIds,
            resourceTypeIds,
        });
        LoggingService.Debug(
            'Sending Resource.getByGeographicStructureId query. Headers: ',
            ResourceApi.PersistedHeaders
        );
        return ResourceApi.get<ResourcesGS[]>(
            `${this.BuildBaseUrl(1)}/Resource/byGeographicStructureId?${areaIds}&${resourceTypeIds}`
        );
    };
}
