import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import ResourceBookingsReport from '../../features/Reports/ResourceBookingsReport';

const ResourceBookings = (): JSX.Element => {
    return (
        <MainLayout>
            <ResourceBookingsReport />
        </MainLayout>
    );
};

export default ResourceBookings;
