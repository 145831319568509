import { RootEpic } from 'PortalTypes';
import { replace } from 'redux-first-history';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { loginSuccess } from './actions';

export const loginSuccessEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf(loginSuccess)),
        mergeMap((action) => {
            // eslint-disable-next-line no-useless-escape
            const pathName = action.payload.replace(/^.*\/\/[^\/]+/, '');

            return [replace({ pathname: pathName })];
        })
    );
