import React, { FunctionComponent, MouseEvent } from 'react';
import classNames from 'classnames';
import { isNil } from 'utilities/ts';

export interface TabProps {
    icon: FunctionComponent | null;
    tabButtonLabel?: JSX.Element;
    id: string;
    title: string | null;
    selected: boolean;
    dataTestId: string;
    onClick(tabId: string): void;
}

const Tabs = ({
    icon: Icon,
    title = '',
    selected,
    id,
    dataTestId,
    onClick,
    tabButtonLabel: TabButtonLabel,
}: TabProps): JSX.Element => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
        if (isNil(onClick)) return;
        onClick(e.currentTarget.value);
    };

    const defaultButtonLabelElement = (
        <>
            {Icon && <Icon />} {title}
        </>
    );
    return (
        <button
            type="button"
            className={classNames('tab', { selected })}
            onClick={handleClick}
            value={id}
            data-testid={dataTestId}
        >
            {TabButtonLabel || defaultButtonLabelElement}
        </button>
    );
};

export default Tabs;
