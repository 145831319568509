import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';
import Guid from 'utilities/guid';

import BaseApiClient from '../BaseApiClient';

import { GeographicStructureItem } from './Models/GeographicStructureItem';

export class OrganisationStructureApi extends BaseApiClient {
    public static BuildBaseUrl = (version: number): string => {
        const baseUrl = getConfiguration().ORGANISATION_STRUCTURE_API;

        return `${baseUrl}/api/v${version}`;
    };

    public static getGeographicStructureChildren = async (
        gsId: Guid
    ): Promise<ApiResponse<GeographicStructureItem[]>> => {
        LoggingService.Debug('Sending OrganisationStructure.getGeographicStructureChildren command. Request: ');
        LoggingService.Debug(
            'Sending OrganisationStructure.getGeographicStructureChildren command. Headers: ',
            OrganisationStructureApi.PersistedHeaders
        );
        return OrganisationStructureApi.get<GeographicStructureItem[]>(
            `${this.BuildBaseUrl(1)}/Geostructures/${gsId.toString()}/children`
        );
    };

    public static getGeographicStructureById = async (gsId: Guid): Promise<ApiResponse<GeographicStructureItem>> => {
        LoggingService.Debug('Sending OrganisationStructure.getGeographicStructureById command. Request: ');
        LoggingService.Debug(
            'Sending OrganisationStructure.getGeographicStructureById command. Headers: ',
            OrganisationStructureApi.PersistedHeaders
        );

        return OrganisationStructureApi.get<GeographicStructureItem>(
            `${this.BuildBaseUrl(2)}/Geostructures/${gsId.toString()}`
        );
    };

    public static getGeographicStructureSiteById = async (
        gsId: Guid
    ): Promise<ApiResponse<GeographicStructureItem>> => {
        LoggingService.Debug('Sending OrganisationStructure.getGeographicStructureSiteById command. Request: ');
        LoggingService.Debug(
            'Sending OrganisationStructure.getGeographicStructureSiteById command. Headers: ',
            OrganisationStructureApi.PersistedHeaders
        );

        return OrganisationStructureApi.get<GeographicStructureItem>(
            `${this.BuildBaseUrl(2)}/Sites/${gsId.toString()}`
        );
    };

    public static getGeographicStructureAllSites = async (): Promise<ApiResponse<GeographicStructureItem[]>> => {
        LoggingService.Debug('Sending OrganisationStructure.getGeographicStructureAllSites command. Request: ');
        LoggingService.Debug(
            'Sending OrganisationStructure.getGeographicStructureAllSites command. Headers: ',
            OrganisationStructureApi.PersistedHeaders
        );

        return OrganisationStructureApi.get<GeographicStructureItem[]>(`${this.BuildBaseUrl(1)}/Sites`);
    };

    public static getGeographicStructureSiteImageById = async (gsId: Guid): Promise<ApiResponse<string>> => {
        LoggingService.Debug('Sending OrganisationStructure.getGeographicStructureSiteImageById command. Request: ');
        LoggingService.Debug(
            'Sending OrganisationStructure.getGeographicStructureSiteImageById command. Headers: ',
            OrganisationStructureApi.PersistedHeaders
        );

        return OrganisationStructureApi.get<string>(`${this.BuildBaseUrl(2)}/site/${gsId.toString()}/image`);
    };
}
