import { AxiosError } from 'axios';
import * as constants from 'features/constants';
import { getGeographicStructureAreas } from 'features/GeographicStructures/Redux/actions';
import {
    getResources,
    updateBookedResourceForBookingAsync,
    updateSingleOccurrenceAsync,
    updateSingleOccurrenceForBookingInfoAsync,
} from 'features/Resources/Combined/Redux/actions';
import { getSiteConfigurationAsync } from 'features/Resources/Common/Redux/actions';
import { combineReducers } from 'redux';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { Booking } from 'services/ApiClients/Booking/Models';
import { Occurrence } from 'services/ApiClients/Booking/Models/Occurrence';
import { PersonById } from 'services/ApiClients/Person/Models/PersonById';
import { CombinedResource, ResourceImage } from 'services/ApiClients/Resource';
import { createReducer } from 'typesafe-actions';

import { UserRole } from '../../../../../../services/ApiClients/Identity/Models/UserRole';
import { Person, PersonsResponse } from '../../../../../../services/ApiClients/Models';
import { SpecifiedCompanyAttributesResponse } from '../../../../../../services/ApiClients/Organization/Models/CompanyAttributes';
import actions from '../actions';
import { getSingleBookingDetailsAsync, updateRepeatCombinedBookingAsync } from '../actions/info';

export interface InfoState {
    operationInProgress: boolean;
    item: Booking | null;
    error: ErrorState;
    resource: CombinedResource | null;
    users: PersonById[];
    roles: UserRole[];
    companyAttributes: SpecifiedCompanyAttributesResponse[];
    rolesLoading: boolean;
    usersLoading: boolean;
    companyAttributesLoading: boolean;
    isSearchOboPersonInProgress: boolean;
    searchOboPersonsResult: Person[] | null;
    isVisitorCreationOrEditingInProgress: boolean;
    isVisitorCreated: boolean;
    isVisitorUpdated: boolean;
    errorMessage: string;
    failedStatus: string;
    occurrences: Occurrence[];
    resourceImageLoading: boolean;
    resourceImage: ResourceImage;
    tenantCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
}

export interface ErrorState {
    title: string;
    message: string;
    active: boolean;
    response: ApiResponse | null;
}

const defaultErrorResponse: ApiResponse = {
    isComplete: true,
    isSuccess: false,
    statusCode: -1,
    payload: { statusCode: -1, message: '', errorType: 'unknown' },
};

const defaultError = {
    title: '',
    message: '',
    active: false,
    response: null,
};

export default combineReducers<InfoState>({
    operationInProgress: createReducer<boolean>(false)
        .handleAction([actions.setBookingInfoOperationInProgress], (state, action) => action.payload)
        .handleAction(
            [
                actions.getCombinedBookingAsync.request,
                actions.checkInACombinedAsync.request,
                actions.checkOutACombinedAsync.request,
                actions.checkInSingleOccurrence.request,
                actions.checkOutSingleOccurrence.request,
                actions.bookACombinedConfirmFromBookingInfoAsync.request,
                actions.bookASingleOccurrenceFromBookingInfoAsync.request,
                actions.updateCombinedBookingAsync.request,
                actions.abandonBookingAsync.request,
                actions.cancelSingleOccurrenceAsync.request,
                actions.deleteRepeatSeriesAsync.request,
                actions.abandonSingleOccurrenceAsync.request,
                getSiteConfigurationAsync.request,
                getResources.request,
                getGeographicStructureAreas.request,
                updateBookedResourceForBookingAsync.request,
                updateSingleOccurrenceAsync.request,
                updateRepeatCombinedBookingAsync.request,
                updateSingleOccurrenceForBookingInfoAsync.request,
                actions.getCombinedBookingResourceAsync.request,
            ],
            () => true
        )
        .handleAction(
            [
                actions.getCombinedBookingAsync.success,
                actions.getCombinedBookingAsync.success,
                getSingleBookingDetailsAsync.success,
                actions.cancelSingleOccurrenceAsync.success,
                actions.cancelSingleOccurrenceAsync.failure,
                actions.getCombinedBookingAsync.failure,
                actions.checkInACombinedAsync.failure,
                actions.checkOutACombinedAsync.failure,
                actions.checkInSingleOccurrence.failure,
                actions.checkOutSingleOccurrence.failure,
                actions.bookACombinedConfirmFromBookingInfoAsync.failure,
                actions.bookASingleOccurrenceFromBookingInfoAsync.failure,
                actions.updateCombinedBookingAsync.failure,
                actions.deleteRepeatSeriesAsync.success,
                actions.deleteRepeatSeriesAsync.failure,
                actions.abandonSingleOccurrenceAsync.success,
                actions.abandonSingleOccurrenceAsync.failure,
                actions.getCombinedBookingResourceAsync.success,
                actions.getCombinedBookingResourceAsync.failure,
                getSiteConfigurationAsync.failure,
                getResources.failure,
                getSingleBookingDetailsAsync.failure,
                updateBookedResourceForBookingAsync.failure,
                getGeographicStructureAreas.failure,
                updateSingleOccurrenceAsync.failure,
                updateRepeatCombinedBookingAsync.failure,
                updateSingleOccurrenceForBookingInfoAsync.failure,
            ],
            () => false
        ),

    item: createReducer<Booking | null>(null)
        .handleAction(
            [actions.getCombinedBookingAsync.success, actions.getSingleBookingDetailsAsync.success],
            (state, action) => {
                const booking = action.payload.payload as Booking;
                return booking;
            }
        )
        .handleAction(
            [
                actions.resetNotSavedBookingInfo,
                actions.getCombinedBookingAsync.failure,
                actions.getSingleBookingDetailsAsync.failure,
                actions.navigateFromInfoPage,
                actions.getParentBooking,
            ],
            () => null
        ),

    resource: createReducer<CombinedResource | null>(null)
        .handleAction([actions.getCombinedBookingResourceAsync.success], (state, action) => {
            const bookingResource = action.payload as CombinedResource;
            return bookingResource;
        })
        .handleAction([actions.getCombinedBookingResourceAsync.failure, actions.navigateFromInfoPage], () => null),

    resourceImageLoading: createReducer<boolean>(false)
        .handleAction([actions.getResourceImage.request], () => true)
        .handleAction([actions.getResourceImage.success, actions.getResourceImage.failure], () => false),

    resourceImage: createReducer<ResourceImage>({} as ResourceImage)
        .handleAction([actions.getResourceImage.success], (state, action) => {
            const resourceImage = action.payload.payload as ResourceImage;
            return resourceImage;
        })
        .handleAction([actions.getResourceImage.failure], () => {
            const resourceImage = {} as ResourceImage;
            return resourceImage;
        })
        .handleAction([actions.resetResourceImage], () => {
            const resourceImage = {} as ResourceImage;
            return resourceImage;
        }),

    error: createReducer<ErrorState>(defaultError)
        .handleAction(
            [
                actions.getCombinedBookingAsync.request,
                actions.getCombinedBookingAsync.success,
                actions.bookACombinedConfirmFromBookingInfoAsync.request,
                actions.bookACombinedConfirmFromGridAsync.request,
                actions.bookACombinedConfirmFromBookingInfoAsync.success,
                actions.bookACombinedConfirmFromGridAsync.success,
                actions.combinedBookingDismissError,
            ],
            () => defaultError
        )
        .handleAction(
            [
                actions.bookACombinedConfirmFromGridAsync.failure,
                actions.bookACombinedConfirmFromBookingInfoAsync.failure,
            ],
            (state, action) => {
                const axiosResponse = action.payload as AxiosError;

                if (!axiosResponse || !axiosResponse.response) {
                    return { ...state, response: { ...defaultErrorResponse } };
                }

                return {
                    ...defaultError,
                    active: true,
                    response: {
                        ...defaultErrorResponse,
                        statusCode: axiosResponse.response.status,
                        payload: {
                            statusCode: axiosResponse.response.status,
                            message: axiosResponse.response.data,
                            errorType: 'error',
                        },
                    },
                };
            }
        ),

    users: createReducer<PersonById[]>([])
        .handleAction([actions.getPersonsByIds.success], (state, action) => action.payload.payload as PersonById[])
        .handleAction([actions.getPersonsByIds.failure], () => [])
        .handleAction([actions.getPersonsByIds.request], () => [])
        .handleAction([actions.setUsersLoadStatus], (state, action) =>
            action.payload === constants.LOAD_STATUSES.REQUIRED ? [] : state
        )
        .handleAction([actions.resetUsers], () => []),

    roles: createReducer<UserRole[]>([])
        .handleAction([actions.getRolesByIds.success], (state, action) => action.payload.payload as UserRole[])
        .handleAction([actions.getRolesByIds.failure], () => [])
        .handleAction([actions.getRolesByIds.request], () => [])
        .handleAction([actions.setRolesLoadStatus], (state, action) =>
            action.payload === constants.LOAD_STATUSES.REQUIRED ? [] : state
        )
        .handleAction([actions.resetRoles], () => []),

    companyAttributes: createReducer<SpecifiedCompanyAttributesResponse[]>([])
        .handleAction(
            [actions.getSpecifiedAttributes.success],
            (state, action) => action.payload.payload as SpecifiedCompanyAttributesResponse[]
        )
        .handleAction([actions.getSpecifiedAttributes.failure], () => [])
        .handleAction([actions.getSpecifiedAttributes.request], () => [])
        .handleAction([actions.setAttributesLoadStatus], (state, action) =>
            action.payload === constants.LOAD_STATUSES.REQUIRED ? [] : state
        )
        .handleAction([actions.resetAttributes], () => []),

    usersLoading: createReducer<boolean>(false)
        .handleAction([actions.getPersonsByIds.request], () => true)
        .handleAction([actions.getPersonsByIds.success, actions.getPersonsByIds.failure], () => false),

    rolesLoading: createReducer<boolean>(false)
        .handleAction([actions.getRolesByIds.request], () => true)
        .handleAction([actions.getRolesByIds.success, actions.getRolesByIds.failure], () => false),

    companyAttributesLoading: createReducer<boolean>(false)
        .handleAction([actions.getSpecifiedAttributes.request], () => true)
        .handleAction([actions.getSpecifiedAttributes.success, actions.getSpecifiedAttributes.failure], () => false),

    isSearchOboPersonInProgress: createReducer<boolean>(false)
        .handleAction([actions.searchInternalOboPersons.request, actions.searchExternalOboPersons.request], () => true)
        .handleAction(
            [
                actions.searchInternalOboPersons.success,
                actions.searchInternalOboPersons.failure,
                actions.searchExternalOboPersons.success,
                actions.searchExternalOboPersons.failure,
            ],
            () => false
        ),

    searchOboPersonsResult: createReducer<Person[] | null>(null)
        .handleAction(
            [actions.searchInternalOboPersons.success, actions.searchExternalOboPersons.success],
            (state, action) => (action.payload.payload as PersonsResponse).items
        )
        .handleAction([actions.searchInternalOboPersons.failure, actions.searchExternalOboPersons.failure], () => [])
        .handleAction(
            [
                actions.resetSearchOboPersonsResult,
                actions.searchInternalOboPersons.request,
                actions.searchExternalOboPersons.request,
            ],
            () => null
        ),

    isVisitorCreationOrEditingInProgress: createReducer<boolean>(false)
        .handleAction([actions.createVisitor.request, actions.editVisitor.request], () => true)
        .handleAction(
            [
                actions.createVisitor.success,
                actions.createVisitor.failure,
                actions.editVisitor.success,
                actions.editVisitor.failure,
            ],
            () => false
        ),

    isVisitorCreated: createReducer<boolean>(false)
        .handleAction([actions.createVisitor.success], () => true)
        .handleAction([actions.createVisitor.failure], () => false),

    isVisitorUpdated: createReducer<boolean>(false)
        .handleAction([actions.editVisitor.success], () => true)
        .handleAction([actions.editVisitor.failure], () => false),

    errorMessage: createReducer<string>('')
        .handleAction([updateBookedResourceForBookingAsync.failure], (state, action) => {
            return action.payload;
        })
        .handleAction(
            [updateBookedResourceForBookingAsync.request, updateSingleOccurrenceForBookingInfoAsync.request],
            () => ''
        ),
    failedStatus: createReducer<string>('').handleAction(
        [actions.getCombinedBookingAsync.failure],
        () => constants.LOAD_STATUSES.FAILED
    ),

    occurrences: createReducer<Occurrence[]>([])
        .handleAction([actions.getRepeatOccurrences.success], (state, action) => {
            return action.payload.payload as Occurrence[];
        })
        .handleAction([actions.getRepeatOccurrences.failure, actions.navigateFromInfoPage], () => []),

    tenantCompanyAttributes: createReducer<any[] | null>(null)
        .handleAction([actions.getTenantCompanyAttributes.success], (state, action) => action.payload.payload as any[])
        .handleAction([actions.getTenantCompanyAttributes.failure], () => null),
});
