import React, { memo } from 'react';
import SingleBookingComponent from 'features/Resources/Combined/SingleBooking';
import Provider from 'features/Resources/Common/EditBooking/Provider';
import MainLayout from 'layouts/MainLayout/MainLayout';

const SingleBooking = (): JSX.Element => {
    return (
        <MainLayout>
            <Provider>
                <SingleBookingComponent />
            </Provider>
        </MainLayout>
    );
};

export default memo(SingleBooking);
