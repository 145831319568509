import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import ResourceReport from '../../features/Reports/ResourceReport';

const Resource = (): JSX.Element => {
    return (
        <MainLayout>
            <ResourceReport />
        </MainLayout>
    );
};

export default Resource;
