import React from 'react';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';

import InputWrapper from '../FormElements/InputWrapper';

import './baseIconButton.scss';

export interface BaseIconButtonProps {
    onClick: Function;
    className?: string;
    dataTestId?: string;
    title: string;
    isDisabled?: boolean;
    required?: boolean;
    hideLabel?: boolean;
    icon: JSX.Element;
}
const BaseIconButton = (props: BaseIconButtonProps): JSX.Element => {
    const { onClick, className, isDisabled, icon, title, hideLabel, required, dataTestId } = props;

    return (
        <InputWrapper
            label={title}
            hideLabel={hideLabel}
            className="icon-button-wrapper"
            inputName="inputName"
            required={required}
        >
            <IconButton
                data-testid={dataTestId}
                className={cn('icon-button', className)}
                disabled={isDisabled || !onClick}
                onClick={(e) => onClick(e)}
                disableFocusRipple
                disableRipple
            >
                {icon}
            </IconButton>
        </InputWrapper>
    );
};

export default BaseIconButton;
