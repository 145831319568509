import React from 'react';
import { useTranslation } from 'react-i18next';
// import { TextBox } from 'components/FormElements';
import { ListItem, MobileInput, Select, TextBox } from 'components/Forms';
import { AccountState } from 'features/Account/Models/AccountState';
import { UkCountryCode } from 'features/constants';

import { CountryCodes } from '../../../constants/CountryCodes';

import './PersonalDetails.scss';

interface PersonalDetailsProps {
    isSso: boolean;
    initialValues: AccountState;
    salutations: ListItem[];
    register: any;
    errors: any;
    control: any;
    setValue: any;
    clearErrors: any;
}

const PersonalDetails = (props: PersonalDetailsProps): JSX.Element => {
    const { isSso, initialValues, salutations, register, errors, control, setValue, clearErrors } = props;
    const { t } = useTranslation();
    return (
        <div className="account_personalDetails">
            <h2>{t('MyAccount_PersonalDetailsTitle')}</h2>
            <div className="row">
                <Select
                    label={t('MyAccount_SalutationLabel')}
                    name="title"
                    items={salutations}
                    initialValue={initialValues.title || ''}
                    className="salutation"
                    control={control}
                    id="myAccount-salutation"
                    isDisabled={isSso}
                />
                <TextBox
                    label={t('MyAccount_FirstNameLabel')}
                    name="firstname"
                    {...register('firstname', { required: t('MyAccount_FirstNameRequired') })}
                    dataTestId="myAccount-firstName"
                    setValue={setValue}
                    className="firstName"
                    error={errors.firstname?.message}
                    initialValue={initialValues.firstname}
                    maxLength={50}
                    isDisabled={isSso}
                    clearErrors={clearErrors}
                />
                <TextBox
                    label={t('MyAccount_LastNameLabel')}
                    name="surname"
                    {...register('surname', { required: t('MyAccount_LastNameRequired') })}
                    dataTestId="myAccount-lastName"
                    setValue={setValue}
                    className="lastName"
                    error={errors.surname?.message}
                    initialValue={initialValues.surname}
                    maxLength={50}
                    isDisabled={isSso}
                    clearErrors={clearErrors}
                />
            </div>

            <div className="row">
                <TextBox
                    label={t('MyAccount_EmailLabel')}
                    name="email"
                    {...register('surname')}
                    dataTestId="myAccount-email"
                    className="email"
                    error={errors.email?.message}
                    initialValue={initialValues.email}
                    maxLength={50}
                    isDisabled
                />
            </div>

            <div className="row">
                <MobileInput
                    codeName="countryCodeMobile"
                    numberName="mobile"
                    name="mobile"
                    label={t('MyAccount_MobileLabel')}
                    initialValue={
                        initialValues.countryCodeMobile ? `+${initialValues.countryCodeMobile}` : UkCountryCode
                    }
                    dataTestId="myAccount-mobile"
                    maxLength={20}
                    register={register}
                    items={CountryCodes}
                    control={control}
                    error={errors.mobile?.message}
                    validation={{
                        pattern: {
                            value: /^[0-9 ]*$/,
                            message: t('MyAccount_MobileError'),
                        },
                    }}
                    className="mobile"
                    isDisabled={isSso}
                    initialInputValue={initialValues.mobile || ''}
                />
            </div>

            <div className="row">
                <MobileInput
                    codeName="countryCodeTelephone"
                    numberName="telephone"
                    name="telephone"
                    label={t('MyAccount_TelephoneLabel')}
                    initialValue={
                        initialValues.countryCodeTelephone ? `+${initialValues.countryCodeTelephone}` : UkCountryCode
                    }
                    dataTestId="myAccount-telephone"
                    maxLength={20}
                    register={register}
                    items={CountryCodes}
                    control={control}
                    error={errors.telephone?.message}
                    validation={{
                        pattern: {
                            value: /^[0-9 ]*$/,
                            message: t('MyAccount_TelephoneError'),
                        },
                    }}
                    className="telephone"
                    isDisabled={isSso}
                    initialInputValue={initialValues.telephone || ''}
                />
            </div>
        </div>
    );
};

export default PersonalDetails;
