import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import ActionButtonsPanel from '../../components/ActionButtonsPanel';
import Button from '../../components/FormElements/Button';
import DateRangePicker from '../../components/FormElements/DatePicker/DateRangePicker';
import { ListItem, Select } from '../../components/Forms';
import Header from '../../components/SiteSubHeader';
import { ReportType } from '../../enums/ReportType';

import LoginsReportFilterForm from './LoginsReportFilterForm/LoginsReportFilterForm';
import ResourceBookingFilterForm from './ResourceBookingFilterForm/ResourceBookingFilterForm';
import ResourceFilterForm from './ResourceFilterForm/ResourceFilterForm';
import ResourceUtilisationReportFilterForm from './ResourceUtilisationReport/ResourceUtilisationReportFilterForm';
import UserReportFilterForm from './UserReport/UserReportFilterForm';
import VisitorBookingsFilterForm from './VisitorBookingsFiltersForm/VisitorBookingsFilterForm';
import VisitorProfileFilterForm from './VisitorProfileFiltersForm/VisitorProfileFilterForm';
import { getReportTypesListItems } from './utils';
// eslint-disable-next-line import/no-cycle
import { ReportsProps } from '.';

import './Reports.scss';

const Reports = ({
    reportsPermissions,
    searchInternalVisitors,
    searchExternalVisitors,
    searchUsers,
    searchVisitorsResult,
    searchUsersResult,
    resetVisitorSearch,
    isSearchVisitorsInProgress,
    searchCompaniesResult,
    searchCompanies,
    isSearchCompaniesInProgress,
    isSearchUsersInProgress,
    resetCompaniesSearch,
    resetUsersSearch,
    tenantId,
    sites,
    siteGroups,
    areas,
    getAreas,
    areasLoadStatus,
    getUserReportFilterOptions,
    userReportFilterOptions,
    isGetFiltersInProgress,
}: ReportsProps): JSX.Element => {
    const { t } = useTranslation();
    const [reportType, setReportType] = useState<ListItem | null>(null);
    const reportTypes = useMemo(() => getReportTypesListItems(t, reportsPermissions), [t('Reports_Logins')]);
    const [reportsFilter, setReportsFilter] = useState<any>({});
    const navigate = useNavigate();

    const onGenerate = useCallback((): void => {
        let pathname = '';

        switch (Number(reportType?.value)) {
            case ReportType.VisitorProfile:
                pathname = `/reports/visitor-profile`;
                break;
            case ReportType.VisitorBookings:
                pathname = `/reports/visitor-bookings-report`;
                break;
            case ReportType.ResourceBookings:
                pathname = `/reports/resource-bookings`;
                break;
            case ReportType.ResourceUtilization:
                pathname = `/reports/resource-utilisation`;
                break;
            case ReportType.ResourcesList:
                pathname = `/reports/resource`;
                break;
            case ReportType.Users:
                pathname = `/reports/user-report`;
                break;
            case ReportType.Logins:
                pathname = `/reports/logins-report`;
                break;
            default:
                break;
        }
        const state = { ...reportsFilter };

        if (state.dateRange) {
            state.dateRange.from = state.dateRange.from.toISO();
            state.dateRange.to = state.dateRange.to.toISO();
        }

        navigate(pathname, { state });
    }, [reportType, reportsFilter]);

    const reportTypeValue = Number(reportType?.value);

    return (
        <main className="reports-page">
            <Header title={t('Reports_Title')} dataTestId="reports-title" />
            <div className="type-n-date">
                <Select
                    label={t('Reports_ReportType')}
                    items={reportTypes}
                    className="report-type"
                    name="reportType"
                    dataTestId="report-type"
                    onChange={setReportType}
                    placeholder="Select"
                />
                {(reportTypeValue === ReportType.ResourceBookings ||
                    reportTypeValue === ReportType.VisitorBookings ||
                    reportTypeValue === ReportType.ResourceUtilization) && (
                    <DateRangePicker
                        dataTestId="date-range-picker"
                        dateRange={reportsFilter.dateRange}
                        onChange={(dateRange) => {
                            setReportsFilter({ ...reportsFilter, dateRange });
                        }}
                        name="date-range-picker"
                        title={t(
                            reportTypeValue === ReportType.ResourceUtilization
                                ? 'Reports_ResourceUtilisationDateRange'
                                : 'Reports_BookingDateRange'
                        )}
                        label={t(
                            reportTypeValue === ReportType.ResourceUtilization
                                ? 'Reports_ResourceUtilisationDateRange'
                                : 'Reports_BookingDateRange'
                        )}
                        required={false}
                    />
                )}

                {reportTypeValue === ReportType.Logins && (
                    <DateRangePicker
                        dataTestId="date-range-picker"
                        dateRange={reportsFilter.dateRange}
                        onChange={(dateRange) => setReportsFilter({ ...reportsFilter, dateRange })}
                        name="date-range-picker"
                        title={t('Reports_LogInsBetween')}
                        label={t('Reports_LogInsBetween')}
                        required={false}
                        futureDatesAvailable={false}
                    />
                )}

                {reportTypeValue === ReportType.Users && (
                    <div className="checkbox-n-date-range">
                        <input
                            className="checkbox"
                            type="checkbox"
                            checked={reportsFilter.useDateRange}
                            onChange={() =>
                                setReportsFilter({
                                    ...reportsFilter,
                                    useDateRange: !reportsFilter.useDateRange,
                                    dateRange: { from: DateTime.now(), to: DateTime.now() },
                                })
                            }
                        />
                        <div className="label">{t('Reports_LogInsBetween')}</div>
                        <DateRangePicker
                            dataTestId="date-range-picker"
                            dateRange={reportsFilter.dateRange}
                            onChange={(dateRange) => setReportsFilter({ ...reportsFilter, dateRange })}
                            name="date-range-picker"
                            title={t('Reports_LogInsBetween')}
                            label=""
                            required={false}
                            futureDatesAvailable={false}
                            disabled={!reportsFilter.useDateRange}
                        />
                    </div>
                )}
            </div>
            {!reportType && <div className="filter-placeholder">{t('Reports_Placeholder')}</div>}
            <div className="report-filters">
                {reportType?.value && <div className="filter-label">{t('Reports_ReportFilters')}</div>}
                {reportTypeValue === ReportType.Logins && (
                    <LoginsReportFilterForm
                        setReportsFilter={setReportsFilter}
                        reportsFilter={reportsFilter}
                        searchUsersResult={searchUsersResult}
                        searchUsers={searchUsers}
                        isSearchUsersInProgress={isSearchUsersInProgress}
                        resetUsersSearch={resetUsersSearch}
                    />
                )}
                {reportTypeValue === ReportType.VisitorProfile && (
                    <VisitorProfileFilterForm
                        setReportsFilter={setReportsFilter}
                        reportsFilter={reportsFilter}
                        searchInternalVisitors={searchInternalVisitors}
                        searchExternalVisitors={searchExternalVisitors}
                        searchVisitorsResult={searchVisitorsResult}
                        resetVisitorSearch={resetVisitorSearch}
                        isSearchVisitorsInProgress={isSearchVisitorsInProgress}
                        searchCompaniesResult={searchCompaniesResult}
                        searchCompanies={searchCompanies}
                        isSearchCompaniesInProgress={isSearchCompaniesInProgress}
                        resetCompaniesSearch={resetCompaniesSearch}
                    />
                )}
                {reportTypeValue === ReportType.ResourceBookings && (
                    <ResourceBookingFilterForm
                        setReportsFilter={setReportsFilter}
                        reportsFilter={reportsFilter}
                        siteGroups={siteGroups[tenantId]}
                        sites={sites}
                        areas={areas}
                        getAreas={getAreas}
                        areasLoadStatus={areasLoadStatus}
                    />
                )}
                {reportTypeValue === ReportType.ResourcesList && (
                    <ResourceFilterForm
                        setReportsFilter={setReportsFilter}
                        reportsFilter={reportsFilter}
                        siteGroups={siteGroups[tenantId]}
                        sites={sites}
                        areas={areas}
                        getAreas={getAreas}
                        areasLoadStatus={areasLoadStatus}
                    />
                )}
                {reportTypeValue === ReportType.Users && (
                    <UserReportFilterForm
                        setReportsFilter={setReportsFilter}
                        reportsFilter={reportsFilter}
                        getFilterOptions={getUserReportFilterOptions}
                        userReportFilterOptions={userReportFilterOptions}
                        isGetFiltersInProgress={isGetFiltersInProgress}
                    />
                )}
                {reportTypeValue === ReportType.VisitorBookings && (
                    <VisitorBookingsFilterForm
                        reportsFilter={reportsFilter}
                        setReportsFilter={setReportsFilter}
                        searchCompaniesResult={searchCompaniesResult}
                        searchCompanies={searchCompanies}
                        isSearchCompaniesInProgress={isSearchCompaniesInProgress}
                        resetCompaniesSearch={resetCompaniesSearch}
                        siteGroups={siteGroups[tenantId]}
                        sites={sites}
                    />
                )}
                {reportTypeValue === ReportType.ResourceUtilization && (
                    <ResourceUtilisationReportFilterForm
                        setReportsFilter={setReportsFilter}
                        reportsFilter={reportsFilter}
                        siteGroups={siteGroups[tenantId]}
                        sites={sites}
                        areas={areas}
                        getAreas={getAreas}
                        areasLoadStatus={areasLoadStatus}
                    />
                )}
            </div>

            <ActionButtonsPanel>
                <>
                    <Button
                        text={t('Reports_GenerateReport')}
                        className="book-btn"
                        dataTestId="book-btn"
                        onClick={onGenerate}
                        disabled={!reportType?.value}
                    />
                </>
            </ActionButtonsPanel>
        </main>
    );
};

export default Reports;
