import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { SlidingPanel } from 'components';
import { Button, DropDown } from 'components/FormElements';
import { getSelectedTimeSlot } from 'components/FormElements/DateTimePicker/Components/DateTimeRangeEditor';
import RadioButtonSelector from 'components/RadioButtonSelector/RadioButtonSelector';
import { CUSTOM_DURATION } from 'features/constants';
import { DateTime } from 'luxon';
import DateTimeService, { TimeSlotOption } from 'services/DateTimeService';

import { DurationDateTime, SlotDuration, validateStartEndTime } from './utils';

interface DurationSliderProps {
    isOpen: boolean;
    onClose: () => void;
    onDone: (value: string, customDuration: DurationDateTime) => void;
    slotDurations: SlotDuration[];
    durationValue: string | number;
    dateTime: DurationDateTime;
    timeInterval: number;
    bookingWindowStartMinutesFromMidnight: number;
    bookingWindowEndMinutesFromMidnight: number;
    hourFormat: string;
    timezone: string;
    selectedDate: DateTime;
}

const DurationSlider = ({
    isOpen,
    slotDurations,
    durationValue,
    dateTime,
    timeInterval,
    bookingWindowStartMinutesFromMidnight,
    bookingWindowEndMinutesFromMidnight,
    hourFormat,
    timezone,
    selectedDate,
    onClose,
    onDone,
}: DurationSliderProps): JSX.Element => {
    const { t } = useTranslation();
    const [duration, setDuration] = useState<any>(durationValue);
    const [customDuration, setCustom] = useState<DurationDateTime>(dateTime);
    const [validationMessage, setValidation] = useState<string | null>(null);

    useEffect(() => {
        setDuration(durationValue);
    }, [durationValue]);

    const customTimeSlots: TimeSlotOption[] = useMemo(() => {
        return DateTimeService.getTimeSlots(
            Math.floor(bookingWindowStartMinutesFromMidnight / timeInterval),
            Math.ceil(bookingWindowEndMinutesFromMidnight / timeInterval),
            timeInterval,
            hourFormat
        );
    }, [timeInterval]);

    const selectedFromTimeSlot = useMemo(() => {
        return getSelectedTimeSlot(customTimeSlots, customDuration?.startDateTime.setZone(timezone), timeInterval);
    }, [customDuration.startDateTime, timeInterval]);

    const selectedToTimeSlot = useMemo(() => {
        return getSelectedTimeSlot(customTimeSlots, customDuration?.endDateTime.setZone(timezone), timeInterval, true);
    }, [customDuration.endDateTime, timeInterval]);

    const onOptionChange = (option: any): void => {
        setDuration(Number.isNaN(Number(option)) ? option.target.value : Number(option));
    };

    const handleFromChange = (timeSlot: number): void => {
        setCustom({
            ...customDuration,
            startDateTime: DateTimeService.getDateFromTimeSlot(selectedDate.setZone(timezone), +timeSlot, timeInterval),
        });
    };

    const handleToChange = (timeSlot: number): void => {
        setCustom({
            ...customDuration,
            endDateTime: DateTimeService.getDateFromTimeSlot(selectedDate.setZone(timezone), +timeSlot, timeInterval),
        });
    };

    return (
        <SlidingPanel open={isOpen} onClose={onClose} title={t('Timeline_Slider_Title')} showBackgroundMask>
            <main className="slider-container">
                <RadioButtonSelector
                    required
                    value={duration}
                    items={slotDurations}
                    handleChange={onOptionChange}
                    tickView
                />
                {duration === CUSTOM_DURATION && (
                    <div className="validation-container">
                        <div className="time-container">
                            <DropDown
                                value={selectedFromTimeSlot?.value}
                                label={t('Repeat_Start_Time')}
                                className="start-time"
                                dataTestId="duration_Time_Slots"
                                onChange={(value: number) => handleFromChange(value)}
                            >
                                {customTimeSlots.map((timeSlot: TimeSlotOption, index: number) => (
                                    <MenuItem
                                        key={timeSlot.value}
                                        value={timeSlot.value.toString()}
                                        className={`start-time-item-${index}`}
                                    >
                                        {timeSlot.label}
                                    </MenuItem>
                                ))}
                            </DropDown>
                            <DropDown
                                value={selectedToTimeSlot?.value}
                                label={t('Repeat_End_Time')}
                                className="end-time"
                                dataTestId="duration_Time_Slots"
                                onChange={(value: number) => handleToChange(value)}
                            >
                                {customTimeSlots.map((timeSlot: TimeSlotOption, index: number) => (
                                    <MenuItem
                                        key={timeSlot.value}
                                        value={timeSlot.value.toString()}
                                        className={`end-time-item-${index}`}
                                    >
                                        {timeSlot.label}
                                    </MenuItem>
                                ))}
                            </DropDown>
                        </div>
                        <span className="validation-message">{validationMessage}</span>
                    </div>
                )}
            </main>
            <Button
                type="button"
                className="completeAction"
                dataTestId="done-button"
                onClick={() => {
                    if (customDuration && duration === CUSTOM_DURATION) {
                        const validation = validateStartEndTime(
                            customDuration,
                            dateTime.startDateTime.setZone(timezone),
                            hourFormat
                        );
                        if (validation) {
                            setValidation(validation);
                            return;
                        }
                    }
                    onDone(duration, customDuration);
                    onClose();
                    setValidation(null);
                }}
                text={t('Button_Done')}
            />
        </SlidingPanel>
    );
};

export default DurationSlider;
