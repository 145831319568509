import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';

import ActionButtonsPanel from '../../../components/ActionButtonsPanel';
import DataTable from '../../../components/DataTable';
import Pagination from '../../../components/DataTable/Pagination';
import Button from '../../../components/FormElements/Button';
import DateRangePicker from '../../../components/FormElements/DatePicker/DateRangePicker';
import { ListItem, Select } from '../../../components/Forms';
import EditColumnsForm from '../../../components/Forms/EditColumnsForm';
import WithLoading from '../../../components/HOC/WithLoading';
import ReportFilterPanel from '../../../components/ReportFilterPanel/ReportFilterPanel';
import Header from '../../../components/SiteSubHeader';
import SlidingPanel from '../../../components/SlidingPanel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { ResourceType } from '../../../services/ApiClients/Resource';
import { AllListItemOption, LOAD_STATUSES } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface, GridSettings, ReportsActiveColumn } from '../interfaces';
import {
    capitalizeFirstLetter,
    checkEnabling,
    customReportSelectStyles,
    filterArrayByAll,
    generateAndDownloadReportExportFile,
    getAreaListItemsOfSelectedSite,
    getDefaultColumnsValues,
    getSiteGroupListItems,
    getSiteListItems,
    resultPerPageListItems,
} from '../utils';

// eslint-disable-next-line import/no-cycle
import { ResourceUtilisationReportProps } from './index';
import { buildQuery, getColumns } from './resourceUtilisationReportUtils';

import './ResourceUtilisationReport.scss';

const ResourceUtilisationReport = ({
    tenantId,
    sites,
    siteGroups,
    areas,
    isReportGenerationInProgress,
    areasLoadStatus,
    reportData,
    getAreas,
    generateReport,
    isGenerationReportForExportInProgress,
    reportDataForExport,
    generateReportForExport,
}: ResourceUtilisationReportProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [isFiltersOpened, setIsFiltersOpened] = useState(false);
    const [columnsFilter, setColumnsFilter] = useState<ColumnFilterInterface>({});
    const [activeColumnsFilter, setActiveColumnsFilter] = useState<ActiveColumnFilterInterface>({});
    const [addColumnsFilterAfterApply, setAddColumnsFilterAfterApply] = useState<boolean>(false);

    const [activeFilter, setActiveFilter] = useState('');
    const [activeFilterHeaderName, setActiveFilterHeaderName] = useState('');
    const [activeColumnsFilterArray, setActiveColumnsFilterArray] = useState<any[]>([]);

    const [gridSettings, setGridSettings] = useState<GridSettings>({
        page: '1',
        resultPerPage: resultPerPageListItems[2],
        sortOptions: [{ field: 'type', sort: 'asc' }],
    });

    const defaultFilter = {
        selectedSiteGroup: AllListItemOption,
        selectedSite: AllListItemOption,
        selectedArea: AllListItemOption,
        selectedResourceType: AllListItemOption,
        dateRange: { from: DateTime.now(), to: DateTime.now() },
    };

    const resourceTypeListItems = [
        AllListItemOption,
        { label: ResourceType.DESK.name, value: ResourceType.DESK.value.toString() },
        { label: ResourceType.ROOM.name, value: ResourceType.ROOM.value.toString() },
    ];
    const dateRange = location.state
        ? {
              from: DateTime.fromISO(location.state.dateRange.from),
              to: DateTime.fromISO(location.state.dateRange.to),
          }
        : null;
    const [appliedReportsFilter, setAppliedReportsFilter] = useState<any>(
        location.state ? { ...location.state, dateRange } : defaultFilter
    );
    const [reportsFilter, setReportsFilter] = useState<any>({
        ...defaultFilter,
        ...{ ...location.state, ...({ dateRange } || null) },
    });

    const siteGroupListItems = useMemo(() => getSiteGroupListItems(siteGroups[tenantId], sites), [siteGroups]);

    const siteListItems = useMemo(
        () => getSiteListItems(sites, reportsFilter.selectedSiteGroup),
        [sites, reportsFilter.selectedSiteGroup]
    );

    const areaListItems = useMemo(
        () => getAreaListItemsOfSelectedSite(areas, reportsFilter.selectedSite),
        [areas, reportsFilter.selectedSite]
    );

    const defaultColumnsFilter: ColumnFilterInterface = {
        type: {
            values: [...getDefaultColumnsValues(resourceTypeListItems)],
            enabled: checkEnabling(resourceTypeListItems, reportsFilter.selectedResourceType.value),
        },
        siteGroup: {
            values: [...getDefaultColumnsValues(siteGroupListItems)],
            enabled: checkEnabling(siteGroupListItems, reportsFilter.selectedSiteGroup.value),
        },
        site: {
            values: [...getDefaultColumnsValues(siteListItems)],
            enabled: checkEnabling(siteListItems, reportsFilter.selectedSite.value),
        },
        area: {
            values: [...getDefaultColumnsValues(areaListItems)],
            enabled: checkEnabling(areaListItems, reportsFilter.selectedArea.value, addColumnsFilterAfterApply),
        },
    };

    const applyFilterAndGenerateReport = useCallback(
        (reportsFilterParam?: any) => {
            setAppliedReportsFilter(reportsFilterParam || reportsFilter);
            setGridSettings({ ...gridSettings, page: '1' });
            generateReport(
                buildQuery(
                    Number(gridSettings.resultPerPage.value),
                    1,
                    gridSettings.sortOptions,
                    reportsFilterParam || reportsFilter,
                    activeColumnsFilter
                )
            );
            setAddColumnsFilterAfterApply(true);
        },
        [reportsFilter, gridSettings, addColumnsFilterAfterApply]
    );

    useEffect(() => {
        if (reportsFilter.selectedSite.label !== 'All') {
            setAddColumnsFilterAfterApply(false);
        } else {
            setAddColumnsFilterAfterApply(true);
        }
    }, [reportsFilter.selectedSite]);

    useEffect(() => {
        setColumnsFilter({
            ...columnsFilter,
            ...defaultColumnsFilter,
        });
    }, [siteListItems, areaListItems, siteGroupListItems, reportsFilter, addColumnsFilterAfterApply]);

    const updateColumnsFilter = (columnName: string, values: any[]): void => {
        setColumnsFilter({
            ...columnsFilter,
            [columnName]: {
                ...columnsFilter[columnName],
                values,
            },
        });
    };

    const handleFilterClick = (columnName: string, headerName: string): void => {
        setIsFiltersOpened(true);
        setActiveFilter(columnName);
        setActiveFilterHeaderName(headerName);

        if (columnName in activeColumnsFilter) {
            updateColumnsFilter(columnName, [...activeColumnsFilter[columnName].values]);
        } else {
            updateColumnsFilter(columnName, [...defaultColumnsFilter[columnName].values]);
        }
    };

    const renderSideBar = (): void => {
        switch (activeFilter) {
            case 'type':
                setActiveColumnsFilterArray(filterArrayByAll(resourceTypeListItems));
                break;
            case 'siteGroup':
                setActiveColumnsFilterArray(filterArrayByAll(siteGroupListItems));
                break;
            case 'site':
                setActiveColumnsFilterArray(filterArrayByAll(siteListItems));
                break;
            case 'area':
                setActiveColumnsFilterArray(filterArrayByAll(areaListItems));
                break;
            default:
                setActiveColumnsFilterArray([]);
        }
    };

    useEffect(() => {
        if (activeFilter) {
            renderSideBar();
        }
    }, [activeFilter, siteListItems, areaListItems, siteGroupListItems]);

    const generateReportWithColumnsFilter = (): void => {
        setIsFiltersOpened(!isFiltersOpened);
        const newActiveColumnFilter = {
            ...activeColumnsFilter,
            [activeFilter]: { values: columnsFilter[activeFilter].values },
        };
        setActiveColumnsFilter(newActiveColumnFilter);
        generateReport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                newActiveColumnFilter
            )
        );
    };

    const loadPage = (gridState: GridSettings): void => {
        generateReport(
            buildQuery(
                Number(gridState.resultPerPage.value),
                Number(gridState.page),
                gridState.sortOptions,
                appliedReportsFilter,
                activeColumnsFilter
            )
        );
    };

    useEffect(() => {
        loadPage(gridSettings);
    }, []);

    const setGridStateAndLoadPage = (newGridState: GridSettings): void => {
        setGridSettings(newGridState);
        loadPage(newGridState);
    };

    const defaultActiveColumns = getColumns(
        !!siteGroups[tenantId],
        handleFilterClick,
        columnsFilter,
        activeColumnsFilter
    ).map((item) => ({
        name: item.headerName,
        active: true,
    }));

    const [isEditColumnsPanelOpen, setEditColumnsPanelOpen] = useState<boolean>(false);
    const [activeColumns, setActiveColumns] = useState<ReportsActiveColumn[]>(defaultActiveColumns);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    const generateReportForExportCallBack = useCallback(() => {
        const visibleColumns = columns.map((c) => c.field);
        generateReportForExport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                activeColumnsFilter,
                true,
                reportData?.totalCount,
                visibleColumns
            )
        );
    }, [gridSettings, reportData, gridSettings, columns]);

    const formatReportForExport = (report?: any[]): any => {
        if (!report) {
            return [];
        }

        const formattedReport = report.map((i) => {
            return {
                ...('type' in i && { type: capitalizeFirstLetter(i.type) }),
                ...('name' in i && { name: i.name }),
                ...('site' in i && { site: i.site }),
                ...('area' in i && { area: i.area }),
                ...('hoursAvailable' in i && { hoursAvailable: i.hoursAvailable }),
                ...('hoursBooked' in i && { hoursBooked: i.hoursBooked }),
                ...('hoursOccupied' in i && { hoursOccupied: i.hoursOccupied }),
                ...('resourceUtilisation' in i && { resourceUtilisation: i.resourceUtilisation }),
                ...('avgBookingDuration' in i && { avgBookingDuration: i.avgBookingDuration }),
                ...('avgOccupancyDuration' in i && { avgOccupancyDuration: i.avgOccupancyDuration }),
                ...('numberOfBookings' in i && { numberOfBookings: i.numberOfBookings }),
                ...('checkedOutHoursSaved' in i && { checkedOutHoursSaved: i.checkedOutHoursSaved }),
            };
        });

        return formattedReport;
    };

    useDidMountEffect(() => {
        if (!isGenerationReportForExportInProgress) {
            const formattedData = formatReportForExport(reportDataForExport?.items);
            generateAndDownloadReportExportFile('Resource Utilisation Report', columns, formattedData);
        }
    }, [isGenerationReportForExportInProgress]);

    useEffect(() => {
        const gridColumns = getColumns(!!siteGroups[tenantId], handleFilterClick, columnsFilter, activeColumnsFilter);
        const filteredColumns = gridColumns.filter(
            (item) => activeColumns.find((e) => e.name === item.headerName)?.active
        );
        setColumns(filteredColumns);
    }, [activeColumns, columnsFilter, activeColumnsFilter]);

    return (
        <main className="resource-utilisation-report">
            <WithLoading
                isLoading={
                    areasLoadStatus === LOAD_STATUSES.LOADING ||
                    isReportGenerationInProgress ||
                    isGenerationReportForExportInProgress
                }
            >
                <Header
                    title={t('ResourceUtilisationReport_Title')}
                    dataTestId="resource-utilisation-title"
                    className="resource-utilisation-title"
                    rightSideItems={[
                        <div className="edit-btn-n-date-range">
                            <DateRangePicker
                                dataTestId="date-range-picker"
                                dateRange={reportsFilter.dateRange}
                                onChange={(newDateRange: any) => {
                                    const newFilter = { ...reportsFilter, dateRange: newDateRange };
                                    setReportsFilter(newFilter);
                                    applyFilterAndGenerateReport(newFilter);
                                    setActiveColumnsFilter({});
                                }}
                                name="date-range-picker"
                                title={t('Reports_ResourceUtilisationDateRange')}
                                required={false}
                            />
                            ,
                            <Button
                                dataTestId="edit-columns-btn"
                                text={t('Reports_EditColumns')}
                                className="edit-btn"
                                onClick={() => {
                                    setEditColumnsPanelOpen(true);
                                }}
                            />
                        </div>,
                    ]}
                />
                <div className="filter-options">
                    {siteGroups[tenantId] && (
                        <Select
                            label={t('Reports_SiteGroups')}
                            name="siteGroup"
                            items={siteGroupListItems}
                            className="site-select"
                            styles={customReportSelectStyles}
                            onChange={(selectedSiteGroup) => {
                                setReportsFilter({
                                    ...reportsFilter,
                                    selectedSiteGroup,
                                    selectedSite: AllListItemOption,
                                });
                                setActiveColumnsFilter({});
                            }}
                            value={reportsFilter.selectedSiteGroup}
                        />
                    )}

                    <Select
                        label={t('Reports_Sites')}
                        name="site"
                        items={siteListItems}
                        styles={customReportSelectStyles}
                        onChange={(selectedSite) => {
                            if (selectedSite?.value !== AllListItemOption.value) {
                                getAreas(selectedSite.value);
                            }

                            setReportsFilter({ ...reportsFilter, selectedSite, selectedArea: AllListItemOption });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedSite}
                    />

                    <Select
                        label={t('Reports_Area')}
                        name="area"
                        items={areaListItems}
                        styles={customReportSelectStyles}
                        isDisabled={reportsFilter.selectedSite?.value === AllListItemOption.value}
                        onChange={(selectedArea) => {
                            setReportsFilter({ ...reportsFilter, selectedArea });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedArea}
                    />

                    <Select
                        label={t('Reports_ResourceType')}
                        name="area"
                        items={resourceTypeListItems}
                        styles={customReportSelectStyles}
                        onChange={(selectedResourceType) => {
                            setReportsFilter({ ...reportsFilter, selectedResourceType });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedResourceType}
                    />

                    <Button
                        text={t('Button_Apply')}
                        type="submit"
                        className="apply-btn"
                        dataTestId="apply-btn-btn"
                        disabled={isEqual(reportsFilter, appliedReportsFilter)}
                        onClick={applyFilterAndGenerateReport}
                    />
                </div>

                <DataTable
                    columns={columns}
                    rowsData={(activeColumns.some((column) => column.active) && reportData?.items) || []}
                    onSort={(sortOptions: any) => setGridStateAndLoadPage({ ...gridSettings, sortOptions })}
                    sortModel={gridSettings.sortOptions}
                    page={gridSettings.page}
                />

                <ActionButtonsPanel
                    leftSideElement={
                        <Pagination
                            pageString={gridSettings.page}
                            total={reportData?.totalCount || 0}
                            onPageChange={(page: string) => setGridStateAndLoadPage({ ...gridSettings, page })}
                            onPerPageChange={(perPage: ListItem) =>
                                setGridStateAndLoadPage({ ...gridSettings, resultPerPage: perPage, page: '1' })
                            }
                            resultPerPageOptions={resultPerPageListItems}
                            selectedResultPerPage={gridSettings.resultPerPage}
                        />
                    }
                >
                    <>
                        <Button
                            text={t('Button_Back')}
                            className="back-btn"
                            dataTestId="back-btn"
                            onClick={() => navigate(`/reports`)}
                        />
                        <Button
                            primary
                            text={t('Button_ExportReport')}
                            className="export-btn"
                            dataTestId="export-btn"
                            onClick={() => generateReportForExportCallBack()}
                            disabled={reportData?.items?.length === 0}
                        />
                    </>
                </ActionButtonsPanel>

                <SlidingPanel
                    title={t('Reports_CustomColumns')}
                    open={isEditColumnsPanelOpen}
                    showBackgroundMask
                    className="sliding-panel"
                    onClose={() => {
                        setEditColumnsPanelOpen(false);
                    }}
                >
                    <EditColumnsForm
                        activeColumns={activeColumns}
                        applyNewActiveColumns={setActiveColumns}
                        isEditColumnsPanelOpen={isEditColumnsPanelOpen}
                        setEditColumnsPanelOpen={setEditColumnsPanelOpen}
                    />
                </SlidingPanel>

                <ReportFilterPanel
                    isFiltersOpened={isFiltersOpened}
                    activeFilterHeaderName={activeFilterHeaderName}
                    setIsFiltersOpened={setIsFiltersOpened}
                    activeFilter={activeFilter}
                    activeColumnsFilterArray={activeColumnsFilterArray}
                    setColumnsFilter={setColumnsFilter}
                    columnsFilter={columnsFilter}
                    generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                />
            </WithLoading>
        </main>
    );
};

export default ResourceUtilisationReport;
