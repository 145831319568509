import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as GeographicStructureIcon } from 'assets/icons/AreaIcon.svg';
import classNames from 'classnames';

import './Header.scss';

export interface SiteSubHeaderProps {
    geographicStructure?: string;
    title: string;
    dataTestId: string;
    rightSideItems?: JSX.Element[];
    className?: string;
    withButtons?: boolean;
    onClick?: () => void;
}
export default ({
    geographicStructure,
    title,
    dataTestId,
    rightSideItems,
    className,
    withButtons,
    onClick,
}: SiteSubHeaderProps): JSX.Element => {
    return (
        <div
            className={classNames('header', className, withButtons && 'has-buttons')}
            data-testid={dataTestId}
            onClick={onClick ? () => onClick() : () => {}}
        >
            <ReactMarkdown source={title} />

            {rightSideItems && (
                <div className="right-side-items">
                    {rightSideItems.map((item) => (
                        <div className="right-side-item" key={item.key}>
                            {item}
                        </div>
                    ))}
                </div>
            )}

            {geographicStructure && (
                <div className="geographicStructure">
                    <GeographicStructureIcon />
                    <span>{geographicStructure}</span>
                </div>
            )}
        </div>
    );
};
