import { getLanguages } from 'features/Languages/Redux/actions';
import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getTenantInfo, loadReferenceDataBegin, loadReferenceDataEnd } from './actions';

export const getReferenceDataEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(loadReferenceDataBegin)),
        concatMap(() => {
            const tenantId = state$.value.authentication?.user?.tenantId || '';

            return of(getLanguages.request(), getTenantInfo.request(tenantId), loadReferenceDataEnd());
        })
    );
};

export const getTenantInfoEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getTenantInfo.request)),
        switchMap((action) =>
            from(api.organisation.getTenantInfo(action.payload)).pipe(
                map((payload) => getTenantInfo.success(payload)),
                catchError((error) => of(getTenantInfo.failure(error)))
            )
        )
    );
