import { Access, DeskBooking, ParkingBooking, RoomBooking } from '../enums/authorization';
import { Permissions } from '../features/Authentication/types';
import { GeographicStructureItem } from '../services/ApiClients/OrganisationStructure';
import { TenantInfo } from '../services/ApiClients/Organization/Models/TenantInfo';
import { ResourceType, ResourceTypeInterface } from '../services/ApiClients/Resource/Models';

export const HasPermissionToEditNotOwnBooking = (
    resourceType: number | undefined,
    permissions: Permissions
): boolean | null => {
    switch (resourceType) {
        case ResourceType.DESK.value:
            return !!(permissions?.DeskBooking & DeskBooking.EditOtherUsers);
        case ResourceType.ROOM.value:
            return !!(permissions?.RoomBooking & RoomBooking.EditOtherUsers);
        case ResourceType.PARKING.value:
            return !!(permissions?.ParkingBooking & ParkingBooking.EditOtherUsers);
        default:
            return null;
    }
};

export const HasPermissionToEditPastBooking = (
    resourceType: number | undefined,
    permissions: Permissions
): boolean | null => {
    switch (resourceType) {
        case ResourceType.DESK.value:
            return !!(permissions?.DeskBooking & DeskBooking.EditPastBookings);
        case ResourceType.ROOM.value:
            return !!(permissions?.RoomBooking & RoomBooking.EditPastBookings);
        case ResourceType.PARKING.value:
            return !!(permissions?.ParkingBooking & ParkingBooking.EditPastBookings);
        default:
            return null;
    }
};

export const HasPermissionToAddOnBehalfOf = (
    resourceType: number | undefined,
    permissions: Permissions
): boolean | null => {
    switch (resourceType) {
        case ResourceType.DESK.value:
            return !!(permissions?.DeskBooking & DeskBooking.BookOnBehalf);
        case ResourceType.ROOM.value:
            return !!(permissions?.RoomBooking & RoomBooking.BookOnBehalf);
        case ResourceType.PARKING.value:
            return !!(permissions?.ParkingBooking & ParkingBooking.BookOnBehalf);
        default:
            return null;
    }
};

export const HasPermissionToViewPrivateBookings = (
    resourceType: number | undefined,
    permissions: Permissions
): boolean | null => {
    switch (resourceType) {
        case ResourceType.DESK.value:
            return !!(permissions?.DeskBooking & DeskBooking.ViewPrivateBookings);
        case ResourceType.ROOM.value:
            return !!(permissions?.RoomBooking & RoomBooking.ViewPrivateBookings);
        case ResourceType.PARKING.value:
            return !!(permissions?.ParkingBooking & ParkingBooking.ViewPrivateBookings);
        default:
            return null;
    }
};

export const GetAllowedResourceTypes = (
    accessLevel: number,
    tenantInfo: TenantInfo | null,
    selectedSite: GeographicStructureItem | null
): ResourceTypeInterface[] => {
    const areDesksAvailable =
        tenantInfo?.areDesksAvailable && selectedSite?.areDesksAvailable && accessLevel & Access.DeskBooking;
    const areRoomsAvailable =
        tenantInfo?.areRoomsAvailable && selectedSite?.areRoomsAvailable && accessLevel & Access.RoomBooking;

    const resourceTypes = [
        ...(areDesksAvailable ? [ResourceType.DESK] : []),
        ...(areRoomsAvailable ? [ResourceType.ROOM] : []),
    ];

    return resourceTypes;
};
