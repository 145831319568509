import { keyBy, mapValues } from 'lodash';
import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { UnavailabilityResponse } from 'services/ApiClients/Booking/Models';
import { isActionOf } from 'typesafe-actions';

import { getResourcesUnavailability } from './actions';

export const getUnavailabilityEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getResourcesUnavailability.request)),
        filter(({ payload }) => !!payload.resourcesIds.length),
        switchMap(({ payload }) => {
            return from(api.booking.getResourcesUnavailability(payload)).pipe(
                map((response) => {
                    const responseData = response.payload as UnavailabilityResponse;

                    const dictionary = keyBy(responseData, (x) => x.resourceId);
                    const unavailabilityDictionary = mapValues(dictionary, (x) => x.unavailableTimes);

                    return getResourcesUnavailability.success(unavailabilityDictionary);
                }),
                catchError((error) => of(getResourcesUnavailability.failure(error)))
            );
        })
    );

export const clearUnavailabilityForNoResourcesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getResourcesUnavailability.request)),
        filter(({ payload }) => !payload.resourcesIds.length),
        switchMap(() => of(getResourcesUnavailability.success({})))
    );
