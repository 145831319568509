import { useRef } from 'react';
import { Dictionary } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { BookedResourceSummary, UnavailableTime } from 'services/ApiClients/Booking/Models';
import { Configuration } from 'services/ApiClients/Configuration';
import { Resource, UnavailableCategory } from 'services/ApiClients/Resource';

import { DayPilotDate } from '../Models/DayPilotDate';

enum AdjustmentDirection {
    None,
    Forward,
    Backward,
}

type HandleType = {
    anchor: DayPilotDate;
    direction: AdjustmentDirection;
};

type HookResult = {
    adjust: (start: DayPilotDate, end: DayPilotDate, anchor: DayPilotDate, resourceId: string) => [any, any];
    dispose: () => void;
};

// TODO: Max, replace it with ConfigurationService method from combining functionality
const isRangeAvailable = (
    configuration: Configuration,
    start: DateTime,
    end: DateTime,
    selectedDay: string
): boolean => {
    const bookableDay = configuration.bookableDays[selectedDay];
    if (!bookableDay) {
        return false;
    }

    const specificBeginsHour = Math.floor(bookableDay.bookingWindowStartMinutesFromMidnight / 60);
    const specificEndsHour = Math.ceil(bookableDay.bookingWindowEndMinutesFromMidnight / 60);

    const currentEndHours = end.hour;
    const currentEndMinutes = end.minute;

    if (currentEndHours > 0 || currentEndMinutes > 0) {
        if (currentEndHours < specificBeginsHour) {
            return false;
        }

        if (currentEndHours === specificBeginsHour && currentEndMinutes === 0) {
            return false;
        }
    }

    const currentStartHours = start.hour;
    const currentStartMinutes = start.minute;
    if (currentStartHours > specificEndsHour) {
        return false;
    }

    if (currentStartHours === specificEndsHour && currentStartMinutes >= 0) {
        return false;
    }

    return true;
};

export const useSetupCleardownAdjustment = (
    configuration: Configuration,
    selectedDay: string,
    resourceDictionary: Dictionary<Resource>,
    unavailableTimes: Dictionary<UnavailableTime[]>,
    resourceBookingsDictionary: Dictionary<BookedResourceSummary[]>,
    slotDuration: number,
    timezone: string,
    enabled: boolean
): HookResult => {
    const handle = useRef<HandleType | null>(null);

    const shiftDates = (start: DayPilotDate, end: DayPilotDate): [DayPilotDate, DayPilotDate] => {
        if (!handle.current) {
            return [start, end];
        }

        if (handle.current.direction === AdjustmentDirection.None) {
            return [start, end];
        }

        let adjustedStart = start;
        let adjustedEnd = end;

        if (handle.current.direction === AdjustmentDirection.Backward) {
            if (adjustedStart.toDate() < handle.current.anchor.toDate()) {
                adjustedEnd = handle.current.anchor;
            }

            if (adjustedEnd.toDate() > handle.current.anchor.toDate()) {
                adjustedStart = handle.current.anchor;
            }
        } else {
            if (adjustedEnd.toDate() > handle.current.anchor.toDate()) {
                adjustedStart = handle.current.anchor;
            }

            if (adjustedStart.toDate() < handle.current.anchor.toDate()) {
                adjustedEnd = handle.current.anchor;
            }
        }

        return [adjustedStart, adjustedEnd];
    };

    const calculateAnchorOffset = (
        setupTimeInSeconds: number,
        cleardownTimeInSeconds: number,
        setupTimes: UnavailableTime[],
        clearDownTimes: UnavailableTime[],
        bookings: BookedResourceSummary[],
        start: DayPilotDate,
        end: DayPilotDate
    ): number => {
        const timeInSeconds = Math.max(setupTimeInSeconds, cleardownTimeInSeconds);

        const slotDurationInSeconds = slotDuration * 60;
        for (let i = slotDurationInSeconds; i <= timeInSeconds; i += slotDurationInSeconds) {
            const offset = timeInSeconds - i + slotDurationInSeconds;

            if (setupTimeInSeconds >= offset) {
                const cellStartTime = DateTime.fromISO(start.toString(), { zone: timezone }).minus({ seconds: i });
                if (
                    clearDownTimes.some(
                        (x) =>
                            Interval.fromDateTimes(
                                DateTime.fromISO(x.startDateTime),
                                DateTime.fromISO(x.endDateTime)
                            ).contains(cellStartTime) && cellStartTime < DateTime.fromISO(x.endDateTime)
                    )
                ) {
                    return offset;
                }

                if (
                    bookings.some(
                        (x) =>
                            Interval.fromDateTimes(
                                DateTime.fromISO(x.startDateTime),
                                DateTime.fromISO(x.endDateTime)
                            ).contains(cellStartTime) && cellStartTime < DateTime.fromISO(x.endDateTime)
                    )
                ) {
                    return offset;
                }
            }

            if (cleardownTimeInSeconds >= offset) {
                const cellEndTime = DateTime.fromISO(end.toString(), { zone: timezone }).plus({ seconds: i });
                if (
                    setupTimes.some(
                        (x) =>
                            Interval.fromDateTimes(
                                DateTime.fromISO(x.startDateTime),
                                DateTime.fromISO(x.endDateTime)
                            ).contains(cellEndTime) && cellEndTime > DateTime.fromISO(x.startDateTime)
                    )
                ) {
                    return -offset + slotDurationInSeconds;
                }

                if (
                    bookings.some(
                        (x) =>
                            Interval.fromDateTimes(
                                DateTime.fromISO(x.startDateTime),
                                DateTime.fromISO(x.endDateTime)
                            ).contains(cellEndTime) && cellEndTime > DateTime.fromISO(x.startDateTime)
                    )
                ) {
                    return -offset + slotDurationInSeconds;
                }
            }
        }

        return 0;
    };

    const adjust = (
        start: DayPilotDate,
        end: DayPilotDate,
        draggingAnchor: DayPilotDate,
        resourceId: string
    ): [DayPilotDate, DayPilotDate] => {
        if (!enabled) {
            return [start, end];
        }
        if (!resourceId) {
            return [start, end];
        }
        if (handle.current !== null) {
            return shiftDates(start, end);
        }

        const resource = resourceDictionary[resourceId];
        if (!resource) {
            return [start, end];
        }

        const resourceBookings = resourceBookingsDictionary[resourceId] || [];

        handle.current = {
            anchor: draggingAnchor,
            direction: AdjustmentDirection.None,
        };

        const resourceUnavailableTimes = unavailableTimes[resourceId] || [];
        const resourceSetupTimes = resourceUnavailableTimes.filter((x) => x.category === UnavailableCategory.Setup);
        const resourceCleardownTimes = resourceUnavailableTimes.filter(
            (x) => x.category === UnavailableCategory.Cleardown
        );
        const calculatedOffset = calculateAnchorOffset(
            resource.setupTimeInSeconds,
            resource.cleardownTimeInSeconds,
            resourceSetupTimes,
            resourceCleardownTimes,
            resourceBookings,
            start,
            end
        );

        if (!calculatedOffset) {
            return [start, end];
        }

        const adjustedAnchor = draggingAnchor.addSeconds(calculatedOffset);

        let direction = AdjustmentDirection.Forward;
        if (calculatedOffset < 0) {
            direction = AdjustmentDirection.Backward;
        }

        const adjustedAnchorStart = DateTime.fromISO(adjustedAnchor.toString(), { zone: timezone });
        const adjustedAnchorEnd = DateTime.fromISO(adjustedAnchor.toString(), { zone: timezone }).plus({
            minutes: slotDuration,
        });

        if (!isRangeAvailable(configuration, adjustedAnchorStart, adjustedAnchorEnd, selectedDay)) {
            handle.current = {
                anchor: draggingAnchor,
                direction: AdjustmentDirection.None,
            };

            return [start, end];
        }

        handle.current = {
            anchor: adjustedAnchor,
            direction,
        };
        return shiftDates(start, end);
    };

    const dispose = (): void => {
        handle.current = null;
    };

    return {
        adjust,
        dispose,
    };
};
