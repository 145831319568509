import React from 'react';
import ReactDOM from 'react-dom';
import { createPrimaryButton, createSecondaryButton } from 'components/ActionButtons/Models/ActionButton';
import Dialog from 'components/Dialog';
import i18n from 'i18n';

export const renderConfirmationDialog = (
    message: string,
    description?: string,
    confrimMessage?: string,
    cancelMessage?: string,
    buttonsClassName?: string
): Promise<boolean> => {
    const dialogRoot = document.createElement('div');
    const body = document.querySelector('body');
    body?.appendChild(dialogRoot);

    return new Promise((resolve) => {
        const cancel = (): void => {
            ReactDOM.unmountComponentAtNode(dialogRoot);
            resolve(false);
        };

        const confirm = (): void => {
            ReactDOM.unmountComponentAtNode(dialogRoot);
            resolve(true);
        };

        const dialogButtons = [
            createSecondaryButton(
                'no',
                cancelMessage || i18n.t('Button_No'),
                cancel,
                false,
                undefined,
                'no-dialogButton'
            ),
            createPrimaryButton(
                'yes',
                confrimMessage || i18n.t('Button_Yes'),
                confirm,
                false,
                undefined,
                'yes-dialogButton'
            ),
        ];

        ReactDOM.render(
            <Dialog
                message={message}
                buttons={dialogButtons}
                description={description}
                buttonsClassName={buttonsClassName}
            />,
            dialogRoot
        );
    });
};
