import configurationsIcon from './ConfigurationsColor.svg';
import configurationsMonoIcon from './ConfigurationsMono.svg';
import desksIcon from './DesksColor.svg';
import desksMonoIcon from './DesksMono.svg';
import emergencyIcon from './EmergencyColor.svg';
import emergencyMonoIcon from './EmergencyMono.svg';
import fixedResourceIcon from './FixedResourceColor.svg';
import fixedResourceMonoIcon from './FixedResourceMono.svg';
import informationIcon from './InformationColor.svg';
import informationMonoIcon from './InformationMono.svg';
import networkingIcon from './NetworkColor.svg';
import networkingMonoIcon from './NetworkMono.svg';
import pcIcon from './PCColor.svg';
import pcMonoIcon from './PCMono.svg';
import restrictedIcon from './RestrictedColor.svg';
import restrictedMonoIcon from './RestrictedMono.svg';
import wheelchairIcon from './WheelchairColor.svg';
import wheelchairMonoIcon from './WheelchairMono.svg';

export default {
    configurationsIcon,
    configurationsMonoIcon,
    desksIcon,
    desksMonoIcon,
    emergencyIcon,
    emergencyMonoIcon,
    fixedResourceIcon,
    fixedResourceMonoIcon,
    informationIcon,
    informationMonoIcon,
    networkingIcon,
    networkingMonoIcon,
    pcIcon,
    pcMonoIcon,
    restrictedIcon,
    restrictedMonoIcon,
    wheelchairIcon,
    wheelchairMonoIcon,
};
