import { connect } from 'react-redux';
import { setCombinedDate } from 'features/Filters/Redux/actions';
import { getGeographicStructureAreas } from 'features/GeographicStructures/Redux/actions';
import {
    getCombinedBookingAsync,
    getCombinedBookingResourceAsync,
    getRepeatOccurrences,
} from 'features/Resources/Common/EditBooking/Redux/actions/info';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import { GetBookingQuery } from 'services/ApiClients/Booking';
import { GetBookingResourceQuery } from 'services/ApiClients/Resource';

import { getResources } from '../../../Combined/Redux/actions';
import { getSiteConfigurationAsync, getTenantConfigurationAsync } from '../../Redux/actions';
import { getProviderData } from '../Redux/selectors';

// eslint-disable-next-line import/no-cycle
import Provider from './ProviderComponent';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({ ...getProviderData(state) });

const mapDispatchToProps = {
    getSiteConfiguration: getSiteConfigurationAsync.request,
    getTenantConfiguration: getTenantConfigurationAsync.request,
    getBooking: (bookingQuery: GetBookingQuery) => getCombinedBookingAsync.request(bookingQuery),
    getSingleBookingResource: (resourceQuery: GetBookingResourceQuery) =>
        getCombinedBookingResourceAsync.request(resourceQuery),
    getResources: getResources.request,
    getAreas: getGeographicStructureAreas.request,
    getRepeatOccurrences: (bookingQuery: GetBookingQuery) => getRepeatOccurrences.request(bookingQuery),
    setDate: (selectedDate: DateTime) => setCombinedDate(selectedDate),
};

export type ProviderProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Provider);
