import { combineReducers } from 'redux';
import { UserReportFilterOptions } from 'services/ApiClients/Reports/Models/UserReportFilterOptions';
import { createReducer } from 'typesafe-actions';

import { Person, PersonsResponse } from '../../../services/ApiClients/Models';

import {
    generateReport,
    generateReportForExport,
    getUserReportFilterOptions,
    reportsGetCompanies,
    reportsGetDepartments,
    reportsResetCompaniesResult,
    reportsResetSearchCompaniesResult,
    reportsResetSearchUsersResult,
    reportsResetSearchVisitorsResult,
    reportsSearchCompanies,
    reportsSearchExternalVisitors,
    reportsSearchInternalVisitors,
    reportsSearchUsers,
    resetReportData,
    resetReportDataForExport,
} from './actions';

interface ReportsState {
    searchVisitorsResult: Person[] | null;
    isSearchVisitorsInProgress: boolean;
    searchCompaniesResult: string[] | null;
    searchUsersResult: Person[] | null;
    companiesResult: string[] | null;
    isSearchCompaniesInProgress: boolean;
    isSearchUsersInProgress: boolean;
    departmentsResult: { departments: string[] } | null;
    isGetDepartmentsInProgress: boolean;
    isGetCompaniesInProgress: boolean;
    reportData: any;
    reportDataForExport: any;
    isReportGenerationInProgress: boolean;
    userReportFilterOptions: UserReportFilterOptions | null;
    isGetFiltersInProgress: boolean;
    isGenerationReportForExportInProgress: false;
}

export default combineReducers<ReportsState>({
    searchVisitorsResult: createReducer<Person[] | null>(null)
        .handleAction(
            [reportsSearchInternalVisitors.success, reportsSearchExternalVisitors.success],
            (state, action) => (action.payload.payload as PersonsResponse).items
        )
        .handleAction([reportsSearchInternalVisitors.failure, reportsSearchExternalVisitors.failure], () => [])
        .handleAction(
            [
                reportsResetSearchVisitorsResult,
                reportsSearchInternalVisitors.request,
                reportsSearchExternalVisitors.request,
            ],
            () => null
        ),

    isSearchVisitorsInProgress: createReducer<boolean>(false)
        .handleAction([reportsSearchInternalVisitors.request, reportsSearchExternalVisitors.request], () => true)
        .handleAction(
            [
                reportsSearchInternalVisitors.success,
                reportsSearchInternalVisitors.failure,
                reportsSearchExternalVisitors.success,
                reportsSearchExternalVisitors.failure,
            ],
            () => false
        ),

    searchCompaniesResult: createReducer<string[] | null>(null)
        .handleAction([reportsSearchCompanies.success], (state, action) => action.payload.payload as string[])
        .handleAction([reportsSearchCompanies.failure], () => [])
        .handleAction([reportsResetSearchCompaniesResult, reportsSearchCompanies.request], () => null),

    isSearchCompaniesInProgress: createReducer<boolean>(false)
        .handleAction([reportsSearchCompanies.request], () => true)
        .handleAction([reportsSearchCompanies.success, reportsSearchCompanies.failure], () => false),

    searchUsersResult: createReducer<Person[] | null>(null)
        .handleAction(
            [reportsSearchUsers.success],
            (state, action) => (action.payload.payload as PersonsResponse).items
        )
        .handleAction([reportsSearchUsers.failure], () => [])
        .handleAction([reportsResetSearchUsersResult, reportsSearchUsers.request], () => null),

    isSearchUsersInProgress: createReducer<boolean>(false)
        .handleAction([reportsSearchUsers.request], () => true)
        .handleAction([reportsSearchUsers.success, reportsSearchUsers.failure], () => false),

    companiesResult: createReducer<string[] | null>(null)
        .handleAction([reportsGetCompanies.success], (state, action) => action.payload.payload as string[])
        .handleAction([reportsGetCompanies.failure], () => [])
        .handleAction([reportsResetCompaniesResult, reportsGetCompanies.request], () => null),

    isGetCompaniesInProgress: createReducer<boolean>(false)
        .handleAction([reportsGetCompanies.request], () => true)
        .handleAction([reportsGetCompanies.success, reportsGetCompanies.failure], () => false),

    departmentsResult: createReducer<{ departments: string[] } | null>(null)
        .handleAction(
            [reportsGetDepartments.success],
            (state, action) => action.payload.payload as { departments: string[] }
        )
        .handleAction([reportsGetDepartments.failure], () => null),

    isGetDepartmentsInProgress: createReducer<boolean>(false)
        .handleAction([reportsGetDepartments.request], () => true)
        .handleAction([reportsGetDepartments.success, reportsGetDepartments.failure], () => false),

    reportData: createReducer<Person[] | null>(null)
        .handleAction([generateReport.success], (state, action) => action.payload.payload.data)
        .handleAction([generateReport.failure, resetReportData], () => null),

    reportDataForExport: createReducer<Person[] | null>(null)
        .handleAction([generateReportForExport.success], (state, action) => action.payload.payload.data)
        .handleAction([generateReportForExport.failure, resetReportDataForExport], () => null),

    isReportGenerationInProgress: createReducer<boolean>(false)
        .handleAction([generateReport.request], () => true)
        .handleAction([generateReport.success, generateReport.failure], () => false),

    userReportFilterOptions: createReducer<UserReportFilterOptions | null>(null)
        .handleAction(
            [getUserReportFilterOptions.success],
            (state, action) => action.payload.payload as UserReportFilterOptions
        )
        .handleAction([getUserReportFilterOptions.failure], () => null),

    isGetFiltersInProgress: createReducer<boolean>(false)
        .handleAction([getUserReportFilterOptions.request], () => true)
        .handleAction([getUserReportFilterOptions.success, getUserReportFilterOptions.failure], () => false),

    isGenerationReportForExportInProgress: createReducer<boolean>(false)
        .handleAction([generateReportForExport.request], () => true)
        .handleAction([generateReportForExport.success, generateReportForExport.failure], () => false),
});
