import React from 'react';
import deskInfoIcons from 'assets/icons/deskInfo';

import './AboutInfo.scss';

interface AboutInfoProps {
    title: string;
    description: string;
    showBottomBorder?: boolean;
}

const AboutInfo = ({ title, description, showBottomBorder }: AboutInfoProps): JSX.Element => {
    return (
        <div className={`aboutInfo ${showBottomBorder ? 'bottom-border' : ''}`}>
            <img src={deskInfoIcons.informationIcon} alt="more information" />
            <div className="content">
                <p className="title">{title}</p>
                <p className="body">{description}</p>
            </div>
        </div>
    );
};

export default AboutInfo;
