import React, { useState } from 'react';
import classNames from 'classnames';
import BackgroundMask from 'components/BackgroundMask';
import SlidingPanel from 'components/SlidingPanel';
import useKeyPress from 'hooks/useKeyPress';
import { DateTime } from 'luxon';
import { Configuration } from 'services/ApiClients/Configuration';
import { isNil } from 'utilities/ts';

import InputWrapper from '../InputWrapper';

import DateTimeEditor from './Components/DateTimeEditor';
import Editor from './Components/DateTimeRangeEditor/index';
import Field from './Components/Field';
import DateTimePickerValue from './Models/DateTimePickerValue';

import 'scss/components/DateTimePicker.scss';

export interface DateTimePickerProps {
    dateTimeRangeValue?: DateTimePickerValue;
    dateTimeValue?: DateTime;
    onChange(value: any): void;
    supportEndTime?: boolean;
    name: string;
    dataTestId: string;
    disabled?: boolean;
    timeFromDisabled?: boolean;
    isSelectedDateBookable?: (current: DateTime) => boolean;
    isToggleDisabled?: boolean;
    resourceTypeSiteConfiguration: Configuration;
    label?: string;
    title: string;
    timeTitle?: string;
    isDateTimeRange?: boolean;
    selectedDate: DateTime;
    showTime?: boolean;
    className?: string;
    isValidDate?: (current: DateTime) => boolean;
    timeInterval?: number;
    nowDateTime?: DateTime;
    initialViewDate?: DateTime;
    required?: boolean;
    hourFormat: string;
    timezone?: string;
}

const DateTimePicker = ({
    name,
    title,
    timeTitle,
    dateTimeRangeValue,
    dateTimeValue,
    onChange,
    dataTestId,
    supportEndTime = false,
    resourceTypeSiteConfiguration,
    disabled = false,
    timeFromDisabled = false,
    isSelectedDateBookable,
    isToggleDisabled = false,
    label,
    selectedDate,
    isDateTimeRange = true,
    showTime = true,
    className = '',
    isValidDate,
    timeInterval,
    nowDateTime,
    initialViewDate,
    required = true,
    hourFormat,
    timezone,
}: DateTimePickerProps): JSX.Element => {
    const [editorOpen, setEditorOpen] = useState<boolean>(false);
    const [editorVisible, setEditorVisible] = useState<boolean>(true);

    const handleFieldFocus = (): void => setEditorOpen(true);
    const handleClose = (): void => {
        onChange(dateTimeRangeValue || dateTimeValue);
        setEditorOpen(false);
    };

    const handleDone = (v: DateTimePickerValue | DateTime | undefined): void => {
        setEditorOpen(false);
        if (isNil(onChange)) {
            return;
        }

        onChange(v);
    };

    const timeoutRef = React.useRef<any>(null);

    React.useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
        if (editorOpen) {
            setEditorVisible(true);
        } else {
            timeoutRef.current = setTimeout(() => {
                setEditorVisible(false);
            }, 1000);
        }
    }, [editorOpen]);

    const sidePanelClass = classNames({ open: editorOpen });
    const backgroundMaskClass = classNames({ open: editorOpen });

    useKeyPress(27, handleClose);

    return (
        <InputWrapper
            label={label}
            inputName={name}
            required={required}
            className={classNames('dateTimePicker', className)}
        >
            <Field
                onFocus={handleFieldFocus}
                value={dateTimeRangeValue}
                dateTime={dateTimeValue}
                isSingleTime
                dataTestId={dataTestId}
                disabled={disabled}
                showTime={showTime}
                hourFormat={hourFormat}
            />
            <BackgroundMask className={backgroundMaskClass} onClick={handleClose} />

            <SlidingPanel title={title} className={sidePanelClass} open={editorOpen} onClose={handleClose}>
                {editorVisible &&
                    (isDateTimeRange && dateTimeRangeValue ? (
                        <Editor
                            value={dateTimeRangeValue}
                            supportEndTime={supportEndTime}
                            onDone={handleDone}
                            resourceTypeSiteConfiguration={resourceTypeSiteConfiguration}
                            selectedDate={selectedDate}
                            timeFromDisabled={timeFromDisabled}
                            isToggleDisabled={isToggleDisabled}
                            isSelectedDateBookable={isSelectedDateBookable}
                            hourFormat={hourFormat}
                            timezone={timezone}
                        />
                    ) : (
                        <DateTimeEditor
                            value={dateTimeValue}
                            timeTitle={timeTitle}
                            onDone={handleDone}
                            resourceTypeSiteConfiguration={resourceTypeSiteConfiguration}
                            timeFromDisabled={timeFromDisabled}
                            showTime={showTime}
                            isValidDate={isValidDate}
                            timeInterval={timeInterval}
                            nowDateTime={nowDateTime}
                            initialViewDate={initialViewDate}
                            hourFormat={hourFormat}
                        />
                    ))}
            </SlidingPanel>
        </InputWrapper>
    );
};

export default DateTimePicker;
