import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import WithLoading from 'components/HOC/WithLoading';
import { InfoSliderData } from 'components/ResourceInfo/ResourceInfoSlider';
import Tooltip from 'components/Tooltip';
import { UserProfile } from 'features/Authentication/types';
import {
    BOOKING_SLOT_UNITS_MULTIPLIER,
    DEFAULT_DATE_FORMAT,
    DEFAULT_FIFTEEN_MIN_SLOT_DURATION,
} from 'features/constants';
import { FloorPlanTimelineFilterState } from 'features/Filters/Redux/reducer';
import { cloneDeep, Dictionary, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { BookedResourceSummary, CreateBookingCommand } from 'services/ApiClients/Booking';
import { SearchBookedResourcesQuery, UnavailableTime } from 'services/ApiClients/Booking/Models';
import { CombinedConfiguration, Configuration } from 'services/ApiClients/Configuration';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import {
    SpecifiedCompanyAttributesQuery,
    SpecifiedCompanyAttributesResponse,
} from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { FloorPin } from 'services/ApiClients/Resource/Models/Resource';
import { SearchResponse } from 'services/ApiClients/Search/Models/SearchResponse';
import {
    FLOORPIN_STATUSES,
    getFloorPinIconByStatus,
    getFloorPinStatus,
    getFloorPinTooltipText,
    getFloorPinTooltipTextFromSearch,
    getStartEndTimeCompareToResourceConfigurations,
    getTooltipOrientation,
    getTooltipXYPosition,
    isBookingInTimeSlot,
    roundTimeToNearestTimeSlot,
} from 'utilities/floorplanUtils';
import Guid from 'utilities/guid';

import { ResourcesGS, ResourceType, ResourceTypeInterface } from '../../../../services/ApiClients/Resource/Models';
import InfoSlider from '../InfoSlider/InfoSlider';
import { ConfigurationSettings } from '../Redux/Reducer/configuratioReducer';

import { CardProps } from './FloorPlanHeader/Timeline/TimeSlotCard';
import FloorPlanHeader from './FloorPlanHeader';

import './FloorPlan.scss';

interface FloorPlanDataProps {
    isLoading: boolean;
    operationInProgress: boolean;
    selectedSite: GeographicStructureItem | null;
    area: GeographicStructureItem | null;
    floorPins: FloorPin[];
    user: UserProfile;
    selectedDate: DateTime;
    gridDate: DateTime;
    resourceTypes: ResourceTypeInterface[];
    timezone: string;
    configurationSiteSettings: CombinedConfiguration | null;
    siteConfigurations: ConfigurationSettings;
    tenantConfiguration: ConfigurationSettings;
    hourFormat: string;
    unavailabilityTimes: Dictionary<UnavailableTime[]>;
    allResourceTypeConfigurations: Configuration[] | null;
    floorPlanTimelineFilter: FloorPlanTimelineFilterState;
    searchResultItem: SearchResponse | null;
}

interface FloorPlanProps {
    bookAResource: (param: CreateBookingCommand) => void;
    navigateToEditBooking: (param: Guid, pathname: string, search: string) => void;
    floorPlanData: FloorPlanDataProps;
    resources: ResourcesGS[];
    getResourceImage: (imageId: Guid) => void;
    resetResourceImage: (data: object) => object;
    infoSliderData: InfoSliderData;
    getUsers: (usersIds: string[]) => void;
    getRoles: (usersIds: string[]) => void;
    getAttributes: (param: SpecifiedCompanyAttributesQuery) => void;
    resetUsers: (param: []) => void;
    resetRoles: (param: []) => void;
    resetAttributes: (param: []) => void;
    getBookingsByResourcesIds?: (params: SearchBookedResourcesQuery) => void;
    resetBookingsByResourcesIds?: (param: []) => void;
    bookingsByResourcesIdsLoadStatus?: string;
    setFloorPlanTimeLineFilter: (param: any) => void;
    setFloorPlanTimeLineDurationFilter: (param: string | number) => void;
    tenantCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
}

const FloorPlan = ({
    floorPlanData,
    bookAResource,
    navigateToEditBooking,
    resources,
    getResourceImage,
    resetResourceImage,
    infoSliderData,
    getUsers,
    getRoles,
    getAttributes,
    resetUsers,
    resetRoles,
    resetAttributes,
    getBookingsByResourcesIds,
    resetBookingsByResourcesIds,
    bookingsByResourcesIdsLoadStatus,
    setFloorPlanTimeLineFilter,
    setFloorPlanTimeLineDurationFilter,
    tenantCompanyAttributes,
}: FloorPlanProps): JSX.Element => {
    const {
        area,
        selectedSite,
        floorPins,
        gridDate,
        user,
        isLoading,
        operationInProgress,
        selectedDate,
        resourceTypes,
        timezone,
        configurationSiteSettings,
        hourFormat,
        unavailabilityTimes,
        tenantConfiguration,
        siteConfigurations,
        allResourceTypeConfigurations,
        floorPlanTimelineFilter,
        searchResultItem,
    } = floorPlanData;
    const [floorplanWidth, setFloorplanWidth] = useState<number>();
    const [floorplanHeight, setFloorplanHeight] = useState<number>();
    const floorplanRef = useRef<HTMLDivElement>(null);
    const [selectedTimeSlot, setSlot] = useState<CardProps | null>(null);
    const [filteredPins, setPins] = useState<FloorPin[]>([]);
    const [infoSliderResource, setInfoSliderResource] = useState<any | undefined>();
    const [t] = useTranslation();

    const setFloorplanSize = (): void => {
        setFloorplanWidth(floorplanRef.current?.scrollWidth);
        setFloorplanHeight(floorplanRef.current?.scrollHeight);
    };

    useEffect(() => {
        setFloorplanSize();
    }, [filteredPins]);

    useEffect(() => {
        window.addEventListener('resize', setFloorplanSize);

        return () => {
            window.removeEventListener('resize', setFloorplanSize);
        };
    }, []);

    useEffect(() => {
        let pins = cloneDeep(floorPins);

        if (selectedTimeSlot) {
            pins = floorPins.map((pin) => {
                const status = getFloorPinStatus(
                    pin,
                    selectedDate,
                    user,
                    unavailabilityTimes,
                    timezone,
                    allResourceTypeConfigurations,
                    tenantConfiguration,
                    siteConfigurations,
                    selectedTimeSlot,
                    floorPlanTimelineFilter.duration
                );
                return {
                    ...pin,
                    floorPinStatus: status,
                    isHighlighting:
                        pin.id.toString() === searchResultItem?.resourceId &&
                        (searchResultItem?.startTime && searchResultItem?.startTime
                            ? selectedTimeSlot.startTime.day ===
                              DateTime.fromISO(searchResultItem.startTime).setZone(timezone).day
                            : true),
                };
            });
        }
        setPins(pins);
    }, [selectedTimeSlot, searchResultItem, floorPlanTimelineFilter, floorPins]);

    const selectedDay = selectedDate.toFormat('cccc').toLowerCase();

    const createBooking = (item: FloorPin): void => {
        if (selectedTimeSlot && allResourceTypeConfigurations) {
            const [startTimeCompareToConfiguration, endTimeCompareToConfiguration] =
                getStartEndTimeCompareToResourceConfigurations(
                    allResourceTypeConfigurations,
                    item.resourceType,
                    selectedDay,
                    selectedDate,
                    selectedTimeSlot.startTime,
                    selectedTimeSlot.endTime,
                    timezone
                );

            const minimalStartTime =
                startTimeCompareToConfiguration < DateTime.now() && endTimeCompareToConfiguration > DateTime.now()
                    ? DateTime.now()
                    : startTimeCompareToConfiguration;

            const slotDuration =
                (siteConfigurations[item.resourceType].bookingSlotUnits ?? 0) * BOOKING_SLOT_UNITS_MULTIPLIER ||
                DEFAULT_FIFTEEN_MIN_SLOT_DURATION;

            const startTime = roundTimeToNearestTimeSlot(minimalStartTime, slotDuration);
            const endTime =
                endTimeCompareToConfiguration.toMillis() === startTime.toMillis()
                    ? endTimeCompareToConfiguration.plus({ minutes: slotDuration })
                    : endTimeCompareToConfiguration;

            const request: CreateBookingCommand = {
                bookingId: Guid.newGuid(),
                resources: [
                    {
                        resourceId: item.id,
                        startDateTime: startTime.toISO(),
                        endDateTime: endTime.toISO(),
                        resourceType: item.resourceType,
                    },
                ],
                startDateTime: startTime.toISO(),
                endDateTime: endTime.toISO(),
                createdByDisplayName: user?.name ?? '',
                createdByUserId: user?.userProfileId ? new Guid(user.userProfileId) : Guid.newGuid(),
            };
            bookAResource(request);
        }
    };

    const formatBookingUrl = (isPartOfRepeat: boolean | undefined): any => ({
        pathname: isPartOfRepeat ? '/repeat' : '',
        search: isPartOfRepeat ? `selectedDate=${selectedDate.setZone(timezone).toFormat(DEFAULT_DATE_FORMAT)}` : '',
    });

    const handleBookResource = (item: FloorPin): void => {
        const { isPartOfRepeat, bookings, floorPinStatus } = item;
        const { pathname, search } = formatBookingUrl(isPartOfRepeat);

        const bookingsInTimeSlot = bookings.filter((booking) =>
            isBookingInTimeSlot(
                DateTime.fromISO(booking.startDateTime),
                DateTime.fromISO(booking.endDateTime),
                selectedTimeSlot?.startTime as DateTime,
                selectedTimeSlot?.endTime as DateTime
            )
        );
        const booking = bookingsInTimeSlot[0] as BookedResourceSummary;

        switch (floorPinStatus) {
            case FLOORPIN_STATUSES.AVAILABLE: {
                createBooking(item);
                break;
            }

            case FLOORPIN_STATUSES.MY_BOOKING: {
                navigateToEditBooking(booking?.bookingId, pathname, search);
                break;
            }

            case FLOORPIN_STATUSES.IS_PRIVATE: {
                navigateToEditBooking(booking?.bookingId, pathname, search);
                break;
            }

            case FLOORPIN_STATUSES.ON_BEHALF_OF_ME: {
                navigateToEditBooking(booking?.bookingId, pathname, search);
                break;
            }

            case FLOORPIN_STATUSES.ON_BEHALF_OF_INTERNAL: {
                navigateToEditBooking(booking?.bookingId, pathname, search);
                break;
            }

            case FLOORPIN_STATUSES.BOOKED: {
                setInfoSliderResource(false);
                break;
            }

            case FLOORPIN_STATUSES.RESTRICTED: {
                setInfoSliderResource(false);
                break;
            }

            case FLOORPIN_STATUSES.NOT_WORKING_TIME: {
                setInfoSliderResource(false);
                break;
            }

            case FLOORPIN_STATUSES.BOOKED_IN_THE_PAST: {
                setInfoSliderResource(false);
                break;
            }

            case FLOORPIN_STATUSES.UNAVAILABLE: {
                setInfoSliderResource(false);
                break;
            }

            case FLOORPIN_STATUSES.UNAVAILABLE_COVID: {
                setInfoSliderResource(false);
                break;
            }

            default: {
                break;
            }
        }
    };

    const onFloorPinClick = (item: FloorPin): void => {
        setInfoSliderResource({
            id: item.id,
            geoStructure: { id: floorPlanData.area?.id || Guid.Empty, name: floorPlanData.area?.name || '' },
            pinInformation: item,
        });
    };

    const onInfoSliderClose = (): void => {
        setInfoSliderResource(false);
    };

    const renderFloorPin = (item: FloorPin): JSX.Element => {
        const tooltipMaxWidthInPx = 200;

        const FloorPinIcon = getFloorPinIconByStatus(item.floorPinStatus, item.resourceType);

        let booking = item.bookings[0];
        if (selectedTimeSlot?.startTime && selectedTimeSlot?.endTime) {
            const currentBooking = item.bookings.find((b) => {
                return isBookingInTimeSlot(
                    DateTime.fromISO(b.startDateTime, { zone: timezone }),
                    DateTime.fromISO(b.endDateTime, { zone: timezone }),
                    selectedTimeSlot.startTime,
                    selectedTimeSlot.endTime
                );
            });
            if (currentBooking) {
                booking = currentBooking;
            }
        }

        const tooltipText = getFloorPinTooltipText(item.name, booking, item.floorPinStatus, user, item.resourceType);

        const tooltipSearchText = getFloorPinTooltipTextFromSearch(searchResultItem, t);

        const text = searchResultItem && item?.isHighlighting ? tooltipSearchText : tooltipText;

        const position = getTooltipOrientation(
            item.xCoordinate,
            item.yCoordinate,
            tooltipMaxWidthInPx,
            floorplanWidth,
            floorplanHeight
        );

        const style = getTooltipXYPosition(position, item.pinSize);
        const highlightBorderWidth = item?.isHighlighting ? 5 : 0;

        return (
            <div
                className={classNames('floorPlan__container--floorplan--pinWrapper', {
                    searchedDeskWrapper: item.isHighlighting && item.resourceType === ResourceType.DESK.value,
                    searchedRoomWrapper: item.isHighlighting && item.resourceType === ResourceType.ROOM.value,
                })}
                style={{
                    top: item.yCoordinate,
                    left: item.xCoordinate,
                    width: item.pinSize,
                    height: item.pinSize,
                }}
                key={item.id.toString()}
            >
                <FloorPinIcon
                    className={classNames(
                        `floorPlan__container--floorplan--pin floorPlan__container--floorplan--pin--${item.floorPinStatus}`,
                        {
                            searchedDesk: item.resourceType === ResourceType.DESK.value,
                            searchedRoom: item.resourceType === ResourceType.ROOM.value,
                        }
                    )}
                    data-testid={`resource-pin-${item.id}`}
                    style={{
                        width: item.pinSize + highlightBorderWidth,
                        height: item.pinSize + highlightBorderWidth,
                    }}
                    onClick={() => onFloorPinClick(item)}
                />
                <Tooltip
                    style={style}
                    className={item.floorPinStatus}
                    position={position}
                    isHighlighting={item.isHighlighting}
                    text={text}
                    maxWidth={tooltipMaxWidthInPx}
                    setPins={setPins}
                    filteredPins={filteredPins}
                />
            </div>
        );
    };

    const handleTimeSlotChange = (newSlot: CardProps): void => {
        setSlot(newSlot);
    };

    const handleDurationChange = (newDuration: any): void => {
        setFloorPlanTimeLineDurationFilter(newDuration);
    };

    return (
        <>
            <WithLoading isLoading={operationInProgress || isLoading}>
                <FloorPlanHeader
                    configuration={configurationSiteSettings}
                    selectedDay={selectedDay}
                    hourFormat={hourFormat}
                    timezone={timezone}
                    selectedDate={selectedDate}
                    gridDate={gridDate}
                    handleTimeSlotChange={handleTimeSlotChange}
                    handleDurationChange={handleDurationChange}
                    floorPlanTimelineFilter={floorPlanTimelineFilter}
                    setFloorPlanTimeLineFilter={setFloorPlanTimeLineFilter}
                    setFloorPlanTimeLineDurationFilter={setFloorPlanTimeLineDurationFilter}
                />

                <div className="floorPlan__container">
                    <div className="floorPlan__container--floorplan" ref={floorplanRef}>
                        <img src={area?.floorPlanImage} alt={`${selectedSite?.name} - ${area?.name} - Floor Plan`} />
                        {!isEmpty(filteredPins) &&
                            filteredPins.map((item) => {
                                return resourceTypes.some((rt) => rt.value === item.resourceType)
                                    ? renderFloorPin(item)
                                    : null;
                            })}
                    </div>
                </div>
            </WithLoading>
            <InfoSlider
                resource={infoSliderResource}
                resources={resources}
                onClose={onInfoSliderClose}
                title={t('Resource_About_Label')}
                getResourceImage={getResourceImage}
                resetResourceImage={resetResourceImage}
                infoSliderData={infoSliderData}
                getUsers={getUsers}
                getRoles={getRoles}
                getAttributes={getAttributes}
                resetUsers={resetUsers}
                resetRoles={resetRoles}
                resetAttributes={resetAttributes}
                isFromFloorPlanPage
                selectedDate={selectedDate}
                timezone={timezone}
                getBookingsByResourcesIds={getBookingsByResourcesIds}
                area={area as GeographicStructureItem}
                resetBookingsByResourcesIds={resetBookingsByResourcesIds}
                bookingsByResourcesIdsLoadStatus={bookingsByResourcesIdsLoadStatus}
                handleBookResource={handleBookResource}
                tenantCompanyAttributes={tenantCompanyAttributes}
            />
        </>
    );
};

export default memo(FloorPlan);
