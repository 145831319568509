import React from 'react';
import HelpComponent from 'features/Help';
import MainLayout from 'layouts/MainLayout/MainLayout';

const Help = (): JSX.Element => {
    return (
        <MainLayout>
            <HelpComponent />
        </MainLayout>
    );
};

export default Help;
