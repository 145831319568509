import { connect } from 'react-redux';
import { setLanguage } from 'features/Filters/Redux/actions';
import { RootState } from 'PortalTypes';
import { Language } from 'services/ApiClients/Language/Models';

// eslint-disable-next-line import/no-cycle
import LanguageSelector from './LanguageSelector';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    language: state.filters.language,
    options: state.referenceData.languages,
});

const mapDispatchToProps = {
    setLanguage: (selectedLanguage: Language) => setLanguage(selectedLanguage),
};

export type LanguageSelectorProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
