import React from 'react';

import './Chip.scss';

export interface ChipProps {
    name: string;
    onClick: (value?: string) => void;
}

const Chip = ({ onClick, name }: ChipProps): JSX.Element => {
    return (
        <div className="chip">
            {/* TODO: Add translation */}
            {/* <p>{t(`AdvancedFilters_${name}`)}</p> */}
            <p>{name}</p>
            <button type="button" className="close" onClick={() => onClick(name)} value={name}>
                <div className="closeIcon" />
            </button>
        </div>
    );
};

export default Chip;
