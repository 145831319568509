import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/FormElements/Button';
import { TextBox } from 'components/Forms';

import './UpdatePassword.scss';

interface UpdatePasswordProps {
    register: any;
    getValues: any;
    handleSubmit: any;
    errors: any;
    onUpdatePassword: (oldPassword: string, newPassword: string) => void;
    isUpdatingPassword: boolean;
    errorMessage: string | undefined;
    setValue: any;
    clearErrors: any;
}

const UpdatePassword = ({
    register,
    getValues,
    handleSubmit,
    errors,
    onUpdatePassword,
    isUpdatingPassword,
    errorMessage,
    setValue,
    clearErrors,
}: UpdatePasswordProps): JSX.Element => {
    const { t } = useTranslation();

    const [hidePassword, setHidePassword] = React.useState<boolean>(true);
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = React.useState<any>('');
    const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = React.useState('');

    React.useEffect(() => {
        if (errorMessage === t('MyAccount_CurrentPasswordDoesNotMatch')) {
            setCurrentPasswordErrorMessage(t('MyAccount_CurrentPasswordDoesNotMatch'));
        } else {
            setNewPasswordErrorMessage(errorMessage);
        }
    }, [errorMessage]);

    const onSave = (form: any): void => {
        const { newPassword, confirmPassword } = getValues();
        if (newPassword !== confirmPassword) {
            setNewPasswordErrorMessage(t('MyAccount_PasswordsDontMatch'));
        } else {
            setNewPasswordErrorMessage('');
            onUpdatePassword(form.currentPassword, form.newPassword);
        }
    };

    const togglePassword = (): void => {
        setHidePassword(!hidePassword);
    };

    const updateButtonText = t('Button_Update');

    const passwordRequirements = t('MyAccount_PasswordRequirementList')
        .split('.')
        .map((requirement) => requirement.trim());

    return (
        <div className="updatePassword">
            <form onSubmit={handleSubmit(onSave)}>
                <div className="updatePasswordFormFields">
                    <TextBox
                        label={t('MyAccount_CurrentPassword')}
                        name="currentPassword"
                        {...register('currentPassword', { required: t('MyAccount_CurrentPasswordRequired') })}
                        dataTestId="myAccount-passwordCurrent"
                        className="password"
                        initialValue=""
                        isPassword
                        error={errors.currentPassword?.message || currentPasswordErrorMessage}
                        setValue={setValue}
                        clearErrors={clearErrors}
                    />

                    <TextBox
                        label={t('MyAccount_NewPassword')}
                        name="newPassword"
                        {...register('newPassword', { required: t('MyAccount_NewPasswordRequired') })}
                        dataTestId="myAccount-passwordNew"
                        className="password"
                        initialValue=""
                        isPassword
                        error={errors.newPassword?.message}
                        hidePassword={hidePassword}
                        toggleHidePassword={togglePassword}
                        setValue={setValue}
                        clearErrors={clearErrors}
                    />
                    <TextBox
                        label={t('MyAccount_ConfirmPassword')}
                        name="confirmPassword"
                        dataTestId="myAccount-passwordConfirm"
                        className="confirm"
                        {...register('confirmPassword', { required: t('MyAccount_NewPasswordRequired') })}
                        initialValue=""
                        isPassword
                        error={errors.confirmPassword?.message || newPasswordErrorMessage}
                        hidePassword={hidePassword}
                        showTogglePasswordButton={false}
                        setValue={setValue}
                        clearErrors={clearErrors}
                    />

                    {!errors.confirmPassword?.message && errorMessage === t('MyAccount_PasswordRulesNotMet') ? (
                        <div className="passwordRequirements">
                            {t('MyAccount_PasswordRequirements')}
                            <ul>
                                {passwordRequirements.map((requirement) => (
                                    <li key={requirement}>{requirement}</li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <Button
                    type="submit"
                    className="completeAction"
                    dataTestId="done-button"
                    submitting={isUpdatingPassword}
                    text={updateButtonText}
                />
            </form>
        </div>
    );
};

export default UpdatePassword;
