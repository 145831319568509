import * as constants from 'features/constants';
import { setCombinedDate } from 'features/Filters/Redux/actions';
import { combineReducers } from 'redux';
import { BookedResourceSummary } from 'services/ApiClients/Booking';
import BookingSignalRService from 'services/BookingSignalRService';
import { createReducer } from 'typesafe-actions';

import {
    bookAResourceAsync,
    getResourcesBookingsAsync,
    setResourceBookingsOperationInProgress,
    setResourcesBookingsLoadStatus,
    updateBookedResourceAsync,
    updateLocalResourceBookings,
    updateSingleOccurrenceAsync,
    updateSingleOccurrenceForBookingInfoAsync,
    updateWholeSeriesFromGrid,
} from '../actions';

export interface BookingsState {
    loadStatus: string;
    operationInProgress: boolean;
    items: BookedResourceSummary[];
}

export default combineReducers<BookingsState>({
    loadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction(
            [
                setCombinedDate,
                setResourcesBookingsLoadStatus,
                updateBookedResourceAsync.success,
                updateBookedResourceAsync.failure,
                updateSingleOccurrenceAsync.success,
                updateSingleOccurrenceAsync.failure,
                updateWholeSeriesFromGrid.success,
                updateWholeSeriesFromGrid.failure,
            ],
            () => constants.LOAD_STATUSES.REQUIRED
        )
        .handleAction([getResourcesBookingsAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([getResourcesBookingsAsync.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([getResourcesBookingsAsync.failure], () => constants.LOAD_STATUSES.FAILED),

    operationInProgress: createReducer<boolean>(false)
        .handleAction([setResourceBookingsOperationInProgress], (state, action) => action.payload)
        .handleAction(
            [
                updateBookedResourceAsync.request,
                bookAResourceAsync.request,
                updateSingleOccurrenceAsync.request,
                updateSingleOccurrenceForBookingInfoAsync.request,
                updateWholeSeriesFromGrid.request,
            ],
            () => true
        )
        .handleAction(
            [
                updateBookedResourceAsync.success,
                updateBookedResourceAsync.failure,
                updateSingleOccurrenceAsync.success,
                updateSingleOccurrenceAsync.failure,
                bookAResourceAsync.success,
                bookAResourceAsync.failure,
                updateSingleOccurrenceForBookingInfoAsync.success,
                updateSingleOccurrenceForBookingInfoAsync.failure,
                updateWholeSeriesFromGrid.success,
                updateWholeSeriesFromGrid.failure,
            ],
            () => false
        ),

    items: createReducer<BookedResourceSummary[]>([])
        .handleAction([getResourcesBookingsAsync.success], (state: BookedResourceSummary[], action) => {
            const bookings = action.payload.payload as BookedResourceSummary[];
            return bookings;
        })
        .handleAction([getResourcesBookingsAsync.failure], () => [])
        .handleAction([updateLocalResourceBookings], (state: BookedResourceSummary[], action) => {
            return BookingSignalRService.updateReducerState(state, action);
        }),
});
