import React from 'react';

import './TabButtonLabelAndNumber.scss';

export interface TabButtonLabelAndNumberProps {
    label: string;
    number: number;
}

const TabButtonLabelAndNumber = ({ label, number }: TabButtonLabelAndNumberProps): JSX.Element => {
    return (
        <div className="tab-btn-with-label-n-number">
            <div className="label">{label}</div>
            <div className="number">{number}</div>
        </div>
    );
};

export default TabButtonLabelAndNumber;
