import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import actions from '../actions';

export const getDeskBookingAuditLogsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getCombinedBookingHistoryDataAsync.request)),
        switchMap((action) =>
            from(api.booking.getHistoryDataById(action.payload)).pipe(
                map((payload) => actions.getCombinedBookingHistoryDataAsync.success(payload)),
                catchError((error) => of(actions.getCombinedBookingHistoryDataAsync.failure(error)))
            )
        )
    );
