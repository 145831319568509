import * as constants from 'features/constants';
import { combineReducers } from 'redux';
import { BookedResourceSummary } from 'services/ApiClients/Booking';
import { createReducer } from 'typesafe-actions';

import {
    getBookingsByResourcesIdsAsync,
    resetBookingsByResourcesIds,
    setBookingsByResourcesIdsLoadStatus,
} from '../actions';

export interface BookingsByResourcesIdsState {
    loadStatus: string;
    items: BookedResourceSummary[];
}

export default combineReducers<BookingsByResourcesIdsState>({
    loadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setBookingsByResourcesIdsLoadStatus], () => constants.LOAD_STATUSES.REQUIRED)
        .handleAction([getBookingsByResourcesIdsAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([getBookingsByResourcesIdsAsync.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([getBookingsByResourcesIdsAsync.failure], () => constants.LOAD_STATUSES.FAILED),

    items: createReducer<BookedResourceSummary[]>([])
        .handleAction([getBookingsByResourcesIdsAsync.success], (state: BookedResourceSummary[], action) => {
            const bookings = action.payload.payload as BookedResourceSummary[];
            return bookings;
        })
        .handleAction([getBookingsByResourcesIdsAsync.failure, resetBookingsByResourcesIds], () => []),
});
