import React, { createContext, FormEvent, Reducer, useEffect, useReducer } from 'react';

import './Form.scss';

export type FormActionTypeWithStatePayload = {
    type: string;
    payload: any;
};

export type FormActionTypeWithNameValue = {
    type: string;
    group: string;
    name: string;
    value: any;
};

export type FormActionType = FormActionTypeWithStatePayload | FormActionTypeWithNameValue;

export interface FormProps {
    initialValues?: any;
    validate?(validationValues: any): void;
    initialErrors?: any;
    reducer?: any;
    onSubmit?(values: any): void;
    className: string;
}

export type FormContextProps = {
    values: any;
    errors?: any;
    runValidations?(validationValues: any): void;
    setFieldValue(group: string, name: string, value: any): void;
    handleSubmit?(event: FormEvent<HTMLFormElement>): void;
    handleKeyPress?(event: FormEvent<HTMLFormElement>): void;
    handleReset?(): void;
};

const FormContext = createContext<FormContextProps>({ values: {}, setFieldValue: () => {} });

const defaultReducer = (state: any, action: FormActionType): any => {
    switch (action.type) {
        case 'reset':
            return { ...(action as FormActionTypeWithStatePayload).payload };
        case 'set':
            return {
                ...state,
                [(action as FormActionTypeWithNameValue).group]: {
                    ...state[(action as FormActionTypeWithNameValue).group],
                    [(action as FormActionTypeWithNameValue).name]: (action as FormActionTypeWithNameValue).value,
                },
            };
        case 'setValues':
            return { ...(action as FormActionTypeWithStatePayload).payload };
        default:
            return state;
    }
};

const Form = (props: React.PropsWithChildren<FormProps>): JSX.Element => {
    const {
        initialValues = {},
        validate = async () => null,
        initialErrors = {},
        reducer = defaultReducer,
        onSubmit = () => null,
        children,
        className,
    } = props;

    const [values, dispatch] = useReducer<Reducer<any, FormActionType>>(reducer, initialValues);
    const [errors, errorDispatch] = useReducer<Reducer<any, FormActionType>>(reducer, initialErrors);
    const formProps: FormContextProps = {
        values,
        errors,
        runValidations: async (validationValues: any) => {
            const validateErrors = await validate(validationValues);
            errorDispatch({ type: 'setValues', payload: validateErrors });
        },
        setFieldValue: (group: string, name: string, value: any) => {
            dispatch({ type: 'set', group, name, value });
        },
        handleSubmit: (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit(values);
        },

        handleKeyPress: (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        },

        handleReset: () => {
            dispatch({ type: 'reset', payload: initialValues });
        },
    };

    useEffect(() => {
        if (!formProps?.runValidations) return;
        formProps.runValidations(values);
    }, [values]);

    return (
        <FormContext.Provider value={formProps}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <form className={className} onSubmit={formProps.handleSubmit} onKeyPress={formProps.handleKeyPress}>
                {children}
            </form>
        </FormContext.Provider>
    );
};

export { Form, FormContext };
