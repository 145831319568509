import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import {
    BookedResourceSummary,
    CreateBookingCommand,
    SearchBookedResourcesQuery,
    UpdateBookedRoomResourceCommand,
} from 'services/ApiClients/Booking';
import {
    UpdateSingleOccurrence,
    UpdateSingleOccurrenceFromBookingInfo,
    UpdateWholeSeries,
} from 'services/ApiClients/Booking/Models';
import { Resource, ResourceRequest } from 'services/ApiClients/Resource';
import { ResourcesGS } from 'services/ApiClients/Resource/Models';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getResources = createAsyncAction('GET_RESOURCES_REQUEST', 'GET_RESOURCES_SUCESS', 'GET_RESOURCES_FAILURE')<
    ResourceRequest,
    { [areaId: string]: Resource[] },
    AxiosError | ApiResponse<ResourcesGS[]>
>();

export const setResourceBookingsOperationInProgress = createAction(
    'BOOKINGS_Resource_SET_OPERATION_IN_PROGRESS'
)<boolean>();

export const bookAResourceAsync = createAsyncAction(
    'BOOK_A_RESOURCE_CREATE_REQUEST',
    'BOOK_A_RESOURCE_CREATE_SUCCESS',
    'BOOK_A_RESOURCE_CREATE_FAILURE'
)<CreateBookingCommand, ApiResponse, AxiosError | ApiResponse>();

export const updateBookedResourceAsync = createAsyncAction(
    'UPDATE_BOOKED_RESOURCE_REQUEST',
    'UPDATE_BOOKED_RESOURCE_SUCCESS',
    'UPDATE_BOOKED_RESOURCE_FAILURE'
)<UpdateBookedRoomResourceCommand, ApiResponse, AxiosError>();

export const updateSingleOccurrenceAsync = createAsyncAction(
    'UPDATE_SINGLE_OCCURRENCE_REQUEST',
    'UPDATE_SINGLE_OCCURRENCE_SUCCESS',
    'UPDATE_SINGLE_OCCURRENCE_FAILURE'
)<UpdateSingleOccurrence, ApiResponse, AxiosError>();

export const updateBookedResourceForBookingAsync = createAsyncAction(
    'UPDATE_BOOKED_RESOURCE_FOR_BOOKING_REQUEST',
    'UPDATE_BOOKED_RESOURCE_FOR_BOOKING_SUCCESS',
    'UPDATE_BOOKED_RESOURCE_FOR_BOOKING_FAILURE'
)<UpdateBookedRoomResourceCommand, ApiResponse, string>();

export const updateSingleOccurrenceForBookingInfoAsync = createAsyncAction(
    'UPDATE_SINGLE_OCCURRENCE_FOR_BOOKING_INFO_REQUEST',
    'UPDATE_SINGLE_OCCURRENCE_FOR_BOOKING_INFO_SUCCESS',
    'UPDATE_SINGLE_OCCURRENCE_FOR_BOOKING_INFO_FAILURE'
)<UpdateSingleOccurrenceFromBookingInfo, ApiResponse, AxiosError>();

export const updateLocalResourceBookings = createAction('UPDATE_LOCAL_RESOURCE_BOOKINGS')<BookedResourceSummary[]>();

export const setResourcesBookingsLoadStatus = createAction('BOOKINGS_RESOURCE_SET_LOAD_STATUS')<string>();

export const getResourcesBookingsAsync = createAsyncAction(
    'GET_RESOURCES_BOOKING_REQUEST',
    'GET_RESROURCES_BOOKING_SUCCESS',
    'GET_RESOURCES_BOOKING_FAILURE'
)<
    SearchBookedResourcesQuery,
    ApiResponse<BookedResourceSummary[]>,
    AxiosError | ApiResponse<BookedResourceSummary[]>
>();

export const setBookingsByResourcesIdsLoadStatus = createAction('BOOKINGS_BY_RESOURCES_IDS_LOAD_STATUS')<string>();

export const resetBookingsByResourcesIds = createAction('RESET_BOOKINGS_BY_RESOURCES_IDS')<[]>();

export const getBookingsByResourcesIdsAsync = createAsyncAction(
    'GET_BOOKINGS_BY_RESOURCES_IDS_REQUEST',
    'GET_BOOKINGS_BY_RESOURCES_IDS_SUCCESS',
    'GET_BOOKINGS_BY_RESOURCES_IDS_FAILURE'
)<
    SearchBookedResourcesQuery,
    ApiResponse<BookedResourceSummary[]>,
    AxiosError | ApiResponse<BookedResourceSummary[]>
>();

export const setResourcesLoadStatus = createAction('SET_RESOURCES_LOAD_STATUS')<string>();

export const updateWholeSeriesFromGrid = createAsyncAction(
    'UPDATE_WHOLE_SERIES_REQUEST',
    'UPDATE_WHOLE_SERIES_SUCCESS',
    'UPDATE_WHOLE_SERIES_FAILURE'
)<UpdateWholeSeries, void, AxiosError>();

export default {
    getResourcesBookingsAsync,
    setResourcesBookingsLoadStatus,
    updateLocalResourceBookings,
    updateBookedResourceAsync,
    updateBookedResourceForBookingAsync,
    bookAResourceAsync,
    setResourceBookingsOperationInProgress,
    getResources,
    setResourcesLoadStatus,
    updateSingleOccurrenceAsync,
    updateSingleOccurrenceForBookingInfoAsync,
    getBookingsByResourcesIdsAsync,
    setBookingsByResourcesIdsLoadStatus,
    resetBookingsByResourcesIds,
    updateWholeSeriesFromGrid,
};
