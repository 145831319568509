import { ProfileCompanyAttributes, ResourceCompanyAttributes } from 'features/Authentication/types';
import { ResourcesState } from 'features/ReferenceData/Redux/reducer';
import i18n from 'i18next';
import { isEmpty } from 'lodash';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { CustomFilter, GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { Resource, ResourcesGS } from 'services/ApiClients/Resource/Models';
import ToastService from 'services/ToastService';
import Guid from 'utilities/guid';

const getRestrictionsByAttribute = (
    resourceCompanyAttributes: ResourceCompanyAttributes[],
    profileCompanyAttributes: ProfileCompanyAttributes[]
): boolean => {
    const profileCompanyAttributesIds = profileCompanyAttributes.map(({ CompanyAttributeId }) => CompanyAttributeId);
    const isAttributeExist = resourceCompanyAttributes.some(({ id }) => profileCompanyAttributesIds.includes(id));

    if (resourceCompanyAttributes?.length && !profileCompanyAttributes?.length) {
        return false;
    }

    if (isAttributeExist) {
        const profileCompanyAttributesValues = profileCompanyAttributes?.reduce((acc, el) => {
            // eslint-disable-next-line no-param-reassign
            acc = [...acc, ...el.CompanyAttributeValues];
            return acc;
        }, [] as string[]);
        const resourceCompanyAttributesValues = resourceCompanyAttributes.reduce((acc, el) => {
            // eslint-disable-next-line no-param-reassign
            acc = [...acc, ...el.values];
            return acc;
        }, [] as string[]);

        return resourceCompanyAttributesValues.some((el) => profileCompanyAttributesValues.includes(el));
    }
    return false;
};

export const formatRestrictedResource = (
    resource: Resource,
    userId = '',
    cbRoles: string[] = [],
    companyAttributes: ProfileCompanyAttributes[] = []
): Resource => {
    let restricted;
    if (
        resource?.restrictedTo === null ||
        (isEmpty(resource?.restrictedTo.users) &&
            isEmpty(resource?.restrictedTo?.roles) &&
            isEmpty(resource?.restrictedTo?.companyAttributes))
    ) {
        return {
            ...resource,
            restricted: null,
        };
    }

    if (
        !isEmpty(resource?.restrictedTo?.users) ||
        (!isEmpty(resource?.restrictedTo?.roles) && !isEmpty(cbRoles)) ||
        !isEmpty(resource?.restrictedTo?.companyAttributes)
    ) {
        const isRestrictedByAttribute = getRestrictionsByAttribute(
            resource?.restrictedTo?.companyAttributes,
            companyAttributes
        );

        if (
            !resource.restrictedTo.users.includes(userId) === false ||
            !resource.restrictedTo.roles.some((el) => cbRoles.includes(el.toString())) === false ||
            isRestrictedByAttribute
        ) {
            restricted = false;
        } else {
            restricted = true;
        }

        return {
            ...resource,
            restricted,
        };
    }

    return {
        ...resource,
        restricted: null,
    };
};

export const mapResources = (
    response: ApiResponse<ResourcesGS[]>,
    geographicStructureIds: Guid[],
    userId = '',
    cbRoles: string[] | undefined,
    companyAttributes: ProfileCompanyAttributes[] | undefined
): any => {
    const resourcesGS = response?.payload as ResourcesGS[];
    return geographicStructureIds.reduce(
        (acc, id: Guid) => ({
            ...acc,
            [id.toString()]:
                resourcesGS
                    .find((gsItem) => gsItem.geographicStructureId.toString() === id.toString())
                    ?.resources.map((resource) =>
                        formatRestrictedResource(resource, userId, cbRoles, companyAttributes)
                    ) || [],
        }),
        {}
    );
};

const filterAvailableResourcesByTypes = (resources: Resource[] = [], types: number[]): Resource[] | [] =>
    resources.filter(({ resourceType }) => types.includes(resourceType));

export const formatAvailableResources = (
    area: GeographicStructureItem | null,
    resources: ResourcesState,
    availableAreas: GeographicStructureItem[],
    types: number[]
): ResourcesGS[] =>
    area
        ? [
              {
                  geographicStructureId: area.id,
                  resources: filterAvailableResourcesByTypes(resources.items[area.id.toString()], types),
              },
          ]
        : availableAreas.map((item) => ({
              geographicStructureId: item.id,
              resources: filterAvailableResourcesByTypes(resources.items[item.id.toString()], types),
          }));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const handleCreateBookingError = (error: any, bookingSlotMinutes?: number) => {
    if (
        error.response.status === 409 &&
        error.response.data.type.split('.').includes('ResourceAvailabilityReservationConflictException')
    ) {
        ToastService.Error({ message: i18n.t('Toast_BookingSlotNoLongerAvailable') });
    } else if (
        error.response.status === 409 &&
        error.response.data.type.split('.').includes('ResourceNotBookableException')
    ) {
        ToastService.Error({ message: i18n.t('Toast_CreateBookingOnUnavailableResource') });
    } else if (
        error.response.status === 403 &&
        error.response.data.type.split('.').includes('BookingTimeExceedsMaxNoticePeriodException')
    ) {
        ToastService.Error({ message: i18n.t('Toast_CreateBookingOnMaxNoticePeriodException') });
    } else if (
        error.response.status === 403 &&
        error.response.data.type.split('.').includes('ConcurrentBookingException')
    ) {
        ToastService.Warning({ message: i18n.t('Toast_CreateBookingOnConcurrentBookingException') });
    } else if (
        error.response.status === 400 &&
        error.response.data.type.split('.').includes('BookingTimeDoesNotMatchWithTimeSlotConfigurationException')
    ) {
        ToastService.Error({
            message: i18n.t('Toast_ErrorMinBookingSlotUnit', { bookingSlotMinutes }),
        });
    } else {
        ToastService.Error({ message: i18n.t('Toast_UnexpectedError') });
    }
};

export const filterAdvancedFiltersByResourceType = (
    equipment: { [k: string]: CustomFilter },
    resourceTypes: number[]
): any =>
    Object.keys(equipment).reduce(
        (acc, id) =>
            equipment[id].types.some((type) => resourceTypes.includes(type))
                ? { ...acc, [id]: equipment[id] }
                : { ...acc },
        {}
    );
