import React, { SyntheticEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { MenuItem } from '@mui/material';
import { ReactComponent as PencilIcon } from 'assets/icons/edit-pencil-icon.svg';
import { Loading } from 'components';
import { Button, DropDown, FormGroup, TextBox, Toggle } from 'components/FormElements';
import InputWrapper from 'components/Forms/InputWrapper';
import PersonDetailsPanel from 'components/PersonDetailsPanel/PersonDetailsPanel';
import { PersonSearchData } from 'components/PersonSearchPanels/interfaces/PersonSearchData';
import { OboPersonSearch } from 'components/PersonSearchPanels/OboPersonSearch/OboPersonSearch';
import { formatVisitorData, getSearchDataConditions } from 'components/PersonSearchPanels/OboPersonSearch/utils';
import { VisitorPanel } from 'components/VisitorPanel/VisitorPanel';
import { PersonType } from 'enums/PersonType';
import { CHARACTER_LIMIT, LOAD_STATUSES } from 'features/constants';
import RepeatSlider from 'features/Resources/Common/EditBooking/BookingInfo/Repeat/RepeatSlider';
import { resetSearchOboPersonsResult } from 'features/Resources/Common/EditBooking/Redux/actions/info';
import { DateTime } from 'luxon';
import { RepeatSchedule } from 'services/ApiClients/Booking';
import { ResourceType } from 'services/ApiClients/Resource';
import { isNil } from 'utilities/ts';

// eslint-disable-next-line import/no-cycle
import { SeriesInfoProps } from '.';

import './SeriesInfo.scss';

const SeriesInfo = ({
    commonInfo,
    bookingInfo,
    visitorData,
    disabled,
    updateRepeatBooking,
    createVisitor,
    editVisitor,
    searchInternalOboPersons,
    searchExternalOboPersons,
    handleSetBookingInfoDataState,
    bookingInfoDataState,
    bookingsByResourceLoadStatus,
}: SeriesInfoProps): JSX.Element => {
    const { register, setValue } = useFormContext();
    const { t } = useTranslation();
    const [isSliderOpen, setSliderOpen] = useState<boolean>(false);
    const [personSearchData, setPersonSearchData] = useState<PersonSearchData>({} as PersonSearchData);
    const [isNewVisitorPanelOpen, setNewVisitorPanelOpen] = useState<boolean>(false);
    const [isVisitorDetailsPanelOpen, setVisitorDetailsPanelOpen] = useState<boolean>(false);
    const [personType, setPersonType] = useState<PersonType>(
        visitorData.onBehalfOfPerson?.visitorId ? PersonType.External : PersonType.Internal
    );

    const {
        resourceTypeTenantConfiguration,
        resourceTypeSiteConfiguration,
        isCurrentUserBooking,
        canEditNotOwnBooking,
        canViewPrivateBookings,
        isOnBehalfOfUserBooking,
        canAddOnBehalfOf,
        selectedSite,
        dateTimeNow,
        timezone,
        resource,
        hourFormat,
    } = commonInfo;

    const { bookingId, isPrivate, resourceType, displayName, bookingDateTime, notes, selectedDate, repeatSchedule } =
        bookingInfo;

    const {
        isVisitorCreationOrEditingInProgress,
        searchOboPersonsResult,
        isSearchOboPersonInProgress,
        isVisitorCreated,
        isVisitorUpdated,
    } = visitorData;

    const { selectedPerson, isPrivateBooking, attendeesCount, bookingInfoNotes, bookingInfoTitle, isOBODeleted } =
        bookingInfoDataState;

    const handleUpdateRepeatBooking = (schedule?: RepeatSchedule): void => {
        if (!isNil(schedule)) {
            updateRepeatBooking({ bookingData: { schedule, bookingId } });
        }
        setSliderOpen(false);
    };

    useEffect(() => {
        setPersonSearchData({} as PersonSearchData);
        resetSearchOboPersonsResult();
    }, [personType, visitorData.onBehalfOfPerson]);

    useLayoutEffect(() => {
        handleSetBookingInfoDataState({
            ...bookingInfoDataState,
            selectedPerson: isOBODeleted ? null : selectedPerson || visitorData.onBehalfOfPerson,
            isPrivateBooking: isPrivateBooking ?? isPrivate,
        });
    }, [visitorData.onBehalfOfPerson, isPrivate]);

    useEffect(() => {
        if (!isVisitorCreationOrEditingInProgress && (isVisitorCreated || isVisitorUpdated)) {
            setNewVisitorPanelOpen(false);
            setVisitorDetailsPanelOpen(false);
        }
    }, [isVisitorCreationOrEditingInProgress]);

    const onVisitorSave = useCallback(
        (vsData: any): void => {
            const visitor = formatVisitorData(vsData, bookingInfo.onBehalfOfPerson?.id, selectedSite?.id.toString());
            handleSetBookingInfoDataState({
                ...bookingInfoDataState,
                selectedPerson: visitor,
            });

            if (isNewVisitorPanelOpen) {
                createVisitor(visitor);
            }

            if (isVisitorDetailsPanelOpen) {
                editVisitor(visitor);
            }
        },
        [isNewVisitorPanelOpen, isVisitorDetailsPanelOpen]
    );

    const onOboPersonSearch = useCallback(
        (searchData: PersonSearchData): void => {
            const searchDataConditions = getSearchDataConditions(searchData);

            if (personType === PersonType.Internal) {
                searchInternalOboPersons(searchDataConditions);
            } else {
                searchExternalOboPersons(searchDataConditions);
            }
        },
        [personSearchData, personType]
    );

    const handleSetPrivateBooking = (e: SyntheticEvent): void => {
        const target = e.target as HTMLInputElement;
        handleSetBookingInfoDataState({ ...bookingInfoDataState, isPrivateBooking: target.checked });
        setValue('isPrivate', target.checked);
    };

    const privateBookingCondition =
        isCurrentUserBooking || (canEditNotOwnBooking && canViewPrivateBookings) || isOnBehalfOfUserBooking;

    const isShowIsPrivateToggle: boolean =
        privateBookingCondition &&
        resourceTypeTenantConfiguration?.allowPrivateBookings &&
        !resourceTypeTenantConfiguration?.setAllBookingsAsPrivate;

    const isCanEditOboUserInOwnBooking =
        canAddOnBehalfOf && !canEditNotOwnBooking && (isCurrentUserBooking || isOnBehalfOfUserBooking);

    const isCanEditOboUserForAllBookings = canAddOnBehalfOf && canEditNotOwnBooking;

    const isLoading =
        !resourceTypeSiteConfiguration || isNil(bookingId) || bookingsByResourceLoadStatus === LOAD_STATUSES.LOADING;

    return isLoading ? (
        <Loading />
    ) : (
        <div className="series-info">
            <FormGroup disabled={disabled}>
                <TextBox
                    label={t('Resource_DisplayName_Label')}
                    inputName="displayName"
                    value={bookingInfoTitle ?? displayName}
                    {...(typeof register('displayName') === 'object' ? register('displayName') : {})}
                    onChange={(value: string) => {
                        setValue('displayName', value);
                        handleSetBookingInfoDataState({ ...bookingInfoDataState, bookingInfoTitle: value }, true);
                    }}
                    dataTestId="displayName-text"
                    maxLength={CHARACTER_LIMIT}
                    disabled={isPrivateBooking && !privateBookingCondition}
                />
                {(resourceType === ResourceType.ROOM.value || isShowIsPrivateToggle) && (
                    <InputWrapper name="attendeesAndPrivateBooking" horizontal>
                        {resourceType === ResourceType.ROOM.value && (
                            <DropDown
                                required
                                label={t('Resource_Attendees')}
                                inputName="attendees"
                                className="attendees"
                                value={attendeesCount || bookingInfo.attendeesCount.toString()}
                                {...(typeof register('attendeesCount') === 'object' ? register('attendeesCount') : {})}
                                onChange={(value) => {
                                    setValue('attendeesCount', value);
                                    handleSetBookingInfoDataState({ ...bookingInfoDataState, attendeesCount: value });
                                }}
                                dataTestId="Attendees_SeriesInfo"
                                disabled={
                                    bookingDateTime?.endDateTime.toISO() < dateTimeNow ||
                                    (isPrivateBooking && !privateBookingCondition)
                                }
                            >
                                {Array.from(Array(commonInfo?.resource?.capacity ?? 1)).map((x, index) => {
                                    const value = index + 1;
                                    return (
                                        <MenuItem value={value} key={value}>
                                            {value}
                                        </MenuItem>
                                    );
                                })}
                            </DropDown>
                        )}

                        {isShowIsPrivateToggle && (
                            <Toggle
                                label={t('Combined_BookingInfo_PrivateBooking')}
                                inputId="isPrivateBooking"
                                inputName="privateBookingToggle"
                                {...(typeof register('isPrivate') === 'object' ? register('isPrivate') : {})}
                                onChange={handleSetPrivateBooking}
                                dataTestId="toggle_privateBooking"
                                checked={isPrivateBooking}
                                value={`${isPrivateBooking}`}
                                type="checkbox"
                            />
                        )}
                    </InputWrapper>
                )}

                <OboPersonSearch
                    dataTestId="person-search"
                    buttonLabel={t('Resource_OnBehalfOf_Label')}
                    panelLabel={t('Search')}
                    resultPlaceHolder={
                        personType === PersonType.Internal
                            ? t('Resource_SearchByName')
                            : t('Resource_SearchByNameAndCompany')
                    }
                    {...(typeof register('onBehalfOfPerson') === 'object' ? register('onBehalfOfPerson') : {})}
                    onSelect={(value: any) => {
                        setValue('onBehalfOfPerson', isNil(value) ? null : value);
                        handleSetBookingInfoDataState({
                            ...bookingInfoDataState,
                            selectedPerson: value,
                            isOBODeleted: false,
                        });
                    }}
                    onSearch={onOboPersonSearch}
                    onSearchDataChange={(searchValue: object) => {
                        setPersonSearchData({ ...personSearchData, ...searchValue });
                    }}
                    searchResult={searchOboPersonsResult}
                    selectedItem={selectedPerson}
                    iaSearchInProgress={isSearchOboPersonInProgress}
                    resetSearchResult={resetSearchOboPersonsResult}
                    searchData={personSearchData}
                    personType={personType}
                    onNewVisitor={() => {
                        setNewVisitorPanelOpen(true);
                    }}
                    setNewVisitorPanelOpen={setNewVisitorPanelOpen}
                    onPersonTypeChange={setPersonType}
                    setPersonDetailsPanelOpen={setVisitorDetailsPanelOpen}
                    isDisabled={disabled}
                    isCanEditOboUserInOwnBooking={isCanEditOboUserInOwnBooking}
                    isCanEditOboUserForAllBookings={isCanEditOboUserForAllBookings}
                    isPrivate={isPrivateBooking}
                    canViewPrivateBookings={canViewPrivateBookings}
                    canAddOnBehalfOf={canAddOnBehalfOf}
                    handleSetBookingInfoDataState={handleSetBookingInfoDataState}
                    bookingInfoDataState={bookingInfoDataState}
                />
                <TextBox
                    className="notes"
                    label={t('Resource_Notes_Label')}
                    inputName="notes"
                    value={bookingInfoNotes ?? notes}
                    {...(typeof register('notes') === 'object' ? register('notes') : {})}
                    onChange={(value: string) => {
                        setValue('notes', value);
                        handleSetBookingInfoDataState({ ...bookingInfoDataState, bookingInfoNotes: value }, true);
                    }}
                    dataTestId="notes-textarea"
                    maxLength={4000}
                    rows={6}
                />
            </FormGroup>
            <FormGroup disabled={disabled}>
                <InputWrapper label="Repeat booking series" name="repeats">
                    <ReactMarkdown
                        className="repeat-summary-info"
                        source={bookingInfo?.repeatSchedule?.pattern?.friendlyScheduleText}
                    />
                    <Button
                        className="edit-repeat-button"
                        dataTestId="edit-repeat-button"
                        icon={PencilIcon}
                        text="Edit frequency"
                        onClick={() => setSliderOpen(true)}
                    />
                </InputWrapper>
            </FormGroup>
            <Controller
                name="repeatSchedule"
                defaultValue={null}
                render={(scheduleProps) => (
                    <RepeatSlider
                        isOpen={isSliderOpen}
                        onClose={(value: any) => {
                            handleUpdateRepeatBooking(value);
                            scheduleProps.onChange(value);
                        }}
                        dateTimeRangeValue={{
                            startDate: bookingDateTime?.startDateTime.setZone(timezone),
                            endDate: bookingDateTime?.endDateTime.setZone(timezone),
                        }}
                        selectedDate={selectedDate}
                        resourceTypeSiteConfiguration={resourceTypeSiteConfiguration}
                        resourceConfiguration={resource}
                        repeatSchedule={repeatSchedule}
                        isSelectedDateBookable={(current: DateTime) =>
                            !isNil(
                                commonInfo?.resourceTypeSiteConfiguration?.bookableDays[
                                    current.toFormat('cccc').toLowerCase()
                                ]
                            )
                        }
                        timezone={timezone}
                        hourFormat={hourFormat}
                    />
                )}
            />
            <VisitorPanel
                isOpen={isNewVisitorPanelOpen}
                setPanelOpen={setNewVisitorPanelOpen}
                creationOrUpdatingInProgress={isVisitorCreationOrEditingInProgress}
                onSave={onVisitorSave}
            />

            <PersonDetailsPanel
                title={t('PersonDetails_StaffDetails')}
                isOpen={isVisitorDetailsPanelOpen}
                setPanelOpen={setVisitorDetailsPanelOpen}
                person={selectedPerson || visitorData.onBehalfOfPerson}
                nameTitle={t('PersonDetails_Name')}
            />
        </div>
    );
};

export default SeriesInfo;
