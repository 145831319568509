import React from 'react';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import i18n from 'i18next';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { DateTime } from 'luxon';

import ReportFilterColumnHeader from '../../../components/ReportsFilterColumnHeader/ReportFilterColumnHeader';
import { ResourceType } from '../../../services/ApiClients/Resource';
import { AllListItemOption } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface } from '../interfaces';

export const itemSeparator = ', ';
export const formatDateRanges = (dateRanges: any[]): string =>
    dateRanges
        ?.map(
            (dateRange: any) =>
                `${DateTime.fromISO(dateRange.startDateTime).toFormat('dd MMM yyyy')} - ${DateTime.fromISO(
                    dateRange.endDateTime
                ).toFormat('dd MMM yyyy')}`
        )
        .join(itemSeparator);

export enum UnavailableReportCategoryEnumForView {
    EARLY_CHECK_IN = 'Early check in',
    COVID = 'Covid',
    CLEANING = 'Cleaning',
    SETUP = 'Setup',
    CLEARDOWN = 'Clear down',
}

export const getColumns = (
    showSiteGroups: boolean,
    handleFilterClick: (field: string, headerName: string) => void,
    sideBarFilter: ColumnFilterInterface,
    activeSideBarFilter: ActiveColumnFilterInterface
): GridColDef[] => [
    {
        field: 'name',
        headerName: i18n.t('Reports_ResourceName'),
        minWidth: 225,
        flex: 0.2,
    },
    {
        field: 'resourceType',
        headerName: i18n.t('Reports_ResourceType'),
        minWidth: 175,
        cellClassName: 'capitalize',
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.resourceType.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_ResourceType')}</p>
            ),
    },
    {
        field: 'description',
        headerName: i18n.t('Reports_ResourceDescription'),
        minWidth: 250,
        sortable: false,
        flex: 0.2,
    },
    ...(showSiteGroups
        ? [
              {
                  field: 'siteGroupName',
                  headerName: i18n.t('Reports_SiteGroup'),
                  minWidth: 150,
                  flex: 0.1,
                  valueGetter: (params: { row: { site: { siteGroup: { name: string } } } }) =>
                      params.row.site?.siteGroup?.name,
                  renderHeader: (params: GridColumnHeaderParams) =>
                      sideBarFilter.siteGroupName.enabled ? (
                          <ReportFilterColumnHeader
                              handleFilterClick={handleFilterClick}
                              field={params.colDef.field}
                              headerName={params.colDef.headerName || ''}
                              activeSideBarFilter={activeSideBarFilter}
                          />
                      ) : (
                          <p>{i18n.t('Reports_SiteGroup')}</p>
                      ),
              },
          ]
        : []),
    {
        field: 'siteName',
        headerName: i18n.t('Reports_Site'),
        minWidth: 225,
        flex: 0.2,
        valueGetter: (params) => params.row.site?.name,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.siteName.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Site')}</p>
            ),
    },
    {
        field: 'areaName',
        headerName: i18n.t('Reports_Area'),
        minWidth: 175,
        flex: 0.1,
        valueGetter: (params) => params.row.area?.name,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.areaName.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Area')}</p>
            ),
    },
    {
        field: 'enabled',
        headerName: i18n.t('Reports_Enabled'),
        minWidth: 175,
        flex: 0.1,
        cellClassName: 'capitalize',
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.enabled.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Enabled')}</p>
            ),
    },
    {
        field: 'unavailableUntilFurtherNotice',
        headerName: i18n.t('Reports_UnavailableUntilFurtherNotice'),
        minWidth: 275,
        flex: 0.2,
        cellClassName: 'capitalize',
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.unavailableUntilFurtherNotice.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_UnavailableUntilFurtherNotice')}</p>
            ),
    },
    {
        field: 'unavailableDates',
        headerName: i18n.t('Reports_UnavailableDates'),
        minWidth: 217,
        flex: 0.2,
        sortable: false,
        cellClassName: 'multiline',
        valueGetter: (params) => formatDateRanges(params.row.unavailableDates),
        renderCell: (params) => <div>{params.value}</div>,
    },
    {
        field: 'unavailableUntilFurtherNoticeCategory',
        headerName: i18n.t('Reports_UnavailableCategory'),
        minWidth: 225,
        flex: 0.2,
        valueGetter: (params): string =>
            UnavailableReportCategoryEnumForView[
                params.row.unavailableUntilFurtherNoticeCategory as keyof typeof UnavailableReportCategoryEnumForView
            ],
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.unavailableUntilFurtherNoticeCategory.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_UnavailableCategory')}</p>
            ),
    },
    {
        field: 'fixedEquipment',
        headerName: i18n.t('Reports_FixedEquipment'),
        minWidth: 175,
        flex: 0.1,
        sortable: false,
        cellClassName: 'multiline',
        valueGetter: (params) => params.row.equipment?.map((item: any) => item.name).join(itemSeparator),
        renderCell: (params) => <div>{params.value}</div>,
    },
    {
        field: 'restrictions',
        headerName: i18n.t('Reports_Restrictions'),
        minWidth: 225,
        flex: 0.2,
        sortable: false,
        valueGetter: (params) =>
            `${params.row.resourceRestrictedUsers
                ?.map((item: any) => `${item.concatenatedName}; `)
                .join('')}${params.row.resourceRestrictedRoles?.map((item: any) => `${item.name}; `).join('')}`,
        renderCell: (params) => <div>{params.value}</div>,
    },
    {
        field: 'isSetupCleardownEnabled',
        headerName: i18n.t('Reports_SetupAndClearDown'),
        minWidth: 225,
        flex: 0.2,
        cellClassName: 'capitalize',
    },
    {
        field: 'setupTimeInMin',
        headerName: i18n.t('Reports_SetupTime'),
        minWidth: 175,
        flex: 0.1,
    },
    {
        field: 'cleardownTimeInMin',
        headerName: i18n.t('Reports_ClearDownTime'),
        minWidth: 175,
        flex: 0.1,
    },
    {
        field: 'disabledAccess',
        headerName: i18n.t('Reports_DisabledAccess'),
        minWidth: 175,
        flex: 0.1,
        cellClassName: 'capitalize',
    },
    {
        field: 'capacity',
        headerName: i18n.t('Reports_Capacity'),
        minWidth: 175,
        flex: 0.1,
    },
];

const items = {
    id: true,
    name: true,
    resourceType: true,
    description: true,
    enabled: true,
    unavailableUntilFurtherNotice: true,
    unavailableUntilFurtherNoticeCategory: true,
    isSetupCleardownEnabled: true,
    setupTimeInMin: true,
    cleardownTimeInMin: true,
    capacity: true,
    disabledAccess: true,
    equipment: { id: true, name: true, category: true },
    site: { name: true, siteGroup: { name: true } },
    area: { name: true },
    resourceRestrictedUsers: { name: true, concatenatedName: true },
    resourceRestrictedRoles: { name: true },
    unavailableDates: { startDateTime: true, endDateTime: true, category: true },
};

export const buildQuery = (
    itemsPerPageAmount: number,
    activePage: number,
    sortOptions: any,
    reportsFilter: any,
    activeSideBarFilter?: ActiveColumnFilterInterface,
    isForExport = false,
    totalCount = 0,
    visibleColumns?: string[]
): string => {
    const { selectedSiteGroup, selectedSite, selectedArea } = reportsFilter;
    const ascSorting = new EnumType('ASC');
    const graphQLItems: { [key: string]: any } = { ...items };
    if (visibleColumns) {
        Object.keys(graphQLItems).forEach((key: string) => {
            if (!visibleColumns.includes(key)) {
                delete graphQLItems[key];
            }
        });
    }
    const siteObjectOrder = { site: { siteGroup: { name: ascSorting }, name: ascSorting }, area: { name: ascSorting } };

    const query = {
        resources: {
            __args: {
                skip: isForExport ? 0 : itemsPerPageAmount * (activePage - 1),
                take: isForExport ? totalCount : itemsPerPageAmount,
                siteGroupId: selectedSiteGroup?.value !== AllListItemOption.value ? selectedSiteGroup?.value : null,
                siteId: selectedSite?.value !== AllListItemOption.value ? selectedSite?.value : null,
                areaId: selectedArea?.value !== AllListItemOption.value ? selectedArea?.value : null,
                order: siteObjectOrder,
                where: {},
            },
            items: graphQLItems,
            totalCount: true,
        },
    };

    if (sortOptions?.length) {
        switch (sortOptions[0].field) {
            case 'siteGroupName':
                query.resources.__args.order.site.siteGroup.name = new EnumType(sortOptions[0].sort.toUpperCase());
                break;
            case 'siteName':
                query.resources.__args.order.site.name = new EnumType(sortOptions[0].sort.toUpperCase());
                break;
            case 'areaName':
                query.resources.__args.order.area.name = new EnumType(sortOptions[0].sort.toUpperCase());
                break;
            default:
                query.resources.__args.order = {
                    [sortOptions[0].field]: new EnumType(sortOptions[0].sort.toUpperCase()),
                    ...query.resources.__args.order,
                };
        }
    }

    let where = {};

    if (reportsFilter?.selectedResourceType.value !== AllListItemOption.value) {
        where = {
            ...where,
            resourceType: { eq: reportsFilter.selectedResourceType.label },
        };
    }

    if (activeSideBarFilter?.siteName?.values) {
        where = {
            ...where,
            site: {
                id: { in: activeSideBarFilter.siteName.values },
            },
        };
    }

    if (activeSideBarFilter?.resourceType?.values) {
        where = {
            ...where,
            resourceType: {
                in: activeSideBarFilter.resourceType.values.map((item: string) => {
                    let newItem = item;
                    if (item === ResourceType.DESK.value.toString()) {
                        newItem = ResourceType.DESK.name;
                    }
                    if (item === ResourceType.ROOM.value.toString()) {
                        newItem = ResourceType.ROOM.name;
                    }
                    return newItem;
                }),
            },
        };
    }

    if (activeSideBarFilter?.siteGroupName?.values) {
        where = {
            ...where,
            site: {
                siteGroup: { id: { in: activeSideBarFilter.siteGroupName.values } },
            },
        };
    }

    if (activeSideBarFilter?.areaName?.values) {
        where = {
            ...where,
            area: {
                id: { in: activeSideBarFilter.areaName.values },
            },
        };
    }

    if (activeSideBarFilter?.enabled?.values && activeSideBarFilter?.enabled?.values.length === 1) {
        where = {
            ...where,
            enabled: { eq: Boolean(+activeSideBarFilter.enabled.values[0]) },
        };
    }

    if (
        activeSideBarFilter?.unavailableUntilFurtherNotice?.values &&
        activeSideBarFilter?.unavailableUntilFurtherNotice?.values.length === 1
    ) {
        where = {
            ...where,
            unavailableUntilFurtherNotice: {
                eq: Boolean(+activeSideBarFilter.unavailableUntilFurtherNotice.values[0]),
            },
        };
    }

    if (activeSideBarFilter?.unavailableUntilFurtherNoticeCategory?.values) {
        where = {
            ...where,
            unavailableUntilFurtherNoticeCategory: {
                in: activeSideBarFilter.unavailableUntilFurtherNoticeCategory.values.map(
                    (item: string) => new EnumType(item)
                ),
            },
        };
    }

    if (Object.keys(where).length === 0) {
        delete query.resources.__args.where;
    } else {
        query.resources.__args.where = where;
    }

    return jsonToGraphQLQuery(query);
};
