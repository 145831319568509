import { connect } from 'react-redux';
import { loginFailure, loginSuccess } from 'features/Authentication/Redux/actions';

// eslint-disable-next-line import/no-cycle
import Login from './Login';

const mapDispatchToProps = {
    onSuccess: (returnUrl: string) => loginSuccess(returnUrl),
    onFailure: () => loginFailure(),
};

export type LoginProps = typeof mapDispatchToProps & { action: string };

export default connect(null, mapDispatchToProps)(Login);
