import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as CancelIcon } from 'assets/icons/reception/Cancel.svg';
import { ReactComponent as CheckInIcon } from 'assets/icons/reception/CheckIn.svg';
import { ReactComponent as CheckOutIcon } from 'assets/icons/reception/CheckOut.svg';
import { ReactComponent as EditBookingIcon } from 'assets/icons/reception/EditBooking.svg';
import { ReactComponent as ExternalVisitorIcon } from 'assets/icons/visitor/ExternalVisitor.svg';
import { ReactComponent as InternalVisitorIcon } from 'assets/icons/visitor/InternalVisitor.svg';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';

import { createPrimaryButton, createSecondaryButton } from '../../../../components/ActionButtons/Models/ActionButton';
import Dialog from '../../../../components/Dialog';
import Button from '../../../../components/FormElements/Button';
import { SiteVisit } from '../../../../services/ApiClients/VisitorManagement/Models/SiteVisit';

import './BookingViewItem.scss';

interface BookingViewItemProps {
    siteVisitItem: SiteVisit;
    isToday: boolean;
    openCheckInPanel: Function;
    onCheckOut: (siteVisitId: string, cancelFutureSiteVisits: boolean) => void;
    onCancelBooking: (siteVisitId: string, isMultiDay?: boolean) => void;
    onVisitorClick: Function;
    onHostClick: Function;
    timezone: string;
    hourFormat: string;
    sites?: GeographicStructureItem[];
}

export const CheckInStatus = { CheckedIn: 0, CheckedOut: 1, Due: 2 };

const BookingViewItem = ({
    siteVisitItem,
    isToday,
    openCheckInPanel,
    onCheckOut,
    onCancelBooking,
    timezone,
    onVisitorClick,
    onHostClick,
    hourFormat,
    sites,
}: BookingViewItemProps): JSX.Element => {
    const isInternalVisitor = siteVisitItem.visitor.visitorType === 'Internal';
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [checkoutDialogOpen, setCheckoutDialogOpen] = useState<boolean>(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);

    const isDue = !siteVisitItem.visitorCheckedIn && !siteVisitItem.visitorCheckedOut;
    const isCheckedIn = siteVisitItem.visitorCheckedIn && !siteVisitItem.visitorCheckedOut;
    const isCheckedOut = siteVisitItem.visitorCheckedOut;
    const { isMultiDaySeriesCancelable } = siteVisitItem;
    const isMultiDay = !!siteVisitItem.multiDaySiteVisitId;

    const buttonsForMultiDayCheckOutConfirmation = useMemo(
        () => (
            <div className="multiday-dialog-buttons">
                <Button
                    text={t('VisitorBooking_CheckOutTodayOnly')}
                    dataTestId="check-out"
                    onClick={() => {
                        onCheckOut(siteVisitItem.id, false);
                        setCheckoutDialogOpen(false);
                    }}
                    danger
                />
                <Button
                    text={t('VisitorBooking_CheckOutTodayAndCancelSeries')}
                    dataTestId="check-out"
                    onClick={() => {
                        onCheckOut(siteVisitItem.id, true);
                        setCheckoutDialogOpen(false);
                    }}
                    primary
                />
            </div>
        ),
        []
    );

    const cancelConfirmDialog = (): JSX.Element => {
        const buttonsForSingle = [
            createSecondaryButton('no', t('Button_No'), () => setCancelDialogOpen(false)),
            createPrimaryButton('yes', t('Button_Yes'), () => {
                onCancelBooking(siteVisitItem.id);
                setCheckoutDialogOpen(false);
            }),
        ];

        const buttonsForMulti = [
            createSecondaryButton('keep', t('Reception_KeepSeries'), () => setCancelDialogOpen(false)),
            createPrimaryButton('cancel', t('Reception_CancelSeries'), () => {
                onCancelBooking(siteVisitItem.multiDaySiteVisitId, true);
                setCheckoutDialogOpen(false);
            }),
        ];

        const message = siteVisitItem.multiDaySiteVisitId
            ? t('CancelConfirmationMultiDay')
            : t('Reception_CancelBookingConfirmation');

        return (
            <Dialog
                message={message}
                buttons={siteVisitItem.multiDaySiteVisitId ? buttonsForMulti : buttonsForSingle}
                description={siteVisitItem.multiDaySiteVisitId ? t('CancelConfirmationMultiDayDescription') : ''}
            />
        );
    };

    const checkOutConfirmDialog = (): JSX.Element => {
        const buttonsForSingle = [
            createSecondaryButton('no', t('Button_No'), () => setCheckoutDialogOpen(false)),
            createPrimaryButton('yes', t('Button_Yes'), () => {
                onCheckOut(siteVisitItem.id, false);
                setCheckoutDialogOpen(false);
            }),
        ];

        const message = siteVisitItem.multiDaySiteVisitId
            ? t('Reception_CheckOutConfirmationMultiDay')
            : t('Reception_CheckOutConfirmation');

        return (
            <Dialog
                className="checkout-confirmation"
                message={message}
                buttons={buttonsForSingle}
                customButtons={siteVisitItem.multiDaySiteVisitId ? buttonsForMultiDayCheckOutConfirmation : null}
                description={siteVisitItem.multiDaySiteVisitId ? t('Reception_CheckOutConfirmationWhatToDo') : ''}
                showCloseIcon={!!siteVisitItem.multiDaySiteVisitId}
                closeDialog={() => setCheckoutDialogOpen(false)}
            />
        );
    };

    const navigateToEditVisitorHandler = (): void => {
        const { visitor, id, dueDateTime, multiDaySiteVisitId, visitorCheckedIn, visitorCheckedOut, isCanceled } =
            siteVisitItem;
        const { firstname, surname, visitorType } = visitor;
        const siteInformation =
            sites?.find((site) => site.id.toString() === siteVisitItem.geographicStructureId.toString()) ||
            ({} as GeographicStructureItem);

        const editVisitorLocationState = {
            isEdit: true,
            visitorInfo: `${firstname} ${surname}`,
            visitorType,
            siteVisitId: id,
            visitor,
            dueDateTime,
            previousPath: location.pathname,
            multiDaySiteVisitId,
            isMultiDaySeriesCancelable,
            siteInformation,
            visitorCheckedIn,
            visitorCheckedOut,
            isCanceled,
        };
        navigate(`/visitor-booking/${id.toString()}`, { state: editVisitorLocationState, replace: true });
    };
    return (
        <div className="booking-view-item">
            {checkoutDialogOpen && checkOutConfirmDialog()}
            {cancelDialogOpen && cancelConfirmDialog()}

            <div
                className={classNames('booking-type-indicator', {
                    internal: isInternalVisitor,
                    external: !isInternalVisitor,
                })}
            />
            <div className="column-item label-time">
                {isDue && (
                    <>
                        <div className="label due">{t('Reception_Due')}</div>{' '}
                        <div className="time">
                            {DateTime.fromISO(siteVisitItem.dueDateTime).setZone(timezone).toFormat(hourFormat)}
                        </div>
                    </>
                )}

                {isCheckedIn && (
                    <>
                        <div className="label checked-in">{t('Reception_CheckedIn')}</div>
                        <div className="time">
                            {DateTime.fromISO(siteVisitItem.visitorCheckedInDate)
                                .setZone(timezone)
                                .toFormat(hourFormat)}
                        </div>
                    </>
                )}
                {isCheckedOut && (
                    <>
                        <div className="label checked-out">{t('Reception_CheckedOut')}</div>
                        <div className="time">
                            {DateTime.fromISO(siteVisitItem.visitorCheckedOutDate)
                                .setZone(timezone)
                                .toFormat(hourFormat)}
                        </div>
                    </>
                )}
            </div>

            <div className="column-item visitor">
                <div
                    className={classNames('visitor-icon', {
                        internal: isInternalVisitor,
                        external: !isInternalVisitor,
                    })}
                >
                    {isInternalVisitor ? <InternalVisitorIcon /> : <ExternalVisitorIcon />}
                </div>
                <div className="name-n-company">
                    <button
                        type="button"
                        className="name"
                        data-testid="visitor-name"
                        onClick={() => onVisitorClick(siteVisitItem)}
                    >{`${siteVisitItem.visitor.surname}, ${siteVisitItem.visitor.firstname}`}</button>
                    <div className="company">{siteVisitItem.visitor.company}</div>
                </div>
            </div>

            <div className="column-item host">
                {siteVisitItem.host && (
                    <div className="name-n-phone">
                        <button
                            type="button"
                            className="name"
                            data-testid="host-name"
                            onClick={() => onHostClick(siteVisitItem)}
                        >{`${siteVisitItem.host.surname}, ${siteVisitItem.host.firstname}`}</button>
                        <div className="phone">{siteVisitItem.host.mobile}</div>
                    </div>
                )}
            </div>
            <div className="booking-item-buttons">
                {isDue && (
                    <>
                        {(!isMultiDay || isMultiDaySeriesCancelable) && (
                            <button
                                type="button"
                                data-testid="cancel-booking-button"
                                className={classNames('item-button', 'cancel-visit-btn')}
                                onClick={() => setCancelDialogOpen(true)}
                            >
                                <CancelIcon />
                                <div>{t('Reception_CancelBooking')}</div>
                            </button>
                        )}
                        <button
                            type="button"
                            data-testid="edit-booking-button"
                            className={classNames('item-button', 'edit-visit-btn')}
                            onClick={navigateToEditVisitorHandler}
                        >
                            <EditBookingIcon />
                            <div>{t('Reception_EditBooking')}</div>
                        </button>
                        <button
                            type="button"
                            data-testid="checkin-button"
                            className={classNames('item-button', 'checkin-btn', { disabled: !isToday })}
                            onClick={() => isToday && openCheckInPanel(siteVisitItem)}
                        >
                            <CheckInIcon />
                            <div>{t('Reception_CheckIn')}</div>
                        </button>
                    </>
                )}

                {isCheckedIn && (
                    <button
                        type="button"
                        data-testid="checkout-button"
                        className={classNames('item-button', 'checkout-btn')}
                        onClick={() => setCheckoutDialogOpen(true)}
                    >
                        <CheckOutIcon />
                        <div>{t('Reception_CheckOut')}</div>
                    </button>
                )}
            </div>
        </div>
    );
};

export default BookingViewItem;
