import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { ListItem } from 'components/Forms';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';

import { ReactComponent as CrossCircle } from '../../../assets/icons/CrossCircle.svg';
import ActionButtonsPanel from '../../../components/ActionButtonsPanel';
import DataTable from '../../../components/DataTable';
import Pagination from '../../../components/DataTable/Pagination';
import Button from '../../../components/FormElements/Button';
import DateRangePicker from '../../../components/FormElements/DatePicker/DateRangePicker';
import InputWrapper from '../../../components/FormElements/InputWrapper';
import EditColumnsForm from '../../../components/Forms/EditColumnsForm';
import WithLoading from '../../../components/HOC/WithLoading';
import { PersonSearchData } from '../../../components/PersonSearchPanels/interfaces/PersonSearchData';
import UserSearchForm from '../../../components/PersonSearchPanels/VmsPersonSearch/SearchForm/SearchForm';
import ReportFilterPanel from '../../../components/ReportFilterPanel/ReportFilterPanel';
import Header from '../../../components/SiteSubHeader';
import SlidingPanel from '../../../components/SlidingPanel';
import { PersonType } from '../../../enums/PersonType';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { Person } from '../../../services/ApiClients/Models';
import { AllListItemOption } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface, GridSettings, ReportsActiveColumn } from '../interfaces';
import {
    capitalizeFirstLetter,
    convertDateForExport,
    convertTimeForExport,
    createValuesArray,
    generateAndDownloadReportExportFile,
    onUserSearch,
    resultPerPageListItems,
} from '../utils';

// eslint-disable-next-line import/no-cycle
import { LoginsReportProps } from './index';
import { buildQuery, getColumns } from './loginsReportUtils';

import './LoginsReport.scss';

const LoginsReport = ({
    isReportGenerationInProgress,
    generateReport,
    reportData,
    searchUsers,
    searchUsersResult,
    resetUsersSearch,
    isSearchUsersInProgress,
    timeFormat,
    isGenerationReportForExportInProgress,
    reportDataForExport,
    generateReportForExport,
    is24HoursTimeFormat,
}: LoginsReportProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation() as any;

    const [gridSettings, setGridSettings] = useState<GridSettings>({
        page: '1',
        resultPerPage: resultPerPageListItems[2],
        sortOptions: [{ field: 'name', sort: 'asc' }],
    });

    const defaultFilter = {
        dateRange: { from: DateTime.now(), to: DateTime.now() },
    };

    const dateRange = location.state
        ? {
              from: DateTime.fromISO(location.state.dateRange.from),
              to: DateTime.fromISO(location.state.dateRange.to),
          }
        : null;

    const isSSOListItems = [
        { value: '0', label: 'False' },
        { value: '1', label: 'True' },
    ];

    const deviceLoggedInListItems = [
        { value: 'Book', label: 'Book' },
        { value: 'Manage', label: 'Manage' },
        { value: 'Mobile', label: 'Mobile' },
    ];

    const [isUsersSearchPanelOpen, setUserSearchPanelOpen] = useState<boolean>(false);
    const [userSearchData, setUserSearchData] = useState<any>({});

    const [appliedReportsFilter, setAppliedReportsFilter] = useState<any>(
        location.state ? { ...location.state, dateRange } : defaultFilter
    );
    const [reportsFilter, setReportsFilter] = useState<any>(
        location.state ? { ...location.state, dateRange } : defaultFilter
    );

    const [isFiltersOpened, setIsFiltersOpened] = useState(false);
    const [columnsFilter, setColumnsFilter] = useState<ColumnFilterInterface>({});
    const [activeColumnsFilter, setActiveColumnsFilter] = useState<ActiveColumnFilterInterface>({});

    const [activeFilter, setActiveFilter] = useState('');
    const [activeFilterHeaderName, setActiveFilterHeaderName] = useState('');
    const [activeColumnsFilterArray, setActiveColumnsFilterArray] = useState<any[]>([]);

    const defaultColumnsFilter: ColumnFilterInterface = {
        deviceLoggedIn: {
            values: [...createValuesArray(deviceLoggedInListItems)],
            enabled: true,
        },
        isSso: {
            values: [...createValuesArray(isSSOListItems)],
            enabled: true,
        },
    };

    useEffect(() => {
        setColumnsFilter({
            ...columnsFilter,
            ...defaultColumnsFilter,
        });
    }, [reportsFilter]);

    const updateColumnsFilter = (columnName: string, values: any[]): void => {
        setColumnsFilter({
            ...columnsFilter,
            [columnName]: {
                ...columnsFilter[columnName],
                values,
            },
        });
    };

    const handleFilterClick = (columnName: string, headerName: string): void => {
        setIsFiltersOpened(true);
        setActiveFilter(columnName);
        setActiveFilterHeaderName(headerName);

        if (columnName in activeColumnsFilter) {
            updateColumnsFilter(columnName, [...activeColumnsFilter[columnName].values]);
        } else {
            updateColumnsFilter(columnName, [...defaultColumnsFilter[columnName].values]);
        }
    };

    const renderSideBar = (): void => {
        switch (activeFilter) {
            case 'deviceLoggedIn':
                setActiveColumnsFilterArray(deviceLoggedInListItems);
                break;
            case 'isSso':
                setActiveColumnsFilterArray(isSSOListItems);
                break;
            default:
                setActiveColumnsFilterArray([]);
        }
    };

    useEffect(() => {
        if (activeFilter) {
            renderSideBar();
        }
    }, [activeFilter]);

    const generateReportWithColumnsFilter = (): void => {
        setIsFiltersOpened(!isFiltersOpened);
        const newActiveColumnFilter = {
            ...activeColumnsFilter,
            [activeFilter]: { values: columnsFilter[activeFilter].values },
        };
        setActiveColumnsFilter(newActiveColumnFilter);
        generateReport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                newActiveColumnFilter
            )
        );
    };

    const applyFilterAndGenerateReport = useCallback(
        (reportsFilterParam?: any) => {
            setAppliedReportsFilter(reportsFilterParam || reportsFilter);
            setGridSettings({ ...gridSettings, page: '1' });
            generateReport(
                buildQuery(
                    Number(gridSettings.resultPerPage.value),
                    1,
                    gridSettings.sortOptions,
                    reportsFilterParam || reportsFilter
                )
            );
        },
        [reportsFilter, gridSettings]
    );

    const loadPage = (gridState: GridSettings): void => {
        generateReport(
            buildQuery(
                Number(gridState.resultPerPage.value),
                Number(gridState.page),
                gridState.sortOptions,
                appliedReportsFilter,
                activeColumnsFilter
            )
        );
    };

    const setGridStateAndLoadPage = (newGridState: GridSettings): void => {
        setGridSettings(newGridState);
        loadPage(newGridState);
    };

    useEffect(() => {
        loadPage(gridSettings);
    }, []);

    const defaultActiveColumns = getColumns(
        timeFormat,
        handleFilterClick,
        columnsFilter,
        activeColumnsFilter,
        is24HoursTimeFormat
    ).map((item) => ({
        name: item.headerName,
        active: true,
    }));

    const [isEditColumnsPanelOpen, setEditColumnsPanelOpen] = useState<boolean>(false);
    const [activeColumns, setActiveColumns] = useState<ReportsActiveColumn[]>(defaultActiveColumns);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    useEffect(() => {
        setColumns(getColumns(timeFormat, handleFilterClick, columnsFilter, activeColumnsFilter, is24HoursTimeFormat));
    }, [columnsFilter, activeColumnsFilter]);

    const applyNewActiveColumns = (newColumns: ReportsActiveColumn[]): void => {
        setActiveColumns(newColumns);
    };

    const generateReportForExportCallBack = useCallback(() => {
        generateReportForExport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                activeColumnsFilter,
                true,
                reportData?.totalCount
            )
        );
    }, [gridSettings, reportData, gridSettings, columns, activeColumnsFilter]);

    const formatReportForExport = (report?: any[]): any => {
        if (!report) {
            return [];
        }

        const visibleColumns = columns.map((c) => c.field);
        const formattedReport = report.map((i) => {
            return {
                ...(visibleColumns.includes('name') && { name: i.userProfile?.concatenatedName }),
                ...(visibleColumns.includes('email') && { email: i.userProfile?.email }),
                ...(visibleColumns.includes('loginDateTime') && {
                    loginDateTime: convertDateForExport(i.loginDateTime),
                }),
                ...(visibleColumns.includes('loginTime') && {
                    loginTime: convertTimeForExport(i.loginDateTime),
                }),
                ...(visibleColumns.includes('isSso') && {
                    isSso: capitalizeFirstLetter(i.userProfile?.isSso.toString()),
                }),
                ...(visibleColumns.includes('deviceLoggedIn') && {
                    deviceLoggedIn: capitalizeFirstLetter(i.deviceLoggedIn),
                }),
            };
        });

        return formattedReport;
    };

    useDidMountEffect(() => {
        if (!isGenerationReportForExportInProgress) {
            const formattedData = formatReportForExport(reportDataForExport?.items);
            generateAndDownloadReportExportFile('Logins Report', columns, formattedData);
        }
    }, [isGenerationReportForExportInProgress]);

    useEffect(() => {
        const gridColumns = getColumns(
            timeFormat,
            handleFilterClick,
            columnsFilter,
            activeColumnsFilter,
            is24HoursTimeFormat
        );
        const filteredColumns = gridColumns.filter(
            (item) => activeColumns.find((e) => e.name === item.headerName)?.active
        );
        setColumns(filteredColumns);
    }, [activeColumns, columnsFilter]);

    useEffect(() => {
        if (isUsersSearchPanelOpen) {
            if (userSearchData.name) {
                setUserSearchData({ ...userSearchData, name: '' });
            }
        }
    }, [isUsersSearchPanelOpen]);

    return (
        <main className="logins-report">
            <Header
                title={t('LoginsReport_Title')}
                dataTestId="logins-report-title"
                className="logins-report-title"
                rightSideItems={[
                    <div className="edit-btn-n-date-range">
                        <DateRangePicker
                            dataTestId="date-range-picker"
                            dateRange={reportsFilter.dateRange}
                            onChange={(newDateRange) => {
                                const newFilter = { ...reportsFilter, dateRange: newDateRange };
                                setReportsFilter(newFilter);
                                setActiveColumnsFilter({});
                                applyFilterAndGenerateReport(newFilter);
                            }}
                            name="date-range-picker"
                            title={t('Reports_LogInsBetween')}
                            required={false}
                            futureDatesAvailable={false}
                        />
                        <Button
                            dataTestId="edit-columns-btn"
                            text={t('Reports_EditColumns')}
                            className="edit-btn"
                            onClick={() => {
                                setEditColumnsPanelOpen(true);
                            }}
                        />
                    </div>,
                ]}
            />
            <WithLoading isLoading={isReportGenerationInProgress || isGenerationReportForExportInProgress}>
                <div className="filter-options">
                    <InputWrapper label={t('Reports_User')} inputName="user">
                        <button type="button" className="side-panel-input" data-testid="">
                            <div
                                data-testid="user"
                                className="selected-item"
                                onClick={() => {
                                    resetUsersSearch();
                                    setUserSearchPanelOpen(!isUsersSearchPanelOpen);
                                }}
                            >
                                {reportsFilter.user && reportsFilter.user === AllListItemOption.label
                                    ? reportsFilter.user
                                    : `${reportsFilter.user.firstname} ${reportsFilter.user.surname}`}
                            </div>
                            <div
                                data-testid="remove-user"
                                className="cross-icon"
                                onClick={() => {
                                    setReportsFilter({ ...reportsFilter, user: AllListItemOption.label });
                                }}
                            >
                                <CrossCircle />
                            </div>
                        </button>
                    </InputWrapper>
                    <Button
                        text={t('Button_Apply')}
                        type="submit"
                        className="apply-btn"
                        dataTestId="apply-btn"
                        disabled={isEqual(reportsFilter, appliedReportsFilter)}
                        onClick={applyFilterAndGenerateReport}
                    />
                </div>

                <DataTable
                    columns={columns}
                    rowsData={(activeColumns.some((column) => column.active) && reportData?.items) || []}
                    onSort={(sortOptions: any) => setGridStateAndLoadPage({ ...gridSettings, sortOptions })}
                    sortModel={gridSettings.sortOptions}
                    page={gridSettings.page}
                />

                <ActionButtonsPanel
                    leftSideElement={
                        <Pagination
                            pageString={gridSettings.page}
                            total={reportData?.totalCount || 0}
                            onPageChange={(page: string) => setGridStateAndLoadPage({ ...gridSettings, page })}
                            onPerPageChange={(perPage: ListItem) =>
                                setGridStateAndLoadPage({ ...gridSettings, resultPerPage: perPage, page: '1' })
                            }
                            resultPerPageOptions={resultPerPageListItems}
                            selectedResultPerPage={gridSettings.resultPerPage}
                        />
                    }
                >
                    <>
                        <Button
                            text={t('Button_Back')}
                            className="back-btn"
                            dataTestId="back-btn"
                            onClick={() => navigate(`/reports`)}
                        />
                        <Button
                            primary
                            text={t('Button_ExportReport')}
                            className="export-btn"
                            dataTestId="export-btn"
                            onClick={() => generateReportForExportCallBack()}
                            disabled={reportData?.items?.length === 0}
                        />
                    </>
                </ActionButtonsPanel>

                <SlidingPanel
                    title={t('Reports_SearchUsers')}
                    className="sliding-panel"
                    open={isUsersSearchPanelOpen}
                    showBackgroundMask
                    onClose={() => {
                        setUserSearchPanelOpen(false);
                    }}
                >
                    <WithLoading isLoading={isSearchUsersInProgress}>
                        <UserSearchForm
                            searchData={userSearchData as PersonSearchData}
                            onSearchDataChange={setUserSearchData}
                            onSearch={(searchData: PersonSearchData) => onUserSearch(searchData, searchUsers)}
                            onSelect={(user: Person) => {
                                setReportsFilter({ ...reportsFilter, user });
                                setActiveColumnsFilter({});
                            }}
                            searchResult={searchUsersResult}
                            resultPlaceHolder={t('Reports_SearchForUsers')}
                            setSearchPanelOpen={setUserSearchPanelOpen}
                            personType={PersonType.Internal}
                            companySearch={false}
                        />
                    </WithLoading>
                </SlidingPanel>

                <SlidingPanel
                    title={t('Reports_CustomColumns')}
                    open={isEditColumnsPanelOpen}
                    showBackgroundMask
                    className="sliding-panel"
                    onClose={() => {
                        setEditColumnsPanelOpen(false);
                    }}
                >
                    <EditColumnsForm
                        activeColumns={activeColumns}
                        applyNewActiveColumns={applyNewActiveColumns}
                        isEditColumnsPanelOpen={isEditColumnsPanelOpen}
                        setEditColumnsPanelOpen={setEditColumnsPanelOpen}
                    />
                </SlidingPanel>

                <ReportFilterPanel
                    isFiltersOpened={isFiltersOpened}
                    activeFilterHeaderName={activeFilterHeaderName}
                    setIsFiltersOpened={setIsFiltersOpened}
                    activeFilter={activeFilter}
                    activeColumnsFilterArray={activeColumnsFilterArray}
                    setColumnsFilter={setColumnsFilter}
                    columnsFilter={columnsFilter}
                    generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                />
            </WithLoading>
        </main>
    );
};

export default LoginsReport;
