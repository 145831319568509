import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import BackgroundMask from 'components/BackgroundMask';
import usePortal from 'hooks/usePortal';

import './SignOutPortal.scss';

const SignOutPortal = (): JSX.Element => {
    const { t } = useTranslation();
    const modalRootElement = usePortal();

    return (
        <>
            <BackgroundMask className="open" />
            {createPortal(
                <div className="sign-out-container">
                    <div className="sign-out-header">
                        <h1>{t('SignOutPortal_Title')}</h1>
                    </div>
                    <div className="sign-out-content">
                        <ReactMarkdown source={t('SignOutPorta_Description') || ''} />
                        <Link className="sign-out-button" to="/authentication/logout" data-testid="sign-out-button">
                            {t('UserProfileSummary_LogOut')}
                        </Link>
                    </div>
                </div>,
                modalRootElement,
                'signOutModal'
            )}
        </>
    );
};

export default SignOutPortal;
