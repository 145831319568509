import { getConfiguration } from 'utilities/appConfigsUtils';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';

import { SearchRequestQuery, SearchResponse } from './Models/SearchResponse';

export class SearchApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().SEARCH_API;

        return `${baseUrl}/api/v1`;
    };

    public static search = async (requestQuery: SearchRequestQuery): Promise<ApiResponse<SearchResponse>> => {
        if (!requestQuery) throw new Error('You must provide a valid search query');

        const searchString = requestQuery?.searchString
            ? `?SearchString=${requestQuery.searchString}`
            : '?SearchString=';
        const SearchType = requestQuery?.searchType ? `&SearchType=${requestQuery.searchType}` : '&SearchType=0';
        const NumberOfResults = requestQuery?.numberOfResults
            ? `&NumberOfResults=${requestQuery.numberOfResults}`
            : '&NumberOfResults=101';

        return SearchApi.get(
            `${this.BuildBaseUrl()}/Search/resource-colleague-visitor${searchString}${SearchType}${NumberOfResults}`
        );
    };
}
