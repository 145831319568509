export enum SearchRadioButtonsTypes {
    ALL,
    PEOPLE,
    DESKS,
    ROOMS,
}

export enum SearchDiscriminator {
    BOOKING = 'Booking',
    RESOURCE = 'Resource',
}

export enum SearchPersonType {
    VISITOR = 'Visitor',
    PERSON = 'Person',
}
