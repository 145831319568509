import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowParams } from '@mui/x-data-grid';
import DataTable from 'components/DataTable';
import { v4 as uuidv4 } from 'uuid';

import { getRepeatInfoColumns } from './repeatInfoUtils';

import './RepeatInfo.scss';

export interface RepeatInfoProps {
    items: any[];
    resourceType: string;
    emptyMessage: string;
}

const RepeatInfo = ({ items, resourceType, emptyMessage }: RepeatInfoProps): JSX.Element => {
    const { t } = useTranslation();
    const columns = useMemo(() => getRepeatInfoColumns(resourceType), [resourceType, items]);

    return (
        <main className="repeat-info-page">
            {items.length ? (
                <DataTable
                    columns={columns}
                    rowsData={items || []}
                    getRowId={() => uuidv4()}
                    getRowClassName={(params: GridRowParams) => (params.row.isSelected ? 'selectedRow' : '')}
                />
            ) : (
                <span className="no-repeats-message">{t(`${emptyMessage}`)}</span>
            )}
        </main>
    );
};

export default RepeatInfo;
