import { ALL_DAY, AM, AM_PM, CUSTOM_DURATION, PM } from 'features/constants';
import i18n from 'i18next';
import { range } from 'lodash';
import { DateTime } from 'luxon';
import { CombinedConfiguration } from 'services/ApiClients/Configuration';
import DateTimeService, { TimeSlotOption } from 'services/DateTimeService';
import {
    getEndDateByConfigurationType,
    getIsSameDayStartDate,
    getStartDateByConfigurationType,
} from 'utilities/floorplanUtils';

import { CardProps } from './Timeline/TimeSlotCard';

export interface SlotDuration {
    value: string | number;
    label: string;
    dataTestId: string;
}

export interface DurationDateTime {
    startDateTime: DateTime;
    endDateTime: DateTime;
}

export const SLOT_MULTIPLIER = 2;
const MINUTES_IN_HOUR = 60;

export const getSlotDurations = (slotInMunites: number): SlotDuration[] => [
    {
        value: ALL_DAY,
        label: 'All-day',
        dataTestId: 'all-day-radio-btn',
    },
    {
        value: AM_PM,
        label: 'AM/PM',
        dataTestId: 'am/pm-radio-btn',
    },
    {
        value: slotInMunites * SLOT_MULTIPLIER * SLOT_MULTIPLIER,
        label: `${slotInMunites * SLOT_MULTIPLIER * SLOT_MULTIPLIER} mins`,
        dataTestId: 'quad-slot-radio-btn',
    },
    {
        value: slotInMunites * SLOT_MULTIPLIER,
        label: `${slotInMunites * SLOT_MULTIPLIER} mins`,
        dataTestId: 'double-slot-radio-btn',
    },
    {
        value: CUSTOM_DURATION,
        label: 'Custom',
        dataTestId: 'custom-slot-radio-btn',
    },
];

export const getMinutes = (slotInMunites: number): any[] => {
    const count = MINUTES_IN_HOUR / slotInMunites;

    return Array.from(Array(count).keys()).map((index) => ({
        value: index * slotInMunites,
    }));
};

export const getHours = (slotInMinutes: number, hourEndTime: number): any[] => {
    const hourDuration = Math.round(slotInMinutes / 60) + 1;

    return range(hourDuration, hourEndTime, hourDuration).map((hour) => ({ value: hour }));
};

export const formatCustomDurationValue = (startTime: string, hour: string, minute: string): any => {
    return {
        startTime,
        hour,
        minute,
    };
};

export const buildTimeLine = (
    timeSlots: TimeSlotOption[],
    slotDuration: any,
    timezone: string,
    selectedDate: DateTime
): CardProps[] => {
    return timeSlots
        .map((timeSlot, index) => {
            if (timeSlots[index + 1]) {
                return {
                    label: `${timeSlot.label}-${timeSlots[index + 1].label}`,
                    startTime: DateTimeService.getDateFromTimeSlot(
                        selectedDate.setZone(timezone),
                        timeSlot.value,
                        slotDuration
                    ).toUTC(),
                    endTime: DateTimeService.getDateFromTimeSlot(
                        selectedDate.setZone(timezone),
                        timeSlots[index + 1].value,
                        slotDuration
                    ).toUTC(),
                };
            }
            return null;
        })
        .filter((item): item is Exclude<typeof item, null> => item !== null);
};

export const getSelectedCard = (timeline: CardProps[], selectedDate: DateTime, timezone: string): CardProps => {
    return selectedDate.hasSame(DateTime.now().setZone(timezone), 'day')
        ? timeline.find((item) => item.startTime < selectedDate && item.endTime > selectedDate) || timeline[0]
        : timeline[0];
};
export const getStartEndDateTimeFromConfig = (
    timezone: string,
    gridDate: DateTime,
    selectedDay: string,
    combinedConfiguration: CombinedConfiguration | null
): DurationDateTime => {
    const currentDateTime = DateTime.utc();
    const currentTimeZoneDate = currentDateTime.setZone(timezone);

    const gridTimeZoneDate = gridDate.setZone(timezone);
    const isSameDay = currentTimeZoneDate.hasSame(gridTimeZoneDate, 'day');

    const endDateTime = getEndDateByConfigurationType(
        gridTimeZoneDate,
        combinedConfiguration?.bookableDays ?? null,
        selectedDay
    );
    let startDateTime = getStartDateByConfigurationType(
        gridTimeZoneDate,
        combinedConfiguration?.bookableDays ?? null,
        selectedDay
    );

    if (isSameDay && currentDateTime.toMillis() > startDateTime.toMillis()) {
        startDateTime = getIsSameDayStartDate(currentDateTime, combinedConfiguration?.bookingSlotUnits);
    }

    return {
        startDateTime,
        endDateTime,
    };
};

export const buildHalfDayTimeline = (startTime: DateTime, endTime: DateTime): CardProps[] => {
    const timeline: CardProps[] = [];
    const midnight = startTime.startOf('day');
    const noon = midnight.plus({ hours: 12 });

    timeline.push({ label: AM, startTime, endTime: noon });

    timeline.push({ label: PM, startTime: noon, endTime });

    return timeline;
};

export const validateStartEndTime = (
    dateTime: DurationDateTime,
    initialStartDateTime: DateTime,
    hourFormat: string
): string | null => {
    if (dateTime.startDateTime > dateTime.endDateTime) {
        return i18n.t('Start_Time_Bigger_Then_End_Time_Validation_Message');
    }

    if (initialStartDateTime > dateTime.startDateTime) {
        return i18n.t('Before_Start_Time_Validation_Message', { startTime: initialStartDateTime.toFormat(hourFormat) });
    }

    return null;
};
