import { getConfiguration } from 'utilities/appConfigsUtils';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';

import { SpecifiedCompanyAttributesQuery, SpecifiedCompanyAttributesResponse } from './Models/CompanyAttributes';
import { TenantInfo } from './Models/TenantInfo';

export class OrganizationApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().ORGANISATION_API;

        return `${baseUrl}/api/v1`;
    };

    public static getTenantInfo = async (tenantId: string): Promise<ApiResponse<TenantInfo>> => {
        return OrganizationApi.get(`${this.BuildBaseUrl()}/tenant/${tenantId}`);
    };

    public static getSpecifiedCompanyAttributes = async (
        attributes: SpecifiedCompanyAttributesQuery
    ): Promise<ApiResponse<SpecifiedCompanyAttributesResponse[]>> => {
        return OrganizationApi.post(`${this.BuildBaseUrl()}/CompanyAttribute/get-specified`, attributes);
    };

    public static getAvailableCompanyAttributes = async (): Promise<
        ApiResponse<SpecifiedCompanyAttributesResponse[]>
    > => {
        return OrganizationApi.get(`${this.BuildBaseUrl()}/CompanyAttribute/with-values`);
    };
}
