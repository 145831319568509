import { AuthRequestState, AuthResponse, UserProfile } from 'features/Authentication/types';
import {
    OidcClientSettings,
    SigninResponse,
    UserManager,
    UserManagerSettings,
    WebStorageStateStore,
} from 'oidc-client';
import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';
import Guid from 'utilities/guid';

import { ApplicationName, getPortalIdentifier, getPortalUrl, TENANT_IDENTIFIER_KEY } from '../constants';

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail',
};

type UserType = Partial<SigninResponse | null>;

type Callback = { callback: () => void; subscription: number };

class AuthorizeService {
    callbacks: Callback[] = [];

    nextSubscriptionId = 0;

    user: UserType | null = null;

    isAuthenticated = false;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    popUpDisabled = true;

    userManager: UserManager | undefined = undefined;

    static createArguments(state: AuthRequestState = { returnUrl: '/' }): {
        useReplaceToNavigate: boolean;
        data: AuthRequestState;
    } {
        return { useReplaceToNavigate: true, data: state };
    }

    static error(message: string): AuthResponse {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    static success(state: AuthRequestState): AuthResponse {
        return { status: AuthenticationResultStatus.Success, state };
    }

    static redirect(): AuthResponse {
        return { status: AuthenticationResultStatus.Redirect };
    }

    static buildOidcConfigurationObject(tenantId: string): OidcClientSettings {
        let portalUrl = getPortalUrl();
        if (!portalUrl.endsWith('/')) {
            portalUrl += '/';
        }

        return {
            authority: `${getConfiguration().IDENTITY_AUTHORITY}`,
            client_id: 'CB.Portal',
            redirect_uri: `${portalUrl}authentication/login-callback`,
            post_logout_redirect_uri: `${portalUrl}authentication/logout-callback`,
            response_type: 'code',
            scope: 'openid profile offline_access cb-permissions',
            acr_values: tenantId ? `tenant:${tenantId}` : '',
        };
    }

    async checkIsAuthenticated(): Promise<boolean> {
        const user = this.userManager ? await this.userManager.getUser() : null;
        if (user && new Date().getTime() / 1000 > user?.expires_at) {
            this.userManager?.removeUser();
            return false;
        }

        const userProfile: UserProfile = await this.getUser();
        return !!userProfile;
    }

    async getUser(): Promise<UserProfile> {
        if (this.user && this.user.profile) {
            return this.user.profile;
        }

        await this.ensureUserManagerInitialized(false);
        const user = this.userManager ? await this.userManager.getUser() : null;

        return user && user.profile;
    }

    async refreshUser(): Promise<void> {
        await this.ensureUserManagerInitialized(false);
        await this.userManager?.signinSilent();
    }

    async getAccessToken(): Promise<string | null> {
        await this.ensureUserManagerInitialized(false);
        const user = this.userManager ? await this.userManager.getUser() : null;

        return user && user.access_token;
    }

    async getTenantId(): Promise<Guid | null> {
        await this.ensureUserManagerInitialized(false);
        const user = this.userManager ? await this.userManager.getUser() : null;

        if (!user || !user.profile) {
            return null;
        }

        LoggingService.Debug('Tenant Id from profile is', user.profile.tenantId);
        return new Guid(user.profile.tenantId);
    }

    async getTenantIdentifier(): Promise<string> {
        await this.ensureUserManagerInitialized(false);
        const user = this.userManager ? await this.userManager.getUser() : '';

        if (!user || !user.profile) {
            return '';
        }

        LoggingService.Debug('Tenant Identifier from profile is', user.profile.tenantIdentifier);
        return user.profile.tenantIdentifier;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state: AuthRequestState): Promise<AuthResponse> {
        try {
            await this.ensureUserManagerInitialized(false);
            const silentUser = (await this.userManager?.signinSilent(AuthorizeService.createArguments())) as UserType;
            this.updateState(silentUser);
            return AuthorizeService.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            LoggingService.Debug('Silent authentication error: ', silentError);

            try {
                if (this.popUpDisabled) {
                    throw new Error(
                        "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
                    );
                }

                await this.ensureUserManagerInitialized(false);
                const popUpUser = (await this.userManager?.signinPopup(AuthorizeService.createArguments())) as UserType;
                this.updateState(popUpUser);
                return AuthorizeService.success(state);
            } catch (popUpError) {
                if (popUpError.message === 'Popup window closed') {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return AuthorizeService.error('The user closed the window.');
                }
                if (!this.popUpDisabled) {
                    LoggingService.Debug('Popup authentication error: ', popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await this.ensureUserManagerInitialized(true);
                    await this.userManager?.signinRedirect(AuthorizeService.createArguments(state));
                    localStorage.removeItem(TENANT_IDENTIFIER_KEY);
                    return AuthorizeService.redirect();
                } catch (redirectError) {
                    LoggingService.Debug('Redirect authentication error: ', redirectError);
                    return AuthorizeService.error(redirectError);
                }
            }
        }
    }

    async completeSignIn(url: string): Promise<AuthResponse> {
        try {
            await this.ensureUserManagerInitialized(false);
            const user = (await this.userManager?.signinCallback(url)) as UserType;
            this.updateState(user);
            this.createUserManager(false);
            return AuthorizeService.success(user && user.state);
        } catch (error) {
            LoggingService.Debug('There was an error signing in: ', error);
            return AuthorizeService.error('There was an error signing in.');
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state: AuthRequestState): Promise<AuthResponse> {
        await this.ensureUserManagerInitialized(false);
        try {
            if (this.popUpDisabled) {
                throw new Error(
                    "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
                );
            }

            await this.userManager?.signoutPopup(AuthorizeService.createArguments());
            this.updateState(null);
            return AuthorizeService.success(state);
        } catch (popupSignOutError) {
            LoggingService.Debug('Popup signout error: ', popupSignOutError);
            try {
                await this.userManager?.signoutRedirect(AuthorizeService.createArguments(state));
                return AuthorizeService.redirect();
            } catch (redirectSignOutError) {
                LoggingService.Debug('Redirect signout error: ', redirectSignOutError);
                return AuthorizeService.error(redirectSignOutError);
            }
        }
    }

    async completeSignOut(url: string): Promise<AuthResponse> {
        await this.ensureUserManagerInitialized(false);

        let returnUrl = '/';
        const [, fromUri] = getPortalIdentifier();
        if (fromUri) {
            const tenantId = localStorage.getItem(TENANT_IDENTIFIER_KEY);
            if (tenantId) {
                returnUrl = `/${tenantId}`;
            }
        }

        try {
            await this.userManager?.signoutCallback(url);
            this.updateState(null);
            localStorage.removeItem(TENANT_IDENTIFIER_KEY);
            return AuthorizeService.success({ returnUrl });
        } catch (error) {
            LoggingService.Debug(`There was an error trying to log out '${error}'.`);
            return AuthorizeService.error(error);
        }
    }

    updateState(user: UserType): void {
        this.user = user;
        this.isAuthenticated = !!this.user;
        this.notifySubscribers();

        if (user) {
            localStorage.setItem(TENANT_IDENTIFIER_KEY, user.profile.tenantIdentifier);
        }
    }

    subscribe(callback: () => void): number {
        this.callbacks.push({ callback, subscription: this.nextSubscriptionId++ });
        return this.nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number): void {
        const subscriptionIndex: { found: boolean; index: number }[] | [] = this.callbacks
            .map((element, index) =>
                element.subscription === subscriptionId ? { found: true, index } : { found: false, index }
            )
            .filter((element) => element.found === true);

        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this.callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers(): void {
        for (let i = 0; i < this.callbacks.length; i++) {
            const { callback } = this.callbacks[i];
            callback();
        }
    }

    createUserManager(persistResolvedTenant: boolean): void {
        // Get tenant identifier from the url sub-domain
        const [portalTenantIdentifier, fromUri] = getPortalIdentifier();
        let tenantIdentifier = portalTenantIdentifier;

        if (fromUri) {
            if (persistResolvedTenant) {
                localStorage.setItem(TENANT_IDENTIFIER_KEY, tenantIdentifier);
            }
            tenantIdentifier = localStorage.getItem(TENANT_IDENTIFIER_KEY) || tenantIdentifier;
        } else {
            localStorage.removeItem(TENANT_IDENTIFIER_KEY);
        }

        const settings: UserManagerSettings = {
            ...AuthorizeService.buildOidcConfigurationObject(tenantIdentifier),
            automaticSilentRenew: true,
            includeIdTokenInSilentRenew: true,

            // @todo: We will probably have to set the prefix to include the tenantid name as well so that we don't
            // get conflicts with multiple tenants
            userStore: new WebStorageStateStore({
                prefix: fromUri ? ApplicationName : `${ApplicationName}-${tenantIdentifier}`,
            }),
        };

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserSignedOut(async () => {
            await this.userManager?.removeUser();
            this.updateState(null);
        });
    }

    async ensureUserManagerInitialized(persistResolvedTenant: boolean): Promise<void> {
        if (this.userManager !== undefined) {
            return;
        }

        this.createUserManager(persistResolvedTenant);
    }
}

const authService = new AuthorizeService();

export default authService;
