import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { ConfigurationResponse } from 'services/ApiClients/Configuration';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { Person, PersonsResponse } from '../../../services/ApiClients/Models';
import { PersonById } from '../../../services/ApiClients/Person/Models/PersonById';
import { SiteVisit } from '../../../services/ApiClients/VisitorManagement/Models/SiteVisit';
import { Visitor } from '../../../services/ApiClients/VisitorManagement/Models/Visitor';
import { SearchData } from '../../../utilities/searchUtils';

export const searchInternalVisitors = createAsyncAction(
    'SEARCH_INTERNAL_VISITORS_REQUEST',
    'SEARCH_INTERNAL_VISITORS_SUCCESS',
    'SEARCH_INTERNAL_VISITORS_FAILURE'
)<SearchData, ApiResponse<PersonsResponse>, AxiosError | ApiResponse<Person[]>>();

export const searchExternalVisitors = createAsyncAction(
    'SEARCH_EXTERNAL_VISITORS_REQUEST',
    'SEARCH_EXTERNAL_VISITORS_SUCCESS',
    'SEARCH_EXTERNAL_VISITORS_FAILURE'
)<SearchData, ApiResponse<PersonsResponse>, AxiosError | ApiResponse<Person[]>>();

export const searchHosts = createAsyncAction('SEARCH_HOSTS_REQUEST', 'SEARCH_HOSTS_SUCCESS', 'SEARCH_HOSTS_FAILURE')<
    SearchData,
    ApiResponse<PersonsResponse>,
    AxiosError | ApiResponse
>();

export const getPersonById = createAsyncAction(
    'GET_PERSON_BY_ID_REQUEST',
    'GET_PERSON_BY_ID_SUCCESS',
    'GET_PERSON_BY_ID_FAILURE'
)<string, ApiResponse<PersonById>, null>();

export const getBookableDays = createAsyncAction(
    'GET_GET_BOOKABLE_DAYS_REQUEST',
    'GET_GET_BOOKABLE_DAYS_SUCCESS',
    'GET_GET_BOOKABLE_DAYS_FAILURE'
)<string, ApiResponse<ConfigurationResponse>, null>();

export const resetStoreData = createAction('VISITOR_BOOKING_RESET_DATA')();

export const resetSearchVisitorsResult = createAction('VISITOR_BOOKING_RESET_VISITORS')();

export const resetSearchHostsResult = createAction('VISITOR_BOOKING_RESET_HOSTS')();

export const createExternalVisitor = createAsyncAction(
    'CREATE_EXTERNAL_VISITORS_REQUEST',
    'CREATE_EXTERNAL_VISITORS_SUCCESS',
    'CREATE_EXTERNAL_VISITORS_FAILURE'
)<Visitor, void, AxiosError>();

export const editExternalVisitor = createAsyncAction(
    'EDIT_EXTERNAL_VISITORS_REQUEST',
    'EDIT_EXTERNAL_VISITORS_SUCCESS',
    'EDIT_EXTERNAL_VISITORS_FAILURE'
)<Visitor, void, AxiosError>();

export const createSiteVisit = createAsyncAction(
    'CREATE_SITE_VISIT_REQUEST',
    'CREATE_SITE_VISIT_SUCCESS',
    'CREATE_SITE_VISIT_FAILURE'
)<SiteVisit, void, AxiosError>();

export const createMultiDaySiteVisit = createAsyncAction(
    'CREATE_MULTI_DAY_SITE_VISIT_REQUEST',
    'CREATE_MULTI_DAY_SITE_VISIT_SUCCESS',
    'CREATE_MULTI_DAY_SITE_VISIT_FAILURE'
)<SiteVisit, void, AxiosError>();

export const cancelEditingSiteVisit = createAsyncAction(
    'CANCEL_EDITING_SITE_VISIT_REQUEST',
    'CANCEL_EDITING_SITE_VISIT_SUCCESS',
    'CANCEL_EDITING_SITE_VISIT_FAILURE'
)<{ siteVisitId: string }, void, null>();

export const editSiteVisit = createAsyncAction(
    'EDIT_SITE_VISIT_REQUEST',
    'EDIT_SITE_VISIT_SUCCESS',
    'EDIT_SITE_VISIT_FAILURE'
)<any, void, null>();

export const cancelEditMultiDaySiteVisit = createAsyncAction(
    'CANCEL_EDIT_MULTI_DAY_SITE_VISIT_REQUEST',
    'CANCEL_EDIT_MULTI_DAY_SITE_VISIT_SUCCESS',
    'CANCEL_EDIT_MULTI_DAY_SITE_VISIT_FAILURE'
)<{ multiDaySiteVisitId: string }, void, null>();

export const setMySiteVisitsLoadStatus = createAction('SET_MY_BOOKINGS_LOAD_STATUS')<string>();

export default {
    searchInternalVisitors,
    searchExternalVisitors,
    searchHosts,
    resetStoreData,
    resetSearchVisitorsResult,
    resetSearchHostsResult,
    getPersonById,
    createExternalVisitor,
    editExternalVisitor,
    createSiteVisit,
    createMultiDaySiteVisit,
    getBookableDays,
    cancelEditingSiteVisit,
    setMySiteVisitsLoadStatus,
    editSiteVisit,
    cancelEditMultiDaySiteVisit,
};
