import { connect } from 'react-redux';
import { DEFAULT_TIMEZONE } from 'features/constants';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import { MyBookingCommand } from 'services/ApiClients/Booking/Models';

import {
    getMyBookingsAsync,
    getMySiteVisitsAsync,
    setMyBookingsLoadStatus,
    setMySiteVisitsLoadStatus,
} from './Redux/actions';
import { formatMyBookings, formatMySiteVisits, mergeBookingsAndSiteVisits } from './utils/index';
import MyBookings from './MyBookings';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => {
    const timezone = (state.filters.global.site && state.filters.global.site.timezone) || DEFAULT_TIMEZONE;
    const sites = state.referenceData.sites.items;
    return {
        bookingsAndSiteVisits: mergeBookingsAndSiteVisits(
            formatMyBookings(state.myBookings.bookings, sites, state.authentication.user?.extendedHourFormat),
            formatMySiteVisits(state.myBookings.siteVisits, sites, state.authentication.user?.extendedHourFormat)
        ),
        loadStatus: state.myBookings.loadStatus,
        siteVisitsLoadStatus: state.myBookings.siteVisitsLoadStatus,
        range: {
            fromDateTime: DateTime.now().toUTC().toISO(),
            toDateTime: DateTime.now().plus({ days: 60 }).toUTC().endOf('day').toISO(),
        },
        timezone,
    };
};

const mapDispatchToProps = {
    getMyBookings: (myBookingRequest: MyBookingCommand) => getMyBookingsAsync.request(myBookingRequest),
    setMyBookingsLoadStatus,
    getMySiteVisits: (myBookingRequest: MyBookingCommand) => getMySiteVisitsAsync.request(myBookingRequest),
    setMySiteVisitsLoadStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBookings);
