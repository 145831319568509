import React from 'react';
import BackgroundMask from 'components/BackgroundMask';
import ErrorMessage from 'components/ErrorMessage';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { ErrorDetails } from 'services/ApiClients/@Models/ErrorDetails';

export type HttpErrorMessageProps = {
    response: ApiResponse;
    onDismiss?(): void;
};

const getErrorMessage = (response: ApiResponse): string => {
    const errorDetails = response.payload as ErrorDetails;
    let result = 'There was an unexpected error whilst processing your request. Please try again.';

    if (errorDetails.statusCode === 429) {
        result = 'There was a temporary error whilst processing your request. Please wait a few moments and try again.';
    }

    return result;
};

const HttpErrorMessage = React.forwardRef<HTMLDivElement, HttpErrorMessageProps>(
    ({ response, onDismiss }: HttpErrorMessageProps, ref) => {
        if (!response.isComplete || response.isSuccess) return <></>;

        // If this is a Bad Request, it indicates that there is a validation error, and this shouldn't display
        // an error message modal, it should just highlight the fields that are invalid on the forms
        if (response.statusCode === 400) return <></>;

        const errorMessage = getErrorMessage(response);

        return (
            <div className="httpErrorMessage">
                <ErrorMessage title="Error" message={errorMessage} showClose onClose={onDismiss} ref={ref} />
                <BackgroundMask className="open" />
            </div>
        );
    }
);

export default HttpErrorMessage;
