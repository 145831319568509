interface Config {
    ORGANISATION_STRUCTURE_API: string;
    IDENTITY_SERVICE: string;
    IDENTITY_SERVICE_API: string;
    ORGANISATION_API: string;
    AUDIT_API: string;
    BOOKING_API: string;
    BOOKING_SIGNALR_API: string;
    RESOURCE_API: string;
    PERSON_API: string;
    VISITOR_MANAGEMENT_API: string;
    REPORTING_API: string;
    IDENTITY_AUTHORITY: string;
    PORTAL_DOMAIN: string;
    LANGUAGE_API: string;
    REPEATS_ENABLED: string | boolean;
    CONFIG_URI: string;
    ANALYTICS_ENABLED: string | boolean;
    SEARCH_ENABLED: string | boolean;
    SEARCH_API: string;
}

declare global {
    interface Window {
        env: Config;
    }
}

const Config: Config = {
    ORGANISATION_STRUCTURE_API: window.env.ORGANISATION_STRUCTURE_API,
    IDENTITY_SERVICE: window.env.IDENTITY_SERVICE,
    IDENTITY_SERVICE_API: window.env.IDENTITY_SERVICE_API,
    ORGANISATION_API: window.env.ORGANISATION_API,
    AUDIT_API: window.env.AUDIT_API,
    BOOKING_API: window.env.BOOKING_API,
    BOOKING_SIGNALR_API: window.env.BOOKING_SIGNALR_API,
    RESOURCE_API: window.env.RESOURCE_API,
    PERSON_API: window.env.PERSON_API,
    VISITOR_MANAGEMENT_API: window.env.VISITOR_MANAGEMENT_API,
    REPORTING_API: window.env.REPORTING_API,
    LANGUAGE_API: window.env.LANGUAGE_API,
    IDENTITY_AUTHORITY: window.env.IDENTITY_AUTHORITY,
    PORTAL_DOMAIN: window.env.PORTAL_DOMAIN,
    REPEATS_ENABLED: window.env.REPEATS_ENABLED === 'true',
    CONFIG_URI: window.env.CONFIG_URI,
    ANALYTICS_ENABLED: window.env.ANALYTICS_ENABLED === 'true',
    SEARCH_ENABLED: window.env.SEARCH_ENABLED === 'true',
    SEARCH_API: window.env.SEARCH_API,
};

export default Config;
