import React from 'react';
import { useParams } from 'react-router-dom';
import MultiResourceBookingEditComponent from 'features/Resources/Combined/MultiResourceBookingEdit';
import MainLayout from 'layouts/MainLayout/MainLayout';

const MultiResourceBookingEdit = (): JSX.Element => {
    const { id }: { id: string } = useParams();

    return (
        <MainLayout>
            <MultiResourceBookingEditComponent bookingId={id} />
        </MainLayout>
    );
};

export default MultiResourceBookingEdit;
