import { connect } from 'react-redux';
import { ProfileCompanyAttributes } from 'features/Authentication/types';
// eslint-disable-next-line import/no-cycle
import ResourceInfo from 'features/Resources/Common/EditBooking/ResourceInfo/ResourceInfo';
import { formatRestrictedResource } from 'features/Resources/Common/utils';
import { RootState } from 'PortalTypes';
import { CombinedResource } from 'services/ApiClients/Resource';

import {
    getPersonsByIds,
    getResourceImage,
    getRolesByIds,
    getSpecifiedAttributes,
    getTenantCompanyAttributes,
    resetAttributes,
    resetResourceImage,
    resetRoles,
    resetUsers,
    setAttributesLoadStatus,
    setRolesLoadStatus,
    setUsersLoadStatus,
} from '../Redux/actions/info';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => {
    const { user } = state.authentication;
    return {
        bookingResource: formatRestrictedResource(
            state.combined.edit.info.resource as CombinedResource,
            user?.userProfileId,
            user?.['cb-roles'] as string[],
            user?.companyAttributes as unknown as ProfileCompanyAttributes[]
        ),
        users: state.combined.edit.info.users,
        roles: state.combined.edit.info.roles,
        companyAttributes: state.combined.edit.info.companyAttributes,
        userProfileId: user?.userProfileId,
        cbRoles: user?.['cb-roles'],
        cbCompanyAttributes: user?.companyAttributes,
        resourceImage: state.combined.edit.info.resourceImage.imageUrl,
        resourceImageLoading: state.combined.edit.info.resourceImageLoading,
        tenantCompanyAttributes: state.combined.edit.info.tenantCompanyAttributes,
    };
};

const dispatchProps = {
    getUsers: getPersonsByIds.request,
    setUsersLoadStatus,
    getRoles: getRolesByIds.request,
    setRolesLoadStatus,
    getAttributes: getSpecifiedAttributes.request,
    setAttributesLoadStatus,
    resetUsers,
    resetRoles,
    resetAttributes,
    getResourceImage: getResourceImage.request,
    resetResourceImage,
    getTenantCompanyAttributes: getTenantCompanyAttributes.request,
};

export type CombinedInfoProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export default connect(mapStateToProps, dispatchProps)(ResourceInfo);
