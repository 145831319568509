import React, { RefObject, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnFilterInterface } from '../../../features/Reports/interfaces';
import CheckboxButtonSelector from '../../CheckboxButtonSelector/CheckboxButtonSelector';
import Button from '../../FormElements/Button';
import WithLoading from '../../HOC/WithLoading';

import './ReportFilterPanelMultiple.scss';

interface ReportFilterPanelMultipleProps {
    activeFilter: string;
    activeColumnsFilterArray: any[];
    columnsFilter: ColumnFilterInterface;
    generateReportWithColumnsFilter: () => void;
    isLoading?: boolean;
    toggleAll: () => void;
    handleChange: (event: SyntheticEvent) => void;
    scrollContainerRef: RefObject<HTMLInputElement>;
}

const ReportFilterPanelMultiple = ({
    activeFilter,
    activeColumnsFilterArray,
    columnsFilter,
    generateReportWithColumnsFilter,
    isLoading,
    toggleAll,
    handleChange,
    scrollContainerRef,
}: ReportFilterPanelMultipleProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <WithLoading isLoading={isLoading}>
            <div className="report-filter-container">
                <Button
                    dataTestId="toggle-all-columns"
                    text={t('Reports_ToggleAll')}
                    className="toggle-btn"
                    onClick={toggleAll}
                />
                <div className="report-filter-content-scroll" ref={scrollContainerRef}>
                    {activeColumnsFilterArray.map((item) => {
                        return (
                            <CheckboxButtonSelector
                                label={item.label}
                                value={item.value}
                                checked={columnsFilter[activeFilter]?.values.includes(item.value)}
                                handleChange={handleChange}
                                key={item.label}
                            />
                        );
                    })}
                </div>
                <div className="report-filter-actions">
                    <Button
                        text={t('Button_Apply')}
                        type="button"
                        className="apply-btn"
                        dataTestId="apply-btn-btn"
                        disabled={!columnsFilter[activeFilter]?.values.length}
                        onClick={generateReportWithColumnsFilter}
                    />
                </div>
            </div>
        </WithLoading>
    );
};

export default ReportFilterPanelMultiple;
