import React from 'react';
import classNames from 'classnames';

import './InlineFields.scss';

export interface InlineFieldsInterface {
    className?: string;
}

const InlineFields = ({ className, children }: React.PropsWithChildren<InlineFieldsInterface>): JSX.Element => {
    return <div className={classNames('inlineFields', className)}>{children}</div>;
};

export default InlineFields;
