import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

import './Loading.scss';

const Loading = (): JSX.Element => {
    return (
        <div className="loading" aria-busy="true">
            <ThreeDots color="#666" height={80} width={80} />
        </div>
    );
};

export default Loading;
