import React from 'react';

export interface EventProps {
    text: string;
    displayName?: string;
}

const EventComponent = ({ displayName, text }: EventProps): JSX.Element => <h2>{displayName || text}</h2>;

export default EventComponent;
