import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getFloorPlanSearchData } from './actions';

export const searchInternalVisitorsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getFloorPlanSearchData.request)),
        switchMap((action) =>
            from(api.search.search(action.payload)).pipe(
                map((payload) => getFloorPlanSearchData.success(payload)),
                catchError((error) => of(getFloorPlanSearchData.failure(error)))
            )
        )
    );
