import React from 'react';
import classNames from 'classnames';

import { ReactComponent as ColumnFilter } from '../../assets/icons/reports/ColumnFilter.svg';
import { ActiveColumnFilterInterface } from '../../features/Reports/interfaces';

import './ReportFilterColumnHeader.scss';

interface ReportsFilterColumnHeaderProps {
    handleFilterClick: (field: string, headerName: string) => void;
    field: string;
    headerName: string;
    activeSideBarFilter: ActiveColumnFilterInterface;
}

const ReportFilterColumnHeader = ({
    handleFilterClick,
    field,
    headerName,
    activeSideBarFilter,
}: ReportsFilterColumnHeaderProps): JSX.Element => (
    <div className="report-filter-column">
        <div
            className="report-filter-icon-container"
            onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                event.preventDefault();
                event.stopPropagation();
                handleFilterClick(field, headerName);
            }}
        >
            <ColumnFilter className={classNames('report-filter-icon', field in activeSideBarFilter ? 'applied' : '')} />
        </div>
        {headerName}
    </div>
);

export default ReportFilterColumnHeader;
