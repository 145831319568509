import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

import {
    generateReport,
    generateReportForExport,
    reportsGetCompanies,
    reportsResetSearchCompaniesResult,
    reportsSearchCompanies,
} from '../Redux/actions';

// eslint-disable-next-line import/no-cycle,
import VisitorBookingsReport from './VisitorBookingsReport';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    searchCompaniesResult: state.reports.searchCompaniesResult,
    companiesResult: state.reports.companiesResult,
    isSearchCompaniesInProgress: state.reports.isSearchCompaniesInProgress,
    isGetCompaniesInProgress: state.reports.isGetCompaniesInProgress,
    isReportGenerationInProgress: state.reports.isReportGenerationInProgress,
    sites: state.referenceData.sites.items,
    siteGroups: state.referenceData.siteGroup.items,
    tenantId: state.authentication.user?.tenantId || '',
    reportData: state.reports.reportData?.siteVisits,
    is24HoursTimeFormat: state.authentication.user?.is_24h_timeFormat,
    isGenerationReportForExportInProgress: state.reports.isGenerationReportForExportInProgress,
    reportDataForExport: state.reports.reportDataForExport?.siteVisits,
});

const mapDispatchToProps = {
    searchCompanies: reportsSearchCompanies.request,
    getCompanies: reportsGetCompanies.request,
    resetCompaniesSearch: reportsResetSearchCompaniesResult,
    generateReport: generateReport.request,
    generateReportForExport: generateReportForExport.request,
};

export type VisitorBookingsReportProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(VisitorBookingsReport);
