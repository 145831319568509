import React, { memo, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import Calendar from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { ReactComponent as ClosePanel } from 'assets/icons/ClosePanel.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/DangerIcon.svg';
import classNames from 'classnames';
import { Button, DropDown, FormGroup, InlineFields } from 'components/FormElements';
import { getSelectedTimeSlot } from 'components/FormElements/DateTimePicker/Components/DateTimeRangeEditor';
import SingleField from 'components/FormElements/DateTimePicker/Components/SingleField';
import InputWrapper from 'components/Forms/InputWrapper';
import SlidingPanel from 'components/SlidingPanel';
import {
    BOOKING_SLOT_UNITS_MULTIPLIER,
    DEFAULT_DATE_FORMAT,
    MONTH_REPEAT_INDEXES,
    MONTH_REPEAT_TYPE,
    REPEAT,
    REPEAT_ON_DAY,
    TIME_FORMAT,
} from 'features/constants';
import { DateTime as DateTimeLuxon } from 'luxon';
import { RepeatSchedule } from 'services/ApiClients/Booking';
import { Configuration } from 'services/ApiClients/Configuration';
import DateTimeService, { TimeSlotOption } from 'services/DateTimeService';
import { isNil } from 'utilities/ts';

import { periodUnitEnum } from '../../../../../../components/BookingGrid/Models/CellFillTypes';
import RadioButtonSelector from '../../../../../../components/RadioButtonSelector/RadioButtonSelector';
import ToastService from '../../../../../../services/ToastService';
import {
    DATE_DROPDOWN_CLASS_NAME_ERROR,
    DATE_TIME_DROPDOWN_CLASS_NAME_ERROR,
    DAYS_CLASS_NAME_ERROR,
    formatMonthRadioButtons,
    formatScheduleState,
    getMonthRepeatIndex,
    monthRadioOptions,
    RepeatState,
    validateRepeatData,
} from '../utils';

import './RepeatSlider.scss';

type RepeatSliderDateTime = {
    startDate: DateTimeLuxon;
    endDate: DateTimeLuxon;
};

interface RepeatSliderProps {
    isOpen: boolean;
    onClose: (param?: RepeatSchedule) => void;
    resourceTypeSiteConfiguration: Configuration;
    dateTimeRangeValue: RepeatSliderDateTime;
    selectedDate: DateTimeLuxon;
    repeatSchedule: RepeatSchedule;
    isSelectedDateBookable: (current: any) => boolean;
    timezone: string;
    hourFormat: string;
    resourceConfiguration: any;
}

const RepeatSlider = ({
    isOpen,
    onClose,
    resourceTypeSiteConfiguration,
    dateTimeRangeValue,
    selectedDate,
    repeatSchedule,
    isSelectedDateBookable,
    timezone,
    hourFormat,
    resourceConfiguration,
}: RepeatSliderProps): JSX.Element => {
    const { t } = useTranslation();
    const [repeatState, setRepeatState] = useState<RepeatState>(
        formatScheduleState(repeatSchedule, dateTimeRangeValue)
    );

    const {
        repeat,
        everyRepeat,
        date,
        checkedDays,
        validationObject,
        isStartDatePicked,
        isEndDatePicked,
        monthRepeatRadioOption,
    } = repeatState;

    useEffect(() => {
        if (repeatSchedule) {
            const isoStartDateFormat = DateTimeLuxon.fromISO(repeatSchedule.range.start, { zone: timezone }).toISO();
            const isoEndDateFormat = DateTimeLuxon.fromISO(repeatSchedule.range.end, { zone: timezone }).toISO();
            setRepeatState({
                ...repeatState,
                date: {
                    startDate: DateTimeLuxon.fromISO(isoStartDateFormat, { setZone: true }),
                    endDate: DateTimeLuxon.fromISO(isoEndDateFormat, { setZone: true }),
                },
            });
        }
    }, [repeatSchedule, isOpen]);

    const handleMonthRepeatTypeChange = (value: monthRadioOptions): void => {
        let type;
        if (value === monthRadioOptions.absolute) {
            type = MONTH_REPEAT_TYPE.ABSOLUTE;
        } else {
            type = MONTH_REPEAT_TYPE.RELATIVE;
        }

        let index = null;
        if (value === monthRadioOptions.relativeSecond) {
            index = MONTH_REPEAT_INDEXES.last;
        } else if (value === monthRadioOptions.relativeFirst) {
            index = getMonthRepeatIndex(date.startDate);
        }

        setRepeatState({
            ...repeatState,
            monthRepeatSubType: type,
            monthRepeatIndex: index,
            monthRepeatRadioOption: value,
        });
    };

    useEffect(() => {
        let radioOption;
        if (repeatSchedule?.pattern.index) {
            radioOption = monthRadioOptions.relativeFirst;
            if (repeatSchedule.pattern.index === MONTH_REPEAT_INDEXES.last) {
                radioOption = monthRadioOptions.relativeSecond;
            }
        } else {
            radioOption = monthRadioOptions.absolute;
        }
        handleMonthRepeatTypeChange(radioOption);
    }, [repeatSchedule?.pattern.index]);

    const selectedDay = date.startDate
        ? date.startDate.toFormat('cccc').toLowerCase()
        : selectedDate.toFormat('cccc').toLowerCase();

    const timeInterval: number = resourceTypeSiteConfiguration.bookingSlotUnits * BOOKING_SLOT_UNITS_MULTIPLIER;
    const timeSlots: TimeSlotOption[] = DateTimeService.getTimeSlots(
        Math.floor(
            resourceTypeSiteConfiguration.bookableDays[selectedDay]?.bookingWindowStartMinutesFromMidnight /
                timeInterval
        ),
        Math.ceil(
            resourceTypeSiteConfiguration.bookableDays[selectedDay]?.bookingWindowEndMinutesFromMidnight / timeInterval
        ),
        timeInterval,
        hourFormat
    );

    const selectedFromTimeSlot = getSelectedTimeSlot(timeSlots, date.startDate, timeInterval);
    const selectedToTimeSlot = getSelectedTimeSlot(timeSlots, date.endDate, timeInterval, true);

    const handleOnChecked = (event: SyntheticEvent): void => {
        if (validationObject?.className === DAYS_CLASS_NAME_ERROR) {
            setRepeatState({ ...repeatState, validationObject: null });
        }
        const { value: day } = event.target as HTMLInputElement;
        if (checkedDays && !checkedDays.includes(day)) {
            setRepeatState({ ...repeatState, checkedDays: [...checkedDays, day] });
        } else {
            setRepeatState({
                ...repeatState,
                checkedDays: checkedDays?.filter((checkedDay: string) => checkedDay !== day) || null,
            });
        }
    };

    const handleRepeatChange = (value: string): void => {
        const repeatKey = Object.keys(REPEAT).find((key) => REPEAT[key].type === value) || '';
        setRepeatState({
            ...repeatState,
            repeat: REPEAT[repeatKey],
            everyRepeat: 1,
            checkedDays: repeatKey.toLowerCase() === REPEAT.WEEK.every ? [date.startDate.toFormat('cccc')] : null,
        });
    };

    const handleStartTimeChange = (selected: string): void => {
        setRepeatState({
            ...repeatState,
            date: {
                ...date,
                startDate: DateTimeService.getDateFromTimeSlot(
                    date.startDate.startOf('day'),
                    Number(selected),
                    timeInterval
                ),
            },
            isStartDatePicked: false,
        });
    };

    const handleEndTimeChange = (selected: string): void => {
        setRepeatState({
            ...repeatState,
            date: {
                ...date,
                endDate: DateTimeService.getDateFromTimeSlot(
                    date.endDate.startOf('day'),
                    Number(selected),
                    timeInterval
                ),
            },
            isEndDatePicked: false,
        });
    };

    const handleOnClose = (): void => {
        setRepeatState({
            ...repeatState,
            validationObject: null,
        });
        const daysIndexes = checkedDays
            ? Object.keys(REPEAT_ON_DAY).reduce(
                  (acc: number[], day: string, index: number) =>
                      checkedDays?.includes(day) ? [...acc, index] : [...acc],
                  []
              )
            : null;

        const isMonthRepeat = JSON.stringify(REPEAT.MONTH.type) === JSON.stringify(repeat.type);
        const type = isMonthRepeat ? repeatState.monthRepeatSubType : repeat.type;

        let daysOfWeek = null;

        const monthRelativeRepeatDayOfWeek = repeatState.date.startDate.weekday - 1;

        if (isMonthRepeat) {
            daysOfWeek = type === MONTH_REPEAT_TYPE.RELATIVE ? [monthRelativeRepeatDayOfWeek] : null;
        } else {
            daysOfWeek = daysIndexes;
        }

        const schedule: RepeatSchedule = {
            pattern: {
                type: Number(type),
                interval: Number(everyRepeat),
                daysOfWeek,
                index: repeatState.monthRepeatIndex,
            },
            range: {
                start: date.startDate.toFormat(DEFAULT_DATE_FORMAT),
                end: date.endDate.toFormat(DEFAULT_DATE_FORMAT),
            },
            bookedTimeRange: {
                start: date.startDate.toFormat(TIME_FORMAT),
                end: date.endDate.toFormat(TIME_FORMAT),
            },
            timezone,
        };

        const validObject = validateRepeatData(date, daysIndexes);
        if (!validObject) {
            onClose(schedule);
        } else {
            setRepeatState({ ...repeatState, validationObject: validObject });
        }
    };

    const getNoticePeriodLastDay = useMemo((): DateTimeLuxon => {
        const todayDate = DateTimeLuxon.now().setZone(timezone);
        let noticePeriodLastDay = todayDate;

        if (resourceConfiguration?.noticePeriodDuration && resourceConfiguration?.noticePeriodUnit !== null) {
            noticePeriodLastDay = todayDate.plus({
                [periodUnitEnum[resourceConfiguration.noticePeriodUnit]]: resourceConfiguration.noticePeriodDuration,
            });
            return noticePeriodLastDay;
        }

        if (
            resourceTypeSiteConfiguration?.noticePeriodDuration &&
            resourceTypeSiteConfiguration?.noticePeriodUnit !== null
        ) {
            noticePeriodLastDay = todayDate.plus({
                [periodUnitEnum[resourceTypeSiteConfiguration.noticePeriodUnit as number]]:
                    resourceTypeSiteConfiguration.noticePeriodDuration,
            });
            return noticePeriodLastDay;
        }

        return noticePeriodLastDay;
    }, []);

    const isDoneButtonDisabled = useMemo((): boolean => {
        const { startDate } = repeatState.date;

        const getLastAvailableDay = (periodDate: any): any => {
            const day = periodDate.toFormat('cccc').toLowerCase();
            if (Object.keys(resourceTypeSiteConfiguration?.bookableDays).includes(day)) {
                return periodDate;
            }
            return getLastAvailableDay(
                periodDate.minus({
                    day: 1,
                })
            );
        };

        return (
            startDate.startOf('day').equals(getNoticePeriodLastDay.startOf('day')) ||
            startDate.startOf('day').equals(getLastAvailableDay(getNoticePeriodLastDay).startOf('day'))
        );
    }, [date.startDate]);

    useEffect(() => {
        if (isDoneButtonDisabled && isOpen) {
            ToastService.Warning({
                message: t('Toast_RepeatStartDayMaxNoticePeriodException'),
            });
        }
    }, [date.startDate]);

    return (
        <>
            <SlidingPanel
                open={isOpen}
                showBackgroundMask
                className="repeat-slider"
                title={!repeatSchedule ? t('Repeat_Slider_Title') : t('Repeat_Edit_Slider_Title')}
                onClose={() => onClose()}
            >
                <div className="form-container">
                    {repeatSchedule && <span className="edit-message">{t('Repeat_Edit_Message')}</span>}
                    <FormGroup>
                        <InlineFields>
                            <DropDown
                                value={selectedFromTimeSlot?.value.toString() || ''}
                                label={t('Repeat_Start_Time')}
                                onChange={handleStartTimeChange}
                                paperProps={{ large: true }}
                                dropDownClassName={classNames({
                                    error: validationObject?.className === DATE_TIME_DROPDOWN_CLASS_NAME_ERROR,
                                })}
                                dataTestId="repeat_Start_Time_DropDown"
                            >
                                {timeSlots.map((timeSlot) => (
                                    <MenuItem key={timeSlot.value} value={timeSlot.value.toString()}>
                                        {timeSlot.label}
                                    </MenuItem>
                                ))}
                            </DropDown>
                            <DropDown
                                value={selectedToTimeSlot?.value.toString() || ''}
                                onChange={handleEndTimeChange}
                                label={t('Repeat_End_Time')}
                                paperProps={{ large: true }}
                                dropDownClassName={classNames({
                                    error: validationObject?.className === DATE_TIME_DROPDOWN_CLASS_NAME_ERROR,
                                })}
                                dataTestId="repeat_End_Time_DropDown"
                            >
                                {timeSlots.map((timeSlot) => (
                                    <MenuItem key={timeSlot.value} value={timeSlot.value.toString()}>
                                        {timeSlot.label}
                                    </MenuItem>
                                ))}
                            </DropDown>
                        </InlineFields>
                        <InlineFields>
                            <DropDown
                                className="repeat-dropdown"
                                label={t('Repeat_Dropdown_Label')}
                                value={repeat?.type || ''}
                                onChange={handleRepeatChange}
                                dataTestId="repeat_Dropdown_Slider"
                            >
                                {Object.keys(REPEAT).map((key: string) => (
                                    <MenuItem key={key} value={REPEAT[key].type}>
                                        {REPEAT[key].option}
                                    </MenuItem>
                                ))}
                            </DropDown>
                            <InputWrapper className="repeat-start-date" label={t('Repeat_Start_Date')} name="startDate">
                                <SingleField
                                    dateTime={date.startDate}
                                    onClick={() =>
                                        setRepeatState({ ...repeatState, isStartDatePicked: !isStartDatePicked })
                                    }
                                    className={classNames('repeat-datePicker', {
                                        error: validationObject?.className === DATE_DROPDOWN_CLASS_NAME_ERROR,
                                    })}
                                    dataTestId="repear_Start_Date"
                                />
                            </InputWrapper>
                        </InlineFields>
                        {isStartDatePicked && (
                            <InlineFields className="repeat-calendar-container">
                                <div className="divider" />
                                <ClosePanel
                                    className="close-calendar"
                                    onClick={() => setRepeatState({ ...repeatState, isStartDatePicked: false })}
                                />
                                {date.startDate?.isValid && (
                                    <Calendar
                                        className="repeat-calendar"
                                        value={date.startDate.setZone('local', { keepLocalTime: true }).toJSDate()}
                                        input={false}
                                        timeFormat={false}
                                        onChange={(value: any) => {
                                            setRepeatState({
                                                ...repeatState,
                                                isStartDatePicked: false,
                                                date: {
                                                    ...date,
                                                    startDate: DateTimeLuxon.fromISO(value.toISOString()).setZone(
                                                        timezone,
                                                        {
                                                            keepLocalTime: true,
                                                        }
                                                    ),
                                                },
                                            });
                                        }}
                                        isValidDate={(currentDate) => {
                                            const dateLuxon = DateTimeLuxon.fromISO(currentDate.toISOString());
                                            const getNoticePeriodBeforeLastDayInMills = getNoticePeriodLastDay
                                                .minus({ day: 1 })
                                                .toMillis();

                                            return (
                                                dateLuxon > DateTimeLuxon.now().minus({ day: 1 }) &&
                                                isSelectedDateBookable(dateLuxon) &&
                                                currentDate.isBefore(getNoticePeriodBeforeLastDayInMills)
                                            );
                                        }}
                                    />
                                )}
                                <div className="divider" />
                            </InlineFields>
                        )}
                        <InlineFields className="repeat-every-container">
                            <DropDown
                                value={everyRepeat.toString() || ''}
                                className="repeat-every"
                                label={t('Repeat_Every_Label')}
                                onChange={(value) => setRepeatState({ ...repeatState, everyRepeat: value })}
                                paperProps={{ large: true }}
                                dataTestId="repeat_Every_Dropdown_Slider"
                            >
                                {Array.from(Array(repeat.maxRepeats)).map((_, index) => {
                                    const value = index + 1;
                                    return (
                                        <MenuItem key={value} value={value.toString()}>
                                            {value}
                                        </MenuItem>
                                    );
                                })}
                            </DropDown>

                            <span className="every-count">{repeat.every}(s)</span>
                        </InlineFields>
                        {checkedDays && repeatState.repeat !== REPEAT.MONTH && repeatState.repeat !== REPEAT.DAY && (
                            <InlineFields className="repeat-on-container">
                                <label htmlFor="on">{t('Repeat_On_Label')}</label>
                                <div className="checkboxes">
                                    {Object.keys(REPEAT_ON_DAY).map((day: string) => {
                                        return (
                                            <div
                                                key={day}
                                                className={classNames('checkbox-container', {
                                                    error: validationObject?.className === DAYS_CLASS_NAME_ERROR,
                                                })}
                                            >
                                                <input
                                                    key={day}
                                                    type="checkbox"
                                                    checked={checkedDays?.includes(day)}
                                                    value={day}
                                                    className="repeat-on-checkbox"
                                                    onChange={handleOnChecked}
                                                    disabled={
                                                        !resourceTypeSiteConfiguration.bookableDays[day.toLowerCase()]
                                                    }
                                                />
                                                <label htmlFor={`${day}`}>{REPEAT_ON_DAY[day]}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </InlineFields>
                        )}

                        {repeatState.repeat === REPEAT.MONTH && (
                            <RadioButtonSelector
                                value={monthRepeatRadioOption}
                                items={formatMonthRadioButtons(repeatState.date.startDate, t)}
                                handleChange={(e: any) => {
                                    const value = Number(e.target.value);
                                    handleMonthRepeatTypeChange(value);
                                }}
                            />
                        )}

                        <InlineFields>
                            <InputWrapper className="repeat-end-date" label={t('Repeat_End_Date')} name="endDate">
                                <SingleField
                                    dataTestId="repeat-endDate"
                                    dateTime={date.endDate}
                                    onClick={() =>
                                        setRepeatState({ ...repeatState, isEndDatePicked: !isEndDatePicked })
                                    }
                                    className={classNames('repeat-datePicker', {
                                        error: validationObject?.className === DATE_DROPDOWN_CLASS_NAME_ERROR,
                                    })}
                                />
                            </InputWrapper>
                        </InlineFields>
                        {isEndDatePicked && (
                            <InlineFields className="repeat-calendar-container">
                                <div className="divider" />
                                <ClosePanel
                                    className="close-calendar"
                                    onClick={() => setRepeatState({ ...repeatState, isEndDatePicked: false })}
                                />
                                {date.endDate?.isValid && (
                                    <Calendar
                                        className="repeat-calendar"
                                        value={date.endDate.setZone('local', { keepLocalTime: true }).toJSDate()}
                                        input={false}
                                        timeFormat={false}
                                        onChange={(value: any) => {
                                            setRepeatState({
                                                ...repeatState,
                                                isEndDatePicked: false,
                                                date: {
                                                    ...date,
                                                    endDate: DateTimeLuxon.fromISO(value.toISOString()).setZone(
                                                        timezone,
                                                        {
                                                            keepLocalTime: true,
                                                        }
                                                    ),
                                                },
                                            });
                                        }}
                                        isValidDate={(currentDate) => {
                                            const dateLuxon = DateTimeLuxon.fromISO(currentDate.toISOString());
                                            const getNoticePeriodLastDayInMillis = getNoticePeriodLastDay.toMillis();

                                            return (
                                                dateLuxon >
                                                    date.startDate
                                                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                                        .minus({ day: 1 }) &&
                                                isSelectedDateBookable(dateLuxon) &&
                                                currentDate.isBefore(getNoticePeriodLastDayInMillis)
                                            );
                                        }}
                                    />
                                )}
                                <div className="divider" />
                            </InlineFields>
                        )}
                        {!isNil(validationObject) && (
                            <small className="error-message" role="alert">
                                <DangerIcon />
                                {validationObject?.message}
                            </small>
                        )}
                    </FormGroup>
                </div>
                <div className="repeat-button-container">
                    <Button
                        type="button"
                        dataTestId="done-button"
                        onClick={handleOnClose}
                        text={t('Button_Done')}
                        disabled={isDoneButtonDisabled}
                    />
                </div>
            </SlidingPanel>
        </>
    );
};

export default memo(RepeatSlider);
