import React from 'react';
import { Tooltip } from '@mui/material';
import { GridCellParams, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import i18n from 'i18next';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

import ReportFilterColumnHeader from '../../../components/ReportsFilterColumnHeader/ReportFilterColumnHeader';
import { ResourceType } from '../../../services/ApiClients/Resource';
import { AllListItemOption, DEFAULT_DATE_FORMAT } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface } from '../interfaces';
import { dateFormatter, timeFormatterWithTimeFormat } from '../utils';

const items = {
    id: true,
    friendlyBookingId: true,
    resourceType: true,
    resourceName: true,
    siteGroupName: true,
    siteName: true,
    areaName: true,
    createdDateTime: true,
    createdTime: true,
    startDateTime: true,
    startTime: true,
    endDateTime: true,
    endTime: true,
    bookingTitle: true,
    createdBy: true,
    createdByEmail: true,
    attendeesCount: true,
    onBehalfOf: true,
    repeatDetails: true,
    bookingStatus: true,
    checkedInTime: true,
    checkedOutTime: true,
    checkedInDateTime: true,
    checkedOutDateTime: true,
    lastModifiedBy: true,
    lastModifiedDate: true,
    lastModifiedTime: true,
    checkedOutBy: true,
    timezone: true,
    department: true,
    isPrivate: true,
};

export const getColumns = (
    showSiteGroups: boolean,
    handleFilterClick: (field: string, headerName: string) => void,
    sideBarFilter: ColumnFilterInterface,
    activeSideBarFilter: ActiveColumnFilterInterface,
    is24HoursFormat?: boolean
): GridColDef[] => {
    return [
        {
            field: 'friendlyBookingId',
            headerName: i18n.t('Reports_BookingId'),
            minWidth: 150,
            flex: 0.1,
        },
        {
            field: 'resourceType',
            headerName: i18n.t('Reports_ResourceType'),
            minWidth: 175,
            flex: 0.1,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.resourceType.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_ResourceType')}</p>
                ),
        },
        {
            field: 'resourceName',
            headerName: i18n.t('Reports_ResourceName'),
            minWidth: 150,
            flex: 0.1,
        },
        ...(showSiteGroups
            ? [
                  {
                      field: 'siteGroupName',
                      headerName: i18n.t('Reports_SiteGroup'),
                      minWidth: 150,
                      flex: 0.1,
                      renderHeader: (params: GridColumnHeaderParams) =>
                          sideBarFilter.siteGroupName.enabled ? (
                              <ReportFilterColumnHeader
                                  handleFilterClick={handleFilterClick}
                                  field={params.colDef.field}
                                  headerName={params.colDef.headerName || ''}
                                  activeSideBarFilter={activeSideBarFilter}
                              />
                          ) : (
                              <p>{i18n.t('Reports_SiteGroup')}</p>
                          ),
                  },
              ]
            : []),
        {
            field: 'siteName',
            headerName: i18n.t('Reports_Site'),
            minWidth: 150,
            flex: 0.1,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.siteName.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_Site')}</p>
                ),
        },
        {
            field: 'areaName',
            headerName: i18n.t('Reports_Area'),
            minWidth: 150,
            flex: 0.1,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.areaName.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_Area')}</p>
                ),
        },
        {
            field: 'timezone',
            headerName: i18n.t('Reports_Timezone'),
            minWidth: 175,
            flex: 0.1,
        },
        {
            field: 'createdDateTime',
            headerName: i18n.t('Reports_CreatedDate'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) => dateFormatter(params.row.createdDateTime, params.row.timezone),
        },
        {
            field: 'createdTime',
            headerName: i18n.t('Reports_CreatedTime'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) =>
                timeFormatterWithTimeFormat(params.row.createdDateTime, is24HoursFormat, params.row.timezone),
        },
        {
            field: 'startDateTime',
            headerName: i18n.t('Reports_StartDate'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) => dateFormatter(params.row.startDateTime, params.row.timezone),
        },
        {
            field: 'startTime',
            headerName: i18n.t('Reports_StartTime'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) =>
                timeFormatterWithTimeFormat(params.row.startDateTime, is24HoursFormat, params.row.timezone),
        },
        {
            field: 'endDateTime',
            headerName: i18n.t('Reports_EndDate'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) => dateFormatter(params.row.endDateTime, params.row.timezone),
        },
        {
            field: 'endTime',
            headerName: i18n.t('Reports_EndTime'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) =>
                timeFormatterWithTimeFormat(params.row.endDateTime, is24HoursFormat, params.row.timezone),
        },
        {
            field: 'bookingTitle',
            headerName: i18n.t('Reports_BookingTitle'),
            minWidth: 150,
            flex: 0.1,
        },
        {
            field: 'createdBy',
            headerName: i18n.t('Reports_CreatedBy'),
            minWidth: 150,
            flex: 0.1,
        },
        {
            field: 'createdByEmail',
            headerName: i18n.t('Reports_CreatedByEmail'),
            minWidth: 150,
            flex: 0.1,
            renderCell: (params: GridCellParams) => (
                <Tooltip arrow title={params.value}>
                    <span className="table-cell-truncate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'isPrivate',
            headerName: i18n.t('Report_PrivateBooking'),
            minWidth: 200,
            cellClassName: 'capitalize',
            flex: 0.1,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.isPrivate.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Report_PrivateBooking')}</p>
                ),
        },

        {
            field: 'department',
            headerName: i18n.t('Reports_Department'),
            minWidth: 150,
            flex: 0.1,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.department.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_Department')}</p>
                ),
        },
        {
            field: 'attendeesCount',
            headerName: i18n.t('Reports_AttendeeCount'),
            minWidth: 175,
            flex: 0.1,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.attendeesCount.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_AttendeeCount')}</p>
                ),
        },
        {
            field: 'onBehalfOf',
            headerName: i18n.t('Reports_OnBehalfOf'),
            minWidth: 150,
            flex: 0.1,
        },
        {
            field: 'repeatDetails',
            headerName: i18n.t('Reports_RepeatDetails'),
            minWidth: 150,
            flex: 0.1,
        },
        {
            field: 'bookingStatus',
            headerName: i18n.t('Reports_BookingStatus'),
            minWidth: 175,
            flex: 0.1,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.bookingStatus.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_BookingStatus')}</p>
                ),
        },
        {
            field: 'checkedInDateTime',
            headerName: i18n.t('Reports_CheckInTime'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) =>
                timeFormatterWithTimeFormat(params.row.checkedInDateTime, is24HoursFormat, params.row.timezone),
        },
        {
            field: 'checkedOutDateTime',
            headerName: i18n.t('Reports_CheckoutTime'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) =>
                timeFormatterWithTimeFormat(params.row.checkedOutDateTime, is24HoursFormat, params.row.timezone),
        },
        {
            field: 'lastModifiedBy',
            headerName: i18n.t('Reports_LastModifiedBy'),
            minWidth: 200,
            flex: 0.1,
        },
        {
            field: 'lastModifiedDate',
            headerName: i18n.t('Reports_LastModifiedDate'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) => dateFormatter(params.row.lastModifiedDate, params.row.timezone),
        },
        {
            field: 'lastModifiedTime',
            headerName: i18n.t('Reports_LastModifiedTime'),
            minWidth: 200,
            flex: 0.2,
            valueGetter: (params) =>
                timeFormatterWithTimeFormat(params.row.lastModifiedDate, is24HoursFormat, params.row.timezone),
        },
        {
            field: 'checkedOutBy',
            headerName: i18n.t('Reports_CheckedOutBy'),
            minWidth: 175,
            flex: 0.1,
        },
    ];
};

export const buildQuery = (
    itemsPerPageAmount: number,
    activePage: number,
    sortOptions: any,
    reportsFilter: any,
    activeSideBarFilter?: ActiveColumnFilterInterface,
    isForExport = false,
    totalCount = 0,
    visibleColumns?: string[]
): string => {
    const { selectedSiteGroup, selectedSite, selectedArea } = reportsFilter;
    const ascSorting = new EnumType('ASC');
    const graphQLItems: { [key: string]: any } = { ...items };
    if (visibleColumns) {
        Object.keys(graphQLItems).forEach((key: string) => {
            if (!visibleColumns.includes(key)) {
                delete graphQLItems[key];
            }
        });
    }
    const query = {
        resourceBookings: {
            __args: {
                skip: isForExport ? 0 : itemsPerPageAmount * (activePage - 1),
                take: isForExport ? totalCount : itemsPerPageAmount,
                siteGroupId: selectedSiteGroup?.value !== AllListItemOption.value ? selectedSiteGroup?.value : null,
                siteId: selectedSite?.value !== AllListItemOption.value ? selectedSite?.value : null,
                areaId: selectedArea?.value !== AllListItemOption.value ? selectedArea?.value : null,
                from: reportsFilter.dateRange.from.toFormat(DEFAULT_DATE_FORMAT),
                to: reportsFilter.dateRange.to.toFormat(DEFAULT_DATE_FORMAT),
                order: {
                    // default sorting (grouping)
                    ...(sortOptions[0]?.field !== 'siteGroupName' && { siteGroupName: ascSorting }),
                    ...(sortOptions[0]?.field !== 'siteName' && { siteName: ascSorting }),
                    ...(sortOptions[0]?.field !== 'areaName' && { areaName: ascSorting }),
                },
                where: {},
            },
            items: graphQLItems,
            totalCount: true,
        },
    };

    if (sortOptions?.length) {
        query.resourceBookings.__args.order = {
            [sortOptions[0].field]: new EnumType(sortOptions[0].sort.toUpperCase()),
            ...query.resourceBookings.__args.order,
        };
    }

    let where = {};

    if (reportsFilter?.selectedResourceType.value !== AllListItemOption.value) {
        where = {
            ...where,
            resourceType: { eq: reportsFilter.selectedResourceType.label },
        };
    }

    if (activeSideBarFilter?.siteName?.values) {
        where = {
            ...where,
            siteId: { in: activeSideBarFilter.siteName.values },
        };
    }

    if (activeSideBarFilter?.resourceType?.values) {
        where = {
            ...where,
            resourceType: {
                in: activeSideBarFilter.resourceType.values.map((item: string) => {
                    let newItem = item;
                    if (item === ResourceType.DESK.value.toString()) {
                        newItem = ResourceType.DESK.name;
                    }
                    if (item === ResourceType.ROOM.value.toString()) {
                        newItem = ResourceType.ROOM.name;
                    }
                    return newItem;
                }),
            },
        };
    }

    if (activeSideBarFilter?.siteGroupName?.values) {
        where = {
            ...where,
            siteGroupId: { in: activeSideBarFilter.siteGroupName.values },
        };
    }

    if (activeSideBarFilter?.areaName?.values) {
        where = {
            ...where,
            areaId: { in: activeSideBarFilter.areaName.values },
        };
    }

    if (activeSideBarFilter?.bookingStatus?.values) {
        where = {
            ...where,
            bookingStatus: { in: activeSideBarFilter.bookingStatus.values },
        };
    }

    if (activeSideBarFilter?.isPrivate?.values && activeSideBarFilter?.isPrivate?.values.length === 1) {
        where = {
            ...where,
            isPrivate: {
                eq: Boolean(+activeSideBarFilter.isPrivate.values[0]),
            },
        };
    }

    if (activeSideBarFilter?.department?.values) {
        where = {
            ...where,
            department: { in: activeSideBarFilter.department.values },
        };
    }

    if (activeSideBarFilter?.attendeesCount?.values[0]) {
        switch (activeSideBarFilter.attendeesCount.values[0].option) {
            case 'less': {
                where = {
                    ...where,
                    attendeesCount: { lt: activeSideBarFilter.attendeesCount.values[0].value },
                };
                break;
            }
            case 'more': {
                where = {
                    ...where,
                    attendeesCount: { gt: activeSideBarFilter.attendeesCount.values[0].value },
                };
                break;
            }
            default: {
                where = {
                    ...where,
                    attendeesCount: { eq: activeSideBarFilter.attendeesCount.values[0].value },
                };
                break;
            }
        }
    }

    if (Object.keys(where).length === 0) {
        delete query.resourceBookings.__args.where;
    } else {
        query.resourceBookings.__args.where = where;
    }

    return jsonToGraphQLQuery(query);
};
