import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { createAsyncAction } from 'typesafe-actions';
import Guid from 'utilities/guid';

export const getGeographicStructureById = createAsyncAction(
    'GET_GEOGRAPHICSTRUCTURE_BY_ID_REQUEST',
    'GET_GEOGRAPHICSTRUCTURE_BY_ID_SUCCESS',
    'GET_GEOGRAPHICSTRUCTURE_BY_ID_FAILURE'
)<Guid, ApiResponse<GeographicStructureItem>, AxiosError | ApiResponse<GeographicStructureItem>>();

export const getGeographicStructureSites = createAsyncAction(
    'GET_GEOGRAPHICSTRUCTURE_SITES_REQUEST',
    'GET_GEOGRAPHICSTRUCTURE_SITES_SUCCESS',
    'GET_GEOGRAPHICSTRUCTURE_SITES_FAILURE'
)<Guid, { parentId: string; items: GeographicStructureItem[] }, AxiosError | ApiResponse<GeographicStructureItem[]>>();

export const getGeographicStructureAllSites = createAsyncAction(
    'GET_GEOGRAPHICSTRUCTURE_ALL_SITES_REQUEST',
    'GET_GEOGRAPHICSTRUCTURE_ALL_SITES_SUCCESS',
    'GET_GEOGRAPHICSTRUCTURE_ALL_SITES_FAILURE'
)<void, GeographicStructureItem[], AxiosError | ApiResponse<GeographicStructureItem[]>>();

export const getGeographicStructureSiteGroups = createAsyncAction(
    'GET_GEOGRAPHICSTRUCTURE_SITEGROUPS_REQUEST',
    'GET_GEOGRAPHICSTRUCTURE_SITEGROUPS_SUCCESS',
    'GET_GEOGRAPHICSTRUCTURE_SITEGROUPS_FAILURE'
)<Guid, { parentId: string; items: GeographicStructureItem[] }, AxiosError | ApiResponse<GeographicStructureItem[]>>();

export const getGeographicStructureAreas = createAsyncAction(
    'GET_GEOGRAPHICSTRUCTURE_AREAS_REQUEST',
    'GET_GEOGRAPHICSTRUCTURE_AREAS_SUCCESS',
    'GET_GEOGRAPHICSTRUCTURE_AREAS_FAILURE'
)<Guid, { parentId: string; items: GeographicStructureItem[] }, AxiosError | ApiResponse<GeographicStructureItem[]>>();

export default {
    getGeographicStructureById,
    getGeographicStructureSites,
    getGeographicStructureAllSites,
    getGeographicStructureSiteGroups,
    getGeographicStructureAreas,
};
