import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as hamburgerIcon } from 'assets/icons/nav/Hamburger.svg';
import Button from 'components/FormElements/Button';

import { Access } from '../../enums/authorization';
import { Permissions } from '../../features/Authentication/types';
import { GeographicStructureItem } from '../../services/ApiClients/OrganisationStructure';
import { TenantInfo } from '../../services/ApiClients/Organization/Models/TenantInfo';

import NavigationLink from './Components/NavigationLink';
import navigationMenu from './navigationMenu';

import './SiteNavigation.scss';

export interface NavigationProps {
    isOpen: boolean;
    onToggle: () => void;
    access: Access | 0;
    tenantInfo: TenantInfo | null;
    selectedSite: GeographicStructureItem | null;
    permissions?: Permissions;
}

const Navigation = ({
    isOpen,
    onToggle,
    access,
    tenantInfo,
    selectedSite,
    permissions,
}: NavigationProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <nav className={`siteNavigation ${isOpen ? 'open' : ''}`} aria-labelledby="hamburger">
            <Button
                id="hamburger"
                aria-label="Toggle Navigation Menu"
                aria-expanded={isOpen}
                type="button"
                onClick={onToggle}
                text="Toggle navigation"
                dataTestId="navigation-toggleButton"
                iconOnly
                icon={hamburgerIcon}
            />

            <ul>
                {navigationMenu(tenantInfo, selectedSite, permissions).map(
                    ({ to, icon, translation, dataTestId, accessTypes, turnedOn }) => {
                        const label = t(translation);
                        const end = translation === 'Navigation_Home';
                        if ((!accessTypes || accessTypes.some((at: Access) => at & access)) && turnedOn) {
                            return (
                                <li data-testid={dataTestId} key={dataTestId}>
                                    <NavigationLink end={end} to={to} icon={icon} label={label} title={label} />
                                </li>
                            );
                        }

                        return null;
                    }
                )}
            </ul>
        </nav>
    );
};

export default memo(Navigation);
