import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ClosePanel.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/SuccessTick.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ToastError.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ToastInfo.svg';
import Button from 'components/FormElements/Button';

import './ToastBody.scss';

export enum IconType {
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    Success = 'success',
}

export interface IconTypeProps {
    type: IconType;
}

export interface ToastBodyProps {
    title?: string;
    content: string;
    type?: IconType;
    closeToast?: () => void;
}

const ToastBody = ({ title, content, type = IconType.Success, closeToast }: ToastBodyProps): JSX.Element => {
    const Icon = (): JSX.Element => {
        switch (type) {
            case IconType.Warning:
            case IconType.Error:
                return <ErrorIcon />;
            case IconType.Info:
                return <InfoIcon />;
            case IconType.Success:
            default:
                return <SuccessIcon />;
        }
    };

    return (
        <div className={`toastBody ${type}`}>
            <div className="iconWrap">
                <Icon />
            </div>
            <div className="toastContent">
                {title ? <h1>{title}</h1> : null}
                <p>{content}</p>
            </div>
            {type !== IconType.Success && (
                <Button
                    dataTestId="closeNotification-button"
                    type="button"
                    onClick={closeToast}
                    text="close"
                    className="iconOnly"
                    icon={CloseIcon}
                />
            )}
        </div>
    );
};

export default ToastBody;
