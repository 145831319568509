import React, { RefObject, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnFilterInterface } from '../../../features/Reports/interfaces';
import CheckboxButtonSelector from '../../CheckboxButtonSelector/CheckboxButtonSelector';
import { TextBox } from '../../FormElements';
import Button from '../../FormElements/Button';
import WithLoading from '../../HOC/WithLoading';

import './ReportFilterPanelSearch.scss';

interface ReportFilterPanelSearchProps {
    activeFilter: string;
    activeColumnsFilterArray: any[];
    columnsFilter: ColumnFilterInterface;
    generateReportWithColumnsFilter: () => void;
    isLoading?: boolean;
    toggleAll: () => void;
    handleChange: (event: SyntheticEvent) => void;
    scrollContainerRef: RefObject<HTMLInputElement>;
}

const ReportFilterPanelSearch = ({
    activeFilter,
    activeColumnsFilterArray,
    columnsFilter,
    generateReportWithColumnsFilter,
    isLoading,
    toggleAll,
    handleChange,
    scrollContainerRef,
}: ReportFilterPanelSearchProps): JSX.Element => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState('');
    const [filteredArray, setFilteredArray] = useState<any[]>([]);

    useEffect(() => {
        setFilteredArray(activeColumnsFilterArray);
    }, [activeColumnsFilterArray]);

    return (
        <WithLoading isLoading={isLoading}>
            <div className="report-filter-container">
                <Button
                    dataTestId="toggle-all-columns"
                    text={t('Reports_ToggleAll')}
                    className="toggle-btn"
                    onClick={toggleAll}
                />
                <TextBox
                    className="report-filter-search"
                    value={searchValue}
                    onChange={(value: string) => {
                        setSearchValue(value);
                        setFilteredArray([
                            ...activeColumnsFilterArray.filter((item) =>
                                item.label.toLowerCase().startsWith(value.toLocaleLowerCase())
                            ),
                        ]);
                    }}
                    type="search"
                    dataTestId="report-filter-search"
                    placeholder="Department"
                />
                <div className="report-filter-content-scroll" ref={scrollContainerRef}>
                    {filteredArray.length
                        ? filteredArray.map((item) => {
                              return (
                                  <CheckboxButtonSelector
                                      label={item.label}
                                      value={item.value}
                                      checked={columnsFilter[activeFilter]?.values.includes(item.value)}
                                      handleChange={handleChange}
                                  />
                              );
                          })
                        : null}
                    {searchValue && !filteredArray.length ? (
                        <div className="report-filter-no-result">
                            <span className="report-filter-no-result-message">{`${t(
                                'Report_Filter_NoResults'
                            )} ${searchValue}`}</span>
                        </div>
                    ) : null}
                </div>
                <div className="report-filter-actions">
                    <Button
                        text={t('Button_Apply')}
                        type="button"
                        className="apply-btn"
                        dataTestId="apply-btn-btn"
                        disabled={!columnsFilter[activeFilter]?.values.length}
                        onClick={generateReportWithColumnsFilter}
                    />
                </div>
            </div>
        </WithLoading>
    );
};

export default ReportFilterPanelSearch;
