import React from 'react';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import i18n from 'i18next';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

import ReportFilterColumnHeader from '../../../components/ReportsFilterColumnHeader/ReportFilterColumnHeader';
import { AllListItemOption, DEFAULT_DATE_FORMAT } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface } from '../interfaces';
import { dateFormatter, timeFormatterWithTimeFormat } from '../utils';

const items = {
    id: true,
    friendlyId: true,
    createdBy: true,
    due: true,
    dueTime: true,
    status: true,
    visitorName: true,
    company: true,
    visitorType: true,
    hostName: true,
    siteName: true,
    siteId: true,
    siteGroupName: true,
    siteGroupId: true,
    timezone: true,
    arrivalDateTime: true,
    arrivalTime: true,
    departureDateTime: true,
    departureTime: true,
};

export const getColumns = (
    showSiteGroups: boolean,
    handleFilterClick: (field: string, headerName: string) => void,
    sideBarFilter: ColumnFilterInterface,
    activeSideBarFilter: ActiveColumnFilterInterface,
    is24HoursTimeFormat?: boolean
): GridColDef[] => [
    {
        field: 'friendlyId',
        headerName: i18n.t('Reports_BookingId'),
        minWidth: 150,
        flex: 0.1,
    },
    {
        field: 'createdBy',
        headerName: i18n.t('Reports_BookedBy'),
        minWidth: 175,
        flex: 0.1,
        cellClassName: 'capitalize',
    },
    {
        field: 'due',
        headerName: i18n.t('Reports_DueDate'),
        minWidth: 200,
        flex: 0.2,
        valueGetter: (params) => dateFormatter(params.row.due, params.row.timezone),
    },
    {
        field: 'dueTime',
        headerName: i18n.t('Reports_DueTime'),
        minWidth: 200,
        flex: 0.2,
        valueGetter: (params) => timeFormatterWithTimeFormat(params.row.due, is24HoursTimeFormat, params.row.timezone),
    },
    {
        field: 'timezone',
        headerName: i18n.t('Reports_Timezone'),
        minWidth: 200,
        flex: 0.2,
    },
    {
        field: 'status',
        headerName: i18n.t('Reports_Status'),
        minWidth: 150,
        flex: 0.1,
    },
    {
        field: 'visitorName',
        headerName: i18n.t('Reports_Visitor'),
        minWidth: 200,
        flex: 0.2,
    },
    {
        field: 'company',
        headerName: i18n.t('Reports_Company'),
        minWidth: 150,
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.company.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Company')}</p>
            ),
    },
    {
        field: 'visitorType',
        headerName: i18n.t('Reports_Type'),
        minWidth: 150,
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.visitorType.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Type')}</p>
            ),
    },
    {
        field: 'hostName',
        headerName: i18n.t('Reports_Host'),
        minWidth: 200,
        flex: 0.2,
    },
    ...(showSiteGroups
        ? [
              {
                  field: 'siteGroupName',
                  headerName: i18n.t('Reports_SiteGroup'),
                  minWidth: 200,
                  flex: 0.2,
              },
          ]
        : []),
    {
        field: 'siteName',
        headerName: i18n.t('Reports_Site'),
        minWidth: 200,
        flex: 0.2,
    },
    {
        field: 'arrivalDateTime',
        headerName: i18n.t('VisitorBooking_ArrivalDate'),
        minWidth: 200,
        flex: 0.2,
        valueFormatter: (params) => dateFormatter(params.value),
    },
    {
        field: 'arrivalTime',
        headerName: i18n.t('VisitorBooking_ArrivalTime'),
        minWidth: 200,
        flex: 0.2,
        valueGetter: (params) => timeFormatterWithTimeFormat(params.row.arrivalDateTime, is24HoursTimeFormat),
    },
    {
        field: 'departureDateTime',
        headerName: i18n.t('Reports_DepartureDate'),
        minWidth: 200,
        flex: 0.2,
        valueFormatter: (params) => dateFormatter(params.value),
    },
    {
        field: 'departureTime',
        headerName: i18n.t('Reports_DepartureTime'),
        minWidth: 200,
        flex: 0.2,
        valueGetter: (params) => timeFormatterWithTimeFormat(params.row.departureDateTime, is24HoursTimeFormat),
    },
];

export const buildQuery = (
    itemsPerPageAmount: number,
    activePage: number,
    sortOptions: any,
    reportsFilter: any,
    activeSideBarFilter?: ActiveColumnFilterInterface,
    isForExport = false,
    totalCount = 0,
    visibleColumns?: string[]
): string => {
    const { selectedSiteGroup, selectedSite } = reportsFilter;
    const ascSorting = new EnumType('ASC');
    const graphQLItems: { [key: string]: any } = { ...items };
    if (visibleColumns) {
        Object.keys(graphQLItems).forEach((key: string) => {
            if (!visibleColumns.includes(key)) {
                delete graphQLItems[key];
            }
        });
    }
    const query = {
        siteVisits: {
            __args: {
                skip: isForExport ? 0 : itemsPerPageAmount * (activePage - 1),
                take: isForExport ? totalCount : itemsPerPageAmount,
                siteGroupId: selectedSiteGroup?.value !== AllListItemOption.value ? selectedSiteGroup?.value : null,
                siteId: selectedSite?.value !== AllListItemOption.value ? selectedSite?.value : null,
                from: reportsFilter.dateRange.from.toFormat(DEFAULT_DATE_FORMAT),
                to: reportsFilter.dateRange.to.toFormat(DEFAULT_DATE_FORMAT),
                order: {
                    // default sorting (grouping)
                    ...(sortOptions[0]?.field !== 'siteGroupName' && { siteGroupName: ascSorting }),
                    ...(sortOptions[0]?.field !== 'siteName' && { siteName: ascSorting }),
                },
                where: {},
            },
            items: graphQLItems,
            totalCount: true,
        },
    };

    if (sortOptions?.length) {
        query.siteVisits.__args.order = {
            [sortOptions[0].field]: new EnumType(sortOptions[0].sort.toUpperCase()),
            ...query.siteVisits.__args.order,
        };
    }

    let where = {};

    if (reportsFilter?.companyName !== AllListItemOption.label) {
        where = {
            ...where,
            company: { eq: reportsFilter.companyName },
        };
    }

    if (activeSideBarFilter?.company?.values) {
        where = {
            ...where,
            company: { in: activeSideBarFilter.company.values },
        };
    }

    if (activeSideBarFilter?.visitorType?.values) {
        where = {
            ...where,
            visitorType: { in: activeSideBarFilter.visitorType.values },
        };
    }

    if (Object.keys(where).length === 0) {
        delete query.siteVisits.__args.where;
    } else {
        query.siteVisits.__args.where = where;
    }

    return jsonToGraphQLQuery(query);
};
