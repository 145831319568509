import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import VisitorBookingsReport from '../../features/Reports/VisitorBookingsReport';

const VisitorBookings = (): JSX.Element => {
    return (
        <MainLayout>
            <VisitorBookingsReport />
        </MainLayout>
    );
};

export default VisitorBookings;
