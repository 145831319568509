import { connect } from 'react-redux';

import { Access } from '../../../../enums/authorization';
import { setUser } from '../../Redux/actions';
import { UserProfile } from '../../types';

// eslint-disable-next-line import/no-cycle
import AuthorizeRoute from './AuthorizeRoute';

const mapStateToProps = null;

const mapDispatchToProps = {
    setUser: (user: UserProfile) => setUser(user),
};

export type AuthorizeRouteProps = typeof mapDispatchToProps & {
    accessTypes?: Access[];
    turnedOn?: boolean;
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeRoute);
