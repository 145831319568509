import React from 'react';
import { ReactComponent as BookingInfoIcon } from 'assets/icons/BookingInfoTabIcon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { ReactComponent as DeskInfoTabIcon } from 'assets/icons/DeskInfoTabIcon.svg';
import { ReactComponent as BookingHistoryIcon } from 'assets/icons/HistoryInfoTabIcon.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/RepeatsInfoTabIcon.svg';
import { ReactComponent as RoomInfoTabIcon } from 'assets/icons/RoomInfoTabIcon.svg';
import { ReactComponent as SeriesInfoIcon } from 'assets/icons/SeriesInfo.svg';
import ActionButton, {
    createDangerButton,
    createEditButton,
    createPrimaryButton,
    createSecondaryButton,
    createSuccessButton,
} from 'components/ActionButtons/Models/ActionButton';
import DateTimePickerValue from 'components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import { BOOKING_STATUS, CHECK_IN_STATUS, EditMode } from 'features/constants';
import { BookingInfoDataState } from 'features/Resources/Common/EditBooking/Editor/Models/BookingInfoDataState';
import History from 'features/Resources/Common/EditBooking/History';
import ResourceInfo from 'features/Resources/Common/EditBooking/ResourceInfo';
import BookMode from 'features/Resources/Common/Models/BookMode';
import i18n from 'i18n';
import { DateTime, Interval } from 'luxon';
import { RepeatSchedule } from 'services/ApiClients/Booking';
import { Occurrence } from 'services/ApiClients/Booking/Models/Occurrence';
import { findResourceNameByValue, ResourceType } from 'services/ApiClients/Resource';
import Guid from 'utilities/guid';
import { isNil } from 'utilities/ts';

import BookingInfo from './Components/BookingInfo';
import RepeatInfo from './Components/RepeatInfo';
// eslint-disable-next-line import/no-cycle
import SeriesInfo from './Components/SeriesInfo';

export const repeatTabs = (
    bookingInfo: any,
    resourceType: number,
    editMode: EditMode,
    isRepeatsEnabled: boolean,
    handleDateTimeChange: (value: DateTimePickerValue) => void,
    handleSetBookingInfoDataState: (bookingInfoData: BookingInfoDataState) => void,
    bookingInfoDataState: BookingInfoDataState
): any =>
    [
        editMode === EditMode.ENABLE_SERIES
            ? {
                  id: 'seriesInfo',
                  icon: SeriesInfoIcon,
                  title: 'Series Info',
                  dataTestId: 'seriesInfo-tab',
                  content: (
                      <SeriesInfo
                          handleSetBookingInfoDataState={handleSetBookingInfoDataState}
                          bookingInfoDataState={bookingInfoDataState}
                      />
                  ),
              }
            : {
                  id: 'bookingInfo',
                  icon: BookingInfoIcon,
                  title: i18n.t('Combined_BookingInfo_TabTitle'),
                  dataTestId: 'bookingInfo-tab',
                  content: (
                      <BookingInfo
                          updateDateAndTime={handleDateTimeChange}
                          bookingInfo={bookingInfo}
                          disabled={editMode === EditMode.DISABLED_OCCURRENCE}
                          editMode={editMode}
                          bookingInfoDataState={bookingInfoDataState}
                          handleSetBookingInfoDataState={handleSetBookingInfoDataState}
                      />
                  ),
              },
        {
            id: 'combinedInfo',
            icon: resourceType === ResourceType.DESK.value ? DeskInfoTabIcon : RoomInfoTabIcon,
            title: i18n.t('Combined_Info_TabTitle', {
                type: findResourceNameByValue(resourceType),
            }),
            dataTestId: 'combinedInfo-tab',
            content: <ResourceInfo />,
        },
        isRepeatsEnabled
            ? { id: 'repeat', icon: RepeatIcon, title: 'Repeats', dataTestId: 'repeat-tab', content: <RepeatInfo /> }
            : null,
        {
            id: 'history',
            icon: BookingHistoryIcon,
            title: i18n.t('Combined_History_TabTitle'),
            dataTestId: 'history-tab',
            content: <History />,
        },
    ].filter((tab) => tab !== null);

export const repeatButtons = (
    bookMode: BookMode,
    bookingStatus: string,
    now: DateTime,
    startTime: DateTime,
    endTime: DateTime,
    operationInProgress: boolean,
    checkInStatus: string,
    canEditNotOwnBooking: boolean,
    isCurrentUserBooking: boolean,
    isEarlyCheckedInBooking: boolean,
    editMode: EditMode,
    repeatSchedule: RepeatSchedule,
    repeatBookingId: Guid,
    isUpdatePending: boolean,
    handleClose: () => void,
    handleExitWithoutSaving: (shouldExit?: boolean, newMode?: EditMode) => void,
    handleCheckIn: () => void,
    handleCheckOut: () => void,
    handleEdit: () => void,
    handleDeleteSeries: () => void,
    handleCancelSingleOccurrence: () => void,
    isShowCheckInOutButtons: boolean
): any => {
    const buttons: ActionButton[] = [];
    const isCheckedInStatus = checkInStatus === CHECK_IN_STATUS.CHECKED_IN;
    const isCheckedOutStatus = checkInStatus === CHECK_IN_STATUS.CHECKED_OUT;
    const checkInIcon = isCheckedInStatus ? CheckIcon : undefined;

    const checkInButton = createSuccessButton(
        'checkIn',
        isCheckedInStatus ? i18n.t('Button_CheckedIn') : i18n.t('Button_CheckIn'),
        handleCheckIn,
        operationInProgress,
        checkInIcon,
        undefined,
        isCheckedInStatus || isCheckedOutStatus || editMode === EditMode.ENABLE_OCCURRENCE
    );

    const checkOutButton = createDangerButton(
        'checkOut',
        i18n.t('Button_CheckOut'),
        handleCheckOut,
        operationInProgress,
        undefined,
        undefined,
        isCheckedOutStatus || editMode === EditMode.ENABLE_OCCURRENCE
    );

    const deleteSeries = createDangerButton(
        'deleteSeries',
        i18n.t('Repeat_Delete_Button'),
        handleDeleteSeries,
        operationInProgress,
        undefined,
        undefined
    );

    const saveSeries = createPrimaryButton('saveSeries', i18n.t('Repeat_Save_Series'), () => {}, operationInProgress);

    const saveButtons = createPrimaryButton('save', i18n.t('Button_Save'), () => {}, operationInProgress);

    const editButton = createEditButton('edit', 'Edit', handleEdit, operationInProgress, undefined, undefined, false);

    const cancelSingleOccurrenceButton = createDangerButton(
        'cancelSingleOccurence',
        i18n.t('Repeat_Delele_Occurrence'),
        handleCancelSingleOccurrence,
        operationInProgress,
        undefined,
        undefined,
        false
    );

    if (editMode === EditMode.DISABLED_OCCURRENCE) {
        buttons.push(editButton);
    }

    if (editMode === EditMode.ENABLE_OCCURRENCE) {
        buttons.push(saveButtons);
    }

    if (editMode === EditMode.ENABLE_SERIES) {
        buttons.push(deleteSeries);
        buttons.push(saveSeries);
    }

    if (bookMode === BookMode.Edit) {
        if (
            (isCurrentUserBooking || canEditNotOwnBooking) &&
            (isEarlyCheckedInBooking || Interval.fromDateTimes(startTime, endTime).contains(now)) &&
            editMode === EditMode.DISABLED_OCCURRENCE &&
            isShowCheckInOutButtons
        ) {
            buttons.push(checkInButton);
            buttons.push(checkOutButton);
        }
    }

    if (
        (repeatSchedule || !isNil(repeatBookingId)) &&
        now < endTime &&
        now < startTime &&
        editMode !== EditMode.ENABLE_SERIES &&
        !isCheckedInStatus &&
        !isCheckedOutStatus
    ) {
        buttons.push(cancelSingleOccurrenceButton);
    }

    const closeButton = createSecondaryButton(
        'close',
        i18n.t('Button_Close'),
        isUpdatePending ? () => handleExitWithoutSaving(true) : handleClose
    );

    if (bookingStatus !== BOOKING_STATUS.PENDING) {
        buttons.push(closeButton);
    }

    return buttons;
};

export const isSeriesInfoEnable = (
    occurrences: Occurrence[],
    dateTimeNow: DateTime,
    canEditPastBooking: boolean | null
): boolean => {
    if (canEditPastBooking) {
        return true;
    }

    return occurrences.some((occurrence) => DateTime.fromISO(occurrence.startDateTime) > dateTimeNow);
};
