import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { ApplicationPaths, LoginActions, QueryParameterNames } from 'features/Authentication/constants';
import authService, { AuthenticationResultStatus } from 'features/Authentication/Services/AuthorizeService';
import { AuthRequestState, AuthResponse } from 'features/Authentication/types';

// eslint-disable-next-line import/no-cycle
import { LoginProps } from '.';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
const Login = ({ action, onSuccess, onFailure }: LoginProps): JSX.Element => {
    const [message, setMessage] = useState<string | null>(null);

    const getReturnUrl = (state: AuthRequestState | void): string => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);

        // TODO: How much of an attack surface is this?
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
        }

        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    };

    const navigateToReturnUrl = (returnUrl: string): void => {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    };

    const processLoginCallback = async (): Promise<void> => {
        const url = window.location.href;
        const result: AuthResponse = await authService.completeSignIn(url);

        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                onSuccess(getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                onFailure();
                setMessage(result.message ? result.message : null);
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    };

    const redirectToApiAuthorizationPath = (apiAuthorizationPath: string): void => {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath || ''}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    };

    const redirectToProfile = (): void => {
        redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    };

    const redirectToRegister = (): void => {
        redirectToApiAuthorizationPath(
            `${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(
                ApplicationPaths.Login
            )}`
        );
    };

    const login = async (returnUrl: string): Promise<void> => {
        const result: AuthResponse = await authService.signIn({ returnUrl });

        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                break;
            case AuthenticationResultStatus.Success:
                onSuccess(getReturnUrl(result.state));
                await navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                onFailure();
                setMessage(result.message ? result.message : null);
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    };

    useEffect(() => {
        switch (action) {
            case LoginActions.Login:
                login(getReturnUrl());
                break;
            case LoginActions.LoginCallback:
                processLoginCallback();
                break;
            case LoginActions.LoginFailed:
                // These constants are defined here for readability. These constants will not be required in any other scenario except in a Login failed scenario
                /* eslint-disable no-case-declarations */
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                setMessage(error);
                /* eslint-enable no-case-declarations */
                break;
            case LoginActions.Profile:
                redirectToProfile();
                break;
            case LoginActions.Register:
                redirectToRegister();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }, [action]);

    if (message) {
        return <div>{message}</div>;
    }

    switch (action) {
        case LoginActions.Login:
            return <Loading />;
        case LoginActions.LoginCallback:
            return <Loading />;
        case LoginActions.Profile:
        case LoginActions.Register:
            return <div />;
        default:
            throw new Error(`Invalid action '${action}'`);
    }
};

export default Login;
