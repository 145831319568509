import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import ActionButtonsPanel from '../../../../components/ActionButtonsPanel';
import Button from '../../../../components/FormElements/Button';
import HeaderSteps from '../../../../components/HeaderSteps';
import MultiResourceBookingSiteSelector from '../../../../components/MultiResourceBookingSiteSelector';
import Header from '../../../../components/SiteSubHeader';
import { GeographicStructureItem } from '../../../../services/ApiClients/OrganisationStructure';
import { DEFAULT_TIMEZONE } from '../../../constants';

import DateTimeHeader from './Components/DateTimeHeader';
import SelectedSitesPanel from './Components/SelectedSitesPanel';
// eslint-disable-next-line import/no-cycle
import { MultiResourceEditProps } from './index';

import '../../Common/EditBooking/Editor/Editor.scss';
import './MultiResourceBooking.scss';

const MultiResourceBooking = ({
    selectedSiteTimezone,
    sites,
    siteGroups,
    tenantId,
    selectedSiteGlobal,
    selectedSiteGroupGlobal,
    originalBookingInfo,
    bookingId,
    areas,
}: MultiResourceEditProps): JSX.Element => {
    const [selectedSiteGroup, setSelectedSiteGroup] = useState<GeographicStructureItem | null>(selectedSiteGroupGlobal);
    const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([selectedSiteGlobal?.id.toString() || '']);
    const [selectedResourceIds] = useState<string[]>([
        (originalBookingInfo?.resource && originalBookingInfo.resource.id.toString()) || '',
    ]);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const defaultDate = {
        startDateTime: DateTime.fromISO(originalBookingInfo.item?.startDateTime, {
            zone: selectedSiteTimezone || DEFAULT_TIMEZONE,
        }),
        endDateTime: DateTime.fromISO(originalBookingInfo.item?.endDateTime, {
            zone: selectedSiteTimezone || DEFAULT_TIMEZONE,
        }),
        isAM: false,
        isPM: false,
        isAllDay: false,
    };
    const [dateTime, setDateTime] = useState<any>(defaultDate);
    const isTopLevelGeographicStructure = sites[0]?.parentId === tenantId;

    const steps = [
        t('MultiResourceBooking_SelectSites'),
        t('MultiResourceBooking_AddResources'),
        t('MultiResourceBooking_BookingDetails'),
    ];

    return (
        <main className="multi-resource-booking-page">
            <Header
                className="multi-resource-booking-header"
                title={t('MultiResourceBooking_Title')}
                dataTestId="multi-resource-booking-title"
                rightSideItems={[<HeaderSteps stepNames={steps} currentStepNumber={0} />]}
            />
            <DateTimeHeader timezone={selectedSiteTimezone} selectedDate={dateTime} onDateTimeChange={setDateTime} />

            <div className="main-content">
                <MultiResourceBookingSiteSelector
                    sites={sites}
                    siteGroups={siteGroups}
                    tenantId={tenantId}
                    selectedSiteIds={selectedSiteIds}
                    selectedSiteGroup={selectedSiteGroup}
                    setSelectedSiteIds={setSelectedSiteIds}
                    setSelectedSiteGroup={setSelectedSiteGroup}
                    selectedSiteGlobalId={selectedSiteGlobal?.id}
                    isTopLevelGeographicStructure={isTopLevelGeographicStructure}
                />

                <SelectedSitesPanel
                    sites={sites.filter((s: any) => selectedSiteIds.includes(s.id))}
                    selectedResourceIds={selectedResourceIds}
                    resources={originalBookingInfo?.resource ? [originalBookingInfo.resource] : []}
                    selectedDate={dateTime}
                    isTopLevelGeographicStructure={isTopLevelGeographicStructure}
                    siteGroups={siteGroups[tenantId || '']}
                    selectedSiteGlobalId={selectedSiteGlobal?.id.toString() || ''}
                    areas={areas}
                />
            </div>

            <ActionButtonsPanel>
                <>
                    <Button
                        text={t('MultiResourceBooking_ExitWithoutSaving')}
                        className="save-and-checkin-btn"
                        dataTestId="exit-without-saving"
                        onClick={() => navigate(`/bookings/${bookingId}`)}
                        transparent
                    />

                    <Button text={t('Button_Next')} className="book-btn" dataTestId="book-btn" onClick={() => {}} />
                </>
            </ActionButtonsPanel>
        </main>
    );
};

export default MultiResourceBooking;
