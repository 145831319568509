import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeskIcon } from 'assets/icons/floorPlan/DeskSearchIcon.svg';
import { ReactComponent as RoomIcon } from 'assets/icons/floorPlan/RoomSearchIcon.svg';
import { ReactComponent as ExternalVisitorIcon } from 'assets/icons/visitor/ExternalVisitor.svg';
import { ReactComponent as InternalVisitorIcon } from 'assets/icons/visitor/InternalVisitor.svg';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { ResourceType } from 'services/ApiClients/Resource';
import { SearchResponse } from 'services/ApiClients/Search/Models/SearchResponse';

import { SearchDiscriminator, SearchPersonType } from './utils';

import './FloorPlanSearchResultsItem.scss';

export interface SearchResultsItemProps {
    result: SearchResponse;
    extendedHourFormat: string;
    selectedSiteTimezone: string;
    onClose: () => void;
    handleShowSearchedItem: (siteId: string) => void;
    setSearchResultItem: (item: SearchResponse) => void;
    setSearchTrigger: (item: boolean) => void;
}

const FloorPlanSearchResultsItem = ({
    result,
    extendedHourFormat,
    onClose,
    selectedSiteTimezone,
    handleShowSearchedItem,
    setSearchResultItem,
    setSearchTrigger,
}: SearchResultsItemProps): JSX.Element => {
    const {
        discriminator,
        resourceType,
        resourceName,
        areaName,
        siteName,
        createdBy,
        onBehalfOf,
        startTime,
        endTime,
        restrictedTo,
        siteId,
    } = result;
    const { t } = useTranslation();
    const [selected, setSelected] = useState(false);

    const deskType = ResourceType.DESK.name.toLowerCase();
    const roomType = ResourceType.ROOM.name.toLowerCase();

    const isResourceDiscriminator = discriminator === SearchDiscriminator.RESOURCE;
    const isBookingDiscriminator = discriminator === SearchDiscriminator.BOOKING;

    const isDeskIcon = isResourceDiscriminator && resourceType === deskType;
    const isRoomIcon = isResourceDiscriminator && resourceType === roomType;

    const isInternalUser =
        (isBookingDiscriminator && !onBehalfOf?.oboType) || onBehalfOf?.oboType === SearchPersonType.PERSON;
    const isExternalUser = isBookingDiscriminator && onBehalfOf?.oboType === SearchPersonType.VISITOR;

    const isRestrictedToSingleUser = restrictedTo?.firstname && restrictedTo?.surname;
    const restrictedToText = `${t('FloorPlan_Search_RestrictedTo')} ${restrictedTo?.surname}, ${
        restrictedTo?.firstname
    }`;

    const formattedDateTime = `${DateTime.fromISO(startTime as string)
        .setZone(selectedSiteTimezone)
        .toFormat('dd MMM yyyy')} / ${DateTime.fromISO(startTime as string)
        .setZone(selectedSiteTimezone)
        .toFormat(extendedHourFormat)} - ${DateTime.fromISO(endTime as string)
        .setZone(selectedSiteTimezone)
        .toFormat(extendedHourFormat)}`;

    const handleSearchResultItemClick = (): void => {
        setSelected(true);
        setSearchTrigger(true);
        setSearchResultItem(result);
        handleShowSearchedItem(siteId);
        onClose();
    };

    return (
        <div
            className={cn('search-list-item', { selected })}
            onClick={handleSearchResultItemClick}
            data-testid="search-list-item"
        >
            {isResourceDiscriminator ? (
                <div className="text-wrapper">
                    {isRestrictedToSingleUser ? <p className="title">{restrictedToText}</p> : null}
                    <p className={`${isRestrictedToSingleUser ? 'text' : 'title'}`}>{resourceName}</p>
                    <p className="text"> {areaName} </p>
                    <p className="text"> {siteName} </p>
                </div>
            ) : null}
            {isBookingDiscriminator ? (
                <div className="text-wrapper">
                    {onBehalfOf?.oboType ? (
                        <p className="title">{`${onBehalfOf?.surname}, ${onBehalfOf?.firstname}`}</p>
                    ) : (
                        <p className="title">{`${createdBy?.surname}, ${createdBy?.firstname}`}</p>
                    )}
                    {isExternalUser ? <p className="text">{onBehalfOf?.email} </p> : null}
                    <p className="text"> {formattedDateTime} </p>
                    <p className="text"> {`${areaName} > ${resourceName}`} </p>
                    <p className="text"> {siteName} </p>
                </div>
            ) : null}
            <div className="icon">
                {isInternalUser && <InternalVisitorIcon />}
                {isExternalUser && <ExternalVisitorIcon />}
                {isDeskIcon && <DeskIcon />}
                {isRoomIcon && <RoomIcon />}
            </div>
        </div>
    );
};

export default FloorPlanSearchResultsItem;
