import { GridCellValue, GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import * as XLSX from 'xlsx';

import { ListItem } from '../../components/Forms';
import { PersonSearchData } from '../../components/PersonSearchPanels/interfaces/PersonSearchData';
import { ReportPermissions } from '../../enums/authorization';
import { PersonType } from '../../enums/PersonType';
import { ReportType } from '../../enums/ReportType';
import { GeographicStructureItem } from '../../services/ApiClients/OrganisationStructure';
import { buildNameSearchQueryItems, SearchData, SearchItem } from '../../utilities/searchUtils';
import { mapListItems } from '../../utilities/selectList';
import {
    AllListItemOption,
    DEFAULT_TIMEZONE,
    GRID_DATE_FORMAT,
    GRID_TIME_12H_FORMAT,
    GRID_TIME_24H_FORMAT,
} from '../constants';

export const customReportSelectStyles = {
    singleValue: (base: any) => ({
        ...base,
        minHeight: 25,
    }),
};

export const getReportTypesListItems = (t: TFunction, reportsPermissions: number): ListItem[] => {
    return [
        ...(reportsPermissions & ReportPermissions.AccessLoginsReport
            ? [{ label: t('Reports_Logins'), value: ReportType.Logins.toString() }]
            : []),
        ...(reportsPermissions & ReportPermissions.AccessResourceBookingReport
            ? [{ label: t('Reports_ResourceBookings'), value: ReportType.ResourceBookings.toString() }]
            : []),
        ...(reportsPermissions & ReportPermissions.AccessResourceListReport
            ? [{ label: t('Reports_ResourcesList'), value: ReportType.ResourcesList.toString() }]
            : []),
        ...(reportsPermissions & ReportPermissions.AccessResourceUtilisationReport
            ? [{ label: t('Reports_ResourceUtilisation'), value: ReportType.ResourceUtilization.toString() }]
            : []),
        ...(reportsPermissions & ReportPermissions.AccessUsersReport
            ? [{ label: t('Reports_Users'), value: ReportType.Users.toString() }]
            : []),
        ...(reportsPermissions & ReportPermissions.AccessVisitorBookingsReport
            ? [{ label: t('Reports_VisitorBookings'), value: ReportType.VisitorBookings.toString() }]
            : []),
        ...(reportsPermissions & ReportPermissions.AccessVisitorProfileReport
            ? [{ label: t('Reports_VisitorProfile'), value: ReportType.VisitorProfile.toString() }]
            : []),
    ].sort((a, b) => {
        // eslint-disable-next-line no-nested-ternary
        return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    });
};

const allOption = { label: 'All', value: 'all' };

export const getSiteGroupListItems = (
    siteGroups: GeographicStructureItem[],
    sites: GeographicStructureItem[]
): ListItem[] => {
    const listItems = siteGroups
        ? orderBy(
              mapListItems(
                  siteGroups.filter((i) => sites.some((s) => s.parentId === i.id)),
                  (item: GeographicStructureItem) => item.id.toString(),
                  (item: GeographicStructureItem) => item.name
              ),
              ['label', 'asc']
          )
        : [];

    return [allOption, ...listItems];
};

export const getSiteListItems = (sites: GeographicStructureItem[], selectedSiteGroup: ListItem): ListItem[] => {
    const filteredSites =
        selectedSiteGroup?.value === allOption.value
            ? sites
            : sites.filter((s) => s.parentId?.toString() === selectedSiteGroup?.value);

    const listItems = orderBy(
        mapListItems(
            filteredSites,
            (item: GeographicStructureItem) => item.id.toString(),
            (item: GeographicStructureItem) => item.name
        ),
        ['label', 'asc']
    );

    return [allOption, ...listItems];
};

export const getAreaListItemsOfSelectedSite = (
    areas: { [parentId: string]: GeographicStructureItem[] },
    selectedSite: ListItem
): ListItem[] => {
    const filteredAreas =
        selectedSite?.value === allOption.value || !areas[selectedSite?.value] ? [] : areas[selectedSite?.value];

    const listItems = orderBy(
        mapListItems(
            filteredAreas,
            (item: GeographicStructureItem) => item.id.toString(),
            (item: GeographicStructureItem) => item.name
        ),
        ['label', 'asc']
    );

    return [allOption, ...listItems];
};

export const onVisitorSearch = (
    personSearchData: PersonSearchData,
    reportsFilter: any,
    personType: PersonType,
    searchInternalVisitors: Function,
    searchExternalVisitors: Function
): void => {
    let searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>> = [];
    if (personSearchData.name) {
        searchFieldsConditionsOrUnitedByAnd = buildNameSearchQueryItems(personSearchData.name);
    }
    const searchFieldsConditionAND = [];

    if (personSearchData.email) {
        searchFieldsConditionAND.push({ field: 'Email', value: personSearchData.email });
    }

    if (reportsFilter.companyName && reportsFilter.companyName !== AllListItemOption.label) {
        searchFieldsConditionAND.push({ field: 'Company', value: reportsFilter.companyName });
    }

    const searchData: SearchData = {
        searchFieldsConditionAND,
        searchFieldsConditionsOrUnitedByAnd,
        top: 100,
        orderBy: 'Surname',
    };
    if (personType === PersonType.Internal) {
        searchInternalVisitors(searchData);
    } else {
        searchExternalVisitors(searchData);
    }
};

export const onUserSearch = (searchData: PersonSearchData, searchUsers: Function): void => {
    let searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>> = [];

    if (searchData) {
        searchFieldsConditionsOrUnitedByAnd = buildNameSearchQueryItems(searchData.name);
    }

    const modifiedSearchData: SearchData = {
        searchFieldsConditionsOrUnitedByAnd,
        top: 100,
        orderBy: 'Surname',
    };

    searchUsers(modifiedSearchData);
};

export const dateFormatter = (value: GridCellValue, timeZone?: string): string => {
    return !value
        ? ''
        : DateTime.fromISO(value.toString(), { zone: timeZone || DEFAULT_TIMEZONE }).toFormat(GRID_DATE_FORMAT);
};

export const timeFormatterWithTimeFormat = (value: GridCellValue, is24Format?: boolean, timeZone?: string): string => {
    return !value
        ? ''
        : DateTime.fromISO(value.toString(), { zone: timeZone || DEFAULT_TIMEZONE }).toFormat(
              is24Format ? GRID_TIME_24H_FORMAT : GRID_TIME_12H_FORMAT
          );
};

export const convertDateForExport = (dateTime: string, timezone?: string): string | Date => {
    return !dateTime
        ? ''
        : new Date(DateTime.fromISO(dateTime, { zone: timezone || DEFAULT_TIMEZONE }).toFormat('yyyy-MM-dd TT'));
};

export const convertTimeForExport = (dateTime: string, timezone?: string): string | Date => {
    return !dateTime ? '' : DateTime.fromISO(dateTime, { zone: timezone || DEFAULT_TIMEZONE }).toFormat('TT');
};

export const filterArrayByAll = (array: { value: string; label: string }[]): { value: string; label: string }[] =>
    array.filter((item) => item.value !== 'all');

export const createValuesArray = (array: { value: string; label: string }[]): string[] =>
    array.map((item) => item.value);

export const getDefaultColumnsValues = (array: { value: string; label: string }[]): string[] =>
    createValuesArray(filterArrayByAll(array));

export const checkEnabling = (
    array: { value: string; label: string }[],
    value: any,
    addColumnsFilterAfterApply = true
): boolean => (addColumnsFilterAfterApply ? array.length > 1 && value === 'all' : false);

export const capitalizeFirstLetter = (string: string): string =>
    !string ? '' : string.charAt(0).toUpperCase() + string.slice(1);

const autoFitColumns = (json: any[], worksheet: XLSX.WorkSheet, header?: string[]): void => {
    const jsonKeys = Object.keys(json[0]);
    const maxLength = 100;
    const objectMaxLength: any[] = [];
    for (let i = 0; i < json.length; i++) {
        const value = json[i];
        for (let j = 0; j < jsonKeys.length; j++) {
            if (typeof value[jsonKeys[j]] === 'number') {
                objectMaxLength[j] = 10;
            } else if (typeof value[jsonKeys[j]]?.getMonth === 'function') {
                objectMaxLength[j] = 20;
            } else {
                const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length + 1 : 0;

                objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
                objectMaxLength[j] = objectMaxLength[j] >= maxLength ? maxLength : objectMaxLength[j];
            }
        }

        const key = header || jsonKeys;
        for (let j = 0; j < key.length; j++) {
            objectMaxLength[j] = objectMaxLength[j] >= (key[j]?.length || 0) ? objectMaxLength[j] : key[j].length;
        }
    }

    const wscols = objectMaxLength.map((w) => {
        return { width: w };
    });

    // eslint-disable-next-line no-param-reassign
    worksheet['!cols'] = wscols;
};

export const generateAndDownloadReportExportFile = (reportName: string, columns: GridColDef[], data: any): void => {
    const headerNames = [columns.map((c) => c.headerName || '')];
    const wb = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(wb, headerNames);
    const worksheet = XLSX.utils.sheet_add_json(wb, data, {
        skipHeader: true,
        origin: 'A2',
    });

    autoFitColumns(data, worksheet, headerNames[0]);
    XLSX.utils.book_append_sheet(wb, worksheet, 'Report');
    XLSX.writeFile(wb, `${reportName} ${DateTime.now().toFormat('yyyy-MM-dd_HH-mm')}.xlsx`);
};

export const resultPerPageListItems = mapListItems(
    [25, 50, 100],
    (item: number) => item.toString(),
    (item: number) => item.toString()
);
