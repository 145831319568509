import React, { memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PrivateBookingIcon } from 'assets/icons/PrivateBookingIcon.svg';
import ActionButton from 'components/ActionButtons/Models/ActionButton';
import ActionButtonsPanel from 'components/ActionButtonsPanel';
import WithLoading from 'components/HOC/WithLoading';
import HttpErrorMessage from 'components/HttpErrorMessage';
import Header from 'components/SiteSubHeader';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Models/Tab';
import { EditMode } from 'features/constants';
import BookMode from 'features/Resources/Common/Models/BookMode';
import useErrorDismiss from 'hooks/useErrorDismiss';
import { findResourceNameByValue, ResourceType } from 'services/ApiClients/Resource';
import ToastService from 'services/ToastService';

import { Button } from '../../../../../components/FormElements';
import { ErrorState } from '../Redux/reducer/info';

interface EditorProps {
    operationInProgress: boolean;
    areaName?: string;
    hasUpdateError: ErrorState;
    tabs: Tab[];
    bookingInfo: any;
    actionButtons: ActionButton[];
    editMode?: EditMode;
    dismissError: () => void;
    onSubmit: (data: any) => void;
    handleEditModeChanged?: () => void;
}

const Editor = ({
    operationInProgress,
    areaName,
    hasUpdateError,
    tabs,
    bookingInfo,
    actionButtons,
    editMode,
    dismissError,
    onSubmit,
    handleEditModeChanged,
}: EditorProps): JSX.Element => {
    const { t } = useTranslation();
    const methods = useForm();
    const navigate = useNavigate();
    const { resourceType, bookingId, bookMode, repeatSchedule, isPrivate } = bookingInfo;

    useEffect(() => {
        if (hasUpdateError.active && !hasUpdateError.response) {
            ToastService.Error({ title: hasUpdateError.title, message: hasUpdateError.message, onClose: dismissError });
        }
    }, [hasUpdateError]);

    const handleErrorDismiss = (): void => {
        dismissError();
    };

    const errorMessageRef = React.createRef<HTMLDivElement>();
    useErrorDismiss(handleErrorDismiss, errorMessageRef);

    const headerTitle = useMemo(() => {
        switch (editMode) {
            case EditMode.DISABLED_OCCURRENCE:
                return 'BookResourceHeader_Title';
            case EditMode.ENABLE_OCCURRENCE:
                return 'EditSingleOccurence_Title';
            case EditMode.ENABLE_SERIES:
                return 'EditRepeatSeries_Title';
            default:
                return 'BookResourceHeader_Title';
        }
    }, [editMode]);

    const createMuliResourceButton = useMemo(() => {
        return resourceType === ResourceType.ROOM.value && !repeatSchedule && bookMode === BookMode.Create
            ? [
                  <Button
                      className="create-multi-resource-booking-button"
                      dataTestId="create-multi-resource-booking"
                      text={t('MultiResourceBooking_CreateMultiResourceBooking')}
                      onClick={() => {
                          navigate(`/multi-resource-bookings/${bookingId}`);
                      }}
                  />,
              ]
            : [];
    }, [resourceType, repeatSchedule, bookMode]);

    const privateBookingMarker = useMemo(() => {
        return isPrivate
            ? [
                  <div className="private-booking-marker">
                      <PrivateBookingIcon />
                      <span className="private-booking-text">{t('Combined_BookingInfo_PrivateBooking')}</span>
                  </div>,
              ]
            : [];
    }, [isPrivate]);

    const rightSideElements = createMuliResourceButton.concat(privateBookingMarker);

    return (
        <WithLoading isLoading={operationInProgress}>
            <main className="pageBookResource">
                <div className="pageBookReourceContent">
                    <Header
                        title={t(headerTitle, {
                            type: findResourceNameByValue(resourceType).toLowerCase(),
                        })}
                        geographicStructure={areaName}
                        dataTestId="pageBookResouce-title"
                    />
                    <FormProvider {...methods}>
                        <form
                            defaultValue={bookingInfo}
                            onSubmit={methods.handleSubmit(onSubmit)}
                            className="tabs-form-wrapper"
                        >
                            <Tabs
                                tabs={tabs}
                                handleEditModeChanged={handleEditModeChanged}
                                editMode={editMode}
                                rightSideElements={rightSideElements}
                            />
                            <ActionButtonsPanel buttons={actionButtons} disabled={operationInProgress} />
                        </form>
                    </FormProvider>
                    {!operationInProgress && hasUpdateError && hasUpdateError.active && hasUpdateError.response && (
                        <>
                            <HttpErrorMessage
                                response={hasUpdateError.response}
                                onDismiss={handleErrorDismiss}
                                ref={errorMessageRef}
                            />
                        </>
                    )}
                </div>
            </main>
        </WithLoading>
    );
};

export default memo(Editor);
