import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import UserReport from '../../features/Reports/UserReport';

const User = (): JSX.Element => {
    return (
        <MainLayout>
            <UserReport />
        </MainLayout>
    );
};

export default User;
