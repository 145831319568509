import * as constants from 'features/constants';
import { setCombinedDate } from 'features/Filters/Redux/actions';
import {
    getResources,
    setResourcesBookingsLoadStatus,
    updateBookedResourceAsync,
    updateSingleOccurrenceAsync,
} from 'features/Resources/Combined/Redux/actions';
import { Dictionary, groupBy, mapValues } from 'lodash';
import { combineReducers } from 'redux';
import { UnavailableTime } from 'services/ApiClients/Booking/Models';
import { createReducer } from 'typesafe-actions';

import { getResourcesUnavailability, refreshBookingUnavailability } from '../actions';

export interface UnavailabilityState {
    loadStatus: string;
    items: Dictionary<UnavailableTime[]>;
}

export default combineReducers<UnavailabilityState>({
    loadStatus: createReducer<string>(constants.LOAD_STATUSES.LOADED)
        .handleAction(
            [
                getResources.success,
                setCombinedDate,
                setResourcesBookingsLoadStatus,
                updateBookedResourceAsync.success,
                updateSingleOccurrenceAsync.success,
            ],
            () => constants.LOAD_STATUSES.REQUIRED
        )
        .handleAction([getResourcesUnavailability.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([getResourcesUnavailability.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([getResourcesUnavailability.failure], () => constants.LOAD_STATUSES.FAILED),

    items: createReducer<Dictionary<UnavailableTime[]>>({})
        .handleAction([getResourcesUnavailability.success], (_state, action) => action.payload)
        .handleAction([getResourcesUnavailability.failure], () => ({}))

        .handleAction([refreshBookingUnavailability], (state, action) => {
            const newDictionary = groupBy(action.payload.unavailableResources, (x) => x.resourceId);
            const newValues = mapValues(state, (x, resourceId) => {
                const filtered = x.filter((y) => y.bookingId !== action.payload.bookingId);
                if (action.payload.abandoned) {
                    return filtered;
                }
                const newItems = newDictionary[resourceId] || [];
                return [...filtered, ...newItems];
            });

            return newValues;
        }),
});
