import {
    AVAILABLE_ICONS,
    BOOKING_SLOT_UNITS_MULTIPLIER,
    ONE_HOUR_GRID_HEADER_WIDTH,
    TIME_COUNT,
} from 'features/constants';
import { Configuration } from 'services/ApiClients/Configuration';

import { CellFillTypes } from './Models/CellFillTypes';
import { BookingGridResource } from './Models/Resource';

export const findResourceRowById = (mappedResources: BookingGridResource[], selectedRowId: string): any => {
    const resourceRow = mappedResources.reduce((acc, mappedResource) => {
        const item = mappedResource?.children?.reduce((accum, { children }) => {
            const currentRow = children?.find(({ id }) => id === selectedRowId) || {};
            return currentRow ? { ...accum, ...currentRow } : accum;
        }, {});

        return item ? { ...acc, ...item } : acc;
    }, {});

    return resourceRow;
};

export const getCellFillTypeAreas = (type: CellFillTypes | null): { cssClass: string }[] => {
    switch (type) {
        case CellFillTypes.Free:
            return [];
        case CellFillTypes.Unavailable:
            return [{ cssClass: 'unavailable' }];
        case CellFillTypes.SetupCleardown:
        case CellFillTypes.SetupCleardownPreRender:
            return [{ cssClass: 'setup-cleardown' }];
        case CellFillTypes.CheckedIn:
            return [{ cssClass: 'checked-in-cells' }];
        case CellFillTypes.SetupCleardownIntersect:
            return [{ cssClass: 'setup-cleardown-intersect' }];
        default:
            return [];
    }
};

export const checkAvailableIcon = (category: string): string =>
    AVAILABLE_ICONS.includes(category) ? category : 'other';

export const getCellWidthByUnitSlot = (configuration: Configuration): number => {
    return (
        ONE_HOUR_GRID_HEADER_WIDTH /
        (TIME_COUNT.MINUTES_IN_ONE_HOUR / (configuration.bookingSlotUnits * BOOKING_SLOT_UNITS_MULTIPLIER))
    );
};

export enum UpdateMode {
    UPDATE_OCCURRENCE,
    UPDATE_SERIES,
}
