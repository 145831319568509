import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

import { getGeographicStructureAreas } from '../../GeographicStructures/Redux/actions';
import { generateReport, generateReportForExport } from '../Redux/actions';

// eslint-disable-next-line import/no-cycle,
import ResourceUtilisationReport from './ResourceUtilisationReport';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    tenantId: state.authentication.user?.tenantId || '',
    sites: state.referenceData.sites.items,
    siteGroups: state.referenceData.siteGroup.items,
    areas: state.referenceData.area.items,
    areasLoadStatus: state.referenceData.area.loadStatus,
    isReportGenerationInProgress: state.reports.isReportGenerationInProgress,
    reportData: state.reports.reportData?.resourceUtilisations,
    isGenerationReportForExportInProgress: state.reports.isGenerationReportForExportInProgress,
    reportDataForExport: state.reports.reportDataForExport?.resourceUtilisations,
});

const mapDispatchToProps = {
    generateReport: generateReport.request,
    getAreas: getGeographicStructureAreas.request,
    generateReportForExport: generateReportForExport.request,
};

export type ResourceUtilisationReportProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ResourceUtilisationReport);
