import React, { memo, useEffect, useState } from 'react';
import deskImage from 'assets/desk-default-img.png';
import roomImage from 'assets/room-default-img.png';
// eslint-disable-next-line import/no-cycle
import ResourceInfoSlider, { InfoSliderData } from 'components/ResourceInfo/ResourceInfoSlider';
import { DateTime } from 'luxon';
import { SearchBookedResourcesQuery } from 'services/ApiClients/Booking/Models';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import {
    SpecifiedCompanyAttributesQuery,
    SpecifiedCompanyAttributesResponse,
} from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { FloorPin, Resource, ResourcesGS, ResourceType } from 'services/ApiClients/Resource/Models';
import Guid from 'utilities/guid';

export interface ResourceInfoSliderState extends Resource {
    imageUrl: string;
    floorPinStatus?: string;
}
interface ResourceData {
    id: Guid;
    geoStructure: GeographicStructureItem;
    pinInformation: any;
}

interface InfoSliderProps {
    resource: ResourceData;
    resources: ResourcesGS[];
    onClose: () => void;
    title: string;
    infoSliderData: InfoSliderData;
    getResourceImage: (imageId: Guid) => void;
    resetResourceImage: (data: object) => object;
    getUsers: (usersIds: string[]) => void;
    getRoles: (usersIds: string[]) => void;
    getAttributes: (param: SpecifiedCompanyAttributesQuery) => void;
    resetAttributes: (param: []) => void;
    resetUsers: (param: []) => void;
    resetRoles: (param: []) => void;
    isFromFloorPlanPage?: boolean;
    selectedDate?: DateTime;
    timezone?: string;
    getBookingsByResourcesIds?: (params: SearchBookedResourcesQuery) => void;
    area?: GeographicStructureItem;
    resetBookingsByResourcesIds?: (param: []) => void;
    bookingsByResourcesIdsLoadStatus?: string;
    handleBookResource?: (item: FloorPin) => void;
    tenantCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
}

const InfoSlider = (props: InfoSliderProps): JSX.Element => {
    const {
        resource: { id: resourceId, geoStructure, pinInformation } = { id: Guid.Empty, geographicStructure: '' },
        resources,
        onClose,
        title,
        getResourceImage,
        resetResourceImage,
        infoSliderData,
        getUsers,
        getRoles,
        resetUsers,
        resetRoles,
        getAttributes,
        resetAttributes,
        isFromFloorPlanPage,
        selectedDate,
        timezone,
        getBookingsByResourcesIds,
        resetBookingsByResourcesIds,
        area,
        bookingsByResourcesIdsLoadStatus,
        handleBookResource,
        tenantCompanyAttributes,
    } = props;

    const isOpen = !!(resourceId && resourceId !== Guid.Empty);

    const [resourceState, setResourceState] = useState<any>(null);
    const timeoutRef = React.useRef<any>(null);

    useEffect(() => {
        if (resourceId && resourceId !== Guid.Empty) {
            const resourceInformation =
                resources
                    .find((item) => item.geographicStructureId === geoStructure?.id)
                    ?.resources.find(({ id }) => id === resourceId) || null;
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
            }

            setResourceState({
                ...resourceInformation,
                floorPinStatus: pinInformation?.floorPinStatus || null,
                imageUrl: resourceInformation?.resourceType === ResourceType.DESK.value ? deskImage : roomImage,
            });
        } else {
            timeoutRef.current = setTimeout(() => {
                setResourceState(null);
            }, 500);
        }
    }, [resourceId]);

    const handleCreateBooking = (): void => {
        if (handleBookResource) {
            handleBookResource(pinInformation);
        }
    };

    return (
        <ResourceInfoSlider
            isOpen={isOpen}
            isLoading={!resourceState}
            geoStructureName={geoStructure?.name || ''}
            title={title}
            onClose={onClose}
            resource={resourceState}
            getResourceImage={getResourceImage}
            resetResourceImage={resetResourceImage}
            infoSliderData={infoSliderData}
            getUsers={getUsers}
            getRoles={getRoles}
            getAttributes={getAttributes}
            resetAttributes={resetAttributes}
            resetUsers={resetUsers}
            resetRoles={resetRoles}
            isFromFloorPlanPage={isFromFloorPlanPage}
            selectedDate={selectedDate}
            timezone={timezone}
            getBookingsByResourcesIds={getBookingsByResourcesIds}
            resetBookingsByResourcesIds={resetBookingsByResourcesIds}
            area={area}
            bookingsByResourcesIdsLoadStatus={bookingsByResourcesIdsLoadStatus}
            handleCreateBooking={handleCreateBooking}
            createdBy={pinInformation?.bookings[0]?.createdByDisplayName}
            attendeesCount={pinInformation?.bookings[0]?.attendeesCount}
            OBO={pinInformation?.bookings[0]?.onBehalfOf?.displayName}
            tenantCompanyAttributes={tenantCompanyAttributes}
        />
    );
};

export default memo(InfoSlider);
