import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnFilterInterface } from '../../../features/Reports/interfaces';
import { TextBox } from '../../FormElements';
import Button from '../../FormElements/Button';
import WithLoading from '../../HOC/WithLoading';
import RadioButtonSelector from '../../RadioButtonSelector/RadioButtonSelector';

import './ReportFilterPanelCount.scss';

interface ReportFilterPanelCountProps {
    activeFilter: string;
    columnsFilter: ColumnFilterInterface;
    generateReportWithColumnsFilter: () => void;
    isLoading?: boolean;
    updateColumnsFilter: any;
    scrollContainerRef: RefObject<HTMLInputElement>;
    countFieldName?: string;
}

const ReportFilterPanelCount = ({
    activeFilter,
    columnsFilter,
    generateReportWithColumnsFilter,
    isLoading,
    updateColumnsFilter,
    scrollContainerRef,
    countFieldName = '',
}: ReportFilterPanelCountProps): JSX.Element => {
    const { t } = useTranslation();

    const radioGroupItems = [
        {
            value: 'less',
            label: `${t('Reports_Filter_Less')} ${columnsFilter[countFieldName]?.values[0].value}`,
            dataTestId: 'radio-btn',
        },
        {
            value: 'equal',
            label: `${t('Reports_Filter_Equal')} ${columnsFilter[countFieldName]?.values[0].value}`,
            dataTestId: 'radio-btn',
        },
        {
            value: 'more',
            label: `${t('Reports_Filter_More')} ${columnsFilter[countFieldName].values[0].value}`,
            dataTestId: 'radio-btn',
        },
    ];

    const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        updateColumnsFilter([{ ...columnsFilter[countFieldName]?.values[0], option: e.target.value }]);
    };
    const onCountChange = (value: string): void => {
        updateColumnsFilter([{ ...columnsFilter[countFieldName]?.values[0], value: +value }]);
    };

    const isCountValid = (): boolean =>
        columnsFilter[countFieldName]?.values[0].value < 1 || columnsFilter[countFieldName]?.values[0].value > 9999;

    return (
        <WithLoading isLoading={isLoading}>
            <div className="report-filter-container">
                <div className="report-filter-content-scroll" ref={scrollContainerRef}>
                    <div className="report-filter-count-container">
                        <TextBox
                            className="report-filter-count"
                            label={t('Reports_Filter_Value')}
                            value={columnsFilter[countFieldName]?.values[0].value.toString()}
                            onChange={(value: string) => onCountChange(value)}
                            dataTestId="report-filter-count-field"
                            placeholder={t('Reports_Filter_Value')}
                            type="number"
                        />
                        {isCountValid() && <p className="report-filter-count-error">{t('Report_Filter_CountError')}</p>}
                    </div>
                    <RadioButtonSelector
                        required
                        value={columnsFilter[countFieldName]?.values[0].option}
                        items={radioGroupItems}
                        handleChange={onOptionChange}
                        tickView
                    />
                </div>
                <div className="report-filter-actions">
                    <Button
                        text={t('Button_Apply')}
                        type="button"
                        className="apply-btn"
                        dataTestId="apply-btn-btn"
                        disabled={!columnsFilter[activeFilter]?.values.length || isCountValid()}
                        onClick={() => {
                            generateReportWithColumnsFilter();
                        }}
                    />
                </div>
            </div>
        </WithLoading>
    );
};

export default ReportFilterPanelCount;
