import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import deskImage from 'assets/desk-default-img.png';
import roomImage from 'assets/room-default-img.png';
import WithLoading from 'components/HOC/WithLoading';
import PageHeader from 'components/PageHeader';
import { ResourceInfoContent } from 'components/ResourceInfo';
// eslint-disable-next-line import/no-cycle
import { CombinedInfoProps } from 'features/Resources/Common/EditBooking/ResourceInfo';
import { isEmpty } from 'lodash';
import { ResourceType } from 'services/ApiClients/Resource';

import './ResourceInfo.scss';

const ResourceInfo = ({
    bookingResource,
    getUsers,
    users,
    roles,
    companyAttributes,
    userProfileId,
    getRoles,
    cbRoles,
    cbCompanyAttributes,
    resetUsers,
    resetRoles,
    getResourceImage,
    resourceImage,
    resourceImageLoading,
    resetResourceImage,
    getAttributes,
    resetAttributes,
    tenantCompanyAttributes,
    getTenantCompanyAttributes,
}: CombinedInfoProps): JSX.Element => {
    const { t } = useTranslation();
    const { name, resourceType, restrictedTo, resourceImageId } = { ...bookingResource };

    useEffect(() => {
        if (!isEmpty(restrictedTo?.users)) {
            getUsers(restrictedTo?.users);
        }
        if (!isEmpty(restrictedTo?.roles)) {
            getRoles(restrictedTo?.roles);
        }

        if (!isEmpty(restrictedTo?.companyAttributes)) {
            const payload = restrictedTo.companyAttributes.map(({ id: attributeId, values: valueIds }) => ({
                attributeId,
                valueIds,
            }));

            getAttributes({ attributes: payload });
        }
        getTenantCompanyAttributes();
        return () => {
            resetUsers([]);
            resetRoles([]);
            resetAttributes([]);
        };
    }, []);

    useEffect(() => {
        if (resourceImageId) {
            getResourceImage(resourceImageId);
        }
        return () => {
            resetResourceImage({});
        };
    }, []);

    const getImgByResourceType = useMemo(
        () => (resourceType === ResourceType.DESK.value ? resourceImage || deskImage : resourceImage || roomImage),
        [resourceImage]
    );
    return (
        <WithLoading isLoading={resourceImageLoading}>
            <div className="resourceInfo">
                <PageHeader title={name} />
                <ResourceInfoContent
                    resource={{ ...bookingResource, imageUrl: getImgByResourceType }}
                    title={t('Resource_About_Label')}
                    users={users}
                    userProfileId={userProfileId}
                    roles={roles}
                    companyAttributes={companyAttributes}
                    cbRoles={cbRoles}
                    cbCompanyAttributes={cbCompanyAttributes}
                    tenantCompanyAttributes={tenantCompanyAttributes}
                />
            </div>
        </WithLoading>
    );
};

export default memo(ResourceInfo);
