import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import classNames from 'classnames';
import { Button, DropDown } from 'components/FormElements';
import { getSelectedTimeSlot } from 'components/FormElements/DateTimePicker/Components/DateTimeRangeEditor';
import SlidingPanel from 'components/SlidingPanel';
import { DEFAULT_DATE_FORMAT } from 'features/constants';
import { DateTime } from 'luxon';

import { BookableDays } from '../../../../services/ApiClients/Configuration';
import DateTimeService, { TimeSlotOption } from '../../../../services/DateTimeService';
import { getValidBookingWindowStartMinutes } from '../../../../utilities/bookingUtils';

import './MultiDayDueTimePanel.scss';

interface MultiDayDueTimePanelProps {
    onSave: (multiDayTimes: MultiDayTime[]) => void;
    setPanelOpen: Function;
    isOpen: boolean;
    dateFrom: DateTime;
    dateTo: DateTime;
    bookableDays: BookableDays;
    savedMultiDayDueTimes: MultiDayTime[] | null;
    timeSlotInterval: number;
    nowDateTime: DateTime;
    hourFormat: string;
    is24HourFormat?: boolean;
}

interface MultiDayDueDateTimes {
    date: DateTime;
    timeSlots: TimeSlotOption[];
}

export interface MultiDayTime {
    date: DateTime;
    timeSlotValue: string | undefined;
}

const MultiDayDueTimePanel = ({
    onSave,
    setPanelOpen,
    isOpen,
    dateFrom,
    dateTo,
    bookableDays,
    savedMultiDayDueTimes,
    timeSlotInterval,
    nowDateTime,
    hourFormat,
    is24HourFormat,
}: MultiDayDueTimePanelProps): JSX.Element => {
    const { t } = useTranslation();

    const [dueTimes, setDueTimes] = useState<MultiDayTime[]>([]);

    // TODO move to the parent component
    const multiDayDueDateTimeSlots: MultiDayDueDateTimes[] = useMemo(() => {
        const result = [];
        const multiDayDueDateTimes: MultiDayTime[] = [];

        for (
            let date = dateFrom;
            date.toFormat(DEFAULT_DATE_FORMAT) <= dateTo.toFormat(DEFAULT_DATE_FORMAT);
            date = date.plus({ days: 1 })
        ) {
            const weekDay = date.toFormat('cccc').toLowerCase();
            if (bookableDays[weekDay]) {
                const startTimeInMinutes = getValidBookingWindowStartMinutes(
                    nowDateTime,
                    date,
                    timeSlotInterval,
                    bookableDays[weekDay].bookingWindowStartMinutesFromMidnight
                );

                const timeSlots: TimeSlotOption[] = DateTimeService.getTimeSlots(
                    Math.floor(startTimeInMinutes / timeSlotInterval),
                    Math.ceil(
                        (bookableDays[weekDay].bookingWindowEndMinutesFromMidnight - timeSlotInterval) /
                            timeSlotInterval
                    ),
                    timeSlotInterval,
                    hourFormat
                );

                const selectedTimeSlot = getSelectedTimeSlot(timeSlots, dateFrom, timeSlotInterval);

                const dateTimeSlots = { date, timeSlots } as unknown as MultiDayDueDateTimes;
                result.push(dateTimeSlots);
                const multiDayDueDateTime = {
                    date,
                    timeSlotValue: selectedTimeSlot?.value.toString(),
                };
                multiDayDueDateTimes.push(multiDayDueDateTime);
            }
        }

        setDueTimes(multiDayDueDateTimes.map((i) => ({ ...i })));
        return result;
    }, [dateTo, dateFrom, bookableDays]);

    useEffect(() => {
        if (JSON.stringify(dueTimes) !== JSON.stringify(savedMultiDayDueTimes)) {
            onSave(dueTimes.map((i) => ({ ...i })));
        }
    }, [dateTo, dateFrom, bookableDays]);

    useEffect(() => {
        if (savedMultiDayDueTimes) {
            setDueTimes(savedMultiDayDueTimes.map((i) => ({ ...i })));
        }
    }, [isOpen]);

    const handleTimeSlotChange = (slotValue: string, index: number, date: DateTime): void => {
        dueTimes[index].timeSlotValue = slotValue;
        dueTimes[index].date = DateTimeService.getDateFromTimeSlot(date, Number(slotValue), timeSlotInterval);
        setDueTimes([...dueTimes]);
    };

    return (
        <SlidingPanel
            title={t('VisitorBooking_EditMultiDay')}
            className="multi-day-time-panel"
            open={isOpen}
            onClose={() => {
                setPanelOpen(false);
            }}
            showBackgroundMask
        >
            <div className="header-labels">
                <div className="label-date">{t('VisitorBooking_Date')}</div>
                <div className="label-due">{t('VisitorBooking_DueTime')}</div>
            </div>
            <div className="due-times">
                <div className="date-time-slots">
                    {multiDayDueDateTimeSlots.map((i, index) => {
                        return (
                            <div key={i.date.toFormat('d MMM yy')} className="date-time-slots-row">
                                <div className="date">{i.date.toFormat('d MMM yy')}</div>
                                <div className={classNames('time-slots', { isExtended: !is24HourFormat })}>
                                    <DropDown
                                        value={dueTimes?.[index]?.timeSlotValue || ''}
                                        onChange={(value) => {
                                            handleTimeSlotChange(value, index, i.date);
                                        }}
                                        paperProps={{ large: true }}
                                        dropDownClassName="multi-day-time-dropdown"
                                        dataTestId={`edit_Multi_Day_Time_DropDown_${index}`}
                                    >
                                        {i.timeSlots.map((timeSlot) => (
                                            <MenuItem key={timeSlot.value} value={timeSlot.value.toString()}>
                                                {timeSlot.label}
                                            </MenuItem>
                                        ))}
                                    </DropDown>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="action-buttons ">
                <Button
                    text={t('Button_Save')}
                    type="submit"
                    className="save-btn"
                    dataTestId="save-btn"
                    onClick={() => {
                        onSave(dueTimes);
                        setPanelOpen(false);
                    }}
                />
            </div>
        </SlidingPanel>
    );
};

export default MultiDayDueTimePanel;
