import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

export interface NavigationLinkProps {
    to: string;
    icon: FunctionComponent;
    label: string;
    title?: string;
    end?: boolean;
}

const NavigationLink = ({ to, icon: Icon, label, title, end }: NavigationLinkProps): JSX.Element => {
    return (
        <NavLink end={end} to={to} className={classnames('navigationLink')} title={title}>
            <Icon />
            <span>{label}</span>
        </NavLink>
    );
};

export default NavigationLink;
