import * as constants from 'features/constants';
import { combineReducers } from 'redux';
import { SearchResponse } from 'services/ApiClients/Search/Models/SearchResponse';
import { createReducer } from 'typesafe-actions';

import {
    floorPlanSearchLoadStatus,
    getFloorPlanSearchData,
    resetFloorPlanSearchData,
    setSearchResultItem,
    setSearchTrigger,
} from './actions';

export interface FloorPlanState {
    searchResult: SearchResponse[] | null;
    searchResultLoadStatus: string;
    searchResultItem: SearchResponse | null;
    searchTrigger: boolean;
}

export default combineReducers<FloorPlanState>({
    searchResult: createReducer<SearchResponse[] | null>(null)
        .handleAction(
            [getFloorPlanSearchData.success],
            (state, action) => action.payload.payload as unknown as SearchResponse[]
        )
        .handleAction([getFloorPlanSearchData.failure], () => [])
        .handleAction([getFloorPlanSearchData.request, resetFloorPlanSearchData], () => null),

    searchResultItem: createReducer<SearchResponse | null>(null)
        .handleAction([setSearchResultItem], (state, action) => action.payload as SearchResponse)
        .handleAction([resetFloorPlanSearchData], () => null),

    searchResultLoadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([floorPlanSearchLoadStatus], () => constants.LOAD_STATUSES.REQUIRED)
        .handleAction([getFloorPlanSearchData.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([getFloorPlanSearchData.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([getFloorPlanSearchData.failure], () => constants.LOAD_STATUSES.FAILED),

    searchTrigger: createReducer<boolean>(false).handleAction([setSearchTrigger], (state, action) => action.payload),
});
