import React from 'react';
import deskInfoIcons from 'assets/icons/deskInfoPanel';
import { getSummary } from 'components/BookingGrid/Mappers/resourceSummaryMappers';
// eslint-disable-next-line import/no-cycle
import { ResourceInfoSliderState } from 'features/Resources/Common/InfoSlider/InfoSlider';

import './SummaryIcons.scss';

export interface SummaryIconsProps {
    resource: ResourceInfoSliderState;
}

const getIcon = (icon: string): string => (deskInfoIcons as any)[icon] || deskInfoIcons.otherIcon;

const SummaryIcons = ({ resource }: SummaryIconsProps): JSX.Element => {
    const summaryIcons = getSummary(resource);

    return (
        <div className="summaryIcons">
            {summaryIcons.map((summaryIcon) => (
                <div className="summaryIcon" key={`${summaryIcon.icon}-${summaryIcon.name}`}>
                    <img src={getIcon(`${summaryIcon.icon}Icon`)} alt={summaryIcon.name} />
                    <span>{summaryIcon.name}</span>
                </div>
            ))}
        </div>
    );
};

export default SummaryIcons;
