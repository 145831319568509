import React, { memo, SyntheticEvent, useMemo, useState } from 'react';
import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as FilterTickIcon } from 'assets/icons/FilterTickIcon.svg';
import classNames from 'classnames';
import { CustomFilter } from 'services/ApiClients/OrganisationStructure';

interface MultipleFilterProps {
    filters: { [k: string]: CustomFilter };
    onChange: (param: { [k: string]: CustomFilter }) => void;
    title: string;
}

const MultipleFilter = ({ filters, onChange, title }: MultipleFilterProps): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const isSomeChecked = useMemo(() => Object.keys(filters).some((id) => filters[id].checked), [filters]);

    const handleSelectFilter = (event: SyntheticEvent): void => {
        const element = event.target as HTMLInputElement;
        const selectedId = element.value;
        onChange({
            ...filters,
            [selectedId]: { ...filters[selectedId], checked: !filters[selectedId].checked },
        });
    };

    const handleOpen = (): void => {
        setOpen(!isOpen);
    };

    return (
        <>
            <div className="filter" onClick={handleOpen} role="button" onKeyPress={handleOpen} tabIndex={-1}>
                <div className="multipleFilters">
                    <span className="title">{title}</span>
                    {isSomeChecked && <FilterTickIcon />}
                </div>
                <ArrowDown className={classNames('arrowIcon', { isOpen })} />
            </div>
            {isOpen &&
                Object.keys(filters).map((id: string) => {
                    const { checked, name } = filters[id];
                    return (
                        <div className="option" key={id}>
                            <input type="checkbox" checked={checked} onChange={handleSelectFilter} value={id} />
                            <span
                                className={classNames('optionName', {
                                    checked,
                                })}
                            >
                                {name}
                            </span>
                        </div>
                    );
                })}
        </>
    );
};

export default memo(MultipleFilter);
