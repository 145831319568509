import React from 'react';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import i18n from 'i18next';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

import ReportFilterColumnHeader from '../../../components/ReportsFilterColumnHeader/ReportFilterColumnHeader';
import { AllListItemOption, DEFAULT_DATE_FORMAT } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface } from '../interfaces';
import { dateFormatter, timeFormatterWithTimeFormat } from '../utils';

const items = {
    id: true,
    userProfileId: true,
    loginDateTime: true,
    loginTime: true,
    deviceLoggedIn: true,
    userProfile: { name: true, email: true, isSso: true, concatenatedName: true },
};

export const getColumns = (
    timeFormat: string,
    handleFilterClick: (field: string, headerName: string) => void,
    sideBarFilter: ColumnFilterInterface,
    activeSideBarFilter: ActiveColumnFilterInterface,
    is24HoursFormat?: boolean
): GridColDef[] => [
    {
        field: 'name',
        headerName: i18n.t('Reports_Name'),
        flex: 0.2,
        valueGetter: (params) => params.row.userProfile?.concatenatedName,
    },
    {
        field: 'email',
        headerName: i18n.t('Reports_Email'),
        flex: 0.2,
        valueGetter: (params) => params.row.userProfile?.email,
    },
    {
        field: 'loginDateTime',
        headerName: `${i18n.t('Reports_LoginDate')} (UTC)`,
        flex: 0.1,
        minWidth: 75,
        valueGetter: (params) => dateFormatter(params.row.loginDateTime, params.row.timezone),
    },
    {
        field: 'loginTime',
        headerName: `${i18n.t('Reports_LoginTime')} (UTC)`,
        flex: 0.1,
        minWidth: 75,
        valueGetter: (params) =>
            timeFormatterWithTimeFormat(params.row.loginDateTime, is24HoursFormat, params.row.timezone),
    },
    {
        field: 'isSso',
        headerName: i18n.t('Reports_SsoLogin'),
        flex: 0.1,
        cellClassName: 'capitalize',
        valueGetter: (params) => params.row.userProfile?.isSso,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.isSso.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_SsoLogin')}</p>
            ),
    },
    {
        field: 'deviceLoggedIn',
        headerName: i18n.t('Reports_LoggedInTo'),
        cellClassName: 'capitalize',
        flex: 0.2,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.deviceLoggedIn.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_LoggedInTo')}</p>
            ),
    },
];

export const buildQuery = (
    itemsPerPageAmount: number,
    activePage: number,
    sortOptions: any,
    reportsFilter: any,
    activeSideBarFilter?: ActiveColumnFilterInterface,
    isForExport = false,
    totalCount = 0,
    visibleColumns?: string[]
): string => {
    const graphQLItems: { [key: string]: any } = { ...items };
    if (visibleColumns) {
        Object.keys(graphQLItems).forEach((key: string) => {
            if (!visibleColumns.includes(key)) {
                delete graphQLItems[key];
            }
        });
    }
    const query = {
        logins: {
            __args: {
                skip: isForExport ? 0 : itemsPerPageAmount * (activePage - 1),
                take: isForExport ? totalCount : itemsPerPageAmount,
                order: {},
                where: {},
                from: reportsFilter.dateRange.from.toFormat(DEFAULT_DATE_FORMAT),
                to: reportsFilter.dateRange.to.toFormat(DEFAULT_DATE_FORMAT),
            },
            items: graphQLItems,
            totalCount: true,
        },
    };

    if (sortOptions?.length) {
        const sortDirection = new EnumType(sortOptions[0].sort.toUpperCase());
        switch (sortOptions[0].field) {
            case 'name':
                query.logins.__args.order = {
                    ...query.logins.__args.order,
                    userProfile: { surname: sortDirection, firstname: sortDirection },
                };
                break;
            case 'email':
                query.logins.__args.order = {
                    ...query.logins.__args.order,
                    userProfile: { email: sortDirection },
                };
                break;
            case 'isSso':
                query.logins.__args.order = {
                    ...query.logins.__args.order,
                    userProfile: { isSso: sortDirection },
                };
                break;
            default:
                query.logins.__args.order = {
                    [sortOptions[0].field]: new EnumType(sortOptions[0].sort.toUpperCase()),
                    ...query.logins.__args.order,
                };
        }
    }

    let where = {};

    if (reportsFilter?.user !== AllListItemOption.label) {
        where = {
            ...where,
            userProfileId: { eq: `${reportsFilter.user.id}` },
        };
    }

    if (activeSideBarFilter?.deviceLoggedIn?.values) {
        where = {
            ...where,
            deviceLoggedIn: { in: activeSideBarFilter.deviceLoggedIn.values },
        };
    }

    if (activeSideBarFilter?.isSso?.values && activeSideBarFilter?.isSso?.values.length === 1) {
        where = {
            ...where,
            userProfile: {
                isSso: { eq: Boolean(+activeSideBarFilter.isSso.values[0]) },
            },
        };
    }

    if (Object.keys(where).length === 0) {
        delete query.logins.__args.where;
    } else {
        query.logins.__args.where = where;
    }

    return jsonToGraphQLQuery(query);
};
