import React from 'react';
import { createPrimaryButton, createSecondaryButton } from 'components/ActionButtons/Models/ActionButton';
import Dialog from 'components/Dialog';
import i18n from 'i18n';

export interface PromptDialogProps {
    message: string;
    descriptionMessage?: string;
    showDialog: boolean;
    confirmNavigation: () => void;
    cancelNavigation: () => void;
    cancelMessage?: string;
    confrimMessage?: string;
}

const PromptDialog = ({
    message,
    descriptionMessage,
    showDialog,
    confirmNavigation,
    cancelNavigation,
    cancelMessage,
    confrimMessage,
}: PromptDialogProps): JSX.Element | null => {
    const dialogButtons = [
        createSecondaryButton(
            'no',
            cancelMessage || i18n.t('Button_No'),
            cancelNavigation,
            false,
            undefined,
            'no-dialogButton'
        ),
        createPrimaryButton(
            'yes',
            confrimMessage || i18n.t('Button_Yes'),
            confirmNavigation,
            false,
            undefined,
            'yes-dialogButton'
        ),
    ];
    return showDialog ? <Dialog message={message} buttons={dialogButtons} description={descriptionMessage} /> : null;
};

export default PromptDialog;
