import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { ListItem, Select } from 'components/Forms';
import { isEqual } from 'lodash';

import ActionButtonsPanel from '../../../components/ActionButtonsPanel';
import DataTable from '../../../components/DataTable';
import Pagination from '../../../components/DataTable/Pagination';
import Button from '../../../components/FormElements/Button';
import EditColumnsForm from '../../../components/Forms/EditColumnsForm';
import WithLoading from '../../../components/HOC/WithLoading';
import ReportFilterPanel from '../../../components/ReportFilterPanel/ReportFilterPanel';
import Header from '../../../components/SiteSubHeader';
import SlidingPanel from '../../../components/SlidingPanel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { ResourceType, UnavailableReportCategoryEnum } from '../../../services/ApiClients/Resource';
import { AllListItemOption, LOAD_STATUSES } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface, GridSettings, ReportsActiveColumn } from '../interfaces';
import {
    capitalizeFirstLetter,
    checkEnabling,
    customReportSelectStyles,
    filterArrayByAll,
    generateAndDownloadReportExportFile,
    getAreaListItemsOfSelectedSite,
    getDefaultColumnsValues,
    getSiteGroupListItems,
    getSiteListItems,
    resultPerPageListItems,
} from '../utils';

// eslint-disable-next-line import/no-cycle
import { ResourceReportProps } from './index';
import {
    buildQuery,
    formatDateRanges,
    getColumns,
    itemSeparator,
    UnavailableReportCategoryEnumForView,
} from './resourseReportUtils';

import './ResourceReport.scss';

const ResourceReport = ({
    isReportGenerationInProgress,
    generateReport,
    reportData,
    getAreas,
    tenantId,
    siteGroups,
    sites,
    areas,
    areasLoadStatus,
    isGenerationReportForExportInProgress,
    reportDataForExport,
    generateReportForExport,
}: ResourceReportProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [gridSettings, setGridSettings] = useState<GridSettings>({
        page: '1',
        resultPerPage: resultPerPageListItems[2],
        sortOptions: [{ field: 'resourceType', sort: 'desc' }],
    });

    const defaultFilter = {
        selectedSiteGroup: AllListItemOption,
        selectedSite: AllListItemOption,
        selectedArea: AllListItemOption,
        selectedResourceType: AllListItemOption,
    };

    const [appliedReportsFilter, setAppliedReportsFilter] = useState<any>(location.state || defaultFilter);
    const [reportsFilter, setReportsFilter] = useState<any>(location.state || defaultFilter);

    const siteGroupListItems = useMemo(() => getSiteGroupListItems(siteGroups[tenantId], sites), [siteGroups]);

    const siteListItems = useMemo(
        () => getSiteListItems(sites, reportsFilter.selectedSiteGroup),
        [sites, reportsFilter.selectedSiteGroup]
    );

    const areaListItems = useMemo(
        () => getAreaListItemsOfSelectedSite(areas, reportsFilter.selectedSite),
        [areas, reportsFilter.selectedSite]
    );

    const resourceTypeListItems = [
        AllListItemOption,
        { label: ResourceType.DESK.name, value: ResourceType.DESK.value.toString() },
        { label: ResourceType.ROOM.name, value: ResourceType.ROOM.value.toString() },
    ];

    const unavailableCategoryItems = [
        { value: UnavailableReportCategoryEnum.EarlyCheckIn, label: 'Early check in' },
        { value: UnavailableReportCategoryEnum.Covid, label: 'Covid' },
        { value: UnavailableReportCategoryEnum.Cleaning, label: 'Cleaning' },
        { value: UnavailableReportCategoryEnum.Setup, label: 'Setup' },
        { value: UnavailableReportCategoryEnum.Cleardown, label: 'Clear down' },
    ].sort((a, b) => a.label.localeCompare(b.label));

    const [isFiltersOpened, setIsFiltersOpened] = useState(false);
    const [columnsFilter, setColumnsFilter] = useState<ColumnFilterInterface>({});
    const [activeColumnsFilter, setActiveColumnsFilter] = useState<ActiveColumnFilterInterface>({});
    const [addColumnsFilterAfterApply, setAddColumnsFilterAfterApply] = useState<boolean>(false);

    const [activeFilter, setActiveFilter] = useState('');
    const [activeFilterHeaderName, setActiveFilterHeaderName] = useState('');
    const [activeColumnsFilterArray, setActiveColumnsFilterArray] = useState<any[]>([]);

    const defaultColumnsFilter: ColumnFilterInterface = {
        resourceType: {
            values: [...getDefaultColumnsValues(resourceTypeListItems)],
            enabled: checkEnabling(resourceTypeListItems, reportsFilter.selectedResourceType.value),
        },
        siteGroupName: {
            values: [...getDefaultColumnsValues(siteGroupListItems)],
            enabled: checkEnabling(siteGroupListItems, reportsFilter.selectedSiteGroup.value),
        },
        siteName: {
            values: [...getDefaultColumnsValues(siteListItems)],
            enabled: checkEnabling(siteListItems, reportsFilter.selectedSite.value),
        },
        areaName: {
            values: [...getDefaultColumnsValues(areaListItems)],
            enabled: checkEnabling(areaListItems, reportsFilter.selectedArea.value, addColumnsFilterAfterApply),
        },
        enabled: { values: ['0', '1'], enabled: true },
        unavailableUntilFurtherNotice: { values: ['0', '1'], enabled: true },
        unavailableUntilFurtherNoticeCategory: {
            values: [...Object.values(UnavailableReportCategoryEnum)],
            enabled: true,
        },
    };

    useEffect(() => {
        if (reportsFilter.selectedSite.label !== 'All') {
            setAddColumnsFilterAfterApply(false);
        } else {
            setAddColumnsFilterAfterApply(true);
        }
    }, [reportsFilter.selectedSite]);

    useEffect(() => {
        setColumnsFilter({
            ...columnsFilter,
            ...defaultColumnsFilter,
        });
    }, [siteListItems, areaListItems, siteGroupListItems, reportsFilter, addColumnsFilterAfterApply]);

    const updateColumnsFilter = (columnName: string, values: any[]): void => {
        setColumnsFilter({
            ...columnsFilter,
            [columnName]: {
                ...columnsFilter[columnName],
                values,
            },
        });
    };

    const handleFilterClick = (columnName: string, headerName: string): void => {
        setIsFiltersOpened(true);
        setActiveFilter(columnName);
        setActiveFilterHeaderName(headerName);

        if (columnName in activeColumnsFilter) {
            updateColumnsFilter(columnName, [...activeColumnsFilter[columnName].values]);
        } else {
            updateColumnsFilter(columnName, [...defaultColumnsFilter[columnName].values]);
        }
    };

    const renderSideBar = (): void => {
        switch (activeFilter) {
            case 'resourceType':
                setActiveColumnsFilterArray(filterArrayByAll(resourceTypeListItems));
                break;
            case 'siteGroupName':
                setActiveColumnsFilterArray(filterArrayByAll(siteGroupListItems));
                break;
            case 'siteName':
                setActiveColumnsFilterArray(filterArrayByAll(siteListItems));
                break;
            case 'areaName':
                setActiveColumnsFilterArray(filterArrayByAll(areaListItems));
                break;
            case 'enabled':
                setActiveColumnsFilterArray([
                    { value: '0', label: 'False' },
                    { value: '1', label: 'True' },
                ]);
                break;
            case 'unavailableUntilFurtherNotice':
                setActiveColumnsFilterArray([
                    { value: '0', label: 'False' },
                    { value: '1', label: 'True' },
                ]);
                break;
            case 'unavailableUntilFurtherNoticeCategory':
                setActiveColumnsFilterArray(unavailableCategoryItems);
                break;
            default:
                setActiveColumnsFilterArray([]);
        }
    };

    useEffect(() => {
        if (activeFilter) {
            renderSideBar();
        }
    }, [activeFilter, siteListItems, areaListItems, siteGroupListItems]);

    const generateReportWithColumnsFilter = (): void => {
        setIsFiltersOpened(!isFiltersOpened);
        const newActiveColumnFilter = {
            ...activeColumnsFilter,
            [activeFilter]: { values: columnsFilter[activeFilter].values },
        };
        setActiveColumnsFilter(newActiveColumnFilter);
        generateReport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                newActiveColumnFilter
            )
        );
    };

    const loadPage = (gridState: GridSettings): void => {
        generateReport(
            buildQuery(
                Number(gridState.resultPerPage.value),
                Number(gridState.page),
                gridState.sortOptions,
                appliedReportsFilter,
                activeColumnsFilter
            )
        );
    };

    const applyFilterAndGenerateReport = useCallback(() => {
        setAppliedReportsFilter(reportsFilter);
        setGridSettings({ ...gridSettings, page: '1' });
        generateReport(
            buildQuery(Number(gridSettings.resultPerPage.value), 1, gridSettings.sortOptions, reportsFilter)
        );
        setAddColumnsFilterAfterApply(true);
    }, [reportsFilter, gridSettings, addColumnsFilterAfterApply]);

    const setGridStateAndLoadPage = (newGridState: GridSettings): void => {
        setGridSettings(newGridState);
        loadPage(newGridState);
    };

    useEffect(() => {
        loadPage(gridSettings);
    }, []);

    const defaultActiveColumns = getColumns(
        !!siteGroups[tenantId],
        handleFilterClick,
        columnsFilter,
        activeColumnsFilter
    ).map((item) => ({
        name: item.headerName,
        active: true,
    }));

    const [isEditColumnsPanelOpen, setEditColumnsPanelOpen] = useState<boolean>(false);
    const [activeColumns, setActiveColumns] = useState<ReportsActiveColumn[]>(defaultActiveColumns);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    const applyNewActiveColumns = (newColumns: ReportsActiveColumn[]): void => {
        setActiveColumns(newColumns);
    };

    const generateReportForExportCallBack = useCallback(() => {
        generateReportForExport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                activeColumnsFilter,
                true,
                reportData?.totalCount
            )
        );
    }, [gridSettings, reportData, gridSettings, columns]);

    const formatReportForExport = (report?: any[]): any => {
        if (!report) {
            return [];
        }
        const visibleColumns = columns.map((c) => c.field);

        const formattedReport = report.map((i) => {
            return {
                ...(visibleColumns.includes('name') && { name: i.name }),
                ...(visibleColumns.includes('resourceType') && { resourceType: capitalizeFirstLetter(i.resourceType) }),
                ...(visibleColumns.includes('description') && { description: i.description }),
                ...(!!siteGroups[tenantId] &&
                    visibleColumns.includes('siteGroupName') && { siteGroup: i.site?.siteGroup?.name }),
                ...(visibleColumns.includes('siteName') && { siteName: i.site?.name }),
                ...(visibleColumns.includes('areaName') && { areaName: i.area?.name }),
                ...(visibleColumns.includes('enabled') && { enabled: capitalizeFirstLetter(i.enabled?.toString()) }),
                ...(visibleColumns.includes('unavailableUntilFurtherNotice') && {
                    unavailableUntilFurtherNotice: capitalizeFirstLetter(i.unavailableUntilFurtherNotice?.toString()),
                }),
                ...(visibleColumns.includes('unavailableDates') && {
                    unavailableDates: formatDateRanges(i.unavailableDates),
                }),
                ...(visibleColumns.includes('unavailableUntilFurtherNoticeCategory') && {
                    unavailableUntilFurtherNoticeCategory:
                        UnavailableReportCategoryEnumForView[
                            i.unavailableUntilFurtherNoticeCategory as keyof typeof UnavailableReportCategoryEnumForView
                        ],
                }),
                ...(visibleColumns.includes('fixedEquipment') && {
                    fixedEquipment: i.equipment?.map((item: any) => item.name).join(itemSeparator),
                }),
                ...(visibleColumns.includes('restrictions') && {
                    restrictions: `${i.resourceRestrictedUsers
                        ?.map((item: any) => `${item.concatenatedName}; `)
                        .join('')}${i.resourceRestrictedRoles?.map((item: any) => `${item.name}; `).join('')}`,
                }),
                ...(visibleColumns.includes('isSetupCleardownEnabled') && {
                    isSetupCleardownEnabled: capitalizeFirstLetter(i.isSetupCleardownEnabled?.toString()),
                }),
                ...(visibleColumns.includes('setupTimeInMin') && { setupTimeInMin: i.setupTimeInMin }),
                ...(visibleColumns.includes('cleardownTimeInMin') && {
                    cleardownTimeInMin: i.cleardownTimeInMin,
                }),
                ...(visibleColumns.includes('disabledAccess') && {
                    disabledAccess: capitalizeFirstLetter(i.disabledAccess?.toString()),
                }),
                ...(visibleColumns.includes('capacity') && { capacity: i.capacity }),
            };
        });

        return formattedReport;
    };

    useDidMountEffect(() => {
        if (!isGenerationReportForExportInProgress) {
            const formattedData = formatReportForExport(reportDataForExport?.items);
            generateAndDownloadReportExportFile('Resources Report', columns, formattedData);
        }
    }, [isGenerationReportForExportInProgress]);

    useEffect(() => {
        const gridColumns = getColumns(!!siteGroups[tenantId], handleFilterClick, columnsFilter, activeColumnsFilter);
        const filteredColumns = gridColumns.filter(
            (item) => activeColumns.find((e) => e.name === item.headerName)?.active
        );
        setColumns(filteredColumns);
    }, [activeColumns, columnsFilter, activeColumnsFilter]);

    return (
        <main className="resources-report">
            <WithLoading
                isLoading={
                    areasLoadStatus === LOAD_STATUSES.LOADING ||
                    isReportGenerationInProgress ||
                    isGenerationReportForExportInProgress
                }
            >
                <Header
                    title={t('ResourcesReport_Title')}
                    dataTestId="resources-report-title"
                    className="resources-report-title"
                    rightSideItems={[
                        <Button
                            dataTestId="edit-columns-btn"
                            text={t('Reports_EditColumns')}
                            className="edit-btn"
                            onClick={() => {
                                setEditColumnsPanelOpen(true);
                            }}
                        />,
                    ]}
                />

                <div className="filter-options">
                    {siteGroups[tenantId] && (
                        <Select
                            label={t('Reports_SiteGroups')}
                            name="siteGroup"
                            items={siteGroupListItems}
                            className="site-select"
                            styles={customReportSelectStyles}
                            onChange={(selectedSiteGroup) => {
                                setReportsFilter({
                                    ...reportsFilter,
                                    selectedSiteGroup,
                                    selectedSite: AllListItemOption,
                                });
                                setActiveColumnsFilter({});
                            }}
                            value={reportsFilter.selectedSiteGroup}
                        />
                    )}

                    <Select
                        label={t('Reports_Sites')}
                        name="site"
                        items={siteListItems}
                        styles={customReportSelectStyles}
                        onChange={(selectedSite) => {
                            if (selectedSite?.value !== AllListItemOption.value) {
                                getAreas(selectedSite.value);
                            }
                            setReportsFilter({ ...reportsFilter, selectedSite, selectedArea: AllListItemOption });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedSite}
                    />

                    <Select
                        label={t('Reports_Area')}
                        name="area"
                        items={areaListItems}
                        styles={customReportSelectStyles}
                        isDisabled={reportsFilter.selectedSite?.value === AllListItemOption.value}
                        onChange={(selectedArea) => {
                            setReportsFilter({ ...reportsFilter, selectedArea });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedArea}
                    />

                    <Select
                        label={t('Reports_ResourceType')}
                        name="area"
                        items={resourceTypeListItems}
                        styles={customReportSelectStyles}
                        onChange={(selectedResourceType) => {
                            setReportsFilter({ ...reportsFilter, selectedResourceType });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedResourceType}
                    />

                    <Button
                        text={t('Button_Apply')}
                        type="submit"
                        className="apply-btn"
                        dataTestId="apply-btn-btn"
                        disabled={isEqual(reportsFilter, appliedReportsFilter)}
                        onClick={applyFilterAndGenerateReport}
                    />
                </div>

                <DataTable
                    columns={columns}
                    rowsData={(activeColumns.some((column) => column.active) && reportData?.items) || []}
                    onSort={(sortOptions: any) => setGridStateAndLoadPage({ ...gridSettings, sortOptions })}
                    sortModel={gridSettings.sortOptions}
                    page={gridSettings.page}
                />

                <ActionButtonsPanel
                    leftSideElement={
                        <Pagination
                            pageString={gridSettings.page}
                            total={reportData?.totalCount || 0}
                            onPageChange={(page: string) => setGridStateAndLoadPage({ ...gridSettings, page })}
                            onPerPageChange={(perPage: ListItem) =>
                                setGridStateAndLoadPage({ ...gridSettings, resultPerPage: perPage, page: '1' })
                            }
                            resultPerPageOptions={resultPerPageListItems}
                            selectedResultPerPage={gridSettings.resultPerPage}
                        />
                    }
                >
                    <>
                        <Button
                            text={t('Button_Back')}
                            className="back-btn"
                            dataTestId="back-btn"
                            onClick={() => navigate(`/reports`)}
                        />
                        <Button
                            primary
                            text={t('Button_ExportReport')}
                            className="export-btn"
                            dataTestId="export-btn"
                            onClick={() => generateReportForExportCallBack()}
                            disabled={reportData?.items?.length === 0}
                        />
                    </>
                </ActionButtonsPanel>

                <SlidingPanel
                    title={t('Reports_CustomColumns')}
                    open={isEditColumnsPanelOpen}
                    showBackgroundMask
                    className="sliding-panel"
                    onClose={() => {
                        setEditColumnsPanelOpen(false);
                    }}
                >
                    <EditColumnsForm
                        activeColumns={activeColumns}
                        applyNewActiveColumns={applyNewActiveColumns}
                        isEditColumnsPanelOpen={isEditColumnsPanelOpen}
                        setEditColumnsPanelOpen={setEditColumnsPanelOpen}
                    />
                </SlidingPanel>

                <ReportFilterPanel
                    isFiltersOpened={isFiltersOpened}
                    activeFilterHeaderName={activeFilterHeaderName}
                    setIsFiltersOpened={setIsFiltersOpened}
                    activeFilter={activeFilter}
                    activeColumnsFilterArray={activeColumnsFilterArray}
                    setColumnsFilter={setColumnsFilter}
                    columnsFilter={columnsFilter}
                    generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                />
            </WithLoading>
        </main>
    );
};

export default ResourceReport;
