import { LANGUAGE, LOAD_STATUSES } from 'features/constants';
import {
    getGeographicStructureAllSites,
    getGeographicStructureAreas,
    getGeographicStructureSiteGroups,
    getGeographicStructureSites,
} from 'features/GeographicStructures/Redux/actions';
import { getLanguages } from 'features/Languages/Redux/actions';
import { getResources, setResourcesLoadStatus } from 'features/Resources/Combined/Redux/actions';
import { combineReducers } from 'redux';
import { Language, Languages } from 'services/ApiClients/Language/Models';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { Resource } from 'services/ApiClients/Resource/Models';
import { createReducer } from 'typesafe-actions';

import { TenantInfo } from '../../../services/ApiClients/Organization/Models/TenantInfo';

import {
    getTenantInfo,
    loadReferenceDataBegin,
    loadReferenceDataEnd,
    setReferenceDataAreaLoadStatus,
    setReferenceDataLoadStatus,
} from './actions';

interface GeographicStructureState {
    loadStatus: string;
    items: { [parentId: string]: GeographicStructureItem[] };
}

export interface ResourcesState {
    loadStatus: string;
    items: { [parenttId: string]: Resource[] };
}

interface SitesState {
    loadStatus: string;
    items: GeographicStructureItem[];
}

interface State {
    loadStatus: string;
    languages: Language[];
    site: GeographicStructureState;
    siteGroup: GeographicStructureState;
    area: GeographicStructureState;
    combinedResource: ResourcesState;
    sites: SitesState;
    tenantInfo: TenantInfo | null;
}

export default combineReducers<State>({
    loadStatus: createReducer<string>(LOAD_STATUSES.REQUIRED)
        .handleAction([loadReferenceDataBegin], () => LOAD_STATUSES.LOADING)
        .handleAction([loadReferenceDataEnd], () => LOAD_STATUSES.LOADED)
        .handleAction([setReferenceDataLoadStatus], (state, action) => action.payload),

    languages: createReducer<Language[]>([]).handleAction([getLanguages.success], (state, action) => {
        const availableLanguages = action.payload.payload as Languages;
        if (availableLanguages.languages.length) {
            return availableLanguages.languages;
        }
        return LANGUAGE.FALLBACK_OPTIONS;
    }),

    tenantInfo: createReducer<TenantInfo | null>(null).handleAction([getTenantInfo.success], (state, action) => {
        return action.payload.payload as TenantInfo;
    }),

    sites: createReducer<SitesState>({ loadStatus: LOAD_STATUSES.REQUIRED, items: [] })
        .handleAction([getGeographicStructureAllSites.request], (state, action) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADING,
        }))
        .handleAction([getGeographicStructureAllSites.success], (state, action) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
            items: action.payload,
        })),

    site: createReducer<GeographicStructureState>({ loadStatus: LOAD_STATUSES.LOADED, items: {} })
        .handleAction([getGeographicStructureSites.request], (state) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADING,
        }))
        .handleAction([getGeographicStructureSites.success], (state, action) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
            items: { ...state.items, [action.payload.parentId]: action.payload.items },
        }))
        .handleAction([getGeographicStructureSites.failure], (state) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
        })),

    siteGroup: createReducer<GeographicStructureState>({ loadStatus: LOAD_STATUSES.LOADED, items: {} })
        .handleAction([getGeographicStructureSiteGroups.request], (state) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADING,
        }))
        .handleAction([getGeographicStructureSiteGroups.success], (state, action) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
            items: { ...state.items, [action.payload.parentId]: action.payload.items },
        }))
        .handleAction([getGeographicStructureSiteGroups.failure], (state) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
        })),

    area: createReducer<GeographicStructureState>({ loadStatus: LOAD_STATUSES.REQUIRED, items: {} })
        .handleAction([setReferenceDataAreaLoadStatus], (state, action) => ({
            ...state,
            loadStatus: action.payload,
        }))
        .handleAction([getGeographicStructureAreas.request], (state) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADING,
        }))
        .handleAction([getGeographicStructureAreas.success], (state, action) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
            items: { ...state.items, [action.payload.parentId]: action.payload.items },
        }))
        .handleAction([getGeographicStructureAreas.failure], (state) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
        })),

    combinedResource: createReducer<ResourcesState>({ loadStatus: LOAD_STATUSES.REQUIRED, items: {} })
        .handleAction([setResourcesLoadStatus], (state, action) => ({
            ...state,
            loadStatus: action.payload,
        }))
        .handleAction([getResources.request], (state) => ({ ...state, loadStatus: LOAD_STATUSES.LOADING }))
        .handleAction([getResources.success], (state, action) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
            items: { ...state.items, ...action.payload },
        }))
        .handleAction([getResources.failure], (state) => ({
            ...state,
            loadStatus: LOAD_STATUSES.LOADED,
        })),
});
