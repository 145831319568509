import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { ListItem } from 'components/Forms';
import { isEqual } from 'lodash';

import { ReactComponent as CrossCircle } from '../../../assets/icons/CrossCircle.svg';
import ActionButtonsPanel from '../../../components/ActionButtonsPanel';
import CompanySearchFrom from '../../../components/CompanySearchPanel/SearchForm/SearchForm';
import DataTable from '../../../components/DataTable';
import Pagination from '../../../components/DataTable/Pagination';
import Button from '../../../components/FormElements/Button';
import InputWrapper from '../../../components/FormElements/InputWrapper';
import EditColumnsForm from '../../../components/Forms/EditColumnsForm';
import WithLoading from '../../../components/HOC/WithLoading';
import { PersonSearchData } from '../../../components/PersonSearchPanels/interfaces/PersonSearchData';
import VisitorSearchFrom from '../../../components/PersonSearchPanels/VIsitorsSearch/SearchForm/SearchForm';
import ReportFilterPanel from '../../../components/ReportFilterPanel/ReportFilterPanel';
import Header from '../../../components/SiteSubHeader';
import SlidingPanel from '../../../components/SlidingPanel';
import { PersonType } from '../../../enums/PersonType';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { Person } from '../../../services/ApiClients/Models';
import { AllListItemOption } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface, ReportsActiveColumn } from '../interfaces';
import { generateAndDownloadReportExportFile, onVisitorSearch, resultPerPageListItems } from '../utils';

// eslint-disable-next-line import/no-cycle
import { VisitorProfileReportProps } from './index';
import { buildQuery, getColumns } from './VisitorProfileReportUtils';

import './VisitorProfileReport.scss';

interface GridSettings {
    sortOptions?: GridSortModel;
    resultPerPage: ListItem;
    page: string;
}

const VisitorProfileReport = ({
    searchInternalVisitors,
    searchExternalVisitors,
    searchVisitorsResult,
    resetVisitorSearch,
    isSearchVisitorsInProgress,
    isGetCompaniesInProgress,
    searchCompaniesResult,
    getCompanies,
    searchCompanies,
    companiesResult,
    isSearchCompaniesInProgress,
    resetCompaniesSearch,
    generateReport,
    isReportGenerationInProgress,
    visitorProfileReportData,
    isGenerationReportForExportInProgress,
    reportDataForExport,
    generateReportForExport,
}: VisitorProfileReportProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation() as any;

    const [gridSettings, setGridSettings] = useState<GridSettings>({
        page: '1',
        resultPerPage: resultPerPageListItems[2],
        sortOptions: [{ field: 'name', sort: 'asc' }],
    });

    const defaultFilter = {
        companyName: AllListItemOption.label,
        visitor: AllListItemOption.label,
    };

    const [isVisitorSearchPanelOpen, setVisitorSearchPanelOpen] = useState<boolean>(false);
    const [isCompanySearchPanelOpen, setCompanySearchPanelOpen] = useState<boolean>(false);

    const [appliedReportsFilter, setAppliedReportsFilter] = useState<any>({ ...defaultFilter, ...location.state });
    const [reportsFilter, setReportsFilter] = useState<any>({ ...defaultFilter, ...location.state });

    const [visitorSearchData, setVisitorSearchData] = useState<any>({});
    const [companySearchData, setCompanySearchData] = useState<any>({});
    const [personType, setPersonType] = useState<PersonType>(PersonType.Internal);

    const visitorTypeListItems = [
        { value: 'External', label: 'External' },
        { value: 'Internal', label: 'Internal' },
    ];

    const [isFiltersOpened, setIsFiltersOpened] = useState(false);
    const [columnsFilter, setColumnsFilter] = useState<ColumnFilterInterface>({});
    const [activeColumnsFilter, setActiveColumnsFilter] = useState<ActiveColumnFilterInterface>({});

    const [activeFilter, setActiveFilter] = useState('');
    const [activeFilterHeaderName, setActiveFilterHeaderName] = useState('');
    const [activeColumnsFilterArray, setActiveColumnsFilterArray] = useState<any[]>([]);

    const defaultColumnsFilter: ColumnFilterInterface = {
        company: {
            values: companiesResult?.length ? companiesResult : [],
            enabled: !('companyName' in reportsFilter) || reportsFilter.companyName === 'All',
        },
        visitorType: {
            values: ['Internal', 'External'],
            enabled: true,
        },
        cumulativeVisits: {
            values: [{ value: 1, option: 'equal' }],
            enabled: true,
        },
    };

    useEffect(() => {
        setColumnsFilter({
            ...columnsFilter,
            ...defaultColumnsFilter,
        });
    }, [reportsFilter, companiesResult]);

    const updateColumnsFilter = (columnName: string, values: any[]): void => {
        setColumnsFilter({
            ...columnsFilter,
            [columnName]: {
                ...columnsFilter[columnName],
                values,
            },
        });
    };

    const handleFilterClick = (columnName: string, headerName: string): void => {
        if (columnName === 'company' && !companiesResult?.length && reportsFilter.companyName === 'All') {
            getCompanies();
        }
        setIsFiltersOpened(true);
        setActiveFilter(columnName);
        setActiveFilterHeaderName(headerName);

        if (columnName in activeColumnsFilter) {
            updateColumnsFilter(columnName, [...activeColumnsFilter[columnName].values]);
        } else {
            updateColumnsFilter(columnName, [...defaultColumnsFilter[columnName].values]);
        }
    };

    const renderSideBar = (): void => {
        switch (activeFilter) {
            case 'company':
                setActiveColumnsFilterArray(
                    companiesResult?.length
                        ? [
                              ...companiesResult
                                  ?.map((item) => ({ value: item, label: item }))
                                  .sort((a, b) => a.label.localeCompare(b.label)),
                          ]
                        : []
                );
                break;
            case 'visitorType':
                setActiveColumnsFilterArray(visitorTypeListItems);
                break;
            default:
                setActiveColumnsFilterArray([]);
        }
    };

    useEffect(() => {
        if (activeFilter) {
            renderSideBar();
        }
    }, [activeFilter, companiesResult]);

    useEffect(() => {
        resetVisitorSearch();
        setVisitorSearchData({});
    }, [personType]);

    const generateReportWithColumnsFilter = (): void => {
        setIsFiltersOpened(!isFiltersOpened);
        const newActiveColumnFilter = {
            ...activeColumnsFilter,
            [activeFilter]: { values: columnsFilter[activeFilter].values },
        };
        setActiveColumnsFilter(newActiveColumnFilter);
        generateReport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                newActiveColumnFilter
            )
        );
    };

    const loadPage = (gridState: GridSettings): void => {
        generateReport(
            buildQuery(
                Number(gridState.resultPerPage.value),
                Number(gridState.page),
                gridState.sortOptions,
                appliedReportsFilter,
                activeColumnsFilter
            )
        );
    };

    const applyFilterAndGenerateReport = useCallback(
        (reportsFilterParam?: any) => {
            setAppliedReportsFilter(reportsFilterParam || reportsFilter);
            setGridSettings({ ...gridSettings, page: '1' });
            generateReport(
                buildQuery(
                    Number(gridSettings.resultPerPage.value),
                    1,
                    gridSettings.sortOptions,
                    reportsFilterParam || reportsFilter,
                    activeColumnsFilter
                )
            );
        },
        [reportsFilter, gridSettings]
    );

    const setGridStateAndLoadPage = (newGridState: GridSettings): void => {
        setGridSettings(newGridState);
        loadPage(newGridState);
    };

    useEffect(() => {
        if (isVisitorSearchPanelOpen) {
            if (visitorSearchData.name || visitorSearchData.email) {
                setVisitorSearchData({ name: '', email: '' });
            }
            setPersonType(PersonType.Internal);
        }
    }, [isVisitorSearchPanelOpen]);

    useEffect(() => {
        if (isCompanySearchPanelOpen) {
            if (companySearchData.name) {
                setCompanySearchData({ name: '' });
            }
        }
    }, [isCompanySearchPanelOpen]);

    useEffect(() => {
        loadPage(gridSettings);
    }, []);

    const defaultActiveColumns = getColumns(handleFilterClick, columnsFilter, activeColumnsFilter).map((item) => ({
        name: item.headerName,
        active: true,
    }));

    const [isEditColumnsPanelOpen, setEditColumnsPanelOpen] = useState<boolean>(false);
    const [activeColumns, setActiveColumns] = useState<ReportsActiveColumn[]>(defaultActiveColumns);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    const applyNewActiveColumns = (newColumns: ReportsActiveColumn[]): void => {
        setActiveColumns(newColumns);
    };

    const generateReportForExportCallBack = useCallback(() => {
        const visibleColumns = columns.map((c) => c.field);
        generateReportForExport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                activeColumnsFilter,
                true,
                visitorProfileReportData?.totalCount,
                visibleColumns
            )
        );
    }, [gridSettings, visitorProfileReportData, gridSettings, columns]);

    const formatReportForExport = (report?: any[]): any => {
        if (!report) {
            return [];
        }
        const formattedReport = report.map((i) => {
            return {
                ...('name' in i && { name: i.name }),
                ...('email' in i && { email: i.email }),
                ...('company' in i && { company: i.company }),
                ...('visitorType' in i && { visitorType: i.visitorType }),
                ...('mobile' in i && { mobile: i.mobile }),
                ...('cumulativeVisits' in i && { cumulativeVisits: i.cumulativeVisits }),
            };
        });

        return formattedReport;
    };

    useDidMountEffect(() => {
        if (!isGenerationReportForExportInProgress) {
            const formattedData = formatReportForExport(reportDataForExport?.items);
            generateAndDownloadReportExportFile('Visitor Profile Report', columns, formattedData);
        }
    }, [isGenerationReportForExportInProgress]);

    useEffect(() => {
        const gridColumns = getColumns(handleFilterClick, columnsFilter, activeColumnsFilter);
        const filteredColumns = gridColumns.filter(
            (item) => activeColumns.find((e) => e.name === item.headerName)?.active
        );
        setColumns(filteredColumns);
    }, [activeColumns, columnsFilter, activeColumnsFilter]);

    return (
        <main className="visitor-profile-report">
            <Header
                title={t('VisitorProfileReport_Title')}
                dataTestId="visitor-profile-title"
                className="visitor-profile-title"
                rightSideItems={[
                    <Button
                        dataTestId="edit-columns-btn"
                        text={t('Reports_EditColumns')}
                        className="edit-btn"
                        onClick={() => {
                            setEditColumnsPanelOpen(true);
                        }}
                    />,
                ]}
            />
            <WithLoading isLoading={isReportGenerationInProgress || isGenerationReportForExportInProgress}>
                <div className="filter-options">
                    <InputWrapper label={t('Reports_Company')} inputName="company">
                        <button type="button" className="side-panel-input" data-testid="">
                            <div
                                data-testid="company"
                                className="selected-item"
                                onClick={() => {
                                    resetCompaniesSearch();
                                    setCompanySearchPanelOpen(!isCompanySearchPanelOpen);
                                }}
                            >
                                {reportsFilter.companyName && reportsFilter.companyName}
                            </div>
                            <div
                                data-testid="remove-company"
                                className="cross-icon"
                                onClick={() => {
                                    resetCompaniesSearch();
                                    setReportsFilter({ ...reportsFilter, companyName: AllListItemOption.label });
                                }}
                            >
                                <CrossCircle />
                            </div>
                        </button>
                    </InputWrapper>

                    <InputWrapper label={t('Reports_Visitor')} inputName="visitor">
                        <button type="button" className="side-panel-input" data-testid="">
                            <div
                                data-testid="visitor"
                                className="selected-item"
                                onClick={() => {
                                    resetVisitorSearch();
                                    setVisitorSearchPanelOpen(!isVisitorSearchPanelOpen);
                                }}
                            >
                                {reportsFilter.visitor && reportsFilter.visitor === AllListItemOption.label
                                    ? reportsFilter.visitor
                                    : `${reportsFilter.visitor.firstname} ${reportsFilter.visitor.surname}`}
                            </div>
                            <div
                                data-testid="remove-visitor"
                                className="cross-icon"
                                onClick={() => {
                                    setReportsFilter({ ...reportsFilter, visitor: AllListItemOption.label });
                                }}
                            >
                                <CrossCircle />
                            </div>
                        </button>
                    </InputWrapper>
                    <Button
                        text={t('Button_Apply')}
                        type="submit"
                        className="apply-btn"
                        dataTestId="apply-btn"
                        disabled={isEqual(reportsFilter, appliedReportsFilter)}
                        onClick={applyFilterAndGenerateReport}
                    />
                </div>

                <DataTable
                    columns={columns}
                    rowsData={(activeColumns.some((column) => column.active) && visitorProfileReportData?.items) || []}
                    sortModel={gridSettings.sortOptions}
                    onSort={(sortOptions: any) => setGridStateAndLoadPage({ ...gridSettings, sortOptions })}
                    page={gridSettings.page}
                />

                <ActionButtonsPanel
                    leftSideElement={
                        <Pagination
                            pageString={gridSettings.page}
                            total={visitorProfileReportData?.totalCount || 0}
                            onPageChange={(page: string) => setGridSettings({ ...gridSettings, page })}
                            onPerPageChange={(perPage: ListItem) =>
                                setGridSettings({ ...gridSettings, resultPerPage: perPage })
                            }
                            resultPerPageOptions={resultPerPageListItems}
                            selectedResultPerPage={gridSettings.resultPerPage}
                        />
                    }
                >
                    <>
                        <Button
                            text={t('Button_Back')}
                            className="back-btn"
                            dataTestId="back-btn"
                            onClick={() => navigate(`/reports`)}
                        />
                        <Button
                            primary
                            text={t('Button_ExportReport')}
                            className="export-btn"
                            dataTestId="export-btn"
                            onClick={() => generateReportForExportCallBack()}
                            disabled={visitorProfileReportData?.items?.length === 0}
                        />
                    </>
                </ActionButtonsPanel>

                <SlidingPanel
                    title={t('Reports_SearchVisitors')}
                    className="sliding-panel"
                    open={isVisitorSearchPanelOpen}
                    showBackgroundMask
                    onClose={() => {
                        setVisitorSearchPanelOpen(false);
                    }}
                >
                    <WithLoading isLoading={isSearchVisitorsInProgress}>
                        <VisitorSearchFrom
                            searchData={visitorSearchData as PersonSearchData}
                            onSearchDataChange={setVisitorSearchData}
                            onSearch={(searchData: any) =>
                                onVisitorSearch(
                                    searchData,
                                    reportsFilter,
                                    personType,
                                    searchInternalVisitors,
                                    searchExternalVisitors
                                )
                            }
                            onSelect={(visitor: Person) => {
                                setReportsFilter({ ...reportsFilter, visitor });
                                setActiveColumnsFilter({});
                            }}
                            searchResult={searchVisitorsResult}
                            resultPlaceHolder={t('Reports_SearchForVisitors')}
                            setSearchPanelOpen={setVisitorSearchPanelOpen}
                            personType={personType}
                            onPersonTypeChange={setPersonType}
                            showNewVisitorButton={false}
                        />
                    </WithLoading>
                </SlidingPanel>

                <SlidingPanel
                    title={t('Reports_SearchCompanies')}
                    className="sliding-panel"
                    open={isCompanySearchPanelOpen}
                    showBackgroundMask
                    onClose={() => {
                        setCompanySearchPanelOpen(false);
                    }}
                >
                    <WithLoading isLoading={isSearchCompaniesInProgress}>
                        <CompanySearchFrom
                            searchData={companySearchData}
                            onSearchDataChange={setCompanySearchData}
                            onSearch={(searchData: any) => searchCompanies(searchData.name)}
                            onSelect={(companyName: string) => {
                                setReportsFilter({ ...reportsFilter, companyName, visitor: AllListItemOption.label });
                                setVisitorSearchData({ ...visitorSearchData, company: companyName });
                                setActiveColumnsFilter({});
                            }}
                            searchResult={searchCompaniesResult}
                            resultPlaceHolder={t('Reports_SearchForCompanies')}
                            setSearchPanelOpen={setCompanySearchPanelOpen}
                            searchFieldPlaceholder={t('Reports_Company')}
                        />
                    </WithLoading>
                </SlidingPanel>

                <SlidingPanel
                    title={t('Reports_CustomColumns')}
                    open={isEditColumnsPanelOpen}
                    showBackgroundMask
                    className="sliding-panel"
                    onClose={() => {
                        setEditColumnsPanelOpen(false);
                    }}
                >
                    <EditColumnsForm
                        activeColumns={activeColumns}
                        applyNewActiveColumns={applyNewActiveColumns}
                        isEditColumnsPanelOpen={isEditColumnsPanelOpen}
                        setEditColumnsPanelOpen={setEditColumnsPanelOpen}
                    />
                </SlidingPanel>

                <ReportFilterPanel
                    isFiltersOpened={isFiltersOpened}
                    activeFilterHeaderName={activeFilterHeaderName}
                    setIsFiltersOpened={setIsFiltersOpened}
                    activeFilter={activeFilter}
                    activeColumnsFilterArray={activeColumnsFilterArray}
                    setColumnsFilter={setColumnsFilter}
                    columnsFilter={columnsFilter}
                    generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                    isLoading={isGetCompaniesInProgress}
                    countFieldName="cumulativeVisits"
                />
            </WithLoading>
        </main>
    );
};

export default VisitorProfileReport;
