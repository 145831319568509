import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import MultiResourceEdit from 'features/Resources/Combined/MultiResourceBookingEdit/MultiResourceBookingEdit';
import { RootState } from 'PortalTypes';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    sites: state.referenceData.sites.items,
    siteGroups: state.referenceData.siteGroup.items,
    selectedSiteTimezone: state.filters.global.site?.timezone,
    tenantId: state.authentication.user?.tenantId,
    selectedSiteGlobal: state.filters.global.site,
    selectedSiteGroupGlobal: state.filters.global.siteGroup,
    originalBookingInfo: state.combined.edit.info,
    areas: state.referenceData.area.items,
});

const dispatchProps = {};

export type MultiResourceEditProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps & { bookingId: string };

export default connect(mapStateToProps, dispatchProps)(MultiResourceEdit);
