import { Event } from 'components/BookingGrid/Models/Event';
import * as constants from 'features/constants';
import { DateTime } from 'luxon';
import { BookedResourceSummary } from 'services/ApiClients/Booking';
import { isNil } from 'utilities/ts';

import { store } from '../../../../../store';
import { toTimeZoneString } from '../../../../../utilities/dateTimeUtils';

class EventsMapper {
    public static MapBookedResourceSummary = (bookedResourceSummary: BookedResourceSummary): Event | undefined => {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        if (!bookedResourceSummary) {
            return undefined;
        }

        const isOBO = bookedResourceSummary.onBehalfOf?.personId && bookedResourceSummary.onBehalfOf?.displayName;
        const isPendingUpdate =
            bookedResourceSummary.bookingStatus === constants.BOOKING_STATUS.UPDATE_PENDING &&
            bookedResourceSummary.isPending;
        let bookingDisplayName;

        if (bookedResourceSummary.bookingDisplayName !== null && bookedResourceSummary.bookingDisplayName !== '') {
            if (isPendingUpdate) {
                bookingDisplayName = isOBO
                    ? `Reserved by ${bookedResourceSummary.onBehalfOf?.displayName}`
                    : `Reserved by ${bookedResourceSummary.createdByDisplayName}`;
            } else {
                bookingDisplayName = bookedResourceSummary.bookingDisplayName;
            }
        } else if (isOBO) {
            if (isPendingUpdate) {
                bookingDisplayName = `Reserved by ${bookedResourceSummary.onBehalfOf?.displayName}`;
            } else {
                bookingDisplayName = bookedResourceSummary.onBehalfOf?.displayName;
            }
        } else if (bookedResourceSummary.bookingStatus === constants.BOOKING_STATUS.PENDING || isPendingUpdate) {
            bookingDisplayName = `Reserved by ${bookedResourceSummary.createdByDisplayName}`;
        } else {
            bookingDisplayName = bookedResourceSummary.createdByDisplayName;
        }
        const startDateTime = toTimeZoneString(
            bookedResourceSummary.startDateTime,
            timezone,
            constants.LUXON_DEFAULT_DATE_TIME_FORMAT
        );

        const endDateTime = toTimeZoneString(
            bookedResourceSummary.endDateTime,
            timezone,
            constants.LUXON_DEFAULT_DATE_TIME_FORMAT
        );

        return {
            id: `${bookedResourceSummary.resourceId?.toString()}:${bookedResourceSummary.bookingId?.toString()}:${bookedResourceSummary.resourceBookingDetailId?.toString()}:${
                bookedResourceSummary?.isPending
            }`,
            text: bookingDisplayName,
            start: startDateTime,
            end: endDateTime,
            resource: bookedResourceSummary.resourceId.toString(),
            cssClass: `status-${bookedResourceSummary.bookingStatus.toLowerCase()}`,
            toolTip: bookingDisplayName,
            bookingStatus: bookedResourceSummary.bookingStatus,
            checkInStatus: bookedResourceSummary.checkInStatus,
            createdByUserId: bookedResourceSummary.createdByUserId,
            isPartOfRepeat: bookedResourceSummary.isPartOfRepeat,
            isPrivate: bookedResourceSummary.isPrivate,
        };
    };

    private static FindPendingBookings = (
        bookedResourceSummaries: BookedResourceSummary[]
    ): BookedResourceSummary[] => {
        const pendingBookings = bookedResourceSummaries
            .map((item, _, arr) => {
                return arr.find((item1) => {
                    return (
                        (item.bookingId.toString() === item1.repeatBookingId?.toString() ||
                            (item.bookingId.toString() === item1.bookingId.toString() &&
                                (item1.isPending ||
                                    item1.bookingStatus === constants.BOOKING_STATUS.UPDATE_PENDING))) &&
                        !(
                            DateTime.fromISO(item.startDateTime).equals(DateTime.fromISO(item1.startDateTime)) &&
                            DateTime.fromISO(item.endDateTime).equals(DateTime.fromISO(item1.endDateTime))
                        ) &&
                        DateTime.fromISO(item.startDateTime) < DateTime.fromISO(item1.endDateTime) &&
                        DateTime.fromISO(item.endDateTime) > DateTime.fromISO(item1.startDateTime)
                    );
                });
            })
            .filter((i) => i) as BookedResourceSummary[];
        return pendingBookings;
    };

    private static FormatBooking = (
        pendingBooking: BookedResourceSummary | undefined,
        booking: BookedResourceSummary
    ): BookedResourceSummary => {
        return !pendingBooking
            ? booking
            : {
                  ...booking,
                  startDateTime:
                      booking.startDateTime <= pendingBooking?.startDateTime
                          ? booking.startDateTime
                          : pendingBooking?.startDateTime,
                  endDateTime:
                      booking.endDateTime > pendingBooking?.endDateTime
                          ? booking.endDateTime
                          : pendingBooking?.endDateTime,
              };
    };

    public static MapBookedResourceSummaries = (bookedResourceSummaries: BookedResourceSummary[]): Event[] => {
        const result: Event[] = [];

        const pendingBookings: BookedResourceSummary[] = EventsMapper.FindPendingBookings(bookedResourceSummaries);

        EventsMapper.FilterZeroLengthBookedResourceSummaries(bookedResourceSummaries).forEach((booking) => {
            const eventResult = EventsMapper.MapBookedResourceSummary(booking);

            if (!eventResult) {
                return;
            }

            const pendingBooking = pendingBookings?.find(
                (item) =>
                    item.bookingId.toString() === booking.bookingId.toString() ||
                    item.repeatBookingId?.toString() === booking.bookingId.toString()
            );

            if (pendingBooking && (booking.repeatBookingId || booking.isPending)) {
                return;
            }

            if (pendingBooking) {
                const formattedBooking = EventsMapper.FormatBooking(pendingBooking, booking);
                result.push(EventsMapper.MapBookedResourceSummary(formattedBooking) as Event);
            } else {
                result.push(eventResult);
            }
        });
        return result;
    };

    private static FindDuplicates = (bookedResourceSummaries: BookedResourceSummary[]): BookedResourceSummary[] => {
        const duplicateBookings = bookedResourceSummaries.filter(
            (item, index, arr) =>
                index !==
                arr.findIndex(
                    (item1) =>
                        item.bookingId.toString() === item1.bookingId.toString() &&
                        DateTime.fromISO(item.startDateTime).equals(DateTime.fromISO(item1.startDateTime)) &&
                        DateTime.fromISO(item.endDateTime).equals(DateTime.fromISO(item1.endDateTime))
                )
        );

        return duplicateBookings;
    };

    private static FilterZeroLengthBookedResourceSummaries = (
        bookedResourceSummaries: BookedResourceSummary[]
    ): BookedResourceSummary[] => {
        const duplicateBookings = EventsMapper.FindDuplicates(bookedResourceSummaries);
        return bookedResourceSummaries.filter((bookedResource) => {
            const duplicate = duplicateBookings?.find(
                (item) => item.bookingId.toString() === bookedResource.bookingId.toString()
            );

            return !(
                (DateTime.fromISO(bookedResource.startDateTime).equals(DateTime.fromISO(bookedResource.endDateTime)) &&
                    bookedResource.bookingStatus === constants.BOOKING_STATUS.CURTAILED) ||
                (!isNil(duplicate) && !bookedResource.isPending)
            );
        });
    };
}

export default EventsMapper;
