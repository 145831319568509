export enum CellFillTypes {
    Free = 1,
    Unavailable,
    SetupCleardown,
    SetupCleardownPreRender,
    CheckedIn,
    SetupCleardownIntersect,
}

export enum periodUnitEnum {
    day,
    week,
    month,
}
