// eslint-disable-next-line import/no-cycle
import { HistoryDataLogs } from 'services/ApiClients/Booking';
import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';
import Guid from 'utilities/guid';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';

import { AbandonData } from './Models/AbandonSingleOccurrence';
import {
    CheckInOrCheckOutBookingCommand,
    CheckInOrCheckOutSingleOccurrence,
    StartEndTime,
} from './Models/CheckInOrCheckOutBookingCommand';
import { MyBooking } from './Models/MyBooking';
import { Occurrence } from './Models/Occurrence';
// eslint-disable-next-line import/no-cycle
import {
    BookedResourceSummary,
    Booking,
    CancelOrCurtailBookingCommand,
    CancelSingleOccurrence,
    ConfirmBookingCommand,
    ConfirmSingleOccurrence,
    CreateBookingCommand,
    GetBookingQuery,
    GetResourcesAvailabilityQuery,
    GetResourcesUnavailabilityQuery,
    MyBookingCommand,
    ResourceAvailabilityResponse,
    SearchBookedResourcesQuery,
    UnavailabilityResponse,
    UpdateBookedResourceCommand,
    UpdateBookingCommand,
    UpdateSingleOccurrence,
    UpdateWholeSeries,
} from './Models';

export class BookingApi extends BaseApiClient {
    protected static PersistedHeaders = {
        'Content-Type': 'application/json',
    };

    public static BuildBaseUrl = (apiVersion = 'v1'): string => {
        const baseUrl = getConfiguration().BOOKING_API;

        return `${baseUrl}/api/${apiVersion}`;
    };

    public static create = async (bookingRequest: CreateBookingCommand): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Booking.Create command. Request: ', bookingRequest);
        LoggingService.Debug('Sending Booking.Create command. Headers: ', BookingApi.PersistedHeaders);

        return BookingApi.post<CreateBookingCommand>(`${this.BuildBaseUrl()}/Booking`, bookingRequest);
    };

    public static confirm = async (bookingRequest: ConfirmBookingCommand): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Booking.Confirm command. Request: ', bookingRequest);
        LoggingService.Debug('Sending Booking.Confirm command. Headers: ', BookingApi.PersistedHeaders);

        return BookingApi.put<ConfirmBookingCommand>(`${this.BuildBaseUrl()}/Booking/confirm`, bookingRequest);
    };

    public static getSingle = async (query: GetBookingQuery): Promise<ApiResponse<Booking>> => {
        LoggingService.Debug('Sending Booking.Get query. Query: ', query);
        LoggingService.Debug('Sending Booking.Get query. Headers: ', BookingApi.PersistedHeaders);

        if (!query) throw new Error('You must provide a valid query');

        const url = `${this.BuildBaseUrl()}/Booking?bookingId=${query.bookingId}`;

        return BookingApi.get<Booking>(url);
    };

    public static update = async (bookingRequest: UpdateBookingCommand): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Booking.Update command. Request: ', bookingRequest);
        LoggingService.Debug('Sending Booking.Update command. Headers: ', BookingApi.PersistedHeaders);

        return BookingApi.patch<UpdateBookingCommand>(`${this.BuildBaseUrl()}/Booking`, bookingRequest);
    };

    public static updateWholeSeries = async (bookingRequest: UpdateWholeSeries): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Booking.Update command. Request: ', bookingRequest);
        LoggingService.Debug('Sending Booking.Update command. Headers: ', BookingApi.PersistedHeaders);

        return BookingApi.patch<UpdateWholeSeries>(`${this.BuildBaseUrl('v2')}/Booking`, bookingRequest);
    };

    public static updateBookedResource = async (bookingRequest: UpdateBookedResourceCommand): Promise<ApiResponse> => {
        LoggingService.Debug('Sending BookedResource.Update command. Request: ', bookingRequest);
        LoggingService.Debug('Sending BookedResource.Update command. Headers: ', BookingApi.PersistedHeaders);

        return BookingApi.put<UpdateBookedResourceCommand>(
            `${this.BuildBaseUrl()}/Booking/resource-booking-detail`,
            bookingRequest
        );
    };

    public static cancelOrCurtail = async (bookingRequest: CancelOrCurtailBookingCommand): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Booking.CancelOrCurtail command. Request: ', bookingRequest);
        LoggingService.Debug('Sending Booking.CancelOrCurtail command. Headers: ', BookingApi.PersistedHeaders);

        return BookingApi.put<CancelOrCurtailBookingCommand>(`${this.BuildBaseUrl()}/Booking/cancel`, bookingRequest);
    };

    public static search = async (query: SearchBookedResourcesQuery): Promise<ApiResponse<BookedResourceSummary[]>> => {
        LoggingService.Debug('Sending BookedResource.Search query. Query: ', query);
        LoggingService.Debug('Sending BookedResource.Search query. Headers: ', BookingApi.PersistedHeaders);

        if (!query) throw new Error('You must provide a valid search query');
        const resourceTypeIds = query.resourceTypes
            ? query.resourceTypes.map((id) => `resourceType=${id.toString()}`).join('&')
            : '';
        const resourcesIds = query.resourcesIds
            ? query.resourcesIds.map((id) => `ResourceId=${id.toString()}`).join('&')
            : '';

        let url = `${this.BuildBaseUrl()}/ResourceBookingDetail/search?fromDateTime=${query.fromDateTime}&toDateTime=${
            query.toDateTime
        }`;
        url += `&${resourceTypeIds}`;
        query.geographicStructureIds.forEach((gsId) => {
            url += `&areaId=${gsId.toString()}`;
        });
        url += `&${resourcesIds}`;

        return BookingApi.get<BookedResourceSummary[]>(url);
    };

    public static checkIn = async (bookingRequest: CheckInOrCheckOutBookingCommand): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Booking.CheckIn command. Request: ', bookingRequest);
        const url = `${this.BuildBaseUrl()}/Booking/${bookingRequest?.bookingId}/resource-booking-detail/${
            bookingRequest?.resourceId
        }/checkin`;

        return BookingApi.post(url, null);
    };

    public static checkOut = async (bookingRequest: CheckInOrCheckOutBookingCommand): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Booking.CheckIn command. Request: ', bookingRequest);
        const url = `${this.BuildBaseUrl()}/Booking/${bookingRequest?.bookingId}/resource-booking-detail/${
            bookingRequest?.resourceId
        }/checkout`;

        return BookingApi.post<CancelOrCurtailBookingCommand>(url, null);
    };

    public static getResourcesUnavailability = (
        request: GetResourcesUnavailabilityQuery
    ): Promise<ApiResponse<UnavailabilityResponse>> => {
        LoggingService.Debug('Sending ResourcesUnavailability request. Request: ', request);
        const url = `${this.BuildBaseUrl()}/ResourceUnavailability/for-set`;

        return BookingApi.post<GetResourcesUnavailabilityQuery, UnavailabilityResponse>(url, request);
    };

    public static getResourceAvailability = async (
        request: GetResourcesAvailabilityQuery
    ): Promise<ApiResponse<ResourceAvailabilityResponse>> => {
        LoggingService.Debug('Sending ResourcesUnavailability request. Request: ', request);
        const url = `/v1/ResourceAvailability?ResourceId=${request.resourceId}&Date=${request.date}`;

        return BookingApi.get<ResourceAvailabilityResponse>(url);
    };

    public static myBookings = async (myBookingsRequest: MyBookingCommand): Promise<ApiResponse<MyBooking[]>> => {
        LoggingService.Debug('Sending myBooking command. Request: ', myBookingsRequest);
        const url = `${this.BuildBaseUrl()}/Booking/me?fromDateTime=${myBookingsRequest.fromDateTime}&toDateTime=${
            myBookingsRequest.toDateTime
        }`;

        return BookingApi.get<MyBooking[]>(url);
    };

    public static confirmSingleOccurrence = async (bookingRequest: ConfirmSingleOccurrence): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Booking.Confirm command. Request: ', bookingRequest);
        LoggingService.Debug('Sending Booking.Confirm command. Headers: ', BookingApi.PersistedHeaders);

        return BookingApi.put<ConfirmSingleOccurrence>(
            `${this.BuildBaseUrl()}/Booking/confirmSingleOccurrence`,
            bookingRequest
        );
    };

    public static canselSingleOccurrence = async (cancleRequest: CancelSingleOccurrence): Promise<ApiResponse> => {
        LoggingService.Debug('Sending cancel single occurrence command. Request: ', cancleRequest);
        const url = `${this.BuildBaseUrl()}/Booking/cancelSingleOccurrence`;

        return BookingApi.put<CancelSingleOccurrence>(url, cancleRequest);
    };

    public static updateSingleOccurrence = async (updateRequest: UpdateSingleOccurrence): Promise<ApiResponse> => {
        LoggingService.Debug('Sending update single occurrence command. Request: ', updateRequest);
        const url = `${this.BuildBaseUrl('v2')}/Booking/occurrence`;

        return BookingApi.patch<UpdateSingleOccurrence>(url, updateRequest);
    };

    public static abandonBooking = async (bookingRequest: CancelOrCurtailBookingCommand): Promise<ApiResponse> => {
        LoggingService.Debug('Sending abandon single booking command. Request: ', bookingRequest);
        const url = `${this.BuildBaseUrl()}/Booking/abandon`;

        return BookingApi.put<CancelOrCurtailBookingCommand>(url, bookingRequest);
    };

    public static abandonSingleOccurrence = async (request: AbandonData): Promise<ApiResponse> => {
        LoggingService.Debug('Sending abandon occurrence command. Request: ', request);
        const url = `${this.BuildBaseUrl()}/Booking/abandonSingleOccurrence`;

        return BookingApi.put<AbandonData>(url, request);
    };

    public static getRepeatOccurrences = async (query: GetBookingQuery): Promise<ApiResponse<Occurrence[]>> => {
        LoggingService.Debug('Sending get repeat occurrences command. Request: ', query?.bookingId);

        const url = `${this.BuildBaseUrl('v2')}/Booking/${query?.bookingId}/occurrences`;

        return BookingApi.get<Occurrence[]>(url);
    };

    public static checkInOccurrence = async (request: CheckInOrCheckOutSingleOccurrence): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Occurrence.CheckIn command. Request: ', request);
        const url = `${this.BuildBaseUrl()}/Booking/${request?.urlParams?.bookingId}/occurrence/${
            request?.urlParams?.resourceId
        }/checkin`;

        return BookingApi.post<StartEndTime>(url, {
            ...request?.payload,
        });
    };

    public static checkOutOccurrence = async (request: CheckInOrCheckOutSingleOccurrence): Promise<ApiResponse> => {
        LoggingService.Debug('Sending Occurrence.CheckOut command. Request: ', request);
        const url = `${this.BuildBaseUrl()}/Booking/${request?.urlParams?.bookingId}/occurrence/${
            request?.urlParams?.resourceId
        }/checkout`;

        return BookingApi.post<StartEndTime>(url, {
            ...request?.payload,
        });
    };

    public static getHistoryDataById = async (bookingId: Guid): Promise<ApiResponse<HistoryDataLogs>> => {
        LoggingService.Debug('Sending History Tab info command. Request: ', bookingId);
        const url = `${this.BuildBaseUrl()}/Booking/${bookingId.toString()}/History`;

        return BookingApi.get<HistoryDataLogs>(url);
    };
}
