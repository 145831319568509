import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { cssTransition, ToastContainer } from 'react-toastify';
import ContentLoader from 'components/ContentLoader';
import * as constants from 'features/constants';
import { FeatureToggleWrapper } from 'featuretoggle-react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/integration/react';
import AppRoutes from 'routes';
import { history, persistor, store } from 'store';
import { getConfiguration, updateAppConfiguration } from 'utilities/appConfigsUtils';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

const animations = cssTransition({
    enter: 'enterAnimation',
    exit: 'exitAnimation',
    duration: [500, 250],
});

const App = (): JSX.Element => {
    const [configsLoadingStatus, setConfigsLoadingStatus] = useState(constants.LOAD_STATUSES.LOADING);

    useEffect(() => {
        const interval = updateAppConfiguration(() => setConfigsLoadingStatus(constants.LOAD_STATUSES.LOADED));

        return () => clearInterval(interval);
    }, []);

    const provider = {
        init: (handler: any) => {
            const featureToggles = {
                repeatsFeature: getConfiguration().REPEATS_ENABLED,
                analyticsFeature: getConfiguration().ANALYTICS_ENABLED,
                searchFeature: getConfiguration().SEARCH_ENABLED,
            };
            // call your endpoint or insert mock values into featureToggles here
            handler(featureToggles);
        },
    };

    return (
        <ContentLoader load={() => updateAppConfiguration()} loadStatus={configsLoadingStatus}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <FeatureToggleWrapper provider={provider}>
                        <Router history={history}>
                            <div className="appContainer">
                                <AppRoutes />
                            </div>

                            {/* @todo: when we introduce localisation rtl will need to be injected */}
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar
                                newestOnTop
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable={false}
                                pauseOnHover
                                transition={animations}
                            />
                        </Router>
                    </FeatureToggleWrapper>
                </PersistGate>
            </Provider>
        </ContentLoader>
    );
};

export default App;
