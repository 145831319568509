import React, { memo, useEffect } from 'react';
import BackgroundMask from 'components/BackgroundMask';
import Loading from 'components/Loading';
import * as constants from 'features/constants';

interface ContentLoaderProps {
    loadStatus: string;
    load: () => void;
    continueOnFailure?: boolean;
    failureContent?: JSX.Element;
    renderChildrenWhileLoading?: boolean;
}

const ContentLoader = ({
    load,
    loadStatus,
    children,
    continueOnFailure = true,
    renderChildrenWhileLoading = false,
    failureContent = <div />,
}: React.PropsWithChildren<ContentLoaderProps>): JSX.Element => {
    useEffect(() => {
        if (loadStatus === constants.LOAD_STATUSES.REQUIRED) {
            load();
        }
    }, [loadStatus]);

    switch (loadStatus) {
        case constants.LOAD_STATUSES.REQUIRED:
            return <div />;

        case constants.LOAD_STATUSES.LOADING:
            if (!renderChildrenWhileLoading) {
                return (
                    <>
                        <BackgroundMask className="open" />
                        <Loading />
                    </>
                );
            }

            return <>{children}</>;

        case constants.LOAD_STATUSES.FAILED:
            if (!continueOnFailure) {
                return <>{failureContent}</>;
            }

            return <>{children}</>;

        default:
            return <>{children}</>;
    }
};

export default memo(ContentLoader);
