import { ListItem } from 'components/Forms';

export const mapListItems = (
    items: any[],
    valueMap: (item: any) => string,
    labelMap: (item: any) => string
): ListItem[] => {
    return items.map((item) => ({
        value: valueMap(item),
        label: labelMap(item),
    }));
};
