import { GridColDef } from '@mui/x-data-grid';
import i18n from 'i18n';
import { DateTime } from 'luxon';
import { HistoryDataLog } from 'services/ApiClients/Booking';
import { isNil } from 'utilities/ts';

const START_DATE_TIME = 'StartDateTime';
const END_DATE_TIME = 'EndDateTime';
const CHECK_IN_DATE_TIME = 'CheckInDateTime';
const CHECK_OUT_DATE_TIME = 'CheckOutDateTime';

export interface HistoryItem {
    date: string;
    user: null;
    type: string;
    propertyName: string;
    changedFrom: any;
    changedTo: any;
}

const translatedHistoryLabel = (label: string): string =>
    i18n.exists(`HistoryLabel_${label}`) ? i18n.t(`HistoryLabel_${label}`) : label;

const formatProperty = (propertyKey: string, property: string, timezone: string): string => {
    if (
        propertyKey === START_DATE_TIME ||
        propertyKey === END_DATE_TIME ||
        propertyKey === CHECK_IN_DATE_TIME ||
        propertyKey === CHECK_OUT_DATE_TIME
    ) {
        return isNil(property) ? 'None' : DateTime.fromISO(property, { zone: timezone }).toFormat('d MMM yyyy H:mm');
    }
    return isNil(property) ? 'None' : property;
};

const formatPropertyColumnValue = (propertyName: string, property: string, timezone: string): any => {
    if (isNil(propertyName)) {
        return '';
    }
    return `${translatedHistoryLabel(propertyName)}: ${formatProperty(propertyName, property, timezone)}`;
};

export const formatAuditLogs = (items: HistoryDataLog[], timezone: string, hourFormat: string): HistoryItem[] => {
    return items.map(({ timestamp, domainEventName, metadata }) => {
        return {
            date: `${DateTime.fromISO(timestamp, { zone: timezone })
                .toUTC()
                .toFormat('d MMM yyyy')} @ ${DateTime.fromISO(timestamp, { zone: timezone }).toFormat(hourFormat)}`,
            user: null,
            type: domainEventName,
            propertyName: metadata.propertyName,
            changedFrom: formatPropertyColumnValue(metadata.propertyName, metadata.propertyBefore, timezone),
            changedTo: formatPropertyColumnValue(metadata.propertyName, metadata.propertyAfter, timezone),
        };
    });
};

export const getHistoryInfoColumns = (): GridColDef[] => [
    {
        field: 'date',
        headerName: i18n.t('HistoryTable_Date'),
        minWidth: 200,
        sortable: false,
    },
    {
        field: 'user',
        headerName: i18n.t('HistoryTable_User'),
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'type',
        headerName: i18n.t('HistoryTable_Type'),
        minWidth: 300,
        sortable: false,
    },
    {
        field: 'changedFrom',
        headerName: i18n.t('HistoryTable_ChangedFrom'),
        minWidth: 300,
        flex: 1,
        sortable: false,
    },
    {
        field: 'changedTo',
        headerName: i18n.t('HistoryTable_ChangedTo'),
        minWidth: 300,
        flex: 1,
        sortable: false,
    },
];
