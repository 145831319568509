import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

import { getGeographicStructureAreas } from '../../GeographicStructures/Redux/actions';
import { generateReport, generateReportForExport, reportsGetDepartments } from '../Redux/actions';

// eslint-disable-next-line import/no-cycle,
import ResourceBookingsReport from './ResourceBookingsReport';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    tenantId: state.authentication.user?.tenantId || '',
    sites: state.referenceData.sites.items,
    siteGroups: state.referenceData.siteGroup.items,
    areas: state.referenceData.area.items,
    areasLoadStatus: state.referenceData.area.loadStatus,
    departments: state.reports.departmentsResult?.departments,
    isGetDepartmentsInProgress: state.reports.isGetDepartmentsInProgress,
    isReportGenerationInProgress: state.reports.isReportGenerationInProgress,
    reportData: state.reports.reportData?.resourceBookings,
    is24HoursTimeFormat: state.authentication.user?.is_24h_timeFormat,
    isGenerationReportForExportInProgress: state.reports.isGenerationReportForExportInProgress,
    reportDataForExport: state.reports.reportDataForExport?.resourceBookings,
});

const mapDispatchToProps = {
    getDepartments: reportsGetDepartments.request,
    generateReport: generateReport.request,
    getAreas: getGeographicStructureAreas.request,
    generateReportForExport: generateReportForExport.request,
};

export type ResourceBookingsReportProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ResourceBookingsReport);
