import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'assets/icons/floorPlan/SearchIcon.svg';
import { TextBox } from 'components/FormElements';
import RadioButtonSelector from 'components/RadioButtonSelector/RadioButtonSelector';
import { EnterKey } from 'features/constants';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';

import FloorPlanSearchResults from './FloorPlanSearchResults';
// eslint-disable-next-line import/no-cycle
import { Props } from './index';
import { SearchRadioButtonsTypes } from './utils';

import './FloorPlanSearch.scss';

const FloorPlanSearch = ({
    getSearchData,
    searchResultLoadStatus,
    searchResult,
    resetFloorPlanSearchData,
    extendedHourFormat,
    selectedSiteTimezone,
    onClose,
    updateSite,
    sitesGeoStructure,
    selectedSite,
    setSearchResultItem,
    setSearchTrigger,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchType, setSearchType] = useState<string>(SearchRadioButtonsTypes.ALL.toString());

    const radioGroupItems = [
        { value: SearchRadioButtonsTypes.ALL, label: t('FloorPlan_Search_Type_All'), dataTestId: 'radio-btn-all' },
        {
            value: SearchRadioButtonsTypes.PEOPLE,
            label: t('FloorPlan_Search_Type_People'),
            dataTestId: 'radio-btn-people',
        },
        { value: SearchRadioButtonsTypes.DESKS, label: t('Navigation_Desks'), dataTestId: 'radio-btn-desks' },
        { value: SearchRadioButtonsTypes.ROOMS, label: t('Navigation_Rooms'), dataTestId: 'radio-btn-rooms' },
    ];

    useEffect(() => {
        if (!searchValue) {
            resetFloorPlanSearchData();
        }
    }, [searchValue]);

    const handleOnSearchValueChange = (value: string): void => {
        setSearchValue(value);
    };

    const handleSearch = (): void => {
        getSearchData({ searchString: searchValue, searchType });
    };

    const handleSearchTypeChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setSearchType(e.target.value);
        if (searchValue.length > 2) {
            getSearchData({ searchString: searchValue, searchType: e.target.value });
        }
    };

    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === EnterKey && searchValue.length > 2) {
            getSearchData({ searchString: searchValue, searchType });
        }
    };

    const handleShowSearchedItem = (selectedSiteId: string): void => {
        if (selectedSite?.id.toString() !== selectedSiteId) {
            const site = sitesGeoStructure.find((item) => item.id.toString() === selectedSiteId);
            const newSite = {
                ...site,
                isFloorPlanSearch: true,
            };
            updateSite(newSite as GeographicStructureItem);
        }
    };

    return (
        <div className="floorplan-search-container">
            <h2 className="floorplan-search-title">{t('FloorPlan_Search_Title')}</h2>
            <div className="floorplan-search-field-wrapper">
                <TextBox
                    className="floorplan-search-field"
                    hideLabel
                    value={searchValue}
                    onChange={(value: string) => {
                        handleOnSearchValueChange(value);
                    }}
                    dataTestId="floorplan-search-field"
                    placeholder={t('FloorPlan_Search_Placeholder')}
                    type="search"
                    maxLength={50}
                    onKeyPress={onKeyPress}
                />
                <button
                    onClick={handleSearch}
                    disabled={searchValue.length < 3}
                    className="floorplan-search-button"
                    type="button"
                >
                    <SearchIcon />
                </button>
            </div>
            <RadioButtonSelector
                required
                value={searchType}
                items={radioGroupItems}
                handleChange={handleSearchTypeChange}
            />
            <FloorPlanSearchResults
                searchResults={searchResult}
                searchValue={searchValue}
                searchResultLoadStatus={searchResultLoadStatus}
                extendedHourFormat={extendedHourFormat as string}
                onClose={onClose}
                selectedSiteTimezone={selectedSiteTimezone as string}
                handleShowSearchedItem={handleShowSearchedItem}
                setSearchResultItem={setSearchResultItem}
                setSearchTrigger={setSearchTrigger}
            />
        </div>
    );
};

export default FloorPlanSearch;
