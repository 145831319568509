import React from 'react';
import { ReactComponent as CloudbookingLogo } from 'assets/logo.svg';

const Logo = (): JSX.Element => {
    return (
        <div className="logoWrapper">
            <CloudbookingLogo />
        </div>
    );
};

export default Logo;
