import React, { memo } from 'react';
import classnames from 'classnames';

import './ViewsPanelButton.scss';

export interface ViewsPanelButtonProps {
    icon: React.ElementType;
    active: boolean;
    disabled: boolean;
    onClick: () => void;
    dataTestId?: string;
}

const ViewsPanelButton = ({
    onClick,
    icon: IconComponent,
    active,
    disabled,
    dataTestId,
}: ViewsPanelButtonProps): JSX.Element => (
    <button
        type="button"
        className={classnames('viewsPanelButton', { active, disabled })}
        onClick={disabled ? () => {} : () => onClick()}
        data-testid={dataTestId}
    >
        <IconComponent />
    </button>
);

export default memo(ViewsPanelButton);
