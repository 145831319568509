import React from 'react';
import MyBookingsComponent from 'features/MyBookings';
import MainLayout from 'layouts/MainLayout/MainLayout';

const MyBookings = (): JSX.Element => (
    <MainLayout>
        <MyBookingsComponent />
    </MainLayout>
);

export default MyBookings;
