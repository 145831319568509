import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as CustomerSupportIcon } from 'assets/icons/CustomerSupport.svg';
import { ReactComponent as DocumentLibraryIcon } from 'assets/icons/DocLibrary.svg';
import Header from 'components/SiteSubHeader';

// eslint-disable-next-line import/no-cycle
import { HelpProps } from '.';

import './Help.scss';

const defaultSupportText = `
**We'd love to hear from you and help in any way we can.**

So, please either email us at [hello@cloudbooking.com](mailto:hello@cloudbooking.com)

or call us on +44 (0) 800 033 7841
`;

const renderSupportText = (supportText: any): any => {
    if (supportText) {
        return <div dangerouslySetInnerHTML={{ __html: supportText || defaultSupportText }} />;
    }
    return <ReactMarkdown source={supportText || defaultSupportText} />;
};

const Help = ({ supportText }: HelpProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <main className="pageHelp">
            <Header title="Help" dataTestId="pageHelp-title" />
            <div className="pageHelp__content">
                <div className="pageHelp__content__content-area">
                    <DocumentLibraryIcon className="pageHelp__content--icon" />
                    <h2>{t('Help_DocumentaionTitle')}</h2>
                    <div className="__content-area">
                        <p>{t('Help_DocumentationFaq')}</p>
                        <a
                            className="documentation-link"
                            href="https://cloudbooking.com/knowledgebase"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Help_DocumentaionLibrary')}
                        </a>
                    </div>
                </div>
                <div className="pageHelp__content__content-area">
                    <CustomerSupportIcon className="pageHelp__content--icon" />
                    <h2>{t('Support_Title')}</h2>
                    <div data-testid="help-content" className="pageHelp__content__content-area--text-content">
                        {renderSupportText(supportText)}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Help;
