import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';
import { ResourceType } from '../Resource';

import { ConfigurationLevel, ConfigurationQuery, ConfigurationResponse } from './Models';

export class ConfigurationApi extends BaseApiClient {
    protected static PersistedHeaders = {
        'Content-Type': 'application/json',
    };

    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().BOOKING_API;

        return `${baseUrl}/api/v1`;
    };

    public static getDeskConfiguration = async (
        query: ConfigurationQuery
    ): Promise<ApiResponse<ConfigurationResponse>> => {
        const deskConfigurationQuery =
            query.configurationLevel === ConfigurationLevel.SITE
                ? `configurationType=${ResourceType.DESK.name.toLocaleLowerCase()}&configurationLevel=${
                      query.configurationLevel
                  }&configurationLevelEntityId=${query.configurationLevelEntityId.toString()}`
                : `configurationType=${ResourceType.DESK.name.toLocaleLowerCase()}&configurationLevel=${
                      query.configurationLevel
                  }`;

        LoggingService.Debug('Sending Booking.getConfiguration query. Query: ', deskConfigurationQuery);
        LoggingService.Debug('Sending Booking.getConfiguration query. Headers: ', ConfigurationApi.PersistedHeaders);

        return ConfigurationApi.get<ConfigurationResponse>(
            `${this.BuildBaseUrl()}/Configuration/effective?${deskConfigurationQuery}`
        );
    };

    public static getRoomsConfiguration = async (
        query: ConfigurationQuery
    ): Promise<ApiResponse<ConfigurationResponse>> => {
        const deskConfigurationQuery =
            query.configurationLevel === ConfigurationLevel.SITE
                ? `configurationType=${ResourceType.ROOM.name.toLocaleLowerCase()}&configurationLevel=${
                      query.configurationLevel
                  }&configurationLevelEntityId=${query.configurationLevelEntityId.toString()}`
                : `configurationType=${ResourceType.ROOM.name.toLocaleLowerCase()}&configurationLevel=${
                      query.configurationLevel
                  }`;

        LoggingService.Debug('Sending Booking.getConfiguration query. Query: ', deskConfigurationQuery);
        LoggingService.Debug('Sending Booking.getConfiguration query. Headers: ', ConfigurationApi.PersistedHeaders);

        return ConfigurationApi.get<ConfigurationResponse>(
            `${this.BuildBaseUrl()}/Configuration/effective?${deskConfigurationQuery}`
        );
    };
}
