import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import icons from 'assets/icons/deskInfoGrid';
import { ProfileCompanyAttributes } from 'features/Authentication/types';
import { isEmpty } from 'lodash';
import { UserRole } from 'services/ApiClients/Identity/Models';
import {
    SpecifiedCompanyAttributesResponse,
    SpecifiedCompanyAttributesResponseValues,
} from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { PersonById } from 'services/ApiClients/Person/Models/PersonById';

import './List.scss';

interface UserRestrictionProps {
    users?: PersonById[];
    userProfileId?: string;
    roles?: UserRole[];
    cbRoles?: string[];
    companyAttributes?: SpecifiedCompanyAttributesResponse[];
    cbCompanyAttributes?: ProfileCompanyAttributes[];
    isFromSliderView?: boolean;
}

interface AttributesToDisplay {
    attributeName: string;
    attributeValue: string;
    id: string;
}

const UserRestriction = ({
    users = [],
    userProfileId = '',
    roles = [],
    cbRoles = [],
    companyAttributes = [],
    cbCompanyAttributes = [],
    isFromSliderView = false,
}: UserRestrictionProps): JSX.Element => {
    const [t] = useTranslation();

    const isRestrictedAttribute = (
        specifiedCompanyAttributes: SpecifiedCompanyAttributesResponse[],
        profileCompanyAttributes: ProfileCompanyAttributes[]
    ): boolean => {
        const profileCompanyAttributesIds = profileCompanyAttributes.map(
            ({ CompanyAttributeId }) => CompanyAttributeId
        );
        const isAttributeExist = specifiedCompanyAttributes.some(({ id }) => profileCompanyAttributesIds.includes(id));

        if (isAttributeExist) {
            const profileCompanyAttributesValues = profileCompanyAttributes?.reduce((acc, el) => {
                // eslint-disable-next-line no-param-reassign
                acc = [...acc, ...el.CompanyAttributeValues];
                return acc;
            }, [] as string[]);
            const specifiedCompanyAttributesValues = specifiedCompanyAttributes.reduce((acc, el) => {
                // eslint-disable-next-line no-param-reassign
                acc = [...acc, ...el.values];
                return acc;
            }, [] as SpecifiedCompanyAttributesResponseValues[]);

            return specifiedCompanyAttributesValues.some(({ id }) => profileCompanyAttributesValues.includes(id));
        }
        return false;
    };

    const isIdExists =
        users.some(({ id }) => id === userProfileId) ||
        roles.some(({ roleId }) => cbRoles.includes(roleId.toString())) ||
        isRestrictedAttribute(companyAttributes, cbCompanyAttributes);

    const attributesToDisplay: AttributesToDisplay[] = companyAttributes.reduce(
        (accumulator, attribute: SpecifiedCompanyAttributesResponse) => {
            attribute.values.reduce((acc, value) => {
                // eslint-disable-next-line no-param-reassign
                acc = { attributeName: attribute.name, attributeValue: value.name, id: value.id };
                accumulator.push(acc);
                return acc;
            }, {} as AttributesToDisplay);

            return accumulator;
        },
        [] as AttributesToDisplay[]
    );

    attributesToDisplay.sort((a, b) => {
        const nameA = a.attributeName.toLowerCase();
        const nameB = b.attributeName.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return (
        <div className="restrictions">
            <img src={isIdExists ? icons.restrictedToIcon : icons.restrictedIcon} alt="restrictions" />
            <div className="content">
                <span className="title">{t('ResourceInfo_Restrictions')}</span>
                <ul className={`restrictions-list ${isFromSliderView ? 'slider-view' : ''}`}>
                    {!isEmpty(users) &&
                        users.map((user) => (
                            <li key={user.id}>
                                {t('HistoryTable_User')}: {user.firstName} {user.surname}
                            </li>
                        ))}
                    {!isEmpty(roles) &&
                        roles.map((role: UserRole) => (
                            <li key={role.roleId.toString()}>
                                {t('HistoryTable_Role')}: {role.name}
                            </li>
                        ))}
                    {!isEmpty(attributesToDisplay) &&
                        attributesToDisplay.map((attribute: AttributesToDisplay) => (
                            <li key={attribute.id}>
                                {attribute.attributeName}: {attribute.attributeValue}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

export default memo(UserRestriction);
