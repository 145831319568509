import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from 'components';
import { ConfigurationLevel } from 'services/ApiClients/Configuration';
import { findResourceNameByValue } from 'services/ApiClients/Resource';
import DateTimeService from 'services/DateTimeService';
import Guid from 'utilities/guid';

// eslint-disable-next-line import/no-cycle
import { ProviderProps } from '.';

const Provider = ({
    children,
    getBooking,
    booking,
    resources,
    getSiteConfiguration,
    getTenantConfiguration,
    getResources,
    site,
    areas,
    getAreas,
    setDate,
    selectedDate,
    resourceTypes,
    operationInProgress,
    isLoadingError,
    resource,
    getSingleBookingResource,
    getRepeatOccurrences,
}: React.PropsWithChildren<ProviderProps>): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const loadAreas = (): void => {
        if (!DateTimeService.isSelectedDateBookable(selectedDate)) {
            setDate(DateTimeService.getNextBookableDate(selectedDate));
        }

        const siteId = site?.id as Guid;
        if (areas[siteId?.toString()]) {
            return;
        }

        getAreas(siteId);
    };

    const loadConfiguration = (): void => {
        const configurationQuery = {
            configurationLevel: ConfigurationLevel.SITE,
            configurationLevelEntityId: site?.id as Guid,
        };

        const tenantConfigurationQuery = {
            configurationLevel: ConfigurationLevel.TENANT,
            configurationLevelEntityId: '',
        };

        getSiteConfiguration(configurationQuery);
        getTenantConfiguration(tenantConfigurationQuery);
    };

    const loadResources = (): void => {
        if (!booking) {
            return;
        }

        const { geographicStructures } = booking.resources[0];
        if (!geographicStructures || !geographicStructures.length) {
            return;
        }

        const bookedResourceArea = geographicStructures[0].geographicStructureId;
        if (resources[bookedResourceArea.toString()]) {
            return;
        }

        getResources({ geographicStructureIds: [bookedResourceArea], resourceTypes });
    };

    const loadBooking = (): void => {
        getBooking({ bookingId: new Guid(id) });
    };

    useEffect(() => {
        loadConfiguration();
        loadAreas();
        loadResources();
        loadBooking();
    }, []);

    useEffect(() => {
        if (booking && !resource) {
            const { resourceId, resourceType } = booking?.resources[0];
            getSingleBookingResource({
                resourceId,
                resourceType: findResourceNameByValue(resourceType),
            });
        }

        if (booking && (booking.isRepeat || booking.repeatBookingId)) {
            getRepeatOccurrences({ bookingId: new Guid(booking.repeatBookingId?.toString() || booking.id.toString()) });
        }
    }, [booking?.id, booking?.isRepeat]);

    if (!Guid.isValid(id) && !operationInProgress) {
        return <ErrorMessage title={t('Booking_InvalidTitle')} message={t('Booking_InvalidMessage')} />;
    }

    if (!booking && !operationInProgress) {
        return <ErrorMessage title={t('Booking_NotFountTitle')} message={t('Booking_NotFoundMessage')} />;
    }

    if (isLoadingError) {
        return <ErrorMessage title={t('Booking_RetrieveErrorTitle')} message={t('Booking_RetrieveErrorMessage')} />;
    }

    return <>{children}</>;
};

export default Provider;
