import { useEffect } from 'react';

const usePortal = (): HTMLElement => {
    const bodyElement: HTMLBodyElement | null = document.querySelector('body');
    const modalRootElement = document.createElement('div');

    useEffect(() => {
        bodyElement?.appendChild(modalRootElement);

        return () => {
            bodyElement?.removeChild(modalRootElement);
        };
    }, [bodyElement, modalRootElement]);

    return modalRootElement;
};

export default usePortal;
