import { ResourceCompanyAttributes } from 'features/Authentication/types';
// eslint-disable-next-line import/no-cycle
import { BookedResourceSummary } from 'services/ApiClients/Booking';
import Guid from 'utilities/guid';

export interface Restriction {
    users: string[];
    roles: string[];
    companyAttributes: ResourceCompanyAttributes[];
}
export interface ResourceImage {
    imageUrl: string;
    id: Guid;
    isDeskImage: boolean;
    isRoomImage: boolean;
    name: string;
    siteId: Guid;
    uploadedDateTime: Date;
}

export interface ResourceEquipment {
    id: string;
    name: string;
    description: string;
    category: string;
}

export enum UnavailableCategory {
    EarlyCheckIn,
    Covid,
    Cleaning,
    Setup,
    Cleardown,
}

export enum UnavailableReportCategoryEnum {
    EarlyCheckIn = 'EARLY_CHECK_IN',
    Covid = 'COVID',
    Cleaning = 'CLEANING',
    Setup = 'SETUP',
    Cleardown = 'CLEARDOWN',
}

export interface Resource {
    id: Guid;
    autoCurtailTime: number;
    resourceType: number;
    name: string;
    description: string;
    geographicStructureId: Guid;
    disabledAccess: boolean;
    pinSize: number;
    sortOrder: number;
    xCoordinate: number;
    yCoordinate: number;
    enabled: boolean;
    equipment: ResourceEquipment[];
    unavailableUntilFurtherNotice: boolean;
    unavailableUntilFurtherNoticeCategory?: UnavailableCategory;
    setupTimeInSeconds: number;
    cleardownTimeInSeconds: number;
    restrictedTo: Restriction;
    restricted: boolean | null;
    noticePeriodUnit: number | null;
    noticePeriodDuration: number | null;
    resourceImageId: Guid;
    attributes: ResourceCompanyAttributes[];
}

export interface FloorPin extends Resource {
    bookings: BookedResourceSummary[];
    floorPinStatus?: string;
    isPartOfRepeat?: boolean;
    isHighlighting?: boolean;
}

export interface CombinedResource extends Resource {
    capacity: number;
    siteId: Guid;
}

export interface ResourceRequest {
    geographicStructureIds: Guid[];
    resourceTypes: number[];
}

export interface GetBookingResourceQuery {
    resourceId: Guid;
    resourceType: string;
}
