import * as constants from 'features/constants';
import { combineReducers } from 'redux';
import { ResourceAvailabilityResponse } from 'services/ApiClients/Booking/Models';
import { createReducer } from 'typesafe-actions';

import {
    getResourcesAvailabilityAsync,
    resetResourcesAvailability,
    setResourcesAvailabilityLoadStatus,
} from '../actions';

export interface AvailabilityState {
    loadStatus: string;
    availability: ResourceAvailabilityResponse | null;
}

export default combineReducers<AvailabilityState>({
    loadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setResourcesAvailabilityLoadStatus], () => constants.LOAD_STATUSES.REQUIRED)
        .handleAction([getResourcesAvailabilityAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([getResourcesAvailabilityAsync.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([getResourcesAvailabilityAsync.failure], () => constants.LOAD_STATUSES.FAILED),

    availability: createReducer<ResourceAvailabilityResponse | null>(null)
        .handleAction([getResourcesAvailabilityAsync.success], (_state, action) => action.payload)
        .handleAction([getResourcesAvailabilityAsync.failure, resetResourcesAvailability], () => null),
});
