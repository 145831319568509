import React from 'react';
import { ReactComponent as DangerIcon } from 'assets/icons/DangerIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/WarningIcon.svg';

import './AlertBox.scss';

export enum AlertBoxType {
    Info,
    Warning,
    Danger,
}

export interface AlertBoxProps {
    type: AlertBoxType;
}

const AlertBox = ({ type, children }: React.PropsWithChildren<AlertBoxProps>): JSX.Element => {
    const Icon = (): JSX.Element => {
        switch (type) {
            case AlertBoxType.Warning:
                return <WarningIcon />;
            case AlertBoxType.Danger:
                return <DangerIcon />;
            default:
                return <InfoIcon />;
        }
    };

    return (
        <div className="alertBox">
            <Icon />
            <div className="message">{children}</div>
        </div>
    );
};

export default AlertBox;
