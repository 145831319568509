import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { TIMELINE_TIME_UPDATE_IN_MILLISECONDS } from 'features/constants';
import { DateTime } from 'luxon';

import { getSelectedCard } from '../utils';

import TimeSlotCardСomponent, { CardProps } from './TimeSlotCard';

import './Timeline.scss';

interface TimelineProps {
    timeline: CardProps[];
    selectedDate: DateTime;
    timezone: string;
    handleTimeSlotChange: (value: any) => void;
}

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const RightArrow = (): JSX.Element => {
    const { scrollNext } = useContext(VisibilityContext);

    return <span className="arrow-selector" onClick={() => scrollNext()} />;
};

const LeftArrow = (): JSX.Element => {
    const { scrollPrev } = useContext(VisibilityContext);

    return <span className="arrow-selector left" onClick={() => scrollPrev()} />;
};

const Timeline = ({ timeline, selectedDate, timezone, handleTimeSlotChange }: TimelineProps): JSX.Element => {
    const [selectedCard, setSelectedCard] = useState<CardProps>(getSelectedCard(timeline, selectedDate, timezone));
    const apiRef = useRef({} as scrollVisibilityApiType);
    const { items } = apiRef?.current;

    const scrollToItem = (label: string): void => {
        const element = apiRef.current?.getItemElementById(label) || ({} as Element);
        apiRef.current?.scrollToItem(element);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const updatedCard = getSelectedCard(timeline, DateTime.now().setZone(timezone), timezone);
            if (updatedCard.label !== selectedCard.label) {
                setSelectedCard(updatedCard);
                scrollToItem(updatedCard.label);
            }
        }, TIMELINE_TIME_UPDATE_IN_MILLISECONDS);
        return () => {
            clearInterval(interval);
        };
    }, [selectedCard]);

    useEffect(() => {
        handleTimeSlotChange(selectedCard);
    }, [selectedCard]);

    useEffect(() => {
        scrollToItem(selectedCard.label);
    }, [items?.size]);

    useEffect(() => {
        const newSelectedCard = getSelectedCard(timeline, selectedDate, timezone);
        setSelectedCard(newSelectedCard);
        handleTimeSlotChange(newSelectedCard);
    }, [timeline, selectedDate]);

    const getCardWidth = (): number => {
        const timelineWidth = apiRef.current?.scrollContainer?.current?.offsetWidth || 0;
        return timelineWidth / timeline.length;
    };

    const handleCardClick = (label: string): void => {
        setSelectedCard(timeline.find((item) => item.label === label) || ({} as CardProps));
    };

    return (
        <ScrollMenu apiRef={apiRef} RightArrow={RightArrow} LeftArrow={LeftArrow}>
            {timeline.map((item: CardProps) => (
                <TimeSlotCardСomponent
                    key={item.label}
                    itemId={item.label}
                    timeSlot={item}
                    onClick={handleCardClick}
                    cardWidth={getCardWidth()}
                    selected={selectedCard.label === item.label}
                />
            ))}
        </ScrollMenu>
    );
};

export default Timeline;
