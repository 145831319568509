import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserReportFilterOptions } from 'services/ApiClients/Reports/Models/UserReportFilterOptions';

import { Select } from '../../../components/Forms';
import WithLoading from '../../../components/HOC/WithLoading';
import { mapListItems } from '../../../utilities/selectList';
import { AllListItemOption } from '../../constants';

interface UserReportFilterFormProps {
    setReportsFilter: Function;
    reportsFilter: any;
    getFilterOptions: Function;
    userReportFilterOptions: UserReportFilterOptions | null;
    isGetFiltersInProgress: boolean;
}

export const statusListItems = [
    AllListItemOption,
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' },
];

const UserReportFilterForm = ({
    setReportsFilter,
    reportsFilter,
    getFilterOptions,
    userReportFilterOptions,
    isGetFiltersInProgress,
}: UserReportFilterFormProps): JSX.Element => {
    const { t } = useTranslation();

    useEffect(() => {
        getFilterOptions();

        setReportsFilter({
            role: AllListItemOption,
            status: AllListItemOption,
            company: AllListItemOption,
        });
    }, []);

    const roleListItems = useMemo(
        () =>
            [AllListItemOption].concat(
                mapListItems(
                    userReportFilterOptions?.roles || [],
                    (item: any) => item.id,
                    (item: any) => item.name
                )
            ),
        [userReportFilterOptions?.roles]
    );

    const companyListItems = useMemo(
        () =>
            [AllListItemOption].concat(
                mapListItems(
                    userReportFilterOptions?.companies || [],
                    (item: string) => item,
                    (item: string) => item
                )
            ),
        [userReportFilterOptions?.companies]
    );

    return (
        <WithLoading isLoading={isGetFiltersInProgress}>
            <Select
                label={t('Reports_Role')}
                name="role"
                dataTestId="role"
                items={roleListItems}
                onChange={(role) => {
                    setReportsFilter({ ...reportsFilter, role });
                }}
                value={reportsFilter.role}
            />
            <Select
                label={t('Reports_Status')}
                name="status"
                dataTestId="status"
                items={statusListItems}
                onChange={(status) => {
                    setReportsFilter({ ...reportsFilter, status });
                }}
                value={reportsFilter.status}
            />
            <Select
                label={t('Reports_Company')}
                name="company"
                dataTestId="company"
                items={companyListItems}
                onChange={(company) => {
                    setReportsFilter({ ...reportsFilter, company });
                }}
                value={reportsFilter.company}
            />
        </WithLoading>
    );
};

export default UserReportFilterForm;
