import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { CardProps } from '../Timeline/TimeSlotCard';

import './AllDayTimeline.scss';

interface AllDayTimelineProps {
    handleTimeSlotChange: (value: CardProps) => void;
    startTime: DateTime;
    endTime: DateTime;
}

const AllDayTimeline = ({ handleTimeSlotChange, startTime, endTime }: AllDayTimelineProps): JSX.Element => {
    const { t } = useTranslation();
    const label = t('Timeline_All_Day_Booking');

    useEffect(() => {
        handleTimeSlotChange({ label, startTime, endTime });
    }, []);

    return <div className="time-label-container">{label}</div>;
};

export default AllDayTimeline;
