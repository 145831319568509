import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Chip from 'components/Chip';

import './GridFilterPanel.scss';

export interface GridFilterPanelProps {
    advancedFilters: any[];
    filterPanelOpen: boolean;
}

const GridFilterPanel = ({ advancedFilters, filterPanelOpen }: GridFilterPanelProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={classNames('gridFilterPanel', { open: filterPanelOpen })}>
            <div className="tooltip" />
            <p className="filterTitle">{t('BookingGridFilterPanel_FiltersAdded')}:</p>
            {advancedFilters.map(({ name, onClose, id }) => (
                <Chip key={name} onClick={() => onClose(id)} name={name} />
            ))}
        </div>
    );
};

export default memo(GridFilterPanel);
