import React, { useCallback, useEffect, useState } from 'react';
import { TextBox } from 'components/FormElements';
import { EnterKey } from 'features/constants';
import { debounce } from 'lodash';

// eslint-disable-next-line import/no-cycle
import { CompanySearchData } from '../index';
// eslint-disable-next-line import/no-cycle
import SearchResult from '../SearchResult/SearchResult';

import './SearchForm.scss';

interface SearchFromProps {
    setSearchPanelOpen: Function;
    onSelect: Function;
    onSearch: Function;
    searchData: CompanySearchData;
    onSearchDataChange: Function;
    searchResult: any[] | null;
    resultPlaceHolder: string;
    searchFieldPlaceholder: string;
}
const SearchFrom = ({
    searchData,
    onSearchDataChange,
    onSearch,
    onSelect,
    searchResult,
    resultPlaceHolder,
    setSearchPanelOpen,
    searchFieldPlaceholder,
}: SearchFromProps): JSX.Element => {
    const [lastSearchData, setLastSearchData] = useState<CompanySearchData>({} as CompanySearchData);
    const debounceSearch = useCallback(
        debounce((onSearchFunc: Function) => onSearchFunc(), 1000),
        []
    );

    const search = useCallback((data: CompanySearchData) => {
        debounceSearch.cancel();
        debounceSearch(() => onSearch(data));
        setLastSearchData(data);
    }, []);

    useEffect(() => {
        debounceSearch.cancel();
    }, []);

    const onKeyPress = (e: any): void => {
        if (e.key === EnterKey) {
            debounceSearch.cancel();
            setLastSearchData(searchData);
            onSearch(searchData);
        }
    };

    return (
        <div className="company-search-form">
            <div className="search-fields">
                <TextBox
                    className="search-field"
                    hideLabel
                    value={searchData?.name || ''}
                    onChange={(value: string) => {
                        onSearchDataChange({ name: value });
                        search({ ...searchData, name: value });
                    }}
                    dataTestId="search-field"
                    placeholder={searchFieldPlaceholder}
                    type="search"
                    maxLength={100}
                    onKeyPress={onKeyPress}
                />
            </div>

            <SearchResult
                lastSearchData={lastSearchData}
                onSelect={onSelect}
                searchResult={searchResult}
                setSearchPanelOpen={setSearchPanelOpen}
                resultPlaceHolder={resultPlaceHolder}
            />
        </div>
    );
};

export default SearchFrom;
