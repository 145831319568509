import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import './TextBox.scss';

export interface TextBoxProps extends InputWrapperProps {
    validation?: any;
    error?: string;
    initialValue: string;
    maxLength?: number;
    rows?: number;
    isPassword?: boolean;
    hidePassword?: boolean;
    showTogglePasswordButton?: boolean;
    isDisabled?: boolean;
    toggleHidePassword?: () => void;
    type?: string;
    placeholder?: string;
    horizontal?: boolean;
    autoComplete?: string;
    dataTestId?: string;
    setValue?: any;
    clearErrors?: any;
}

export default (props: TextBoxProps): JSX.Element => {
    const {
        label,
        name,
        className,
        hideLabel,
        validation,
        dataTestId,
        initialValue,
        maxLength,
        isDisabled,
        rows = 1,
        isPassword = false,
        hidePassword = null,
        showTogglePasswordButton = true,
        toggleHidePassword = null,
        error,
        type,
        placeholder,
        horizontal,
        autoComplete = 'off',
        setValue,
        clearErrors,
    } = props;

    const [hasValue, setHasValue] = React.useState<boolean>(initialValue?.length > 0);
    const [inputValue, setInputValue] = React.useState<string>(initialValue);
    const [hidePasswordState, setHidePasswordState] = React.useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (setValue) {
            setValue(name, initialValue);
        }
    }, [initialValue]);

    const handleOnChange = (e: any): void => {
        const newValue = e.target.value;

        if (clearErrors && newValue.length > 0) {
            clearErrors(name);
        }
        setHasValue(newValue?.length > 0);
        setInputValue(newValue);
        if (setValue) {
            setValue(name, newValue);
        }
    };

    const togglePassword = (): void => {
        if (toggleHidePassword) {
            toggleHidePassword();
        } else if (setHidePasswordState) {
            setHidePasswordState(!hidePasswordState);
        }
    };

    useEffect(() => {
        if (isPassword && hidePassword === null) {
            setHidePasswordState(true);
        }
    }, []);

    const showPasswordLabel = t('TogglePasswordButton_ShowLabel');
    const hidePasswordLabel = t('TogglePasswordButton_HideLabel');

    return (
        <InputWrapper
            label={label}
            name={name}
            className={classnames('TextBoxElement', className)}
            hideLabel={hideLabel}
            required={validation?.required}
            error={error}
            horizontal={horizontal}
        >
            {showTogglePasswordButton && isPassword && (
                <button type="button" onClick={togglePassword}>
                    <span>{hidePassword || hidePasswordState ? showPasswordLabel : hidePasswordLabel}</span>
                </button>
            )}
            {!rows || rows === 1 ? (
                <input
                    name={name}
                    className={classnames({ hasValue, error })}
                    disabled={isDisabled}
                    onChange={handleOnChange}
                    maxLength={maxLength}
                    data-testid={dataTestId}
                    type={type || (isPassword && (hidePassword || hidePasswordState) ? 'password' : 'text')}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    value={inputValue}
                />
            ) : (
                <textarea
                    name={name}
                    className={classnames({ hasValue, error: !!error })}
                    disabled={isDisabled}
                    onChange={handleOnChange}
                    maxLength={maxLength}
                    rows={rows}
                    data-testid={dataTestId}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    value={inputValue}
                />
            )}
        </InputWrapper>
    );
};
