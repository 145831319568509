import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';

import { Visitor } from '../../services/ApiClients/VisitorManagement/Models/Visitor';

import {
    cancelMultiDaySiteVisit,
    cancelSiteVisit,
    checkInSiteVisit,
    checkOutSiteVisit,
    editExternalVisitorFromReception,
    getEvacuationReports,
    getSiteVisits,
} from './Redux/actions';
// eslint-disable-next-line import/no-cycle,
import Reception from './Reception';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    selectedSiteTimezone: state.filters.global.site?.timezone,
    areSiteVisitsLoading: state.reception.areSiteVisitsLoading,
    siteVisits: state.reception.siteVisits,
    selectedSite: state.filters.global.site,
    isGetReportsInProgress: state.reception.isGetReportsInProgress,
    isVisitorEditingInProgress: state.reception.isVisitorEditingInProgress,
    hourFormat: state.authentication.user?.extendedHourFormat,
    sites: state.referenceData.sites.items,
});

const mapDispatchToProps = {
    getSiteVisits: (siteId: string, date: DateTime) => getSiteVisits.request({ siteId, date }),
    getEvacuationReports: (siteId: string) => getEvacuationReports.request(siteId),
    checkInSiteVisit: (siteVisitId: string, siteId: string, date: DateTime) =>
        checkInSiteVisit.request({ siteVisitId, getSiteVisitsRequestData: { siteId, date } }),
    checkOutSiteVisit: (siteVisitId: string, cancelFutureSiteVisits: boolean, siteId: string, date: DateTime) =>
        checkOutSiteVisit.request({ siteVisitId, cancelFutureSiteVisits, getSiteVisitsRequestData: { siteId, date } }),
    cancelSiteVisit: (siteVisitId: string, siteId: string, date: DateTime) =>
        cancelSiteVisit.request({ siteVisitId, getSiteVisitsRequestData: { siteId, date } }),
    cancelMultiDaySiteVisit: (multiDaySiteVisitId: string, siteId: string, date: DateTime) =>
        cancelMultiDaySiteVisit.request({ multiDaySiteVisitId, getSiteVisitsRequestData: { siteId, date } }),
    editExternalVisitor: (visitor: Visitor, siteId: string, date: DateTime) =>
        editExternalVisitorFromReception.request({ visitor, getSiteVisitsRequestData: { siteId, date } }),
};

export type ReceptionProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Reception);
