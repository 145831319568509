import { AxiosError } from 'axios';
import BookMode from 'features/Resources/Common/Models/BookMode';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import {
    Booking,
    CancelOrCurtailBookingCommand,
    CheckInOrCheckOutBookingCommand,
    CheckInOrCheckOutSingleOccurrence,
    ConfirmCommand,
    ConfirmSingleOccurrence,
    GetBookingQuery,
    UpdateCommand,
} from 'services/ApiClients/Booking';
import {
    AbandonSingleBooking,
    AbandonSingleOccurrence,
    CancelSingleOccurrence,
} from 'services/ApiClients/Booking/Models';
import { Occurrence } from 'services/ApiClients/Booking/Models/Occurrence';
import { PersonById } from 'services/ApiClients/Person/Models/PersonById';
import { CombinedResource, GetBookingResourceQuery, ResourceImage } from 'services/ApiClients/Resource';
import { createAction, createAsyncAction } from 'typesafe-actions';
import Guid from 'utilities/guid';

import { UserRole } from '../../../../../../services/ApiClients/Identity/Models/UserRole';
import { Person, PersonsResponse } from '../../../../../../services/ApiClients/Models';
import {
    SpecifiedCompanyAttributesQuery,
    SpecifiedCompanyAttributesResponse,
} from '../../../../../../services/ApiClients/Organization/Models/CompanyAttributes';
import { Visitor } from '../../../../../../services/ApiClients/VisitorManagement/Models/Visitor';
import { SearchData } from '../../../../../../utilities/searchUtils';

export const setBookingInfoLoadStatus = createAction('GET_COMBINED_SET_LOAD_STATUS')<string>();
export const setBookingInfoOperationInProgress = createAction('GET_COMBINED_SET_OPERATION_IN_PROGRESS')<boolean>();
export const combinedBookingDismissError = createAction('COMBINED_BOOKING_DISMISS_ERROR')<void>();
export const setUsersLoadStatus = createAction('SET_USERS_LOAD_STATUS')<string>();
export const setRolesLoadStatus = createAction('SET_ROLES_LOAD_STATUS')<string>();
export const setAttributesLoadStatus = createAction('SET_ATTRIBUTES_LOAD_STATUS')<string>();
export const resetUsers = createAction('RESET_USERS_RESTRICTIONS')<[]>();
export const resetRoles = createAction('RESET_ROLES_RESTRICTIONS')<[]>();
export const resetAttributes = createAction('RESET_ATTRIBUTES_RESTRICTIONS')<[]>();
export const resetResourceImage = createAction('RESET_RESOURCE_IMAGE')<{}>();

export const getCombinedBookingAsync = createAsyncAction(
    'GET_COMBINED_BOOKING_REQUEST',
    'GET_COMBINED_BOOKING_SUCCESS',
    'GET_COMBINED_BOOKING_FAILURE'
)<GetBookingQuery, ApiResponse<Booking>, AxiosError | ApiResponse>();

export const getSingleBookingDetailsAsync = createAsyncAction(
    'GET_SINGLE_BOOKING_INFO_DETAILS_REQUEST',
    'GET_SINGLE_BOOKING_INFO_DETAILS_SUCCESS',
    'GET_SINGLE_BOOKING_INFO_DETAILS_FAILURE'
)<GetBookingQuery, ApiResponse<Booking>, AxiosError | ApiResponse>();

export const getCombinedBookingResourceAsync = createAsyncAction(
    'GET_COMBINED_BOOKING_RESOURCE_REQUEST',
    'GET_COMBINED_BOOKING_RESOURCE_SUCCESS',
    'GET_COMBINED_BOOKING_RESOURCE_FAILURE'
)<GetBookingResourceQuery, CombinedResource, AxiosError | ApiResponse>();

export const updateCombinedBookingAsync = createAsyncAction(
    'UPDATE_COMBINED_BOOKING_REQUEST',
    'UPDATE_COMBINED_BOOKING_SUCCESS',
    'UPDATE_COMBINED_BOOKING_FAILURE'
)<UpdateCommand, ApiResponse, AxiosError | ApiResponse>();

export const updateRepeatCombinedBookingAsync = createAsyncAction(
    'UPDATE_REPEAT_COMBINED_BOOKING_REQUEST',
    'UPDATE_REPEAT_COMBINED_BOOKING_SUCCESS',
    'UPDATE_REPEATCOMBINED_BOOKING_FAILURE'
)<UpdateCommand, ApiResponse, AxiosError | ApiResponse>();

export const bookACombinedConfirmFromGridAsync = createAsyncAction(
    'BOOK_A_COMBINED_CONFIRM_FROM_GRID_REQUEST',
    'BOOK_A_COMBINED_CONFIRM_FROM_GRIDSUCCESS',
    'BOOK_A_COMBINED_CONFIRM_FROM_GRIDFAILURE'
)<ConfirmCommand, ApiResponse, AxiosError | ApiResponse>();

export const bookACombinedConfirmFromBookingInfoAsync = createAsyncAction(
    'BOOK_A_COMBINED_CONFIRM_FROM_BOOKING_INFO_REQUEST',
    'BOOK_A_COMBINED_CONFIRM_FROM_BOOKING_INFO_SUCCESS',
    'BOOK_A_COMBINED_CONFIRM_FROM_BOOKING_INFO_FAILURE'
)<ConfirmCommand, BookMode | undefined, AxiosError | ApiResponse>();

export const bookASingleOccurrenceFromBookingInfoAsync = createAsyncAction(
    'BOOK_SINGLE_OCCURRENCE_FROM_BOOKING_INFO_REQUEST',
    'BOOK_SINGLE_OCCURRENCE_FROM_BOOKING_INFO_SUCCESS',
    'BOOK_SINGLE_OCCURRENCE_FROM_BOOKING_INFO_FAILURE'
)<ConfirmSingleOccurrence, void, AxiosError | ApiResponse>();

export const cancelACombinedAsync = createAsyncAction(
    'BOOK_A_COMBINED_CANCEL_BOOKING_REQUEST',
    'BOOK_A_COMBINED_CANCEL_BOOKING_SUCCESS',
    'BOOK_A_COMBINED_CANCEL_BOOKING_FAILURE'
)<CancelOrCurtailBookingCommand, ApiResponse, AxiosError | ApiResponse>();

export const cancelPendingBookingAsync = createAsyncAction(
    'BOOK_A_COMBINED_CANCEL_PENDING_BOOKING_REQUEST',
    'BOOK_A_COMBINED_CANCEL_PENDING_BOOKING_SUCCESS',
    'BOOK_A_COMBINED_CANCEL_PENDING_BOOKING_FAILURE'
)<CancelOrCurtailBookingCommand, ApiResponse<any>, AxiosError | ApiResponse>();

export const checkInACombinedAsync = createAsyncAction(
    'CHECK_IN_A_COMBINED_BOOKING_REQUEST',
    'CHECK_IN_A_COMBINED_BOOKING_SUCCESS',
    'CHECK_IN_A_COMBINED_BOOKING_FAILURE'
)<CheckInOrCheckOutBookingCommand, ApiResponse, AxiosError | ApiResponse>();

export const checkOutACombinedAsync = createAsyncAction(
    'CHECK_OUT_A_COMBINED_BOOKING_REQUEST',
    'CHECK_OUT_A_COMBINED_BOOKING_SUCCESS',
    'CHECK_OUT_A_COMBINED_BOOKING_FAILURE'
)<CheckInOrCheckOutBookingCommand, ApiResponse, AxiosError | ApiResponse>();

export const getPersonsByIds = createAsyncAction(
    'GET_PERSONS_BY_IDS_REQUEST',
    'GET_PERSONS_BY_IDS_SUCCESS',
    'GET_PERSONS_BY_IDS_FAILURE'
)<string[], ApiResponse<PersonById[]>, AxiosError | ApiResponse>();

export const getRolesByIds = createAsyncAction(
    'GET_ROLES_BY_IDS_REQUEST',
    'GET_ROLES_BY_IDS_SUCCESS',
    'GET_ROLES_BY_IDS_FAILURE'
)<string[], ApiResponse<UserRole[]>, AxiosError | ApiResponse>();

export const getSpecifiedAttributes = createAsyncAction(
    'GET_SPECIFIED_ATTRIBUTES_REQUEST',
    'GET_SPECIFIED_ATTRIBUTES_SUCCESS',
    'GET_SPECIFIED_ATTRIBUTES_FAILURE'
)<SpecifiedCompanyAttributesQuery, ApiResponse<SpecifiedCompanyAttributesResponse[]>, AxiosError | ApiResponse>();

export const cancelSingleOccurrenceAsync = createAsyncAction(
    'CANCEL_SINGLE_OCCURRENCE_REQUEST',
    'CANCEL_SINGLE_OCCURRENCE_SUCCESS',
    'CANCEL_SINGLE_OCCURRENCE_FAILURE'
)<CancelSingleOccurrence, ApiResponse, AxiosError | ApiResponse>();

export const deleteRepeatSeriesAsync = createAsyncAction(
    'DELETE_REPEAT_SERIES_REQUEST',
    'DELETE_REPEAT_SERIES_SUCCESS',
    'DELETE_REPEAT_SERIES_FAILURE'
)<CancelOrCurtailBookingCommand, ApiResponse, AxiosError | ApiResponse>();

export const searchOboPersonInProgress = createAsyncAction(
    'SEARCH_OBO_PERSON_REQUEST',
    'SEARCH_OBO_PERSON_SUCCESS',
    'SEARCH_OBO_PERSON_FAILURE'
)<SearchData, ApiResponse<PersonsResponse>, AxiosError | ApiResponse<Person[]>>();

export const searchInternalOboPersons = createAsyncAction(
    'SEARCH_INTERNAL_OBO_PERSONS_REQUEST',
    'SEARCH_INTERNAL_OBO_PERSONS_SUCCESS',
    'SEARCH_INTERNAL_OBO_PERSONS_FAILURE'
)<SearchData, ApiResponse<PersonsResponse>, AxiosError | ApiResponse<Person[]>>();

export const createVisitor = createAsyncAction(
    'CREATE_VISITORS_REQUEST',
    'CREATE_VISITORS_SUCCESS',
    'CREATE_VISITORS_FAILURE'
)<Visitor, void, AxiosError>();

export const editVisitor = createAsyncAction('EDIT_VISITORS_REQUEST', 'EDIT_VISITORS_SUCCESS', 'EDIT_VISITORS_FAILURE')<
    Visitor,
    void,
    AxiosError
>();

export const resetSearchOboPersonsResult = createAction('SEARCH_OBO_PERSONS_RESULT_RESET')();

export const searchExternalOboPersons = createAsyncAction(
    'SEARCH_EXTERNAL_OBO_PERSONS_REQUEST',
    'SEARCH_EXTERNAL_OBO_PERSONS_SUCCESS',
    'SEARCH_EXTERNAL_OBO_PERSONS_FAILURE'
)<SearchData, ApiResponse<PersonsResponse>, AxiosError | ApiResponse<Person[]>>();

export const abandonBookingAsync = createAsyncAction(
    'ABANDON_BOOKING_REQUEST',
    'ABANDON_BOOKING_SUCCESS',
    'ABANDON_BOOKING_FAILURE'
)<AbandonSingleBooking, void, AxiosError>();

export const abandonSingleOccurrenceAsync = createAsyncAction(
    'ABANDON_SINGLE_OCCURRENCE_REQUEST',
    'ABANDON_SINGLE_OCCURRENCE_SUCCESS',
    'ABANDON_SINGLE_OCCURRENCE_FAILURE'
)<AbandonSingleOccurrence, void, AxiosError>();

export const navigateFromInfoPage = createAction('NAVIGATE_FROM_INFO_PAGE')<void | string>();
export const resetNotSavedBookingInfo = createAction('RESET_NOT_SAVED_BOOKING_INFO')<void>();

export const getRepeatOccurrences = createAsyncAction(
    'GET_REPEAT_OCCURRENCES_REQUEST',
    'GET_REPEAT_OCCURRENCES_SUCCESS',
    'GET_REPEAT_OCCURRENCES_FAILURE'
)<GetBookingQuery, ApiResponse<Occurrence[]>, AxiosError>();

export const getParentBooking = createAction('GET_PARENT_BOOKING_REQUEST')<GetBookingQuery>();

export const checkInSingleOccurrence = createAsyncAction(
    'CHECK_IN_SINGLE_OCCURRENCE_REQUEST',
    'CHECK_IN_SINGLE_OCCURRENCE_SUCCESS',
    'CHECK_IN_SINGLE_OCCURRENCE_FAILURE'
)<CheckInOrCheckOutSingleOccurrence, void, AxiosError>();

export const checkOutSingleOccurrence = createAsyncAction(
    'CHECK_OUT_SINGLE_OCCURRENCE_REQUEST',
    'CHECK_OUT_SINGLE_OCCURRENCE_SUCCESS',
    'CHECK_OUT_SINGLE_OCCURRENCE_FAILURE'
)<CheckInOrCheckOutSingleOccurrence, void, AxiosError>();

export const getResourceImage = createAsyncAction(
    'GET_RESOURCE_IMAGE_REQUEST',
    'GET_RESOURCE_IMAGE_SUCCESS',
    'GET_RESOURCE_IMAGE_FAILURE'
)<Guid, ApiResponse<ResourceImage>, AxiosError>();

export const getTenantCompanyAttributes = createAsyncAction(
    'GET_TENANT_ATTRIBUTES_REQUEST',
    'GET_TENANT_ATTRIBUTES_SUCCESS',
    'GET_TENANT_ATTRIBUTES_FAILURE'
)<void, ApiResponse<SpecifiedCompanyAttributesResponse[]>, AxiosError | ApiResponse>();
