import React from 'react';
import classnames from 'classnames';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

export interface TextBoxProps extends InputWrapperProps {
    value: string;
    maxLength?: number;
    onChange?: (text: string) => void;
    disabled?: boolean;
    rows?: number;
    password?: boolean;
    type?: string;
    placeholder?: string;
    onKeyPress?: (e: any) => void;
    contentEditable?: boolean;
    dataTestId: string;
}

export default (props: TextBoxProps): JSX.Element => {
    const {
        label,
        inputName,
        className,
        hideLabel,
        required,
        errorText,
        isError,
        dataTestId,
        value,
        maxLength,
        onChange,
        disabled,
        rows = 1,
        password = false,
        type,
        placeholder,
        onKeyPress,
        contentEditable = true,
    } = props;

    const [text, setText] = React.useState(value || '');

    React.useEffect(() => {
        setText(value || '');
    }, [value]);

    const handleOnChange = (e: any): void => {
        const newValue = e.target.value;
        setText(newValue);
        if (onChange) {
            onChange(e.target.value);
        }
    };

    const handleKeyDown = (event: any): void => {
        if (event.key === 'Enter') {
            event.stopPropagation();
        }
        if (onKeyPress) {
            onKeyPress(event);
        }
    };

    return (
        <InputWrapper
            label={label}
            inputName={inputName}
            className={className}
            hideLabel={hideLabel}
            required={required}
            errorText={errorText}
            isError={isError}
        >
            {!rows || rows === 1 ? (
                <input
                    className={classnames({ hasValue: !!text, error: isError })}
                    value={text}
                    disabled={disabled}
                    onChange={handleOnChange}
                    maxLength={maxLength}
                    data-testid={dataTestId}
                    type={type || (password ? 'password' : 'text')}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    contentEditable={contentEditable}
                />
            ) : (
                <textarea
                    className={classnames({ hasValue: !!text, error: isError })}
                    value={text}
                    disabled={disabled}
                    onChange={handleOnChange}
                    maxLength={maxLength}
                    rows={rows}
                    data-testid={dataTestId}
                    placeholder={placeholder}
                    onKeyPress={onKeyPress}
                />
            )}
        </InputWrapper>
    );
};
