import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';

export const ApplicationName = 'CBPortal';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message',
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out',
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
};

const identityServerBaseUrl = `${getConfiguration().IDENTITY_SERVICE}/`;
const prefix = `/authentication`;

export const getPortalUrl = (): string => {
    return `${window.location.origin}/`;
};

export const TENANT_IDENTIFIER_KEY = 'tenantIdentifier';

export const getPortalIdentifier = (): [string, boolean] => {
    const portalUrlString = getPortalUrl();
    const portalUrl = new URL(portalUrlString);
    let identifier = '';
    let fromUri = true;

    const path = window.location.pathname;
    const devAksSubDomain = '.kbdev.cloudbooking.com';

    if (portalUrl.hostname === getConfiguration().PORTAL_DOMAIN || portalUrl.hostname.indexOf(devAksSubDomain) > 0) {
        [, identifier] = path.split('/');
    } else {
        identifier = portalUrl.hostname;
        fromUri = false;
        if (identifier.endsWith(devAksSubDomain)) {
            identifier = identifier.replace(devAksSubDomain, '');
            identifier =
                identifier.indexOf('.') === -1 ? identifier : identifier.substr(identifier.lastIndexOf('.') + 1);
        } else {
            identifier = identifier.substr(0, identifier.indexOf('.'));
        }
    }

    LoggingService.Info(`Tenant Identifier discoverd as ${identifier}`);

    return [identifier, fromUri];
};

export const ApplicationPaths = {
    DefaultLoginRedirectPath: getPortalUrl(),
    ApiAuthorizationClientConfigurationUrl: `${identityServerBaseUrl}_configuration/CB.Portal`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: `${identityServerBaseUrl}Identity/Account/Register`,
    IdentityManagePath: `${identityServerBaseUrl}/Identity/Account/Manage`,
};
