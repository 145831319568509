import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { ReactComponent as Circle } from '../../assets/icons/Circle.svg';
import { ReactComponent as Tick } from '../../assets/icons/Tick.svg';
import { RequiredLabel } from '../FormElements/InputWrapper/Components';

import './RadioButtonSelector.scss';

interface RadioButtonItems {
    value: string | number;
    label: string;
    dataTestId?: string;
}

interface RadioButtonSelectorProps {
    items: RadioButtonItems[];
    title?: string;
    handleChange: Function;
    required?: boolean;
    tickView?: boolean;
    value: string | number | null;
    isDisabled?: boolean | undefined;
}

const RadioButtonSelector = (props: RadioButtonSelectorProps): JSX.Element => {
    const { items, title, handleChange, required, value, tickView = false, isDisabled } = props;

    return (
        <div className="radio-button-selector">
            {title && (
                <div className="radio-buttons-title">
                    <RequiredLabel required={required} />
                    {title}
                </div>
            )}
            <RadioGroup row value={value} onChange={(e) => handleChange(e)}>
                {items.map((item) => (
                    <FormControlLabel
                        key={item.value}
                        value={item.value}
                        disabled={isDisabled}
                        control={
                            tickView ? (
                                <Radio
                                    data-testid={item.dataTestId}
                                    className="radio-button"
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                    checkedIcon={
                                        <div className="checked-item">
                                            <Tick />
                                        </div>
                                    }
                                    icon={
                                        <div className="unchecked-item">
                                            <Circle />
                                        </div>
                                    }
                                />
                            ) : (
                                <Radio
                                    data-testid={item.dataTestId}
                                    className="radio-button"
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                />
                            )
                        }
                        label={item.label}
                    />
                ))}
            </RadioGroup>
        </div>
    );
};

export default RadioButtonSelector;
