import axios, { AxiosResponse } from 'axios';
import AuthorizeService from 'features/Authentication/Services/AuthorizeService';
import Guid from 'utilities/guid';

import { ApiResponse } from './@Models/ApiResponse';

export default class BaseApiClient {
    // @todo: We need to refactor this so that it is using proper typescript types
    protected static PersistedHeaders: any = {};

    private static async getPersistedHeaders(): Promise<any> {
        const tenantId = await AuthorizeService.getTenantId();
        const accessToken = await AuthorizeService.getAccessToken();

        const result = this.PersistedHeaders;

        result['cb-request-id'] = Guid.newGuid().toString();
        result['cb-tenant-id'] = tenantId;

        result.Authorization = `Bearer ${accessToken}`;

        const isKubernetes = window.location.href.match(
            /https:\/\/([a-z]{2}-[a-z0-9]{4}).book-dev.kbdev.cloudbooking\.com/
        );

        if (isKubernetes) {
            const [, prefix] = isKubernetes;
            result['kubernetes-route-as'] = prefix;
        }

        return result;
    }

    private static isStatusCodeSuccessful(statusCode: number): boolean {
        // eslint-disable-next-line eqeqeq
        return statusCode / 100 == 2;
    }

    private static createResponse(response: AxiosResponse): ApiResponse {
        const isSuccessful = BaseApiClient.isStatusCodeSuccessful(response.status);

        const result: ApiResponse = {
            isComplete: true,
            isSuccess: isSuccessful,
            payload: isSuccessful ? null : response.data,
            statusCode: response.status,
        };

        return result;
    }

    private static createResponseWithData<TResponse>(response: AxiosResponse<TResponse>): ApiResponse<TResponse> {
        const result: ApiResponse<TResponse> = {
            isComplete: true,
            isSuccess: BaseApiClient.isStatusCodeSuccessful(response.status),
            payload: response.data,
            statusCode: response.status,
            headers: response.headers,
        };

        return result;
    }

    public static async get<TResponse>(url: string): Promise<ApiResponse<TResponse>> {
        const response = await axios.get<TResponse>(url, {
            headers: await this.getPersistedHeaders(),
        });

        return BaseApiClient.createResponseWithData<TResponse>(response);
    }

    public static async post<TRequest, TResponse = void>(
        url: string,
        requestBody: TRequest
    ): Promise<ApiResponse<TResponse>> {
        const response = await axios.post(url, requestBody, {
            headers: await this.getPersistedHeaders(),
        });

        return BaseApiClient.createResponseWithData(response);
    }

    public static async put<TRequest>(url: string, requestBody: TRequest): Promise<ApiResponse> {
        const response = await axios.put(url, requestBody, {
            headers: await this.getPersistedHeaders(),
        });

        return BaseApiClient.createResponse(response);
    }

    public static async patch<TRequest>(url: string, requestBody: TRequest): Promise<ApiResponse> {
        const response = await axios.patch(url, requestBody, {
            headers: this.PersistedHeaders,
        });

        return BaseApiClient.createResponse(response);
    }

    public static async delete<TRequest>(url: string, body: TRequest): Promise<ApiResponse> {
        // This is the only way I have found to get this call to work with Axios,
        // due to an issue with Axios stripping out the content-type header.
        const response = await axios({
            method: 'delete',
            url,
            headers: await this.getPersistedHeaders(),
            data: body,
        });

        return BaseApiClient.createResponse(response);
    }
}
