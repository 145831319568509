import React from 'react';
import { toast } from 'react-toastify';
import ToastBody, { IconType } from 'components/ToastBody';
import i18n from 'i18n';

export interface ToastProps {
    message: string;
    title?: string;
    onClose?(): void;
}
export default class ToastService {
    public static Success({ message, title }: ToastProps): void {
        const translatedTitle = title || i18n.t('Toast_SuccessTitle').toString();
        toast(<ToastBody title={translatedTitle} content={i18n.t(message) || message} type={IconType.Success} />);
    }

    public static Warning({ message, title, onClose = () => {} }: ToastProps): void {
        const translatedTitle = title || i18n.t('Toast_WarningTitle').toString();
        toast(
            <ToastBody
                title={translatedTitle}
                content={i18n.t(message) || message}
                type={IconType.Warning}
                closeToast={onClose}
            />,
            {
                autoClose: false,
                closeOnClick: true,
                onClose,
            }
        );
    }

    public static Error({ message, title, onClose = () => {} }: ToastProps): void {
        const translatedTitle = title || i18n.t('Toast_ErrorTitle').toString();

        toast(
            <ToastBody
                title={translatedTitle}
                content={i18n.t(message) || message}
                type={IconType.Error}
                closeToast={onClose}
            />,
            {
                autoClose: false,
                closeOnClick: true,
                onClose,
            }
        );
    }
}
