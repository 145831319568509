import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { inRange, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { BookedResourceSummary } from 'services/ApiClients/Booking';
import { UnavailableCategory } from 'services/ApiClients/Resource';
import { isNil } from 'utilities/ts';

// eslint-disable-next-line import/no-cycle
import { InfoSliderTimeLineProps } from './index';
import { getTimeLineHoursStyles, TIME_LINE_STATUSES, TWENTY_FOUR_HOURS } from './utils';

import './InfoSliderTimeLine.scss';

export interface TimeLineItem {
    hour: number;
    timeLineStatus: string;
    isBooking: boolean;
}

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const InfoSliderTimeLine = ({
    allSiteConfigurations,
    allTenantConfigurations,
    resource,
    selectedDate,
    timezone,
    is24HoursTimeFormat,
    currentResourceBookings,
    user,
}: InfoSliderTimeLineProps): JSX.Element | null => {
    const timeLineRef = useRef({} as scrollVisibilityApiType);
    const selectedDay = selectedDate.setZone(timezone).toFormat('cccc').toLowerCase();
    const specificSiteConfigurations = allSiteConfigurations[resource.resourceType];
    const specificTenantConfigurations = allTenantConfigurations[resource.resourceType];
    const bookableDay = specificSiteConfigurations.bookableDays[selectedDay];
    const specificBeginsHour = Math.floor(bookableDay?.bookingWindowStartMinutesFromMidnight / 60) || 0;
    const specificEndsHour = Math.ceil(bookableDay?.bookingWindowEndMinutesFromMidnight / 60) || 1440;
    const slotsCount = specificEndsHour - specificBeginsHour;
    const timeLineHoursArray: TimeLineItem[] = [];
    const currentTimeInHours = DateTime.now().hour;

    for (let i = 0; i <= slotsCount; i++) {
        timeLineHoursArray.push({
            hour: i + specificBeginsHour,
            timeLineStatus: TIME_LINE_STATUSES.AVAILABLE,
            isBooking: false,
        });
    }

    const scrollToItem = (id: string): void => {
        const element = timeLineRef.current?.getItemElementById(id) || ({} as Element);
        if (element && !isEmpty(element)) {
            timeLineRef.current?.scrollToItem(element, 'smooth', 'center');
        }
    };

    useEffect(() => {
        const selectedDateString = selectedDate.setZone(timezone).startOf('day').toString();
        const currentDateString = DateTime.now().setZone(timezone).startOf('day').toString();

        if (selectedDateString === currentDateString) {
            scrollToItem(currentTimeInHours.toString());
        }
    }, []);

    const LeftArrow = (): JSX.Element => {
        const { scrollPrev } = useContext(VisibilityContext);

        return (
            <div className="arrow-container left-container" onClick={() => scrollPrev()}>
                <div className="arrow left" />
            </div>
        );
    };

    const RightArrow = (): JSX.Element => {
        const { scrollNext } = useContext(VisibilityContext);
        return (
            <div className="arrow-container right-container" onClick={() => scrollNext()}>
                <div className="arrow right" />
            </div>
        );
    };

    const getTimeLineSectionStatus = (booking: BookedResourceSummary): string => {
        const isCurrentUserBooking = user?.userProfileId?.toString() === booking?.createdByUserId?.toString();
        const isOnBehalfOfUserBooking = user?.userProfileId === booking?.onBehalfOf?.personId;

        if (booking?.isPrivate || specificTenantConfigurations.setAllBookingsAsPrivate) {
            return TIME_LINE_STATUSES.PRIVATE;
        }

        if (isOnBehalfOfUserBooking || isCurrentUserBooking) {
            return TIME_LINE_STATUSES.MY_BOOKING;
        }

        if (!isOnBehalfOfUserBooking && !isCurrentUserBooking) {
            return TIME_LINE_STATUSES.OTHER_USER_BOOKING;
        }

        return TIME_LINE_STATUSES.AVAILABLE;
    };

    const updatedTimeLineHoursArray = useMemo(
        () =>
            timeLineHoursArray.map((item) => {
                if (resource.restricted) {
                    // eslint-disable-next-line no-param-reassign
                    item = { ...item, timeLineStatus: TIME_LINE_STATUSES.RESTRICTED };
                }

                if (resource.unavailableUntilFurtherNotice) {
                    // eslint-disable-next-line no-param-reassign
                    item = { ...item, timeLineStatus: TIME_LINE_STATUSES.UNAVAILABLE };
                }

                if (resource.unavailableUntilFurtherNoticeCategory === UnavailableCategory.Covid) {
                    // eslint-disable-next-line no-param-reassign
                    item = { ...item, timeLineStatus: TIME_LINE_STATUSES.UNAVAILABLE_COVID };
                }

                currentResourceBookings.forEach((booking) => {
                    const {
                        startDateTime,
                        endDateTime,
                        setupTimeInSeconds,
                        cleardownTimeInSeconds,
                        isSetupCleardownEnabled,
                    } = booking;

                    const bookingStartInHours =
                        DateTime.fromISO(startDateTime).setZone(timezone).hour +
                        DateTime.fromISO(startDateTime).setZone(timezone).minute / 60;
                    let bookingEndInHours =
                        DateTime.fromISO(endDateTime).setZone(timezone).hour +
                        DateTime.fromISO(endDateTime).setZone(timezone).minute / 60;

                    // need this as last hour in the day returns 0, not 24
                    if (bookingEndInHours === 0) {
                        bookingEndInHours = TWENTY_FOUR_HOURS;
                    }

                    const setupTimeInHours =
                        !isNil(setupTimeInSeconds) && bookingStartInHours !== 0
                            ? DateTime.fromSeconds(
                                  DateTime.fromISO(startDateTime).toSeconds() - (setupTimeInSeconds as number)
                              ).setZone(timezone).hour +
                              DateTime.fromSeconds(
                                  DateTime.fromISO(startDateTime).toSeconds() - (setupTimeInSeconds as number)
                              ).setZone(timezone).minute /
                                  60
                            : bookingStartInHours;
                    let cleardownTimeInHours =
                        !isNil(cleardownTimeInSeconds) && bookingEndInHours !== TWENTY_FOUR_HOURS
                            ? DateTime.fromSeconds(
                                  DateTime.fromISO(endDateTime).toSeconds() + (cleardownTimeInSeconds as number)
                              ).setZone(timezone).hour +
                              DateTime.fromSeconds(
                                  DateTime.fromISO(endDateTime).toSeconds() + (cleardownTimeInSeconds as number)
                              ).setZone(timezone).minute /
                                  60
                            : bookingEndInHours;

                    // need this as last hour in the day returns 0, not 24
                    if (cleardownTimeInHours === 0) {
                        cleardownTimeInHours = TWENTY_FOUR_HOURS;
                    }

                    if (
                        isSetupCleardownEnabled &&
                        inRange(item.hour, Math.floor(setupTimeInHours), Math.ceil(cleardownTimeInHours)) &&
                        !item.isBooking
                    ) {
                        // eslint-disable-next-line no-param-reassign
                        item = { ...item, timeLineStatus: TIME_LINE_STATUSES.SETUP_CLEAR_DOWN };
                    }

                    if (inRange(item.hour, Math.floor(bookingStartInHours), Math.ceil(bookingEndInHours))) {
                        // eslint-disable-next-line no-param-reassign
                        item = { ...item, timeLineStatus: getTimeLineSectionStatus(booking), isBooking: true };
                    }
                });

                return item;
            }),
        [resource]
    );

    const formatTimeLineHours = (hour: number): number | string => {
        const formatted12H = hour > 12 ? `${hour - 12} PM` : `${hour} AM`;
        const is12H = hour === 12 ? '12 PM' : formatted12H;
        const is0H = hour === 0 ? '12 AM' : is12H;
        const format = is24HoursTimeFormat ? hour : is0H;
        return hour < 24 ? format : '';
    };

    return updatedTimeLineHoursArray.length ? (
        <div className="timeline-container">
            <ScrollMenu apiRef={timeLineRef} LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {updatedTimeLineHoursArray.map((item: TimeLineItem) => (
                    <div key={item.hour} itemID={item.hour.toString()} className="timeline-item">
                        {item.hour !== specificEndsHour && (
                            <div className={`timeline-item-line timeline-item-line--${item.timeLineStatus}`} />
                        )}
                        <div
                            className={`timeline-item-hours ${getTimeLineHoursStyles(
                                item.hour,
                                specificBeginsHour,
                                specificEndsHour
                            )}`}
                        >
                            {formatTimeLineHours(item.hour)}
                        </div>
                    </div>
                ))}
            </ScrollMenu>
        </div>
    ) : null;
};

export default InfoSliderTimeLine;
