import React, { useMemo } from 'react';
import { ReactComponent as ExternalVisitorRoundedIcon } from 'assets/icons/visitor/ExternalVisitorRounded.svg';
import { ReactComponent as InternalVisitorRoundedIcon } from 'assets/icons/visitor/InternalVisitorRounded.svg';
import classnames from 'classnames';
import { RequiredLabel } from 'components/FormElements/InputWrapper/Components';
import SearchIconButton from 'components/IconButtons/SearchIconButton/SearchIconButton';
import TrashIconButton from 'components/IconButtons/TrashIconButton/TrashIconButton';
import { PersonType } from 'enums/PersonType';
import { EditMode } from 'features/constants';
import { BookingInfoDataState } from 'features/Resources/Common/EditBooking/Editor/Models/BookingInfoDataState';
import { EditVisitorsLocationState, Person } from 'services/ApiClients/Models';
import { isNil } from 'utilities/ts';

import './SelectedPerson.scss';

interface SelectedPersonProps {
    onSelect: Function;
    onSearchDataChange: Function;
    buttonLabel: string;
    dataTestId: string;
    required: boolean;
    resetSearchResult: Function;
    personType: number | null;
    setPersonDetailsPanelOpen?: Function;
    selectedPerson: Person | null;
    setSearchPanelOpen: Function;
    isDisabled?: boolean;
    selectedItemComponent?: JSX.Element;
    editVisitorBookings?: EditVisitorsLocationState;
    isPrivate?: boolean;
    editMode?: EditMode;
    isCanEditOboUserInOwnBooking?: boolean;
    isCanEditOboUserForAllBookings?: boolean;
    canViewPrivateBookings?: boolean;
    canAddOnBehalfOf?: boolean;
    isFormBookingPage?: boolean;
    handleSetBookingInfoDataState?: (data: BookingInfoDataState, debounceTrigger?: boolean) => void;
    bookingInfoDataState?: BookingInfoDataState;
}

const SelectedPerson = ({
    onSelect,
    onSearchDataChange,
    buttonLabel,
    dataTestId,
    required,
    resetSearchResult,
    personType,
    setPersonDetailsPanelOpen,
    selectedPerson,
    setSearchPanelOpen,
    isDisabled = false,
    selectedItemComponent,
    editVisitorBookings,
    isPrivate,
    editMode,
    isCanEditOboUserInOwnBooking = true,
    isCanEditOboUserForAllBookings = true,
    canViewPrivateBookings,
    canAddOnBehalfOf,
    isFormBookingPage,
    handleSetBookingInfoDataState,
    bookingInfoDataState,
}: SelectedPersonProps): JSX.Element => {
    const selectedOboPersonName = useMemo(() => {
        if (editVisitorBookings?.isEdit) {
            return editVisitorBookings?.visitorInfo;
        }
        if (isPrivate) {
            return selectedPerson?.displayName
                ? selectedPerson.displayName
                : `${selectedPerson?.firstname} ${selectedPerson?.surname}`;
        }
        return selectedPerson?.firstname && `${selectedPerson?.firstname} ${selectedPerson?.surname}`;
    }, [editVisitorBookings?.visitorInfo, isPrivate, selectedPerson]);

    const isShowOBOUser = selectedPerson || editVisitorBookings?.isEdit;

    const isShowTrashIcon =
        !editVisitorBookings?.isEdit &&
        isNil(editMode) &&
        (isCanEditOboUserInOwnBooking || isCanEditOboUserForAllBookings);

    const isDisabledOBOUser =
        !!editVisitorBookings?.isEdit ||
        editMode === EditMode.DISABLED_OCCURRENCE ||
        editMode === EditMode.ENABLE_OCCURRENCE ||
        (!(isCanEditOboUserInOwnBooking || isCanEditOboUserForAllBookings) && isNil(editMode)) ||
        (isPrivate && !canViewPrivateBookings && !isCanEditOboUserInOwnBooking && !isCanEditOboUserForAllBookings);

    const isDisabledSearchOBOUserIcon =
        isDisabled ||
        editMode === EditMode.ENABLE_OCCURRENCE ||
        (isPrivate && !(isCanEditOboUserInOwnBooking || isCanEditOboUserForAllBookings) && isNil(editMode)) ||
        (!canAddOnBehalfOf && isFormBookingPage);

    return (
        <>
            <div className="label">
                <RequiredLabel required={required} />
                {buttonLabel}
            </div>
            {isShowOBOUser ? (
                <div className="selected-person" data-testid={`${dataTestId}-selected-person`}>
                    <button
                        type="button"
                        className={classnames('icon-n-name', { clickable: setPersonDetailsPanelOpen })}
                        onClick={() => {
                            if (setPersonDetailsPanelOpen && selectedPerson?.firstname) {
                                setPersonDetailsPanelOpen(true);
                                resetSearchResult();
                            }
                        }}
                        disabled={isDisabledOBOUser}
                    >
                        {selectedItemComponent || (
                            <>
                                {personType === PersonType.Internal ? (
                                    <InternalVisitorRoundedIcon />
                                ) : (
                                    <ExternalVisitorRoundedIcon />
                                )}
                                <div className="name" data-testid="selected-person-name">
                                    {selectedOboPersonName}
                                </div>
                            </>
                        )}
                    </button>
                    {isShowTrashIcon ? (
                        <TrashIconButton
                            onClick={() => {
                                onSelect(null);
                                onSearchDataChange({ name: '', company: '' });
                                resetSearchResult();
                                if (handleSetBookingInfoDataState && bookingInfoDataState) {
                                    handleSetBookingInfoDataState({
                                        ...bookingInfoDataState,
                                        selectedPerson: null,
                                        isOBODeleted: true,
                                    });
                                }
                            }}
                            title=""
                            hideLabel
                            dataTestId="clear-selected"
                        />
                    ) : null}
                </div>
            ) : (
                <SearchIconButton
                    title={buttonLabel}
                    dataTestId={`${dataTestId}-button`}
                    hideLabel
                    isDisabled={isDisabledSearchOBOUserIcon}
                    required={required}
                    onClick={() => {
                        setSearchPanelOpen(true);
                    }}
                />
            )}
        </>
    );
};

export default SelectedPerson;
