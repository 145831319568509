import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import History from 'features/Resources/Common/EditBooking/History/History';
import actions from 'features/Resources/Common/EditBooking/Redux/actions';
import { getHistoryData } from 'features/Resources/Common/EditBooking/Redux/selectors';
import { RootState } from 'PortalTypes';
import Guid from 'utilities/guid';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    ...getHistoryData(state),
});

const mapDispatchToProps = {
    setLoadStatus: (loadingStatus: string) => actions.setCombinedBookingHistoryLoadStatus(loadingStatus),
    fetchHistoryDataById: (bookingId: Guid) => actions.getCombinedBookingHistoryDataAsync.request(bookingId),
};
export type HistoryCombinedProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(History);
