import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CardProps } from '../Timeline/TimeSlotCard';

import './HalfDayTimeline.scss';

interface HalfDayTimelineProps {
    timeline: CardProps[];
    handleTimeSlotChange: (value: CardProps) => void;
}

const HalfDayTimeline = ({ timeline, handleTimeSlotChange }: HalfDayTimelineProps): JSX.Element => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<CardProps>(timeline[0]);

    useEffect(() => {
        handleTimeSlotChange(selected);
    }, [selected]);

    const handleClick = (newSlot: CardProps): void => {
        setSelected(newSlot);
    };

    return (
        <div className="half-day-container">
            {timeline.map((item) => (
                <span
                    key={item.label}
                    className={classNames('half-day', { selected: selected.label === item.label })}
                    onClick={() => handleClick(item)}
                >
                    {t(item.label)}
                </span>
            ))}
        </div>
    );
};

export default HalfDayTimeline;
