/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { ReactComponent as DateIcon } from 'assets/icons/DatePickerCalendar.svg';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import '../Field/field.scss';

export interface SingleFieldProps {
    onClick: () => void;
    dataTestId: string;
    dateTime: DateTime;
    disabled?: boolean;
    className?: string;
}

const SingleField = ({
    onClick,
    dateTime,
    dataTestId,
    disabled = false,
    className = '',
}: React.PropsWithChildren<SingleFieldProps>): JSX.Element => {
    if (!dateTime) {
        throw new Error('Datetimes must be provided');
    }

    return (
        <div
            className={classnames(
                'dateTimePickerField',
                `${className}`,
                disabled ? 'dateTimePickerFieldDisabled' : null
            )}
            data-testid={dataTestId}
            tabIndex={0}
            onClick={onClick}
        >
            <div className="dateIcon">
                <DateIcon />
            </div>

            <div className="dateText">{dateTime.toFormat('d MMM yy')}</div>
        </div>
    );
};

export default SingleField;
