import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

import { getGeographicStructureAreas } from '../GeographicStructures/Redux/actions';

import {
    generateReportForExport,
    getUserReportFilterOptions,
    reportsResetSearchCompaniesResult,
    reportsResetSearchUsersResult,
    reportsResetSearchVisitorsResult,
    reportsSearchCompanies,
    reportsSearchExternalVisitors,
    reportsSearchInternalVisitors,
    reportsSearchUsers,
} from './Redux/actions';
// eslint-disable-next-line import/no-cycle,
import Reports from './Reports';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    reportsPermissions: state.authentication.user?.permissions?.ReportsPermissions || 0,
    tenantId: state.authentication.user?.tenantId || '',
    sites: state.referenceData.sites.items,
    siteGroups: state.referenceData.siteGroup.items,
    areas: state.referenceData.area.items,
    areasLoadStatus: state.referenceData.area.loadStatus,
    searchVisitorsResult: state.reports.searchVisitorsResult,
    isSearchVisitorsInProgress: state.reports.isSearchVisitorsInProgress,
    searchCompaniesResult: state.reports.searchCompaniesResult,
    isSearchCompaniesInProgress: state.reports.isSearchCompaniesInProgress,
    userReportFilterOptions: state.reports.userReportFilterOptions,
    isGetFiltersInProgress: state.reports.isGetFiltersInProgress,
    isSearchUsersInProgress: state.reports.isSearchUsersInProgress,
    searchUsersResult: state.reports.searchUsersResult,
});

const mapDispatchToProps = {
    searchInternalVisitors: reportsSearchInternalVisitors.request,
    searchExternalVisitors: reportsSearchExternalVisitors.request,
    searchCompanies: reportsSearchCompanies.request,
    resetVisitorSearch: reportsResetSearchVisitorsResult,
    resetCompaniesSearch: reportsResetSearchCompaniesResult,
    getAreas: getGeographicStructureAreas.request,
    getUserReportFilterOptions: getUserReportFilterOptions.request,
    generateReportForExport: generateReportForExport.request,
    resetUsersSearch: reportsResetSearchUsersResult,
    searchUsers: reportsSearchUsers.request,
};

export type ReportsProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
