import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';
import { SiteVisit } from 'services/ApiClients/VisitorManagement/Models/SiteVisit';

import { DaysConfigurationResponse } from '../../services/ApiClients/Configuration/Models';

import {
    cancelEditingSiteVisit,
    cancelEditMultiDaySiteVisit,
    createMultiDaySiteVisit,
    createSiteVisit,
    editSiteVisit,
    getBookableDays,
    getPersonById,
    setMySiteVisitsLoadStatus,
} from './Redux/actions';
// eslint-disable-next-line import/no-cycle
import VisitorBooking from './VisitorBooking';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    loggedPersonInfo: state.visitorBooking.internalPersonInfo,
    isActionInProgress: state.visitorBooking.isActionInProgress,
    isSiteVisitCreationInProgress: state.visitorBooking.isSiteVisitCreationInProgress,
    userProfile: state.authentication.user,
    selectedSite: state.filters.global.site,
    bookableDaysConfig: state.visitorBooking.bookableDays || ({} as DaysConfigurationResponse),
    cancelEditingSiteVisitLoadStatus: state.visitorBooking.cancelEditingSiteVisitLoadStatus,
    cancelEditingMultiDaySiteVisitLoadStatus: state.visitorBooking.cancelEditingMultiDaySiteVisitLoadStatus,
    editSiteVisitLoadStatus: state.visitorBooking.editSiteVisitLoadStatus,
});

const mapDispatchToProps = {
    getInternalPersonInfo: (id: string) => getPersonById.request(id),
    getBookableDays: (siteId: string) => getBookableDays.request(siteId),
    createSiteVisit: (siteVisit: SiteVisit) => createSiteVisit.request(siteVisit),
    createMultiDaySiteVisit: (siteVisit: SiteVisit) => createMultiDaySiteVisit.request(siteVisit),
    cancelEditSiteVisit: (siteVisitId: string) => cancelEditingSiteVisit.request({ siteVisitId }),
    cancelEditMultiDaySiteVisit: (multiDaySiteVisitId: string) =>
        cancelEditMultiDaySiteVisit.request({ multiDaySiteVisitId }),
    setMySiteVisitsLoadStatus,
    editSiteVisit: (editSiteVisitData: any) => editSiteVisit.request(editSiteVisitData),
};

export type VisitorBookingProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(VisitorBooking);
