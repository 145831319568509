import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ALL_DAY, AM_PM, BOOKING_SLOT_UNITS_MULTIPLIER, CUSTOM_DURATION } from 'features/constants';
import { FloorPlanTimelineFilterState } from 'features/Filters/Redux/reducer';
import { DateTime } from 'luxon';
import { CombinedConfiguration } from 'services/ApiClients/Configuration';
import DateTimeService, { TimeSlotOption } from 'services/DateTimeService';

import AllDayTimeline from './AllDayTimeline';
import CustomTimeline from './CustomTimeline';
import DurationSelector from './DurationSelector';
import DurationSlider from './DurationSlider';
import HalfDayTimeline from './HalfDayTimeline';
import Timeline from './Timeline';
import {
    buildHalfDayTimeline,
    buildTimeLine,
    DurationDateTime,
    getSlotDurations,
    getStartEndDateTimeFromConfig,
} from './utils';

import './FloorPlanHeader.scss';

interface FloorPlanHeaderProps {
    configuration: CombinedConfiguration | null;
    selectedDay: string;
    hourFormat: string;
    timezone: string;
    selectedDate: DateTime;
    gridDate: DateTime;
    handleTimeSlotChange: (newSlot: any) => void;
    handleDurationChange: (newSlot: any) => void;
    floorPlanTimelineFilter: FloorPlanTimelineFilterState;
    setFloorPlanTimeLineFilter: (param: any) => void;
    setFloorPlanTimeLineDurationFilter: (param: string | number) => void;
}

const FloorPlanHeader = ({
    configuration,
    selectedDay,
    hourFormat,
    timezone,
    selectedDate,
    gridDate,
    handleTimeSlotChange,
    handleDurationChange,
    floorPlanTimelineFilter,
    setFloorPlanTimeLineFilter,
    setFloorPlanTimeLineDurationFilter,
}: FloorPlanHeaderProps): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const slotInMinutes = useMemo(() => {
        const slotUnit = configuration?.bookingSlotUnits || 0;
        return slotUnit * BOOKING_SLOT_UNITS_MULTIPLIER;
    }, [configuration]);

    const { bookingWindowStartMinutesFromMidnight = 0, bookingWindowEndMinutesFromMidnight = 0 } =
        configuration?.bookableDays[selectedDay] || {};

    const timeSlots: TimeSlotOption[] = useMemo(() => {
        if (bookingWindowEndMinutesFromMidnight - bookingWindowStartMinutesFromMidnight !== 0) {
            return DateTimeService.getTimeSlots(
                Math.floor(bookingWindowStartMinutesFromMidnight / Number(floorPlanTimelineFilter.duration)),
                Math.ceil(bookingWindowEndMinutesFromMidnight / Number(floorPlanTimelineFilter.duration)),
                Number(floorPlanTimelineFilter.duration),
                hourFormat
            );
        }
        return [];
    }, [configuration, floorPlanTimelineFilter.duration]);

    const slotDurations = useMemo(() => getSlotDurations(slotInMinutes), [slotInMinutes]);

    const { startDateTime, endDateTime } = getStartEndDateTimeFromConfig(
        timezone,
        gridDate,
        selectedDay,
        configuration
    );

    const handleOpenSlider = (): void => {
        setOpen(!isOpen);
    };

    const onDone = (value: any, customDuration: DurationDateTime): void => {
        setFloorPlanTimeLineDurationFilter(Number.isNaN(Number(value)) ? value : Number(value));
        if (value === CUSTOM_DURATION) {
            setFloorPlanTimeLineFilter(customDuration);
        }
    };

    useEffect(() => {
        if (!Number.isNaN(Number(floorPlanTimelineFilter.duration)) && timeSlots.length) {
            setFloorPlanTimeLineFilter(
                buildTimeLine(timeSlots, floorPlanTimelineFilter.duration, timezone, selectedDate)
            );
        }

        if (floorPlanTimelineFilter.duration === AM_PM) {
            setFloorPlanTimeLineFilter(
                buildHalfDayTimeline(startDateTime.setZone(timezone), endDateTime.setZone(timezone))
            );
        }

        if (floorPlanTimelineFilter.duration === ALL_DAY) {
            setFloorPlanTimeLineFilter(null);
        }
    }, [timeSlots]);

    useEffect(() => {
        handleDurationChange(floorPlanTimelineFilter.duration);
    }, [floorPlanTimelineFilter.duration]);

    const renderTimeLine = useCallback(() => {
        switch (floorPlanTimelineFilter.duration) {
            case ALL_DAY:
                return (
                    <AllDayTimeline
                        handleTimeSlotChange={handleTimeSlotChange}
                        startTime={startDateTime.setZone(timezone)}
                        endTime={endDateTime.setZone(timezone)}
                    />
                );
            case AM_PM:
                return (
                    <HalfDayTimeline
                        timeline={floorPlanTimelineFilter.timeLine}
                        handleTimeSlotChange={handleTimeSlotChange}
                    />
                );
            case CUSTOM_DURATION:
                return (
                    <CustomTimeline
                        handleTimeSlotChange={handleTimeSlotChange}
                        startTime={floorPlanTimelineFilter.timeLine.startDateTime}
                        endTime={floorPlanTimelineFilter.timeLine.endDateTime}
                        hourFormat={hourFormat}
                        timezone={timezone}
                    />
                );
            default:
                return (
                    <Timeline
                        timeline={floorPlanTimelineFilter.timeLine}
                        selectedDate={selectedDate}
                        timezone={timezone}
                        handleTimeSlotChange={handleTimeSlotChange}
                    />
                );
        }
    }, [floorPlanTimelineFilter.timeLine]);

    return (
        <header className="floorplan-header">
            {bookingWindowEndMinutesFromMidnight - bookingWindowStartMinutesFromMidnight > 0 ? (
                <DurationSelector
                    handleOpenSlider={handleOpenSlider}
                    slotDurations={slotDurations}
                    durationValue={floorPlanTimelineFilter.duration}
                />
            ) : null}
            {renderTimeLine()}
            <DurationSlider
                isOpen={isOpen}
                slotDurations={slotDurations}
                durationValue={floorPlanTimelineFilter.duration}
                dateTime={{ startDateTime, endDateTime }}
                timeInterval={slotInMinutes}
                bookingWindowStartMinutesFromMidnight={bookingWindowStartMinutesFromMidnight}
                bookingWindowEndMinutesFromMidnight={bookingWindowEndMinutesFromMidnight}
                hourFormat={hourFormat}
                timezone={timezone}
                selectedDate={selectedDate}
                onClose={() => {
                    setOpen(false);
                }}
                onDone={onDone}
            />
        </header>
    );
};

export default FloorPlanHeader;
