import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { CompanySearchData } from '../index';
import SearchResultItem from '../SearchResultItem/SearchResultItem';

import './SearchResult.scss';

interface SearchResultProps {
    onSelect: Function;
    searchResult: any[] | null;
    lastSearchData: CompanySearchData;
    resultPlaceHolder: string;
    setSearchPanelOpen: Function;
}

const SearchResult = ({
    onSelect,
    searchResult,
    lastSearchData,
    resultPlaceHolder,
    setSearchPanelOpen,
}: SearchResultProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="company-search-result">
            <div className="result-count" data-testid="result-count">
                <div className="result-count" data-testid="result-count">
                    <span className="count">{searchResult?.length || 0}&nbsp;</span>
                    <span className="label">{t('PersonSearch_Results')}</span>
                </div>
            </div>
            <div className="search-result" data-testid="search-result">
                {!searchResult && <div className="placeholder"> {resultPlaceHolder} </div>}

                {searchResult && searchResult.length === 0 && (
                    <div className="placeholder">
                        {`${t('PersonSearch_NoResultsFound')} ${t('PersonSearch_For')} ${lastSearchData.name}`}
                    </div>
                )}

                {searchResult &&
                    searchResult.map((item, i) => {
                        return (
                            <SearchResultItem
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={i}
                                onSelect={(selectedItem: object) => {
                                    setSearchPanelOpen(false);
                                    onSelect(selectedItem);
                                }}
                                item={item}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default SearchResult;
