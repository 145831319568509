import { useEffect, useRef } from 'react';
import * as React from 'react';
import { Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRowIdGetter, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { ReactComponent as SortBothIcon } from 'assets/icons/SortBoth.svg';
import { ReactComponent as SortDownIcon } from 'assets/icons/SortDown.svg';
import { ReactComponent as SortUpIcon } from 'assets/icons/SortUp.svg';

import './DataTable.scss';

interface DataTableProps {
    columns: GridColDef[];
    onSort?: Function;
    rowsData: any[];
    sortModel?: GridSortModel;
    getRowId?: GridRowIdGetter;
    columnBuffer?: number;
    page?: any;
    onRowClick?: (params: GridRowParams) => void;
    getRowClassName?: (params: GridRowParams) => string;
}

export default function DataTable({
    columns,
    onSort,
    rowsData,
    sortModel,
    getRowId,
    columnBuffer = 30,
    page,
    onRowClick,
    getRowClassName,
}: DataTableProps): JSX.Element {
    const apiRef = useRef<any>(null);

    useEffect(() => {
        if (apiRef.current) {
            apiRef.current.children[1].children[1].children[0].scrollTop = 0;
        }
    }, [apiRef, page]);

    return (
        <div className="data-table">
            {columns.length && (
                <DataGrid
                    ref={apiRef}
                    className="data-grid-component"
                    rows={rowsData}
                    columns={columns}
                    disableSelectionOnClick
                    hideFooterPagination
                    sortingMode="server"
                    hideFooter
                    columnBuffer={columnBuffer}
                    disableColumnMenu
                    sortModel={sortModel}
                    getRowId={getRowId}
                    components={{
                        ColumnSortedAscendingIcon: () => <SortUpIcon />,
                        ColumnSortedDescendingIcon: () => <SortDownIcon />,
                        ColumnUnsortedIcon: (iconProps: any) => {
                            if (iconProps?.sortingOrder.length === 2) {
                                return <SortUpIcon />;
                            }
                            return <SortBothIcon />;
                        },
                        NoRowsOverlay: () => <Stack height="100%" alignItems="center" justifyContent="center" />,
                    }}
                    onSortModelChange={
                        onSort
                            ? (model) => {
                                  onSort(model);
                              }
                            : undefined
                    }
                    onRowClick={onRowClick}
                    getRowClassName={getRowClassName}
                />
            )}
        </div>
    );
}
