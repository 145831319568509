import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

import { loadReferenceDataBegin } from '../../Redux/actions';
import * as selectors from '../../Redux/selectors';

// eslint-disable-next-line import/no-cycle
import Provider from './Provider';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    loadStatus: selectors.getReferenceDataLoadStatus(state),
});

const mapDispatchToProps = {
    loadReferenceData: () => loadReferenceDataBegin(),
};

export type ProviderProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Provider);
