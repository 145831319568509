import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from 'assets/icons/SidePanelArrow.svg';
import classNames from 'classnames';
import BackgroundMask from 'components/BackgroundMask';
import Loading from 'components/Loading';
import SiteSelector from 'components/SiteSelector';
import SlidingPanel from 'components/SlidingPanel';
import { GEOGRAPHIC_STRUCTURE_TYPES } from 'features/constants';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';

import InputWrapper from '../../../FormElements/InputWrapper';

import './GeographicStructureSelector.scss';

interface GeographicStructureSelectorProps {
    selectedSite: GeographicStructureItem | null;
    selectedSiteGroup: GeographicStructureItem | null;
    sitesGeoStructure: GeographicStructureItem[];
    siteGroupsGeoStructure: { [k: string]: GeographicStructureItem[] };
    tenantId: string;
    isLoading: boolean;
    required?: boolean;
    onBreadcrumbClick: (geoStructureParent: string) => void;
    onGeographicStructureClick: (geoStructureParentId: string, geoStructureType: string) => void;
    onSiteSelect: (site: GeographicStructureItem) => void;
    isInputView?: boolean;
    isDisabled?: boolean;
    isFromVisitorBookingPage?: boolean;
}

const GeographicStructureSelector = ({
    selectedSite,
    selectedSiteGroup,
    sitesGeoStructure,
    siteGroupsGeoStructure,
    tenantId,
    isLoading,
    onBreadcrumbClick,
    onGeographicStructureClick,
    onSiteSelect,
    required,
    isInputView,
    isDisabled = false,
    isFromVisitorBookingPage,
}: GeographicStructureSelectorProps): JSX.Element => {
    const [slidingPanelOpen, setSlidingPanelOpen] = useState<boolean>(false);
    const [slidingPanelSiteGroup, setSlidingPanelSiteGroup] = useState<GeographicStructureItem | null>(null);
    const [slidingPanelContent, setSlidingPanelContent] = useState<GeographicStructureItem[]>([]);
    const [panelContentType, setPanelContentType] = useState<string>(GEOGRAPHIC_STRUCTURE_TYPES.SITE);

    const { t } = useTranslation();

    const availableSites = useMemo(() => {
        if (!slidingPanelSiteGroup) {
            return sitesGeoStructure || [];
        }
        return sitesGeoStructure.filter((s) => s.parentId?.toString() === slidingPanelSiteGroup.id.toString());
    }, [slidingPanelSiteGroup, sitesGeoStructure]);

    const noOfChildrenVB = useMemo(() => {
        return sitesGeoStructure.reduce(
            (acc: { [key: string]: number }, site: GeographicStructureItem): { [key: string]: number } => {
                const siteGroupId = site?.parentId?.toString() ? site.parentId.toString() : '';

                if (acc[siteGroupId]) {
                    acc[siteGroupId] += 1;
                } else {
                    acc[siteGroupId] = 1;
                }
                return acc;
            },
            {} as { [key: string]: number }
        );
    }, [slidingPanelSiteGroup, sitesGeoStructure]);

    useEffect(() => {
        if (selectedSiteGroup) {
            setSlidingPanelSiteGroup(selectedSiteGroup);
        }
    }, [selectedSiteGroup]);

    useEffect(() => {
        if (panelContentType === GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP) {
            const parentId = tenantId as string;
            const availableSiteGroups = siteGroupsGeoStructure[parentId] || [];
            setSlidingPanelContent(availableSiteGroups);
        }
    }, [panelContentType, siteGroupsGeoStructure]);

    useEffect(() => {
        if (panelContentType === GEOGRAPHIC_STRUCTURE_TYPES.SITE) {
            setSlidingPanelContent(availableSites);
        }
    }, [panelContentType, sitesGeoStructure]);

    // view sites
    const onPanelItemClick = (e: any): void => {
        const structureId = e.target.value;
        const structureType = e.target.name;
        setPanelContentType(GEOGRAPHIC_STRUCTURE_TYPES.SITE);

        switch (structureType) {
            case GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP: {
                const siteGroup = siteGroupsGeoStructure[tenantId].find((gs) => gs.id === structureId) || null;
                setSlidingPanelSiteGroup(siteGroup);
                if (!sitesGeoStructure[structureId]) {
                    onGeographicStructureClick(structureId, structureType);
                    break;
                }
                setSlidingPanelContent(sitesGeoStructure);
                break;
            }

            case GEOGRAPHIC_STRUCTURE_TYPES.SITE: {
                const site = sitesGeoStructure.find((gs) => gs.id === structureId) as GeographicStructureItem;

                if (structureId !== selectedSite?.id.toString()) {
                    onSiteSelect(site);
                    setSlidingPanelOpen(false);
                }
                break;
            }

            default:
                break;
        }
    };

    // view site groups
    const onPanelBreadcrumbClick = (): void => {
        setPanelContentType(GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP);
        if (!siteGroupsGeoStructure[tenantId]) {
            onBreadcrumbClick(tenantId);
            return;
        }
        setSlidingPanelContent(siteGroupsGeoStructure[tenantId]);
    };

    const onOpenSlidingPanel = (): void => {
        setSlidingPanelOpen(!slidingPanelOpen);

        // 300ms is the transition time on sliding panel
        setTimeout(() => setSlidingPanelContent(availableSites), 300);
        setTimeout(() => setSlidingPanelSiteGroup(selectedSiteGroup), 300);
    };

    const backgroundMaskClass = classNames({ open: slidingPanelOpen });
    const slidingPanelClass = classNames('geoStructureSelector', {
        hasBreadcrumb: tenantId !== (selectedSite?.parentId?.toString() as string),
    });

    const siteSelectorTitle =
        tenantId === selectedSite?.parentId?.toString() ? t('Header_SelectedSite') : selectedSiteGroup?.name;

    return (
        <div className="geographicStructureSelector">
            {isInputView ? (
                <InputWrapper
                    label={t('SiteSelector_Site')}
                    inputName="inputName"
                    className="input-site-selector"
                    required={required}
                >
                    <button
                        type="button"
                        className="siteField"
                        data-testid="site-selector"
                        onClick={onOpenSlidingPanel}
                        disabled={isDisabled}
                    >
                        <div className="siteName">{selectedSite?.name}</div>

                        <div className="arrowIcon">
                            <Arrow />
                        </div>
                    </button>
                </InputWrapper>
            ) : (
                <>
                    <p>{siteSelectorTitle}</p>
                    <button
                        type="button"
                        className="currentSite"
                        onClick={onOpenSlidingPanel}
                        data-testid="siteHeader-selectedSite"
                        disabled={isDisabled}
                    >
                        <p>{selectedSite?.name}</p>
                        <span className="selectorArrow" />
                    </button>
                </>
            )}
            {slidingPanelOpen && <BackgroundMask className={backgroundMaskClass} onClick={onOpenSlidingPanel} />}
            <SlidingPanel
                open={slidingPanelOpen}
                onClose={onOpenSlidingPanel}
                title={t('SiteSelector_SiteSelection')}
                backButtonId="geostructureselector-back-button"
                className={slidingPanelClass}
            >
                {isLoading ? (
                    <Loading />
                ) : (
                    <SiteSelector
                        tenantId={tenantId}
                        geographicStructures={slidingPanelContent}
                        selectedGeographicStructure={selectedSite}
                        selectedSiteGroup={slidingPanelSiteGroup}
                        onGeographicStructureClick={onPanelItemClick}
                        onBreadcrumbClick={onPanelBreadcrumbClick}
                        isFromVisitorBookingPage={isFromVisitorBookingPage}
                        noOfChildrenVisitorBooking={noOfChildrenVB}
                    />
                )}
            </SlidingPanel>
        </div>
    );
};

GeographicStructureSelector.defaultProps = {
    isInputView: false,
    required: false,
};

export default GeographicStructureSelector;
