import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import WithLoading from 'components/HOC/WithLoading';
import SlidingPanel from 'components/SlidingPanel';
import { Person } from 'services/ApiClients/Models';

import { PersonType } from '../../../enums/PersonType';
import { PersonSearchData } from '../interfaces/PersonSearchData';
import SelectedPerson from '../SelectedPerson/SelectedPerson';

import SearchFrom from './SearchForm/SearchForm';

import './VisitorSearch.scss';

interface VisitorSearchProps {
    onSelect: Function;
    onSearch: Function;
    searchData: PersonSearchData;
    onSearchDataChange: Function;
    searchResult: Person[] | null;
    selectedItem: Person | null;
    buttonLabel: string;
    panelLabel: string;
    resultPlaceHolder: string;
    dataTestId: string;
    iaSearchInProgress: boolean;
    required?: boolean;
    resetSearchResult: Function;
    personType: number | null;
    onNewVisitor?: Function;
    setNewVisitorPanelOpen?: Function;
    setPersonDetailsPanelOpen?: Function;
    className?: string;
    isDisabled?: boolean;
    onPersonTypeChange: Function;
    showNewVisitorButton?: boolean;
}

export const VisitorSearch = ({
    className,
    onSelect,
    searchData,
    onSearchDataChange,
    onSearch,
    searchResult,
    selectedItem,
    iaSearchInProgress,
    buttonLabel,
    panelLabel,
    resultPlaceHolder,
    dataTestId,
    required = false,
    resetSearchResult,
    personType,
    onNewVisitor,
    setNewVisitorPanelOpen,
    setPersonDetailsPanelOpen,
    isDisabled,
    onPersonTypeChange,
    showNewVisitorButton,
}: VisitorSearchProps): JSX.Element => {
    const [isSearchPanelOpen, setSearchPanelOpen] = useState<boolean>(false);

    useEffect(() => {
        if (selectedItem) {
            setSearchPanelOpen(false);
        }
    }, [selectedItem]);

    useEffect(() => {
        if (isSearchPanelOpen) {
            resetSearchResult();
            if (searchData.name || searchData.email) {
                onSearchDataChange({ name: '', email: '' });
            }
            onPersonTypeChange(PersonType.Internal);
        }
    }, [isSearchPanelOpen]);

    return (
        <div className={classNames('internal-external-visitor-search', className)}>
            <SelectedPerson
                onSelect={onSelect}
                onSearchDataChange={onSearchDataChange}
                buttonLabel={buttonLabel}
                dataTestId={dataTestId}
                required={required}
                resetSearchResult={resetSearchResult}
                personType={personType}
                setPersonDetailsPanelOpen={setPersonDetailsPanelOpen}
                selectedPerson={selectedItem}
                setSearchPanelOpen={setSearchPanelOpen}
                isDisabled={isDisabled}
            />

            <SlidingPanel
                title={panelLabel}
                className="person-search-panel"
                open={isSearchPanelOpen}
                showBackgroundMask
                onClose={() => {
                    setSearchPanelOpen(false);
                    if (setNewVisitorPanelOpen) {
                        setNewVisitorPanelOpen(false);
                    }
                }}
            >
                <WithLoading isLoading={iaSearchInProgress}>
                    <SearchFrom
                        searchData={searchData}
                        onSearchDataChange={onSearchDataChange}
                        onSearch={onSearch}
                        onSelect={onSelect}
                        searchResult={searchResult}
                        resultPlaceHolder={resultPlaceHolder}
                        selectedItemId={selectedItem?.id}
                        setSearchPanelOpen={setSearchPanelOpen}
                        personType={personType}
                        onPersonTypeChange={onPersonTypeChange}
                        onNewVisitor={onNewVisitor}
                        showNewVisitorButton={showNewVisitorButton}
                    />
                </WithLoading>
            </SlidingPanel>
        </div>
    );
};
