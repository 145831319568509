import i18n from 'i18n';

import { BookingGridResource } from '../Models/Resource';

export interface SummaryIcon {
    icon: string;
    name: string;
}

// This function is creates a unique array of categories from the applicable list of fixed equipment.
// The list is filtered further to remove 'other' from the list as these are not represented as icons.
export const getSummary = (resourceRow: BookingGridResource | {}): SummaryIcon[] => {
    const { disabledAccess, restricted } = { ...resourceRow };
    const summaryIcons: SummaryIcon[] = [];

    if (disabledAccess) {
        summaryIcons.push({ icon: 'wheelchair', name: i18n.t('ResourceInfo_DisabledAccess') });
    }

    if (restricted) {
        summaryIcons.push({ icon: 'restricted', name: i18n.t('ResourceInfo_Restricted') });
    } else if (restricted === false) {
        summaryIcons.push({ icon: 'restrictedTo', name: i18n.t('ResourceInfo_Restricted') });
    }

    return summaryIcons;
};
