import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { ConfigurationQuery, ConfigurationResponse } from 'services/ApiClients/Configuration';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getSiteConfigurationAsync = createAsyncAction(
    'GET_SITE_CONFIGURATION_REQUEST',
    'GET_SITE_CONFIGURATION_SUCCESS',
    'GET_SITE_CONFIGURATION_FAILURE'
)<
    ConfigurationQuery,
    { [index: number]: ApiResponse<ConfigurationResponse> },
    AxiosError | { [index: number]: ApiResponse<ConfigurationResponse> }
>();

export const setSiteConfigurationLoadStatus = createAction('CONFIGURATION_SITE_SET_LOAD_STATUS')<string>();

export const getTenantConfigurationAsync = createAsyncAction(
    'GET_TENANT_CONFIGURATION_REQUEST',
    'GET_TENANT_CONFIGURATION_SUCCESS',
    'GET_TENANT_CONFIGURATION_FAILURE'
)<
    ConfigurationQuery,
    { [index: number]: ApiResponse<ConfigurationResponse> },
    AxiosError | { [index: number]: ApiResponse<ConfigurationResponse> }
>();

export const setTenantConfigurationLoadStatus = createAction('CONFIGURATION_SITE_SET_LOAD_STATUS')<string>();

export default {
    getSiteConfigurationAsync,
    setSiteConfigurationLoadStatus,
    getTenantConfigurationAsync,
    setTenantConfigurationLoadStatus,
};
