import React, { useEffect, useState } from 'react';
import Calendar from 'react-datetime';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SlidingPanel from 'components/SlidingPanel';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, MOMENT_DEFAULT_DATE_FORMAT } from 'features/constants';
import useKeyPress from 'hooks/useKeyPress';
import { DateTime } from 'luxon';

import Button from '../../Button';
import InputWrapper from '../../InputWrapper';

import Field from './Components/Field';

import './DateRangePicker.scss';

export interface DateRangePickerProps {
    name: string;
    title: string;
    onChange(value: any): void;
    dataTestId: string;
    disabled?: boolean;
    label?: string;
    className?: string;
    required?: boolean;
    futureDatesAvailable?: boolean;
    dateRange: any;
}

const DateRangePicker = ({
    name,
    title,
    onChange,
    dataTestId,
    disabled = false,
    label,
    className = '',
    required = true,
    dateRange,
    futureDatesAvailable = true,
}: DateRangePickerProps): JSX.Element => {
    const { t } = useTranslation();

    const [editorOpen, setEditorOpen] = useState<boolean>(false);
    const [dateRangeValue, setDateRangeValue] = useState<any>({});

    const handleFieldFocus = (): void => setEditorOpen(true);
    const handleClose = (): void => {
        setEditorOpen(false);
    };

    const handleDone = (): void => {
        onChange(dateRangeValue);
        setEditorOpen(false);
    };

    useEffect(() => {
        setDateRangeValue({ from: dateRange?.from, to: dateRange?.to });
    }, [editorOpen]);

    useEffect(() => {
        if (dateRangeValue?.from?.toFormat(DEFAULT_DATE_FORMAT) > dateRangeValue?.to?.toFormat(DEFAULT_DATE_FORMAT)) {
            setDateRangeValue({ ...dateRangeValue, to: dateRangeValue.from });
        }
    }, [dateRangeValue?.from]);

    useKeyPress(27, handleClose);

    return (
        <InputWrapper
            label={label}
            inputName={name}
            required={required}
            className={classNames('date-range-picker', className)}
        >
            <Field onFocus={handleFieldFocus} dateRange={dateRange} dataTestId={dataTestId} disabled={disabled} />

            <SlidingPanel title={title} open={editorOpen} onClose={handleClose} showBackgroundMask>
                <div className="range-calendars">
                    <div className="title-n-calendar">
                        <div className="date-label">From</div>
                        <Calendar
                            className="calendar"
                            value={dateRangeValue?.from?.toJSDate() || DateTime.now()}
                            timeFormat={false}
                            onChange={(from: any) =>
                                setDateRangeValue({
                                    ...dateRangeValue,
                                    from: DateTime.fromISO(from.format(DEFAULT_DATE_TIME_FORMAT)),
                                })
                            }
                            closeOnSelect={false}
                            locale={DateTime.local().locale}
                            input={false}
                            isValidDate={(current: any) => {
                                return (
                                    futureDatesAvailable ||
                                    current.format(MOMENT_DEFAULT_DATE_FORMAT) <=
                                        DateTime.now().toFormat(DEFAULT_DATE_FORMAT)
                                );
                            }}
                        />
                    </div>
                    <div className="title-n-calendar">
                        <div className="date-label">To</div>
                        <Calendar
                            value={dateRangeValue?.to?.toJSDate() || DateTime.now()}
                            timeFormat={false}
                            onChange={(to: any) =>
                                setDateRangeValue({
                                    ...dateRangeValue,
                                    to: DateTime.fromISO(to.format(DEFAULT_DATE_TIME_FORMAT)),
                                })
                            }
                            closeOnSelect={false}
                            locale={DateTime.local().locale}
                            input={false}
                            isValidDate={(current) => {
                                return (
                                    current.format(MOMENT_DEFAULT_DATE_FORMAT) >=
                                        dateRangeValue?.from?.toFormat(DEFAULT_DATE_FORMAT) &&
                                    (futureDatesAvailable ||
                                        current.format(MOMENT_DEFAULT_DATE_FORMAT) <=
                                            DateTime.now().toFormat(DEFAULT_DATE_FORMAT))
                                );
                            }}
                        />
                    </div>
                </div>
                <Button
                    type="button"
                    className="completeAction"
                    dataTestId="done-button"
                    onClick={handleDone}
                    text={t('Button_Done')}
                />
            </SlidingPanel>
        </InputWrapper>
    );
};

export default DateRangePicker;
