import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

import './Button.scss';

type ButtonType = 'submit' | 'button' | 'reset';

export interface ButtonProps {
    type?: ButtonType;
    text?: string;
    className?: string;
    dataTestId: string;
    onClick?: () => void;
    disabled?: boolean;
    submitting?: boolean;
    iconOnly?: boolean;
    icon?: FunctionComponent;
    id?: string;
    success?: boolean;
    secondary?: boolean;
    primary?: boolean;
    danger?: boolean;
    transparent?: boolean;
}

const Button = ({
    type = 'button',
    text = '',
    className,
    dataTestId,
    disabled = false,
    onClick,
    submitting,
    iconOnly,
    icon: Icon,
    id,
    success,
    secondary,
    primary,
    danger,
    transparent,
}: ButtonProps): JSX.Element => {
    const handleClick = (): void => {
        if (!onClick) return;

        onClick();
    };

    return (
        <button
            className={classNames(
                'button',
                className,
                id,
                { submitting },
                { iconOnly },
                { success },
                { secondary },
                { primary },
                { danger },
                { transparent }
            )}
            // eslint-disable-next-line react/button-has-type
            type={type}
            onClick={handleClick}
            disabled={disabled || submitting}
            data-testid={dataTestId}
            id={id}
        >
            {Icon && <Icon />}
            <span>
                <ReactMarkdown source={text} />
            </span>
        </button>
    );
};

export default Button;
