import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import WithLoading from 'components/HOC/WithLoading';
import RadioButtonSelector from 'components/RadioButtonSelector/RadioButtonSelector';
import Header from 'components/SiteSubHeader';
import { PersonType } from 'enums/PersonType';
import * as constants from 'features/constants';
import { DateTime } from 'luxon';
import { EditVisitorsLocationState, Person } from 'services/ApiClients/Models';
import { SiteVisit } from 'services/ApiClients/VisitorManagement/Models/SiteVisit';
import { Visitor } from 'services/ApiClients/VisitorManagement/Models/Visitor';
import { v4 as uuidv4 } from 'uuid';

import { GeographicStructureItem } from '../../services/ApiClients/OrganisationStructure';
import { formatBookableDays, getAvailableDateTimeSlot } from '../../utilities/bookingUtils';
import { DEFAULT_TIMEZONE } from '../constants';

import { MultiDayTime } from './VisitorBookingForm/MultiDayDueTimePanel/MultiDayDueTimePanel';
// eslint-disable-next-line import/no-cycle
import VisitorBookingActionButtons from './VisitorBookingsActions/VisitorBookingActionButtons';
import VisitorBookingDialogs from './VisitorBookingsActions/VisitorBookingDialogs';
// eslint-disable-next-line import/no-cycle
import VisitorBookingForm from './VisitorBookingForm';
// eslint-disable-next-line import/no-cycle
import { VisitorBookingProps } from '.';

import './VisitorBooking.scss';

export interface BookingData {
    visitor?: Visitor | null;
    host?: Person;
    dueDateTime: DateTime;
    isValidToDate?: DateTime;
    site: GeographicStructureItem | null;
    isMultiday?: boolean;
    dueDateTimes?: MultiDayTime[];
}

const VisitorBooking = ({
    getInternalPersonInfo,
    createSiteVisit,
    createMultiDaySiteVisit,
    isActionInProgress,
    isSiteVisitCreationInProgress,
    loggedPersonInfo,
    userProfile,
    selectedSite,
    getBookableDays,
    bookableDaysConfig,
    cancelEditSiteVisit,
    cancelEditingSiteVisitLoadStatus,
    cancelEditingMultiDaySiteVisitLoadStatus,
    cancelEditMultiDaySiteVisit,
    editSiteVisitLoadStatus,
    setMySiteVisitsLoadStatus,
    editSiteVisit,
}: VisitorBookingProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as EditVisitorsLocationState;
    const [bookingData, setBookingData] = useState<BookingData>({} as BookingData);
    const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
    const [cancelMultiDayDialogOpen, setCancelMultiDayDialogOpen] = useState<boolean>(false);
    const [closeDialogOpen, setCloseDialogOpen] = useState<boolean>(false);
    const [selectedVisitorType, setVisitorType] = useState<number | null>(null);

    const slotInterval = bookableDaysConfig.tenantVisitArrivalTimeInterval || 15;
    const firstDateTimeSlotAccordingToTimeZone = useMemo(
        () =>
            DateTime.utc()
                .setZone(bookingData?.site?.timezone || selectedSite?.timezone || DEFAULT_TIMEZONE)
                .set({ minute: Math.ceil(DateTime.now().minute / slotInterval) * slotInterval }),
        [bookableDaysConfig]
    );

    const defaultDateTime = useMemo(
        () =>
            locationState?.isEdit
                ? DateTime.fromISO(locationState.dueDateTime).setZone(
                      bookingData?.site?.timezone || selectedSite?.timezone || DEFAULT_TIMEZONE
                  )
                : getAvailableDateTimeSlot(
                      firstDateTimeSlotAccordingToTimeZone,
                      formatBookableDays(bookableDaysConfig),
                      slotInterval,
                      DateTime.utc().setZone(bookingData?.site?.timezone || selectedSite?.timezone || DEFAULT_TIMEZONE)
                  ),
        [firstDateTimeSlotAccordingToTimeZone, locationState?.isEdit]
    );

    useEffect(() => {
        setBookingData({
            ...bookingData,
            dueDateTime: defaultDateTime,
        });
    }, [bookableDaysConfig]);

    useEffect(() => {
        if (!bookingData.site) {
            setBookingData({ ...bookingData, site: selectedSite });
        }
    }, [selectedSite]);

    useEffect(() => {
        if (locationState?.isEdit) {
            if (locationState?.visitorType === 'External') {
                setVisitorType(1);
            } else {
                setVisitorType(0);
            }
        }
        return () => {
            setMySiteVisitsLoadStatus(constants.LOAD_STATUSES.REQUIRED);
        };
    }, []);

    useEffect(() => {
        if (selectedVisitorType === PersonType.External && userProfile?.userProfileId) {
            getInternalPersonInfo(userProfile.userProfileId);
        }
        setBookingData({
            dueDateTime: defaultDateTime,
            site: locationState?.isEdit ? locationState.siteInformation : selectedSite,
            isMultiday: false,
            host: locationState?.isEdit ? (locationState.hostInfo as Person) : undefined,
        });
    }, [selectedVisitorType]);

    useEffect(() => {
        if (bookingData.site) {
            getBookableDays(bookingData.site.id.toString());
        }
    }, [bookingData.site]);

    useEffect(() => {
        if (loggedPersonInfo && selectedVisitorType === PersonType.External) {
            const host = loggedPersonInfo as unknown as Person;
            host.firstname = loggedPersonInfo.firstName;

            setBookingData({ ...bookingData, host });
        }
    }, [loggedPersonInfo]);

    useEffect(() => {
        if (cancelEditingSiteVisitLoadStatus === constants.LOAD_STATUSES.LOADED) {
            navigate(locationState.previousPath);
        }
    }, [cancelEditingSiteVisitLoadStatus]);

    useEffect(() => {
        if (cancelEditingMultiDaySiteVisitLoadStatus === constants.LOAD_STATUSES.LOADED) {
            navigate(locationState.previousPath);
        }
    }, [cancelEditingMultiDaySiteVisitLoadStatus]);

    useEffect(() => {
        if (editSiteVisitLoadStatus === constants.LOAD_STATUSES.LOADED) {
            navigate(locationState.previousPath);
        }
    }, [editSiteVisitLoadStatus]);

    const [dueTimeErrorMessage, setDueTimeErrorMessage] = useState<string>('666');

    const resetDueTimeErrorMessage = (): void => {
        if (dueTimeErrorMessage) {
            setDueTimeErrorMessage('');
        }
    };

    useEffect(() => resetDueTimeErrorMessage(), [bookingData.dueDateTime]);

    const onVisitorTypeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setVisitorType(parseInt(e.target.value, 2));
    };

    const radioGroupItems = [
        { value: PersonType.Internal, label: t('VisitorBooking_Internal'), dataTestId: 'radio-btn-internal' },
        { value: PersonType.External, label: t('VisitorBooking_External'), dataTestId: 'radio-btn-external' },
    ];

    const handleBook = (checkedIn = false): void => {
        if (bookingData.dueDateTime < DateTime.now().setZone(bookingData?.site?.timezone || DEFAULT_TIMEZONE)) {
            setDueTimeErrorMessage(t('VisitorBooking_DueTimeInPast'));
            return;
        }

        const siteVisitData = {
            id: uuidv4().toString(),
            geographicStructureId: bookingData.site?.id || '',
            visitor: {
                ...bookingData.visitor,
                countryCode: bookingData.visitor?.countryCode?.value,
            },
            host: bookingData.host ? { ...bookingData.host, countryCode: bookingData.host?.countryCode?.value } : null,
            dueDateTime: bookingData.dueDateTime.toUTC().toISO(),
            visitorCheckedIn: checkedIn,
        } as SiteVisit;

        siteVisitData.visitor.id = siteVisitData.visitor.id.replace('Person|', '');
        siteVisitData.visitor.visitorType = selectedVisitorType === PersonType.Internal ? 'Internal' : 'External';
        siteVisitData.visitor.personId = selectedVisitorType === PersonType.Internal ? siteVisitData.visitor.id : null;
        if (siteVisitData.host) {
            siteVisitData.host.personId = siteVisitData.host.id.replace('Person|', '');
        }
        if (bookingData.isMultiday) {
            siteVisitData.dueDateTimes = [];

            bookingData.dueDateTimes?.forEach((i) => {
                const { date } = i;
                date.setZone(bookingData.site?.timezone || DEFAULT_TIMEZONE);
                siteVisitData.dueDateTimes?.push(date.toUTC());
            });

            createMultiDaySiteVisit(siteVisitData);
        } else {
            createSiteVisit(siteVisitData);
        }

        setVisitorType(null);
        setBookingData({ dueDateTime: defaultDateTime } as BookingData);
        if (dueTimeErrorMessage) {
            setDueTimeErrorMessage('');
        }
    };

    const withLoading =
        cancelEditingSiteVisitLoadStatus === constants.LOAD_STATUSES.LOADING ||
        editSiteVisitLoadStatus === constants.LOAD_STATUSES.LOADING ||
        cancelEditingMultiDaySiteVisitLoadStatus === constants.LOAD_STATUSES.LOADING ||
        isSiteVisitCreationInProgress ||
        isActionInProgress;

    return (
        <main className="page-visitor-booking">
            <Header
                title={locationState?.isEdit ? t('VisitorBooking_EditTitle') : t('VisitorBooking_Title')}
                dataTestId="visitor-booking-title"
            />

            <WithLoading isLoading={withLoading}>
                <RadioButtonSelector
                    required
                    value={selectedVisitorType}
                    title={`${t('VisitorBooking_VisitorType')}:`}
                    items={radioGroupItems}
                    handleChange={onVisitorTypeChange}
                    tickView
                    isDisabled={locationState?.isEdit}
                />
                {selectedVisitorType === null && <div className="no-type-selected" />}
                {selectedVisitorType !== null && (
                    <VisitorBookingForm
                        editVisitorBookings={locationState}
                        visitorType={selectedVisitorType}
                        setBookingData={setBookingData}
                        bookingData={bookingData}
                        defaultDueDateTime={defaultDateTime}
                        timezone={bookingData.site?.timezone || DEFAULT_TIMEZONE}
                        dueTimeErrorMessage={dueTimeErrorMessage}
                    />
                )}

                <VisitorBookingActionButtons
                    locationState={locationState}
                    setCancelMultiDayDialogOpen={setCancelMultiDayDialogOpen}
                    setCancelDialogOpen={setCancelDialogOpen}
                    bookingData={bookingData}
                    selectedVisitorType={selectedVisitorType}
                    setCloseDialogOpen={setCloseDialogOpen}
                    editSiteVisit={editSiteVisit}
                    setDueTimeErrorMessage={setDueTimeErrorMessage}
                    handleBook={handleBook}
                />

                <VisitorBookingDialogs
                    locationState={locationState}
                    cancelMultiDayDialogOpen={cancelMultiDayDialogOpen}
                    cancelDialogOpen={cancelDialogOpen}
                    closeDialogOpen={closeDialogOpen}
                    setCancelDialogOpen={setCancelDialogOpen}
                    cancelEditSiteVisit={cancelEditSiteVisit}
                    setCancelMultiDayDialogOpen={setCancelMultiDayDialogOpen}
                    cancelEditMultiDaySiteVisit={cancelEditMultiDaySiteVisit}
                    setCloseDialogOpen={setCloseDialogOpen}
                />
            </WithLoading>
        </main>
    );
};

export default VisitorBooking;
