import React from 'react';
import classNames from 'classnames';

import { ErrorText, RequiredLabel } from './Components';

import './InputWrapper.scss';

export interface InputWrapperProps {
    label?: string;
    inputName?: string;
    className?: string;
    hideLabel?: boolean;
    required?: boolean;
    errorText?: string;
    isError?: boolean;
}

const InputWrapper = ({
    label,
    inputName,
    className,
    hideLabel,
    required = false,
    errorText,
    isError,
    children,
}: React.PropsWithChildren<InputWrapperProps>): JSX.Element => {
    return (
        <div className={classNames('inputWrapper', className)}>
            <label className={classNames('label', { error: isError, hidden: hideLabel })} htmlFor={inputName}>
                <RequiredLabel required={required} />
                {label}
            </label>
            {children}
            {isError && <ErrorText text={errorText} />}
        </div>
    );
};

export default InputWrapper;
