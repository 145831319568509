import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import ReceptionComponent from '../../features/Reception';

const Reception = (): JSX.Element => {
    return (
        <MainLayout>
            <ReceptionComponent />
        </MainLayout>
    );
};

export default Reception;
