import React from 'react';
import Select, { OnChangeValue } from 'react-select';
import classnames from 'classnames';

import InputWrapper, { InputWrapperProps } from '../Forms/InputWrapper';

import './Select.scss';

export interface ListItem {
    label: string;
    value: string;
    displayOrder?: number;
}

export interface SelectProps extends InputWrapperProps {
    items: ListItem[];
    value: OnChangeValue<ListItem, false> | null;
    isDisabled?: boolean;
    placeholder?: string;
    isLoading?: boolean;
    isClearable?: boolean;
    onChange: (value: OnChangeValue<ListItem, false>) => void;
    dataTestId?: string;
}
const SelectComponent = (props: SelectProps): JSX.Element => {
    const {
        label,
        name,
        className,
        hideLabel,
        required,
        error,
        dataTestId,
        items,
        value,
        isDisabled = false,
        isLoading = false,
        isClearable = false,
        onChange,
        placeholder,
    } = props;

    const placeholderValue = placeholder ? { label: placeholder, value: '' } : { label: 'Select', value: '' };

    return (
        <InputWrapper
            label={label}
            name={name}
            className={className}
            hideLabel={hideLabel}
            required={required}
            error={error}
            dataTestId={dataTestId}
        >
            <Select
                id={dataTestId}
                name={name}
                onChange={onChange}
                className={classnames(
                    'dropdown-select-container',
                    value && 'dropdown-select-container--has-value',
                    isDisabled && 'dropdown-select-container--disabled'
                )}
                classNamePrefix="select"
                options={items}
                value={value || placeholderValue}
                placeholder={placeholder}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isLoading={isLoading}
            />
        </InputWrapper>
    );
};

export default SelectComponent;
