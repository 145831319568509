import { AuditApi } from './ApiClients/Audit';
import { BookingApi } from './ApiClients/Booking';
import { ConfigurationApi } from './ApiClients/Configuration';
import { IdentityApi } from './ApiClients/Identity';
import { LanguageApi } from './ApiClients/Language';
import { OrganisationStructureApi } from './ApiClients/OrganisationStructure/OrganisationStructureApi';
import { OrganizationApi } from './ApiClients/Organization/OrganizationApi';
import { PersonApi } from './ApiClients/Person';
import { ReportsApi } from './ApiClients/Reports';
import { ResourceApi } from './ApiClients/Resource';
import { SearchApi } from './ApiClients/Search/SearchApi';
import { VisitorManagementApi } from './ApiClients/VisitorManagement';
import * as bookingSignalR from './BookingSignalRService';
import * as logging from './LoggingService';

export default {
    logging,
    bookingSignalR,
    api: {
        audit: AuditApi,
        booking: BookingApi,
        resource: ResourceApi,
        organisationStructure: OrganisationStructureApi,
        identity: IdentityApi,
        language: LanguageApi,
        configuration: ConfigurationApi,
        person: PersonApi,
        visitorManagement: VisitorManagementApi,
        reports: ReportsApi,
        organisation: OrganizationApi,
        search: SearchApi,
    },
};
