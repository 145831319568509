import { getConfiguration } from 'utilities/appConfigsUtils';

import { BuildAzureSearchQuery, SearchData } from '../../../utilities/searchUtils';
import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';
import { PersonsResponse } from '../Models';

import { PersonById } from './Models/PersonById';

export class PersonApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().PERSON_API;

        return `${baseUrl}/api/v1`;
    };

    public static searchPersons = async (searchPersonsRequest: SearchData): Promise<ApiResponse<PersonsResponse>> => {
        const searchQuery = BuildAzureSearchQuery(searchPersonsRequest);
        return PersonApi.get(
            `${this.BuildBaseUrl()}/Person/search?SearchText=${searchQuery}&top=${
                searchPersonsRequest.top
            }&queryType=${1}&orderBy=${searchPersonsRequest.orderBy}`
        );
    };

    public static searchUsers = async (searchPersonsRequest: SearchData): Promise<ApiResponse<PersonsResponse>> => {
        const searchQuery = BuildAzureSearchQuery(searchPersonsRequest);
        return PersonApi.get(
            `${this.BuildBaseUrl()}/Person/search?SearchText=${searchQuery}&top=${
                searchPersonsRequest.top
            }&queryType=${1}&orderBy=${searchPersonsRequest.orderBy}&personType=STAFFMEMBER`
        );
    };

    public static getPersonById = async (id: string): Promise<ApiResponse<PersonById>> => {
        return PersonApi.get(`${this.BuildBaseUrl()}/Person/${id}`);
    };

    public static getPersonsByIds = async (ids: string[]): Promise<ApiResponse<PersonById[]>> => {
        const personIds = ids.map((id) => `personId=${id.toString()}`).join('&');
        return PersonApi.get(`${this.BuildBaseUrl()}/Person/multiple?${personIds}`);
    };
}
