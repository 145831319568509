import React from 'react';
import NotFound from 'features/NotFound';
import AuthenticationLayout from 'layouts/AuthenticationLayout';

const NoMatchRoute = (): JSX.Element => {
    return (
        <AuthenticationLayout>
            <NotFound />
        </AuthenticationLayout>
    );
};

export default NoMatchRoute;
