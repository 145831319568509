import React from 'react';
import { ReactComponent as ExternalVisitorIcon } from 'assets/icons/visitor/ExternalVisitor.svg';
import { ReactComponent as InternalVisitorIcon } from 'assets/icons/visitor/InternalVisitor.svg';
import cn from 'classnames';
import { PersonType } from 'enums/PersonType';

import { Person } from '../../../services/ApiClients/Models';

import './PersonSearchResultItem.scss';

interface PersonSearchResultItemProps {
    onSelect: Function;
    selectedItemId?: string;
    item: Person;
    personType: number | null;
}

const PersonSearchResultItem = ({
    onSelect,
    item,
    selectedItemId,
    personType,
}: PersonSearchResultItemProps): JSX.Element => {
    return (
        <div
            className={cn('search-result-item', item.id === selectedItemId && 'selected')}
            onClick={() => (() => onSelect(item))()}
            data-testid="search-result-item"
        >
            <div className="name-email">
                <div className="name">{`${item.surname}, ${item.firstname}`}</div>
                <div className="email"> {item.email} </div>
            </div>
            <div className="visitor-type">
                {personType === PersonType.Internal ? <InternalVisitorIcon /> : <ExternalVisitorIcon />}
            </div>
        </div>
    );
};

export default PersonSearchResultItem;
