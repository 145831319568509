import React, { SyntheticEvent } from 'react';

import './Toggle.scss';

interface ToggleProps {
    label: string;
    inputName: string;
    inputId: string;
    onChange: (e: SyntheticEvent) => void;
    dataTestId: string;
    checked: boolean;
    value?: string;
    type: string;
    isDisabled?: boolean;
}

const Toggle = ({
    label,
    inputName,
    inputId,
    onChange,
    dataTestId,
    checked = false,
    value = 'false',
    type,
    isDisabled,
}: ToggleProps): JSX.Element => {
    return (
        <div className="toggle">
            <input
                type={type}
                name={inputName}
                id={inputId}
                onChange={onChange}
                data-testid={dataTestId}
                checked={checked}
                value={value}
                disabled={isDisabled}
            />
            <label htmlFor={inputId}>{label}</label>
        </div>
    );
};

Toggle.defaultProps = {
    isDisabled: false,
};

export default Toggle;
