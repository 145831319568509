import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';

import { Languages } from './Models';

export class LanguageApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().LANGUAGE_API;

        return `${baseUrl}/api/v1`;
    };

    public static getLanguages = async (): Promise<ApiResponse<Languages>> => {
        LoggingService.Debug('Sending Language.Language query. Headers: ', LanguageApi.PersistedHeaders);

        return LanguageApi.get<Languages>(`${this.BuildBaseUrl()}/Language`);
    };
}
