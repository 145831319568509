import { FunctionComponent } from 'react';

import ActionButtonType from './ActionButtonType';

export default interface ActionButton {
    name: string;
    type: ActionButtonType;
    label: string;
    icon?: FunctionComponent;
    onClick?(): void;
    submitting?: boolean;
    dataTestId?: string;
    isDisabled?: boolean;
}

export const createPrimaryButton = (
    name: string,
    label: string,
    onClick?: () => void,
    submitting?: boolean,
    icon?: FunctionComponent,
    dataTestId?: string,
    isDisabled?: boolean
): ActionButton => ({
    name,
    label,
    submitting,
    icon,
    onClick,
    type: ActionButtonType.Primary,
    dataTestId,
    isDisabled,
});

export const createSecondaryButton = (
    name: string,
    label: string,
    onClick?: () => void,
    submitting?: boolean,
    icon?: FunctionComponent,
    dataTestId?: string
): ActionButton => ({
    name,
    label,
    submitting,
    icon,
    onClick,
    type: ActionButtonType.Secondary,
    dataTestId,
});

export const createDangerButton = (
    name: string,
    label: string,
    onClick?: () => void,
    submitting?: boolean,
    icon?: FunctionComponent,
    dataTestId?: string,
    isDisabled?: boolean
): ActionButton => ({
    name,
    label,
    submitting,
    icon,
    onClick,
    type: ActionButtonType.Danger,
    dataTestId,
    isDisabled,
});

export const createSuccessButton = (
    name: string,
    label: string,
    onClick?: () => void,
    submitting?: boolean,
    icon?: FunctionComponent,
    dataTestId?: string,
    isDisabled?: boolean
): ActionButton => ({
    name,
    label,
    submitting,
    icon,
    onClick,
    type: ActionButtonType.Success,
    dataTestId,
    isDisabled,
});

export const createEditButton = (
    name: string,
    label: string,
    onClick?: () => void,
    submitting?: boolean,
    icon?: FunctionComponent,
    dataTestId?: string,
    isDisabled?: boolean
): ActionButton => ({
    name,
    label,
    submitting,
    icon,
    onClick,
    type: ActionButtonType.Edit,
    dataTestId,
    isDisabled,
});
