import React from 'react';
import Account from 'features/Account';
import MainLayout from 'layouts/MainLayout/MainLayout';

const MyAccount = (): JSX.Element => (
    <MainLayout>
        <Account />
    </MainLayout>
);

export default MyAccount;
