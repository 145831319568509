import React, { useEffect, useState } from 'react';
import Calendar from 'react-datetime';
import { useTranslation } from 'react-i18next';
import Select, { OnChangeValue } from 'react-select';
import Button from 'components/FormElements/Button';
import InlineFields from 'components/FormElements/InlineFields';
import InputWrapper from 'components/FormElements/InputWrapper';
import { DEFAULT_TIME_SLOT } from 'features/constants';
import { DateTime } from 'luxon';
import { Configuration } from 'services/ApiClients/Configuration';
import DateTimeService, { TimeSlotOption } from 'services/DateTimeService';
import { getBookingTimeSlotsConsideringCurrentTime } from 'utilities/bookingUtils';
import { isNil } from 'utilities/ts';

import 'moment/locale/en-gb';

export interface EditorProps {
    onDone(value: DateTime | undefined): void;
    value?: DateTime;
    resourceTypeSiteConfiguration: Configuration;
    timeFromDisabled?: boolean;
    timeTitle?: string;
    showTime?: boolean;
    isValidDate?: (current: DateTime) => boolean;
    timeInterval?: number;
    nowDateTime?: DateTime;
    initialViewDate?: DateTime;
    hourFormat: string;
}

const getSelectedTimeSlot = (
    timeSlots: TimeSlotOption[],
    dateTime: DateTime,
    slotInterval: number,
    isEndTimeSlot = false
): TimeSlotOption | undefined => {
    const selectedTimeSlotValue = DateTimeService.getTimeSlotFromDate(dateTime, slotInterval, isEndTimeSlot);
    return timeSlots.find((timeSlot) => timeSlot.value === selectedTimeSlotValue);
};

const DateTimeEditor = ({
    value,
    onDone,
    resourceTypeSiteConfiguration,
    timeFromDisabled,
    timeTitle,
    isValidDate,
    timeInterval,
    showTime = true,
    nowDateTime,
    initialViewDate,
    hourFormat,
}: EditorProps): JSX.Element | null => {
    // eslint-disable-next-line no-param-reassign
    timeInterval = timeInterval || DEFAULT_TIME_SLOT;
    const { t } = useTranslation();
    const [localValue, setLocalValue] = useState<DateTime | undefined>(value);
    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    let timeSlots: TimeSlotOption[] = [];
    if (showTime && localValue && nowDateTime) {
        timeSlots = getBookingTimeSlotsConsideringCurrentTime(
            resourceTypeSiteConfiguration.bookableDays,
            localValue,
            nowDateTime,
            timeInterval,
            hourFormat
        );
    }

    const selectedFromTimeSlot = localValue && getSelectedTimeSlot(timeSlots, localValue, timeInterval);
    const handleTimeChange = (selected: OnChangeValue<TimeSlotOption, false>): void => {
        const timeSlot = (selected as TimeSlotOption).value;
        const newDateTime = localValue && DateTimeService.getDateFromTimeSlot(localValue, timeSlot, timeInterval);

        setLocalValue(newDateTime);
    };

    const handleDateChange = (dateValue: any): void => {
        const date = dateValue.toISOString ? dateValue.toISOString() : dateValue;
        const newDate = DateTime.fromISO(date);
        setLocalValue(newDate);
    };

    const handleDone = async (): Promise<void> => {
        if (!isNil(onDone)) {
            onDone(localValue);
        }
    };

    return (
        <>
            <div className="editor">
                <Calendar
                    value={localValue?.setZone('local', { keepLocalTime: true }).toJSDate()}
                    input={false}
                    timeFormat={false}
                    onChange={(dateTime) => handleDateChange(dateTime)}
                    isValidDate={(current) => {
                        const date = DateTime.fromISO(current.toISOString());
                        return (
                            (isValidDate ? isValidDate(date) : true) &&
                            (Object.keys(resourceTypeSiteConfiguration).length === 0 ||
                                DateTimeService.isSelectedDateBookable(date, resourceTypeSiteConfiguration))
                        );
                    }}
                    initialViewDate={initialViewDate?.setZone('local', { keepLocalTime: true }).toJSDate()}
                />
                {showTime && (
                    <InlineFields>
                        <InputWrapper label={timeTitle || ''} inputName="time">
                            <Select
                                id="time"
                                value={selectedFromTimeSlot}
                                onChange={handleTimeChange}
                                options={timeSlots}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                isDisabled={timeFromDisabled}
                            />
                        </InputWrapper>
                    </InlineFields>
                )}
            </div>

            <Button
                type="button"
                className="completeAction"
                dataTestId="done-button"
                onClick={handleDone}
                text={t('Button_Done')}
            />
        </>
    );
};

export default DateTimeEditor;
