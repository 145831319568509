import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import ReportsComponent from '../../features/Reports';

const Reports = (): JSX.Element => {
    return (
        <MainLayout>
            <ReportsComponent />
        </MainLayout>
    );
};

export default Reports;
