import { RootEpic } from 'PortalTypes';
import { push } from 'redux-first-history';
import { forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ResourceType } from 'services/ApiClients/Resource';
import ToastService from 'services/ToastService';
import { isActionOf } from 'typesafe-actions';

import { getSiteConfigurationAsync, getTenantConfigurationAsync } from './actions';

export const getSiteConfigurationEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getSiteConfigurationAsync.request)),
        switchMap(({ payload }) => {
            return forkJoin({
                desksResponse: from(api.configuration.getDeskConfiguration(payload)),
                roomsResponse: from(api.configuration.getRoomsConfiguration(payload)),
            }).pipe(
                map(({ desksResponse, roomsResponse }) =>
                    getSiteConfigurationAsync.success({
                        [ResourceType.DESK.value]: desksResponse,
                        [ResourceType.ROOM.value]: roomsResponse,
                    })
                )
            );
        }),
        catchError(() => {
            ToastService.Error({ message: 'Toast_ConfigurationServiceError' });
            return of(push({ pathname: `/` }));
        })
    );

export const getTenantConfigurationEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getTenantConfigurationAsync.request)),
        switchMap(({ payload }) => {
            return forkJoin({
                desksResponse: from(api.configuration.getDeskConfiguration(payload)),
                roomsResponse: from(api.configuration.getRoomsConfiguration(payload)),
            }).pipe(
                map(({ desksResponse, roomsResponse }) =>
                    getTenantConfigurationAsync.success({
                        [ResourceType.DESK.value]: desksResponse,
                        [ResourceType.ROOM.value]: roomsResponse,
                    })
                )
            );
        }),
        catchError(() => {
            ToastService.Error({ message: 'Toast_ConfigurationServiceError' });
            return of(push(`/`));
        })
    );
