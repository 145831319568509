import React from 'react';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import i18n from 'i18next';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

import ReportFilterColumnHeader from '../../../components/ReportsFilterColumnHeader/ReportFilterColumnHeader';
import { ResourceType } from '../../../services/ApiClients/Resource/Models';
import { AllListItemOption, DEFAULT_DATE_FORMAT } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface } from '../interfaces';

const items = {
    id: true,
    type: true,
    name: true,
    siteGroup: true,
    site: true,
    area: true,
    hoursAvailable: true,
    hoursBooked: true,
    hoursOccupied: true,
    resourceUtilisation: true,
    numberOfBookings: true,
    avgBookingDuration: true,
    avgOccupancyDuration: true,
    checkedOutHoursSaved: true,
};

export const getColumns = (
    showSiteGroups: boolean,
    handleFilterClick: (field: string, headerName: string) => void,
    sideBarFilter: ColumnFilterInterface,
    activeSideBarFilter: ActiveColumnFilterInterface
): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: i18n.t('Reports_ResourceType'),
            minWidth: 175,
            flex: 0.1,
            cellClassName: 'capitalize',
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.type?.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_ResourceType')}</p>
                ),
        },
        {
            field: 'name',
            headerName: i18n.t('Reports_ResourceName'),
            minWidth: 225,
            flex: 0.2,
        },
        ...(showSiteGroups
            ? [
                  {
                      field: 'siteGroup',
                      headerName: i18n.t('Reports_SiteGroup'),
                      minWidth: 150,
                      flex: 0.1,
                      renderHeader: (params: GridColumnHeaderParams) =>
                          sideBarFilter.siteGroup?.enabled ? (
                              <ReportFilterColumnHeader
                                  handleFilterClick={handleFilterClick}
                                  field={params.colDef.field}
                                  headerName={params.colDef.headerName || ''}
                                  activeSideBarFilter={activeSideBarFilter}
                              />
                          ) : (
                              <p>{i18n.t('Reports_SiteGroup')}</p>
                          ),
                  },
              ]
            : []),
        {
            field: 'site',
            headerName: i18n.t('Reports_Site'),
            minWidth: 225,
            flex: 0.2,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.site?.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_Site')}</p>
                ),
        },
        {
            field: 'area',
            headerName: i18n.t('Reports_Area'),
            minWidth: 175,
            flex: 0.1,
            renderHeader: (params: GridColumnHeaderParams) =>
                sideBarFilter.area?.enabled ? (
                    <ReportFilterColumnHeader
                        handleFilterClick={handleFilterClick}
                        field={params.colDef.field}
                        headerName={params.colDef.headerName || ''}
                        activeSideBarFilter={activeSideBarFilter}
                    />
                ) : (
                    <p>{i18n.t('Reports_Area')}</p>
                ),
        },
        {
            field: 'hoursAvailable',
            headerName: i18n.t('Reports_HoursAvailable'),
            minWidth: 175,
            flex: 0.1,
        },
        {
            field: 'hoursBooked',
            headerName: i18n.t('Reports_HoursBooked'),
            minWidth: 150,
            flex: 0.1,
        },
        {
            field: 'hoursOccupied',
            headerName: i18n.t('Reports_HoursOccupied'),
            minWidth: 175,
            flex: 0.1,
        },
        {
            field: 'resourceUtilisation',
            headerName: i18n.t('Reports_ResourceUtilisation%'),
            minWidth: 225,
            flex: 0.2,
            valueGetter: ({ row }) => {
                const valueToFixed = Number(row.resourceUtilisation).toFixed(2);
                return valueToFixed.toString();
            },
        },
        {
            field: 'avgBookingDuration',
            headerName: i18n.t('Reports_AvgBookingDuration'),
            minWidth: 275,
            flex: 0.2,
        },
        {
            field: 'avgOccupancyDuration',
            headerName: i18n.t('Reports_AvgOccupancyDuration'),
            minWidth: 275,
            flex: 0.2,
        },
        {
            field: 'numberOfBookings',
            headerName: i18n.t('Reports_NumberOfBookings'),
            minWidth: 200,
            flex: 0.1,
        },
        {
            field: 'checkedOutHoursSaved',
            headerName: i18n.t('Reports_CheckedOutHoursSaved'),
            minWidth: 250,
            flex: 0.2,
        },
    ];
};

export const buildQuery = (
    itemsPerPageAmount: number,
    activePage: number,
    sortOptions: any,
    reportsFilter: any,
    activeSideBarFilter?: ActiveColumnFilterInterface,
    isForExport = false,
    totalCount = 0,
    visibleColumns?: string[]
): string => {
    const { selectedSiteGroup, selectedSite, selectedArea } = reportsFilter;
    const ascSorting = new EnumType('ASC');
    const graphQLItems: { [key: string]: any } = { ...items };
    if (visibleColumns) {
        Object.keys(graphQLItems).forEach((key: string) => {
            if (!visibleColumns.includes(key)) {
                delete graphQLItems[key];
            }
        });
    }
    const query = {
        resourceUtilisations: {
            __args: {
                skip: isForExport ? 0 : itemsPerPageAmount * (activePage - 1),
                take: isForExport ? totalCount : itemsPerPageAmount,
                siteGroupId: selectedSiteGroup?.value !== AllListItemOption.value ? selectedSiteGroup?.value : null,
                siteId: selectedSite?.value !== AllListItemOption.value ? selectedSite?.value : null,
                areaId: selectedArea?.value !== AllListItemOption.value ? selectedArea?.value : null,
                from: reportsFilter.dateRange.from.toFormat(DEFAULT_DATE_FORMAT),
                to: reportsFilter.dateRange.to.toFormat(DEFAULT_DATE_FORMAT),
                order: {
                    // default sorting (grouping)
                    ...(sortOptions[0]?.field !== 'siteGroup' && { siteGroup: ascSorting }),
                    ...(sortOptions[0]?.field !== 'site' && { site: ascSorting }),
                    ...(sortOptions[0]?.field !== 'area' && { area: ascSorting }),
                },
                where: {},
            },
            items: graphQLItems,
            totalCount: true,
        },
    };

    if (sortOptions?.length) {
        query.resourceUtilisations.__args.order = {
            [sortOptions[0].field]: new EnumType(sortOptions[0].sort.toUpperCase()),
            ...query.resourceUtilisations.__args.order,
        };
    }

    let where = {};

    if (reportsFilter?.selectedResourceType.value !== AllListItemOption.value) {
        where = {
            ...where,
            type: { eq: reportsFilter.selectedResourceType.label },
        };
    }

    if (activeSideBarFilter?.site?.values) {
        where = {
            ...where,
            siteId: { in: activeSideBarFilter.site.values },
        };
    }

    if (activeSideBarFilter?.type?.values) {
        where = {
            ...where,
            type: {
                in: activeSideBarFilter.type.values.map((item: string) => {
                    let newItem = item;
                    if (item === ResourceType.DESK.value.toString()) {
                        newItem = ResourceType.DESK.name;
                    }
                    if (item === ResourceType.ROOM.value.toString()) {
                        newItem = ResourceType.ROOM.name;
                    }
                    return newItem;
                }),
            },
        };
    }

    if (activeSideBarFilter?.siteGroup?.values) {
        where = {
            ...where,
            siteGroupId: { in: activeSideBarFilter.siteGroup.values },
        };
    }

    if (activeSideBarFilter?.area?.values) {
        where = {
            ...where,
            areaId: { in: activeSideBarFilter.area.values },
        };
    }

    if (Object.keys(where).length === 0) {
        delete query.resourceUtilisations.__args.where;
    } else {
        query.resourceUtilisations.__args.where = where;
    }

    return jsonToGraphQLQuery(query);
};
