import axios from 'axios';
import { DateTime } from 'luxon';
import Config from 'utilities/config';

const updateConfiguration = (callback = () => {}): void => {
    if (!Config.CONFIG_URI) {
        callback();
        return;
    }

    const lastUpdatedValue = sessionStorage.getItem('appConfigLastUpdated');
    const lastUpdated = lastUpdatedValue ? DateTime.fromISO(lastUpdatedValue) : null;
    const configsJson = sessionStorage.getItem('appConfigs');
    if (configsJson && lastUpdated && lastUpdated.plus({ minutes: 5 }) > DateTime.utc()) {
        callback();
        return;
    }

    const appConfigEtag = sessionStorage.getItem('appConfigEtag');

    axios
        .get<PortalConfigurationModel>(Config.CONFIG_URI, {
            headers: {
                'If-None-Match': appConfigEtag || 0,
            },
        })
        .then((response) => {
            sessionStorage.setItem('appConfigLastUpdated', DateTime.utc().toString());

            if (response.status === 304) return;

            sessionStorage.setItem('appConfigEtag', response.headers.etag);
            const { data } = response;
            const config: Config = {
                ORGANISATION_STRUCTURE_API: data.organisationStructureApiBaseUrl,
                IDENTITY_SERVICE: data.identityAuthority,
                IDENTITY_SERVICE_API: data.identityApiBaseUrl,
                ORGANISATION_API: data.organisationApiBaseUrl,
                AUDIT_API: data.auditApiBaseUrl,
                BOOKING_API: data.bookingApiBaseUrl,
                BOOKING_SIGNALR_API: data.bookingSignalrApiBaseUrl,
                RESOURCE_API: data.resourceApiBaseUrl,
                PERSON_API: data.personApiBaseUrl,
                VISITOR_MANAGEMENT_API: data.visitorManagementApiBaseUrl,
                REPORTING_API: data.reportingApiBaseUrl,
                IDENTITY_AUTHORITY: data.identityAuthority,
                PORTAL_DOMAIN: data.portalDomain,
                LANGUAGE_API: data.languageApiBaseUrl,
                REPEATS_ENABLED: data.repeatsEnabled,
                CONFIG_URI: Config.CONFIG_URI,
                ANALYTICS_ENABLED: data.analyticsEnabled,
                SEARCH_API: data.searchApiBaseUrl,
                SEARCH_ENABLED: data.searchEnabled,
            };

            sessionStorage.setItem('appConfigs', JSON.stringify(config));

            callback();
        })
        .catch(() => {
            callback();
        });
};

export const updateAppConfiguration = (callback = () => {}): NodeJS.Timeout => {
    updateConfiguration(callback);
    const interval = setInterval(() => {
        updateConfiguration();
    }, 120000);

    return interval;
};

export const getConfiguration = (): Config => {
    if (!Config.CONFIG_URI) {
        return Config;
    }

    const configsJson = sessionStorage.getItem('appConfigs');

    if (configsJson) {
        return JSON.parse(configsJson);
    }

    return Config;
};

interface PortalConfigurationModel {
    portalUrl: string;
    organisationStructureApiBaseUrl: string;
    identityApiBaseUrl: string;
    organisationApiBaseUrl: string;
    auditApiBaseUrl: string;
    bookingApiBaseUrl: string;
    bookingSignalrApiBaseUrl: string;
    resourceApiBaseUrl: string;
    personApiBaseUrl: string;
    visitorManagementApiBaseUrl: string;
    reportingApiBaseUrl: string;
    identityAuthority: string;
    portalDomain: string;
    languageApiBaseUrl: string;
    repeatsEnabled: string;
    analyticsEnabled: string;
    searchApiBaseUrl: string;
    searchEnabled: string;
}
