import React, { memo, useEffect, useMemo } from 'react';
import DataTable from 'components/DataTable';
import WithLoading from 'components/HOC/WithLoading';
import PageHeader from 'components/PageHeader';
import * as constants from 'features/constants';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/no-cycle
import { HistoryCombinedProps } from './index';
import { getHistoryInfoColumns } from './utils';

import './History.scss';

const History = ({
    isLoading,
    requiresLoading,
    booking,
    fetchHistoryDataById,
    auditLogs,
    setLoadStatus,
    title,
}: HistoryCombinedProps): JSX.Element => {
    const columns = useMemo(() => getHistoryInfoColumns(), [auditLogs]);

    useEffect(() => {
        return () => {
            setLoadStatus(constants.LOAD_STATUSES.REQUIRED);
        };
    }, []);

    useEffect(() => {
        if (requiresLoading && !isLoading) {
            fetchHistoryDataById(booking.id);
        }
    }, [requiresLoading]);

    return (
        <WithLoading isLoading={isLoading}>
            <main>
                <PageHeader title={title} />
                <DataTable columns={columns} rowsData={auditLogs || []} getRowId={() => uuidv4()} />
            </main>
        </WithLoading>
    );
};

export default memo(History);
