import React from 'react';
import ContentLoader from 'components/ContentLoader';

// eslint-disable-next-line import/no-cycle
import { ProviderProps } from '.';

const Provider = (props: React.PropsWithChildren<ProviderProps>): JSX.Element => {
    const { children, loadStatus, setFilters } = props;

    return (
        <ContentLoader load={() => setFilters()} loadStatus={loadStatus}>
            {children}
        </ContentLoader>
    );
};

export default Provider;
