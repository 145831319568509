import React from 'react';
import { connect } from 'react-redux';
import SiteHeader from 'components/SiteHeader';
import Navigation from 'features/SiteNavigation';
import { RootState } from 'PortalTypes';
import { compose } from 'redux';

import './MainLayout.scss';

// @todo: This has only been added for development debugging support. This can be removed in a future scenario where we are confident in the redix state and the persistence of it.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    currentState: state,
});

const MainLayout = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <>
            <SiteHeader />
            <div role="main" className="mainLayout">
                <Navigation />

                {children}
            </div>
        </>
    );
};

export default compose(connect(mapStateToProps, null))(MainLayout);
