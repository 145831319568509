import React from 'react';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';

import BaseIconButton from '../BaseIconButton';

export interface TrashIconButtonProps {
    onClick: Function;
    title: string;
    isDisabled?: boolean;
    required?: boolean;
    hideLabel?: boolean;
    dataTestId?: string;
}

const TrashIconButton = (props: TrashIconButtonProps): JSX.Element => {
    const { onClick, title, dataTestId, isDisabled = false, required = false, hideLabel = false } = props;

    return (
        <BaseIconButton
            className="trash-icon-button"
            required={required}
            title={title}
            hideLabel={hideLabel}
            onClick={onClick}
            isDisabled={isDisabled}
            dataTestId={dataTestId}
            icon={<TrashIcon />}
        />
    );
};

export default TrashIconButton;
