import AuthorizeService from 'features/Authentication/Services/AuthorizeService';
import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';

import {
    UpdateCompanyAttributesCommand,
    UpdateUserDefaultSiteCommand,
    UpdateUserPasswordCommand,
    UpdateUserProfileCommand,
    UserProfile,
} from './Models';

export class IdentityApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().IDENTITY_SERVICE_API;

        return `${baseUrl}/api/v1`;
    };

    public static getUserProfile = async (): Promise<ApiResponse<UserProfile>> => {
        const authenticatedUser = await AuthorizeService.getUser();

        if (!authenticatedUser || !authenticatedUser.email) {
            LoggingService.Debug('User is not authenticated.');
            window.location.replace('/signout');
        }

        const url = `${this.BuildBaseUrl()}/UserProfile/GetSingle?email=${authenticatedUser?.email}`;

        LoggingService.Debug('Sending Identity > getUserProfile query. Query: ', authenticatedUser?.email);
        LoggingService.Debug('Sending Identity > getUserProfile query. Headers:: ', IdentityApi.PersistedHeaders);

        return IdentityApi.get(url);
    };

    public static updateUserProfile = async (
        updateRequest: UpdateUserProfileCommand | UpdateUserDefaultSiteCommand
    ): Promise<any> => {
        LoggingService.Debug('Sending Identity > UserProfile. Patch Request: ', updateRequest);
        LoggingService.Debug('Sending Identity > UserProfile. Patch Headers: ', IdentityApi.PersistedHeaders);

        return IdentityApi.patch<UpdateUserProfileCommand | UpdateUserDefaultSiteCommand>(
            `${this.BuildBaseUrl()}/UserProfile/Me`,
            updateRequest
        );
    };

    public static updateUserPassword = async (updateRequest: UpdateUserPasswordCommand): Promise<any> => {
        LoggingService.Debug('Sending Identity > UserPassword. Put Request: ', updateRequest);
        LoggingService.Debug('Sending Identity > UserPassword. Put Headers: ', IdentityApi.PersistedHeaders);

        return IdentityApi.put<UpdateUserPasswordCommand>(`${this.BuildBaseUrl()}/password/update`, updateRequest);
    };

    public static getRolesByIds = async (ids: string[]): Promise<any> => {
        LoggingService.Debug('Sending Identity > UserPassword. Put Request: ', ids);
        LoggingService.Debug('Sending Identity > UserPassword. Put Headers: ', IdentityApi.PersistedHeaders);

        const rolesIds = ids.map((id) => `roleIds=${id.toString()}`).join('&');

        return IdentityApi.get<any>(`${this.BuildBaseUrl()}/Roles?${rolesIds}`);
    };

    public static updateUserCompanyAttributes = async (updateRequest: UpdateCompanyAttributesCommand): Promise<any> => {
        LoggingService.Debug('Sending Identity > UserProfile Company Attributes. Put Request: ', updateRequest);
        LoggingService.Debug(
            'Sending Identity > UserProfile Company Attributes. Put Headers: ',
            IdentityApi.PersistedHeaders
        );

        return IdentityApi.put<UpdateCompanyAttributesCommand>(
            `${this.BuildBaseUrl()}/UserProfileCompanyAttributes`,
            updateRequest
        );
    };
}
