import { connect } from 'react-redux';
import { setCombinedDate } from 'features/Filters/Redux/actions';
import { getGeographicStructureAreas } from 'features/GeographicStructures/Redux/actions';
import { setReferenceDataAreaLoadStatus } from 'features/ReferenceData/Redux/actions';
// eslint-disable-next-line import/no-cycle
import Provider from 'features/Resources/Common/Provider';
import {
    getResourcesUnavailability,
    refreshBookingUnavailability,
} from 'features/Resources/Unavailability/Redux/actions';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import {
    BookedResourceSummary,
    GetResourcesUnavailabilityQuery,
    SearchBookedResourcesQuery,
} from 'services/ApiClients/Booking';
import { BookingUnavailableTimes } from 'services/ApiClients/Booking/Models';
import { ResourceRequest } from 'services/ApiClients/Resource';

import {
    getSiteConfigurationAsync,
    getTenantConfigurationAsync,
    setSiteConfigurationLoadStatus,
} from '../../Common/Redux/actions';
import {
    getResources,
    getResourcesBookingsAsync,
    setResourcesBookingsLoadStatus,
    setResourcesLoadStatus,
    updateLocalResourceBookings,
} from '../Redux/actions';
import { getProviderData } from '../Redux/selectors';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    data: getProviderData(state),
});

const mapDispatchToProps = {
    setBookingsLoadStatus: setResourcesBookingsLoadStatus,
    getSiteConfiguration: getSiteConfigurationAsync.request,
    getTenantConfiguration: getTenantConfigurationAsync.request,
    getAreas: getGeographicStructureAreas.request,
    getResources: (request: ResourceRequest) => getResources.request(request),
    setAreasLoadStatus: setReferenceDataAreaLoadStatus,
    setResourcesLoadStatus,
    setSiteConfigurationLoadStatus,
    setDate: (selectedDate: DateTime) => setCombinedDate(selectedDate),
    getBookings: (query: SearchBookedResourcesQuery) => getResourcesBookingsAsync.request(query),
    updateBookings: (bookedResources: BookedResourceSummary[]) => updateLocalResourceBookings(bookedResources),
    loadUnavailability: (query: GetResourcesUnavailabilityQuery) => getResourcesUnavailability.request(query),
    refreshBookingUnavailability: (query: BookingUnavailableTimes) => refreshBookingUnavailability(query),
};

export type ProviderProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Provider);
