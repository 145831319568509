import { AllListItemOption } from '../../../../features/constants';

export interface ResourceTypeInterface {
    name: string;
    value: number;
}

export interface TYPES {
    [k: string]: ResourceTypeInterface;
}

export interface Type {
    [k: string]: ResourceTypeInterface;
}

const DESK: ResourceTypeInterface = {
    name: 'Desk',
    value: 0,
};

const ROOM: ResourceTypeInterface = {
    name: 'Room',
    value: 1,
};

const PARKING: ResourceTypeInterface = {
    name: 'PARKING',
    value: 2,
};

export const ResourceType: TYPES = { DESK, ROOM, PARKING };

export const CombinedResourceTypes: TYPES = { DESK, ROOM };

export const ResourceTypeListItems = [
    AllListItemOption,
    { label: ResourceType.DESK.name, value: ResourceType.DESK.value.toString() },
    { label: ResourceType.ROOM.name, value: ResourceType.ROOM.value.toString() },
];

export const findResourceNameByValue = (value: number | undefined): string => {
    const type: string = Object.keys(ResourceType).find((key: string) => ResourceType[key].value === value) || '';
    return ResourceType[type]?.name || '';
};

export const findResourceValueByName = (name: string): number => {
    const type: string = Object.keys(ResourceType).find((key: string) => ResourceType[key].name === name) || '';
    return ResourceType[type]?.value;
};

export const includesResourceTypeValue = (value: number): boolean =>
    Object.keys(ResourceType)
        .map((type) => ResourceType[type].value)
        .includes(value);
