import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import VisitorProfileReport from '../../features/Reports/VisitorProfileReport';

const VisitorProfile = (): JSX.Element => {
    return (
        <MainLayout>
            <VisitorProfileReport />
        </MainLayout>
    );
};

export default VisitorProfile;
