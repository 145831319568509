import React from 'react';
import classNames from 'classnames';

import './HeaderSteps.scss';

export interface HeaderStepsItemProps {
    name: string;
    isFirst?: boolean;
    isLast?: boolean;
    isSelected: boolean;
    isAccessible: boolean;
}

export interface HeaderStepsProps {
    stepNames: string[];
    currentStepNumber: number;
}

const HeaderStepItem = ({
    name,
    isSelected,
    isAccessible,
    isFirst = false,
    isLast = false,
}: HeaderStepsItemProps): JSX.Element => {
    return (
        <div className={classNames('header-step-item', isSelected && 'selected', isAccessible && 'accessible')}>
            {!isFirst && <div className="line before" />}
            <div className="circle-n-name">
                <div className="circle" />
                <div className="name">{name}</div>
                <div className="arrow-up" />
            </div>
            {!isLast && <div className="line after" />}
        </div>
    );
};

const HeaderSteps = ({ stepNames, currentStepNumber }: HeaderStepsProps): JSX.Element => {
    return (
        <div className="header-steps">
            {stepNames.map((name, i) => (
                <HeaderStepItem
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={i}
                    name={name}
                    isFirst={i === currentStepNumber}
                    isLast={i === stepNames.length - 1}
                    isSelected={i === currentStepNumber}
                    isAccessible={i === currentStepNumber + 1}
                />
            ))}
        </div>
    );
};

export default HeaderSteps;
