import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import ResourceUtilisationReport from '../../features/Reports/ResourceUtilisationReport';

const ResourceUtilisation = (): JSX.Element => {
    return (
        <MainLayout>
            <ResourceUtilisationReport />
        </MainLayout>
    );
};

export default ResourceUtilisation;
