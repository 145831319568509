import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { SiteVisit } from '../../../services/ApiClients/VisitorManagement/Models/SiteVisit';

import { editExternalVisitorFromReception, getEvacuationReports, getSiteVisits } from './actions';

interface ReceptionState {
    siteVisits: SiteVisit[] | null;
    areSiteVisitsLoading: boolean;
    isGetReportsInProgress: boolean;
    isVisitorEditingInProgress: boolean;
}

export default combineReducers<ReceptionState>({
    areSiteVisitsLoading: createReducer<boolean>(false)
        .handleAction([getSiteVisits.request], () => true)
        .handleAction([getSiteVisits.success, getSiteVisits.failure], () => false),

    siteVisits: createReducer<SiteVisit[] | null>(null)
        .handleAction(
            [getSiteVisits.success],
            (state, action) => (action.payload.payload as { siteVisits: SiteVisit[] }).siteVisits
        )
        .handleAction([getSiteVisits.failure], () => null)
        .handleAction([getSiteVisits.request], () => null),

    isGetReportsInProgress: createReducer<boolean>(false)
        .handleAction([getEvacuationReports.request], () => true)
        .handleAction([getEvacuationReports.success, getEvacuationReports.failure], () => false),

    isVisitorEditingInProgress: createReducer<boolean>(false)
        .handleAction([editExternalVisitorFromReception.request], () => true)
        .handleAction(
            [editExternalVisitorFromReception.success, editExternalVisitorFromReception.failure],
            () => false
        ),
});
