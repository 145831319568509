// These are loaded from the local environment variables at build time (.env) and can be replaced by
// a deployment pipeline post build if needed.
window.env = {
    ORGANISATION_STRUCTURE_API: process.env.REACT_APP_CBPORTAL_ORGANISATION_STRUCTURE_API,
    IDENTITY_SERVICE: process.env.REACT_APP_CBPORTAL_IDENTITY_SERVICE,
    IDENTITY_SERVICE_API: process.env.REACT_APP_CBPORTAL_IDENTITY_SERVICE_API,
    ORGANISATION_API: process.env.REACT_APP_CBPORTAL_ORGANISATION_API,
    AUDIT_API: process.env.REACT_APP_CBPORTAL_AUDIT_API,
    BOOKING_API: process.env.REACT_APP_CBPORTAL_BOOKING_API,
    BOOKING_SIGNALR_API: process.env.REACT_APP_CBPORTAL_BOOKING_SIGNALR_API,
    RESOURCE_API: process.env.REACT_APP_CBPORTAL_RESOURCE_API,
    PERSON_API: process.env.REACT_APP_CBPORTAL_PERSON_API,
    VISITOR_MANAGEMENT_API: process.env.REACT_APP_CBPORTAL_VISITOR_MANAGEMENT_API,
    REPORTING_API: process.env.REACT_APP_CBPORTAL_REPORTING_API,
    IDENTITY_AUTHORITY: process.env.REACT_APP_CBPORTAL_IDENTITY_AUTHORITY,
    PORTAL_DOMAIN: process.env.REACT_APP_CBPORTAL_PORTAL_DOMAIN,
    LANGUAGE_API: process.env.REACT_APP_CBPORTAL_LANGUAGE_API,
    ENV: process.env.REACT_APP_CBPORTAL_PORTAL_ENVIRONMENT,
    REPEATS_ENABLED: process.env.REACT_APP_CBPORTAL_REPEATS_ENABLED,
    CONFIG_URI: process.env.REACT_APP_CBPORTAL_CONFIG_URI,
    ANALYTICS_ENABLED: process.env.REACT_APP_CBPORTAL_ANALYTICS_ENABLED,
    SEARCH_ENABLED: process.env.REACT_APP_CBPORTAL_SEARCH_ENABLED,
    SEARCH_API: process.env.REACT_APP_CBPORTAL_SEARCH_API,
};
