import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createPrimaryButton } from 'components/ActionButtons/Models/ActionButton';
import ActionButtonsPanel from 'components/ActionButtonsPanel';
import { ListItem } from 'components/Forms';
import Header from 'components/SiteSubHeader';
import { AccountState } from 'features/Account/Models/AccountState';
import {
    CompanyAttributesErrorModel,
    CompanyAttributesModel,
} from 'services/ApiClients/Identity/Models/UpdateUserProfileCommand';
import { SpecifiedCompanyAttributesResponse } from 'services/ApiClients/Organization/Models/CompanyAttributes';

import CompanyAttributes from './CompanyAttributes';
import PersonalDetails from './PersonalDetails';
import Settings from './Settings';

import './Account.scss';

interface AccountProps {
    isSso: boolean;
    initialValues: AccountState;
    salutations: ListItem[];
    sites: ListItem[];
    onSave: (data: any) => void;
    onOpenPasswordPanel: () => void;
    assignedCompanyAttributes: SpecifiedCompanyAttributesResponse[];
    availableCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
    setSelectedCompanyAttributes: any;
    selectedCompanyAttributes: CompanyAttributesModel[];
    allowEditCompanyAttributes: boolean;
}

const Account = (props: AccountProps): JSX.Element => {
    const {
        isSso,
        initialValues,
        salutations,
        onSave,
        onOpenPasswordPanel,
        sites,
        assignedCompanyAttributes,
        availableCompanyAttributes,
        setSelectedCompanyAttributes,
        selectedCompanyAttributes,
        allowEditCompanyAttributes,
    } = props;
    const [companyAttributeErrors, setCompanyAttributeErrors] = React.useState<CompanyAttributesErrorModel[]>([]);
    const { register, control, handleSubmit, errors, setValue, clearErrors } = useForm();
    const { t } = useTranslation();

    const buttons: any[] = [createPrimaryButton('primary', t('Button_Save'))];

    return (
        <main className="pageMyAccount">
            <Header title={t('MyAccount_Title')} dataTestId="pageMyAccount-title" />
            <div className="pageMyAccountContent">
                <form onSubmit={handleSubmit(onSave)}>
                    <div className="content">
                        <PersonalDetails
                            initialValues={initialValues}
                            salutations={salutations}
                            register={register}
                            errors={errors}
                            control={control}
                            isSso={isSso}
                            setValue={setValue}
                            clearErrors={clearErrors}
                        />

                        {allowEditCompanyAttributes ||
                        (!allowEditCompanyAttributes && selectedCompanyAttributes?.length) ? (
                            <>
                                <hr />
                                <CompanyAttributes
                                    availableCompanyAttributes={availableCompanyAttributes}
                                    assignedCompanyAttributes={assignedCompanyAttributes}
                                    initialValues={initialValues}
                                    setSelectedCompanyAttributes={setSelectedCompanyAttributes}
                                    selectedCompanyAttributes={selectedCompanyAttributes}
                                    setCompanyAttributeErrors={setCompanyAttributeErrors}
                                    companyAttributeErrors={companyAttributeErrors}
                                />
                            </>
                        ) : null}

                        <hr />
                        <Settings
                            initialValues={initialValues}
                            sites={sites}
                            control={control}
                            onOpenPasswordPanel={onOpenPasswordPanel}
                        />
                        <hr />
                    </div>
                    <ActionButtonsPanel
                        buttons={buttons}
                        disabled={companyAttributeErrors.length > 0}
                        className="myaccountActionButtonsPanel"
                    />
                </form>
            </div>
        </main>
    );
};

export default Account;
