import { DateTime } from 'luxon';
import {
    BookableDays,
    CombinedConfiguration,
    Configuration,
    ConfigurationResponse,
} from 'services/ApiClients/Configuration';

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const nonBookableDayConst = -1;

export default class ConfigurationService {
    public static mapFromResponse(configurationResponse: ConfigurationResponse): Configuration {
        const response: Configuration = {
            bookingSlotUnits: configurationResponse.configurationSettings.bookingSlotUnits,
            reservationWindowInSeconds: configurationResponse.configurationSettings.reservationWindowInSeconds,
            bookableDays: {},
            noticePeriodDuration: configurationResponse.configurationSettings.noticePeriodDuration,
            noticePeriodUnit: configurationResponse.configurationSettings.noticePeriodUnit,
            configurationType: configurationResponse.configurationType,
            allowPrivateBookings: configurationResponse.configurationSettings.allowPrivateBookings,
            setAllBookingsAsPrivate: configurationResponse.configurationSettings.setAllBookingsAsPrivate,
            isRepeatBookingsEnabled: configurationResponse.configurationSettings.isRepeatBookingsEnabled,
        };

        daysOfWeek.map((day): Configuration => {
            if (
                configurationResponse.configurationSettings[`${day}ReservationWindowStartMinutesFromMidnight`] !==
                nonBookableDayConst
            ) {
                const bookableDay = {
                    bookingWindowStartMinutesFromMidnight:
                        configurationResponse.configurationSettings[`${day}ReservationWindowStartMinutesFromMidnight`],
                    bookingWindowEndMinutesFromMidnight:
                        configurationResponse.configurationSettings[`${day}ReservationWindowEndMinutesFromMidnight`],
                };

                if (response.bookableDays) {
                    response.bookableDays[day] = bookableDay;
                }
            }

            return response;
        });

        return response;
    }

    public static combine(configurations: Configuration[]): CombinedConfiguration | null {
        if (!configurations.length) {
            return null;
        }

        const firstConfiguration = configurations[0];
        const firstConfigurationResourceType = firstConfiguration.configurationType ?? '';

        if (configurations.length === 1) {
            return {
                bookableDays: firstConfiguration.bookableDays,
                bookingSlotUnits: firstConfiguration.bookingSlotUnits,
                isRepeatBookingsEnabled: firstConfiguration.isRepeatBookingsEnabled,
                reservationWindowInSeconds: firstConfiguration.reservationWindowInSeconds,
                setAllBookingsAsPrivate: firstConfiguration.setAllBookingsAsPrivate,
                noticePeriodDurations: { [firstConfigurationResourceType]: firstConfiguration.noticePeriodDuration },
                noticePeriodUnits: { [firstConfigurationResourceType]: firstConfiguration.noticePeriodUnit },
                configurationTypes: [firstConfigurationResourceType],
                allowPrivateBookings: { [firstConfigurationResourceType]: firstConfiguration.allowPrivateBookings },
            } as CombinedConfiguration;
        }

        // counting smallest bookingSlotUnit in all resources
        const bookingSlotUnits = configurations.reduce((acc: number, configuration: Configuration) => {
            if (configuration.bookingSlotUnits < acc) {
                // eslint-disable-next-line no-param-reassign
                acc = configuration.bookingSlotUnits ?? 0;
            }
            return acc;
        }, 12);

        const { reservationWindowInSeconds, setAllBookingsAsPrivate, isRepeatBookingsEnabled } = firstConfiguration;

        const bookableDays = daysOfWeek.reduce((acc, x) => {
            const weekDayBookableDays = configurations.map((y) => y.bookableDays[x]).filter((y) => !!y);
            if (!weekDayBookableDays.length) {
                return acc;
            }

            const bookingWindowStartMinutesFromMidnights = weekDayBookableDays.map(
                (y) => y.bookingWindowStartMinutesFromMidnight
            );
            const minBookingWindowStartMinutesFromMidnight = Math.min(...bookingWindowStartMinutesFromMidnights);

            const bookingWindowEndMinutesFromMidnights = weekDayBookableDays.map(
                (y) => y.bookingWindowEndMinutesFromMidnight
            );
            const maxBookingWindowEndMinutesFromMidnight = Math.max(...bookingWindowEndMinutesFromMidnights);

            return {
                ...acc,
                [x]: {
                    bookingWindowStartMinutesFromMidnight: minBookingWindowStartMinutesFromMidnight,
                    bookingWindowEndMinutesFromMidnight: maxBookingWindowEndMinutesFromMidnight,
                },
            };
        }, {} as BookableDays);

        const combinedConfiguration: CombinedConfiguration = {
            bookingSlotUnits,
            reservationWindowInSeconds,
            bookableDays,
            noticePeriodDurations: Object.assign(
                {},
                ...configurations.map((c) => ({ [c.configurationType ?? '']: c.noticePeriodDuration }))
            ),
            noticePeriodUnits: Object.assign(
                {},
                ...configurations.map((c) => ({ [c.configurationType ?? '']: c.noticePeriodUnit }))
            ),
            configurationTypes: configurations.map((c) => c.configurationType ?? ''),
            allowPrivateBookings: Object.assign(
                {},
                ...configurations.map((c) => ({ [c.configurationType ?? '']: c.allowPrivateBookings }))
            ),
            setAllBookingsAsPrivate,
            isRepeatBookingsEnabled,
        };

        return combinedConfiguration;
    }

    static isRangeAvailable = (
        configuration: Configuration,
        start: DateTime,
        end: DateTime,
        selectedDay: string
    ): boolean => {
        const bookableDay = configuration.bookableDays[selectedDay];
        if (!bookableDay) {
            return false;
        }

        const specificBeginsHour = bookableDay.bookingWindowStartMinutesFromMidnight / 60;
        const specificEndsHour = bookableDay.bookingWindowEndMinutesFromMidnight / 60;

        const currentEndHours = end.hour;
        const currentEndMinutes = end.minute;

        const currentStartHours = start.hour;
        const currentStartMinutes = start.minute;

        let currentCellEndHours = currentEndHours + currentEndMinutes / 60;
        // need this as last cell in a day end time is 0
        if (currentCellEndHours === 0) {
            currentCellEndHours = 24;
        }

        const currentCellStartHours = currentStartHours + currentStartMinutes / 60;

        if (currentCellStartHours < specificBeginsHour) {
            return false;
        }

        if (currentCellEndHours > specificEndsHour) {
            return false;
        }

        return true;
    };
}
