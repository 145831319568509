import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FloorPin } from 'services/ApiClients/Resource';

import './Tooltip.scss';

export interface TooltipProps {
    style: any;
    className: string | undefined;
    text: string;
    position: string;
    maxWidth: number;
    isHighlighting?: boolean;
    setPins?: (pins: FloorPin[]) => void;
    filteredPins?: FloorPin[];
}

const Tooltip = ({
    style,
    className,
    text,
    position,
    maxWidth,
    isHighlighting,
    setPins,
    filteredPins,
}: TooltipProps): JSX.Element => {
    const tooltipRef = useRef<any>(null);

    const handleClick = (): void => {
        const resetHighlighting = filteredPins?.map((item: FloorPin) => {
            if (item.isHighlighting) {
                return { ...item, isHighlighting: false };
            }
            return item;
        });

        if (setPins) {
            setPins(resetHighlighting as FloorPin[]);
        }
    };

    useEffect(() => {
        if (tooltipRef?.current?.classList?.contains('isHighlighting') && isHighlighting) {
            document.addEventListener('click', handleClick);
        }
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [tooltipRef, handleClick]);

    return (
        <div ref={tooltipRef} className={classNames('tooltipWrapper', position, { isHighlighting })} style={style}>
            <div className={classNames('tooltip', className)} style={{ maxWidth }}>
                <span className={classNames('tooltipText', position)}>{text}</span>
            </div>
        </div>
    );
};

export default Tooltip;
