import React from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/DatePickerArrow.svg';
import { ReactComponent as DateIcon } from 'assets/icons/DatePickerCalendar.svg';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import './field.scss';

export interface FieldProps {
    onFocus?(): void;
    dataTestId: string;
    disabled?: boolean;
    dateRange: any;
}

const Field = ({
    onFocus,
    dataTestId,
    disabled = false,
    dateRange,
}: React.PropsWithChildren<FieldProps>): JSX.Element => {
    const handleFocus = (): void => {
        if (!onFocus || disabled) {
            return;
        }

        onFocus();
    };

    const format = 'd MMM yy';
    return (
        <div
            className={classNames('date-range-picker-field', { disabled })}
            data-testid={dataTestId}
            onFocus={handleFocus}
            onClick={handleFocus}
        >
            <div className="dateIcon">
                <DateIcon />
            </div>

            <div className="dateText">{`${dateRange?.from?.toFormat(format) || DateTime.now().toFormat(format)}`}</div>

            <div className="arrowIcon">
                <ArrowIcon />
            </div>

            <div className="dateText">{`${dateRange?.to?.toFormat(format) || DateTime.now().toFormat(format)}`}</div>
        </div>
    );
};

export default Field;
