import { AxiosError } from 'axios';
import { Dictionary } from 'lodash';
import { GetResourcesUnavailabilityQuery } from 'services/ApiClients/Booking';
import { BookingUnavailableTimes, UnavailableTime } from 'services/ApiClients/Booking/Models';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getResourcesUnavailability = createAsyncAction(
    'GET_RESOURCES_UNAVAILABILITY_REQUEST',
    'GET_RESOURCES_UNAVAILABILITY_SUCCESS',
    'GET_RESOURCES_UNAVAILABILITY_FAILURE'
)<GetResourcesUnavailabilityQuery, Dictionary<UnavailableTime[]>, AxiosError>();

export const refreshBookingUnavailability = createAction('REFRESH_BOOKING_UNAVAILABILITY')<BookingUnavailableTimes>();

export default {
    getResourcesUnavailability,
    refreshBookingUnavailability,
};
