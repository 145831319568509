import React, { memo, useEffect } from 'react';
import BookingHeader from 'components/BookingHeader';
import GridFilterPanel from 'components/GridFilterPanel';
import { VIEW_TYPE } from 'features/constants';
import Grid from 'features/Resources/Common/Grid';
import { getActiveFilters, getMultipleFilters, getStaticFilter } from 'utilities/filterUtils';

// eslint-disable-next-line import/no-cycle
import { Props } from '../Combined';

import FloorPlan from './FloorPlan';

import 'features/Resources/Common/ResourceView.scss';

const ResourceView = ({
    viewType,
    headerData,
    gridData,
    floorPlanData,
    setSelectedArea,
    onViewSelect,
    setDisabledAccess,
    resetAdvancedFilters,
    bookAResource,
    updateBookedResource,
    setDate,
    setEquipmentsFilter,
    setToggleFilter,
    navigateToEditBooking,
    updateSingleOccurrence,
    permissions,
    userProfileId,
    setExpandedResources,
    expandedResources,
    getResourceImage,
    resetResourceImage,
    getUsers,
    getRoles,
    resetUsers,
    resetRoles,
    getAttributes,
    resetAttributes,
    getBookingsByResourcesIds,
    resetBookingsByResourcesIds,
    bookingsByResourcesIdsLoadStatus,
    updateWholeSeries,
    setFloorPlanTimeLineFilter,
    setFloorPlanTimeLineDurationFilter,
    setSearchTrigger,
    tenantCompanyAttributes,
    getTenantCompanyAttributes,
}: Props): JSX.Element => {
    const [activeAdvancedFilters, setActiveAdvancedFilters] = React.useState<any[]>([]);

    const { equipment, disabledAccess } = gridData.advancedFilters;

    useEffect(() => {
        getTenantCompanyAttributes();
    }, []);

    useEffect(() => {
        const equipmentFilters = getMultipleFilters(equipment, (selectedId) => {
            setEquipmentsFilter({
                ...equipment,
                [selectedId]: { ...equipment[selectedId], checked: !equipment[selectedId].checked },
            });
        });

        const disabledAccessFilter = getStaticFilter('disabledAccess', disabledAccess, () => {
            setDisabledAccess(false);
        });
        const filters = [...equipmentFilters, disabledAccessFilter];

        setActiveAdvancedFilters(getActiveFilters(filters));
    }, [gridData.advancedFilters]);

    return (
        <div className="container">
            <BookingHeader
                headerData={headerData}
                setSelectedArea={setSelectedArea}
                onViewSelect={onViewSelect}
                setDisabledAccess={setDisabledAccess}
                resetAdvancedFilters={resetAdvancedFilters}
                viewType={viewType}
                setDate={setDate}
                setEquipmentsFilter={setEquipmentsFilter}
                setToggleFilter={setToggleFilter}
                setFloorPlanTimeLineFilter={setFloorPlanTimeLineFilter}
                setFloorPlanTimeLineDurationFilter={setFloorPlanTimeLineDurationFilter}
                setSearchTrigger={setSearchTrigger}
                floorPlanTimelineFilter={floorPlanData.floorPlanTimelineFilter}
            />
            <GridFilterPanel
                advancedFilters={activeAdvancedFilters}
                filterPanelOpen={activeAdvancedFilters.length > 0}
            />
            {viewType === VIEW_TYPE.GRID || !floorPlanData?.area ? (
                <Grid
                    gridData={gridData}
                    userProfileId={userProfileId}
                    permissions={permissions}
                    bookAResource={bookAResource}
                    updateBookedResource={updateBookedResource}
                    setDate={setDate}
                    updateSingleOccurrence={updateSingleOccurrence}
                    isFilterPanelOpen={activeAdvancedFilters.length > 0}
                    setExpandedResources={setExpandedResources}
                    expandedResources={expandedResources}
                    getResourceImage={getResourceImage}
                    resetResourceImage={resetResourceImage}
                    getUsers={getUsers}
                    getRoles={getRoles}
                    getAttributes={getAttributes}
                    resetUsers={resetUsers}
                    resetRoles={resetRoles}
                    resetAttributes={resetAttributes}
                    updateWholeSeries={updateWholeSeries}
                    tenantCompanyAttributes={tenantCompanyAttributes}
                />
            ) : (
                <FloorPlan
                    floorPlanData={floorPlanData}
                    bookAResource={bookAResource}
                    navigateToEditBooking={navigateToEditBooking}
                    resources={gridData.resources}
                    getResourceImage={getResourceImage}
                    resetResourceImage={resetResourceImage}
                    infoSliderData={gridData.infoSliderData}
                    getUsers={getUsers}
                    getRoles={getRoles}
                    getAttributes={getAttributes}
                    resetUsers={resetUsers}
                    resetRoles={resetRoles}
                    resetAttributes={resetAttributes}
                    getBookingsByResourcesIds={getBookingsByResourcesIds}
                    resetBookingsByResourcesIds={resetBookingsByResourcesIds}
                    bookingsByResourcesIdsLoadStatus={bookingsByResourcesIdsLoadStatus}
                    setFloorPlanTimeLineFilter={setFloorPlanTimeLineFilter}
                    setFloorPlanTimeLineDurationFilter={setFloorPlanTimeLineDurationFilter}
                    tenantCompanyAttributes={tenantCompanyAttributes}
                />
            )}
        </div>
    );
};

export default memo(ResourceView);
