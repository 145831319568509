import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { OnChangeValue } from 'react-select';
import { ReactComponent as Filters } from 'assets/icons/Filters.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/floorPlan/SearchIcon.svg';
import { ReactComponent as FloorPlanIcon } from 'assets/icons/gridHeader/FloorPlan.svg';
import { ReactComponent as GridIcon } from 'assets/icons/gridHeader/Grid.svg';
import classNames from 'classnames';
import AdvancedFilters from 'components/AdvancedFilters';
import ToggleFilter from 'components/AdvancedFilters/ToggleFilter';
import BackgroundMask from 'components/BackgroundMask';
import ViewsPanel from 'components/BookingGrid/Components/ViewsPanel';
import Button from 'components/FormElements/Button';
import DatePickerForHeader from 'components/FormElements/DatePicker/DatePickerForHeader';
import InputWrapper from 'components/FormElements/InputWrapper';
import NewSlidingPanel from 'components/NewSlidingPanel';
import { ListItem } from 'components/Select';
import SlidingPanel from 'components/SlidingPanel';
import { ALL_DAY, CUSTOM_DURATION, VIEW_TYPE } from 'features/constants';
import { FloorPlanTimelineFilterState } from 'features/Filters/Redux/reducer';
import FloorPlanSearch from 'features/Resources/Common/FloorPlan/FloorPlanHeader/FloorPlanSearch/index';
import { SearchDiscriminator } from 'features/Resources/Common/FloorPlan/FloorPlanHeader/FloorPlanSearch/utils';
import { FloorPlanState } from 'features/Resources/Common/FloorPlan/Redux/floorPlanReducer';
import { FeatureToggle, On } from 'featuretoggle-react';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { CustomFilter, GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { ResourceType } from 'services/ApiClients/Resource';
import { getActiveFilters, getMultipleFilters, getStaticFilter } from 'utilities/filterUtils';
import { isNil } from 'utilities/ts';

import './BookingHeader.scss';

interface ToggleFilter {
    name: string;
    value: number;
    isChecked: boolean;
}

interface HeaderDataProps {
    areas: GeographicStructureItem[];
    areaListItems: ListItem[];
    area: GeographicStructureItem | null;
    advancedFilters: any;
    title: string;
    selectedDate: DateTime;
    timezone: string;
    toggleFilters: ToggleFilter[];
    floorPlanData: FloorPlanState;
    floorPlanTimelineFilter: FloorPlanTimelineFilterState;
}

interface BookingHeaderProps {
    setSelectedArea: (param: GeographicStructureItem | null) => void;
    onViewSelect: (param: VIEW_TYPE) => void;
    setDisabledAccess: (param: boolean) => void;
    resetAdvancedFilters: (param: void) => void;
    setDate: (param: DateTime) => void;
    setEquipmentsFilter: (param: { [k: string]: CustomFilter }) => void;
    setToggleFilter: (value: number) => void;
    viewType: VIEW_TYPE;
    headerData: HeaderDataProps;
    setFloorPlanTimeLineFilter: (param: any) => void;
    setFloorPlanTimeLineDurationFilter: (param: string | number) => void;
    setSearchTrigger: (param: boolean) => void;
    floorPlanTimelineFilter: FloorPlanTimelineFilterState;
}

const BookingHeader = ({
    setSelectedArea,
    onViewSelect,
    setDisabledAccess,
    resetAdvancedFilters,
    setEquipmentsFilter,
    setDate,
    setToggleFilter,
    viewType,
    headerData,
    setFloorPlanTimeLineFilter,
    setFloorPlanTimeLineDurationFilter,
    setSearchTrigger,
    floorPlanTimelineFilter,
}: BookingHeaderProps): JSX.Element => {
    const [slidingPanelOpen, setSlidingPanelOpen] = useState<boolean>(false);
    const [filterPanelOpen, setFilterPanelOpen] = useState<boolean>(false);
    const [defaultValue, setDefaultValue] = useState<ListItem>();
    const [openSearchPanel, setOpenSearchPanel] = useState<boolean>(false);

    const { areas, areaListItems, area, advancedFilters, title, selectedDate, timezone, toggleFilters, floorPlanData } =
        headerData;

    const { t } = useTranslation();
    const isFloorPlan = viewType === VIEW_TYPE.FLOOR_PLAN;
    const AREA_ALL_OPTION: ListItem = { label: t('BookingGridHeader_Area_Selector_All'), value: '', displayOrder: 1 };
    const options = isFloorPlan ? areaListItems : [AREA_ALL_OPTION].concat(areaListItems);

    useEffect(() => {
        const equipmentFilters = getMultipleFilters(advancedFilters.equipment);
        const disabledAccessFilter = getStaticFilter('disabledAccess', advancedFilters.disabledAccess);
        const filters = [...equipmentFilters, disabledAccessFilter];

        setFilterPanelOpen(getActiveFilters(filters).length > 0);
    }, [advancedFilters]);

    useEffect(() => {
        const areaWithImage = area?.floorPlanImage ? area : areas.find(({ floorPlanImage }) => floorPlanImage);
        const defaultArea =
            area?.isDirtyAreaFilter && areaWithImage
                ? { ...areaWithImage, isDirtyAreaFilter: true }
                : { ...areaWithImage, isDirtyAreaFilter: false };
        const isDirtyArea = area?.isDirtyAreaFilter ? area : null;

        const selectedArea: ListItem =
            isFloorPlan && defaultArea?.id && defaultArea?.name
                ? { label: defaultArea.name, value: defaultArea.id.toString() }
                : AREA_ALL_OPTION;

        setSelectedArea((isFloorPlan && defaultArea ? defaultArea : isDirtyArea) as GeographicStructureItem);
        setDefaultValue(selectedArea);
    }, [viewType]);

    useEffect(() => {
        const searchArea = areas.find((item) => item.id.toString() === floorPlanData.searchResultItem?.areaId);

        if (!isNil(floorPlanData.searchResultItem) && floorPlanData.searchTrigger) {
            const isBookingDiscriminator =
                floorPlanData.searchResultItem?.discriminator === SearchDiscriminator.BOOKING;
            const isResourceDiscriminator =
                floorPlanData.searchResultItem?.discriminator === SearchDiscriminator.RESOURCE;
            const startDateTime = DateTime.fromISO(floorPlanData?.searchResultItem?.startTime as string);
            const endDateTime = DateTime.fromISO(floorPlanData?.searchResultItem?.endTime as string);

            if (area?.id?.toString() !== floorPlanData.searchResultItem?.areaId) {
                setSelectedArea(searchArea as GeographicStructureItem);
            }

            if (isBookingDiscriminator) {
                setFloorPlanTimeLineDurationFilter(CUSTOM_DURATION);
                setFloorPlanTimeLineFilter({
                    startDateTime,
                    endDateTime,
                });

                if (
                    selectedDate.setZone(timezone).startOf('day').toString() !==
                    startDateTime.setZone(timezone).startOf('day').toString()
                ) {
                    setDate(startDateTime);
                }
            }

            if (isResourceDiscriminator) {
                setFloorPlanTimeLineDurationFilter(ALL_DAY);
                setFloorPlanTimeLineFilter(null);
            }

            setSearchTrigger(false);
        }
    }, [floorPlanData]);

    const onSelectArea = (selectedArea: OnChangeValue<ListItem, false>): void => {
        const selected = (selectedArea as ListItem).value;
        if (!selected) {
            setSelectedArea(null);
        }

        const selectedGeographicStructureArea = areas.find((item) => item.id.toString() === selected);
        const isDirtySelectedGeographicStructureArea = selectedGeographicStructureArea
            ? {
                  ...selectedGeographicStructureArea,
                  isDirtyAreaFilter: true,
              }
            : null;
        setSelectedArea(isDirtySelectedGeographicStructureArea as GeographicStructureItem);
    };

    const value = area ? { label: area.name, value: area.id.toString() } : defaultValue;

    const onOpenSlidingPanel = (): void => {
        setSlidingPanelOpen(!slidingPanelOpen);
    };
    const onDone = (): void => {
        setSlidingPanelOpen(false);
    };

    const viewPanelControls = useMemo(
        () => [
            {
                name: VIEW_TYPE.GRID.toLowerCase(),
                icon: GridIcon,
                onClick: () => onViewSelect(VIEW_TYPE.GRID),
                active: !isFloorPlan || isEmpty(areas),
                disabled: false,
            },
            {
                name: VIEW_TYPE.FLOOR_PLAN.toLowerCase(),
                icon: FloorPlanIcon,
                onClick: () => onViewSelect(VIEW_TYPE.FLOOR_PLAN),
                active: isFloorPlan,
                disabled: !areas.some((item) => item.floorPlanImage) || (!!area && !area?.floorPlanImage),
            },
        ],
        [area, viewType, areas]
    );

    const handleOnToggleChange = (newValue: number): void => {
        setToggleFilter(newValue);

        if (
            toggleFilters[newValue]?.name === ResourceType.DESK.name &&
            !toggleFilters[ResourceType.ROOM.value]?.isChecked
        ) {
            setToggleFilter(ResourceType.ROOM.value);
        }
        if (
            toggleFilters[newValue]?.name === ResourceType.ROOM.name &&
            !toggleFilters[ResourceType.DESK.value]?.isChecked
        ) {
            setToggleFilter(ResourceType.DESK.value);
        }

        resetAdvancedFilters();
    };

    const handleOpenSearchSlidingPanel = (): void => {
        setOpenSearchPanel(true);
    };

    const handleCloseSearchSlidingPanel = (): void => {
        setOpenSearchPanel(false);
    };

    return (
        <div className="bookingHeader">
            {isFloorPlan && !isEmpty(areas) ? (
                <div className="headerDatePicker">
                    <DatePickerForHeader
                        viewType={viewType}
                        setDate={setDate}
                        selectedDate={selectedDate}
                        timezone={timezone}
                        floorPlanTimelineFilter={floorPlanTimelineFilter}
                        setFloorPlanTimeLineFilter={setFloorPlanTimeLineFilter}
                    />
                </div>
            ) : (
                <h2 className="headerTitle">{t(title)}</h2>
            )}
            <div className="resourcesFilter">
                {toggleFilters.map(({ name, isChecked, value: resourceType }) => {
                    return (
                        <ToggleFilter
                            key={name}
                            label=""
                            title={`${name}s`}
                            inputId={name}
                            onChange={() => handleOnToggleChange(resourceType)}
                            dataTestId={`toggle_${name}`}
                            checked={isChecked}
                            value={name}
                            isDisabled={toggleFilters.length <= 1}
                        />
                    );
                })}
            </div>
            <div className="filterContainer">
                <div className="viewTypes">
                    <ViewsPanel controls={viewPanelControls} />
                </div>
                <div className="areas">
                    <InputWrapper label={t('BookingGridHeader_Area')} inputName="siteAreas">
                        <Select
                            id="siteAreas"
                            value={value}
                            options={options}
                            className="select-container areas"
                            classNamePrefix="select"
                            onChange={onSelectArea}
                        />
                    </InputWrapper>
                </div>

                <div className="filterView">
                    <div className="dividerLine" />
                    <Button
                        className={classNames('advancedFilterButton', { filtersOpen: filterPanelOpen })}
                        type="button"
                        text={t('BookingGridHeader_FilterView')}
                        icon={Filters}
                        dataTestId="bookingGrid-advancedFilters"
                        onClick={onOpenSlidingPanel}
                    />
                    <FeatureToggle feature="searchFeature">
                        <On>
                            {isFloorPlan && (
                                <Button
                                    className="advancedFilterButton searchButton"
                                    type="button"
                                    text="search"
                                    icon={SearchIcon}
                                    dataTestId="bookingGrid-searchButton"
                                    onClick={handleOpenSearchSlidingPanel}
                                />
                            )}
                        </On>
                    </FeatureToggle>
                </div>
            </div>
            {slidingPanelOpen && (
                <BackgroundMask className={classNames({ open: slidingPanelOpen })} onClick={onOpenSlidingPanel} />
            )}
            <SlidingPanel open={slidingPanelOpen} onClose={onOpenSlidingPanel} title={t('AdvancedFilters_Title')}>
                <AdvancedFilters
                    advancedFilters={advancedFilters}
                    setDisabledAccess={setDisabledAccess}
                    setEquipmentsFilter={setEquipmentsFilter}
                    onDone={onDone}
                    onResetClick={() => resetAdvancedFilters()}
                />
            </SlidingPanel>

            <NewSlidingPanel
                topActions
                showBackgroundMask
                open={openSearchPanel}
                onClose={handleCloseSearchSlidingPanel}
                dataTestId="floorplan-search-sliding-panel"
            >
                {openSearchPanel ? <FloorPlanSearch onClose={handleCloseSearchSlidingPanel} /> : null}
            </NewSlidingPanel>
        </div>
    );
};

export default memo(BookingHeader);
