import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { GetResourcesAvailabilityQuery, ResourceAvailabilityResponse } from 'services/ApiClients/Booking/Models';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getResourcesAvailabilityAsync = createAsyncAction(
    'GET_RESOURCES_AVAILABILITY_REQUEST',
    'GET_RESOURCES_AVAILABILITY_SUCCESS',
    'GET_RESOURCES_AVAILABILITY_FAILURE'
)<GetResourcesAvailabilityQuery, ResourceAvailabilityResponse, AxiosError | ApiResponse>();

export const setResourcesAvailabilityLoadStatus = createAction('SET_RESOURCES_AVAILABILITY_STATUS')<string>();

export const resetResourcesAvailability = createAction('RESET_RESOURCES_AVAILABILITY')<void>();

export default {
    getResourcesAvailabilityAsync,
    setResourcesAvailabilityLoadStatus,
    resetResourcesAvailability,
};
