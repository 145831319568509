import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from '../../../assets/icons/PlusIcon.svg';
import { PersonType } from '../../../enums/PersonType';
import { Person } from '../../../services/ApiClients/Models';
import { PersonSearchData } from '../interfaces/PersonSearchData';
import PersonSearchResultItem from '../PersonSearchResultItem/PersonSearchResultItem';

import './PersonSearchResult.scss';

interface PersonSearchResultProps {
    onSelect: Function;
    searchResult: Person[] | null;
    lastSearchData: PersonSearchData;
    resultPlaceHolder: string;
    setSearchPanelOpen: Function;
    selectedItemId?: string;
    personType: number | null;
    onNewVisitor?: Function;
    showNewVisitorButton?: boolean;
}

const PersonSearchResult = ({
    onSelect,
    searchResult,
    lastSearchData,
    resultPlaceHolder,
    setSearchPanelOpen,
    selectedItemId,
    personType,
    onNewVisitor,
    showNewVisitorButton = true,
}: PersonSearchResultProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            <div className="result-count-and-new-visitor-btn" data-testid="result-count-and-new-visitor-btn">
                <div className="result-count" data-testid="result-count">
                    <span className="count">{searchResult?.length || 0}&nbsp;</span>
                    <span className="label">{t('PersonSearch_Results')}</span>
                </div>
                {personType === PersonType.External && showNewVisitorButton && (
                    <button
                        type="button"
                        className="new-visitor-btn"
                        data-testid="new-visitor-button"
                        onClick={() => onNewVisitor && onNewVisitor()}
                    >
                        <PlusIcon />
                        <span> {t('PersonSearch_NewVisitor')} </span>
                    </button>
                )}
            </div>
            <div className="search-result" data-testid="search-result">
                {!searchResult && <div className="placeholder"> {resultPlaceHolder} </div>}

                {searchResult && searchResult.length === 0 && (
                    <div className="placeholder">
                        {`${t('PersonSearch_NoResultsFound')} ${
                            lastSearchData.name || lastSearchData.company ? t('PersonSearch_For') : ''
                        } ${lastSearchData.name ? lastSearchData.name : ''} ${
                            lastSearchData.name && lastSearchData.company ? t('PersonSearch_And') : ''
                        }
                            ${lastSearchData.company ? lastSearchData.company : ''} ${
                            lastSearchData.name && lastSearchData.email ? t('PersonSearch_And') : ''
                        } ${lastSearchData.email ? lastSearchData.email : ''}`}
                    </div>
                )}

                {searchResult &&
                    searchResult.length > 0 &&
                    searchResult.map((item) => {
                        return (
                            <PersonSearchResultItem
                                key={item.id}
                                selectedItemId={selectedItemId}
                                onSelect={(selectedItem: object) => {
                                    setSearchPanelOpen(false);
                                    onSelect(selectedItem);
                                }}
                                item={item}
                                personType={personType}
                            />
                        );
                    })}
            </div>
        </>
    );
};

export default PersonSearchResult;
