import AuthorizeService from 'features/Authentication/Services/AuthorizeService';
import { BOOKING_STATUS } from 'features/constants';
import { updateLocalResourceBookings } from 'features/Resources/Combined/Redux/actions';
import i18n from 'i18n';
import { DateTime } from 'luxon';
import { BookedResource, BookedResourceSummary, Booking } from 'services/ApiClients/Booking';
import { BookingSignalRApi } from 'services/ApiClients/BookingSingalR';
import { Configuration } from 'services/ApiClients/Configuration';
import { Resource } from 'services/ApiClients/Resource/Models';
import { ResourcesGS } from 'services/ApiClients/Resource/Models/ResourcesGS';
import LoggingService from 'services/LoggingService';
import ToastService from 'services/ToastService';
import { ActionType } from 'typesafe-actions';
import Guid from 'utilities/guid';

export default class BookingSignalRService {
    public static handleReceivedUpdate(
        booking: Booking,
        resources: ResourcesGS[] | undefined,
        resourceTypeSiteConfiguration: Configuration,
        selectedDate: DateTime,
        timezone: string
    ): BookedResourceSummary[] {
        try {
            LoggingService.Debug('Signal R Update received', booking);

            // Handle the update if necessary
            // @todo: This could be moved into a useEffect perhaps with a useState as this only gets updated whenever the datetime window changes
            const selectedDay = selectedDate.toFormat('cccc').toLowerCase();
            const currentDateBookingWindowStartTime = DateTime.fromISO(booking.startDateTime, { zone: timezone })
                .startOf('day')
                .plus({
                    minutes:
                        resourceTypeSiteConfiguration.bookableDays[selectedDay].bookingWindowStartMinutesFromMidnight,
                });
            const currentDateBookingWindowEndTime = DateTime.fromISO(booking.endDateTime, { zone: timezone })
                .startOf('day')
                .plus({
                    minutes:
                        resourceTypeSiteConfiguration.bookableDays[selectedDay].bookingWindowEndMinutesFromMidnight,
                });

            const releventBookedResources: BookedResourceSummary[] = [];

            // If we don't have any resources than we cannot update any bookings and the update notification gets discarded
            if (!BookingSignalRService.areResourcesValid(resources)) {
                return [];
            }

            // If we don't have any booked resources in the bookibg, we have nothing to update!
            if (!BookingSignalRService.areBookedResourcesValid(booking)) {
                return [];
            }

            // Get a flat list of all resources so we can check inclusivity easier
            // @todo: this can be moved into a useEffect and possibly with a useState as thi sonly needs to happen whenever resources change
            const flatResources = BookingSignalRService.flattenResources(resources);

            // Loop through each BookedResource record to determine if a bookedResource is applicable
            booking.resources.forEach((bookedResource: BookedResource) => {
                const bookedResourceSummary = BookingSignalRService.checkResourceForCompatibilityWithCurrentView(
                    booking,
                    bookedResource,
                    flatResources,
                    currentDateBookingWindowStartTime,
                    currentDateBookingWindowEndTime,
                    timezone
                );

                if (!bookedResourceSummary) {
                    return;
                }

                LoggingService.Debug('Adding booked resource to relevent booked resources');
                releventBookedResources.push(bookedResourceSummary);
            });

            LoggingService.Debug('Relevent booked resources', releventBookedResources);
            return releventBookedResources;
        } catch (error) {
            // An error has been received whilst trying to process this particular booking update. We should therefore show a warning toast. As an error occured we do not want to update the grid with inconsisten data so we return no additional items here
            const message = i18n.t('Toast_LatestBookingDataNotAvailable');
            ToastService.Error({
                message,
            });

            return [];
        }
    }

    private static areResourcesValid(resources: ResourcesGS[] | undefined): boolean {
        LoggingService.Debug('Resources:', resources);

        if (!resources || resources.length <= 0) {
            LoggingService.Debug('No resources loaded, unable to process update');
            return false;
        }

        return true;
    }

    private static areBookedResourcesValid(booking: Booking): boolean {
        LoggingService.Debug('BookedResources:', booking);

        if (!booking.resources || booking.resources.length <= 0) {
            LoggingService.Debug('No booked resources in booking, unable to process update');
            return false;
        }

        return true;
    }

    private static flattenResources(resources: ResourcesGS[] | undefined): Resource[] {
        const flatResources: Resource[] = [];
        resources?.forEach((resourceGS) => flatResources.push(...resourceGS.resources));

        LoggingService.Debug('Flat resources', flatResources);
        return flatResources;
    }

    private static checkResourceForCompatibilityWithCurrentView(
        booking: Booking,
        bookedResource: BookedResource,
        flatResources: Resource[],
        currentDateBookingWindowStartTime: DateTime,
        currentDateBookingWindowEndTime: DateTime,
        timezone: string
    ): BookedResourceSummary | null {
        LoggingService.Debug('Checking bookedResource for compatibility with current view', bookedResource);

        // Check to make sure we have a resource in scope that matches the resource we have received as an update
        const matchingResources = flatResources.filter((resource) => resource.id === bookedResource.resourceId);
        if (!matchingResources || matchingResources.length <= 0) {
            LoggingService.Debug('This booked resource is not in scope due to missing resource... skipping');
            return null;
        }

        // Check to  see if the booking starts or ends on the currently selected state and is therefore in scope
        const bookedResourceStartTime = DateTime.fromISO(bookedResource.startDateTime, { zone: timezone });
        const bookedResourceEndTime = DateTime.fromISO(bookedResource.endDateTime, { zone: timezone });

        const bookingStartsInScope =
            bookedResourceStartTime > currentDateBookingWindowStartTime &&
            bookedResourceStartTime < currentDateBookingWindowEndTime;

        const bookingEndsInScope =
            bookedResourceEndTime > currentDateBookingWindowStartTime &&
            bookedResourceEndTime < currentDateBookingWindowEndTime;

        if (!bookingStartsInScope && !bookingEndsInScope) {
            LoggingService.Debug('Booked resource start time', bookedResourceStartTime);
            LoggingService.Debug('Booked resource end time', bookedResourceStartTime);
            LoggingService.Debug('Current day start time', currentDateBookingWindowStartTime);
            LoggingService.Debug('Current day end time', currentDateBookingWindowEndTime);
            LoggingService.Debug('Start is in scope', bookingStartsInScope);
            LoggingService.Debug('End is in scope', bookingEndsInScope);
            LoggingService.Debug('This booked resource is not in scope due to time window... skipping');
            return null;
        }

        // If we get here then the booking is relevent (e.g. in scope) and needs to be updated in the view. To do this we need to mutate the redux state for the bookings node. So we only do this oncem we create the booking object that we need and then add it to the relevent resource array for later processing.
        const bookedResourceSummary: BookedResourceSummary = {
            bookingId: booking.id,
            // @todo: We will either need to add logic to determine the display name to the UI layer OR we will need to update the azure function
            // to return the display name after calculating from the change feed. We may also consider having a "hidden" field on the booking document
            // in the database that always contains the correct name to display for a booking (regardless of if displayname is set or not)
            createdByDisplayName: booking.createdByDisplayName,
            bookingDisplayName: booking.displayName,
            bookingStatus: booking.bookingStatus,
            resourceId: bookedResource.resourceId,
            startDateTime: bookedResource.startDateTime,
            endDateTime: bookedResource.endDateTime,
            createdByUserId: booking.createdByUserId,
            attendeesCount: bookedResource.attendeesCount,
            checkInStatus: booking.checkInStatus,
            onBehalfOf: booking.onBehalfOf,
            isPartOfRepeat: booking.isRepeat || !!booking.repeatBookingId,
            isPrivate: booking.isPrivate,
            repeatBookingId: booking.repeatBookingId,
        };

        return bookedResourceSummary;
    }

    private static async getTenantId(): Promise<Guid> {
        const tenantId = await AuthorizeService.getTenantId();
        if (tenantId === null) {
            return Guid.Empty;
        }
        return tenantId;
    }

    public static async subscribeToGroup(connectionId: string | null, geographicStructureIds: Guid[]): Promise<void> {
        try {
            if (!connectionId) {
                LoggingService.Warn('No SignalR connectionId provided so unable to subscribe to group');
                return;
            }

            await BookingSignalRApi.subscribe({
                tenantId: await this.getTenantId(),
                geographicStructureIds,
                connectionId,
            });
        } catch (error) {
            // An error has been received whilst trying to process this particular booking update. We should therefore show a warning toast. As an error occured we do not want to update the grid with inconsisten data so we return no additional items here
            const message = i18n.t('Error_SignalRSubscriptionError');
            ToastService.Error({
                message,
            });
        }
    }

    public static async unsubscribeFromGroup(
        connectionId: string | null,
        geographicStructureIds: Guid[]
    ): Promise<void> {
        try {
            if (!connectionId) {
                LoggingService.Warn('No SignalR connectionId provided so unable to unsubscribe from group');
                return;
            }

            await BookingSignalRApi.unsubscribe({
                tenantId: await this.getTenantId(),
                geographicStructureIds,
                connectionId,
            });
        } catch (error) {
            // If we get here we don't actually want to display an error as this is not an exception state, and under the right circumstances can be expected. Instead we preffer to log the error so we can see if debugging an issue
            LoggingService.Error('Error whilst unsubscribing from the group', error);
        }
    }

    public static updateReducerState(
        state: BookedResourceSummary[],
        action: ActionType<typeof updateLocalResourceBookings>
    ): BookedResourceSummary[] {
        try {
            LoggingService.Debug('Handling update local resoure bookings.');
            const releventBookings = action.payload;
            const currentBookings = state;
            let updatedBookings: BookedResourceSummary[] = [];

            if (BookingSignalRService.weHaveCurrentBookings(currentBookings)) {
                updatedBookings = currentBookings;
            }

            releventBookings.forEach((booking) => {
                updatedBookings = BookingSignalRService.processReleventBooking(booking, updatedBookings);
            });

            const result: BookedResourceSummary[] = [...updatedBookings];

            LoggingService.Debug('Updating reducer state with the following response', result);
            return result;
        } catch (error) {
            const message = i18n.t('Toast_UnableToProcessLatestBookingData');
            ToastService.Error({
                message,
            });
            LoggingService.Error('Error whilst updating reducer state after SignalR update', error);

            return state;
        }
    }

    private static weHaveCurrentBookings(currentBookings: BookedResourceSummary[]): boolean {
        return currentBookings && Array.isArray(currentBookings) && currentBookings.length > 0;
    }

    private static processReleventBooking(
        booking: BookedResourceSummary,
        updatedBookings: BookedResourceSummary[]
    ): BookedResourceSummary[] {
        LoggingService.Debug('Processing relevent resource booking', booking);

        if (BookingSignalRService.doesBookingAlreadyExist(booking, updatedBookings)) {
            if (BookingSignalRService.isBookingADelete()) {
                return BookingSignalRService.handleLocalBookingDelete(booking, updatedBookings);
            }

            return BookingSignalRService.handleLocalBookingUpdate(booking, updatedBookings);
        }

        return BookingSignalRService.handleNotExistingBooking(booking, updatedBookings);
    }

    private static doesBookingAlreadyExist(
        booking: BookedResourceSummary,
        updatedBookings: BookedResourceSummary[]
    ): boolean {
        // Does this booking already exist within the current bookings?
        const filteredBookings = updatedBookings.filter(
            (existingBooking) => existingBooking.bookingId === booking.bookingId
        );

        return filteredBookings && filteredBookings.length > 0;
    }

    private static isBookingADelete(): boolean {
        // @todo: We need to handle deletes, but currently cosmos change feed does not supply these. It's on MS roadmap but in the meantime we will most likely need to use integrationevents to trigger the update message via SignalR. When that is implemented the BookedResourceSummary will need to have some kind of new state that allows us to know a booking has been deleted (e.g. reservation expired). We may also choose to do this thorugh a different signalr stream to keep the changes separate
        return false;
    }

    private static handleLocalBookingDelete(
        booking: BookedResourceSummary,
        updatedBookings: BookedResourceSummary[]
    ): BookedResourceSummary[] {
        LoggingService.Debug('Booking is a delete - not yet implemented');

        // @todo: Implement delete resource booking
        return updatedBookings;
    }

    private static handleLocalBookingUpdate(
        booking: BookedResourceSummary,
        updatedBookings: BookedResourceSummary[]
    ): BookedResourceSummary[] {
        LoggingService.Debug('Booking is an update');

        // First remove the booking from the list of bookings, then push the updated version
        const result = updatedBookings.filter(
            (existingBooking) =>
                existingBooking.bookingId !== booking.bookingId || existingBooking.bookingId === booking.repeatBookingId
        );

        // When we update a booking there a few states in which we might want to keep the booking on screen, and a few states where
        // we might want to remove it.
        // @todo: This logic should be moved to the azure function as that's a more sensible place for it to live. This will mean a change to the shapre returned by the Azure function, but will provide consistency when we need to actually delete a booking
        if (
            [
                BOOKING_STATUS.PENDING,
                BOOKING_STATUS.CONFIRMED,
                BOOKING_STATUS.CURTAILED,
                BOOKING_STATUS.UPDATE_PENDING,
            ].includes(booking.bookingStatus)
        ) {
            result.push(booking);
        }

        return result;
    }

    private static handleNotExistingBooking(
        booking: BookedResourceSummary,
        updatedBookings: BookedResourceSummary[]
    ): BookedResourceSummary[] {
        const result = updatedBookings;

        if ([BOOKING_STATUS.ABANDONED, BOOKING_STATUS.CANCELLED].includes(booking.bookingStatus)) {
            // It's happening when CB.Synchronisation service updates  booking external ids for already abandoned/cancelled bookings.
            // This fixes an issue when such bookings are reappearing on booking grid.
            LoggingService.Debug("Not existing booking has 'abandoned' or 'cancelled' status. We're ignoring this");

            return result;
        }

        LoggingService.Debug("As booking doesn't already exist, we're adding it to the end");

        result.push(booking);

        return result;
    }
}
