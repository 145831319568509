import { connect } from 'react-redux';
import { ListItem } from 'components/Forms';
import { AccountState } from 'features/Account/Models/AccountState';
import { MY_ACCOUNT_12_FORMAT_VALUE, MY_ACCOUNT_24_FORMAT_VALUE } from 'features/constants';
import { RootState } from 'PortalTypes';

// eslint-disable-next-line import/no-cycle
import Settings from './Settings';

interface PassedProps {
    control: any;
    initialValues: AccountState;
    sites: ListItem[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps: PassedProps) => ({
    ...ownProps,
    isSso: state.authentication.user?.is_sso,
    defaultHourFormat: state.account.userProfile?.is24HoursTimeFormat
        ? MY_ACCOUNT_24_FORMAT_VALUE
        : MY_ACCOUNT_12_FORMAT_VALUE,
});

const mapDispatchToProps = {};

export type SettingsProps = {
    onOpenPasswordPanel: () => void;
} & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
