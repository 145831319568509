import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ReactComponent as Tick } from 'assets/icons/Tick.svg';
import { GRID_DATE_FORMAT } from 'features/constants';
import i18n from 'i18next';
import { DateTime } from 'luxon';
import { FormattedBooking, MyBooking } from 'services/ApiClients/Booking/Models/MyBooking';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { findResourceNameByValue } from 'services/ApiClients/Resource';

import { SiteVisit } from '../../../services/ApiClients/VisitorManagement/Models/SiteVisit';

import '../MyBookings.scss';

export const formatMyBookings = (
    bookings: MyBooking[],
    sites: GeographicStructureItem[],
    hourFormat = ''
): FormattedBooking[] => {
    const allBookings: FormattedBooking[] = [];

    bookings.forEach((booking) => {
        booking.resources.forEach((singleRepeat) => {
            const { resourceType, startDateTime, endDateTime, title, displayName, geographicStructures } = singleRepeat;
            const { name: siteName } =
                sites.find((site) => site.id.toString() === geographicStructures[1].geographicStructureId.toString()) ||
                {};

            allBookings.push({
                id: booking.id,
                resourceType: findResourceNameByValue(resourceType),
                createdDateTime: DateTime.fromISO(startDateTime).toFormat(GRID_DATE_FORMAT),
                startDateTime: DateTime.fromISO(startDateTime).toFormat(hourFormat),
                endDateTime: DateTime.fromISO(endDateTime).toFormat(hourFormat),
                title: title || booking.displayName,
                site: siteName,
                detail: displayName,
                createdByDisplayName: booking.createdByDisplayName,
                onBehalfOfName: booking.onBehalfOf?.displayName,
                sortIndex: DateTime.fromISO(startDateTime).toMillis(),
                isPartOfRepeat: booking.isRepeat || !!booking.repeatBookingId,
                recurringIcon:
                    booking.isRepeat || !!booking.repeatBookingId ? <Tick className="recurring-icon" /> : null,
            });
        });
    });

    return allBookings.sort((a, b) => a.sortIndex - b.sortIndex);
};

export const formatMySiteVisits = (
    siteVisits: SiteVisit[],
    sites: GeographicStructureItem[],
    hourFormat = ''
): FormattedBooking[] => {
    return siteVisits
        .map((siteVisit) => {
            const siteInformation =
                sites.find((site) => site.id.toString() === siteVisit.geographicStructureId.toString()) ||
                ({} as GeographicStructureItem);
            return {
                id: siteVisit.id,
                resourceType: i18n.t('MyBookings_VisitorType'),
                createdDateTime: DateTime.fromISO(siteVisit.dueDateTime).toFormat('dd MMM yyyy'),
                startDateTime: DateTime.fromISO(siteVisit.dueDateTime).toFormat(hourFormat),
                endDateTime: '',
                title: `${siteVisit.visitor?.firstname} ${siteVisit.visitor?.surname}${
                    siteVisit.visitor?.company ? `, ${siteVisit.visitor?.company}` : ''
                }`,
                visitorInfo: siteVisit.visitor,
                hostInfo: siteVisit.host,
                site: siteInformation.name,
                siteInformation,
                detail: siteVisit.visitor?.visitorType,
                createdByDisplayName: siteVisit.createdBy.displayName,
                onBehalfOfName:
                    siteVisit.host !== null ? `${siteVisit.host?.firstname} ${siteVisit.host?.surname}` : '',
                sortIndex: DateTime.fromISO(siteVisit.dueDateTime).toMillis(),
                dueDateTime: siteVisit.dueDateTime,
                isPartOfRepeat: false,
                recurringIcon: null,
                multiDaySiteVisitId: siteVisit.multiDaySiteVisitId ? siteVisit.multiDaySiteVisitId : '',
                isMultiDaySeriesCancelable: siteVisit.isMultiDaySeriesCancelable
                    ? siteVisit.isMultiDaySeriesCancelable
                    : false,
                visitorCheckedIn: siteVisit.visitorCheckedIn,
                visitorCheckedOut: siteVisit.visitorCheckedOut,
                isCanceled: siteVisit.isCanceled,
            };
        })
        .sort((a, b) => a.sortIndex - b.sortIndex);
};

export const mergeBookingsAndSiteVisits = (
    bookings: FormattedBooking[],
    siteVisits: FormattedBooking[]
): FormattedBooking[] => {
    return [...bookings, ...siteVisits].sort((a, b) => a.sortIndex - b.sortIndex);
};

export const getMyBookingColumns = (): GridColDef[] => [
    {
        field: 'resourceType',
        headerName: i18n.t('MyBookings_Service'),
        minWidth: 150,
        width: 150,
        sortable: false,
    },
    {
        field: 'createdDateTime',
        headerName: i18n.t('MyBookings_Date'),
        minWidth: 150,
        width: 150,
        sortable: false,
    },
    {
        field: 'startDateTime',
        headerName: i18n.t('MyBookings_Start'),
        minWidth: 80,
        width: 80,
        sortable: false,
    },
    {
        field: 'endDateTime',
        headerName: i18n.t('MyBookings_End'),
        minWidth: 80,
        width: 80,
        sortable: false,
    },
    {
        field: 'title',
        headerName: i18n.t('MyBookings_TableTitle'),
        minWidth: 200,
        width: 200,
        sortable: false,
    },
    {
        field: 'site',
        headerName: i18n.t('MyBookings_Site'),
        minWidth: 200,
        width: 200,
        sortable: false,
    },
    {
        field: 'detail',
        headerName: i18n.t('MyBookings_Detail'),
        minWidth: 300,
        width: 300,
        sortable: false,
    },
    {
        field: 'createdByDisplayName',
        headerName: i18n.t('MyBookings_CreatedBy'),
        minWidth: 200,
        width: 200,
        sortable: false,
    },
    {
        field: 'onBehalfOfName',
        headerName: i18n.t('MyBookings_OnBehalfOf'),
        minWidth: 200,
        width: 200,
        sortable: false,
    },
    {
        field: 'recurringIcon',
        headerName: i18n.t('MyBookings_Recurring'),
        minWidth: 100,
        width: 100,
        sortable: false,
        align: 'center',
        renderCell: (params: GridCellParams) => (
            <div className="recurring-icon-container">{params.row.recurringIcon}</div>
        ),
    },
];
