import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import ToastService from '../../../services/ToastService';

import {
    generateReport,
    generateReportForExport,
    getUserReportFilterOptions,
    reportsGetCompanies,
    reportsGetDepartments,
    reportsSearchCompanies,
    reportsSearchExternalVisitors,
    reportsSearchInternalVisitors,
    reportsSearchUsers,
} from './actions';

export const reportsSearchInternalVisitorsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(reportsSearchInternalVisitors.request)),
        switchMap((action) =>
            from(api.person.searchPersons(action.payload)).pipe(
                map((payload) => reportsSearchInternalVisitors.success(payload)),
                catchError((error) => of(reportsSearchInternalVisitors.failure(error)))
            )
        )
    );

export const reportsSearchExternalVisitorsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(reportsSearchExternalVisitors.request)),
        switchMap((action) =>
            from(api.visitorManagement.searchVisitor(action.payload)).pipe(
                map((payload) => reportsSearchExternalVisitors.success(payload)),
                catchError((error) => of(reportsSearchExternalVisitors.failure(error)))
            )
        )
    );

export const reportsSearchCompaniesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(reportsSearchCompanies.request)),
        switchMap((action) =>
            from(api.visitorManagement.searchCompanies(action.payload)).pipe(
                map((payload) => reportsSearchCompanies.success(payload)),
                catchError((error) => of(reportsSearchCompanies.failure(error)))
            )
        )
    );

export const reportsSearchUsersEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(reportsSearchUsers.request)),
        switchMap((action) =>
            from(api.person.searchUsers(action.payload)).pipe(
                map((payload) => reportsSearchUsers.success(payload)),
                catchError((error) => of(reportsSearchUsers.failure(error)))
            )
        )
    );

export const reportsGetCompaniesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(reportsGetCompanies.request)),
        switchMap(() =>
            from(api.visitorManagement.getCompanies()).pipe(
                map((payload) => reportsGetCompanies.success(payload)),
                catchError((error) => of(reportsGetCompanies.failure(error)))
            )
        )
    );

export const reportsGetDepartmentsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(reportsGetDepartments.request)),
        switchMap(() =>
            from(api.reports.getDepartments()).pipe(
                map((payload) => reportsGetDepartments.success(payload)),
                catchError((error) => of(reportsGetDepartments.failure(error)))
            )
        )
    );

export const getUserReportFilterOptionsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getUserReportFilterOptions.request)),
        switchMap(() =>
            from(api.reports.getUserReportFilterOptions()).pipe(
                map((payload) => getUserReportFilterOptions.success(payload)),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(getUserReportFilterOptions.failure(error));
                })
            )
        )
    );

export const generateReportEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(generateReport.request)),
        switchMap((action) =>
            from(api.reports.generateReport(action.payload)).pipe(
                map((payload) => {
                    return generateReport.success(payload);
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(generateReport.failure(error));
                })
            )
        )
    );

export const generateReportForExportEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(generateReportForExport.request)),
        switchMap((action) =>
            from(api.reports.generateReport(action.payload)).pipe(
                map((payload) => {
                    return generateReportForExport.success(payload);
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(generateReportForExport.failure(error));
                })
            )
        )
    );
