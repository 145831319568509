/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/DatePickerArrow.svg';
import { ReactComponent as DateIcon } from 'assets/icons/DatePickerCalendar.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/DatePickerTime.svg';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import DateTimePickerValue from '../../Models/DateTimePickerValue';

import './field.scss';

export interface FieldProps {
    onFocus?(): void;
    value?: DateTimePickerValue;
    dateTime?: DateTime;
    dataTestId: string;
    disabled?: boolean;
    isSingleTime?: boolean;
    showTime?: boolean;
    hourFormat: string;
}

const Field = ({
    onFocus,
    value,
    dataTestId,
    disabled = false,
    isSingleTime,
    dateTime,
    showTime = true,
    hourFormat,
}: React.PropsWithChildren<FieldProps>): JSX.Element => {
    const handleFocus = (): void => {
        if (!onFocus || disabled) {
            return;
        }

        onFocus();
    };

    let displayDate;
    let displayTime;

    if (isSingleTime && dateTime) {
        displayDate = `${dateTime.toFormat('d MMM yy')}`;
        displayTime = dateTime.toFormat(hourFormat);
    } else if (value?.startDateTime && value?.endDateTime) {
        displayDate = `${value.startDateTime.toFormat('d MMM yy')}`;
        displayTime = `${value.startDateTime.toFormat(hourFormat)} - ${value.endDateTime.toFormat(hourFormat)}`;
    }

    return (
        <div
            className={classnames(
                'dateTimePickerField',
                { dateTimePickerFieldDisabled: disabled },
                { 'with-time': showTime }
            )}
            data-testid={dataTestId}
            tabIndex={0}
            onFocus={handleFocus}
            onClick={handleFocus}
        >
            <div className="dateIcon">
                <DateIcon />
            </div>

            <div className="dateText">{displayDate}</div>

            {showTime && (
                <>
                    <div className="arrowIcon">
                        <ArrowIcon />
                    </div>

                    <div className="timeIcon">
                        <TimeIcon />
                    </div>

                    <div className="timeText">{displayTime}</div>
                </>
            )}
        </div>
    );
};

export default Field;
