import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextBox } from 'components/FormElements';
import { PersonType } from 'enums/PersonType';
import { EnterKey } from 'features/constants';
import { debounce } from 'lodash';
import { Person } from 'services/ApiClients/Models';

import RadioButtonSelector from '../../../RadioButtonSelector/RadioButtonSelector';
import { PersonSearchData } from '../../interfaces/PersonSearchData';
import PersonSearchResult from '../../PesonSearchResult/PersonSearchResult';

import './SearchForm.scss';

interface SearchFromProps {
    setSearchPanelOpen: Function;
    onSelect: Function;
    onSearch: Function;
    searchData: PersonSearchData;
    onSearchDataChange: Function;
    searchResult: Person[] | null;
    selectedItemId?: string;
    resultPlaceHolder: string;
    personType: number | null;
    onNewVisitor?: Function;
    onPersonTypeChange: Function;
    showNewVisitorButton?: boolean;
}
const SearchFrom = ({
    searchData,
    onSearchDataChange,
    onSearch,
    onSelect,
    searchResult,
    onNewVisitor,
    resultPlaceHolder,
    selectedItemId,
    setSearchPanelOpen,
    personType,
    onPersonTypeChange,
    showNewVisitorButton,
}: SearchFromProps): JSX.Element => {
    const { t } = useTranslation();
    const [lastSearchData, setLastSearchData] = useState<PersonSearchData>({} as PersonSearchData);
    const debounceSearch = useCallback(
        debounce((onSearchFunc: Function) => onSearchFunc(), 1000),
        []
    );

    const search = (data: PersonSearchData): void => {
        debounceSearch.cancel();
        debounceSearch(() => onSearch(data));
        setLastSearchData(data);
    };

    useEffect(() => {
        debounceSearch.cancel();
    }, [personType]);

    const onPersonTypeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onPersonTypeChange(parseInt(e.target.value, 2));
    };

    const radioGroupItems = [
        { value: PersonType.Internal, label: t('VisitorBooking_Internal'), dataTestId: 'radio-btn-internal' },
        { value: PersonType.External, label: t('VisitorBooking_External'), dataTestId: 'radio-btn-external' },
    ];

    const onKeyPress = (e: any): void => {
        if (e.key === EnterKey) {
            debounceSearch.cancel();
            setLastSearchData(searchData);
            onSearch(searchData);
        }
    };

    return (
        <div className="person-search-form-booking">
            <div className="search-fields">
                <RadioButtonSelector
                    required
                    value={personType}
                    items={radioGroupItems}
                    handleChange={onPersonTypeChangeHandler}
                />
                <TextBox
                    className="name"
                    hideLabel
                    value={searchData?.name || ''}
                    onChange={(value: string) => {
                        onSearchDataChange({ name: value });
                        search({ ...searchData, name: value });
                    }}
                    dataTestId="name-field"
                    placeholder={t('PersonSearch_PlaceholderName')}
                    type="search"
                    maxLength={100}
                    onKeyPress={onKeyPress}
                />
                <TextBox
                    className="email"
                    hideLabel
                    value={searchData?.email || ''}
                    onChange={(value: string) => {
                        onSearchDataChange({ email: value });
                        search({ ...searchData, email: value });
                    }}
                    dataTestId="email-field"
                    placeholder={t('PersonSearch_PlaceholderEmail')}
                    type="search"
                    maxLength={100}
                    onKeyPress={onKeyPress}
                />
            </div>

            <PersonSearchResult
                lastSearchData={lastSearchData}
                onSelect={onSelect}
                personType={personType}
                searchResult={searchResult}
                setSearchPanelOpen={setSearchPanelOpen}
                selectedItemId={selectedItemId}
                resultPlaceHolder={resultPlaceHolder}
                onNewVisitor={onNewVisitor}
                showNewVisitorButton={showNewVisitorButton}
            />
        </div>
    );
};

export default SearchFrom;
