import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import LoggingService from 'services/LoggingService';
import { isNil } from 'utilities/ts';

import './UserProfileBadge.scss';

export interface UserProfileBadgeProps {
    className?: string;
    initials?: string;
    imageUrl?: string;
    color: string;
    onClick?(): void;
    dataTestId?: string;
}

const buildUserProfileBadgeStyle = (color: string, imageUrl?: string): CSSProperties => {
    // Build inline style that can be applied based on options selected:
    const style = { background: color };

    if (!isNil(imageUrl)) {
        style.background = `url('${imageUrl}')`;
    }

    return style;
};

const UserProfileBadge = ({
    initials,
    imageUrl,
    color,
    onClick,
    className,
    dataTestId,
}: UserProfileBadgeProps): JSX.Element => {
    if (isNil(initials) && isNil(imageUrl)) {
        LoggingService.Error('Unable to initialise UserProfileBadge. Initials or ImageUrl must be set.');
        return <></>;
    }

    const style = buildUserProfileBadgeStyle(color, imageUrl);

    const handleClick = (): void => {
        if (!onClick) return;
        onClick();
    };

    return (
        <button
            className={classNames('userProfileBadge', className)}
            style={style}
            type="button"
            onClick={handleClick}
            data-testid={dataTestId}
        >
            <span className="initials">{initials}</span>
        </button>
    );
};

export default UserProfileBadge;
