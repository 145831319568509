import React from 'react';
import { useTranslation } from 'react-i18next';
import infoIcons from 'assets/icons/deskInfo';
import { ResourceEquipment } from 'services/ApiClients/Resource/Models/Resource';

import './List.scss';

interface FixedResourceProps {
    fixedResources: ResourceEquipment[];
}

const FixedResources = ({ fixedResources }: FixedResourceProps): JSX.Element => {
    const { t } = useTranslation();
    const equipments: { [key: string]: number } = {};
    fixedResources?.forEach((item) => {
        if (equipments[item.name]) {
            equipments[item.name] += 1;
        } else {
            equipments[item.name] = 1;
        }
    });

    return (
        <div className="fixedResources">
            <img src={infoIcons.fixedResourceIcon} alt="fixed equipment" />
            <div className="content">
                <span className="title">{t('ResourceInfo_FixedEquipment')}</span>
                <ul>
                    {Object.keys(equipments).map((name) => (
                        <li key={name}>
                            {`${name}`} {equipments[name] > 1 ? `(x${equipments[name]})` : ''}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FixedResources;
