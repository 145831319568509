import { connect } from 'react-redux';
import { getBookedResource } from 'features/Resources/Common/EditBooking/BookingInfo/utils';
import { BookingInfoDataState } from 'features/Resources/Common/EditBooking/Editor/Models/BookingInfoDataState';
import {
    createVisitor,
    editVisitor,
    resetSearchOboPersonsResult,
    searchExternalOboPersons,
    searchInternalOboPersons,
    updateRepeatCombinedBookingAsync,
} from 'features/Resources/Common/EditBooking/Redux/actions/info';
import { getCommonInfo } from 'features/Resources/Common/EditBooking/Redux/selectors';
import { fortmatFormValuesForSeries } from 'features/Resources/Common/EditBooking/utils';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import { BookedResource, Booking, UpdateCommand } from 'services/ApiClients/Booking';
import { Visitor } from 'services/ApiClients/VisitorManagement/Models/Visitor';
import { SearchData } from 'utilities/searchUtils';

// eslint-disable-next-line import/no-cycle
import { isSeriesInfoEnable } from '../../utils';

// eslint-disable-next-line import/no-cycle
import SeriesInfo from './SeriesInfo';

interface PassedProps {
    handleSetBookingInfoDataState: (bookingInfoData: BookingInfoDataState) => void;
    bookingInfoDataState: BookingInfoDataState;
}

const mapStateToProps = (state: RootState, ownProps: PassedProps): any => {
    const info = getCommonInfo(state);
    const combinedBooking = state.combined.edit.info.item as Booking;
    const bookedResource = getBookedResource(
        combinedBooking,
        info.commonInfo.selectedDate,
        info.commonInfo.timezone
    ) as BookedResource;
    const { selectedDate } = state.filters.combined;

    return {
        ...ownProps,
        ...info,
        bookingInfo: {
            ...fortmatFormValuesForSeries(combinedBooking, bookedResource, info.commonInfo.timezone, selectedDate),
            displayName: combinedBooking?.displayName,
            notes: combinedBooking?.notes,
            attendeesCount: combinedBooking?.attendeesCount || 1,
            isPrivate: combinedBooking?.isPrivate,
        },
        disabled: !isSeriesInfoEnable(
            state.combined.edit.info.occurrences,
            DateTime.fromISO(info.commonInfo.dateTimeNow),
            info.commonInfo.canEditPastBooking
        ),
        bookingsByResourceLoadStatus: state.combined.bookingsByResource.loadStatus,
    };
};

const mapDispatchToProps = {
    searchInternalOboPersons: (searchPersonsRequest: SearchData) =>
        searchInternalOboPersons.request(searchPersonsRequest),
    searchExternalOboPersons: (searchPersonsRequest: SearchData) =>
        searchExternalOboPersons.request(searchPersonsRequest),
    resetSearchOboPersonsResult,
    updateRepeatBooking: (bookingRequest: UpdateCommand) => updateRepeatCombinedBookingAsync.request(bookingRequest),
    createVisitor: (visitor: Visitor) => createVisitor.request(visitor),
    editVisitor: (visitor: Visitor) => editVisitor.request(visitor),
};

export type SeriesInfoProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SeriesInfo);
