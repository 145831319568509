import { ReactComponent as combinedIcon } from 'assets/icons/nav/BookV2.svg';
import { ReactComponent as helpIcon } from 'assets/icons/nav/HelpV2.svg';
import { ReactComponent as homeIcon } from 'assets/icons/nav/home.svg';
import { ReactComponent as myAccountIcon } from 'assets/icons/nav/MyAccountV2.svg';
import { ReactComponent as myBookingsIcon } from 'assets/icons/nav/MyBookingsV2.svg';
import { ReactComponent as receptionIcon } from 'assets/icons/nav/ReceptionV2.svg';
import { ReactComponent as reportsIcon } from 'assets/icons/nav/ReportsV2.svg';
import { ReactComponent as visitorBookingIcon } from 'assets/icons/nav/VisitorBookingV2.svg';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure/Models/GeographicStructureItem';

import { Access } from '../../enums/authorization';
import { Permissions } from '../../features/Authentication/types';
import { TenantInfo } from '../../services/ApiClients/Organization/Models/TenantInfo';

export default (
    tenantInfo: TenantInfo | null,
    selectedSite: GeographicStructureItem | null,
    permissions?: Permissions
): Array<any> => [
    { to: '/', icon: homeIcon, translation: 'Navigation_Home', dataTestId: 'home-navigationLink', turnedOn: true },
    {
        to: '/myaccount',
        icon: myAccountIcon,
        translation: 'Navigation_MyAccount',
        dataTestId: 'myaccount-navigationLink',
        turnedOn: true,
    },
    {
        to: '/bookings',
        icon: combinedIcon,
        translation: 'Navigation_Book',
        dataTestId: 'combined-navigationLink',
        turnedOn:
            (tenantInfo?.areDesksAvailable || tenantInfo?.areRoomsAvailable) &&
            (selectedSite?.areDesksAvailable || selectedSite?.areRoomsAvailable),
        accessTypes: [Access.DeskBooking, Access.RoomBooking],
    },
    {
        to: '/mybookings',
        icon: myBookingsIcon,
        translation: 'Navigation_MyBookings',
        dataTestId: 'mybookings-navigationLink',
        turnedOn: true,
    },
    {
        to: '/visitor-booking',
        icon: visitorBookingIcon,
        translation: 'Navigation_VisitorBooking',
        dataTestId: 'visitor-booking-navigationLink',
        turnedOn: tenantInfo?.isVisitorManagementAvailable && selectedSite?.isVisitorManagementAvailable,
    },
    {
        to: '/reception',
        icon: receptionIcon,
        translation: 'Navigation_Reception',
        dataTestId: 'reception-navigationLink',
        accessTypes: [Access.Reception],
        turnedOn: tenantInfo?.isVisitorManagementAvailable && selectedSite?.isVisitorManagementAvailable,
    },
    {
        to: '/reports',
        icon: reportsIcon,
        translation: 'Navigation_Reports',
        dataTestId: 'reports-navigationLink',
        turnedOn: !!permissions?.ReportsPermissions,
    },
    { to: '/help', icon: helpIcon, translation: 'Navigation_Help', dataTestId: 'help-navigationLink', turnedOn: true },
];
