import { useEffect, useState } from 'react';
import { ListItem } from 'components/Forms';

const useFetchSalutations = (): ListItem[] => {
    const [items, setItems] = useState<ListItem[]>([]);

    const fetch = async (): Promise<void> => {
        // const response = await ToDoApi.getSalutations();
        const response = {
            payload: [
                { label: '-', value: '' },
                { label: 'Dr', value: 'Dr' },
                { label: 'Miss', value: 'Miss' },
                { label: 'Mr', value: 'Mr' },
                { label: 'Mrs', value: 'Mrs' },
                { label: 'Ms', value: 'Ms' },
            ],
            isSuccess: true,
        };

        if (!response.isSuccess || !response.payload) {
            setItems([]);
            return;
        }

        const resourceData = response.payload as ListItem[];
        setItems(resourceData);
    };

    useEffect(() => {
        fetch();
    }, []);

    return items;
};

export default useFetchSalutations;
