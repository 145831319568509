import React from 'react';

import BackgroundMask from '../../BackgroundMask';
import Loading from '../../Loading';

export interface WithLoadingProps {
    isLoading?: boolean;
    children: any;
}

const WithLoading = ({ isLoading, children }: WithLoadingProps): JSX.Element => {
    return (
        <>
            {isLoading && (
                <>
                    <BackgroundMask open />
                    <Loading />
                </>
            )}
            {children}
        </>
    );
};

export default WithLoading;
