import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton, {
    createDangerButton,
    createPrimaryButton,
    createSecondaryButton,
} from 'components/ActionButtons/Models/ActionButton';
import ActionButtonsPanel from 'components/ActionButtonsPanel';
import Button from 'components/FormElements/Button';
import { PersonType } from 'enums/PersonType';
import { DEFAULT_TIMEZONE } from 'features/constants';
import { DateTime } from 'luxon';
import { EditVisitorsLocationState } from 'services/ApiClients/Models';

// eslint-disable-next-line import/no-cycle
import { BookingData } from '../VisitorBooking';

interface VisitorBookingActionButtonsProps {
    locationState: EditVisitorsLocationState;
    setCancelMultiDayDialogOpen: (value: boolean) => void;
    setCancelDialogOpen: (value: boolean) => void;
    setCloseDialogOpen: (value: boolean) => void;
    bookingData: BookingData;
    selectedVisitorType: number | null;
    editSiteVisit: (editSiteVisitData: any) => void;
    setDueTimeErrorMessage: (message: string) => void;
    handleBook: () => void;
}

const VisitorBookingActionButtons = ({
    locationState,
    setCancelMultiDayDialogOpen,
    setCancelDialogOpen,
    bookingData,
    selectedVisitorType,
    setCloseDialogOpen,
    editSiteVisit,
    setDueTimeErrorMessage,
    handleBook,
}: VisitorBookingActionButtonsProps): JSX.Element => {
    const { t } = useTranslation();
    const editVisitorButtons: ActionButton[] = [];

    const handleCancelVisitorBooking = (): void => {
        if (locationState.multiDaySiteVisitId) {
            setCancelMultiDayDialogOpen(true);
        } else {
            setCancelDialogOpen(true);
        }
    };

    const handleSaveVisitorBooking = (): void => {
        const { siteVisitId, visitor } = locationState;

        if (bookingData.dueDateTime < DateTime.now().setZone(bookingData?.site?.timezone || DEFAULT_TIMEZONE)) {
            setDueTimeErrorMessage(t('VisitorBooking_DueTimeInPast'));
            return;
        }

        const host = {
            title: bookingData?.host?.title,
            firstname: bookingData?.host?.firstname,
            surname: bookingData?.host?.surname,
            company: bookingData?.host?.company,
            email: bookingData?.host?.email,
            hostType: bookingData?.host?.personType?.value,
            personId: bookingData?.host?.id,
            dateOfBirth: bookingData?.host?.dateOfBirth,
            countryCode: bookingData?.host?.countryCode?.value,
            mobile: bookingData?.host?.mobile,
            nationality: bookingData?.host?.nationality,
            citizenship: bookingData?.host?.citizenship,
        };

        let editSiteVisitData = {
            siteVisitId,
            visitorId: visitor?.id,
            geographicStructureId: bookingData.site?.id || '',
            dueDateTime: bookingData.dueDateTime.toUTC().toISO(),
            host: null,
        };

        if (bookingData?.host) {
            editSiteVisitData = { ...editSiteVisitData, host };
        }
        editSiteVisit(editSiteVisitData);
    };

    const canCancelMultiDaySeries = locationState?.multiDaySiteVisitId && locationState?.isMultiDaySeriesCancelable;
    const canCancelSiteVisitBooking = !locationState?.multiDaySiteVisitId;

    const isBookBtnDisabled =
        !bookingData.visitor ||
        !bookingData.site ||
        !bookingData.dueDateTime ||
        (selectedVisitorType === PersonType.External && !bookingData.host) ||
        (bookingData.isMultiday && !bookingData.isValidToDate);

    const isSaveEditBookBtnDisabled =
        !bookingData.site ||
        !bookingData.dueDateTime ||
        (selectedVisitorType === PersonType.External && !bookingData.host) ||
        (!locationState?.multiDaySiteVisitId &&
            (locationState?.isCanceled || locationState?.visitorCheckedOut || locationState?.visitorCheckedIn));

    const cancelVisitorBookingButton = createDangerButton(
        'cancel-visitor-booking',
        canCancelMultiDaySeries ? t('Button_CancelSeries') : t('Button_CancelBooking'),
        handleCancelVisitorBooking,
        undefined,
        undefined,
        'cancel-visitor-booking',
        false
    );

    if (canCancelSiteVisitBooking || canCancelMultiDaySeries) {
        editVisitorButtons.push(cancelVisitorBookingButton);
    }

    const closeVisitorBookingButton = createSecondaryButton(
        'close-visitor-booking',
        t('Button_Close'),
        () => setCloseDialogOpen(true),
        undefined,
        undefined,
        'cancel-visitor-booking'
    );

    const saveVisitorBookingButton = createPrimaryButton(
        'save-visitor-booking',
        t('Button_Save'),
        handleSaveVisitorBooking,
        undefined,
        undefined,
        'save-visitor-booking',
        isSaveEditBookBtnDisabled
    );

    editVisitorButtons.push(closeVisitorBookingButton, saveVisitorBookingButton);

    return locationState?.isEdit ? (
        <ActionButtonsPanel buttons={editVisitorButtons} />
    ) : (
        <ActionButtonsPanel>
            <Button
                text={t('Button_Book')}
                className="book-btn"
                dataTestId="book-btn"
                onClick={handleBook}
                disabled={isBookBtnDisabled}
                primary
            />
        </ActionButtonsPanel>
    );
};

export default VisitorBookingActionButtons;
