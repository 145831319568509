import React from 'react';
import { createPortal } from 'react-dom';
import { OnChangeValue } from 'react-select';
import BackgroundMask from 'components/BackgroundMask';
import Button from 'components/FormElements/Button';
import Select, { ListItem } from 'components/Select';
import usePortal from 'hooks/usePortal';

import './DefaultSiteSelector.scss';

interface DefaultSiteSelectorProps {
    title: string;
    description: string;
    items: ListItem[];
    onChange: (value: OnChangeValue<ListItem, false>) => void;
    onSave: () => void;
    value: OnChangeValue<ListItem, false> | null;
    buttonText: string;
    isLoading: boolean;
}

const DefaultSiteSelector = (props: DefaultSiteSelectorProps): JSX.Element => {
    const { title, description, buttonText, items, onChange, value, isLoading, onSave } = props;
    const modalRootElement = usePortal();

    return (
        <>
            <BackgroundMask className="open" />
            {createPortal(
                <div className="default-site-selector">
                    <div className="default-site-selector__header">
                        <h1>{title}</h1>
                        <span>{description}</span>
                    </div>
                    <div className="default-site-selector__content">
                        <div className="default-site-selector__content--select">
                            <Select
                                items={items}
                                value={value}
                                onChange={onChange}
                                name="site-selector"
                                label="Site Selector"
                                hideLabel
                                placeholder="Select a site"
                                dataTestId="default-site-selector-select"
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="default-site-selector__content--button">
                            <Button
                                text={buttonText}
                                dataTestId="default-site-selector-button"
                                disabled={!value}
                                className="primary default-site-selector__content--button"
                                onClick={() => onSave()}
                            />
                        </div>
                    </div>
                </div>,
                modalRootElement
            )}
        </>
    );
};

export default DefaultSiteSelector;
