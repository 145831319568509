import React from 'react';

import './components.scss';

export interface RequiredLabelProps {
    required?: boolean;
}
const RequiredLabel = ({ required = false }: RequiredLabelProps): JSX.Element | null => {
    return required ? <div className="requiredLabel">*</div> : null;
};

export default RequiredLabel;
