import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { HistoryDataLogs } from 'services/ApiClients/Booking';
import { createAction, createAsyncAction } from 'typesafe-actions';
import Guid from 'utilities/guid';

export const setCombinedBookingHistoryLoadStatus = createAction('COMBINED_BOOKING_HISTORY_SET_LOAD_STATUS')<string>();

export const getCombinedBookingHistoryDataAsync = createAsyncAction(
    'GET_COMBINED_BOOKING_HISTORY_DATA_REQUEST',
    'GET_COMBINED_BOOKING_HISTORY_DATA_SUCCESS',
    'GET_COMBINED_BOOKING_HISTORY_DATA_FAILURE'
)<Guid, ApiResponse<HistoryDataLogs>, AxiosError | ApiResponse>();
