import account from 'features/Account/Redux/reducer';
import authentication from 'features/Authentication/Redux/reducer';
import filters from 'features/Filters/Redux/reducer';
import myBookings from 'features/MyBookings/Redux/reducer';
import reception from 'features/Reception/Redux/reducer';
import referenceData from 'features/ReferenceData/Redux/reducer';
import reports from 'features/Reports/Redux/reducer';
import availability from 'features/Resources/Availability/Redux/Reducer';
import combined from 'features/Resources/Combined/Redux/reducer';
import floorPlan from 'features/Resources/Common/FloorPlan/Redux/floorPlanReducer';
import commonResources from 'features/Resources/Common/Redux/Reducer';
import unavailability from 'features/Resources/Unavailability/Redux/Reducer';
import navigation from 'features/SiteNavigation/Redux/reducers';
import visitorBooking from 'features/VisitorBooking/Redux/reducer';
import { combineReducers } from 'redux';

import { routerReducer } from './reduxHistoryContext';

// NOTE: This doesn't require a return type. This gets set in the types.d.ts file in the same folder in the RootState type.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const rootReducer = () =>
    combineReducers({
        router: routerReducer,
        navigation,
        unavailability,
        availability,
        visitorBooking,
        floorPlan,
        account,
        referenceData,
        filters,
        authentication,
        commonResources,
        myBookings,
        reception,
        combined,
        reports,
    });

export default rootReducer;
