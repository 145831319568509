import React from 'react';
import classNames from 'classnames';

import ActionButtons from '../ActionButtons';
import ActionButton from '../ActionButtons/Models/ActionButton';

import './ActionButtonPanel.scss';

export interface ActionButtonsPanelProps {
    buttons?: ActionButton[];
    disabled?: boolean;
    leftSideElement?: any;
    className?: string;
    children?: JSX.Element;
}

const ActionButtonsPanel = ({
    buttons,
    disabled,
    className,
    children,
    leftSideElement,
}: ActionButtonsPanelProps): JSX.Element => {
    return (
        <div className={classNames('actionButtonsPanel', className)}>
            {leftSideElement && <div className="left-side-elements">{leftSideElement}</div>}
            {children ? (
                <div className="action-buttons">{children}</div>
            ) : (
                <ActionButtons buttons={buttons} disabled={disabled} />
            )}
        </div>
    );
};

export default ActionButtonsPanel;
