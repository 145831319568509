import React from 'react';
import classNames from 'classnames';

export interface TabContentProps {
    tabId: string;
    selected?: boolean;
}

const TabContent = ({ tabId, children, selected }: React.PropsWithChildren<TabContentProps>): JSX.Element => {
    return (
        <div className={classNames('tabContent', { selected })} id={`${tabId}Content`}>
            {children}
        </div>
    );
};

export default TabContent;
