import React, { memo } from 'react';
import CombinedView from 'features/Resources/Combined';
import Provider from 'features/Resources/Combined/Provider';
import MainLayout from 'layouts/MainLayout/MainLayout';

const Combined = (): JSX.Element => (
    <MainLayout>
        <Provider>
            <CombinedView />
        </Provider>
    </MainLayout>
);

export default memo(Combined);
