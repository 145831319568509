import AuthorizeService from 'features/Authentication/Services/AuthorizeService';
import { setSite, setSiteGroup } from 'features/Filters/Redux/actions';
import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { MyAccountCompanyAttributes, UserProfile } from 'services/ApiClients/Identity/Models';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import ToastService from 'services/ToastService';
import { isActionOf } from 'typesafe-actions';
import Guid from 'utilities/guid';

import { getSpecifiedAttributes } from '../../Resources/Common/EditBooking/Redux/actions/info';

import {
    getMyAccountUserProfileAsync,
    getTenantCompanyAttributesAsync,
    updateMyAccountUserProfileAsync,
    updateUserDefaultSite,
    updateUserProfileCompanyAttributesAsync,
} from './actions';

export const getMyAccountUserProfileEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getMyAccountUserProfileAsync.request)),
        switchMap(() =>
            from(api.identity.getUserProfile()).pipe(
                mergeMap((payload) => {
                    const specifiedAttributesPayload = (payload?.payload as UserProfile)?.companyAttributes.map(
                        ({
                            companyAttributeId: attributeId,
                            companyAttributeValues: valueIds,
                        }: MyAccountCompanyAttributes) => ({
                            attributeId,
                            valueIds,
                        })
                    );
                    const firstRequest = [getMyAccountUserProfileAsync.success(payload)];

                    if (specifiedAttributesPayload?.length) {
                        return [
                            ...firstRequest,
                            getSpecifiedAttributes.request({ attributes: specifiedAttributesPayload }),
                        ];
                    }
                    return [getMyAccountUserProfileAsync.success(payload)];
                }),
                catchError((error) => of(getMyAccountUserProfileAsync.failure(error)))
            )
        )
    );

export const updateMyAccountUserProfileEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(updateMyAccountUserProfileAsync.request)),
        switchMap((action) =>
            from(api.identity.updateUserProfile(action.payload)).pipe(
                map((payload) => {
                    (async () => {
                        await AuthorizeService.refreshUser();
                        await AuthorizeService.getUser();
                    })();

                    ToastService.Success({ message: 'Toast_DetailsSaved' });
                    return updateMyAccountUserProfileAsync.success(payload);
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_ChangesNotSaved' });
                    return of(updateMyAccountUserProfileAsync.failure(error));
                })
            )
        )
    );

export const setUserDefaultSiteEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(updateUserDefaultSite.request)),
        switchMap((action) =>
            from(api.identity.updateUserProfile(action.payload)).pipe(
                concatMap(() => {
                    (async () => {
                        await AuthorizeService.refreshUser();
                        await AuthorizeService.getUser();
                    })();

                    const sites = state$.value.referenceData.sites.items;
                    const tenantId = state$.value.authentication.user?.tenantId;

                    const selectedSite = sites.find(
                        (item) => item.id.toString() === action.payload.siteId.toString()
                    ) as GeographicStructureItem;

                    ToastService.Success({ message: 'Default_Site_Save_Success' });

                    if (selectedSite.parentId === tenantId) {
                        return of(setSite(selectedSite), updateUserDefaultSite.success());
                    }

                    const siteGroupId = selectedSite.parentId as Guid;
                    return from(api.organisationStructure.getGeographicStructureById(siteGroupId)).pipe(
                        concatMap((siteGroupResponse) => {
                            const selectedSiteGroup = siteGroupResponse.payload as GeographicStructureItem;
                            return of(
                                setSite(selectedSite),
                                setSiteGroup(selectedSiteGroup),
                                updateUserDefaultSite.success()
                            );
                        })
                    );
                }),
                catchError(() => {
                    ToastService.Error({ message: 'Default_Site_Save_Error' });
                    return of(updateUserDefaultSite.failure());
                })
            )
        )
    );

export const updateUserProfileCompanyAttributesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(updateUserProfileCompanyAttributesAsync.request)),
        switchMap((action) =>
            from(api.identity.updateUserCompanyAttributes(action.payload)).pipe(
                map((payload) => {
                    // ToastService.Success({ message: 'Toast_DetailsSaved' });
                    return updateUserProfileCompanyAttributesAsync.success(payload);
                }),
                catchError((error) => {
                    // ToastService.Error({ message: 'Toast_ChangesNotSaved' });
                    return of(updateUserProfileCompanyAttributesAsync.failure(error));
                })
            )
        )
    );

export const getTenantsCompanyAttributesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getTenantCompanyAttributesAsync.request)),
        switchMap((action) =>
            from(api.organisation.getAvailableCompanyAttributes()).pipe(
                map((payload) => getTenantCompanyAttributesAsync.success(payload)),
                catchError((error) => of(getTenantCompanyAttributesAsync.failure(error)))
            )
        )
    );
