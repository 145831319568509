import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import cn from 'classnames';

import './headerIconButton.scss';

export interface BaseIconButtonProps {
    onClick: Function;
    className?: string;
    dataTestId?: string;
    label: string;
    isDisabled: boolean;
    icon: JSX.Element;
    onHoverIcon: JSX.Element;
}
const BaseHeaderIconButton = (props: BaseIconButtonProps): JSX.Element => {
    const { onClick, className, isDisabled, icon, onHoverIcon, dataTestId, label } = props;
    const [isHovered, setIsHover] = useState<boolean>(false);

    return (
        <div
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            data-testid={dataTestId}
            className={cn('header-icon-button', className)}
        >
            <IconButton disabled={isDisabled || !onClick} onClick={(e) => onClick(e)} disableRipple>
                {!isHovered ? icon : onHoverIcon}
            </IconButton>

            {label && <div className="header-icon-button-label">{label}</div>}
        </div>
    );
};

export default BaseHeaderIconButton;
