import { Visitor } from 'services/ApiClients/VisitorManagement/Models/Visitor';
import { SearchData, SearchItem } from 'utilities/searchUtils';
import { v4 as uuidv4 } from 'uuid';

import { PersonSearchData } from '../interfaces/PersonSearchData';

const buildNameSearchQueryItems = (searchValue: string): Array<Array<SearchItem>> => {
    const searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>> = [];

    searchValue
        .trim()
        .split(' ')
        .forEach((i) => {
            const searchFieldsConditionOR: Array<SearchItem> = [];
            const value = i.trim();
            if (value) {
                searchFieldsConditionOR.push({ field: 'Firstname', value });
                searchFieldsConditionOR.push({ field: 'Surname', value });

                searchFieldsConditionsOrUnitedByAnd.push(searchFieldsConditionOR);
            }
        });

    return searchFieldsConditionsOrUnitedByAnd;
};

export const getSearchDataConditions = (searchData: PersonSearchData): SearchData => {
    let searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>> = [];

    if (searchData.name) {
        searchFieldsConditionsOrUnitedByAnd = buildNameSearchQueryItems(searchData.name);
    }

    const searchDataConditions: SearchData = {
        searchFieldsConditionAND: !searchData.company ? [] : [{ field: 'Company', value: searchData.company }],
        searchFieldsConditionsOrUnitedByAnd,
        top: 100,
        orderBy: 'Surname',
    };

    return searchDataConditions;
};

export const formatVisitorData = (
    vsData: any,
    onBehalfOfPersonId: string | undefined,
    selectedSiteId: string
): Visitor =>
    ({
        id: onBehalfOfPersonId ?? uuidv4(),
        geographicStructureId: selectedSiteId,
        title: typeof vsData.title === 'object' ? vsData.title?.value : vsData.title || null,
        company: vsData.company,
        email: vsData.email,
        firstname: vsData.firstname,
        surname: vsData.surname,
        countryCode: vsData.countryCode.value,
        mobile: vsData.mobile.toString(),
        visitorType: 'External',
    } as Visitor);
