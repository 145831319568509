import React, { SyntheticEvent } from 'react';
import Toggle from 'components/FormElements/Toggle';

interface ToogleFilterProps {
    title: string;
    onChange: (e: SyntheticEvent) => void;
    value: string;
    dataTestId: string;
    inputId: string;
    label: string;
    checked: boolean;
    isDisabled?: boolean;
}

const ToggleFilter = ({
    title,
    onChange,
    value,
    dataTestId,
    inputId,
    label,
    checked,
    isDisabled,
}: ToogleFilterProps): JSX.Element => (
    <div className="filter">
        <p>{title}</p>
        <Toggle
            type="checkbox"
            label={label}
            inputId={inputId}
            onChange={onChange}
            dataTestId={dataTestId}
            checked={checked}
            value={value}
            inputName="filterToogle"
            isDisabled={isDisabled}
        />
    </div>
);

export default ToggleFilter;
