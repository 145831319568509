import React, { memo, useEffect } from 'react';
import ContentLoader from 'components/ContentLoader';
import * as constants from 'features/constants';
// eslint-disable-next-line import/no-cycle
import { ProviderProps } from 'features/Resources/Combined/Provider';
import SignalRProvider from 'features/Resources/Common/SignalRProvider/Provider';
import { flatMap } from 'lodash';
import { SearchBookedResourcesQuery } from 'services/ApiClients/Booking';
import { ConfigurationLevel } from 'services/ApiClients/Configuration';
import DateTimeService from 'services/DateTimeService';
import Guid from 'utilities/guid';

const Provider = ({
    children,
    getSiteConfiguration,
    getTenantConfiguration,
    setSiteConfigurationLoadStatus,
    getAreas,
    getResources,
    setAreasLoadStatus,
    getBookings,
    setBookingsLoadStatus,
    setDate,
    updateBookings,
    loadUnavailability,
    refreshBookingUnavailability,
    data,
}: React.PropsWithChildren<ProviderProps>): JSX.Element => {
    const {
        configurationLoadStatus,
        areas,
        site,
        resources,
        areasLoadStatus,
        resourcesLoadStatus,
        bookingsLoadStatus,
        selectedDate,
        availableAreas,
        availableResources,
        configuration,
        timezone,
        resourceTypes,
        unavailability,
    } = data;

    useEffect(() => {
        return () => {
            setBookingsLoadStatus(constants.LOAD_STATUSES.REQUIRED);
            setSiteConfigurationLoadStatus(constants.LOAD_STATUSES.REQUIRED);
        };
    }, []);

    const loadConfiguration = (): void => {
        const siteConfigurationQuery = {
            configurationLevel: ConfigurationLevel.SITE,
            configurationLevelEntityId: site?.id as Guid,
        };

        const tenantConfigurationQuery = {
            configurationLevel: ConfigurationLevel.TENANT,
            configurationLevelEntityId: '',
        };

        getSiteConfiguration(siteConfigurationQuery);
        getTenantConfiguration(tenantConfigurationQuery);
    };

    const loadAreas = (): void => {
        const siteId = site?.id as Guid;
        if (areas[siteId.toString()]) {
            setAreasLoadStatus(constants.LOAD_STATUSES.LOADED);
            return;
        }

        getAreas(siteId);
    };

    const loadResources = (): void => {
        const areaIds = areas[site?.id.toString() as string]?.map((item) => item.id) || [];
        if (!areaIds.length) {
            return;
        }

        if (!DateTimeService.isSelectedDateBookable(selectedDate)) {
            const date = DateTimeService.getNextBookableDate(selectedDate);
            setDate(date);
        }

        getResources({
            geographicStructureIds: areaIds,
            resourceTypes: resourceTypes.map((type) => type.value),
        });
    };

    const loadBookings = (): void => {
        const areaIds = areas[site?.id.toString() as string]?.map((item) => item.id);
        const getBookingsQuery: SearchBookedResourcesQuery = {
            geographicStructureIds: areaIds,
            fromDateTime: selectedDate.setZone(timezone).startOf('day').toUTC().toISO(),
            toDateTime: selectedDate.setZone(timezone).endOf('day').toUTC().toISO(),
            resourceTypes: resourceTypes.map((type) => type.value),
        };
        getBookings(getBookingsQuery);
    };

    const onLoadResources = (): void => {
        const allResources = flatMap(resources, (x) => x);
        const resourcesIds = allResources.map((x) => x.id);

        const fromDateTime = selectedDate.setZone(timezone).startOf('day').toISO();
        const toDateTime = selectedDate.setZone(timezone).endOf('day').toISO();

        const query = {
            resourcesIds,
            fromDateTime,
            toDateTime,
        };
        loadUnavailability(query);
    };

    return (
        <ContentLoader load={() => loadConfiguration()} loadStatus={configurationLoadStatus}>
            <ContentLoader load={() => loadAreas()} loadStatus={areasLoadStatus}>
                <ContentLoader load={() => loadResources()} loadStatus={resourcesLoadStatus}>
                    <SignalRProvider
                        areas={availableAreas}
                        resources={availableResources}
                        configuration={configuration}
                        selectedSite={site}
                        selectedDate={selectedDate}
                        updateBookings={updateBookings}
                        refreshBookingUnavailability={refreshBookingUnavailability}
                    >
                        <ContentLoader
                            load={() => loadBookings()}
                            loadStatus={bookingsLoadStatus}
                            renderChildrenWhileLoading
                        >
                            <ContentLoader
                                load={onLoadResources}
                                loadStatus={unavailability.loadStatus}
                                renderChildrenWhileLoading
                            >
                                {children}
                            </ContentLoader>
                        </ContentLoader>
                    </SignalRProvider>
                </ContentLoader>
            </ContentLoader>
        </ContentLoader>
    );
};

export default memo(Provider);
