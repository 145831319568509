import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

// eslint-disable-next-line import/no-cycle
import WelcomeMessage from './WelcomeMessage';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    firstName: state.authentication.user?.firstName,
});

export type WelcomeMessageProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(WelcomeMessage);
