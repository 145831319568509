import { InitialiseNavigationAction, ToggleNavigationAction } from '../types';

import * as actionTypes from './actionTypes';

export const onToggleNavigation = (isOpen: boolean): ToggleNavigationAction => ({
    type: actionTypes.TOGGLE_NAV,
    payload: isOpen,
});

export const onInitialise = (): InitialiseNavigationAction => ({
    type: actionTypes.INITIALISE_NAV,
});
