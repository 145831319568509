import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Button } from 'components/FormElements';
import { EditMode } from 'features/constants';

import TabButton from './Components/TabButton';
import TabContent from './Components/TabContent';
import Tab from './Models/Tab';

import './Tabs.scss';

export interface TabsProps {
    tabs: Tab[];
    leftSideElements?: JSX.Element[];
    rightSideElements?: JSX.Element[];
    editMode?: EditMode;
    handleEditModeChanged?: () => void;
}

const Tabs = ({
    tabs,
    leftSideElements,
    rightSideElements,
    editMode,
    handleEditModeChanged,
}: TabsProps): JSX.Element => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);

    useEffect(() => {
        setSelectedTab(tabs.find((item) => item.id === selectedTab.id) || tabs[0]);
    }, [tabs]);

    const handleClick = (tabId: string): void => {
        const newTab = tabs.find((tab) => tab.id === tabId);
        setSelectedTab(newTab);
    };

    return (
        <div className="tabsContainer">
            <div className="tabs">
                {leftSideElements &&
                    leftSideElements.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className="left-side-element">
                            {item}
                        </div>
                    ))}

                {tabs.map(({ title, icon, id, dataTestId, tabButton }) => {
                    return (
                        <TabButton
                            title={title}
                            icon={icon}
                            selected={id === selectedTab.id}
                            id={id}
                            onClick={handleClick}
                            key={id}
                            dataTestId={dataTestId}
                            tabButtonLabel={tabButton}
                        />
                    );
                })}

                {rightSideElements &&
                    rightSideElements.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className="right-side-element">
                            {item}
                        </div>
                    ))}
            </div>
            {editMode !== undefined && (
                <div className="banner" data-testid="repeat-banner">
                    <ReactMarkdown source={t(EditMode[editMode]) || ''} />
                    {editMode === EditMode.ENABLE_OCCURRENCE && handleEditModeChanged && (
                        <Button
                            dataTestId="switch-button"
                            text={t('Repeat_Switch_To_Series')}
                            className="repeat-banner-button"
                            onClick={() => {
                                handleEditModeChanged();
                            }}
                        />
                    )}
                </div>
            )}
            <TabContent tabId={selectedTab.id}>{selectedTab.content}</TabContent>
        </div>
    );
};

export default Tabs;
