import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RightArrowLong } from 'assets/icons/ArrowRightLong.svg';
import classNames from 'classnames';
import { Button, DateTimePicker, FormGroup } from 'components/FormElements';
import PersonDetailsPanel from 'components/PersonDetailsPanel/PersonDetailsPanel';
import GeographicStructureSelectorComponent from 'components/SiteHeader/Components/GeographicStructureSelector';
import { VisitorPanel } from 'components/VisitorPanel/VisitorPanel';
import { PersonType } from 'enums/PersonType';
import { DateTime } from 'luxon';
import { Person } from 'services/ApiClients/Models';
import { Visitor } from 'services/ApiClients/VisitorManagement/Models/Visitor';
import { buildNameSearchQueryItems, SearchData, SearchItem } from 'utilities/searchUtils';
import { v4 as uuidv4 } from 'uuid';

import { periodUnitEnum } from '../../../components/BookingGrid/Models/CellFillTypes';
import Toggle from '../../../components/FormElements/Toggle';
import { PersonSearchData } from '../../../components/PersonSearchPanels/interfaces/PersonSearchData';
import { VmsPersonSearch } from '../../../components/PersonSearchPanels/VmsPersonSearch/VmsPersonSearch';
import { BookableDays, Configuration } from '../../../services/ApiClients/Configuration';
import ToastService from '../../../services/ToastService';
import { formatBookableDays } from '../../../utilities/bookingUtils';
import { DEFAULT_DATE_FORMAT, TWENTY_FOUR_HOUR_FORMAT } from '../../constants';

import MultiDayDueTimePanel, { MultiDayTime } from './MultiDayDueTimePanel/MultiDayDueTimePanel';
// eslint-disable-next-line import/no-cycle
import { VisitorBookingFormProps } from '.';

import './VisitorBookingForm.scss';

const VisitorBookingForm = ({
    selectedSite,
    selectedSiteGroup,
    siteGroupsGeoStructure,
    tenantId,
    isSitesLoading,
    isSearchVisitorsInProgress,
    isSearchHostsInProgress,
    searchVisitorsResult,
    searchHostsResult,
    searchInternalVisitors,
    searchExternalVisitors,
    searchHosts,
    visitorType,
    resetSearchVisitorsResult,
    resetSearchHostsResult,
    createExternalVisitor,
    editExternalVisitor,
    isVisitorCreationOrEditingInProgress,
    isVisitorCreated,
    isVisitorUpdated,
    bookingData,
    setBookingData,
    bookableDaysConfig,
    timezone,
    defaultDueDateTime,
    dueTimeErrorMessage,
    hourFormat = TWENTY_FOUR_HOUR_FORMAT,
    is24HourFormat,
    editVisitorBookings,
    filteredSitesGeoStructure,
}: VisitorBookingFormProps): JSX.Element => {
    const { t } = useTranslation();

    const [visitorSearchData, setVisitorSearchData] = useState<PersonSearchData>({} as PersonSearchData);
    const [hostSearchData, setHostSearchData] = useState<PersonSearchData>({} as PersonSearchData);

    const [isNewVisitorPanelOpen, setNewVisitorPanelOpen] = useState<boolean>(false);
    const [isVisitorDetailsPanelOpen, setVisitorDetailsPanelOpen] = useState<boolean>(false);
    const [isHostDetailsPanelOpen, setHostDetailsPanelOpen] = useState<boolean>(false);
    const [isMultiDayDueTimePanelOpen, setMultiDayDueTimePanelOpen] = useState<boolean>(false);

    const [submittedExtVisitorData, setSubmittedExtVisitorData] = useState<Visitor | null>(null);

    const [dueTimeWarningMessage, setDueTimeWarningMessage] = useState<string>('');

    useEffect(() => {
        if (!isNewVisitorPanelOpen) {
            resetSearchVisitorsResult();
            setVisitorSearchData({} as PersonSearchData);
        }
    }, [isNewVisitorPanelOpen]);

    useEffect(() => {
        resetSearchVisitorsResult();
        resetSearchHostsResult();
        setVisitorSearchData({} as PersonSearchData);
        setHostSearchData({} as PersonSearchData);
        setDueTimeWarningMessage('');
    }, [visitorType]);

    useEffect(() => {
        if (!isVisitorCreationOrEditingInProgress && (isVisitorCreated || isVisitorUpdated)) {
            setBookingData({ ...bookingData, visitor: submittedExtVisitorData });
            setNewVisitorPanelOpen(false);
            setVisitorDetailsPanelOpen(false);
        }
    }, [isVisitorCreationOrEditingInProgress]);

    const formattedBookableDays: BookableDays = useMemo(
        () => formatBookableDays(bookableDaysConfig),
        [bookableDaysConfig]
    );
    const resetDueTimeWarningMessage = (): any => dueTimeWarningMessage && setDueTimeWarningMessage('');

    const onVisitorSearch = useCallback(
        (visitorSearch): void => {
            let searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>> = [];
            if (visitorSearch.name) {
                searchFieldsConditionsOrUnitedByAnd = buildNameSearchQueryItems(visitorSearch.name);
            }

            const searchData: SearchData = {
                searchFieldsConditionAND: !visitorSearch.company
                    ? []
                    : [{ field: 'Company', value: visitorSearch.company }],
                searchFieldsConditionsOrUnitedByAnd,
                top: 100,
                orderBy: 'Surname',
            };
            if (visitorType === PersonType.Internal) {
                searchInternalVisitors(searchData);
            } else {
                searchExternalVisitors(searchData);
            }
        },
        [visitorSearchData, visitorType]
    );

    const onExternalVisitorSave = useCallback(
        (visitorData: any): void => {
            const visitor = {
                id: bookingData?.visitor?.id ?? uuidv4(),
                geographicStructureId: selectedSite?.id.toString(),
                title: typeof visitorData.title === 'object' ? visitorData.title.value : visitorData.title,
                company: visitorData.company,
                email: visitorData.email,
                firstname: visitorData.firstname,
                surname: visitorData.surname,
                countryCode: visitorData.countryCode.value,
                mobile: visitorData.mobile.toString(),
                visitorType: 'External',
            } as Visitor;

            setSubmittedExtVisitorData(visitor);

            if (isNewVisitorPanelOpen) {
                createExternalVisitor(visitor);
            }

            if (isVisitorDetailsPanelOpen) {
                editExternalVisitor(visitor);
            }
        },
        [selectedSite, isNewVisitorPanelOpen, isVisitorDetailsPanelOpen]
    );

    const onHostSearch = useCallback(
        (searchData: any): void => {
            let searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>> = [];

            if (searchData.name) {
                searchFieldsConditionsOrUnitedByAnd = buildNameSearchQueryItems(searchData.name);
            }

            const modifiedSearchData: SearchData = {
                searchFieldsConditionsOrUnitedByAnd,
                top: 100,
                orderBy: 'Surname',
            };

            searchHosts(modifiedSearchData);
        },
        [hostSearchData]
    );

    const onMultiDayDueTimesSave = (e: MultiDayTime[]): void => {
        setBookingData({ ...bookingData, dueDateTimes: e });
    };

    const getNoticePeriodLastDay = (): DateTime => {
        const todayDate = DateTime.now().setZone(timezone);
        let noticePeriodLastDay = todayDate;

        if (bookableDaysConfig.noticePeriodDuration && bookableDaysConfig.noticePeriodUnit !== null) {
            noticePeriodLastDay = todayDate.plus({
                [periodUnitEnum[bookableDaysConfig.noticePeriodUnit]]: bookableDaysConfig.noticePeriodDuration,
            });
        }

        return noticePeriodLastDay;
    };

    const checkMaxNoticePeriodAvailability = (current: any): boolean => {
        const todayDate = DateTime.now().setZone(timezone);

        if (bookableDaysConfig.noticePeriodDuration && bookableDaysConfig.noticePeriodUnit !== null) {
            return (
                current <=
                todayDate.plus({
                    [periodUnitEnum[bookableDaysConfig.noticePeriodUnit]]: bookableDaysConfig.noticePeriodDuration,
                })
            );
        }

        return false;
    };

    const isToggleDisabled = (): boolean => {
        const dueDateWithTimezone = bookingData.dueDateTime.setZone(timezone);

        const getLastAvailableDay = (date: any): any => {
            const day = date.toFormat('cccc').toLowerCase();
            if (Object.keys(formattedBookableDays).includes(day)) {
                return date;
            }
            return getLastAvailableDay(
                date.minus({
                    day: 1,
                })
            );
        };

        return (
            dueDateWithTimezone.startOf('day').equals(getNoticePeriodLastDay().startOf('day')) ||
            dueDateWithTimezone.startOf('day').equals(getLastAvailableDay(getNoticePeriodLastDay()).startOf('day'))
        );
    };

    useEffect(() => {
        if (isToggleDisabled() && !editVisitorBookings?.isEdit) {
            setBookingData({
                ...bookingData,
                isMultiday: false,
            });
            ToastService.Warning({
                message: t('Toast_MultiDayMaxNoticePeriodException'),
            });
        }
    }, [bookingData.dueDateTime]);

    return (
        <div className="visitor-booking-form">
            <FormGroup disabled={false}>
                <VmsPersonSearch
                    dataTestId="visitor-search"
                    buttonLabel={t('VisitorBooking_Visitor')}
                    panelLabel={t('VisitorBooking_SearchVisitors')}
                    resultPlaceHolder={t('PersonSearch_VisitorsResultPlaceholder')}
                    required
                    onSelect={(selectedPerson: Visitor) => {
                        resetDueTimeWarningMessage();
                        setBookingData({ ...bookingData, visitor: selectedPerson });
                    }}
                    onSearch={onVisitorSearch}
                    onSearchDataChange={(searchValue: object) => {
                        setVisitorSearchData({ ...visitorSearchData, ...searchValue });
                    }}
                    searchResult={searchVisitorsResult}
                    selectedItem={bookingData.visitor as unknown as Person}
                    companySearch
                    iaSearchInProgress={isSearchVisitorsInProgress}
                    resetSearchResult={resetSearchVisitorsResult}
                    searchData={visitorSearchData}
                    personType={visitorType}
                    onNewVisitor={() => setNewVisitorPanelOpen(true)}
                    setNewVisitorPanelOpen={setNewVisitorPanelOpen}
                    setPersonDetailsPanelOpen={setVisitorDetailsPanelOpen}
                    editVisitorBookings={editVisitorBookings}
                />

                <GeographicStructureSelectorComponent
                    selectedSite={bookingData.site}
                    selectedSiteGroup={selectedSiteGroup}
                    sitesGeoStructure={filteredSitesGeoStructure}
                    siteGroupsGeoStructure={siteGroupsGeoStructure}
                    tenantId={tenantId as string}
                    isLoading={isSitesLoading}
                    required
                    isInputView
                    onSiteSelect={(site) => {
                        setBookingData({ ...bookingData, site, isValidToDate: undefined });
                        setDueTimeWarningMessage(t('VisitorBooking_ReviewDueTime'));
                    }}
                    onBreadcrumbClick={() => {}}
                    onGeographicStructureClick={() => {}}
                    isDisabled={editVisitorBookings?.isEdit && !!editVisitorBookings?.multiDaySiteVisitId}
                    isFromVisitorBookingPage
                />

                <div className="date-time-row">
                    <DateTimePicker
                        className="due-date-time"
                        name="dateTime"
                        label={t('VisitorBooking_Due')}
                        title={t('VisitorBooking_DateTime')}
                        timeTitle={t('VisitorBooking_Time')}
                        dateTimeValue={bookingData.dueDateTime}
                        isDateTimeRange={false}
                        dataTestId="dateTime-dateTimePicker"
                        onChange={(newDateTime) => {
                            resetDueTimeWarningMessage();
                            setBookingData({
                                ...bookingData,
                                dueDateTime: newDateTime,
                                isValidToDate: undefined,
                            });
                        }}
                        resourceTypeSiteConfiguration={{
                            noticePeriodDuration: bookableDaysConfig.noticePeriodDuration,
                            noticePeriodUnit: bookableDaysConfig.noticePeriodUnit,
                            bookableDays: formattedBookableDays,
                            bookingSlotUnits: -1,
                            reservationWindowInSeconds: -1,
                        }}
                        isValidDate={(current: DateTime) => {
                            return editVisitorBookings?.isEdit && editVisitorBookings?.multiDaySiteVisitId
                                ? bookingData.dueDateTime.setZone(timezone).toFormat(DEFAULT_DATE_FORMAT) ===
                                      current.toFormat(DEFAULT_DATE_FORMAT)
                                : current.toFormat(DEFAULT_DATE_FORMAT) >=
                                      defaultDueDateTime.toFormat(DEFAULT_DATE_FORMAT) &&
                                      checkMaxNoticePeriodAvailability(current);
                        }}
                        initialViewDate={defaultDueDateTime}
                        selectedDate={DateTime.utc().setZone(timezone)}
                        timeInterval={bookableDaysConfig.tenantVisitArrivalTimeInterval}
                        nowDateTime={DateTime.now().setZone(timezone)}
                        hourFormat={hourFormat}
                        timezone={timezone}
                    />

                    <Toggle
                        type="checkbox"
                        label={t('VisitorBooking_MultiDay')}
                        inputId="inputId"
                        onChange={() => {
                            setBookingData({
                                ...bookingData,
                                isMultiday: !bookingData.isMultiday,
                                isValidToDate: undefined,
                            });
                            resetDueTimeWarningMessage();
                        }}
                        dataTestId="visitor-booking-multi-day-toggle-btn"
                        checked={!!bookingData.isMultiday}
                        inputName="input"
                        isDisabled={isToggleDisabled() || editVisitorBookings?.isEdit}
                    />

                    {bookingData.isMultiday && (
                        <>
                            <RightArrowLong className="arrow-right" />

                            <DateTimePicker
                                name="dateTime"
                                className="valid-to-date"
                                dataTestId="valid-to-date"
                                label={t('VisitorBooking_ValidUntil')}
                                title={t('VisitorBooking_Date')}
                                dateTimeValue={bookingData.isValidToDate}
                                showTime={false}
                                onChange={(newDate) => {
                                    setBookingData({ ...bookingData, isValidToDate: newDate });
                                    resetDueTimeWarningMessage();
                                }}
                                resourceTypeSiteConfiguration={{} as Configuration}
                                selectedDate={DateTime.utc()}
                                initialViewDate={bookingData.dueDateTime.plus({ days: 1 })}
                                isValidDate={(current: DateTime) =>
                                    current > bookingData.dueDateTime &&
                                    !!formattedBookableDays[current.toFormat('cccc').toLowerCase()] &&
                                    checkMaxNoticePeriodAvailability(current)
                                }
                                hourFormat={hourFormat}
                                timezone={timezone}
                            />

                            {bookingData.isValidToDate && (
                                <Button
                                    className="multi-day-side-panel-button"
                                    dataTestId="multi-day-side-panel-button"
                                    text={t('VisitorBooking_EditMultiDay')}
                                    onClick={() => {
                                        setMultiDayDueTimePanelOpen(true);
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
                <div
                    className={classNames(
                        'due-time-error-message',
                        (dueTimeWarningMessage || dueTimeErrorMessage) && 'with-value'
                    )}
                >
                    {dueTimeErrorMessage || dueTimeWarningMessage}
                </div>

                <VmsPersonSearch
                    className="host-search"
                    dataTestId="host-search"
                    buttonLabel={t('VisitorBooking_Host')}
                    panelLabel={t('VisitorBooking_SearchHosts')}
                    resultPlaceHolder={t('PersonSearch_HostsResultPlaceholder')}
                    required={visitorType === PersonType.External}
                    onSelect={(selectedPerson: Person) => {
                        resetDueTimeWarningMessage();
                        setBookingData({ ...bookingData, host: selectedPerson });
                    }}
                    onSearch={onHostSearch}
                    onSearchDataChange={(searchValue: object) => {
                        setHostSearchData({ ...visitorSearchData, ...searchValue });
                    }}
                    searchResult={searchHostsResult}
                    selectedItem={bookingData.host as Person}
                    // selectedVisitor={bookingData}
                    companySearch={false}
                    iaSearchInProgress={isSearchHostsInProgress}
                    resetSearchResult={resetSearchHostsResult}
                    searchData={hostSearchData}
                    personType={PersonType.Internal}
                    setPersonDetailsPanelOpen={setHostDetailsPanelOpen}
                />
            </FormGroup>

            <VisitorPanel
                isOpen={isNewVisitorPanelOpen}
                setPanelOpen={setNewVisitorPanelOpen}
                creationOrUpdatingInProgress={isVisitorCreationOrEditingInProgress}
                onSave={onExternalVisitorSave}
            />

            {bookingData.visitor && visitorType === PersonType.External && (
                <VisitorPanel
                    isOpen={isVisitorDetailsPanelOpen}
                    setPanelOpen={setVisitorDetailsPanelOpen}
                    creationOrUpdatingInProgress={isVisitorCreationOrEditingInProgress}
                    person={bookingData?.visitor as unknown as Person}
                    onSave={(updatedVisitor) => {
                        onExternalVisitorSave(updatedVisitor);
                    }}
                    showBackgroundMask
                    isEditMode
                />
            )}

            {bookingData.visitor && visitorType === PersonType.Internal && (
                <PersonDetailsPanel
                    title={t('PersonDetails_VisitorDetails')}
                    isOpen={isVisitorDetailsPanelOpen}
                    setPanelOpen={setVisitorDetailsPanelOpen}
                    person={bookingData?.visitor as unknown as Person}
                    nameTitle={t('PersonDetails_VisitorName')}
                />
            )}

            {bookingData.host && (
                <PersonDetailsPanel
                    title={t('PersonDetails_HostDetails')}
                    isOpen={isHostDetailsPanelOpen}
                    setPanelOpen={setHostDetailsPanelOpen}
                    person={bookingData?.host as unknown as Person}
                    nameTitle={t('PersonDetails_HostName')}
                />
            )}

            {bookingData.isValidToDate && (
                <MultiDayDueTimePanel
                    onSave={onMultiDayDueTimesSave}
                    savedMultiDayDueTimes={bookingData.dueDateTimes || null}
                    setPanelOpen={setMultiDayDueTimePanelOpen}
                    isOpen={isMultiDayDueTimePanelOpen}
                    dateFrom={bookingData.dueDateTime}
                    dateTo={bookingData.isValidToDate}
                    bookableDays={formattedBookableDays}
                    timeSlotInterval={bookableDaysConfig.tenantVisitArrivalTimeInterval}
                    nowDateTime={DateTime.utc().setZone(timezone)}
                    hourFormat={hourFormat}
                    is24HourFormat={is24HourFormat}
                />
            )}
        </div>
    );
};

export default VisitorBookingForm;
