import { connect } from 'react-redux';
import { updateSite } from 'features/Filters/Redux/actions';
import { RootState } from 'PortalTypes';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';

import {
    getFloorPlanSearchData,
    resetFloorPlanSearchData,
    setSearchResultItem,
    setSearchTrigger,
} from '../../Redux/actions';

// eslint-disable-next-line import/no-cycle
import FloorPlanSearch from './FloorPlanSearch';

interface PassedProps {
    onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps: PassedProps) => ({
    searchResult: state.floorPlan.searchResult,
    searchResultLoadStatus: state.floorPlan.searchResultLoadStatus,
    extendedHourFormat: state.authentication.user?.extendedHourFormat,
    selectedSiteTimezone: state.filters.global.site?.timezone,
    sitesGeoStructure: state.referenceData.sites.items,
    selectedSite: state.filters.global.site,
    ...ownProps,
});

const mapDispatchToProps = {
    getSearchData: getFloorPlanSearchData.request,
    resetFloorPlanSearchData,
    updateSite: (site: GeographicStructureItem) => updateSite(site),
    setArea: (area: GeographicStructureItem) => updateSite(area),
    setSearchResultItem,
    setSearchTrigger,
};

export type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanSearch);
