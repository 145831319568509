import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import classnames from 'classnames';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import './Select.scss';

export interface ListItem {
    label: string;
    value: string;
}

export interface SelectProps extends InputWrapperProps {
    validation?: any;
    error?: string;
    control?: any;
    initialValue?: any;
    items: ListItem[];
    isDisabled?: boolean;
    placeholder?: string;
    id?: string;
    horizontal?: boolean;
    onChange?: (value: any) => void;
    value?: any;
    styles?: any;
    isSearchable?: boolean;
    dataTestId?: string;
}

export default (props: SelectProps): JSX.Element | null => {
    const {
        label,
        name,
        className,
        hideLabel,
        validation,
        items,
        initialValue,
        isDisabled = false,
        error,
        control,
        placeholder = 'Select...',
        id,
        horizontal = false,
        onChange,
        value,
        styles,
        isSearchable,
        dataTestId = '',
    } = props;

    if (!items.length) {
        return null;
    }
    const [hasValue, setHasValue] = React.useState<boolean>(!!initialValue);

    const defaultValue = items.find((item) => item.value === initialValue) || null;

    return (
        <InputWrapper
            label={label}
            name={name}
            className={className}
            hideLabel={hideLabel}
            required={validation?.required}
            error={error}
            horizontal={horizontal}
            data-testid={dataTestId}
        >
            {control ? (
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    data-testid={dataTestId}
                    rules={validation}
                    render={({ onChange: onChangeEvent, name: subName }) => (
                        <Select
                            name={subName}
                            onChange={(event) => {
                                onChangeEvent(event);
                                setHasValue(true);
                            }}
                            className={classnames('select-container', [
                                {
                                    'select-container--has-value': hasValue,
                                    'select-container--disabled': isDisabled,
                                    error,
                                },
                            ])}
                            classNamePrefix="select"
                            options={items}
                            defaultValue={defaultValue}
                            placeholder={placeholder}
                            isDisabled={isDisabled}
                            id={id}
                            styles={styles}
                            isSearchable={isSearchable}
                            data-testid={dataTestId}
                        />
                    )}
                />
            ) : (
                <Select
                    onChange={onChange}
                    className={classnames('select-container', [
                        {
                            'select-container--has-value': hasValue,
                            'select-container--disabled': isDisabled,
                            error,
                        },
                    ])}
                    classNamePrefix="select"
                    options={items}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    id={id}
                    menuPlacement="auto"
                    value={value}
                    styles={styles}
                    isSearchable={isSearchable}
                    data-testid={dataTestId}
                />
            )}
        </InputWrapper>
    );
};
