import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { GetSiteVisitsRequest } from 'services/ApiClients/VisitorManagement/Models/GetSiteVisitsRequest';
import { createAsyncAction } from 'typesafe-actions';

import { SiteVisit } from '../../../services/ApiClients/VisitorManagement/Models/SiteVisit';
import { Visitor } from '../../../services/ApiClients/VisitorManagement/Models/Visitor';

export const getSiteVisits = createAsyncAction(
    'GET_SITE_VISITS_REQUEST',
    'GET_SITE_VISITS_SUCCESS',
    'GET_SITE_VISITS_FAILURE'
)<GetSiteVisitsRequest, ApiResponse<{ siteVisits: SiteVisit[] }>, null>();

export const checkInSiteVisit = createAsyncAction(
    'CHECKIN_SITE_VISIT_REQUEST',
    'CHECKIN_SITE_VISIT_SUCCESS',
    'CHECKIN_SITE_VISIT_FAILURE'
)<{ siteVisitId: string; getSiteVisitsRequestData: GetSiteVisitsRequest }, void, null>();

export const checkOutSiteVisit = createAsyncAction(
    'CHECKOUT_SITE_VISIT_REQUEST',
    'CHECKOUT_SITE_VISIT_SUCCESS',
    'CHECKOUT_SITE_VISIT_FAILURE'
)<
    { siteVisitId: string; cancelFutureSiteVisits: boolean; getSiteVisitsRequestData: GetSiteVisitsRequest },
    void,
    null
>();

export const cancelSiteVisit = createAsyncAction(
    'CANCEL_SITE_VISIT_REQUEST',
    'CANCEL_SITE_VISIT_SUCCESS',
    'CANCEL_SITE_VISIT_FAILURE'
)<{ siteVisitId: string; getSiteVisitsRequestData: GetSiteVisitsRequest }, void, null>();

export const cancelMultiDaySiteVisit = createAsyncAction(
    'CANCEL_MULTI_DAY_SITE_VISIT_REQUEST',
    'CANCEL_MULTI_DAY_SITE_VISIT_SUCCESS',
    'CANCEL_MULTI_DAY_SITE_VISIT_FAILURE'
)<{ multiDaySiteVisitId: string; getSiteVisitsRequestData: GetSiteVisitsRequest }, void, null>();

export const getEvacuationReports = createAsyncAction(
    'GET_EVACUATION_REPORTS_REQUEST',
    'GET_EVACUATION_REPORTS_SUCCESS',
    'GET_EVACUATION_REPORTS_FAILURE'
)<string, void, null>();

export const editExternalVisitorFromReception = createAsyncAction(
    'RECEPTION_EDIT_EXTERNAL_VISITORS_REQUEST',
    'RECEPTION_EDIT_EXTERNAL_VISITORS_SUCCESS',
    'RECEPTION_EDIT_EXTERNAL_VISITORS_FAILURE'
)<{ visitor: Visitor; getSiteVisitsRequestData: GetSiteVisitsRequest }, void, AxiosError>();

export default {
    getSiteVisits,
    checkInSiteVisit,
    getEvacuationReports,
    checkOutSiteVisit,
    cancelSiteVisit,
    cancelMultiDaySiteVisit,
    editExternalVisitorFromReception,
};
