import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TIMEZONE, TWENTY_FOUR_HOUR_FORMAT } from 'features/constants';
import { DateTime } from 'luxon';

import BackgroundMask from '../../components/BackgroundMask';
import DatePickerForHeader from '../../components/FormElements/DatePicker/DatePickerForHeader';
import { BookingIconButton, ReportsIconButton } from '../../components/IconButtons/HeaderIconButtons';
import Loading from '../../components/Loading';
import Header from '../../components/SiteSubHeader';
import Tabs from '../../components/Tabs';
import TabButtonLabelAndNumber from '../../components/Tabs/Components/TabButtonLabels/TabButtonLabelAndNumber';
import { createTab } from '../../components/Tabs/Models/Tab';
import { SiteVisit } from '../../services/ApiClients/VisitorManagement/Models/SiteVisit';
import { Visitor } from '../../services/ApiClients/VisitorManagement/Models/Visitor';

import Bookings from './Bookings/Bookings';
// eslint-disable-next-line import/no-cycle
import { ReceptionProps } from '.';

import './Reception.scss';

interface SiteVisitForSorting extends SiteVisit {
    sortingDate: DateTime;
}

const Reception = ({
    selectedSiteTimezone,
    areSiteVisitsLoading,
    siteVisits,
    getSiteVisits,
    selectedSite,
    checkInSiteVisit,
    checkOutSiteVisit,
    cancelSiteVisit,
    cancelMultiDaySiteVisit,
    getEvacuationReports,
    isGetReportsInProgress,
    isVisitorEditingInProgress,
    editExternalVisitor,
    hourFormat = TWENTY_FOUR_HOUR_FORMAT,
    sites,
}: ReceptionProps): JSX.Element => {
    const { t } = useTranslation();
    const [date, setDate] = useState<DateTime>(DateTime.utc().setZone(selectedSiteTimezone || DEFAULT_TIMEZONE));
    const navigate = useNavigate();

    useEffect(() => {
        getSiteVisits(selectedSite?.id.toString() || '', date);
    }, [date]);

    const { dueSiteVisits, checkedInSiteVisits, checkedOutSiteVisits, totalSiteVisits } = useMemo(() => {
        const siteVisitsForSorting = siteVisits as SiteVisitForSorting[];

        siteVisitsForSorting?.forEach((visit) => {
            // eslint-disable-next-line no-param-reassign
            visit.sortingDate = DateTime.fromISO(
                visit.visitorCheckedOutDate || visit.visitorCheckedInDate || visit.dueDateTime
            );
        });

        let sortedSiteVisits = siteVisitsForSorting?.sort((a, b) => a.visitor.surname.localeCompare(b.visitor.surname));
        sortedSiteVisits = siteVisitsForSorting?.sort((a, b) => a.sortingDate.diff(b.sortingDate));

        const due =
            sortedSiteVisits?.filter(
                (siteVisit) => !siteVisit.visitorCheckedIn && !siteVisit.visitorCheckedOut && !siteVisit.isCanceled
            ) || [];

        const checkedIn =
            sortedSiteVisits?.filter((siteVisit) => siteVisit.visitorCheckedIn && !siteVisit.visitorCheckedOut) || [];

        const checkedOut = sortedSiteVisits?.filter((siteVisit) => siteVisit.visitorCheckedOut) || [];

        const total = sortedSiteVisits?.filter((siteVisit) => !siteVisit.isCanceled) || [];

        return {
            dueSiteVisits: due,
            checkedInSiteVisits: checkedIn,
            checkedOutSiteVisits: checkedOut,
            totalSiteVisits: total,
        };
    }, [siteVisits]);

    const onExternalVisitorSave = useCallback(
        (siteVisit: SiteVisit, visitorData: any): void => {
            const visitor = {
                id: siteVisit.visitor?.id,
                geographicStructureId: siteVisit.visitor.id.toString(),
                title: typeof visitorData.title === 'object' ? visitorData.title.value : visitorData.title || null,
                company: visitorData.company,
                email: visitorData.email,
                firstname: visitorData.firstname,
                surname: visitorData.surname,
                countryCode: visitorData.countryCode.value,
                mobile: visitorData.mobile.toString(),
                visitorType: 'External',
            } as Visitor;

            editExternalVisitor(visitor, selectedSite?.id.toString() || '', date);
        },
        [selectedSite, date]
    );

    const headerItems = [
        { label: t('Reception_BookingTime') },
        { label: t('Reception_BookingVisitor') },
        { label: t('Reception_BookingHost') },
    ];

    const isToday = date.startOf('day').equals(
        DateTime.utc()
            .setZone(selectedSiteTimezone || DEFAULT_TIMEZONE)
            .startOf('day')
    );

    const tabs = [
        createTab(
            'due',
            null,
            'due-tab',
            null,
            <Bookings
                headerItems={headerItems}
                isToday={isToday}
                isLoading={areSiteVisitsLoading}
                siteVisits={dueSiteVisits}
                checkInSiteVisit={(siteVisitId) => {
                    checkInSiteVisit(siteVisitId, selectedSite?.id.toString() || '', date);
                }}
                cancelSiteVisit={(siteVisitId, isMultiDay) => {
                    if (isMultiDay) {
                        cancelMultiDaySiteVisit(siteVisitId, selectedSite?.id.toString() || '', date);
                    } else {
                        cancelSiteVisit(siteVisitId, selectedSite?.id.toString() || '', date);
                    }
                }}
                timezone={selectedSiteTimezone || DEFAULT_TIMEZONE}
                noItemsMessage={t('Reception_NoVisitorsDue')}
                onExternalVisitorSave={onExternalVisitorSave}
                isVisitorEditingInProgress={isVisitorEditingInProgress}
                hourFormat={hourFormat}
                sites={sites}
            />,
            <TabButtonLabelAndNumber label={t('Reception_Due')} number={dueSiteVisits.length} />
        ),

        createTab(
            'checkedIn',
            null,
            'checked-in-tab',
            null,
            <Bookings
                headerItems={headerItems}
                isToday={isToday}
                isLoading={areSiteVisitsLoading}
                siteVisits={checkedInSiteVisits}
                checkOutSiteVisit={(siteVisitId, cancelFutureSiteVisits) => {
                    checkOutSiteVisit(siteVisitId, cancelFutureSiteVisits, selectedSite?.id.toString() || '', date);
                }}
                timezone={selectedSiteTimezone || DEFAULT_TIMEZONE}
                noItemsMessage={t('Reception_NoVisitorsCheckedIn')}
                onExternalVisitorSave={onExternalVisitorSave}
                isVisitorEditingInProgress={isVisitorEditingInProgress}
                hourFormat={hourFormat}
            />,
            <TabButtonLabelAndNumber label={t('Reception_CheckedIn')} number={checkedInSiteVisits.length} />
        ),

        createTab(
            'checkedOut',
            null,
            'checked-out-tab',
            null,
            <Bookings
                headerItems={headerItems}
                isToday={isToday}
                isLoading={areSiteVisitsLoading}
                siteVisits={checkedOutSiteVisits}
                timezone={selectedSiteTimezone || DEFAULT_TIMEZONE}
                noItemsMessage={t('Reception_NoVisitorsCheckedOut')}
                onExternalVisitorSave={onExternalVisitorSave}
                isVisitorEditingInProgress={isVisitorEditingInProgress}
                hourFormat={hourFormat}
            />,
            <TabButtonLabelAndNumber label={t('Reception_CheckedOut')} number={checkedOutSiteVisits.length} />
        ),
        createTab(
            'noShows',
            null,
            'no-shows-tab',
            null,
            <Bookings
                headerItems={headerItems}
                isToday={isToday}
                isLoading={areSiteVisitsLoading}
                siteVisits={[]}
                timezone={selectedSiteTimezone || DEFAULT_TIMEZONE}
                noItemsMessage={t('Reception_NoVisitorsNoShows')}
                onExternalVisitorSave={onExternalVisitorSave}
                isVisitorEditingInProgress={isVisitorEditingInProgress}
                hourFormat={hourFormat}
            />,
            <TabButtonLabelAndNumber label={t('Reception_NoShows')} number={0} />
        ),
        createTab(
            'checkIn',
            null,
            'total-tab',
            null,
            <Bookings
                headerItems={headerItems}
                isToday={isToday}
                isLoading={areSiteVisitsLoading}
                siteVisits={totalSiteVisits}
                checkInSiteVisit={(siteVisitId) => {
                    checkInSiteVisit(siteVisitId, selectedSite?.id.toString() || '', date);
                }}
                checkOutSiteVisit={(siteVisitId, cancelFutureSiteVisits) => {
                    checkOutSiteVisit(siteVisitId, cancelFutureSiteVisits, selectedSite?.id.toString() || '', date);
                }}
                cancelSiteVisit={(siteVisitId, isMultiDay = false) => {
                    if (isMultiDay) {
                        cancelMultiDaySiteVisit(siteVisitId, selectedSite?.id.toString() || '', date);
                    } else {
                        cancelSiteVisit(siteVisitId, selectedSite?.id.toString() || '', date);
                    }
                }}
                onExternalVisitorSave={onExternalVisitorSave}
                timezone={selectedSiteTimezone || DEFAULT_TIMEZONE}
                noItemsMessage={t('Reception_NoVisitorsTotal')}
                isVisitorEditingInProgress={isVisitorEditingInProgress}
                hourFormat={hourFormat}
                sites={sites}
            />,
            <TabButtonLabelAndNumber label={t('Reception_Total')} number={totalSiteVisits?.length || 0} />
        ),
    ];

    return (
        <main className="reception-page">
            {isGetReportsInProgress && (
                <>
                    <BackgroundMask className="open" />
                    <Loading />
                </>
            )}

            <Header
                title={t('Reception_Title')}
                dataTestId="reception-title"
                withButtons
                rightSideItems={[
                    <BookingIconButton
                        key="booking-btn"
                        dataTestId="booking-button"
                        label={t('Reception_Booking')}
                        onClick={() => navigate('/visitor-booking')}
                    />,
                    <ReportsIconButton
                        key="reports-btn"
                        dataTestId="reports-button"
                        label={t('Reception_Reports')}
                        onClick={() => {
                            getEvacuationReports(selectedSite?.id.toString() || '');
                        }}
                    />,
                ]}
            />
            <Tabs
                leftSideElements={[
                    <DatePickerForHeader
                        setDate={(newDate) => {
                            setDate(newDate.setZone(selectedSiteTimezone || DEFAULT_TIMEZONE)); // TODO timezones - need tio be fixed in the calendar component!
                        }}
                        selectedDate={date}
                        isSelectedDateBookable={() => true}
                        timezone={selectedSiteTimezone || DEFAULT_TIMEZONE}
                        showTimezone={false}
                    />,
                ]}
                tabs={tabs}
            />
        </main>
    );
};

export default Reception;
