import React, { useEffect, useState } from 'react';
import Calendar from 'react-datetime';
import { useTranslation } from 'react-i18next';
import Select, { OnChangeValue } from 'react-select';
import { ReactComponent as DangerIcon } from 'assets/icons/DangerIcon.svg';
import { ReactComponent as HorizontalSeparator } from 'assets/icons/HorizontalSeparator.svg';
import classNames from 'classnames';
import Button from 'components/FormElements/Button';
import InlineFields from 'components/FormElements/InlineFields';
import InputWrapper from 'components/FormElements/InputWrapper';
import Toggle from 'components/FormElements/Toggle';
import { BOOKING_SLOT_UNITS_MULTIPLIER, DEFAULT_TIMEZONE } from 'features/constants';
import { DateTime } from 'luxon';
import { Configuration } from 'services/ApiClients/Configuration';
import DateTimeService, { TimeSlotOption } from 'services/DateTimeService';
import { isNil } from 'utilities/ts';

import 'moment/locale/en-gb';

import DateTimePickerValue from '../../Models/DateTimePickerValue';

export interface DateTimeValidationResponse {
    isValidTime: boolean;
    error?: string;
}

export interface EditorProps {
    onDone(value: DateTimePickerValue): void;
    value: DateTimePickerValue;
    supportEndTime?: boolean;
    resourceTypeSiteConfiguration: Configuration;
    selectedDate: DateTime;
    timeFromDisabled?: boolean;
    isToggleDisabled?: boolean;
    isSelectedDateBookable?: (current: DateTime) => boolean;
    validationErrorMessage?: string;
    hourFormat: string;
    timezone?: string;
}

// @todo: memoization?
export const getSelectedTimeSlot = (
    timeSlots: TimeSlotOption[],
    dateTime: DateTime,
    slotInterval: number,
    isEndTimeSlot = false
): TimeSlotOption | undefined => {
    // isEndTimeSlot = true indicates the datetime represents the time slot at the end of a date range.
    const selectedTimeSlotValue = DateTimeService.getTimeSlotFromDate(dateTime, slotInterval, isEndTimeSlot);
    return timeSlots.find((timeSlot) => timeSlot.value === selectedTimeSlotValue);
};

// @todo: Currently the editor only supports intraday timeslots. In the future we may want multi-day
const Editor = ({
    value,
    onDone,
    supportEndTime = false,
    resourceTypeSiteConfiguration,
    selectedDate,
    timeFromDisabled,
    isToggleDisabled,
    isSelectedDateBookable,
    validationErrorMessage,
    hourFormat,
    timezone = DEFAULT_TIMEZONE,
}: EditorProps): JSX.Element | null => {
    const selectedDay = value.startDateTime
        ? value.startDateTime.toFormat('cccc').toLowerCase()
        : selectedDate.toFormat('cccc').toLowerCase();
    const timeInterval: number = resourceTypeSiteConfiguration.bookingSlotUnits * BOOKING_SLOT_UNITS_MULTIPLIER;
    const timeSlots: TimeSlotOption[] = DateTimeService.getTimeSlots(
        Math.floor(
            resourceTypeSiteConfiguration.bookableDays[selectedDay]?.bookingWindowStartMinutesFromMidnight /
                timeInterval
        ),
        Math.ceil(
            resourceTypeSiteConfiguration.bookableDays[selectedDay]?.bookingWindowEndMinutesFromMidnight / timeInterval
        ),
        timeInterval,
        hourFormat
    );

    if (!value?.startDateTime) {
        throw new Error('Start date time must be supplied');
    }

    const [message, setValidationErrorMessage] = useState<string | undefined>(validationErrorMessage);
    const [date, setDate] = useState<DateTime>(value.startDateTime);
    const [localValue, setLocalValue] = useState<DateTimePickerValue>(value);

    const { t } = useTranslation();

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    if (!localValue || !localValue.startDateTime || !localValue.endDateTime) {
        return null;
    }

    const selectedFromTimeSlot = getSelectedTimeSlot(timeSlots, localValue.startDateTime, timeInterval);
    const selectedToTimeSlot = getSelectedTimeSlot(timeSlots, localValue.endDateTime, timeInterval, true);

    const handleFromChange = (selected: OnChangeValue<TimeSlotOption, false>): void => {
        const timeSlot = (selected as TimeSlotOption).value;
        const newStartDate = DateTimeService.getDateFromTimeSlot(date, timeSlot, timeInterval);

        setLocalValue((v) => {
            return {
                ...DateTimeService.setDateTimePeriodFromDateTimeValue(
                    { ...v, startDateTime: newStartDate },
                    resourceTypeSiteConfiguration,
                    selectedDay
                ),
            };
        });
    };

    const handleToChange = (selected: OnChangeValue<TimeSlotOption, false>): void => {
        const timeSlot = (selected as TimeSlotOption).value;
        const newEndDate = DateTimeService.getDateFromTimeSlot(date, timeSlot, timeInterval);

        setLocalValue((v) => {
            return {
                ...DateTimeService.setDateTimePeriodFromDateTimeValue(
                    { ...v, endDateTime: newEndDate },
                    resourceTypeSiteConfiguration,
                    selectedDay
                ),
            };
        });
    };

    const handleIsAMToggle = (): any => {
        const startDateTime = DateTimeService.getTimeSlotOptionFromDateTime(
            localValue.startDateTime.setZone(timezone).set({
                hour:
                    resourceTypeSiteConfiguration.bookableDays[selectedDay].bookingWindowStartMinutesFromMidnight / 60,
                minute: 0,
            }),
            timeInterval
        );

        const endDateTime = DateTimeService.getTimeSlotOptionFromDateTime(
            localValue.startDateTime.setZone(timezone).set({ hour: 12, minute: 0 }),
            timeInterval,
            true
        );

        handleFromChange(startDateTime);
        handleToChange(endDateTime);
        setLocalValue((v) => ({ ...v, isAM: true, isPM: false, isAllDay: false }));
    };

    const handleIsPMToggle = (): any => {
        const startDateTime = DateTimeService.getTimeSlotOptionFromDateTime(
            localValue.startDateTime.setZone(timezone).set({ hour: 12, minute: 0 }),
            timeInterval
        );

        const endDateTime = DateTimeService.getTimeSlotOptionFromDateTime(
            localValue.startDateTime.setZone(timezone).set({
                hour: resourceTypeSiteConfiguration.bookableDays[selectedDay].bookingWindowEndMinutesFromMidnight / 60,
                minute: 0,
            }),
            timeInterval,
            true
        );
        handleFromChange(startDateTime);
        handleToChange(endDateTime);
        setLocalValue((v) => ({ ...v, isAM: false, isPM: true, isAllDay: false }));
    };

    const handleIsAllDayToggle = (): any => {
        const startDateTime = DateTimeService.getTimeSlotOptionFromDateTime(
            localValue.startDateTime.setZone(timezone).set({
                hour:
                    resourceTypeSiteConfiguration.bookableDays[selectedDay].bookingWindowStartMinutesFromMidnight / 60,
                minute: 0,
            }),
            timeInterval
        );

        const endDateTime = DateTimeService.getTimeSlotOptionFromDateTime(
            localValue.startDateTime.setZone(timezone).set({
                hour: resourceTypeSiteConfiguration.bookableDays[selectedDay].bookingWindowEndMinutesFromMidnight / 60,
                minute: 0,
            }),
            timeInterval,
            true
        );

        handleFromChange(startDateTime);
        handleToChange(endDateTime);
        setLocalValue((v) => ({ ...v, isAM: false, isPM: false, isAllDay: true }));
    };

    const handleDateChange = (dateValue: any): void => {
        const isoDateFormat = DateTime.fromISO(dateValue.toISOString(), { zone: timezone }).toISO();

        const newDate = DateTime.fromISO(isoDateFormat, { setZone: true });

        setDate(newDate);

        let newStartDate = newDate;
        let newEndDate = newDate;

        // If we don't have selected time slots we are unable to update the from/to date properly, but if we do we can update the time portion as well
        if (selectedToTimeSlot && selectedFromTimeSlot) {
            newStartDate = DateTimeService.getDateFromTimeSlot(newDate, selectedFromTimeSlot?.value, timeInterval);
            newEndDate = DateTimeService.getDateFromTimeSlot(newDate, selectedToTimeSlot?.value, timeInterval);
        }

        const newLocalValue = { ...localValue, startDateTime: newStartDate, endDateTime: newEndDate };
        setLocalValue(newLocalValue);
    };

    const handleDone = async (): Promise<void> => {
        if (!(localValue.endDateTime > localValue.startDateTime)) {
            setValidationErrorMessage(t('Error_StartTimeMustBeBeforeEndTime'));
        } else if (!isNil(onDone)) {
            onDone(localValue);
        }
    };

    return (
        <>
            {date?.isValid && (
                <div className="editor">
                    <Calendar
                        value={date.setZone('local', { keepLocalTime: true }).toJSDate()}
                        input={false}
                        timeFormat={false}
                        onChange={handleDateChange}
                        isValidDate={(current: any) => {
                            if (isSelectedDateBookable) {
                                return isSelectedDateBookable(DateTime.fromISO(current.toISOString()));
                            }
                            return true;
                        }}
                    />

                    <InlineFields>
                        <InputWrapper label="From" inputName="timeFrom">
                            <Select
                                id="timeFrom"
                                value={selectedFromTimeSlot}
                                onChange={handleFromChange}
                                options={timeSlots}
                                className={classNames('react-select-container', {
                                    error: !isNil(message),
                                })}
                                classNamePrefix="react-select"
                                isDisabled={timeFromDisabled}
                            />
                        </InputWrapper>
                        {supportEndTime && (
                            <>
                                <HorizontalSeparator className="separator" />
                                <InputWrapper label="To" inputName="timeTo">
                                    <Select
                                        id="timeTo"
                                        value={selectedToTimeSlot}
                                        onChange={handleToChange}
                                        options={timeSlots}
                                        className={classNames('react-select-container', {
                                            error: !isNil(message),
                                        })}
                                        classNamePrefix="react-select"
                                    />
                                </InputWrapper>
                            </>
                        )}
                    </InlineFields>
                    {!isNil(message) && (
                        <small role="alert">
                            <DangerIcon />
                            {message}
                        </small>
                    )}
                    <InlineFields>
                        <Toggle
                            label="All day"
                            inputId="isAllDay"
                            inputName="duration"
                            onChange={() => handleIsAllDayToggle()}
                            dataTestId="toggle_allDay"
                            checked={!!localValue.isAllDay}
                            value={`${localValue.isAllDay}`}
                            type="radio"
                            isDisabled={isToggleDisabled}
                        />
                        <div className="toggleLabel">OR</div>
                        <Toggle
                            label="AM"
                            inputId="isAM"
                            inputName="duration"
                            onChange={() => handleIsAMToggle()}
                            dataTestId="toggle_am"
                            checked={!!localValue.isAM}
                            value={`${localValue.isAM}`}
                            type="radio"
                            isDisabled={isToggleDisabled}
                        />
                        <Toggle
                            label="PM"
                            inputId="isPM"
                            inputName="duration"
                            onChange={() => handleIsPMToggle()}
                            dataTestId="toggle_pm"
                            checked={!!localValue.isPM}
                            value={`${localValue.isPM}`}
                            type="radio"
                            isDisabled={isToggleDisabled}
                        />
                    </InlineFields>
                </div>
            )}

            <Button
                type="button"
                className="completeAction"
                dataTestId="done-button"
                onClick={handleDone}
                text={t('Button_Done')}
            />
        </>
    );
};

export default Editor;
