import { filterResources } from 'components/BookingGrid/Mappers/ResourceMapper';
import * as constants from 'features/constants';
import { ConfigurationSettings } from 'features/Resources/Common/Redux/Reducer/configuratioReducer';
import { filterAdvancedFiltersByResourceType, formatAvailableResources } from 'features/Resources/Common/utils';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import { createSelector } from 'reselect';
import { CombinedConfiguration } from 'services/ApiClients/Configuration';
import { CombinedResourceTypes, ResourceType } from 'services/ApiClients/Resource';
import ConfigurationService from 'services/ConfigurationService';
// eslint-disable-next-line import/no-cycle
import { mapBookingsToFloorPins } from 'utilities/floorplanUtils';

import { Access } from '../../../../enums/authorization';
import { GetAllowedResourceTypes } from '../../../../utilities/authorizationUtils';

enum ToggleBtnFiltersValue {
    toggleDeskBtnValue = 0,
    toggleRoomBtnValue = 1,
}

export const getCombinedSiteConfiguration = createSelector(
    (state: RootState) => state.commonResources,
    (state: RootState) => state.filters.combined.toggleFilters,
    (commonResources, toggleFilters) => {
        if (!commonResources.configuration.siteSettings) {
            return null;
        }

        let typesToUse = toggleFilters;
        if (!typesToUse.length) {
            typesToUse = [ResourceType.DESK.value, ResourceType.ROOM.value];
        }

        const configurations = typesToUse.map(
            (x) => (commonResources.configuration.siteSettings as ConfigurationSettings)[x]
        );

        const combinedConfiguration = ConfigurationService.combine(configurations);
        return combinedConfiguration;
    }
);

export const getAllSiteConfiguration = createSelector(
    (state: RootState) => state.commonResources,
    (state: RootState) => state.filters.combined.toggleFilters,
    (commonResources, toggleFilters) => {
        if (!commonResources.configuration.siteSettings) {
            return null;
        }

        let typesToUse = toggleFilters;
        if (!typesToUse.length) {
            typesToUse = [ResourceType.DESK.value, ResourceType.ROOM.value];
        }

        const configurations = typesToUse.map(
            (x) => (commonResources.configuration.siteSettings as ConfigurationSettings)[x]
        );

        return configurations;
    }
);

const getSignalRData = createSelector(
    (state: RootState) => state.filters,
    (state: RootState) => state.referenceData,
    getCombinedSiteConfiguration,
    (filters, referenceData, configuration) => {
        const siteId = filters.global.site?.id.toString() as string;
        const availableAreas = referenceData.area.items[siteId] || [];
        const { toggleFilters } = filters.combined;

        const availableResources = formatAvailableResources(
            filters.combined.area,
            referenceData.combinedResource,
            availableAreas,
            toggleFilters
        );

        return {
            availableAreas,
            availableResources,
            configuration: configuration as CombinedConfiguration,
        };
    }
);

export const getProviderData = createSelector(
    (state: RootState) => state.combined,
    (state: RootState) => state.filters,
    (state: RootState) => state.referenceData,
    (state: RootState) => state.commonResources,
    (state: RootState) => state.unavailability,
    (state: RootState) => state.authentication.user?.permissions,
    getSignalRData,
    (combined, filters, referenceData, commonResources, unavailability, permissions, signalRData) => {
        const selectedSite = filters.global.site;
        const timezone = (selectedSite && selectedSite?.timezone) || constants.DEFAULT_TIMEZONE;
        const accessLevel = permissions?.Access || 0;

        return {
            configurationLoadStatus: commonResources.configuration.siteSettingsLoadStatus,
            site: selectedSite,
            areas: referenceData.area.items,
            resources: referenceData.combinedResource.items,
            areasLoadStatus: referenceData.area.loadStatus,
            resourcesLoadStatus: referenceData.combinedResource.loadStatus,
            bookingsLoadStatus: combined.bookings.loadStatus,
            selectedDate: filters.combined.selectedDate || DateTime.utc(),
            timezone,
            resourceTypes: GetAllowedResourceTypes(accessLevel, referenceData.tenantInfo, selectedSite),
            unavailability: {
                loadStatus: unavailability.loadStatus,
                items: unavailability.items,
            },
            ...signalRData,
        };
    }
);

const getCommonData = createSelector(
    (state: RootState) => state.filters,
    (filters) => {
        const { advancedFilters, toggleFilters, area } = filters.combined;
        const filteredEquipments = filterAdvancedFiltersByResourceType(
            advancedFilters.equipment,
            filters.combined.toggleFilters
        );
        const siteId = filters.global.site?.id.toString() as string;
        const timezone = (filters.global.site && filters.global.site.timezone) || constants.DEFAULT_TIMEZONE;
        const selectedDate =
            (filters.combined.selectedDate && filters.combined.selectedDate.setZone(timezone)) ||
            DateTime.utc().setZone(timezone);

        return {
            siteId,
            timezone,
            selectedDate,
            advancedFilters: {
                ...advancedFilters,
                equipment: filteredEquipments,
            },
            toggleFilters,
            area,
            selectedSite: filters.global.site,
        };
    }
);

export const getHeaderData = createSelector(
    (state: RootState) => state.referenceData.area.items,
    (state: RootState) => state.referenceData.tenantInfo,
    (state: RootState) => state.filters,
    (state: RootState) => state.authentication.user?.permissions,
    (state: RootState) => state.floorPlan,
    (state: RootState) => state.commonResources.configuration.siteSettings,
    getCommonData,
    (areas, tenantInfo, filters, permissions, floorPlanData, siteSettings, commonData) => {
        const { siteId, toggleFilters, advancedFilters, timezone, area } = commonData;
        const selectedSite = filters.global.site;

        const availableAreas =
            filters.combined.viewType === constants.VIEW_TYPE.GRID
                ? areas[siteId]
                : areas[siteId].filter((item) => !!item.floorPlanImage);

        const accessLevel = permissions?.Access || 0;

        const areDesksAvailable =
            tenantInfo?.areDesksAvailable &&
            selectedSite?.areDesksAvailable &&
            accessLevel &&
            Access.DeskBooking &&
            siteSettings &&
            !isEmpty(siteSettings[ResourceType.DESK.value].bookableDays);
        const areRoomsAvailable =
            tenantInfo?.areRoomsAvailable &&
            selectedSite?.areRoomsAvailable &&
            accessLevel &&
            Access.RoomBooking &&
            siteSettings &&
            !isEmpty(siteSettings[ResourceType.ROOM.value].bookableDays);

        const formattedToggleFilters = Object.keys(CombinedResourceTypes).map((key) => ({
            ...CombinedResourceTypes[key],
            isChecked: toggleFilters.includes(CombinedResourceTypes[key].value),
        }));

        const displayingToggleFilters = formattedToggleFilters.filter((toggleFilter) => {
            const { toggleDeskBtnValue, toggleRoomBtnValue } = ToggleBtnFiltersValue;

            if (!areDesksAvailable) {
                return toggleFilter.value !== toggleDeskBtnValue;
            }
            if (!areRoomsAvailable) {
                return toggleFilter.value !== toggleRoomBtnValue;
            }
            if (!areRoomsAvailable && !areDesksAvailable) {
                return [];
            }
            return formattedToggleFilters;
        });

        return {
            areas: availableAreas,
            areaListItems: availableAreas.map(({ id, name, displayOrder }) => ({
                value: id.toString(),
                label: name,
                displayOrder,
            })),
            area,
            advancedFilters,
            timezone,
            selectedDate: filters.combined.selectedDate || DateTime.utc(),
            title: 'BookingGridHeader_Bookings',
            toggleFilters: displayingToggleFilters,
            floorPlanData,
            floorPlanTimelineFilter: filters.combined.floorPlanTimelineFilter,
        };
    }
);

export const getGridData = createSelector(
    (state: RootState) => state.combined,
    (state: RootState) => state.referenceData,
    (state: RootState) => state.authentication.user,
    getCombinedSiteConfiguration,
    (state: RootState) => state.commonResources.configuration.siteSettings,
    (state: RootState) => state.commonResources.configuration.tenantSettings,
    (state: RootState) => state.unavailability.items,
    getCommonData,
    (combined, referenceData, user, siteConfiguration, siteSettings, tenantSettings, unavailableTimes, commonData) => {
        const { siteId, selectedDate, toggleFilters, advancedFilters, area, selectedSite } = commonData;
        const availableAreas = referenceData.area.items[siteId] || [];
        const accessLevel = user?.permissions?.Access || 0;

        const availableResources = formatAvailableResources(
            area,
            referenceData.combinedResource,
            availableAreas,
            toggleFilters
        );

        return {
            isLoading: combined.bookings.loadStatus === constants.LOAD_STATUSES.LOADING,
            operationInProgress: combined.bookings.operationInProgress,
            booking: combined.bookings.items,
            resourceTypeSiteConfiguration: siteConfiguration as CombinedConfiguration,
            allSiteConfigurations: siteSettings as ConfigurationSettings,
            allTenantConfigurations: tenantSettings as ConfigurationSettings,
            selectedSite,
            resources: availableResources,
            advancedFilters,
            selectedDate,
            availableAreas,
            resourceTypes: GetAllowedResourceTypes(accessLevel, referenceData.tenantInfo, selectedSite),
            unavailableTimes,
            is24HourFormat: user?.is_24h_timeFormat,
            hourFormat: user?.is_24h_timeFormat ? constants.TWENTY_FOUR_HOUR_FORMAT : constants.GRID_TWELWE_HOUR_FORMAT,
            infoSliderData: {
                resourceImage: combined.edit.info.resourceImage.imageUrl,
                infoDataLoading:
                    combined.edit.info.resourceImageLoading ||
                    combined.edit.info.usersLoading ||
                    combined.edit.info.rolesLoading ||
                    combined.edit.info.companyAttributesLoading,
                users: combined.edit.info.users,
                roles: combined.edit.info.roles,
                companyAttributes: combined.edit.info.companyAttributes,
                userProfileId: user?.userProfileId,
                cbRoles: user?.['cb-roles'],
                cbCompanyAttributes: user?.companyAttributes,
            },
        };
    }
);

export const getFloorPlanData = createSelector(
    (state: RootState) => state.referenceData,
    (state: RootState) => state.combined.bookings,
    (state: RootState) => state.authentication.user,
    (state: RootState) => state.commonResources.configuration.siteSettings,
    (state: RootState) => state.commonResources.configuration.tenantSettings,
    (state: RootState) => state.unavailability.items,
    (state: RootState) => state.filters,
    (state: RootState) => state.floorPlan,
    getCommonData,
    getCombinedSiteConfiguration,
    getAllSiteConfiguration,
    (
        referenceData,
        bookings,
        user,
        siteSettings,
        tenantSettings,
        unavailabilityTimes,
        filters,
        floorPlanData,
        commonData,
        combinedSiteConfiguration,
        allSiteConfiguration
    ) => {
        const { selectedDate, selectedSite, timezone, toggleFilters, area, advancedFilters } = commonData;
        const date = selectedDate.toUTC() || DateTime.utc();
        const resources =
            referenceData.combinedResource.items[area?.id.toString() as string]?.filter(({ resourceType }) =>
                toggleFilters.includes(resourceType)
            ) || [];

        const accessLevel = user?.permissions?.Access || 0;

        return {
            isLoading: bookings.loadStatus === constants.LOAD_STATUSES.LOADING,
            area,
            selectedSite,
            floorPins: mapBookingsToFloorPins(filterResources(resources, advancedFilters), bookings.items, date),
            user,
            unavailabilityTimes,
            timezone,
            siteConfigurations: siteSettings,
            tenantConfiguration: tenantSettings,
            gridDate: date,
            operationInProgress: bookings.operationInProgress,
            selectedDate,
            resourceTypes: GetAllowedResourceTypes(accessLevel, referenceData.tenantInfo, selectedSite),
            allResourceTypeConfigurations: allSiteConfiguration,
            configurationSiteSettings: combinedSiteConfiguration,
            hourFormat: user?.extendedHourFormat || constants.TWENTY_FOUR_HOUR_FORMAT,
            floorPlanTimelineFilter: filters.combined.floorPlanTimelineFilter,
            searchResultItem: floorPlanData.searchResultItem,
        };
    }
);
