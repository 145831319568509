import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

import { onInitialise, onToggleNavigation } from './Redux/actions';
import SiteNavigation from './SiteNavigation';
import { StateProps } from './types';

const mapStateToProps = (state: RootState): StateProps => ({
    isOpen: state.navigation.isOpen,
    initialised: state.navigation.initialised,
    access: state.authentication.user?.permissions?.Access || 0,
    tenantInfo: state.referenceData.tenantInfo,
    selectedSite: state.filters.global.site,
    permissions: state.authentication.user?.permissions,
});

const mapDispatchToProps = {
    toggle: onToggleNavigation,
    initialise: onInitialise,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteNavigation);
