import React, { useEffect, useState } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { ApplicationPaths, QueryParameterNames } from 'features/Authentication/constants';
import authService from 'features/Authentication/Services/AuthorizeService';
import Filters from 'features/Filters/Components/Provider';
import DefaultSiteSelector from 'features/GeographicStructures/Components/DefaultSiteSelector';
import ReferenceData from 'features/ReferenceData/Components/Provider';

// eslint-disable-next-line import/no-cycle
import { AuthorizeRouteProps } from '.';

const AuthorizeRoute = (props: AuthorizeRouteProps & RouteProps): JSX.Element => {
    const { children, path, element, setUser, accessTypes = null, turnedOn = true, ...rest } = props;
    const WrappedComponent = element as React.ElementType;

    const [isReady, setIsReady] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [access, setAccess] = useState<number>(0);

    const populateAuthenticationState = async (): Promise<void> => {
        const authenticated = await authService.checkIsAuthenticated();
        const userProfile = await authService.getUser();

        if (userProfile) {
            const cbPermissions = (userProfile as any)['cb-permissions']?.replace(/'/g, '"');
            userProfile.permissions = cbPermissions && JSON.parse(cbPermissions);

            const cbCompanyAttributes = (userProfile as any)['cb-company-attributes']?.replace(/'/g, '"');
            userProfile.companyAttributes = cbCompanyAttributes && JSON.parse(cbCompanyAttributes).CompanyAttributes;

            setAccess(userProfile.permissions?.Access || 0);
        }

        setUser(userProfile);
        setIsAuthenticated(authenticated);
        setIsReady(true);
    };

    const authenticationChanged = async (): Promise<void> => {
        setIsReady(false);
        setIsAuthenticated(false);
        await populateAuthenticationState();
    };

    useEffect(() => {
        const subscription = authService.subscribe(() => authenticationChanged());
        populateAuthenticationState();

        return () => {
            authService.unsubscribe(subscription);
        };
    });

    const pathLink: any = document.createElement('a');
    pathLink.href = path || '';

    const returnUrl = `${pathLink.protocol}//${pathLink.host}${pathLink.pathname}${pathLink.search}${pathLink.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;

    const isAuthorized = !accessTypes || accessTypes.some((a) => a & access);

    if (!isReady) {
        return <div />;
    }

    if (isAuthenticated && (!isAuthorized || !turnedOn)) {
        return <Navigate to="/" replace />;
    }

    if (isAuthenticated) {
        return (
            <Filters>
                <ReferenceData>
                    <DefaultSiteSelector>
                        <WrappedComponent>{children}</WrappedComponent>
                    </DefaultSiteSelector>
                </ReferenceData>
            </Filters>
        );
    }

    return <Navigate to={redirectUrl} replace />;
};

export default AuthorizeRoute;
