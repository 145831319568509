import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditButton, createPrimaryButton } from 'components/ActionButtons/Models/ActionButton';
import Dialog from 'components/Dialog';
import DateTimePickerValue from 'components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import PromptDialog from 'components/PromptDialog/PromptDialog';
import { BOOKING_STATUS, EditMode, INITIAL_BOOKING_DATA_STATE, LOAD_STATUSES } from 'features/constants';
import { getIsShowCheckInOutBookingButtons } from 'features/Resources/Common/EditBooking/BookingInfo/utils';
import Editor from 'features/Resources/Common/EditBooking/Editor';
import { BookingInfoDataState } from 'features/Resources/Common/EditBooking/Editor/Models/BookingInfoDataState';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { debounce } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { SearchBookedResourcesQuery } from 'services/ApiClients/Booking';
import { renderConfirmationDialog } from 'utilities/renderConfirmationDialog';
import { isNil } from 'utilities/ts';

import { getOnBehalfOfDetails } from '../SingleBooking/utils';

import { repeatButtons, repeatTabs } from './utils';
// eslint-disable-next-line import/no-cycle
import { RepeatContainerProps } from '.';

import './RepeatContainer.scss';

const RepeatContainer = ({
    operationInProgress,
    dismissError,
    hasUpdateError,
    areaName,
    now,
    isCurrentUserBooking,
    bookingInfo,
    timezone,
    isRepeatsEnabled,
    handleClose,
    abandonSingleOccurrence,
    canEditNotOwnBooking,
    isUpdatePending,
    currentExclusion,
    checkIn,
    checkOut,
    updateSingleOccurrence,
    deleteRepeatSeries,
    cancelSingleOccurrence,
    confirmSingleOccurrence,
    updateWholeSeries,
    confirmBooking,
    abandonBooking,
    getParentBooking,
    resetNotSavedBookingInfo,
    getBookingsByResourcesIds,
    resetBookingsByResourcesIds,
    bookingsByResource,
    areaId,
    selectedDate,
    bookingsByResourceLoadStatus,
}: RepeatContainerProps): JSX.Element => {
    const [editMode, setEditMode] = useState<EditMode>(EditMode.DISABLED_OCCURRENCE);
    const [isDialogOpen, setOpen] = useState<boolean>(false);
    const [showPromptDialog, setShowPromptDialog] = useState<boolean>(false);
    const [bookingInfoDataState, setBookingInfoDataState] = useState<BookingInfoDataState>(INITIAL_BOOKING_DATA_STATE);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showPromptDialog);
    const { t } = useTranslation();
    const {
        resourceType,
        bookMode,
        bookingId,
        resourceId,
        bookingStatus,
        dateTime,
        checkInStatus,
        createdByUserId,
        createdByDisplayName,
        repeatSchedule,
        repeatBookingId,
        onBehalfOfPerson,
    } = bookingInfo;

    const earlyCheckedInBookingStartTime = useMemo(
        () => dateTime.startDateTime.minus({ minutes: 30 }),
        [dateTime.startDateTime]
    );
    const isEarlyCheckedInBooking = useMemo(
        () => Interval.fromDateTimes(earlyCheckedInBookingStartTime, dateTime.startDateTime).contains(now),
        [now, dateTime.startDateTime, earlyCheckedInBookingStartTime]
    );
    const isBookingInProgress = useMemo(
        () =>
            Interval.fromDateTimes(
                dateTime.startDateTime,
                dateTime.endDateTime.plus({ seconds: bookingInfo?.cleardownTimeInSeconds || 0 })
            ).contains(now),
        [now, dateTime]
    );

    useEffect(() => {
        const isPrompt = isUpdatePending || bookingStatus === BOOKING_STATUS.UPDATE_PENDING;
        setShowPromptDialog(isPrompt);
    }, [isUpdatePending, bookingStatus]);

    useLayoutEffect(() => {
        setBookingInfoDataState(INITIAL_BOOKING_DATA_STATE);
        return () => {
            resetNotSavedBookingInfo();
        };
    }, []);

    useEffect(() => {
        if (editMode === EditMode.ENABLE_SERIES && !isNil(repeatBookingId)) {
            getParentBooking({ bookingId: repeatBookingId });
        }
    }, [editMode]);

    useEffect(() => {
        const getBookingsByResourcesIdsQuery: SearchBookedResourcesQuery = {
            geographicStructureIds: [areaId],
            fromDateTime: selectedDate.startOf('day').toUTC().toISO(),
            toDateTime: selectedDate.endOf('day').toUTC().toISO(),
            resourcesIds: [resourceId],
        };

        if (resourceId && areaId && (isEarlyCheckedInBooking || isBookingInProgress)) {
            getBookingsByResourcesIds(getBookingsByResourcesIdsQuery);
        }
        return () => {
            resetBookingsByResourcesIds();
        };
    }, [resourceId, areaId]);

    const handleDateTimeChange = (value: DateTimePickerValue): void => {
        updateSingleOccurrence({
            occurrenceData: {
                bookingId,
                originalResourceId: resourceId,
                isConfirmed: false,
                createdByUserId,
                createdByDisplayName,
                originalOccurrenceTimeRange: {
                    start: DateTime.fromISO(dateTime.startDateTime).toUTC().toISO(),
                    end: DateTime.fromISO(dateTime.endDateTime).toUTC().toISO(),
                },
                updatedOccurrenceTimeRange: {
                    start: value.startDateTime.toUTC().toISO(),
                    end: value.endDateTime.toUTC().toISO(),
                },
                timezone,
            },
            isUpdatingDateAndTime: true,
        });
    };

    const handleCheckIn = (): void => {
        checkIn({
            urlParams: {
                bookingId,
                resourceId,
            },
            payload: {
                startDateTime: DateTime.fromISO(dateTime.startDateTime).toUTC().toISO(),
                endDateTime: DateTime.fromISO(dateTime.endDateTime).toUTC().toISO(),
            },
        });
    };

    const handleCheckOut = (): void => {
        checkOut({
            urlParams: {
                bookingId,
                resourceId,
            },
            payload: {
                startDateTime: DateTime.fromISO(dateTime.startDateTime).toUTC().toISO(),
                endDateTime: DateTime.fromISO(dateTime.endDateTime).toUTC().toISO(),
            },
        });
    };

    const handleExitWithoutSaving = async (shouldExit?: boolean, newMode?: EditMode): Promise<void> => {
        setShowPromptDialog(false);
        const isWholeSeriesMode = editMode === EditMode.ENABLE_SERIES;
        const confirm = await renderConfirmationDialog(
            isWholeSeriesMode ? t('Booking_LeavePage') : t('Booking_Occurrence_LeavePage'),
            t('Booking_LeavePage_Description')
        );

        if (confirm) {
            if (isWholeSeriesMode) {
                abandonBooking({ abandonData: { bookingId } });
            } else {
                abandonSingleOccurrence({
                    abandonData: {
                        bookingId,
                        startTime: DateTime.fromISO(dateTime.startDateTime).toUTC().toISO(),
                        endTime: DateTime.fromISO(dateTime.endDateTime).toUTC().toISO(),
                    },
                    shouldExit,
                });

                if (newMode) {
                    setEditMode(newMode);
                }
            }
        }
    };

    const handleExitWithPromptDialog = (): void => {
        const isWholeSeriesMode = editMode === EditMode.ENABLE_SERIES;

        confirmNavigation();
        if (isWholeSeriesMode) {
            abandonBooking({ abandonData: { bookingId }, navigateTo: true });
        } else {
            abandonSingleOccurrence({
                abandonData: {
                    bookingId,
                    startTime: DateTime.fromISO(dateTime.startDateTime).toUTC().toISO(),
                    endTime: DateTime.fromISO(dateTime.endDateTime).toUTC().toISO(),
                },
                navigateTo: true,
            });
        }
    };

    const handleCancelSingleOccurrence = async (): Promise<void> => {
        setShowPromptDialog(false);
        const confirm = await renderConfirmationDialog(t('Repeat_Cancel_Message'));
        if (confirm) {
            cancelSingleOccurrence({
                bookingId: currentExclusion?.bookingId || bookingId,
                resourceId,
                startTime: dateTime.startDateTime.toUTC(),
                endTime: dateTime.endDateTime.toUTC(),
            });
        }
    };

    const handleEdit = (): void => {
        setOpen(true);
    };

    const debounceSetBookingInfoDataState = debounce((bookingInfoData) => {
        setBookingInfoDataState(bookingInfoData);
    }, 400);

    const handleSetBookingInfoDataState = useCallback(
        (bookingInfoData: BookingInfoDataState, debounceTrigger = false): void => {
            if (debounceTrigger) {
                debounceSetBookingInfoDataState(bookingInfoData);
            } else {
                setBookingInfoDataState(bookingInfoData);
            }
        },
        []
    );

    const tabs = useMemo(
        () =>
            repeatTabs(
                bookingInfo,
                resourceType,
                editMode,
                isRepeatsEnabled,
                handleDateTimeChange,
                handleSetBookingInfoDataState,
                bookingInfoDataState
            ),
        [bookingInfo.dateTime, editMode, operationInProgress, bookingInfoDataState]
    );

    const handleSubmit = (values: any): void => {
        setShowPromptDialog(false);
        if (editMode === EditMode.ENABLE_OCCURRENCE) {
            if (isUpdatePending || bookingStatus === BOOKING_STATUS.UPDATE_PENDING) {
                confirmSingleOccurrence({
                    bookingId: currentExclusion?.bookingId || bookingId,
                    displayName: values.displayName,
                    notes: values.notes,
                    attendeesCount: values.attendeesCount,
                    startDateTime: DateTime.fromISO(dateTime.startDateTime).setZone(timezone).toUTC().toISO(),
                    endDateTime: DateTime.fromISO(dateTime.endDateTime).setZone(timezone).toUTC().toISO(),
                });
            } else if (isNil(values.attendeesCount) && isNil(values.notes) && isNil(values.displayName)) {
                handleClose();
            } else {
                updateSingleOccurrence({
                    occurrenceData: {
                        bookingId,
                        originalResourceId: resourceId,
                        isConfirmed: true,
                        createdByUserId,
                        createdByDisplayName,
                        notes: values.notes,
                        displayName: values.displayName,
                        timezone,
                        attendeesCount: values.attendeesCount,
                        originalOccurrenceTimeRange: {
                            start: DateTime.fromISO(dateTime.startDateTime).setZone(timezone).toUTC().toISO(),
                            end: DateTime.fromISO(dateTime.endDateTime).setZone(timezone).toUTC().toISO(),
                        },
                        updatedOccurrenceTimeRange:
                            !isNil(repeatBookingId) ||
                            !isNil(values.notes) ||
                            !isNil(values.displayName) ||
                            !isNil(values.attendeesCount)
                                ? undefined
                                : {
                                      start: DateTime.fromISO(dateTime.startDateTime).setZone(timezone).toUTC().toISO(),
                                      end: DateTime.fromISO(dateTime.endDateTime).setZone(timezone).toUTC().toISO(),
                                  },
                    },
                    isUpdatingDateAndTime: false,
                });
            }
        } else {
            const onBehalfOfDetails = getOnBehalfOfDetails(bookingInfoDataState.selectedPerson, onBehalfOfPerson);

            if (!isNil(values.repeatSchedule)) {
                confirmBooking({
                    bookingData: {
                        bookingId,
                        attendeesCount: values.attendeesCount,
                        displayName: values.displayName,
                        notes: values.notes,
                        onBehalfOfDetails,
                        isPrivate: values.isPrivate,
                        bookingStatus: bookMode,
                    },
                });
                return;
            }

            if (
                isNil(values.attendeesCount) &&
                isNil(values.notes) &&
                isNil(values.displayName) &&
                isNil(onBehalfOfDetails) &&
                isNil(values.isPrivate)
            ) {
                handleClose();
            } else {
                updateWholeSeries({
                    bookingData: {
                        bookingId,
                        displayName: values.displayName,
                        notes: values.notes,
                        attendeesCount: values.attendeesCount,
                        onBehalfOfDetails,
                        isPrivate: values.isPrivate,
                    },
                });
            }
        }
    };

    const handleDeleteSeries = async (): Promise<void> => {
        setShowPromptDialog(false);
        const confirm = await renderConfirmationDialog(t('Repeat_Delete_Message'));
        if (confirm) {
            deleteRepeatSeries({ bookingId });
        }
    };

    const isShowCheckInOutButtons = useMemo(
        () =>
            getIsShowCheckInOutBookingButtons(
                isEarlyCheckedInBooking,
                isBookingInProgress,
                bookingsByResource,
                now,
                timezone,
                bookingId,
                dateTime.startDateTime
            ),
        [bookingsByResource, bookingInfo, now]
    );

    const buttons = useMemo(() => {
        if (bookingsByResourceLoadStatus === LOAD_STATUSES.LOADING) {
            return [];
        }
        return repeatButtons(
            bookMode,
            bookingStatus,
            now,
            dateTime.startDateTime,
            dateTime.endDateTime,
            operationInProgress,
            checkInStatus,
            canEditNotOwnBooking,
            isCurrentUserBooking,
            isEarlyCheckedInBooking,
            editMode,
            repeatSchedule,
            repeatBookingId,
            isUpdatePending || bookingStatus === BOOKING_STATUS.UPDATE_PENDING,
            handleClose,
            handleExitWithoutSaving,
            handleCheckIn,
            handleCheckOut,
            handleEdit,
            handleDeleteSeries,
            handleCancelSingleOccurrence,
            isShowCheckInOutButtons
        );
    }, [operationInProgress, checkInStatus, bookMode, dateTime, editMode, bookingsByResourceLoadStatus]);

    const editDialogButtons = useMemo(() => {
        return [
            createEditButton('singleOccurence', t('Repeat_Edit_Single_Occurence_Message'), () => {
                setEditMode(EditMode.ENABLE_OCCURRENCE);
                setOpen(false);
            }),
            createPrimaryButton('whole_series', t('Repeat_Whole_Series'), () => {
                setEditMode(EditMode.ENABLE_SERIES);
                setOpen(false);
            }),
        ];
    }, []);

    return (
        <>
            {isDialogOpen && (
                <Dialog
                    message={t('Repeat_Edit_Dialog_Title')}
                    description={t('Repeat_Edit_Dialog_Message')}
                    buttons={editDialogButtons}
                    buttonsClassName="edit-repeat-buttons"
                    showCloseIcon
                    closeDialog={() => setOpen(false)}
                />
            )}
            {showPrompt && (
                <PromptDialog
                    message={t('Booking_LeavePage')}
                    descriptionMessage={t('Booking_LeavePage_Description')}
                    showDialog={showPrompt}
                    confirmNavigation={handleExitWithPromptDialog}
                    cancelNavigation={cancelNavigation}
                />
            )}
            <Editor
                operationInProgress={operationInProgress}
                tabs={tabs}
                actionButtons={buttons}
                dismissError={dismissError}
                hasUpdateError={hasUpdateError}
                areaName={areaName}
                bookingInfo={bookingInfo}
                onSubmit={handleSubmit}
                editMode={editMode}
                handleEditModeChanged={() => {
                    if (isUpdatePending || bookingStatus === BOOKING_STATUS.UPDATE_PENDING) {
                        handleExitWithoutSaving(false, EditMode.ENABLE_SERIES);
                    } else {
                        setEditMode(EditMode.ENABLE_SERIES);
                    }
                }}
            />
        </>
    );
};

export default RepeatContainer;
