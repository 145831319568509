import { connect } from 'react-redux';
import DateTimePickerValue from 'components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import { EditMode } from 'features/constants';
import BookingInfo from 'features/Resources/Common/EditBooking/BookingInfo';
import { BookingInfoDataState } from 'features/Resources/Common/EditBooking/Editor/Models/BookingInfoDataState';
import { getCommonInfo } from 'features/Resources/Common/EditBooking/Redux/selectors';
import { RootState } from 'PortalTypes';
import { UpdateCommand } from 'services/ApiClients/Booking';

import { Visitor } from '../../../../../../services/ApiClients/VisitorManagement/Models/Visitor';
import { SearchData } from '../../../../../../utilities/searchUtils';
import {
    createVisitor,
    editVisitor,
    resetSearchOboPersonsResult,
    searchExternalOboPersons,
    searchInternalOboPersons,
    updateRepeatCombinedBookingAsync,
} from '../../../../Common/EditBooking/Redux/actions/info';

interface PassedProps {
    bookingInfo: any;
    updateDateAndTime?: (value: DateTimePickerValue) => void;
    disabled?: boolean;
    editMode?: EditMode;
    bookingInfoDataState?: BookingInfoDataState;
    handleSetBookingInfoDataState?: (bookingInfoData: BookingInfoDataState) => void;
}

const mapStateToProps = (state: RootState, ownProps: PassedProps): any => ({
    ...getCommonInfo(state),
    ...ownProps,
});

const mapDispatchToProps = {
    searchInternalOboPersons: (searchPersonsRequest: SearchData) =>
        searchInternalOboPersons.request(searchPersonsRequest),
    searchExternalOboPersons: (searchPersonsRequest: SearchData) =>
        searchExternalOboPersons.request(searchPersonsRequest),
    resetSearchOboPersonsResult,
    updateRepeatBooking: (bookingRequest: UpdateCommand) => updateRepeatCombinedBookingAsync.request(bookingRequest),
    createVisitor: (visitor: Visitor) => createVisitor.request(visitor),
    editVisitor: (visitor: Visitor) => editVisitor.request(visitor),
};

export type BookingInfoProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(BookingInfo);
