import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components';
import { LOAD_STATUSES } from 'features/constants';
import { SearchResponse } from 'services/ApiClients/Search/Models/SearchResponse';

import FloorPlanSearchResultsItem from './FloorPlanSearchResultsItem';

import './FloorPlanSearchResults.scss';

export interface SearchResultsProps {
    searchResults: SearchResponse[] | null;
    searchValue: string;
    searchResultLoadStatus: string;
    extendedHourFormat: string;
    selectedSiteTimezone: string;
    onClose: () => void;
    handleShowSearchedItem: (siteId: string) => void;
    setSearchResultItem: (item: SearchResponse) => void;
    setSearchTrigger: (item: boolean) => void;
}

const FloorPlanSearchResults = ({
    searchResults,
    searchValue,
    searchResultLoadStatus,
    extendedHourFormat,
    selectedSiteTimezone,
    onClose,
    handleShowSearchedItem,
    setSearchResultItem,
    setSearchTrigger,
}: SearchResultsProps): JSX.Element => {
    const { t } = useTranslation();

    const isTooManyResults = searchResults && searchResults.length > 100;
    const searchResultCount = !searchResults || !searchValue || isTooManyResults ? 0 : searchResults.length;
    const isNoResultsFoundForText = Array.isArray(searchResults) && !searchResults.length && searchValue !== '';
    const isSearchToLocateText = !searchResults || !searchValue;
    const isSearchResults = searchResults?.length && searchValue !== '';

    return (
        <div className="search-results-container">
            <p className="result">
                <span className="count">{searchResultCount}</span> <span>{t('FloorPlan_Search_Results')}</span>
            </p>
            {searchResultLoadStatus === LOAD_STATUSES.LOADING ? (
                <Loading />
            ) : (
                <div className="data">
                    {isTooManyResults && !isSearchToLocateText && (
                        <p className="no-data-text">{t('FloorPlan_Search_TooManyResults')}</p>
                    )}
                    {isSearchToLocateText && <p className="no-data-text">{t('FloorPlan_Search_SearchToLocate')}</p>}
                    {isNoResultsFoundForText && (
                        <p className="no-data-text">
                            <span>{t('FloorPlan_Search_NoResults')}</span>{' '}
                            <span className="no-results">{searchValue}</span>
                        </p>
                    )}
                    {isSearchResults && !isTooManyResults
                        ? searchResults.map((result) => (
                              <FloorPlanSearchResultsItem
                                  extendedHourFormat={extendedHourFormat}
                                  selectedSiteTimezone={selectedSiteTimezone}
                                  key={result.id}
                                  result={result}
                                  onClose={onClose}
                                  handleShowSearchedItem={handleShowSearchedItem}
                                  setSearchResultItem={setSearchResultItem}
                                  setSearchTrigger={setSearchTrigger}
                              />
                          ))
                        : null}
                </div>
            )}
        </div>
    );
};

export default FloorPlanSearchResults;
