import { connect } from 'react-redux';
import { LOAD_STATUSES } from 'features/constants';
import { getGeographicStructureAllSites } from 'features/GeographicStructures/Redux/actions';
import { orderBy } from 'lodash';
import { RootState } from 'PortalTypes';
import { UpdateCompanyAttributesCommand, UpdateUserProfileCommand } from 'services/ApiClients/Identity/Models';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { mapListItems } from 'utilities/selectList';

import {
    getMyAccountUserProfileAsync,
    getTenantCompanyAttributesAsync,
    myAccountUserProfileReset,
    updateMyAccountUserProfileAsync,
    updateUserProfileCompanyAttributesAsync,
} from './Redux/actions';
// eslint-disable-next-line import/no-cycle
import Account from './Account';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    requiresLoading: state.account.loadStatus === LOAD_STATUSES.REQUIRED,
    isLoading: state.account.loadStatus === LOAD_STATUSES.LOADING,
    operationInProgress: state.account.operationInProgress,
    userProfile: state.account.userProfile,
    availableCompanyAttributes: state.account.availableCompanyAttributes,
    assignedCompanyAttributes: state.combined.edit.info.companyAttributes,
    sites: orderBy(
        mapListItems(
            state.referenceData.sites.items,
            (item: GeographicStructureItem) => item.id.toString(),
            (item: GeographicStructureItem) => item.name
        ),
        ['label', 'asc']
    ),
    allowEditCompanyAttributes: state.referenceData.tenantInfo?.areUsersAllowedToEditTheirAttributes,
});

const mapDispatchToProps = {
    loadReset: () => myAccountUserProfileReset(),
    loadUserProfile: () => getMyAccountUserProfileAsync.request(),
    loadTenantCompanyAttributes: () => getTenantCompanyAttributesAsync.request(),
    getSites: getGeographicStructureAllSites.request,
    handleUpdate: (updateRequest: UpdateUserProfileCommand) => updateMyAccountUserProfileAsync.request(updateRequest),
    handleCompanyAttributesUpdate: (updateRequest: UpdateCompanyAttributesCommand) =>
        updateUserProfileCompanyAttributesAsync.request(updateRequest),
};

export type AccountProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Account);
