import React from 'react';
import { useTranslation } from 'react-i18next';
import { TWENTY_FOUR_HOUR_FORMAT } from 'features/constants';
import { DateTime } from 'luxon';

import { DateTimePicker } from '../../../../../../components/FormElements';
import DateTimePickerValue from '../../../../../../components/FormElements/DateTimePicker/Models/DateTimePickerValue';

import './DateTimeHeader.scss';

export interface DateTimeHeaderProps {
    timezone?: string;
    selectedDate: DateTimePickerValue;
    onDateTimeChange(value: any): void;
}

const DateTimeHeader = ({ timezone, selectedDate, onDateTimeChange }: DateTimeHeaderProps): JSX.Element => {
    const { t } = useTranslation();

    const defaultBookingWindow = {
        bookingWindowStartMinutesFromMidnight: 0,
        bookingWindowEndMinutesFromMidnight: 1440,
    };

    return (
        <div className="date-time-header">
            <DateTimePicker
                className="date-time"
                name="dateTime"
                dateTimeRangeValue={selectedDate}
                supportEndTime
                dataTestId="dateTime-dateTimePicker"
                onChange={onDateTimeChange}
                selectedDate={DateTime.now()}
                title={t('Resource_DateAndTime_Label')}
                required={false}
                resourceTypeSiteConfiguration={{
                    bookableDays: {
                        monday: defaultBookingWindow,
                        tuesday: defaultBookingWindow,
                        wednesday: defaultBookingWindow,
                        thursday: defaultBookingWindow,
                        friday: defaultBookingWindow,
                        saturday: defaultBookingWindow,
                        sunday: defaultBookingWindow,
                    },
                    bookingSlotUnits: 3,
                    reservationWindowInSeconds: 5,
                }}
                hourFormat={TWENTY_FOUR_HOUR_FORMAT}
            />

            <div className="timezone">{timezone}</div>
        </div>
    );
};

export default DateTimeHeader;
