import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import classnames from 'classnames';
import { CountryCode } from 'services/ApiClients/Models';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import './MobileInput.scss';

export interface ListItem {
    label: string;
    value: string;
}

export interface MobileInputProps extends InputWrapperProps {
    validation?: any;
    error?: string;
    control: any;
    initialValue: string | CountryCode;
    items: ListItem[];
    isDisabled?: boolean;
    id?: string;
    maxLength?: number;
    dataTestId?: string;
    register: any;
    codeName: string;
    numberName: string;
    initialInputValue?: string;
    horizontal?: boolean;
}

export default (props: MobileInputProps): JSX.Element | null => {
    const {
        label,
        className,
        hideLabel,
        validation,
        items,
        initialValue,
        isDisabled = false,
        error,
        control,
        id,
        dataTestId,
        maxLength,
        register,
        codeName,
        numberName,
        initialInputValue = '',
        horizontal = false,
    } = props;

    if (!items.length) {
        return null;
    }

    const [hasValue, setHasValue] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(initialInputValue);

    const formatOptionLabel = useCallback((e: any, { context }: any): any => {
        if (context === 'value') {
            return e.value;
        }
        if (context === 'menu') {
            return e.label;
        }
        return label;
    }, []);

    const defaultValue = items.find((item) => item.value === initialValue) || null;
    return (
        <InputWrapper
            label={label}
            name={numberName}
            className={classnames(className)}
            hideLabel={hideLabel}
            required={validation?.required}
            error={error}
            horizontal={horizontal}
        >
            <div className="mobile-input">
                <Controller
                    control={control}
                    name={codeName}
                    defaultValue={defaultValue}
                    render={({ onChange: onChangeEvent, name: subName }) => (
                        <Select
                            name={subName}
                            className={classnames('select-container', [
                                {
                                    'select-container--has-value': !!initialValue,
                                    'select-container--disabled': isDisabled,
                                },
                            ])}
                            onChange={(event) => {
                                onChangeEvent(event);
                            }}
                            classNamePrefix="select"
                            options={items}
                            defaultValue={defaultValue}
                            isDisabled={isDisabled}
                            id={id}
                            formatOptionLabel={formatOptionLabel}
                        />
                    )}
                />
                <input
                    ref={register(validation)}
                    name={numberName}
                    className={classnames({ hasValue, error })}
                    disabled={isDisabled}
                    onChange={(e) => {
                        setHasValue(!!e.currentTarget.value);
                        setInputValue(e.target.value);
                    }}
                    maxLength={maxLength}
                    data-testid={dataTestId}
                    value={inputValue}
                />
            </div>
        </InputWrapper>
    );
};
