import React from 'react';
import { ReactComponent as BookingInfoIcon } from 'assets/icons/BookingInfoTabIcon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { ReactComponent as DeskInfoTabIcon } from 'assets/icons/DeskInfoTabIcon.svg';
import { ReactComponent as BookingHistoryIcon } from 'assets/icons/HistoryInfoTabIcon.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/RepeatsInfoTabIcon.svg';
import { ReactComponent as RoomInfoTabIcon } from 'assets/icons/RoomInfoTabIcon.svg';
import ActionButton, {
    createDangerButton,
    createPrimaryButton,
    createSecondaryButton,
    createSuccessButton,
} from 'components/ActionButtons/Models/ActionButton';
import DateTimePickerValue from 'components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import { PersonType } from 'enums/PersonType';
import * as constants from 'features/constants';
import { CHECK_IN_STATUS } from 'features/constants';
import { BookingInfoDataState } from 'features/Resources/Common/EditBooking/Editor/Models/BookingInfoDataState';
import History from 'features/Resources/Common/EditBooking/History';
import ResourceInfo from 'features/Resources/Common/EditBooking/ResourceInfo';
import BookMode from 'features/Resources/Common/Models/BookMode';
import i18n from 'i18n';
import { DateTime, Interval } from 'luxon';
import { findResourceNameByValue, ResourceType } from 'services/ApiClients/Resource';

import BookingInfo from './Components/BookingInfo';
import RepeatInfo from './Components/RepeatInfo';

export const singleBookingTabs = (
    bookingInfo: any,
    resourceType: number,
    isRepeatsEnabled: boolean,
    handleDateTimeChange: (value: DateTimePickerValue) => void,
    handleSetBookingInfoDataState: (bookingInfoData: BookingInfoDataState) => void,
    bookingInfoDataState: BookingInfoDataState,
    isRepeatBookingsEnabled: boolean
): any =>
    [
        {
            id: 'bookingInfo',
            icon: BookingInfoIcon,
            title: i18n.t('Combined_BookingInfo_TabTitle'),
            dataTestId: 'bookingInfo-tab',
            content: (
                <BookingInfo
                    updateDateAndTime={handleDateTimeChange}
                    bookingInfo={bookingInfo}
                    handleSetBookingInfoDataState={handleSetBookingInfoDataState}
                    bookingInfoDataState={bookingInfoDataState}
                />
            ),
        },
        {
            id: 'combinedInfo',
            icon: resourceType === ResourceType.DESK.value ? DeskInfoTabIcon : RoomInfoTabIcon,
            title: i18n.t('Combined_Info_TabTitle', {
                type: findResourceNameByValue(resourceType),
            }),
            dataTestId: 'combinedInfo-tab',
            content: <ResourceInfo />,
        },
        isRepeatsEnabled && isRepeatBookingsEnabled
            ? { id: 'repeat', icon: RepeatIcon, title: 'Repeats', dataTestId: 'repeat-tab', content: <RepeatInfo /> }
            : null,
        {
            id: 'history',
            icon: BookingHistoryIcon,
            title: i18n.t('Combined_History_TabTitle'),
            dataTestId: 'history-tab',
            content: <History />,
        },
    ].filter((tab) => tab !== null);

export const singleBookingButtons = (
    bookMode: BookMode,
    bookingStatus: string,
    now: DateTime,
    startTime: DateTime,
    endTime: DateTime,
    operationInProgress: boolean,
    isCurrentUserBooking: boolean,
    isOnBehalfOfUser: boolean,
    canAddOnBehalfOfUser: boolean,
    canEditNotOwnBooking: boolean,
    canEditPastBooking: boolean,
    checkInStatus: string | undefined,
    isEarlyCheckedInBooking: boolean,
    handleCancel: () => void,
    handleCancelPendingBooking: () => void,
    handleCheckIn: () => void,
    handleCheckOut: () => void,
    handleClose: () => void,
    handleExitWithoutSaving: () => void,
    isShowCheckInOutButtons: boolean
): ActionButton[] => {
    const buttons: ActionButton[] = [];
    const isCheckedInStatus = checkInStatus === CHECK_IN_STATUS.CHECKED_IN;
    const isCheckedOutStatus = checkInStatus === CHECK_IN_STATUS.CHECKED_OUT;
    const checkInIcon = isCheckedInStatus ? CheckIcon : undefined;

    const checkInButton = createSuccessButton(
        'checkIn',
        isCheckedInStatus ? i18n.t('Button_CheckedIn') : i18n.t('Button_CheckIn'),
        handleCheckIn,
        operationInProgress,
        checkInIcon,
        undefined,
        isCheckedInStatus || isCheckedOutStatus
    );
    const checkOutButton = createDangerButton(
        'checkOut',
        i18n.t('Button_CheckOut'),
        handleCheckOut,
        operationInProgress,
        undefined,
        undefined,
        isCheckedOutStatus
    );

    const cancelButton = createDangerButton(
        'cancel',
        i18n.t('Button_CancelBooking'),
        handleCancel,
        operationInProgress
    );

    const closeButton = createSecondaryButton(
        'close',
        i18n.t('Button_Close'),
        bookMode === BookMode.UpdatePending ? handleExitWithoutSaving : handleClose
    );

    const cancelPendingButton = createSecondaryButton(
        'cancel-pending',
        i18n.t('Button_Cancel'),
        handleCancelPendingBooking
    );

    const bookButton = createPrimaryButton(
        bookMode === BookMode.Create ? 'book' : 'save',
        bookMode === BookMode.Create ? i18n.t('Button_Book') : i18n.t('Button_Save'),
        () => {},
        operationInProgress
    );

    const canAddCancelBtn =
        (isCurrentUserBooking || canEditNotOwnBooking) &&
        ((!isCheckedInStatus && isEarlyCheckedInBooking) || (!isEarlyCheckedInBooking && now < startTime));

    if (bookMode === BookMode.Edit) {
        const canAddActionBtn =
            (isCurrentUserBooking || canEditNotOwnBooking) &&
            (isEarlyCheckedInBooking || Interval.fromDateTimes(startTime, endTime).contains(now));
        if (canAddActionBtn && isShowCheckInOutButtons) {
            buttons.push(checkInButton);
            buttons.push(checkOutButton);
        }
    }

    if (canAddCancelBtn) {
        buttons.push(cancelButton);
    }

    if (
        bookingStatus === constants.BOOKING_STATUS.PENDING &&
        now < endTime &&
        (isCurrentUserBooking || canEditNotOwnBooking)
    ) {
        buttons.push(cancelPendingButton);
    } else {
        buttons.push(closeButton);
    }

    const isCanEditOboUserInOwnBooking =
        canAddOnBehalfOfUser && !canEditNotOwnBooking && (isCurrentUserBooking || isOnBehalfOfUser);
    const isCanEditOboUserForAllBookings = canAddOnBehalfOfUser && canEditNotOwnBooking;

    if (
        ((bookingStatus !== 'curtailed' && now < endTime) || canEditPastBooking) &&
        (isCurrentUserBooking || canEditNotOwnBooking || isCanEditOboUserInOwnBooking || isCanEditOboUserForAllBookings)
    ) {
        buttons.push(bookButton);
    }

    return buttons;
};

export const getOnBehalfOfDetails = (onBehalfOfPerson: any, currentOnBehalfOfPerson: any): any => {
    let onBehalfOfDetails = {};

    if (onBehalfOfPerson) {
        let personId = null;
        let visitorId = null;

        if (
            (onBehalfOfPerson?.id?.toString() || onBehalfOfPerson?.personId?.toString()) ===
            currentOnBehalfOfPerson?.personId?.toString()
        ) {
            return undefined;
        }

        if (onBehalfOfPerson.id) {
            if (onBehalfOfPerson.visitorType === PersonType[PersonType.External]) {
                visitorId = onBehalfOfPerson?.id;
            } else {
                personId = onBehalfOfPerson?.id;
            }
        } else {
            visitorId = onBehalfOfPerson?.visitorId;
            personId = onBehalfOfPerson?.personId;
        }

        onBehalfOfDetails = {
            onBehalfOf: {
                personId,
                visitorId,
                displayName: `${onBehalfOfPerson.firstname} ${onBehalfOfPerson.surname}`,
            },
        };
    } else {
        return onBehalfOfPerson === undefined || currentOnBehalfOfPerson === null
            ? undefined
            : { onBehalfOfDetails: { onBehalfOf: null } };
    }

    return onBehalfOfDetails;
};
