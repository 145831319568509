import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { CompanySearchPanel } from '../../../components/CompanySearchPanel';
import { Select } from '../../../components/Forms';
import { GeographicStructureItem } from '../../../services/ApiClients/OrganisationStructure';
import { AllListItemOption } from '../../constants';
import { getSiteGroupListItems, getSiteListItems } from '../utils';

interface VisitorBookingsFilterFormProps {
    reportsFilter: any;
    setReportsFilter: Function;
    searchCompaniesResult: string[] | null;
    searchCompanies: Function;
    isSearchCompaniesInProgress: boolean;
    resetCompaniesSearch: Function;
    siteGroups: GeographicStructureItem[];
    sites: GeographicStructureItem[];
}

const VisitorBookingsFilterForm = ({
    reportsFilter,
    setReportsFilter,
    searchCompanies,
    searchCompaniesResult,
    isSearchCompaniesInProgress,
    resetCompaniesSearch,
    siteGroups,
    sites,
}: VisitorBookingsFilterFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [visitorSearchData, setVisitorSearchData] = useState<any>({});
    const [companySearchData, setCompanySearchData] = useState<any>({});

    const siteGroupListItems = useMemo(() => getSiteGroupListItems(siteGroups, sites), [siteGroups]);

    const siteListItems = useMemo(
        () => getSiteListItems(sites, reportsFilter.selectedSiteGroup),
        [sites, reportsFilter.selectedSiteGroup]
    );

    useEffect(() => {
        setReportsFilter({ ...reportsFilter, selectedSite: AllListItemOption });
    }, [reportsFilter.selectedSiteGroup]);

    useEffect(() => {
        setReportsFilter({
            selectedSiteGroup: AllListItemOption,
            selectedSite: AllListItemOption,
            dateRange: { from: DateTime.now(), to: DateTime.now() },
            companyName: AllListItemOption.label,
        });
    }, []);

    return (
        <>
            <div className="site-groups-n-sites">
                {siteGroups && (
                    <Select
                        label={t('Reports_SiteGroups')}
                        name="siteGroup"
                        dataTestId="site-group"
                        items={siteGroupListItems}
                        className="site-select"
                        onChange={(selectedSiteGroup) => {
                            setReportsFilter({ ...reportsFilter, selectedSiteGroup });
                        }}
                        value={reportsFilter.selectedSiteGroup}
                    />
                )}

                <Select
                    label={t('Reports_Sites')}
                    name="site"
                    dataTestId="site"
                    items={siteListItems}
                    className="defaultSite"
                    onChange={(selectedSite) => {
                        setReportsFilter({ ...reportsFilter, selectedSite });
                    }}
                    value={reportsFilter.selectedSite}
                />
            </div>
            <CompanySearchPanel
                dataTestId="company"
                buttonLabel={t('Reports_Company')}
                panelLabel={t('Reports_SearchCompanies')}
                resultPlaceHolder={t('Reports_SearchForCompanies')}
                onSelect={(companyName: string) => {
                    setReportsFilter({
                        ...reportsFilter,
                        companyName: companyName !== null ? companyName : AllListItemOption.label,
                    });
                    setVisitorSearchData({ ...visitorSearchData, company: companyName });
                }}
                onSearch={(searchData: any) => searchCompanies(searchData.name)}
                onSearchDataChange={(searchValue: object) => {
                    setCompanySearchData({ ...companySearchData, ...searchValue });
                }}
                searchResult={searchCompaniesResult}
                selectedItem={reportsFilter.companyName !== AllListItemOption.label ? reportsFilter.companyName : ''}
                iaSearchInProgress={isSearchCompaniesInProgress}
                resetSearchResult={resetCompaniesSearch}
                searchData={companySearchData}
                searchFieldPlaceholder={t('Reports_Company')}
            />
        </>
    );
};

export default VisitorBookingsFilterForm;
