import { LANGUAGE, LOAD_STATUSES, VIEW_TYPE } from 'features/constants';
import { setReferenceDataAreaLoadStatus } from 'features/ReferenceData/Redux/actions';
import { setResourcesLoadStatus } from 'features/Resources/Combined/Redux/actions';
import { RootEpic } from 'PortalTypes';
import { push } from 'redux-first-history';
import { from, merge, of } from 'rxjs';
import { catchError, concatMap, filter, switchMap } from 'rxjs/operators';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { ResourceType } from 'services/ApiClients/Resource';
import { isActionOf } from 'typesafe-actions';
import { formatMultipleFilters } from 'utilities/filterUtils';
import Guid from 'utilities/guid';

import * as actions from './actions';

export const setFiltersEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.setFilters)),
        switchMap((action) => {
            const siteId = action.payload?.toString() || state$.value.authentication.user?.siteId;
            const tenantId = state$.value.authentication.user?.tenantId;
            let { cachedSiteImage } = state$.value.filters.global || {};
            // if no default site set
            if (!siteId) {
                return of(actions.setFiltersComplete());
            }

            return from(api.organisationStructure.getGeographicStructureSiteById(new Guid(siteId))).pipe(
                concatMap((response) => {
                    return from(api.organisationStructure.getGeographicStructureSiteImageById(new Guid(siteId))).pipe(
                        concatMap((siteImageResponse) => {
                            cachedSiteImage = {
                                ...cachedSiteImage,
                                [siteId]: siteImageResponse?.payload,
                            };
                            const site = {
                                ...response.payload,
                                siteImage: siteImageResponse?.payload || '',
                            } as GeographicStructureItem;

                            const equipments = formatMultipleFilters(site.equipmentList?.equipment, [
                                ResourceType.DESK.name,
                                ResourceType.ROOM.name,
                            ]);

                            if (site.parentId === tenantId) {
                                return of(
                                    actions.setCachedSiteImage(cachedSiteImage),
                                    actions.setSite(site),
                                    actions.setCombinedEquipments(equipments),
                                    actions.setSiteGroup(null),
                                    actions.setLanguage(LANGUAGE.DEFAULT),
                                    actions.setFiltersComplete()
                                );
                            }

                            const siteGroupId = site.parentId as Guid;
                            return from(api.organisationStructure.getGeographicStructureById(siteGroupId)).pipe(
                                concatMap((siteGroupResponse) => {
                                    const siteGroup = siteGroupResponse.payload as GeographicStructureItem;

                                    return of(
                                        actions.setCachedSiteImage(cachedSiteImage),
                                        actions.setSite(site),
                                        actions.setCombinedEquipments(equipments),
                                        actions.setSiteGroup(siteGroup),
                                        actions.setLanguage(LANGUAGE.DEFAULT),
                                        actions.setFiltersComplete()
                                    );
                                })
                            );
                        })
                    );
                }),
                catchError(() => of(actions.setFiltersComplete()))
            );
        })
    );

export const updateSelectedSiteFilterEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.updateSite)),
        switchMap((action) => {
            const siteId = action.payload?.id.toString() || state$.value.authentication.user?.siteId;
            let { cachedSiteImage } = state$.value.filters.global || {};
            let site;

            if (!siteId) {
                return of(actions.setFiltersComplete());
            }

            const siteParentId = action.payload?.parentId as Guid;
            const siteGroups = state$.value.referenceData.siteGroup.items;
            const areas = state$.value.referenceData.area.items;
            const tenantId = state$.value.authentication.user?.tenantId as string;

            const siteGroup =
                (siteGroups[tenantId] && siteGroups[tenantId].find((item) => item.id === siteParentId)) || null;

            const areaObservables = of(actions.setArea(null));
            const loadStatusesObservables = of(
                setReferenceDataAreaLoadStatus(LOAD_STATUSES.REQUIRED),
                setResourcesLoadStatus(LOAD_STATUSES.REQUIRED)
            );
            const viewTypeObservables = of(
                actions.setCombinedViewType(
                    areas[siteId]?.filter((item) => !!item.floorPlanImage)?.length
                        ? VIEW_TYPE.FLOOR_PLAN
                        : VIEW_TYPE.GRID
                )
            );
            const commonObservables = of(
                push(action.payload?.isFloorPlanSearch ? { pathname: '/bookings' } : { pathname: '/' })
            );

            return from(api.organisationStructure.getGeographicStructureSiteById(new Guid(siteId))).pipe(
                concatMap((response) => {
                    if (cachedSiteImage[siteId as keyof typeof cachedSiteImage]) {
                        site = {
                            ...response.payload,
                            siteImage: cachedSiteImage[siteId as keyof typeof cachedSiteImage],
                        } as GeographicStructureItem;

                        const equipments = formatMultipleFilters(site?.equipmentList?.equipment, [
                            ResourceType.DESK.name,
                            ResourceType.ROOM.name,
                        ]);

                        const filtersObservables = of(
                            actions.setCombinedDisabledAccess(false),
                            actions.setCombinedEquipments(equipments),
                            actions.setExpandedResources([])
                        );

                        const siteObservables = of(
                            actions.setSite(site),
                            actions.setSiteGroup(siteGroup),
                            actions.setFiltersComplete()
                        );

                        return merge(
                            siteObservables,
                            areaObservables,
                            loadStatusesObservables,
                            viewTypeObservables,
                            filtersObservables,
                            commonObservables
                        );
                    }

                    return from(api.organisationStructure.getGeographicStructureSiteImageById(new Guid(siteId))).pipe(
                        concatMap((siteImageResponse) => {
                            cachedSiteImage = {
                                ...cachedSiteImage,
                                [siteId]: siteImageResponse?.payload,
                            };

                            site = {
                                ...response.payload,
                                siteImage: siteImageResponse?.payload || '',
                            } as GeographicStructureItem;

                            const equipments = formatMultipleFilters(site?.equipmentList?.equipment, [
                                ResourceType.DESK.name,
                                ResourceType.ROOM.name,
                            ]);

                            const filtersObservables = of(
                                actions.setCombinedDisabledAccess(false),
                                actions.setCombinedEquipments(equipments),
                                actions.setExpandedResources([])
                            );

                            const siteObservables = of(
                                actions.setCachedSiteImage(cachedSiteImage),
                                actions.setSite(site),
                                actions.setSiteGroup(siteGroup),
                                actions.setFiltersComplete()
                            );

                            return merge(
                                siteObservables,
                                areaObservables,
                                loadStatusesObservables,
                                viewTypeObservables,
                                filtersObservables,
                                commonObservables
                            );
                        })
                    );
                }),
                catchError(() => of(actions.setFiltersComplete()))
            );
        })
    );
