import * as constants from 'features/constants';
import { mapValues } from 'lodash';
import { combineReducers } from 'redux';
import { Configuration, ConfigurationResponse } from 'services/ApiClients/Configuration';
import ConfigurationService from 'services/ConfigurationService';
import { createReducer } from 'typesafe-actions';

import {
    getSiteConfigurationAsync,
    getTenantConfigurationAsync,
    setSiteConfigurationLoadStatus,
    setTenantConfigurationLoadStatus,
} from '../actions';

export type ConfigurationSettings = { [index: number]: Configuration };

export interface ConfigurationState {
    siteSettingsLoadStatus: string;
    siteSettings: ConfigurationSettings | null;
    tenantSettingsLoadStatus: string;
    tenantSettings: ConfigurationSettings | null;
}

export default combineReducers<ConfigurationState>({
    siteSettingsLoadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setSiteConfigurationLoadStatus], (state, action) => action.payload)
        .handleAction([getSiteConfigurationAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction(
            [getSiteConfigurationAsync.success, getSiteConfigurationAsync.failure],
            () => constants.LOAD_STATUSES.LOADED
        ),

    siteSettings: createReducer<ConfigurationSettings | null>(null).handleAction(
        [getSiteConfigurationAsync.success],
        (state, action) => {
            const configurationsData = mapValues(action.payload, (x) => x.payload as ConfigurationResponse);
            return mapValues(configurationsData, ConfigurationService.mapFromResponse);
        }
    ),

    tenantSettingsLoadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction([setTenantConfigurationLoadStatus], (state, action) => action.payload)
        .handleAction([getTenantConfigurationAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction(
            [getTenantConfigurationAsync.success, getTenantConfigurationAsync.failure],
            () => constants.LOAD_STATUSES.LOADED
        ),

    tenantSettings: createReducer<ConfigurationSettings | null>(null).handleAction(
        [getTenantConfigurationAsync.success],
        (state, action) => {
            const configurationsData = mapValues(action.payload, (x) => x.payload as ConfigurationResponse);
            return mapValues(configurationsData, ConfigurationService.mapFromResponse);
        }
    ),
});
