import React from 'react';
import classNames from 'classnames';

import { ErrorText, RequiredLabel } from './Components';

import './InputWrapper.scss';

export interface InputWrapperProps {
    label?: string;
    name: string;
    className?: string;
    hideLabel?: boolean;
    required?: string | boolean;
    error?: string;
    horizontal?: boolean;
}

const InputWrapper = ({
    label = '',
    name,
    className,
    hideLabel,
    required = false,
    error,
    children,
    horizontal = false,
}: React.PropsWithChildren<InputWrapperProps>): JSX.Element => {
    return (
        <div className={classNames('inputWrapper', className, { horizontal, error: !!error })}>
            {label && (
                <label className={classNames('label', { error, hidden: hideLabel })} htmlFor={name}>
                    <RequiredLabel required={!!required} />
                    {label}
                </label>
            )}
            {children}
            {error ? <ErrorText text={error} /> : null}
        </div>
    );
};

export default InputWrapper;
