import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import deskImage from 'assets/desk-default-img.png';
import roomImage from 'assets/room-default-img.png';
import Button from 'components/FormElements/Button';
import NewSlidingPanel from 'components/NewSlidingPanel';
import { ProfileCompanyAttributes } from 'features/Authentication/types';
import { LOAD_STATUSES } from 'features/constants';
// eslint-disable-next-line import/no-cycle
import { ResourceInfoSliderState } from 'features/Resources/Common/InfoSlider/InfoSlider';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { SearchBookedResourcesQuery } from 'services/ApiClients/Booking/Models';
import { UserRole } from 'services/ApiClients/Identity/Models';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import {
    SpecifiedCompanyAttributesQuery,
    SpecifiedCompanyAttributesResponse,
} from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { PersonById } from 'services/ApiClients/Person/Models/PersonById';
import { ResourceType } from 'services/ApiClients/Resource';
import { FLOORPIN_STATUSES } from 'utilities/floorplanUtils';
import Guid from 'utilities/guid';

import PageHeader from '../PageHeader/index';

// eslint-disable-next-line import/no-cycle
import ResourceInfoContent from './ResourceInfoContent';

export interface InfoSliderData {
    users: PersonById[];
    roles: UserRole[];
    userProfileId: string | undefined;
    cbRoles: string[] | undefined;
    resourceImage: string;
    infoDataLoading: boolean;
    companyAttributes: SpecifiedCompanyAttributesResponse[];
    cbCompanyAttributes: ProfileCompanyAttributes[] | undefined;
}

interface ResourceInfoSliderProps {
    isOpen: boolean;
    isLoading: boolean;
    geoStructureName: string;
    title: string;
    onClose: () => void;
    resource: ResourceInfoSliderState;
    getResourceImage: (imageId: Guid) => void;
    resetResourceImage: (data: object) => object;
    infoSliderData: InfoSliderData;
    getUsers: (usersIds: string[]) => void;
    getRoles: (usersIds: string[]) => void;
    getAttributes: (param: SpecifiedCompanyAttributesQuery) => void;
    resetAttributes: (param: []) => void;
    resetUsers: (param: []) => void;
    resetRoles: (param: []) => void;
    isFromFloorPlanPage?: boolean;
    selectedDate?: DateTime;
    timezone?: string;
    getBookingsByResourcesIds?: (params: SearchBookedResourcesQuery) => void;
    resetBookingsByResourcesIds?: (param: []) => void;
    area?: GeographicStructureItem;
    bookingsByResourcesIdsLoadStatus?: string;
    handleCreateBooking?: () => void;
    createdBy?: string;
    attendeesCount?: number;
    OBO?: string;
    tenantCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
}

const ResourceInfoSlider = ({
    isOpen,
    isLoading,
    resource,
    geoStructureName,
    title,
    onClose,
    getResourceImage,
    resetResourceImage,
    infoSliderData,
    getUsers,
    getRoles,
    getAttributes,
    resetUsers,
    resetRoles,
    resetAttributes,
    isFromFloorPlanPage,
    selectedDate,
    timezone,
    getBookingsByResourcesIds,
    resetBookingsByResourcesIds,
    area,
    bookingsByResourcesIdsLoadStatus,
    handleCreateBooking,
    createdBy,
    attendeesCount,
    OBO,
    tenantCompanyAttributes,
}: ResourceInfoSliderProps): JSX.Element => {
    const {
        users,
        roles,
        userProfileId,
        cbRoles,
        resourceImage,
        infoDataLoading,
        companyAttributes,
        cbCompanyAttributes,
    } = infoSliderData;
    const { t } = useTranslation();

    useEffect(() => {
        if (resource?.resourceImageId) {
            getResourceImage(resource.resourceImageId);
        }

        if (!isEmpty(resource?.restrictedTo?.users)) {
            getUsers(resource?.restrictedTo?.users);
        }

        if (!isEmpty(resource?.restrictedTo?.roles)) {
            getRoles(resource?.restrictedTo?.roles);
        }

        if (!isEmpty(resource?.restrictedTo?.companyAttributes)) {
            const payload = resource.restrictedTo.companyAttributes.map(({ id: attributeId, values: valueIds }) => ({
                attributeId,
                valueIds,
            }));

            getAttributes({ attributes: payload });
        }

        if (isFromFloorPlanPage && resource && getBookingsByResourcesIds && selectedDate && area) {
            const getBookingsByResourcesIdsQuery: SearchBookedResourcesQuery = {
                geographicStructureIds: [area.id],
                fromDateTime: selectedDate.setZone(timezone).startOf('day').toUTC().toISO(),
                toDateTime: selectedDate.setZone(timezone).endOf('day').toUTC().toISO(),
                resourcesIds: [resource.id],
            };

            getBookingsByResourcesIds(getBookingsByResourcesIdsQuery);
        }

        return () => {
            resetResourceImage({});
            resetUsers([]);
            resetRoles([]);
            resetAttributes([]);
            if (isFromFloorPlanPage && resetBookingsByResourcesIds) {
                resetBookingsByResourcesIds([]);
            }
        };
    }, [resource]);

    const getImgByResourceType = useMemo(
        () =>
            resource?.resourceType === ResourceType.DESK.value
                ? resourceImage || deskImage
                : resourceImage || roomImage,
        [resourceImage, resource]
    );

    const getResourceNameByResourceType =
        resource?.resourceType === ResourceType.DESK.value ? ResourceType.DESK.name : ResourceType.ROOM.name;

    const bookButtonName = useMemo(() => {
        if (
            resource?.floorPinStatus === FLOORPIN_STATUSES.RESTRICTED ||
            resource?.floorPinStatus === FLOORPIN_STATUSES.NOT_WORKING_TIME ||
            resource?.floorPinStatus === FLOORPIN_STATUSES.UNAVAILABLE ||
            resource?.floorPinStatus === FLOORPIN_STATUSES.UNAVAILABLE_COVID ||
            resource?.floorPinStatus === FLOORPIN_STATUSES.BOOKED_IN_THE_PAST ||
            resource?.floorPinStatus === FLOORPIN_STATUSES.BOOKED
        ) {
            return t('Button_Close');
        }
        if (
            resource?.floorPinStatus === FLOORPIN_STATUSES.MY_BOOKING ||
            resource?.floorPinStatus === FLOORPIN_STATUSES.ON_BEHALF_OF_ME ||
            resource?.floorPinStatus === FLOORPIN_STATUSES.ON_BEHALF_OF_INTERNAL ||
            resource?.floorPinStatus === FLOORPIN_STATUSES.IS_PRIVATE
        ) {
            return t('Booking_Edit_Resource_Button', {
                resourceType: getResourceNameByResourceType,
            });
        }
        return t('Booking_Book_Resource_Button', {
            resourceType: getResourceNameByResourceType,
        });
    }, [resource]);

    const isPageLoading =
        !resource || isLoading || infoDataLoading || bookingsByResourcesIdsLoadStatus === LOAD_STATUSES.LOADING;
    const dataTestId = `resource-info-panel-${isFromFloorPlanPage ? 'floor-plan' : 'grid'}`;

    const resourceInfoBookActionButton = isFromFloorPlanPage ? (
        <Button text={bookButtonName} className="book-btn" dataTestId="book-btn" onClick={handleCreateBooking} />
    ) : null;

    return (
        <NewSlidingPanel
            topActions
            bottomActions={resourceInfoBookActionButton}
            showBackgroundMask
            open={isOpen}
            onClose={onClose}
            dataTestId={dataTestId}
            isLoading={isPageLoading}
        >
            <>
                <PageHeader
                    title={resource?.name}
                    isFromFloorPlanPage={isFromFloorPlanPage}
                    floorPinStatus={resource?.floorPinStatus}
                    resourceType={resource?.resourceType}
                    pinSize={resource?.pinSize}
                    createdBy={createdBy}
                    OBO={OBO}
                    isFromSliderView
                />
                <ResourceInfoContent
                    resource={{ ...resource, imageUrl: getImgByResourceType }}
                    title={title}
                    users={users}
                    userProfileId={userProfileId}
                    roles={roles}
                    companyAttributes={companyAttributes}
                    cbRoles={cbRoles}
                    cbCompanyAttributes={cbCompanyAttributes}
                    isFromSliderView
                    isFromFloorPlanPage={isFromFloorPlanPage}
                    label={geoStructureName}
                    attendeesCount={attendeesCount}
                    tenantCompanyAttributes={tenantCompanyAttributes}
                />
            </>
        </NewSlidingPanel>
    );
};

export default memo(ResourceInfoSlider);
