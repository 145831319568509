import { AxiosResponse } from 'axios';
import download from 'downloadjs';
import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { FileResponse } from '../../../services/ApiClients/Models';
import ToastService from '../../../services/ToastService';

import {
    cancelMultiDaySiteVisit,
    cancelSiteVisit,
    checkInSiteVisit,
    checkOutSiteVisit,
    editExternalVisitorFromReception,
    getEvacuationReports,
    getSiteVisits,
} from './actions';

export const getSiteVisitsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getSiteVisits.request)),
        switchMap((action) =>
            from(api.visitorManagement.getSiteVisits(action.payload)).pipe(
                map((payload) => getSiteVisits.success(payload)),
                catchError((error) => of(getSiteVisits.failure(error)))
            )
        )
    );

export const checkInSiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(checkInSiteVisit.request)),
        switchMap((action) =>
            from(api.visitorManagement.checkInSiteVisit(action.payload.siteVisitId)).pipe(
                mergeMap(() => {
                    ToastService.Success({ message: 'Toast_VisitorCheckedIn' });
                    return [checkInSiteVisit.success(), getSiteVisits.request(action.payload.getSiteVisitsRequestData)];
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(checkInSiteVisit.failure(error));
                })
            )
        )
    );

export const checkOutSiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(checkOutSiteVisit.request)),
        switchMap((action) =>
            from(
                api.visitorManagement.checkOutSiteVisit(
                    action.payload.siteVisitId,
                    action.payload.cancelFutureSiteVisits
                )
            ).pipe(
                mergeMap(() => {
                    if (action.payload.cancelFutureSiteVisits) {
                        ToastService.Success({
                            message: 'Toast_VisitorCheckedOutMultiDay',
                        });
                    } else {
                        ToastService.Success({ message: 'Toast_VisitorCheckedOut' });
                    }
                    return [
                        checkOutSiteVisit.success(),
                        getSiteVisits.request(action.payload.getSiteVisitsRequestData),
                    ];
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(checkOutSiteVisit.failure(error));
                })
            )
        )
    );

export const cancelSiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(cancelSiteVisit.request)),
        switchMap((action) =>
            from(api.visitorManagement.cancelSiteVisit(action.payload.siteVisitId)).pipe(
                mergeMap(() => {
                    ToastService.Success({ message: 'Toast_VisitorCanceled' });
                    return [cancelSiteVisit.success(), getSiteVisits.request(action.payload.getSiteVisitsRequestData)];
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(cancelSiteVisit.failure(error));
                })
            )
        )
    );

export const cancelMultiDaySiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(cancelMultiDaySiteVisit.request)),
        switchMap((action) =>
            from(api.visitorManagement.cancelMultiDayVisit(action.payload.multiDaySiteVisitId)).pipe(
                mergeMap(() => {
                    ToastService.Success({ message: 'Toast_MultiDayVisitCanceled' });
                    return [
                        cancelMultiDaySiteVisit.success(),
                        getSiteVisits.request(action.payload.getSiteVisitsRequestData),
                    ];
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(cancelMultiDaySiteVisit.failure(error));
                })
            )
        )
    );

export const getEvacuationReportsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getEvacuationReports.request)),
        switchMap((action) =>
            from(api.visitorManagement.getEvacuationReports(action.payload)).pipe(
                map((response) => {
                    const fileData = response.payload as FileResponse;
                    const blob = new Blob([atob(fileData.content)]);
                    download(blob, fileData.fileName, 'text/csv');

                    return getEvacuationReports.success();
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(getEvacuationReports.failure(error));
                })
            )
        )
    );

export const editExternalVisitorEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(editExternalVisitorFromReception.request)),
        switchMap((action) =>
            from(api.visitorManagement.editExternalVisitor(action.payload.visitor)).pipe(
                mergeMap(() => {
                    ToastService.Success({ message: 'Toast_VisitorUpdated' });

                    return [
                        editExternalVisitorFromReception.success(),
                        getSiteVisits.request(action.payload.getSiteVisitsRequestData),
                    ];
                }),
                catchError((error) => {
                    const { status } = error.response as AxiosResponse;
                    if (status === 409) {
                        ToastService.Error({
                            message: 'Toast_VisitorErrorEmailExists',
                        });
                    } else {
                        ToastService.Error({ message: 'Toast_UnexpectedError' });
                    }
                    return of(editExternalVisitorFromReception.failure(error));
                })
            )
        )
    );
