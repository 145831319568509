import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { CardProps } from '../Timeline/TimeSlotCard';

import '../AllDayTimeline/AllDayTimeline.scss';

interface CustomTimelineProps {
    handleTimeSlotChange: (value: CardProps) => void;
    startTime: DateTime;
    endTime: DateTime;
    hourFormat: string;
    timezone: string;
}

const CustomTimeline = ({
    handleTimeSlotChange,
    startTime,
    endTime,
    hourFormat,
    timezone,
}: CustomTimelineProps): JSX.Element => {
    const { t } = useTranslation();
    const label = `${t('Timeline_Time_Label')} ${startTime.setZone(timezone).toFormat(hourFormat)} - ${endTime
        .setZone(timezone)
        .toFormat(hourFormat)}`;

    useEffect(() => {
        handleTimeSlotChange({ label, startTime, endTime });
    }, [startTime, endTime]);

    return <div className="time-label-container">{label}</div>;
};

export default CustomTimeline;
