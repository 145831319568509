import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';

import { ReactComponent as CrossCircle } from '../../../assets/icons/CrossCircle.svg';
import ActionButtonsPanel from '../../../components/ActionButtonsPanel';
import CompanySearchFrom from '../../../components/CompanySearchPanel/SearchForm/SearchForm';
import DataTable from '../../../components/DataTable';
import Pagination from '../../../components/DataTable/Pagination';
import Button from '../../../components/FormElements/Button';
import DateRangePicker from '../../../components/FormElements/DatePicker/DateRangePicker';
import InputWrapper from '../../../components/FormElements/InputWrapper';
import { ListItem, Select } from '../../../components/Forms';
import EditColumnsForm from '../../../components/Forms/EditColumnsForm';
import WithLoading from '../../../components/HOC/WithLoading';
import ReportFilterPanel from '../../../components/ReportFilterPanel/ReportFilterPanel';
import Header from '../../../components/SiteSubHeader';
import SlidingPanel from '../../../components/SlidingPanel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { AllListItemOption } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface, GridSettings, ReportsActiveColumn } from '../interfaces';
import {
    capitalizeFirstLetter,
    convertDateForExport,
    convertTimeForExport,
    customReportSelectStyles,
    generateAndDownloadReportExportFile,
    getSiteGroupListItems,
    getSiteListItems,
    resultPerPageListItems,
} from '../utils';

// eslint-disable-next-line import/no-cycle
import { VisitorBookingsReportProps } from './index';
import { buildQuery, getColumns } from './VisitorBookingsReportUtils';

import './VisitorBookingsReport.scss';

const VisitorBookingsReport = ({
    searchCompaniesResult,
    getCompanies,
    searchCompanies,
    companiesResult,
    isSearchCompaniesInProgress,
    isGetCompaniesInProgress,
    resetCompaniesSearch,
    is24HoursTimeFormat,
    reportData,
    siteGroups,
    sites,
    tenantId,
    generateReport,
    isReportGenerationInProgress,
    isGenerationReportForExportInProgress,
    reportDataForExport,
    generateReportForExport,
}: VisitorBookingsReportProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation() as any;

    const [gridSettings, setGridSettings] = useState<GridSettings>({
        page: '1',
        resultPerPage: resultPerPageListItems[2],
        sortOptions: [{ field: 'due', sort: 'asc' }],
    });

    const defaultFilter = {
        selectedSiteGroup: AllListItemOption,
        selectedSite: AllListItemOption,
        companyName: AllListItemOption.label,
        dateRange: { from: DateTime.now(), to: DateTime.now() },
    };

    const [isCompanySearchPanelOpen, setCompanySearchPanelOpen] = useState<boolean>(false);
    const [companySearchData, setCompanySearchData] = useState<any>({});
    const dateRange = location.state?.dateRange
        ? {
              from: DateTime.fromISO(location.state.dateRange.from),
              to: DateTime.fromISO(location.state.dateRange.to),
          }
        : null;

    const [appliedReportsFilter, setAppliedReportsFilter] = useState<any>({
        ...defaultFilter,
        ...{ ...location.state, ...({ dateRange } || null) },
    });
    const [reportsFilter, setReportsFilter] = useState<any>({
        ...defaultFilter,
        ...{ ...location.state, ...({ dateRange } || null) },
    });

    const siteGroupListItems = useMemo(() => getSiteGroupListItems(siteGroups[tenantId], sites), [siteGroups]);

    const siteListItems = useMemo(
        () => getSiteListItems(sites, reportsFilter.selectedSiteGroup),
        [sites, reportsFilter.selectedSiteGroup]
    );

    const visitorTypeListItems = [
        { value: 'External', label: 'External' },
        { value: 'Internal', label: 'Internal' },
    ];

    const [isFiltersOpened, setIsFiltersOpened] = useState(false);
    const [columnsFilter, setColumnsFilter] = useState<ColumnFilterInterface>({});
    const [activeColumnsFilter, setActiveColumnsFilter] = useState<ActiveColumnFilterInterface>({});

    const [activeFilter, setActiveFilter] = useState('');
    const [activeFilterHeaderName, setActiveFilterHeaderName] = useState('');
    const [activeColumnsFilterArray, setActiveColumnsFilterArray] = useState<any[]>([]);

    const defaultColumnsFilter: ColumnFilterInterface = {
        company: {
            values: companiesResult?.length ? companiesResult : [],
            enabled: !('companyName' in reportsFilter) || reportsFilter.companyName === 'All',
        },
        visitorType: {
            values: ['Internal', 'External'],
            enabled: true,
        },
    };

    useEffect(() => {
        setColumnsFilter({
            ...columnsFilter,
            ...defaultColumnsFilter,
        });
    }, [reportsFilter, companiesResult]);

    const updateColumnsFilter = (columnName: string, values: any[]): void => {
        setColumnsFilter({
            ...columnsFilter,
            [columnName]: {
                ...columnsFilter[columnName],
                values,
            },
        });
    };

    const handleFilterClick = (columnName: string, headerName: string): void => {
        if (columnName === 'company' && !companiesResult?.length && reportsFilter.companyName === 'All') {
            getCompanies();
        }
        setIsFiltersOpened(true);
        setActiveFilter(columnName);
        setActiveFilterHeaderName(headerName);

        if (columnName in activeColumnsFilter) {
            updateColumnsFilter(columnName, [...activeColumnsFilter[columnName].values]);
        } else {
            updateColumnsFilter(columnName, [...defaultColumnsFilter[columnName].values]);
        }
    };

    const renderSideBar = (): void => {
        switch (activeFilter) {
            case 'company':
                setActiveColumnsFilterArray(
                    companiesResult?.length
                        ? [
                              ...companiesResult
                                  ?.map((item) => ({ value: item, label: item }))
                                  .sort((a, b) => a.label.localeCompare(b.label)),
                          ]
                        : []
                );
                break;
            case 'visitorType':
                setActiveColumnsFilterArray(visitorTypeListItems);
                break;
            default:
                setActiveColumnsFilterArray([]);
        }
    };

    useEffect(() => {
        if (activeFilter) {
            renderSideBar();
        }
    }, [activeFilter, companiesResult]);

    const generateReportWithColumnsFilter = (): void => {
        setIsFiltersOpened(!isFiltersOpened);
        const newActiveColumnFilter = {
            ...activeColumnsFilter,
            [activeFilter]: { values: columnsFilter[activeFilter].values },
        };
        setActiveColumnsFilter(newActiveColumnFilter);
        generateReport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                newActiveColumnFilter
            )
        );
    };

    const loadPage = (gridState: GridSettings): void => {
        generateReport(
            buildQuery(
                Number(gridState.resultPerPage.value),
                Number(gridState.page),
                gridState.sortOptions,
                appliedReportsFilter,
                activeColumnsFilter
            )
        );
    };

    const applyFilterAndGenerateReport = useCallback(
        (reportsFilterParam?: any) => {
            setAppliedReportsFilter(reportsFilterParam || reportsFilter);
            setGridSettings({ ...gridSettings, page: '1' });
            generateReport(
                buildQuery(
                    Number(gridSettings.resultPerPage.value),
                    1,
                    gridSettings.sortOptions,
                    reportsFilterParam || reportsFilter,
                    activeColumnsFilter
                )
            );
        },
        [reportsFilter, gridSettings]
    );

    const setGridStateAndLoadPage = (newGridState: GridSettings): void => {
        setGridSettings(newGridState);
        loadPage(newGridState);
    };

    useEffect(() => {
        if (isCompanySearchPanelOpen) {
            if (companySearchData.name) {
                setCompanySearchData({ name: '' });
            }
        }
    }, [isCompanySearchPanelOpen]);

    useEffect(() => {
        loadPage(gridSettings);
    }, []);

    const defaultActiveColumns = getColumns(
        !!siteGroups[tenantId],
        handleFilterClick,
        columnsFilter,
        activeColumnsFilter,
        is24HoursTimeFormat
    ).map((item) => ({
        name: item.headerName,
        active: true,
    }));

    const [isEditColumnsPanelOpen, setEditColumnsPanelOpen] = useState<boolean>(false);
    const [activeColumns, setActiveColumns] = useState<ReportsActiveColumn[]>(defaultActiveColumns);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    const applyNewActiveColumns = (newColumns: ReportsActiveColumn[]): void => {
        setActiveColumns(newColumns);
    };

    const generateReportForExportCallBack = useCallback(() => {
        const visibleColumns = columns.map((c) => c.field);
        generateReportForExport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                activeColumnsFilter,
                true,
                reportData?.totalCount,
                visibleColumns
            )
        );
    }, [gridSettings, reportData, gridSettings, columns]);

    const formatReportForExport = (report?: any[]): any => {
        if (!report) {
            return [];
        }
        const formattedReport = report.map((i) => {
            return {
                ...('friendlyId' in i && { friendlyId: i.friendlyId }),
                ...('createdBy' in i && { createdBy: capitalizeFirstLetter(i.createdBy) }),
                ...('due' in i && { due: convertDateForExport(i.due, i.timezone) }),
                ...('dueTime' in i && { dueTime: convertTimeForExport(i.due, i.timezone) }),
                ...('timezone' in i && { timezone: convertTimeForExport(i.timezone) }),
                ...('status' in i && { status: i.status }),
                ...('visitorName' in i && { visitorName: i.visitorName }),
                ...('company' in i && { company: i.company }),
                ...('visitorType' in i && { visitorType: i.visitorType }),
                ...('hostName' in i && { hostName: i.hostName }),
                ...(!!siteGroups[tenantId] && 'siteGroupName' in i && { siteGroupName: i.siteGroupName }),
                ...('siteName' in i && { siteName: i.siteName }),
                ...('arrivalDateTime' in i && { arrivalDateTime: convertDateForExport(i.arrivalDateTime) }),
                ...('arrivalTime' in i && { arrivalTime: convertTimeForExport(i.arrivalDateTime) }),
                ...('departureDateTime' in i && { departureDateTime: convertDateForExport(i.departureDateTime) }),
                ...('departureTime' in i && { departureTime: convertTimeForExport(i.departureDateTime) }),
            };
        });

        return formattedReport;
    };

    useDidMountEffect(() => {
        if (!isGenerationReportForExportInProgress) {
            const formattedData = formatReportForExport(reportDataForExport?.items);
            generateAndDownloadReportExportFile('Visitor Bookings Report', columns, formattedData);
        }
    }, [isGenerationReportForExportInProgress]);

    useEffect(() => {
        const gridColumns = getColumns(
            !!siteGroups[tenantId],
            handleFilterClick,
            columnsFilter,
            activeColumnsFilter,
            is24HoursTimeFormat
        );
        const filteredColumns = gridColumns.filter(
            (item) => activeColumns.find((e) => e.name === item.headerName)?.active
        );
        setColumns(filteredColumns);
    }, [activeColumns, columnsFilter, activeColumnsFilter]);

    return (
        <main className="visitor-bookings-report">
            <Header
                title={t('VisitorBookingsReport_Title')}
                dataTestId="visitor-bookings-title"
                className="visitor-bookings-title"
                rightSideItems={[
                    <div className="edit-btn-n-date-range">
                        <DateRangePicker
                            dataTestId="date-range-picker"
                            dateRange={reportsFilter.dateRange}
                            onChange={(newDateRange) => {
                                const newFilter = { ...reportsFilter, dateRange: newDateRange };
                                setReportsFilter(newFilter);
                                setActiveColumnsFilter({});
                                applyFilterAndGenerateReport(newFilter);
                            }}
                            name="date-range-picker"
                            title={t('Reports_VisitorBookingsDateRange')}
                            required={false}
                        />
                        <Button
                            dataTestId="edit-columns-btn"
                            text={t('Reports_EditColumns')}
                            className="edit-btn"
                            onClick={() => {
                                setEditColumnsPanelOpen(true);
                            }}
                        />
                    </div>,
                ]}
            />
            <WithLoading isLoading={isReportGenerationInProgress || isGenerationReportForExportInProgress}>
                <div className="filter-options">
                    {siteGroups[tenantId] && (
                        <Select
                            label={t('Reports_SiteGroups')}
                            name="siteGroup"
                            items={siteGroupListItems}
                            className="site-select"
                            styles={customReportSelectStyles}
                            onChange={(selectedSiteGroup) => {
                                setReportsFilter({
                                    ...reportsFilter,
                                    selectedSiteGroup,
                                    selectedSite: AllListItemOption,
                                });
                                setActiveColumnsFilter({});
                            }}
                            value={reportsFilter.selectedSiteGroup}
                        />
                    )}

                    <Select
                        label={t('Reports_Sites')}
                        name="site"
                        items={siteListItems}
                        styles={customReportSelectStyles}
                        onChange={(selectedSite) => {
                            setReportsFilter({ ...reportsFilter, selectedSite });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedSite}
                    />

                    <InputWrapper label={t('Reports_Company')} inputName="company">
                        <button type="button" className="side-panel-input" data-testid="">
                            <div
                                data-testid="company"
                                className="selected-item"
                                onClick={() => {
                                    resetCompaniesSearch();
                                    setCompanySearchPanelOpen(!isCompanySearchPanelOpen);
                                }}
                            >
                                {reportsFilter.companyName && reportsFilter.companyName}
                            </div>
                            <div
                                data-testid="remove-company"
                                className="cross-icon"
                                onClick={() => {
                                    resetCompaniesSearch();
                                    setReportsFilter({ ...reportsFilter, companyName: AllListItemOption.label });
                                }}
                            >
                                <CrossCircle />
                            </div>
                        </button>
                    </InputWrapper>

                    <Button
                        text={t('Button_Apply')}
                        type="submit"
                        className="apply-btn"
                        dataTestId="apply-btn-btn"
                        disabled={isEqual(reportsFilter, appliedReportsFilter)}
                        onClick={applyFilterAndGenerateReport}
                    />
                </div>

                <DataTable
                    columns={columns}
                    rowsData={(activeColumns.some((column) => column.active) && reportData?.items) || []}
                    onSort={(sortOptions: any) => setGridStateAndLoadPage({ ...gridSettings, sortOptions })}
                    sortModel={gridSettings.sortOptions}
                    page={gridSettings.page}
                />

                <ActionButtonsPanel
                    leftSideElement={
                        <Pagination
                            pageString={gridSettings.page}
                            total={reportData?.totalCount || 0}
                            onPageChange={(page: string) => setGridStateAndLoadPage({ ...gridSettings, page })}
                            onPerPageChange={(perPage: ListItem) =>
                                setGridStateAndLoadPage({ ...gridSettings, resultPerPage: perPage, page: '1' })
                            }
                            resultPerPageOptions={resultPerPageListItems}
                            selectedResultPerPage={gridSettings.resultPerPage}
                        />
                    }
                >
                    <>
                        <Button
                            text={t('Button_Back')}
                            className="back-btn"
                            dataTestId="back-btn"
                            onClick={() => navigate(`/reports`)}
                        />
                        <Button
                            primary
                            text={t('Button_ExportReport')}
                            className="export-btn"
                            dataTestId="export-btn"
                            onClick={() => generateReportForExportCallBack()}
                            disabled={reportData?.items?.length === 0}
                        />
                    </>
                </ActionButtonsPanel>

                <SlidingPanel
                    title={t('Reports_SearchCompanies')}
                    className="sliding-panel"
                    open={isCompanySearchPanelOpen}
                    showBackgroundMask
                    onClose={() => {
                        setCompanySearchPanelOpen(false);
                    }}
                >
                    <WithLoading isLoading={isSearchCompaniesInProgress}>
                        <CompanySearchFrom
                            searchData={companySearchData}
                            onSearchDataChange={setCompanySearchData}
                            onSearch={(searchData: any) => searchCompanies(searchData.name)}
                            onSelect={(companyName: string) => {
                                setReportsFilter({ ...reportsFilter, companyName, visitor: null });
                                setActiveColumnsFilter({});
                            }}
                            searchResult={searchCompaniesResult}
                            resultPlaceHolder={t('Reports_SearchForCompanies')}
                            setSearchPanelOpen={setCompanySearchPanelOpen}
                            searchFieldPlaceholder={t('Reports_Company')}
                        />
                    </WithLoading>
                </SlidingPanel>
                <SlidingPanel
                    title={t('Reports_CustomColumns')}
                    open={isEditColumnsPanelOpen}
                    showBackgroundMask
                    className="sliding-panel"
                    onClose={() => {
                        setEditColumnsPanelOpen(false);
                    }}
                >
                    <EditColumnsForm
                        activeColumns={activeColumns}
                        applyNewActiveColumns={applyNewActiveColumns}
                        isEditColumnsPanelOpen={isEditColumnsPanelOpen}
                        setEditColumnsPanelOpen={setEditColumnsPanelOpen}
                    />
                </SlidingPanel>

                <ReportFilterPanel
                    isFiltersOpened={isFiltersOpened}
                    activeFilterHeaderName={activeFilterHeaderName}
                    setIsFiltersOpened={setIsFiltersOpened}
                    activeFilter={activeFilter}
                    activeColumnsFilterArray={activeColumnsFilterArray}
                    setColumnsFilter={setColumnsFilter}
                    columnsFilter={columnsFilter}
                    generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                    isLoading={isGetCompaniesInProgress}
                />
            </WithLoading>
        </main>
    );
};

export default VisitorBookingsReport;
