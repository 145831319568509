import { connect } from 'react-redux';
import { updateUserDefaultSite } from 'features/Account/Redux/actions';
import { LOAD_STATUSES } from 'features/constants';
import { getGeographicStructureAllSites } from 'features/GeographicStructures/Redux/actions';
import { orderBy } from 'lodash';
import { RootState } from 'PortalTypes';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { mapListItems } from 'utilities/selectList';

// eslint-disable-next-line import/no-cycle
import DefaultSiteSelector from './DefaultSiteSelector';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    isOpen: !state.filters.global.site,
    requiresLoading: state.referenceData.sites.loadStatus === LOAD_STATUSES.REQUIRED,
    isLoading: state.referenceData.sites.loadStatus === LOAD_STATUSES.LOADING,
    items: orderBy(
        mapListItems(
            state.referenceData.sites.items,
            (item: GeographicStructureItem) => item.id.toString(),
            (item: GeographicStructureItem) => item.name
        ),
        ['label', 'asc']
    ),
    userProfileId: state.authentication.user?.userProfileId,
    site: state.filters.global.site,
});

const mapDispatchToProps = {
    getSites: getGeographicStructureAllSites.request,
    saveSite: updateUserDefaultSite.request,
};

export type DefaultSiteSelectorProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(DefaultSiteSelector);
