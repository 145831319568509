import React from 'react';

const NotFound = (): JSX.Element => {
    return (
        <div className="notFound">
            <h1>Page Not Found</h1>
        </div>
    );
};

export default NotFound;
