import React from 'react';
import { GridColDef, GridColumnHeaderParams, GridValueFormatterParams } from '@mui/x-data-grid';
import i18n from 'i18next';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

import ReportFilterColumnHeader from '../../../components/ReportsFilterColumnHeader/ReportFilterColumnHeader';
import { AllListItemOption, DEFAULT_DATE_FORMAT } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface } from '../interfaces';
import { dateFormatter, timeFormatterWithTimeFormat } from '../utils';

const items = {
    id: true,
    name: true,
    department: true,
    roles: true,
    isDisabled: true,
    email: true,
    company: true,
    mobile: true,
    dateRegistered: true,
    timeRegistered: true,
    lastLogon: true,
    lastLogonTime: true,
    defaultSite: true,
    defaultLanguage: true,
    bookingsCount: true,
};

export const getColumns = (
    handleFilterClick: (field: string, headerName: string) => void,
    sideBarFilter: ColumnFilterInterface,
    activeSideBarFilter: ActiveColumnFilterInterface,
    is24HoursFormat?: boolean
): GridColDef[] => [
    {
        field: 'name',
        headerName: i18n.t('Reports_Name'),
        minWidth: 225,
        flex: 0.2,
    },
    {
        field: 'department',
        headerName: i18n.t('Reports_Department'),
        minWidth: 225,
        flex: 0.2,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.department?.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Department')}</p>
            ),
    },
    {
        field: 'isDisabled',
        headerName: i18n.t('Reports_Enabled'),
        minWidth: 175,
        cellClassName: 'capitalize',
        valueGetter: (params) => !params.row.isDisabled,
    },
    {
        field: 'roles',
        headerName: i18n.t('Reports_Roles'),
        minWidth: 250,
        flex: 0.2,
        sortable: false,
        cellClassName: 'multiline',
        renderCell: (params) => <div>{params.value}</div>,
    },
    {
        field: 'email',
        headerName: i18n.t('Reports_Email'),
        minWidth: 200,
        flex: 0.1,
    },
    {
        field: 'mobile',
        headerName: i18n.t('Reports_Mobile'),
        minWidth: 225,
        flex: 0.2,
        sortable: false,
    },
    {
        field: 'dateRegistered',
        headerName: `${i18n.t('Reports_DateRegistered')} (UTC)`,
        minWidth: 215,
        flex: 0.2,
        valueFormatter: (params: GridValueFormatterParams) => dateFormatter(params.value),
    },
    {
        field: 'timeRegistered',
        headerName: `${i18n.t('Reports_TimeRegistered')} (UTC)`,
        minWidth: 215,
        flex: 0.2,
        valueGetter: (params) => timeFormatterWithTimeFormat(params.row.dateRegistered, is24HoursFormat),
    },
    {
        field: 'lastLogon',
        headerName: `${i18n.t('Reports_LastLogonDate')} (UTC)`,
        minWidth: 200,
        flex: 0.2,
        valueFormatter: (params: GridValueFormatterParams) => dateFormatter(params.value),
    },
    {
        field: 'lastLogonTime',
        headerName: `${i18n.t('Reports_LastLogonTime')} (UTC)`,
        minWidth: 200,
        flex: 0.2,
        valueGetter: (params) => timeFormatterWithTimeFormat(params.row.lastLogon, is24HoursFormat),
    },
    {
        field: 'bookingsCount',
        headerName: i18n.t('Reports_BookingsCount'),
        minWidth: 175,
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.bookingsCount?.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_BookingsCount')}</p>
            ),
    },
    {
        field: 'defaultSite',
        headerName: i18n.t('Reports_DefaultSite'),
        minWidth: 200,
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.defaultSite?.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_DefaultSite')}</p>
            ),
    },
    {
        field: 'defaultLanguage',
        headerName: i18n.t('Reports_DefaultLanguage'),
        minWidth: 200,
        flex: 0.1,
    },
    {
        field: 'company',
        headerName: i18n.t('Reports_Company'),
        minWidth: 200,
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.company?.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Company')}</p>
            ),
    },
];

export const buildQuery = (
    itemsPerPageAmount: number,
    activePage: number,
    sortOptions: any,
    reportsFilter: any,
    activeSideBarFilter?: ActiveColumnFilterInterface,
    isForExport = false,
    totalCount = 0,
    visibleColumns?: string[]
): string => {
    const graphQLItems: { [key: string]: any } = { ...items };
    if (visibleColumns) {
        Object.keys(graphQLItems).forEach((key: string) => {
            if (!visibleColumns.includes(key)) {
                delete graphQLItems[key];
            }
        });
    }

    const query = {
        users: {
            __args: {
                skip: isForExport ? 0 : itemsPerPageAmount * (activePage - 1),
                take: isForExport ? totalCount : itemsPerPageAmount,
                order: {},
                where: {},
            },
            items: graphQLItems,
            totalCount: true,
        },
    };

    if (sortOptions?.length) {
        query.users.__args.order = { [sortOptions[0].field]: new EnumType(sortOptions[0].sort.toUpperCase()) };
    }

    if (reportsFilter?.useDateRange) {
        query.users.__args = {
            ...query.users.__args,
            ...{
                from: reportsFilter.dateRange.from.toFormat(DEFAULT_DATE_FORMAT),
                to: reportsFilter.dateRange.to.toFormat(DEFAULT_DATE_FORMAT),
            },
        };
    }
    let where = {};
    if (reportsFilter?.role.value !== AllListItemOption.value) {
        where = { rolesIds: { contains: reportsFilter.role.value } };
    }

    if (reportsFilter?.status.value !== AllListItemOption.value) {
        const isDisabled = reportsFilter.status.value === 'disabled';
        where = {
            ...where,
            isDisabled: { eq: isDisabled },
        };
    }

    if (reportsFilter?.company.value !== AllListItemOption.value) {
        where = {
            ...where,
            company: { eq: `${reportsFilter.company.label}` },
        };
    }

    if (activeSideBarFilter?.defaultSite?.values) {
        where = {
            ...where,
            siteId: { in: activeSideBarFilter.defaultSite.values },
        };
    }

    if (activeSideBarFilter?.department?.values) {
        where = {
            ...where,
            department: { in: activeSideBarFilter.department.values },
        };
    }

    if (activeSideBarFilter?.company?.values) {
        where = {
            ...where,
            company: { in: activeSideBarFilter.company.values },
        };
    }

    if (activeSideBarFilter?.bookingsCount?.values[0]) {
        switch (activeSideBarFilter.bookingsCount.values[0].option) {
            case 'less': {
                where = {
                    ...where,
                    bookingsCount: { lt: activeSideBarFilter.bookingsCount.values[0].value },
                };
                break;
            }
            case 'more': {
                where = {
                    ...where,
                    bookingsCount: { gt: activeSideBarFilter.bookingsCount.values[0].value },
                };
                break;
            }
            default: {
                where = {
                    ...where,
                    bookingsCount: { eq: activeSideBarFilter.bookingsCount.values[0].value },
                };
                break;
            }
        }
    }

    if (Object.keys(where).length === 0) {
        delete query.users.__args.where;
    } else {
        query.users.__args.where = where;
    }

    return jsonToGraphQLQuery(query);
};
