import React from 'react';
import { FeatureToggle, On } from 'featuretoggle-react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import Analytics2Component from '../../features/AnalyticsPoC/Analytics2';

const AnalyticsPOC = (): JSX.Element => {
    return (
        <FeatureToggle feature="analyticsFeature">
            <On>
                <MainLayout>
                    <Analytics2Component />
                </MainLayout>
            </On>
        </FeatureToggle>
    );
};

export default AnalyticsPOC;
