import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { ListItem, Select } from 'components/Forms';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';

import ActionButtonsPanel from '../../../components/ActionButtonsPanel';
import DataTable from '../../../components/DataTable';
import Pagination from '../../../components/DataTable/Pagination';
import Button from '../../../components/FormElements/Button';
import DateRangePicker from '../../../components/FormElements/DatePicker/DateRangePicker';
import EditColumnsForm from '../../../components/Forms/EditColumnsForm';
import WithLoading from '../../../components/HOC/WithLoading';
import ReportFilterPanel from '../../../components/ReportFilterPanel/ReportFilterPanel';
import Header from '../../../components/SiteSubHeader';
import SlidingPanel from '../../../components/SlidingPanel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { mapListItems } from '../../../utilities/selectList';
import { AllListItemOption } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface, GridSettings, ReportsActiveColumn } from '../interfaces';
import {
    capitalizeFirstLetter,
    checkEnabling,
    convertTimeForExport,
    customReportSelectStyles,
    filterArrayByAll,
    generateAndDownloadReportExportFile,
    getDefaultColumnsValues,
    getSiteListItems,
    resultPerPageListItems,
} from '../utils';

// eslint-disable-next-line import/no-cycle
import { UserReportProps } from './index';
import { statusListItems } from './UserReportFilterForm';
import { buildQuery, getColumns } from './userReportUtils';

import './UserReport.scss';

const UserReport = ({
    isReportGenerationInProgress,
    generateReport,
    reportData,
    reportDataForExport,
    userReportFilterOptions,
    getUserReportFilterOptions,
    isGetFiltersInProgress,
    is24HoursTimeFormat,
    isGenerationReportForExportInProgress,
    generateReportForExport,
    sites,
    departments,
    getDepartments,
    isGetDepartmentsInProgress,
}: UserReportProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [gridSettings, setGridSettings] = useState<GridSettings>({
        page: '1',
        resultPerPage: resultPerPageListItems[2],
        sortOptions: [{ field: 'name', sort: 'asc' }],
    });
    const [isFiltersOpened, setIsFiltersOpened] = useState(false);
    const [columnsFilter, setColumnsFilter] = useState<ColumnFilterInterface>({});
    const [activeColumnsFilter, setActiveColumnsFilter] = useState<ActiveColumnFilterInterface>({});

    const [activeFilter, setActiveFilter] = useState('');
    const [activeFilterHeaderName, setActiveFilterHeaderName] = useState('');
    const [activeColumnsFilterArray, setActiveColumnsFilterArray] = useState<any[]>([]);

    const defaultFilter = {
        role: AllListItemOption,
        status: AllListItemOption,
        company: AllListItemOption,
        dateRange: { from: DateTime.now(), to: DateTime.now() },
        useDateRange: false,
    };

    const dateRange = location.state?.dateRange
        ? {
              from: DateTime.fromISO(location.state.dateRange.from),
              to: DateTime.fromISO(location.state.dateRange.to),
          }
        : {
              from: DateTime.now(),
              to: DateTime.now(),
          };
    const roleListItems = useMemo(
        () =>
            [AllListItemOption].concat(
                mapListItems(
                    userReportFilterOptions?.roles || [],
                    (item: any) => item.id,
                    (item: any) => item.name
                )
            ),
        [userReportFilterOptions?.roles]
    );

    const companyListItems = useMemo(
        () =>
            [AllListItemOption].concat(
                mapListItems(
                    userReportFilterOptions?.companies || [],
                    (item: string) => item,
                    (item: string) => item
                )
            ),
        [userReportFilterOptions?.companies]
    );

    const siteListItems = useMemo(() => getSiteListItems(sites, AllListItemOption), [sites]);
    const [appliedReportsFilter, setAppliedReportsFilter] = useState<any>({
        ...defaultFilter,
        ...{ ...location.state, ...({ dateRange } || null) },
    });
    const [reportsFilter, setReportsFilter] = useState<any>({
        ...defaultFilter,
        ...{ ...location.state, ...({ dateRange } || null) },
    });

    const defaultColumnsFilter: ColumnFilterInterface = {
        company: {
            values: [...getDefaultColumnsValues(companyListItems)],
            enabled: checkEnabling(companyListItems, reportsFilter.company.value),
        },
        department: { values: departments?.length ? departments : [], enabled: true },
        defaultSite: {
            values: [...getDefaultColumnsValues(siteListItems)],
            enabled: true,
        },
        bookingsCount: {
            values: [{ value: 1, option: 'equal' }],
            enabled: true,
        },
    };

    useEffect(() => {
        getUserReportFilterOptions();
    }, []);

    const applyFilterAndGenerateReport = useCallback(
        (reportsFilterParam?: any) => {
            setAppliedReportsFilter(reportsFilterParam || reportsFilter);
            setGridSettings({ ...gridSettings, page: '1' });
            generateReport(
                buildQuery(
                    Number(gridSettings.resultPerPage.value),
                    1,
                    gridSettings.sortOptions,
                    reportsFilterParam || reportsFilter
                )
            );
        },
        [reportsFilter, gridSettings]
    );

    const loadPage = (gridState: GridSettings): void => {
        generateReport(
            buildQuery(
                Number(gridState.resultPerPage.value),
                Number(gridState.page),
                gridState.sortOptions,
                appliedReportsFilter,
                activeColumnsFilter
            )
        );
    };

    useEffect(() => {
        loadPage(gridSettings);
    }, []);

    const setGridStateAndLoadPage = (newGridState: GridSettings): void => {
        setGridSettings(newGridState);
        loadPage(newGridState);
    };

    useEffect(() => {
        setColumnsFilter({
            ...columnsFilter,
            ...defaultColumnsFilter,
        });
    }, [siteListItems, reportsFilter, departments, companyListItems]);

    const updateColumnsFilter = (columnName: string, values: any[]): void => {
        setColumnsFilter({
            ...columnsFilter,
            [columnName]: {
                ...columnsFilter[columnName],
                values,
            },
        });
    };

    const handleFilterClick = (columnName: string, headerName: string): void => {
        if (columnName === 'department' && !departments?.length) {
            getDepartments();
        }
        setIsFiltersOpened(true);
        setActiveFilter(columnName);
        setActiveFilterHeaderName(headerName);

        if (columnName in activeColumnsFilter) {
            updateColumnsFilter(columnName, [...activeColumnsFilter[columnName].values]);
        } else {
            updateColumnsFilter(columnName, [...defaultColumnsFilter[columnName].values]);
        }
    };

    const renderSideBar = (): void => {
        switch (activeFilter) {
            case 'company':
                setActiveColumnsFilterArray(filterArrayByAll(companyListItems));
                break;
            case 'defaultSite':
                setActiveColumnsFilterArray(filterArrayByAll(siteListItems));
                break;
            case 'department':
                setActiveColumnsFilterArray(
                    departments?.length
                        ? [
                              ...departments
                                  ?.map((item) => ({ value: item, label: item }))
                                  .sort((a, b) => a.label.localeCompare(b.label)),
                          ]
                        : []
                );
                break;
            default:
                setActiveColumnsFilterArray([]);
        }
    };

    useEffect(() => {
        if (activeFilter) {
            renderSideBar();
        }
    }, [activeFilter, siteListItems, companyListItems, departments]);

    const generateReportWithColumnsFilter = (): void => {
        setIsFiltersOpened(!isFiltersOpened);
        const newActiveColumnFilter = {
            ...activeColumnsFilter,
            [activeFilter]: { values: columnsFilter[activeFilter].values },
        };
        setActiveColumnsFilter(newActiveColumnFilter);
        generateReport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                newActiveColumnFilter
            )
        );
    };

    const defaultActiveColumns = getColumns(
        handleFilterClick,
        columnsFilter,
        activeColumnsFilter,
        is24HoursTimeFormat
    ).map((item) => ({
        name: item.headerName,
        active: true,
    }));

    const [isEditColumnsPanelOpen, setEditColumnsPanelOpen] = useState<boolean>(false);
    const [activeColumns, setActiveColumns] = useState<ReportsActiveColumn[]>(defaultActiveColumns);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    const generateReportForExportCallBack = useCallback(() => {
        const visibleColumns = columns.map((c) => c.field);
        generateReportForExport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                activeColumnsFilter,
                true,
                reportData?.totalCount,
                visibleColumns
            )
        );
    }, [gridSettings, reportData, gridSettings, columns]);

    const formatReportForExport = (report?: any[]): any => {
        if (!report) {
            return [];
        }

        const formattedReport = report.map((i) => {
            return {
                name: i.name,
                department: i.department,
                isDisabled: capitalizeFirstLetter((!i.isDisabled).toString()),
                roles: i.roles,
                email: i.email,
                mobile: i.mobile,
                dateRegistered: new Date(i.dateRegistered),
                timeRegistered: convertTimeForExport(i.dateRegistered),
                lastLogon: new Date(i.lastLogon),
                lastLogonTime: convertTimeForExport(i.lastLogon),
                bookingsCount: i.bookingsCount,
                defaultSite: i.defaultSite,
                defaultLanguage: i.defaultLanguage,
                company: i.company,
            };
        });

        return formattedReport;
    };

    useDidMountEffect(() => {
        if (!isGenerationReportForExportInProgress) {
            const formattedData = formatReportForExport(reportDataForExport?.items);
            generateAndDownloadReportExportFile('Users Report', columns, formattedData);
        }
    }, [isGenerationReportForExportInProgress]);

    useEffect(() => {
        const gridColumns = getColumns(handleFilterClick, columnsFilter, activeColumnsFilter, is24HoursTimeFormat);
        const filteredColumns = gridColumns.filter(
            (item) => activeColumns.find((e) => e.name === item.headerName)?.active
        );
        setColumns(filteredColumns);
    }, [activeColumns, columnsFilter, activeColumnsFilter]);

    const applyNewActiveColumns = (newColumns: ReportsActiveColumn[]): void => {
        setActiveColumns(newColumns);
    };

    return (
        <main className="user-report">
            <WithLoading
                isLoading={
                    isGetFiltersInProgress || isReportGenerationInProgress || isGenerationReportForExportInProgress
                }
            >
                <Header
                    title={t('UserReport_Title')}
                    dataTestId="user-report-title"
                    className="user-report-title"
                    rightSideItems={[
                        <div className="checkbox-n-date-range">
                            <input
                                className="checkbox"
                                type="checkbox"
                                checked={reportsFilter.useDateRange}
                                onChange={() =>
                                    setReportsFilter({ ...reportsFilter, useDateRange: !reportsFilter.useDateRange })
                                }
                            />
                            <div className="label">{t('Reports_LogInsBetween')}</div>
                            <DateRangePicker
                                dataTestId="date-range-picker"
                                dateRange={reportsFilter.dateRange}
                                onChange={(newDateRange) => {
                                    const newFilter = { ...reportsFilter, dateRange: newDateRange };
                                    setReportsFilter(newFilter);
                                    applyFilterAndGenerateReport(newFilter);
                                    setActiveColumnsFilter({});
                                }}
                                name="date-range-picker"
                                title={t('Reports_LogInsBetween')}
                                required={false}
                                futureDatesAvailable={false}
                                disabled={!reportsFilter.useDateRange}
                            />
                            <Button
                                dataTestId="edit-columns-btn"
                                text={t('Reports_EditColumns')}
                                className="edit-btn"
                                onClick={() => {
                                    setEditColumnsPanelOpen(true);
                                }}
                            />
                        </div>,
                    ]}
                />

                <div className="filter-options">
                    <Select
                        label={t('Reports_Role')}
                        name="role"
                        dataTestId="role"
                        items={roleListItems}
                        styles={customReportSelectStyles}
                        onChange={(role) => {
                            setReportsFilter({ ...reportsFilter, role });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.role}
                    />
                    <Select
                        label={t('Reports_Status')}
                        name="status"
                        dataTestId="status"
                        items={statusListItems}
                        styles={customReportSelectStyles}
                        onChange={(status) => {
                            setReportsFilter({ ...reportsFilter, status });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.status}
                    />
                    <Select
                        label={t('Reports_Company')}
                        name="company"
                        dataTestId="company"
                        items={companyListItems}
                        styles={customReportSelectStyles}
                        onChange={(company) => {
                            setReportsFilter({ ...reportsFilter, company });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.company}
                    />

                    <Button
                        text={t('Button_Apply')}
                        type="submit"
                        className="apply-btn"
                        dataTestId="apply-btn-btn"
                        disabled={isEqual(reportsFilter, appliedReportsFilter)}
                        onClick={applyFilterAndGenerateReport}
                    />
                </div>

                <DataTable
                    columns={columns}
                    rowsData={(activeColumns.some((column) => column.active) && reportData?.items) || []}
                    onSort={(sortOptions: any) => setGridStateAndLoadPage({ ...gridSettings, sortOptions })}
                    sortModel={gridSettings.sortOptions}
                    page={gridSettings.page}
                />

                <ActionButtonsPanel
                    leftSideElement={
                        <Pagination
                            pageString={gridSettings.page}
                            total={reportData?.totalCount || 0}
                            onPageChange={(page: string) => setGridStateAndLoadPage({ ...gridSettings, page })}
                            onPerPageChange={(perPage: ListItem) =>
                                setGridStateAndLoadPage({ ...gridSettings, resultPerPage: perPage, page: '1' })
                            }
                            resultPerPageOptions={resultPerPageListItems}
                            selectedResultPerPage={gridSettings.resultPerPage}
                        />
                    }
                >
                    <>
                        <Button
                            text={t('Button_Back')}
                            className="back-btn"
                            dataTestId="back-btn"
                            onClick={() => navigate(`/reports`)}
                        />
                        <Button
                            primary
                            text={t('Button_ExportReport')}
                            className="export-btn"
                            dataTestId="export-btn"
                            onClick={() => generateReportForExportCallBack()}
                            disabled={reportData?.items?.length === 0}
                        />
                    </>
                </ActionButtonsPanel>

                <SlidingPanel
                    title={t('Reports_CustomColumns')}
                    open={isEditColumnsPanelOpen}
                    showBackgroundMask
                    className="sliding-panel"
                    onClose={() => {
                        setEditColumnsPanelOpen(false);
                    }}
                >
                    <EditColumnsForm
                        activeColumns={activeColumns}
                        applyNewActiveColumns={applyNewActiveColumns}
                        isEditColumnsPanelOpen={isEditColumnsPanelOpen}
                        setEditColumnsPanelOpen={setEditColumnsPanelOpen}
                    />
                </SlidingPanel>

                <ReportFilterPanel
                    isFiltersOpened={isFiltersOpened}
                    activeFilterHeaderName={activeFilterHeaderName}
                    setIsFiltersOpened={setIsFiltersOpened}
                    activeFilter={activeFilter}
                    activeColumnsFilterArray={activeColumnsFilterArray}
                    setColumnsFilter={setColumnsFilter}
                    columnsFilter={columnsFilter}
                    generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                    isLoading={isGetDepartmentsInProgress}
                    countFieldName="bookingsCount"
                />
            </WithLoading>
        </main>
    );
};

export default UserReport;
