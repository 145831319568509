import { connect } from 'react-redux';
import RepeatInfo from 'features/Resources/Common/EditBooking/RepeatInfo';
import { RootState } from 'PortalTypes';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => {
    return {
        items: [],
        emptyMessage: state.combined.edit?.info?.item?.isRepeat
            ? 'Repeat_AfterConfirmation_Message'
            : 'Repeat_NoRepeats_Message',
        resourceType: '',
    };
};

export default connect(mapStateToProps)(RepeatInfo);
