import { ALL_DAY, LANGUAGE, LOAD_STATUSES, VIEW_TYPE } from 'features/constants';
import { DateTime } from 'luxon';
import { combineReducers } from 'redux';
import { Language } from 'services/ApiClients/Language/Models';
import { CustomFilter, GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { ResourceType } from 'services/ApiClients/Resource';
import { createReducer } from 'typesafe-actions';

import { ExpandedResources } from '../../../services/ApiClients/OrganisationStructure/Models/GeographicStructureItem';

import * as actions from './actions';

interface GlobalFiltersState {
    site: GeographicStructureItem | null;
    expandedResources: ExpandedResources[] | [];
    siteGroup: GeographicStructureItem | null;
    cachedSiteImage: { [key: string]: string } | {};
}

export interface FloorPlanTimelineFilterState {
    duration: string | number;
    timeLine: any;
}

interface FiltersState {
    area: GeographicStructureItem | null;
    selectedDate: DateTime;
    viewType: VIEW_TYPE;
    advancedFilters: AdvancedFiltersState;
    toggleFilters: number[];
    floorPlanTimelineFilter: FloorPlanTimelineFilterState;
}

interface AdvancedFiltersState {
    disabledAccess: boolean;
    equipment: { [k: string]: CustomFilter };
}

interface State {
    loadStatus: string;
    global: GlobalFiltersState;
    combined: FiltersState;
    language: Language;
}

export default combineReducers<State>({
    loadStatus: createReducer<string>(LOAD_STATUSES.REQUIRED)
        .handleAction([actions.setFilters, actions.updateSite], () => LOAD_STATUSES.LOADING)
        .handleAction([actions.setFiltersComplete], () => LOAD_STATUSES.LOADED),

    global: combineReducers<GlobalFiltersState>({
        site: createReducer<GeographicStructureItem | null>(null).handleAction([actions.setSite], (state, action) => {
            if (!action.payload) {
                return null;
            }

            return { ...action.payload };
        }),

        cachedSiteImage: createReducer<{ [key: string]: string } | {}>({}).handleAction(
            [actions.setCachedSiteImage],
            (state, action) => action.payload
        ),

        expandedResources: createReducer<ExpandedResources[] | []>([]).handleAction(
            [actions.setExpandedResources],
            (state, action) => action.payload
        ),

        siteGroup: createReducer<GeographicStructureItem | null>(null).handleAction(
            [actions.setSiteGroup],
            (state, action) => {
                if (!action.payload) {
                    return null;
                }

                return { ...action.payload };
            }
        ),
    }),

    combined: combineReducers<FiltersState>({
        selectedDate: createReducer<DateTime>(DateTime.utc()).handleAction(
            [actions.setCombinedDate],
            (state, action) => action.payload
        ),

        area: createReducer<GeographicStructureItem | null>(null).handleAction([actions.setArea], (state, action) => {
            if (!action.payload) {
                return null;
            }

            return { ...action.payload };
        }),

        viewType: createReducer<VIEW_TYPE>(VIEW_TYPE.FLOOR_PLAN).handleAction(
            [actions.setCombinedViewType],
            (state, action) => action.payload
        ),

        advancedFilters: combineReducers<AdvancedFiltersState>({
            disabledAccess: createReducer<boolean>(false)
                .handleAction([actions.setCombinedDisabledAccess], (state, action) => action.payload)
                .handleAction([actions.resetCombinedAdvancedFilters], () => false),

            equipment: createReducer<{ [k: string]: CustomFilter }>({})
                .handleAction([actions.setCombinedEquipments], (state, action) => {
                    if (!action.payload) {
                        return {};
                    }

                    return { ...action.payload };
                })
                .handleAction([actions.resetCombinedAdvancedFilters], (state) =>
                    Object.keys(state).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: {
                                ...state[id],
                                checked: false,
                            },
                        }),
                        {}
                    )
                ),
        }),

        toggleFilters: createReducer<number[]>([ResourceType.DESK.value, ResourceType.ROOM.value]).handleAction(
            [actions.setCombinedToggles],
            (state, action) => {
                if (state.includes(action.payload)) {
                    return state.filter((item) => item !== action.payload);
                }
                return [...state, action.payload].sort((a, b) => a - b);
            }
        ),

        floorPlanTimelineFilter: combineReducers<FloorPlanTimelineFilterState>({
            duration: createReducer<number | string>(ALL_DAY).handleAction(
                [actions.setFloorPlanTimeLineDurationFilter],
                (state, action) => action.payload
            ),
            timeLine: createReducer<any>(null).handleAction(
                [actions.setFloorPlanTimeLineFilter],
                (state, action) => action.payload
            ),
        }),
    }),

    language: createReducer<Language>(LANGUAGE.DEFAULT).handleAction(
        [actions.setLanguage],
        (state, action) => action.payload
    ),
});
