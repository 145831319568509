import { FLOORPIN_STATUSES } from '../../../../utilities/floorplanUtils';

export const TWENTY_FOUR_HOURS = 24;

export enum TIME_LINE_STATUSES {
    MY_BOOKING = 'my-booking',
    AVAILABLE = 'available',
    SETUP_CLEAR_DOWN = 'setup-clear-down',
    PRIVATE = 'is-private',
    OTHER_USER_BOOKING = 'other-user-booking',
    RESTRICTED = 'restricted',
    UNAVAILABLE_COVID = 'unavailable_COVID',
    UNAVAILABLE = 'unavailable',
}

export const getTimeLineHoursStyles = (item: number, specificBeginsHour: number, specificEndsHour: number): string => {
    if (item === specificEndsHour) {
        return 'last-item-hour';
    }

    if (item === specificBeginsHour) {
        return 'first-item-hour';
    }

    return item.toString().length === 2 ? 'two-numbers-hour' : 'one-number-hour';
};

export const getPinStatusName = (status = 'Available', createdBy: string, t: any, OBO?: string): string => {
    let pinStatus;
    switch (status) {
        case FLOORPIN_STATUSES.AVAILABLE: {
            pinStatus = t('ResourceInformation_Available');
            break;
        }
        case FLOORPIN_STATUSES.MY_BOOKING: {
            pinStatus = t('ResourceInformation_YourBooking');
            break;
        }

        case FLOORPIN_STATUSES.IS_PRIVATE: {
            pinStatus = t('ResourceInformation_PrivateBooking');
            break;
        }

        case FLOORPIN_STATUSES.ON_BEHALF_OF_ME: {
            pinStatus = t('ResourceInformation_YourBooking');
            break;
        }

        case FLOORPIN_STATUSES.ON_BEHALF_OF_INTERNAL: {
            pinStatus = t('ResourceInformation_InternalOBO', { OBO });
            break;
        }

        case FLOORPIN_STATUSES.RESTRICTED: {
            pinStatus = t('ResourceInformation_Restricted');
            break;
        }

        case FLOORPIN_STATUSES.NOT_WORKING_TIME: {
            pinStatus = 'Unavailable';
            break;
        }

        case FLOORPIN_STATUSES.BOOKED_IN_THE_PAST: {
            pinStatus = t('ResourceInformation_Unavailable');
            break;
        }

        case FLOORPIN_STATUSES.BOOKED: {
            pinStatus = t('ResourceInformation_BookedBy', { createdBy });
            break;
        }
        case FLOORPIN_STATUSES.UNAVAILABLE: {
            pinStatus = t('ResourceInformation_Unavailable');
            break;
        }
        case FLOORPIN_STATUSES.UNAVAILABLE_COVID: {
            pinStatus = t('ResourceInformation_Unavailable_COVID');
            break;
        }

        default:
            pinStatus = t('ResourceInformation_Available');
            break;
    }
    return pinStatus;
};
