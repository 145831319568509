import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from 'features/constants';
import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';

i18n
    // passes i18n down to react-i18next
    .use(initReactI18next)
    .use(Backend)
    // init i18next
    .init({
        lng: LANGUAGE.DEFAULT.locale,
        ns: LANGUAGE.NAMESPACES,
        defaultNS: 'portal',
        fallbackLng: 'en-GB',
        keySeparator: false, // we do not use keys in form messages.welcome

        backend: {
            backends: [HttpApi, HttpApi],
            backendOptions: [
                {
                    loadPath: '/locales/{{lng}}/{{ns}}.json', // `${Config.LANGUAGE_API}/translations/{{lng}}?namespaces={{ns}}&api-version=1.0`,
                    parse: (data: string, language: string, namespace: string) => {
                        const dataset = JSON.parse(data);
                        const context = dataset[language][namespace];
                        return context;
                    },
                    stringify: JSON.stringify,
                },
                {
                    loadPath: '/locales/{{lng}}/{{ns}}.json',
                },
            ],
        },
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
