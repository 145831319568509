import { NavigationStateActionTypes, StateProps } from '../types';

import * as actionTypes from './actionTypes';

const initialState = {
    isOpen: true,
    initialised: false,
    access: 0,
    tenantInfo: null,
    selectedSite: null,
};

export default (state: StateProps = initialState, action: NavigationStateActionTypes): StateProps => {
    switch (action.type) {
        case actionTypes.TOGGLE_NAV:
            return {
                ...state,
                isOpen: action.payload,
            };

        case actionTypes.INITIALISE_NAV:
            return {
                ...state,
                initialised: true,
            };

        default:
            return { ...state };
    }
};
