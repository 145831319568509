import React from 'react';
import { useTranslation } from 'react-i18next';
import deskInfoIcons from 'assets/icons/deskInfo';
import { ResourceCompanyAttributes } from 'features/Authentication/types';
import { isEmpty } from 'lodash';
import { SpecifiedCompanyAttributesResponse } from 'services/ApiClients/Organization/Models/CompanyAttributes';

import './ResourceAttributes.scss';
import './List.scss';

interface ResourceAttributesProps {
    attributes: ResourceCompanyAttributes[];
    tenantCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
    isFromSliderView?: boolean;
}

const ResourceAttributes = ({
    attributes,
    isFromSliderView,
    tenantCompanyAttributes,
}: ResourceAttributesProps): JSX.Element => {
    const { t } = useTranslation();

    const getAttributeName = (id: string): string => {
        if (tenantCompanyAttributes && tenantCompanyAttributes?.length > 0) {
            const foundAttribute = tenantCompanyAttributes?.find((attribute) => attribute.id === id);
            if (foundAttribute) {
                return foundAttribute.name;
            }
        }
        return '';
    };

    const getAttributeValueName = (attributeId: string, id: string): string => {
        if (tenantCompanyAttributes && tenantCompanyAttributes?.length > 0) {
            const foundAttribute = tenantCompanyAttributes?.find((attribute) => attribute.id === attributeId);
            if (foundAttribute) {
                const foundAttributeValue = foundAttribute.values?.find((attributeValue) => attributeValue.id === id);
                if (foundAttributeValue) {
                    return foundAttributeValue.name;
                }
            }
        }
        return '';
    };

    return (
        <div className="resourceAttributes">
            <img src={deskInfoIcons.informationIcon} alt="more information" />
            <div className="content">
                <p className="title">{t('Resource_Attributes')}</p>
                <ul className={`attributes-list ${isFromSliderView ? 'slider-view' : ''}`}>
                    {!isEmpty(attributes) &&
                        attributes.map((attribute: ResourceCompanyAttributes) => {
                            const attName = getAttributeName(attribute.id);
                            return attribute.values.map((attributeValue: string) => {
                                const valName = getAttributeValueName(attribute.id, attributeValue);
                                return (
                                    <li key={attributeValue}>
                                        {attName}: {valName}
                                    </li>
                                );
                            });
                        })}
                </ul>
            </div>
        </div>
    );
};

export default ResourceAttributes;
