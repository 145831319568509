import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InternalVisitorRoundedIcon } from 'assets/icons/visitor/InternalVisitorRounded.svg';
import SlidingPanel from 'components/SlidingPanel';
import { Person } from 'services/ApiClients/Models';

import './PersonDetailsPanel.scss';

interface PersonDetailsPanelProps {
    setPanelOpen: Function;
    isOpen: boolean;
    person: Person;
    title: string;
    nameTitle: string;
}

const PersonDetailsPanel = ({
    title,
    setPanelOpen,
    isOpen,
    person,
    nameTitle,
}: PersonDetailsPanelProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="person-details">
            <SlidingPanel
                title={title}
                className="person-details-panel"
                open={isOpen}
                onClose={() => {
                    setPanelOpen(false);
                }}
                titleIcon={<InternalVisitorRoundedIcon />}
                showBackgroundMask
            >
                {person && (
                    <div className="fields">
                        <div className="title-value">
                            <span className="title">{nameTitle}</span>
                            <span className="value">
                                {person.firstname} {person.surname}
                            </span>
                        </div>

                        {person.company && (
                            <div className="title-value">
                                <span className="title">{t('PersonDetails_Company')}</span>
                                <span className="value">{person.company}</span>
                            </div>
                        )}
                        {person.mobile && (
                            <div className="title-value">
                                <span className="title">{t('PersonDetails_Telephone')}</span>
                                <span className="value">{person.mobile}</span>
                            </div>
                        )}
                        {person.email && (
                            <button
                                type="button"
                                className="title-value email"
                                onClick={() => {
                                    window.location.href = `mailto:${person.email}`;
                                }}
                            >
                                <span className="title">{t('PersonDetails_Email')}</span>
                                <span className="value" data-testid="email-value">
                                    {person.email}
                                </span>
                            </button>
                        )}
                    </div>
                )}
            </SlidingPanel>
        </div>
    );
};

export default PersonDetailsPanel;
