import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthorizeRoute from 'features/Authentication/Components/AuthorizeRoute';
import TenantRedirectRoute from 'features/Authentication/Components/TenantRedirectRoute';
import ApiAuthorizationRoutes from 'features/Authentication/routes';
// eslint-disable-next-line import/no-cycle
import { RoutesProps } from 'routes';

import { Access } from '../enums/authorization';

import AnalyticsPOC2 from './AnalyticsPoC/Analytics2';
import LoginsReport from './Reports/Logins';
import ResourceReport from './Reports/Resource';
import ResourceBookings from './Reports/ResourceBookings';
import ResourceUtilisation from './Reports/ResourceUtilisation';
import UserReport from './Reports/User';
import VisitorBookingsReport from './Reports/VisitorBookings';
import VisitorProfile from './Reports/VitorProfile';
import VisitorBooking from './VisitorBooking/index';
import AnalyticsPOC from './AnalyticsPoC';
import Combined from './Combined';
import HelpRoute from './Help';
import Home from './Home';
import MultiResourceBookingEdit from './MultiResourceBookingEdit';
import MyAccountRoute from './MyAccount';
import MyBookings from './MyBookings';
import NoMatchRoute from './NoMatch';
import Reception from './Reception';
import Repeat from './Repeat';
import Reports from './Reports';
import Signout from './Signout';
import SingleBooking from './SingleBooking';

const AppRoutes = ({ tenantInfo, selectedSite, reportsPermissions }: RoutesProps): JSX.Element => {
    return (
        <Routes>
            <Route path="healthz" element={<p> Healthy </p>} />
            <Route path="/" element={<AuthorizeRoute element={Home} />} />
            <Route
                path="bookings"
                element={
                    <AuthorizeRoute
                        element={Combined}
                        turnedOn={
                            (tenantInfo?.areDesksAvailable || tenantInfo?.areRoomsAvailable) &&
                            (selectedSite?.areDesksAvailable || selectedSite?.areRoomsAvailable)
                        }
                        accessTypes={[Access.DeskBooking, Access.RoomBooking]}
                    />
                }
            />

            <Route
                path="bookings/:id"
                element={
                    <AuthorizeRoute element={SingleBooking} accessTypes={[Access.DeskBooking, Access.RoomBooking]} />
                }
            />

            <Route
                path="/bookings/repeat/:id"
                element={<AuthorizeRoute element={Repeat} accessTypes={[Access.DeskBooking, Access.RoomBooking]} />}
            />
            <Route
                path="/multi-resource-bookings/:id"
                element={
                    <AuthorizeRoute
                        element={MultiResourceBookingEdit}
                        accessTypes={[Access.DeskBooking, Access.RoomBooking]}
                    />
                }
            />
            <Route path="/myaccount" element={<AuthorizeRoute element={MyAccountRoute} />} />
            <Route path="/mybookings" element={<AuthorizeRoute element={MyBookings} />} />
            <Route
                path="/visitor-booking"
                element={
                    <AuthorizeRoute
                        element={VisitorBooking}
                        turnedOn={
                            tenantInfo?.isVisitorManagementAvailable && selectedSite?.isVisitorManagementAvailable
                        }
                    />
                }
            />
            <Route
                path="/visitor-booking/:id"
                element={
                    <AuthorizeRoute
                        element={VisitorBooking}
                        turnedOn={
                            tenantInfo?.isVisitorManagementAvailable && selectedSite?.isVisitorManagementAvailable
                        }
                    />
                }
            />
            <Route
                path="/reception"
                element={
                    <AuthorizeRoute
                        element={Reception}
                        accessTypes={[Access.Reception]}
                        turnedOn={
                            tenantInfo?.isVisitorManagementAvailable && selectedSite?.isVisitorManagementAvailable
                        }
                    />
                }
            />
            <Route path="/reports" element={<AuthorizeRoute element={Reports} turnedOn={!!reportsPermissions} />} />
            <Route
                path="/reports/visitor-profile"
                element={<AuthorizeRoute element={VisitorProfile} turnedOn={!!reportsPermissions} />}
            />
            <Route
                path="/reports/resource-bookings"
                element={<AuthorizeRoute element={ResourceBookings} turnedOn={!!reportsPermissions} />}
            />
            <Route
                path="/reports/resource-utilisation"
                element={<AuthorizeRoute element={ResourceUtilisation} turnedOn={!!reportsPermissions} />}
            />
            <Route
                path="/reports/resource"
                element={<AuthorizeRoute element={ResourceReport} turnedOn={!!reportsPermissions} />}
            />
            <Route
                path="/reports/user-report"
                element={<AuthorizeRoute element={UserReport} turnedOn={!!reportsPermissions} />}
            />
            <Route
                path="/reports/logins-report"
                element={<AuthorizeRoute element={LoginsReport} turnedOn={!!reportsPermissions} />}
            />
            <Route
                path="/reports/visitor-bookings-report"
                element={<AuthorizeRoute element={VisitorBookingsReport} turnedOn={!!reportsPermissions} />}
            />
            <Route path="/help" element={<AuthorizeRoute element={HelpRoute} />} />
            <Route path="/signout" element={<AuthorizeRoute element={Signout} />} />
            {ApiAuthorizationRoutes}
            {/* <Route path={ApplicationPaths.ApiAuthorizationPrefix} element={ApiAuthorizationRoutes} /> */}
            <Route
                path="*"
                element={
                    <TenantRedirectRoute>
                        <AuthorizeRoute element={NoMatchRoute} />
                    </TenantRedirectRoute>
                }
            />

            <Route path="/analytics" element={<AuthorizeRoute element={AnalyticsPOC} />} />
            <Route path="/analytics2" element={<AuthorizeRoute element={AnalyticsPOC2} />} />
        </Routes>
    );
};

export default AppRoutes;
