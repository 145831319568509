import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import DefaultSiteSelectorComponent from 'components/DefaultSiteSelector';
import WithLoading from 'components/HOC/WithLoading';
import { ListItem } from 'components/Select';
import SignOutPortal from 'components/SignOutPortal';
import { isEmpty } from 'lodash';
import Guid from 'utilities/guid';

// eslint-disable-next-line import/no-cycle
import { DefaultSiteSelectorProps } from '.';

const DefaultSiteSelector = ({
    isOpen,
    isLoading,
    requiresLoading,
    getSites,
    items,
    saveSite,
    userProfileId,
    site,
    children,
}: React.PropsWithChildren<DefaultSiteSelectorProps>): JSX.Element => {
    const location = useLocation();

    const { t } = useTranslation();

    const [value, setValue] = useState<OnChangeValue<ListItem, false> | null>(null);

    const onSave = (): void => {
        const request = {
            userProfileId: new Guid(userProfileId),
            siteId: new Guid((value as ListItem).value),
        };
        saveSite(request);
    };

    useEffect(() => {
        if (requiresLoading) {
            getSites();
        }
    }, [requiresLoading]);

    if (!site && location.pathname !== '/') {
        return <Navigate to="/" replace />;
    }

    return (
        <WithLoading isLoading={isLoading}>
            {children}
            {isEmpty(items) && !isLoading && <SignOutPortal />}
            {!isEmpty(items) && !isLoading && isOpen && (
                <DefaultSiteSelectorComponent
                    title={t('Default_Site_Selector_Title')}
                    description={t('Default_Site_Selector_Description')}
                    items={items}
                    onChange={(item: OnChangeValue<ListItem, false>) => setValue(item)}
                    value={value}
                    buttonText={t('Default_Site_Selector_Button_Text')}
                    isLoading={isLoading}
                    onSave={onSave}
                />
            )}
        </WithLoading>
    );
};

export default DefaultSiteSelector;
