import { GEOGRAPHIC_STRUCTURE_TYPES, VIEW_TYPE } from 'features/constants';
import { setCombinedViewType } from 'features/Filters/Redux/actions';
import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, mergeMap, switchMap } from 'rxjs/operators';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { isActionOf } from 'typesafe-actions';

import {
    getGeographicStructureAllSites,
    getGeographicStructureAreas,
    getGeographicStructureById,
    getGeographicStructureSiteGroups,
    getGeographicStructureSites,
} from './actions';

export const getGeographicStructureByIdEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getGeographicStructureById.request)),
        switchMap((action) =>
            from(api.organisationStructure.getGeographicStructureById(action.payload)).pipe(
                concatMap((payload) => of(getGeographicStructureById.success(payload)))
            )
        ),
        catchError((error) => of(getGeographicStructureById.failure(error)))
    );

export const getGeographicStructureAllSitesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getGeographicStructureAllSites.request)),
        switchMap(() =>
            from(api.organisationStructure.getGeographicStructureAllSites()).pipe(
                concatMap((payload) => {
                    const items = payload?.payload as GeographicStructureItem[];
                    return of(getGeographicStructureAllSites.success(items));
                })
            )
        ),
        catchError((error) => of(getGeographicStructureAllSites.failure(error)))
    );

export const getGeographicStructureSitesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getGeographicStructureSites.request)),
        mergeMap((action) =>
            from(api.organisationStructure.getGeographicStructureChildren(action.payload)).pipe(
                concatMap((payload) => {
                    const sites = (payload.payload as GeographicStructureItem[]).filter(
                        (item) => item.type === GEOGRAPHIC_STRUCTURE_TYPES.SITE
                    );
                    return of(
                        getGeographicStructureSites.success({ parentId: action.payload.toString(), items: sites })
                    );
                })
            )
        ),
        catchError((error) => of(getGeographicStructureSites.failure(error)))
    );

export const getGeographicStructureSiteGroupsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getGeographicStructureSiteGroups.request)),
        mergeMap((action) =>
            from(api.organisationStructure.getGeographicStructureChildren(action.payload)).pipe(
                concatMap((payload) => {
                    const siteGroups = (payload.payload as GeographicStructureItem[]).filter(
                        (item) => item.type === GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP
                    );
                    return of(
                        getGeographicStructureSiteGroups.success({
                            parentId: action.payload.toString(),
                            items: siteGroups,
                        })
                    );
                })
            )
        ),
        catchError((error) => of(getGeographicStructureSites.failure(error)))
    );

export const getGeographicStructureAreasEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getGeographicStructureAreas.request)),
        mergeMap((action) =>
            from(api.organisationStructure.getGeographicStructureChildren(action.payload)).pipe(
                concatMap((payload) => {
                    const areas = (payload.payload as GeographicStructureItem[])
                        .filter((item) => item.type === GEOGRAPHIC_STRUCTURE_TYPES.AREA)
                        .sort((a, b) => {
                            if (a?.displayOrder && b?.displayOrder) {
                                return a.displayOrder - b.displayOrder;
                            }
                            return 0;
                        });
                    const areaContainsFloorPlan = areas.some((area) => area.floorPlanImage);
                    return of(
                        getGeographicStructureAreas.success({ parentId: action.payload.toString(), items: areas }),
                        setCombinedViewType(areaContainsFloorPlan ? VIEW_TYPE.FLOOR_PLAN : VIEW_TYPE.GRID)
                    );
                })
            )
        ),
        catchError((error) => of(getGeographicStructureAreas.failure(error)))
    );
