import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

export interface GridState {
    initialised: boolean;
}

export default combineReducers<GridState>({
    initialised: createReducer<boolean>(true),
});
