import * as account from 'features/Account/Redux/epics';
import * as authentication from 'features/Authentication/Redux/epics';
import * as filters from 'features/Filters/Redux/epics';
import * as geographicStructures from 'features/GeographicStructures/Redux/epics';
import * as languages from 'features/Languages/Redux/epics';
import * as myBookings from 'features/MyBookings/Redux/epics';
import * as reception from 'features/Reception/Redux/epics';
import * as referenceData from 'features/ReferenceData/Redux/epics';
import * as reports from 'features/Reports/Redux/epics';
import * as availability from 'features/Resources/Availability/Redux/epics';
import * as combined from 'features/Resources/Combined/Redux/epics';
import * as editCombined from 'features/Resources/Common/EditBooking/Redux/epics';
import * as floorPlan from 'features/Resources/Common/FloorPlan/Redux/epics';
import * as resourcesCommon from 'features/Resources/Common/Redux/epics';
import * as unavailability from 'features/Resources/Unavailability/Redux/epics';
import * as visitorBooking from 'features/VisitorBooking/Redux/epics';
import { combineEpics } from 'redux-observable';

export default combineEpics(
    ...Object.values(authentication),
    ...Object.values(account),
    ...Object.values(combined),
    ...Object.values(editCombined),
    ...Object.values(referenceData),
    ...Object.values(filters),
    ...Object.values(geographicStructures),
    ...Object.values(languages),
    ...Object.values(resourcesCommon),
    ...Object.values(visitorBooking),
    ...Object.values(reception),
    ...Object.values(myBookings),
    ...Object.values(unavailability),
    ...Object.values(availability),
    ...Object.values(reports),
    ...Object.values(floorPlan)
);
