import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { ApplicationPaths, LogoutActions, QueryParameterNames } from 'features/Authentication/constants';
import authService, { AuthenticationResultStatus } from 'features/Authentication/Services/AuthorizeService';
import { AuthRequestState, AuthResponse } from 'features/Authentication/types';
import { history } from 'store';

type LogoutProps = {
    action: string;
};

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
const Logout = ({ action }: LogoutProps): JSX.Element => {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);

    const getReturnUrl = (state: AuthRequestState | void): string => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    };

    const navigateToReturnUrl = (returnUrl: string): void => {
        return window.location.replace(returnUrl);
    };

    const displayLoader = (): JSX.Element => {
        return <Loading />;
    };

    const logout = async (returnUrl: string): Promise<void> => {
        const isauthenticated: boolean = await authService.checkIsAuthenticated();

        if (isauthenticated) {
            const result: AuthResponse = await authService.signOut({ returnUrl });

            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    setMessage(result.message ? result.message : null);
                    break;
                default:
                    throw new Error('Invalid authentication result status.');
            }
        } else {
            setMessage('You successfully logged out!');
        }
    };

    const processLogoutCallback = async (): Promise<void> => {
        const url = window.location.href;
        const result: AuthResponse = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await navigateToReturnUrl(getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                setMessage(result.message ? result.message : null);
                break;
            default:
                throw new Error('Invalid authentication result status.');
        }
    };

    const populateAuthenticationState = async (): Promise<void> => {
        await authService.checkIsAuthenticated();
        setIsReady(true);
    };

    useEffect(() => {
        switch (action) {
            case LogoutActions.Logout:
                if (history.action !== 'POP') {
                    logout(getReturnUrl());
                } else {
                    // This prevents regular links to <app>/authentication/logout from triggering a logout
                    setIsReady(true);
                    setMessage('The logout was not initiated from within the page.');
                }
                break;
            case LogoutActions.LogoutCallback:
                processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                setIsReady(true);
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        populateAuthenticationState();
    }, [action]);

    if (!isReady) {
        return <div />;
    }

    if (message) {
        return <div>{message}</div>;
    }

    switch (action) {
        case LogoutActions.Logout:
            return displayLoader();
        case LogoutActions.LogoutCallback:
            return displayLoader();
        case LogoutActions.LoggedOut:
            return displayLoader();
        default:
            throw new Error(`Invalid action '${action}'`);
    }
};

export default Logout;
