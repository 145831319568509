import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getLanguages } from './actions';

export const getLanguagesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getLanguages.request)),
        switchMap(() =>
            from(api.language.getLanguages()).pipe(
                concatMap((payload) => of(getLanguages.success(payload))),
                catchError((error) => of(getLanguages.failure(error)))
            )
        )
    );
