import { AxiosResponse } from 'axios';
import BookMode from 'features/Resources/Common/Models/BookMode';
import { RootEpic } from 'PortalTypes';
import { push } from 'redux-first-history';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CombinedResource } from 'services/ApiClients/Resource';
import ToastService from 'services/ToastService';
import { isActionOf } from 'typesafe-actions';
import Guid from 'utilities/guid';

import { LOAD_STATUSES } from '../../../../../constants';
import actions from '../actions';

export const getCombinedBookingEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getCombinedBookingAsync.request)),
        switchMap((action) =>
            from(api.booking.getSingle(action.payload)).pipe(
                map((payload) => actions.getCombinedBookingAsync.success(payload)),
                catchError((error) => of(actions.getCombinedBookingAsync.failure(error)))
            )
        )
    );

export const getParentBookingEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getParentBooking)),
        map((action) => actions.getCombinedBookingAsync.request(action.payload))
    );

export const getCombinedBookingResourceEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getCombinedBookingResourceAsync.request)),
        switchMap((action) => {
            return from(api.resource.getBookingResource(action.payload)).pipe(
                map((response) =>
                    actions.getCombinedBookingResourceAsync.success(response.payload as CombinedResource)
                ),
                catchError((error) => of(actions.getCombinedBookingResourceAsync.failure(error)))
            );
        })
    );

export const updateCombinedBookingEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.updateCombinedBookingAsync.request)),
        switchMap((action) =>
            from(api.booking.update(action.payload.bookingData)).pipe(
                map((payload) => actions.updateCombinedBookingAsync.success(payload)),
                catchError((error) => {
                    if (error.response.status === 410) {
                        ToastService.Error({ message: 'Toast_UpdateBookingOnDisabledResource' });
                        setTimeout(() => {
                            window.location.pathname = 'bookings';
                        }, 2000);
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('ConcurrentBookingException')
                    ) {
                        ToastService.Warning({ message: 'Toast_CreateBookingOnConcurrentBookingException' });
                    } else {
                        ToastService.Error({ message: 'Toast_ErrorUpdatingBooking' });
                    }
                    return of(actions.updateCombinedBookingAsync.failure(error));
                })
            )
        )
    );

export const updateCombinedBookingSuccessEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf(actions.updateCombinedBookingAsync.success)),
        tap(() => {
            ToastService.Success({ message: 'Toast_BookingUpdated' });
        }),
        map(() => actions.navigateFromInfoPage())
    );

export const updateRepeatCombinedBookingEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.updateRepeatCombinedBookingAsync.request)),
        switchMap((action) =>
            from(api.booking.update(action.payload.bookingData)).pipe(
                mergeMap((payload) => {
                    return [
                        actions.updateRepeatCombinedBookingAsync.success(payload),
                        actions.getCombinedBookingAsync.request({
                            bookingId: action.payload.bookingData?.bookingId || Guid.newGuid(),
                        }),
                    ];
                }),
                catchError((error) => {
                    if (error.response?.status === 410) {
                        ToastService.Error({ message: 'Toast_UpdateBookingOnDisabledResource' });
                        setTimeout(() => {
                            window.location.pathname = 'bookings';
                        }, 2000);
                    } else if (
                        error.response?.status === 409 &&
                        error.response.data.type.split('.').includes('ResourceAvailabilityReservationConflictException')
                    ) {
                        ToastService.Error({ message: 'Toast_NoTimeForOccurrences' });
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('ConcurrentBookingException')
                    ) {
                        ToastService.Warning({ message: 'Toast_CreateBookingOnConcurrentBookingException' });
                    } else if (
                        error.response.status === 400 &&
                        error.response.data.errors.Schedule.includes(
                            'RepeatSchedule occurrences count cannot be more then 100'
                        )
                    ) {
                        ToastService.Warning({ message: 'Toast_ErrorMaxOccurrencesCount' });
                    } else {
                        ToastService.Error({ message: 'Toast_ErrorUpdatingBooking' });
                    }
                    return of(actions.updateRepeatCombinedBookingAsync.failure(error));
                })
            )
        )
    );

export const bookACombinedConfirmFromBookingInfoFromEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.bookACombinedConfirmFromBookingInfoAsync.request)),
        switchMap((action) =>
            from(api.booking.confirm(action.payload.bookingData)).pipe(
                map(() => {
                    return actions.bookACombinedConfirmFromBookingInfoAsync.success(
                        action.payload?.bookingData?.bookingStatus
                    );
                }),
                catchError((error) => {
                    const { status } = error.response as AxiosResponse;
                    if (status === 409) {
                        ToastService.Error({
                            message: 'Toast_BookingSlotNoLongerAvailable',
                        });

                        return of(actions.navigateFromInfoPage());
                    }

                    return of(actions.bookACombinedConfirmFromBookingInfoAsync.failure(error));
                })
            )
        )
    );

export const bookASingleOccurrenceFromBookingInfoFromEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.bookASingleOccurrenceFromBookingInfoAsync.request)),
        switchMap((action) =>
            from(api.booking.confirmSingleOccurrence(action.payload)).pipe(
                mergeMap(() => {
                    return [
                        actions.bookASingleOccurrenceFromBookingInfoAsync.success(),
                        actions.navigateFromInfoPage(),
                    ];
                }),
                catchError((error) => {
                    const { status } = error.response as AxiosResponse;
                    if (status === 409) {
                        ToastService.Error({
                            message: 'Toast_BookingSlotNoLongerAvailable',
                        });

                        return of(actions.navigateFromInfoPage());
                    }

                    return of(actions.bookASingleOccurrenceFromBookingInfoAsync.failure(error));
                })
            )
        )
    );

export const bookACombinedConfirmFromGridEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.bookACombinedConfirmFromGridAsync.request)),
        switchMap((action) =>
            from(api.booking.confirm(action.payload.bookingData)).pipe(
                map((payload) => {
                    return actions.bookACombinedConfirmFromGridAsync.success(payload);
                }),
                catchError((error) => {
                    const { status } = error.response as AxiosResponse;
                    if (status === 409) {
                        ToastService.Error({
                            message: 'Toast_BookingSlotNoLongerAvailable',
                        });
                    }

                    return of(actions.bookACombinedConfirmFromGridAsync.failure(error));
                })
            )
        )
    );

export const bookACombinedConfirmSuccessEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf(actions.bookACombinedConfirmFromBookingInfoAsync.success)),
        tap((payload) => {
            ToastService.Success({
                message: payload.payload === BookMode.UpdatePending ? 'Toast_BookingUpdated' : 'Toast_BookingCreated',
            });
        }),
        map(() => actions.navigateFromInfoPage())
    );

// cancel a future booking
export const cancelACombinedEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.cancelACombinedAsync.request)),
        switchMap((action) =>
            from(api.booking.cancelOrCurtail(action.payload)).pipe(
                map((payload) => {
                    return actions.cancelACombinedAsync.success(payload);
                }),
                catchError((error) => of(actions.cancelACombinedAsync.failure(error)))
            )
        )
    );

export const cancelACombinedSuccessEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf([actions.cancelACombinedAsync.success])),
        tap(() => {
            ToastService.Success({ message: 'Toast_BookingCancelled' });
        }),
        map(() => actions.navigateFromInfoPage())
    );

export const cancelCombinedFailureEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf([actions.cancelACombinedAsync.failure])),
        tap(() => {
            ToastService.Error({ message: 'Toast_UnexpectedError' });
        }),
        map(() => actions.navigateFromInfoPage())
    );

// cancel a pending booking
export const cancelPendingBookingEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.cancelPendingBookingAsync.request)),
        switchMap((action) =>
            from(api.booking.cancelOrCurtail(action.payload)).pipe(
                map((payload) => {
                    return actions.cancelPendingBookingAsync.success(payload);
                }),
                catchError((error) => of(actions.cancelPendingBookingAsync.failure(error)))
            )
        )
    );

export const cancelPendingBookingSuccessEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf(actions.cancelPendingBookingAsync.success)),
        tap(() => {
            ToastService.Success({ message: 'Toast_ReservationCancelled' });
        }),
        map(() => actions.navigateFromInfoPage())
    );

export const cancelPendingBookingFailureEpic: RootEpic = (action$) =>
    action$.pipe(
        filter(isActionOf(actions.cancelPendingBookingAsync.failure)),
        tap(() => {
            ToastService.Error({ message: 'Toast_UnexpectedError' });
        }),
        map(() => actions.navigateFromInfoPage())
    );

// check in a booking
export const checkInBookingEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.checkInACombinedAsync.request)),
        switchMap((action) =>
            from(api.booking.checkIn(action.payload)).pipe(
                mergeMap((payload) => {
                    ToastService.Success({ message: 'Toast_BookingCheckedIn' });
                    return [
                        actions.checkInACombinedAsync.success(payload),
                        actions.getCombinedBookingAsync.request({
                            bookingId: state$.value.combined.edit.info.item?.id || new Guid(),
                        }),
                        actions.setBookingInfoLoadStatus(LOAD_STATUSES.REQUIRED),
                    ];
                }),
                catchError((error) => {
                    if (error.response.status === 404) {
                        ToastService.Error({ message: 'Toast_BookingCheckedInResourceNotAvailable' });
                    } else if (
                        error.response.status === 409 &&
                        error.response.data.type
                            .split('.')
                            .includes('ResourceBookingDetailCheckInReservationConflictException')
                    ) {
                        ToastService.Error({ message: 'Toast_BookingCheckedInConflict' });
                    } else {
                        ToastService.Error({ message: 'Toast_UnexpectedError' });
                    }
                    return of(actions.checkInACombinedAsync.failure(error));
                })
            )
        )
    );

// check out a booking
export const checkOutBookingEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.checkOutACombinedAsync.request)),
        switchMap((action) =>
            from(api.booking.checkOut(action.payload)).pipe(
                mergeMap((payload) => {
                    ToastService.Success({ message: 'Toast_BookingCheckedOut' });
                    return [
                        actions.checkOutACombinedAsync.success(payload),
                        actions.getCombinedBookingAsync.request({
                            bookingId: state$.value.combined.edit.info.item?.id || new Guid(),
                        }),
                        actions.setBookingInfoLoadStatus(LOAD_STATUSES.REQUIRED),
                    ];
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(actions.checkOutACombinedAsync.failure(error));
                })
            )
        )
    );

export const getPersonsByIds: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getPersonsByIds.request)),
        switchMap((action) =>
            from(api.person.getPersonsByIds(action.payload)).pipe(
                map((payload) => actions.getPersonsByIds.success(payload))
            )
        )
    );

export const getRolesByIds: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getRolesByIds.request)),
        switchMap((action) =>
            from(api.identity.getRolesByIds(action.payload)).pipe(
                map((payload) => actions.getRolesByIds.success(payload))
            )
        )
    );

export const getSpecifiedAttributes: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getSpecifiedAttributes.request)),
        switchMap((action) =>
            from(api.organisation.getSpecifiedCompanyAttributes(action.payload)).pipe(
                map((payload) => actions.getSpecifiedAttributes.success(payload))
            )
        )
    );

export const cancelSingleOccurrence: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.cancelSingleOccurrenceAsync.request)),
        switchMap((action) =>
            from(api.booking.canselSingleOccurrence(action.payload)).pipe(
                mergeMap((payload) => [
                    actions.cancelSingleOccurrenceAsync.success(payload),
                    actions.navigateFromInfoPage(),
                ]),
                catchError((error) => of(actions.cancelSingleOccurrenceAsync.failure(error)))
            )
        )
    );

export const searchInternalOboPersonsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.searchInternalOboPersons.request)),
        switchMap((action) =>
            from(api.person.searchPersons(action.payload)).pipe(
                map((payload) => actions.searchInternalOboPersons.success(payload)),
                catchError((error) => of(actions.searchInternalOboPersons.failure(error)))
            )
        )
    );

export const deleteRepeatSeries: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.deleteRepeatSeriesAsync.request)),
        switchMap((action) =>
            from(api.booking.cancelOrCurtail(action.payload)).pipe(
                mergeMap((payload) => {
                    ToastService.Success({ message: 'Toast_BookingCancelled' });
                    return [actions.deleteRepeatSeriesAsync.success(payload), actions.navigateFromInfoPage()];
                }),
                catchError((error) => of(actions.deleteRepeatSeriesAsync.failure(error)))
            )
        )
    );

export const searchExternalOboPersonsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.searchExternalOboPersons.request)),
        switchMap((action) =>
            from(api.visitorManagement.searchVisitor(action.payload)).pipe(
                map((payload) => actions.searchExternalOboPersons.success(payload)),
                catchError((error) => of(actions.searchExternalOboPersons.failure(error)))
            )
        )
    );

export const createVisitorEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.createVisitor.request)),
        switchMap((action) =>
            from(api.visitorManagement.createExternalVisitor(action.payload)).pipe(
                map(() => {
                    ToastService.Success({ message: 'Toast_VisitorCreated' });

                    return actions.createVisitor.success();
                }),
                catchError((error) => {
                    const { status, data } = error.response as AxiosResponse;

                    if (status === 409) {
                        ToastService.Error({
                            message: 'Toast_VisitorErrorEmailExists',
                        });
                    } else if (
                        status === 403 &&
                        data.type.split('.').includes('VisitorManagementNotAvailableException')
                    ) {
                        ToastService.Error({ message: 'Toast_ErrorVisitorManagementServiceNotAvailaible' });
                    } else {
                        ToastService.Error({ message: 'Toast_UnexpectedError' });
                    }
                    return of(actions.createVisitor.failure(error));
                })
            )
        )
    );

export const editVisitorEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.editVisitor.request)),
        switchMap((action) =>
            from(api.visitorManagement.editExternalVisitor(action.payload)).pipe(
                map(() => {
                    ToastService.Success({ message: 'Toast_VisitorUpdated' });

                    return actions.editVisitor.success();
                }),
                catchError((error) => {
                    const { status } = error.response as AxiosResponse;
                    if (status === 409) {
                        ToastService.Error({
                            message: 'Toast_VisitorErrorEmailExists',
                        });
                    } else {
                        ToastService.Error({ message: 'Toast_UnexpectedError' });
                    }
                    return of(actions.editVisitor.failure(error));
                })
            )
        )
    );

export const abondonBookingEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.abandonBookingAsync.request)),
        switchMap((action) =>
            from(api.booking.abandonBooking(action.payload.abandonData)).pipe(
                mergeMap(() => {
                    const navigateToUrl = action.payload.navigateTo
                        ? state$.value.router?.location?.pathname
                        : '/bookings';

                    return [actions.abandonBookingAsync.success(), actions.navigateFromInfoPage(navigateToUrl)];
                }),
                catchError((error) => of(actions.abandonBookingAsync.failure(error)))
            )
        )
    );
};

export const abondonSingleOccurrenceEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.abandonSingleOccurrenceAsync.request)),
        switchMap((action) =>
            from(api.booking.abandonSingleOccurrence(action.payload.abandonData)).pipe(
                mergeMap(() => {
                    const returnValue = [actions.abandonSingleOccurrenceAsync.success()];
                    const navigateToUrl = action.payload.navigateTo
                        ? state$.value.router?.location?.pathname
                        : '/bookings';

                    if (action.payload.shouldExit) {
                        return [...returnValue, actions.navigateFromInfoPage(navigateToUrl)];
                    }
                    return returnValue;
                }),
                catchError((error) => of(actions.abandonSingleOccurrenceAsync.failure(error)))
            )
        )
    );
};

export const navigateFromBookingInfoPage: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.navigateFromInfoPage)),
        switchMap((action) => {
            let pathname;
            if (!action.payload) {
                pathname = '/bookings';
            } else {
                pathname = action.payload;
            }
            return of(push({ pathname }));
        })
    );
};

export const getRepeatOccurrencesEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.getRepeatOccurrences.request)),
        switchMap((action) =>
            from(api.booking.getRepeatOccurrences(action.payload)).pipe(
                map((response) => actions.getRepeatOccurrences.success(response)),
                catchError((error) => of(actions.getRepeatOccurrences.failure(error)))
            )
        )
    );
};

export const checkInOccurrenceEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.checkInSingleOccurrence.request)),
        switchMap((action) =>
            from(api.booking.checkInOccurrence(action.payload)).pipe(
                mergeMap(() => {
                    ToastService.Success({ message: 'Toast_BookingCheckedIn' });
                    return [
                        actions.checkInSingleOccurrence.success(),
                        actions.getCombinedBookingAsync.request({
                            bookingId: state$.value.combined.edit.info.item?.id || new Guid(),
                        }),
                        actions.getRepeatOccurrences.request({
                            bookingId: state$.value.combined.edit.info.item?.id || new Guid(),
                        }),
                    ];
                }),
                catchError((error) => {
                    if (error.response.status === 404) {
                        ToastService.Error({ message: 'Toast_BookingCheckedInResourceNotAvailable' });
                    } else if (
                        error.response.status === 409 &&
                        error.response.data.type
                            .split('.')
                            .includes('ResourceBookingDetailCheckInReservationConflictException')
                    ) {
                        ToastService.Error({ message: 'Toast_BookingCheckedInConflict' });
                    } else {
                        ToastService.Error({ message: 'Toast_UnexpectedError' });
                    }
                    return of(actions.checkInSingleOccurrence.failure(error));
                })
            )
        )
    );

export const checkOutOccurrenceEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.checkOutSingleOccurrence.request)),
        switchMap((action) =>
            from(api.booking.checkOutOccurrence(action.payload)).pipe(
                mergeMap(() => {
                    ToastService.Success({ message: 'Toast_BookingCheckedOut' });
                    return [
                        actions.checkOutSingleOccurrence.success(),
                        actions.getCombinedBookingAsync.request({
                            bookingId: state$.value.combined.edit.info.item?.id || new Guid(),
                        }),
                        actions.getRepeatOccurrences.request({
                            bookingId: state$.value.combined.edit.info.item?.id || new Guid(),
                        }),
                    ];
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(actions.checkOutSingleOccurrence.failure(error));
                })
            )
        )
    );

export const getResourceImageEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.getResourceImage.request)),
        switchMap((action) =>
            from(api.resource.getResourceImage(action.payload)).pipe(
                map((response) => actions.getResourceImage.success(response)),
                catchError((error) => of(actions.getResourceImage.failure(error)))
            )
        )
    );
};

export const getTenantCompanyAttributesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getTenantCompanyAttributes.request)),
        switchMap((action) =>
            from(api.organisation.getAvailableCompanyAttributes()).pipe(
                map((payload) => actions.getTenantCompanyAttributes.success(payload)),
                catchError((error) => of(actions.getTenantCompanyAttributes.failure(error)))
            )
        )
    );
