import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import WithLoading from 'components/HOC/WithLoading';
import SlidingPanel from 'components/SlidingPanel';

// eslint-disable-next-line import/no-cycle
import SearchFrom from './SearchForm/SearchForm';
import SelectedItem from './SelectedItem/SelectedItem';

import './CompanySearch.scss';

export interface CompanySearchData {
    name: string;
}

interface CompanySearchProps {
    onSelect: Function;
    onSearch: Function;
    searchData: CompanySearchData;
    onSearchDataChange: Function;
    searchResult: string[] | null;
    selectedItem: string | null;
    buttonLabel: string;
    panelLabel: string;
    resultPlaceHolder: string;
    dataTestId: string;
    iaSearchInProgress: boolean;
    required?: boolean;
    resetSearchResult: Function;
    className?: string;
    isDisabled?: boolean;
    searchFieldPlaceholder: string;
}

export const CompanySearchPanel = ({
    className,
    onSelect,
    searchData,
    onSearchDataChange,
    onSearch,
    searchResult,
    selectedItem,
    iaSearchInProgress,
    buttonLabel,
    panelLabel,
    resultPlaceHolder,
    dataTestId,
    required = false,
    resetSearchResult,
    isDisabled,
    searchFieldPlaceholder,
}: CompanySearchProps): JSX.Element => {
    const [isSearchPanelOpen, setSearchPanelOpen] = useState<boolean>(false);

    useEffect(() => {
        if (selectedItem) {
            setSearchPanelOpen(false);
        }
    }, [selectedItem]);

    useEffect(() => {
        if (isSearchPanelOpen) {
            resetSearchResult();
            if (searchData.name) {
                onSearchDataChange({ name: '' });
            }
        }
    }, [isSearchPanelOpen]);

    return (
        <div className={classNames('company-search', className)}>
            <SelectedItem
                onSelect={onSelect}
                onSearchDataChange={onSearchDataChange}
                buttonLabel={buttonLabel}
                dataTestId={dataTestId}
                required={required}
                resetSearchResult={resetSearchResult}
                selectedItemLabel={selectedItem}
                setSelectedPerson={onSelect}
                setSearchPanelOpen={setSearchPanelOpen}
                isDisabled={isDisabled}
            />

            <SlidingPanel
                title={panelLabel}
                className="company-search-panel"
                open={isSearchPanelOpen}
                showBackgroundMask
                onClose={() => {
                    setSearchPanelOpen(false);
                }}
            >
                <WithLoading isLoading={iaSearchInProgress}>
                    <SearchFrom
                        searchData={searchData}
                        onSearchDataChange={onSearchDataChange}
                        onSearch={onSearch}
                        onSelect={onSelect}
                        searchResult={searchResult}
                        resultPlaceHolder={resultPlaceHolder}
                        setSearchPanelOpen={setSearchPanelOpen}
                        searchFieldPlaceholder={searchFieldPlaceholder}
                    />
                </WithLoading>
            </SlidingPanel>
        </div>
    );
};
