import { getConfiguration } from 'utilities/appConfigsUtils';

import { LUXON_DEFAULT_DATE_TIME_FORMAT } from '../../../features/constants';
import { BuildAzureSearchQuery, SearchData } from '../../../utilities/searchUtils';
import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';
import { MyBookingCommand } from '../Booking/Models';
import { ConfigurationResponse } from '../Configuration/Models';
import { FileResponse, PersonsResponse } from '../Models';

import { GetSiteVisitsRequest } from './Models/GetSiteVisitsRequest';
import { SiteVisit } from './Models/SiteVisit';
import { Visitor } from './Models/Visitor';

export class VisitorManagementApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().VISITOR_MANAGEMENT_API;

        return `${baseUrl}/api/v1`;
    };

    public static searchVisitor = async (searchPersonsRequest: SearchData): Promise<ApiResponse<PersonsResponse>> => {
        const searchQuery = BuildAzureSearchQuery(searchPersonsRequest);
        return VisitorManagementApi.get(
            `${this.BuildBaseUrl()}/Visitor/search?SearchText=${searchQuery}&top=${
                searchPersonsRequest.top
            }&queryType=${1}&orderBy=${searchPersonsRequest.orderBy}`
        );
    };

    public static getBookableDays = async (siteId: string): Promise<ApiResponse<ConfigurationResponse>> => {
        return VisitorManagementApi.get(
            `${this.BuildBaseUrl()}/configuration/effective?ConfigurationType=VMS&ConfigurationLevel=site&ConfigurationLevelEntityId=${siteId}`
        );
    };

    public static createExternalVisitor = async (newExternalVisitorData: Visitor): Promise<ApiResponse> => {
        return VisitorManagementApi.post(
            `${this.BuildBaseUrl()}/Visitor/${newExternalVisitorData.id}`,
            newExternalVisitorData
        );
    };

    public static editExternalVisitor = async (visitor: Visitor): Promise<ApiResponse> => {
        return VisitorManagementApi.patch(`${this.BuildBaseUrl()}/Visitor/${visitor.id}`, visitor);
    };

    public static createSiteVisit = async (siteVisitData: SiteVisit): Promise<ApiResponse> => {
        return VisitorManagementApi.post(`${this.BuildBaseUrl()}/SiteVisit/${siteVisitData.id}`, siteVisitData);
    };

    public static createMultiDaySiteVisit = async (siteVisitData: SiteVisit): Promise<ApiResponse> => {
        return VisitorManagementApi.post(`${this.BuildBaseUrl()}/MultiDaySiteVisit/${siteVisitData.id}`, siteVisitData);
    };

    public static getSiteVisits = async (
        requestData: GetSiteVisitsRequest
    ): Promise<ApiResponse<{ siteVisits: SiteVisit[] }>> => {
        return VisitorManagementApi.get(
            `${this.BuildBaseUrl()}/SiteVisits?GeographicStructureId=${requestData.siteId}
            &Date=${requestData.date.toFormat(LUXON_DEFAULT_DATE_TIME_FORMAT)}`
        );
    };

    public static getMySiteVisits = async (
        requestData: MyBookingCommand
    ): Promise<ApiResponse<{ siteVisits: SiteVisit[] }>> => {
        return VisitorManagementApi.get(
            `${this.BuildBaseUrl()}/SiteVisits/me?FromDateTime=${requestData.fromDateTime}&ToDateTime=${
                requestData.toDateTime
            }`
        );
    };

    public static checkInSiteVisit = async (siteVisitId: string): Promise<ApiResponse> => {
        return VisitorManagementApi.patch(`${this.BuildBaseUrl()}/SiteVisit/${siteVisitId}/checkin`, null);
    };

    public static checkOutSiteVisit = async (
        siteVisitId: string,
        cancelFutureSiteVisits: boolean
    ): Promise<ApiResponse> => {
        // if visit not a multiday cancelFutureSiteVisits should not be added
        return VisitorManagementApi.patch(
            `${this.BuildBaseUrl()}/SiteVisit/${siteVisitId}/checkout${
                cancelFutureSiteVisits ? '?cancelFutureSiteVisits=true' : ''
            }`,
            null
        );
    };

    public static cancelSiteVisit = async (siteVisitId: string): Promise<ApiResponse> => {
        return VisitorManagementApi.patch(`${this.BuildBaseUrl()}/SiteVisit/${siteVisitId}/cancel`, null);
    };

    public static editSiteVisit = async (siteVisitData: any): Promise<ApiResponse> => {
        return VisitorManagementApi.patch(
            `${this.BuildBaseUrl()}/SiteVisit/${siteVisitData.siteVisitId}`,
            siteVisitData
        );
    };

    public static cancelMultiDayVisit = async (siteVisitSeriesId: string): Promise<ApiResponse> => {
        return VisitorManagementApi.patch(`${this.BuildBaseUrl()}/MultiDaySiteVisit/${siteVisitSeriesId}/cancel`, null);
    };

    public static getEvacuationReports = async (siteId: string): Promise<ApiResponse<FileResponse>> => {
        return VisitorManagementApi.get(`${this.BuildBaseUrl()}/SiteVisits/${siteId}/evacuation`);
    };

    public static searchCompanies = async (companyName: string): Promise<ApiResponse<string[]>> => {
        return VisitorManagementApi.get(`${this.BuildBaseUrl()}/Visitor/companies?companyName=${companyName}`);
    };

    public static getCompanies = async (): Promise<ApiResponse<string[]>> => {
        return VisitorManagementApi.get(`${this.BuildBaseUrl()}/Visitor/companies`);
    };
}
