import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getPinStatusName } from 'features/Resources/Common/InfoSliderTimeline/utils';
import { getFloorPinIconByStatus } from 'utilities/floorplanUtils';

import './pageHeader.scss';

interface PageHeaderProps {
    title?: string;
    isFromFloorPlanPage?: boolean;
    floorPinStatus?: string;
    resourceType?: number;
    pinSize?: number;
    createdBy?: string;
    OBO?: string;
    isFromSliderView?: boolean;
}

const PageHeader = ({
    title = '',
    isFromFloorPlanPage,
    floorPinStatus,
    resourceType = 0,
    pinSize = 20,
    createdBy = '',
    OBO,
    isFromSliderView,
}: PageHeaderProps): JSX.Element => {
    const { t } = useTranslation();
    const FloorPinIcon = getFloorPinIconByStatus(floorPinStatus, resourceType);

    const floorPinStatusName = getPinStatusName(floorPinStatus, createdBy, t, OBO);

    return (
        <div className={classNames('pageHeader', { pageHeaderSlider: isFromSliderView })}>
            <h2 className="resource">{title}</h2>
            {isFromFloorPlanPage && (
                <div className="resourcePin">
                    <FloorPinIcon
                        className={`floorPlan__container--floorplan--pin--${floorPinStatus}`}
                        style={{
                            width: pinSize,
                            height: pinSize,
                        }}
                    />
                    <p>{floorPinStatusName}</p>
                </div>
            )}
        </div>
    );
};

export default PageHeader;
