import { AxiosResponse } from 'axios';
import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import ToastService from '../../../services/ToastService';

import {
    cancelEditingSiteVisit,
    cancelEditMultiDaySiteVisit,
    createExternalVisitor,
    createMultiDaySiteVisit,
    createSiteVisit,
    editExternalVisitor,
    editSiteVisit,
    getBookableDays,
    getPersonById,
    searchExternalVisitors,
    searchHosts,
    searchInternalVisitors,
} from './actions';

export const searchInternalVisitorsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(searchInternalVisitors.request)),
        switchMap((action) =>
            from(api.person.searchPersons(action.payload)).pipe(
                map((payload) => searchInternalVisitors.success(payload)),
                catchError((error) => of(searchInternalVisitors.failure(error)))
            )
        )
    );

export const searchExternalVisitorsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(searchExternalVisitors.request)),
        switchMap((action) =>
            from(api.visitorManagement.searchVisitor(action.payload)).pipe(
                map((payload) => searchExternalVisitors.success(payload)),
                catchError((error) => of(searchExternalVisitors.failure(error)))
            )
        )
    );

export const searchHostsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(searchHosts.request)),
        switchMap((action) =>
            from(api.person.searchPersons(action.payload)).pipe(
                map((payload) => searchHosts.success(payload)),
                catchError((error) => of(searchHosts.failure(error)))
            )
        )
    );

export const createExternalVisitorEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(createExternalVisitor.request)),
        switchMap((action) =>
            from(api.visitorManagement.createExternalVisitor(action.payload)).pipe(
                map(() => {
                    ToastService.Success({ message: 'Toast_VisitorCreated' });

                    return createExternalVisitor.success();
                }),
                catchError((error) => {
                    const { status } = error.response as AxiosResponse;
                    if (status === 409) {
                        ToastService.Error({
                            message: 'Toast_VisitorErrorEmailExists',
                        });
                    } else {
                        ToastService.Error({ message: 'Toast_UnexpectedError' });
                    }
                    return of(createExternalVisitor.failure(error));
                })
            )
        )
    );

export const editExternalVisitorEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(editExternalVisitor.request)),
        switchMap((action) =>
            from(api.visitorManagement.editExternalVisitor(action.payload)).pipe(
                map(() => {
                    ToastService.Success({ message: 'Toast_VisitorUpdated' });

                    return editExternalVisitor.success();
                }),
                catchError((error) => {
                    const { status } = error.response as AxiosResponse;
                    if (status === 409) {
                        ToastService.Error({
                            message: 'Toast_VisitorErrorEmailExists',
                        });
                    } else {
                        ToastService.Error({ message: 'Toast_UnexpectedError' });
                    }
                    return of(editExternalVisitor.failure(error));
                })
            )
        )
    );

export const getInternalPersonInfoEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getPersonById.request)),
        switchMap((action) =>
            from(api.person.getPersonById(action.payload)).pipe(
                map((payload) => getPersonById.success(payload)),
                catchError((error) => of(getPersonById.failure(error)))
            )
        )
    );

export const getBookableDaysEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getBookableDays.request)),
        switchMap((action) =>
            from(api.visitorManagement.getBookableDays(action.payload)).pipe(
                map((payload) => getBookableDays.success(payload)),
                catchError((error) => of(getBookableDays.failure(error)))
            )
        )
    );

export const createSiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(createSiteVisit.request)),
        switchMap((action) =>
            from(api.visitorManagement.createSiteVisit(action.payload)).pipe(
                map(() => {
                    const toastMessage = action.payload.visitorCheckedIn
                        ? 'Toast_BookingCreatedAndCheckedIn'
                        : 'Toast_BookingCreated';

                    ToastService.Success({ message: toastMessage });
                    return createSiteVisit.success();
                }),

                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(createSiteVisit.failure(error));
                })
            )
        )
    );

export const createMultiDaySiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(createMultiDaySiteVisit.request)),
        switchMap((action) =>
            from(api.visitorManagement.createMultiDaySiteVisit(action.payload)).pipe(
                map(() => {
                    ToastService.Success({ message: 'Toast_MultiDayBookingCreated' });
                    return createMultiDaySiteVisit.success();
                }),

                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(createMultiDaySiteVisit.failure(error));
                })
            )
        )
    );

export const cancelEditingSiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(cancelEditingSiteVisit.request)),
        switchMap((action) =>
            from(api.visitorManagement.cancelSiteVisit(action.payload.siteVisitId)).pipe(
                map(() => {
                    ToastService.Success({ message: 'Toast_VisitorCanceled' });
                    return cancelEditingSiteVisit.success();
                }),
                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(cancelEditingSiteVisit.failure(error));
                })
            )
        )
    );

export const editSiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(editSiteVisit.request)),
        switchMap((action) =>
            from(api.visitorManagement.editSiteVisit(action.payload)).pipe(
                map(() => {
                    ToastService.Success({ message: 'Toast_VisitorEditUpdated' });
                    return editSiteVisit.success();
                }),

                catchError((error) => {
                    ToastService.Error({ message: 'Toast_UnexpectedError' });
                    return of(editSiteVisit.failure(error));
                })
            )
        )
    );

export const cancelEditMultiDaySiteVisitEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(cancelEditMultiDaySiteVisit.request)),
        switchMap((action) =>
            from(api.visitorManagement.cancelMultiDayVisit(action.payload.multiDaySiteVisitId)).pipe(
                map(() => {
                    ToastService.Success({ message: 'Toast_MultiDayVisitCanceled' });
                    return cancelEditMultiDaySiteVisit.success();
                }),

                catchError((error) => {
                    if (
                        error.response.status === 409 &&
                        error.response.data.type.split('.').includes('MultiDaySiteVisitCancelConflictException')
                    ) {
                        ToastService.Warning({ message: 'VisitorBooking_CancelAlreadyStartedMultiDaySiteVisit' });
                    } else {
                        ToastService.Error({ message: 'Toast_UnexpectedError' });
                    }
                    return of(cancelEditMultiDaySiteVisit.failure(error));
                })
            )
        )
    );
