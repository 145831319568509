import React from 'react';
import classNames from 'classnames';
import Button from 'components/FormElements/Button';

import ActionButton from './Models/ActionButton';
import ActionButtonType from './Models/ActionButtonType';

import './ActionButtons.scss';

export interface ActionButtonsProps {
    buttons?: ActionButton[];
    disabled?: boolean;
    buttonsClassName?: string;
}

const ActionButtons = ({ buttons = [], disabled = false, buttonsClassName = '' }: ActionButtonsProps): JSX.Element => {
    const CreateButton = (actionButton: ActionButton, className: string): JSX.Element => {
        const { name, label, onClick, type, submitting, dataTestId, isDisabled, icon } = actionButton;
        const buttonType = type === ActionButtonType.Primary ? 'submit' : 'button';
        const testId = dataTestId || `${name}-${buttonType}`;

        return (
            <Button
                key={name}
                text={label}
                type={buttonType}
                className={className}
                dataTestId={testId}
                onClick={onClick}
                submitting={submitting}
                disabled={disabled || isDisabled} // disabled - all buttons, isDisabled - option of concrete button
                icon={icon}
            />
        );
    };

    const filterButtons = (actionButtonType: ActionButtonType): ActionButton[] =>
        buttons.filter((button) => button.type === actionButtonType);

    const dangerButtons = filterButtons(ActionButtonType.Danger);
    const secondaryButtons = filterButtons(ActionButtonType.Secondary);
    const primaryButtons = filterButtons(ActionButtonType.Primary);
    const successButtons = filterButtons(ActionButtonType.Success);
    const editButtons = filterButtons(ActionButtonType.Edit);

    return (
        <div className={classNames(buttonsClassName, 'actionButtons')}>
            <div className="alignLeft">
                {successButtons.map((button) => CreateButton(button, 'success'))}
                {dangerButtons.map((button) => CreateButton(button, 'danger'))}
            </div>
            <div className="alignRight">
                {secondaryButtons.map((button) => CreateButton(button, 'secondary'))}
                {primaryButtons.map((button) => CreateButton(button, 'primary'))}
                {editButtons.map((button) => CreateButton(button, 'edit'))}
            </div>
        </div>
    );
};

export default ActionButtons;
