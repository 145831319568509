import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Reset } from 'assets/icons/Reset.svg';
import Button from 'components/FormElements/Button';
import { isEmpty } from 'lodash';
import { CustomFilter } from 'services/ApiClients/OrganisationStructure';

import MultipleFilter from './MultipleFilter';
import ToggleFilter from './ToggleFilter';

import './AdvancedFilters.scss';

export interface AdvancedFiltersObject {
    disabledAccess: boolean;
    equipment: { [k: string]: CustomFilter };
}
export interface AdvancedFiltersProps {
    onDone: () => void;
    onResetClick: () => void;
    advancedFilters: AdvancedFiltersObject;
    setDisabledAccess: (param: any) => void;
    setEquipmentsFilter: (param: { [k: string]: CustomFilter }) => void;
}

const AdvancedFilters = ({
    advancedFilters,
    onDone,
    onResetClick,
    setDisabledAccess,
    setEquipmentsFilter,
}: AdvancedFiltersProps): JSX.Element => {
    const { t } = useTranslation();
    const { disabledAccess, equipment } = advancedFilters;

    const handleToggleChange = (): void => {
        setDisabledAccess(!disabledAccess);
    };

    const handleMultipleFiltersChange = (filters: { [k: string]: CustomFilter }): void => {
        setEquipmentsFilter(filters);
    };

    return (
        <div className="advancedFilters">
            <Button
                type="button"
                className="reset"
                dataTestId="reset-button"
                text={t('AdvancedFilters_Reset')}
                icon={Reset}
                onClick={onResetClick}
            />
            {!isEmpty(equipment) && (
                <MultipleFilter
                    filters={equipment}
                    onChange={handleMultipleFiltersChange}
                    title={t('AdvancedFilters_FixedEquipment')}
                />
            )}
            <ToggleFilter
                label=""
                title={t('AdvancedFilters_DisabledAccess')}
                inputId="hasDisabledAccess"
                onChange={handleToggleChange}
                dataTestId="toggle_disabledAccess"
                checked={disabledAccess}
                value="disabledAccess"
            />
            <Button
                type="button"
                className="completeAction"
                dataTestId="done-button"
                onClick={onDone}
                text={t('Button_Done')}
            />
        </div>
    );
};

export default memo(AdvancedFilters);
