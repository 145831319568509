import React, { PropsWithChildren } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/ClosePanel.svg';
import classNames from 'classnames';
import BackgroundMask from 'components/BackgroundMask';
import Button from 'components/FormElements/Button';
import Loading from 'components/Loading';

import './NewSlidingPanel.scss';

export interface NewSlidingPanelProps {
    open?: boolean;
    showBackgroundMask?: boolean;
    onClose: (param?: any) => void;
    className?: string;
    dataTestId?: string;
    topActions?: boolean;
    bottomActions?: JSX.Element | null;
    isLoading?: boolean;
}

const NewSlidingPanel = ({
    open,
    showBackgroundMask,
    onClose,
    className,
    dataTestId,
    children,
    topActions = false,
    bottomActions,
    isLoading = false,
}: PropsWithChildren<NewSlidingPanelProps>): JSX.Element => {
    return (
        <>
            <BackgroundMask open={open && showBackgroundMask} onClick={onClose} />
            <aside
                data-testid={dataTestId}
                className={classNames('newSlidingPanel', className, { open })}
                aria-hidden={!open}
            >
                {topActions && (
                    <div className={classNames({ topActions })}>
                        <Button
                            className={classNames('close-button')}
                            type="button"
                            iconOnly
                            icon={CloseIcon}
                            onClick={onClose}
                            dataTestId="close-button"
                        />
                    </div>
                )}
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="content">{children}</div>
                        {!!bottomActions && (
                            <div className={classNames({ bottomActions: !!bottomActions })}>{bottomActions}</div>
                        )}
                    </>
                )}
            </aside>
        </>
    );
};

export default NewSlidingPanel;
