import edit, { CombinedState } from 'features/Resources/Common/EditBooking/Redux/reducer';
import { combineReducers } from 'redux';

import bookings, { BookingsState } from './bookings';
import bookingsByResource, { BookingsByResourcesIdsState } from './bookingsByResource';

interface CombinedResourceState {
    bookings: BookingsState;
    edit: CombinedState;
    bookingsByResource: BookingsByResourcesIdsState;
}

export default combineReducers<CombinedResourceState>({
    edit,
    bookings,
    bookingsByResource,
});
