import React, { createRef, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import privateBookingIcon from 'assets/icons/PrivateBookingIcon.svg';
import repeatIcon from 'assets/icons/repeats-button-icon.svg';
import { createPrimaryButton } from 'components/ActionButtons/Models/ActionButton';
import Dialog from 'components/Dialog';
import DatePicker from 'components/FormElements/DatePicker/DatePickerForHeader';
import { InfoSliderData } from 'components/ResourceInfo/ResourceInfoSlider';
import { DayPilotScheduler } from 'daypilot-pro-react';
import { Permissions } from 'features/Authentication/types';
import {
    BOOKING_GRID_IS_PRIVATE_ICON,
    BOOKING_GRID_REPEAT_ICON,
    BOOKING_SLOT_UNITS_MULTIPLIER,
    BOOKING_STATUS,
    CHECK_IN_STATUS,
    DEFAULT_DATE_FORMAT,
    GRID_HEADER_TWELWE_HOUR_FORMAT,
    GRID_TWELWE_HOUR_FORMAT,
    TIME_COUNT,
    TWENTY_FOUR_HOUR_FORMAT,
    VIEW_TYPE,
} from 'features/constants';
import { SETUP_CLEARDOWN_ADJUSTMENT, SETUP_CLEARDOWN_PRE_RENDER } from 'features/featureFlags';
import InfoSlider from 'features/Resources/Common/InfoSlider/InfoSlider';
import { ConfigurationSettings } from 'features/Resources/Common/Redux/Reducer/configuratioReducer';
import { Dictionary, flatMap, groupBy, keyBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { Configuration } from 'services/ApiClients/Configuration';
import { ExpandedResources, GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import {
    SpecifiedCompanyAttributesQuery,
    SpecifiedCompanyAttributesResponse,
} from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { includesResourceTypeValue, ResourceTypeInterface, UnavailableCategory } from 'services/ApiClients/Resource';
import { ResourcesGS } from 'services/ApiClients/Resource/Models/ResourcesGS';
import ConfigurationService from 'services/ConfigurationService';
import { HasPermissionToEditNotOwnBooking } from 'utilities/authorizationUtils';
import Guid from 'utilities/guid';
import { renderConfirmationDialog } from 'utilities/renderConfirmationDialog';

import {
    BookedResourceSummary,
    UnavailableTime,
    UpdateSingleOccurrence,
    UpdateWholeSeries,
} from '../../services/ApiClients/Booking/Models';

import EventComponent from './Components/EventComponent';
import { useSetupCleardownAdjustment } from './Hooks/useSetupCleardownAdjustment';
import { DraggingBookingType, useSetupCleardownPreRender } from './Hooks/useSetupCleardownPreRender';
import DayPilotArgsMapper from './Mappers/DayPilotArgsMapper';
import mapResourcesForGeographicStructures from './Mappers/ResourceMapper';
import { getSummary } from './Mappers/resourceSummaryMappers';
import { BookingClickedArgs } from './Models/BookingClickedArgs';
import { BookingCreatedArgs } from './Models/BookingCreatedArgs';
import { CellFillTypes, periodUnitEnum } from './Models/CellFillTypes';
import { Event } from './Models/Event';
import { BookingGridResource } from './Models/Resource';
import {
    checkAvailableIcon,
    findResourceRowById,
    getCellFillTypeAreas,
    getCellWidthByUnitSlot,
    UpdateMode,
} from './utils';

import './BookingGrid.scss';

export interface BookingGridProps {
    selectedDate: DateTime;
    resources?: ResourcesGS[];
    bookings?: BookedResourceSummary[];
    events?: Event[];
    resourceTypeSiteConfiguration: Configuration;
    allSiteConfigurations: ConfigurationSettings;
    onBookingCreated: (args: BookingCreatedArgs) => void;
    onBookingClicked: (args: BookingClickedArgs) => void;
    onBookingMoved: (args: any) => void;
    updateSingleOccurrence: (args: UpdateSingleOccurrence) => void;
    setDate: (param: DateTime) => void;
    timezone: string;
    filterPanelOpen: boolean;
    advancedFilters: any;
    availableAreas: GeographicStructureItem[];
    resourceTypes: ResourceTypeInterface[];
    unavailableTimes: Dictionary<UnavailableTime[]>;
    permissions: Permissions;
    userProfileId: string;
    is24HourFormat?: boolean;
    hourFormat?: string;
    setExpandedResources: (expandedResources: ExpandedResources[]) => void;
    expandedResources: ExpandedResources[];
    infoSliderData: InfoSliderData;
    getResourceImage: (imageId: Guid) => void;
    resetResourceImage: (data: object) => object;
    getUsers: (usersIds: string[]) => void;
    getRoles: (usersIds: string[]) => void;
    getAttributes: (param: SpecifiedCompanyAttributesQuery) => void;
    resetUsers: (param: []) => void;
    resetRoles: (param: []) => void;
    resetAttributes: (param: []) => void;
    updateWholeSeries: (param: UpdateWholeSeries) => void;
    tenantCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
}

const BookingGrid = ({
    selectedDate,
    resources = [],
    bookings,
    events,
    resourceTypeSiteConfiguration,
    allSiteConfigurations,
    onBookingCreated,
    onBookingClicked,
    onBookingMoved,
    setDate,
    updateSingleOccurrence,
    timezone,
    filterPanelOpen,
    advancedFilters,
    availableAreas,
    resourceTypes,
    unavailableTimes,
    permissions,
    userProfileId,
    hourFormat = TWENTY_FOUR_HOUR_FORMAT,
    is24HourFormat = true,
    setExpandedResources,
    expandedResources,
    getResourceImage,
    resetResourceImage,
    infoSliderData,
    getUsers,
    getRoles,
    getAttributes,
    resetUsers,
    resetRoles,
    resetAttributes,
    updateWholeSeries,
    tenantCompanyAttributes,
}: BookingGridProps): JSX.Element => {
    const schedulerRef = createRef<DayPilotScheduler>();

    const [mappedResources, setMappedResources] = useState<BookingGridResource[]>([]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogMessage, setDialogMessage] = useState<string>('');
    const [infoSliderResource, setInfoSliderResource] = useState<any | undefined>();
    const [updateMode, setUpdateMode] = useState<UpdateMode | null>(null);

    const { t } = useTranslation();
    const selectedDay = selectedDate.setZone(timezone).toFormat('cccc').toLowerCase();
    const now = DateTime.now().setZone(timezone).toISO({ includeOffset: false });

    const allFlatResources = useMemo(() => flatMap(resources, (x) => x.resources), [resources]);
    const resourcesDictionary = useMemo(() => keyBy(allFlatResources, (x) => x.id.toString()), [allFlatResources]);
    const bookingsByResourcesDictionary = useMemo(() => groupBy(bookings, (x) => x.resourceId.toString()), [bookings]);

    // Multiply the bookingSlotUnits by 5 to obtain the slot duration in minutes
    const slotDuration: number = useMemo(
        () => resourceTypeSiteConfiguration.bookingSlotUnits * BOOKING_SLOT_UNITS_MULTIPLIER,
        [resourceTypeSiteConfiguration.bookingSlotUnits]
    );

    const cellWidth: number = useMemo(() => {
        return getCellWidthByUnitSlot(resourceTypeSiteConfiguration);
    }, [resourceTypeSiteConfiguration.bookingSlotUnits]);

    // @todo: handle partial booking hours via IsBusiness property in BeforeCellRender i.e. 8:30 => 17:30
    // Using floor / ceil as day pilot scheduler only accepts whole start / end business hours
    const dayBookableHours = resourceTypeSiteConfiguration.bookableDays[selectedDay];
    let beginsHour = 0;
    let endsHour = 24;

    if (dayBookableHours) {
        beginsHour = Math.floor(
            resourceTypeSiteConfiguration.bookableDays[selectedDay].bookingWindowStartMinutesFromMidnight / 60
        );
        endsHour = Math.ceil(
            resourceTypeSiteConfiguration.bookableDays[selectedDay].bookingWindowEndMinutesFromMidnight / 60
        );
    }

    const { reDrawDraggingHandle, disposeDraggingHandle, checkDragging } = useSetupCleardownPreRender(
        schedulerRef,
        selectedDate,
        timezone,
        resourcesDictionary,
        unavailableTimes,
        beginsHour,
        endsHour,
        SETUP_CLEARDOWN_PRE_RENDER
    );

    // const { adjust, dispose } = useSetupCleardownAdjustment(
    const { dispose } = useSetupCleardownAdjustment(
        resourceTypeSiteConfiguration,
        selectedDay,
        resourcesDictionary,
        unavailableTimes,
        bookingsByResourcesDictionary,
        slotDuration,
        timezone,
        SETUP_CLEARDOWN_ADJUSTMENT
    );
    useEffect(() => {
        schedulerRef.current?.control.scrollTo(now);
    }, []);

    useEffect(() => {
        setMappedResources(
            mapResourcesForGeographicStructures(
                resources,
                advancedFilters,
                availableAreas,
                resourceTypes,
                expandedResources
            )
        );
    }, [resources, advancedFilters, availableAreas]);

    useEffect(() => {
        schedulerRef.current?.control.show();
    }, [filterPanelOpen]);

    const timeRangeSelecting = (args: any): void => {
        const { start, end, resource } = args;

        // const { start, end, anchor, resource } = args;
        // const [newStart, newEnd] = adjust(start, end, anchor, resource); // causes issue
        const indicatorHourFormat = is24HourFormat ? TWENTY_FOUR_HOUR_FORMAT : GRID_TWELWE_HOUR_FORMAT;

        // eslint-disable-next-line no-param-reassign
        args.left.html = start.toString(indicatorHourFormat);

        // eslint-disable-next-line no-param-reassign
        args.right.html = end.toString(indicatorHourFormat);

        reDrawDraggingHandle(DraggingBookingType.NEW, null, resource, start.toString(), end.toString());

        // eslint-disable-next-line no-param-reassign
        // args.start = newStart;
        // eslint-disable-next-line no-param-reassign
        // args.left.html = newStart.toString(indicatorHourFormat);

        // eslint-disable-next-line no-param-reassign
        // args.end = newEnd;
        // eslint-disable-next-line no-param-reassign
        // args.right.html = newEnd.toString(indicatorHourFormat);

        // reDrawDraggingHandle(DraggingBookingType.NEW, null, resource, newStart.toString(), newEnd.toString());
    };

    const isSlotAvailableIncludesSuCd = (
        args: any,
        resourceId: string,
        startTime: string,
        endTime: string
    ): boolean => {
        const bookingId = args.e?.data?.id?.split(':')[1];
        const res = resources
            .map((geoStr) => geoStr?.resources?.filter((item) => item.id.toString() === resourceId))
            .filter((arr) => arr.length)[0][0];

        if ((res.setupTimeInSeconds || res.cleardownTimeInSeconds) && bookings?.length) {
            const selectedStartTime = DateTime.fromISO(startTime, { zone: timezone });
            const selectedEndTime = DateTime.fromISO(endTime, { zone: timezone });

            const currentResourceBookings = bookings.filter((item) => item.resourceId.toString() === resourceId);

            const descBookings = [...currentResourceBookings].sort(
                (a, b) =>
                    DateTime.fromISO(b.startDateTime).setZone(timezone).toMillis() -
                    DateTime.fromISO(a.startDateTime).setZone(timezone).toMillis()
            );

            const ascBookings = [...currentResourceBookings].sort(
                (a, b) =>
                    DateTime.fromISO(a.startDateTime).setZone(timezone).toMillis() -
                    DateTime.fromISO(b.startDateTime).setZone(timezone).toMillis()
            );

            const previousBooking = descBookings?.find(
                (item) =>
                    DateTime.fromISO(item.endDateTime).setZone(timezone) <= selectedStartTime &&
                    item.bookingId.toString() !== bookingId?.toString()
            );

            const nextBooking = ascBookings?.find(
                (item) =>
                    DateTime.fromISO(item.startDateTime).setZone(timezone) >= selectedEndTime &&
                    item.bookingId.toString() !== bookingId?.toString()
            );

            const bookingEndTime =
                previousBooking &&
                DateTime.fromISO(previousBooking.endDateTime)
                    .setZone(timezone)
                    .plus({ second: res.cleardownTimeInSeconds ? res.cleardownTimeInSeconds : 0 });

            const bookingStartTime =
                nextBooking &&
                DateTime.fromISO(nextBooking.startDateTime)
                    .setZone(timezone)
                    .minus({ second: res.setupTimeInSeconds ? res.setupTimeInSeconds : 0 });

            if (
                res.setupTimeInSeconds &&
                res.cleardownTimeInSeconds &&
                bookingEndTime &&
                selectedStartTime.toSeconds() - bookingEndTime.toSeconds() < res.setupTimeInSeconds &&
                bookingStartTime &&
                bookingStartTime.toSeconds() - selectedEndTime.toSeconds() < res.cleardownTimeInSeconds
            ) {
                args.preventDefault();
                setDialogMessage(t('Booking_NeedSuAndCdCell'));
                setDialogOpen(true);
                return false;
            }

            if (
                res.setupTimeInSeconds &&
                bookingEndTime &&
                selectedStartTime.toSeconds() - bookingEndTime.toSeconds() < res.setupTimeInSeconds
            ) {
                args.preventDefault();
                setDialogMessage(t('Booking_NeedSuCell'));
                setDialogOpen(true);
                return false;
            }

            if (
                res.cleardownTimeInSeconds &&
                bookingStartTime &&
                bookingStartTime.toSeconds() - selectedEndTime.toSeconds() < res.cleardownTimeInSeconds
            ) {
                args.preventDefault();
                setDialogMessage(t('Booking_NeedCdCell'));
                setDialogOpen(true);
                return false;
            }
        }
        return true;
    };

    const timeRangeSelected = (args: any): void => {
        disposeDraggingHandle();
        dispose();

        const { resourceType, restricted } = findResourceRowById(mappedResources, args.resource);

        const nowDateTime = DateTime.now().setZone(timezone);

        const differenceInMinutes =
            (nowDateTime.valueOf() - DateTime.fromISO(args.start.value, { zone: timezone }).valueOf()) / 60000;
        const isCurrentSlot = Math.sign(differenceInMinutes) !== -1 && differenceInMinutes < slotDuration;
        const specificConfiguration = allSiteConfigurations[resourceType];

        if (
            !specificConfiguration.bookableDays[selectedDay] ||
            !ConfigurationService.isRangeAvailable(
                specificConfiguration,
                DateTime.fromISO(args.start.value.toString()),
                DateTime.fromISO(args.end.value.toString()),
                selectedDay
            )
        ) {
            args.preventDefault();
            setDialogMessage(t('Booking_NotAvailableTime'));
            setDialogOpen(true);
            return;
        }

        if (args.start.value < DateTime.now().setZone(timezone).toISO({ includeOffset: false }) && !isCurrentSlot) {
            args.preventDefault();
            setDialogMessage(t('Booking_PastBooking'));
            setDialogOpen(true);
            return;
        }

        if (restricted) {
            args.preventDefault();
            setDialogMessage(t('Booking_Restricted'));
            setDialogOpen(true);
            return;
        }

        const argsStart = DateTime.fromISO(args.start.value, { zone: timezone });
        const argsEnd = DateTime.fromISO(args.end.value, { zone: timezone });

        const item = unavailableTimes[args.resource.toString()]?.find((i) => {
            const itemStartTime = DateTime.fromISO(i.startDateTime).setZone(timezone);
            const itemEndTime = DateTime.fromISO(i.endDateTime).setZone(timezone);

            return (
                ((argsStart >= itemStartTime && argsStart < itemEndTime) ||
                    (argsEnd > itemStartTime && argsEnd <= itemEndTime)) &&
                (i.category === UnavailableCategory.Setup || i.category === UnavailableCategory.Cleardown)
            );
        });

        if (!item) {
            const res = isSlotAvailableIncludesSuCd(args, args.resource.toString(), args.start.value, args.end.value);
            if (!res) {
                return;
            }
        }

        // Clear the current selection so that it doesn't stay selected
        schedulerRef?.current?.control?.clearSelection();
        // @todo: Determine if we are creating a booking or whether this booking overlaps with anything else on the grid
        // If it does overlap we should display some kind of error (toast?)

        const bookingCreatedArgs = DayPilotArgsMapper.MapTimeRangeSelectedToBookingCreated(args, resourceType);

        if (onBookingCreated && bookingCreatedArgs) {
            onBookingCreated(bookingCreatedArgs);
        }
    };

    const toTimeZone = (dateTime: string): string => {
        return DateTime.fromISO(dateTime).setZone(timezone, { keepLocalTime: true }).toUTC().toISO();
    };

    const onBeforeRowHeaderRender = (args: any): void => {
        if (args.row.level < 2) {
            // eslint-disable-next-line no-param-reassign
            args.row.verticalAlignment = 'center';
            return;
        }
        const summary = getSummary(findResourceRowById(mappedResources, args.row.data.id));
        summary.push({ icon: 'info', name: 'info' });

        const iconSize = 16;
        const iconSpacing = 5;
        // NOTE: Despite this not being a best practice, it is how the DayPilot control recommends you update
        // the areas within the row - https://api.daypilot.org/daypilot-scheduler-onbeforerowheaderrender/
        // eslint-disable-next-line no-param-reassign
        args.row.areas = summary.map((item: any, idx: number) => ({
            right: idx * (iconSize + iconSpacing),
            height: iconSize,
            width: iconSize,
            bottom: 0,
            icon: `icon tooltip icon_${checkAvailableIcon(item.icon)}`,
        }));
        // eslint-disable-next-line no-param-reassign
        args.row.verticalAlignment = 'top';
    };

    const onBeforeCornerDomAdd = (args: any): void => {
        // eslint-disable-next-line no-param-reassign
        args.element = (
            <DatePicker
                selectedDate={selectedDate.toUTC()}
                timezone={timezone}
                setDate={setDate}
                viewType={VIEW_TYPE.GRID}
            />
        );
    };

    const onBeforeEventDomAdd = (args: any): void => {
        const { displayName, text } = args.e.data;
        // eslint-disable-next-line no-param-reassign
        args.element = <EventComponent displayName={displayName} text={text} />;
    };

    const handleEventClicked = (args: any): void => {
        if (!args || !args.e || !args.e.data) {
            return;
        }

        const bookingAndResourceId: [Guid, Guid] = args.e.data.id.split(':');
        const bookingId = bookingAndResourceId[1];

        const bookingClickedArgs: BookingClickedArgs = {
            bookingId,
            isPartOfRepeat: args.e.data.isPartOfRepeat,
        };

        if (onBookingClicked) {
            onBookingClicked(bookingClickedArgs);
        }
    };

    const handleEventUpdate = async (args: any): Promise<void> => {
        const nowDateTime = DateTime.now().setZone(timezone);
        const nowDateTimeIso = nowDateTime.toISO();

        const differenceInMinutes =
            (nowDateTime.valueOf() - DateTime.fromISO(args.newStart.value.toString()).valueOf()) / 60000;
        const isCurrentSlot = Math.sign(differenceInMinutes) !== -1 && differenceInMinutes < slotDuration;

        if (args.e.data.bookingStatus === BOOKING_STATUS.CURTAILED && args.e.data.end > nowDateTimeIso) {
            args.preventDefault();
            setDialogMessage(t('Booking_StatusCurtailed'));
            setDialogOpen(true);
            return;
        }

        if (args.e.data.start < nowDateTimeIso && args.e.data.end < nowDateTimeIso) {
            args.preventDefault();
            setDialogMessage(t('Booking_PastBookingDateTime'));
            setDialogOpen(true);
            return;
        }

        if (
            args.e.data.start < nowDateTimeIso &&
            args.e.data.end > nowDateTimeIso &&
            args.newStart.value !== args.e.data.start
        ) {
            args.preventDefault();
            setDialogMessage(t('Booking_InProgressBookingStartDateTime'));
            setDialogOpen(true);
            return;
        }

        if (args.newStart.value < nowDateTimeIso && args.newStart.value !== args.e.data.start && !isCurrentSlot) {
            args.preventDefault();
            setDialogMessage(t('Booking_PastBooking'));
            setDialogOpen(true);
            return;
        }

        const resource = resources
            .map((resourceArray) => resourceArray.resources.find((r) => r.id === args.e.data.resource))
            .find((x) => !!x);

        const hasPermissionToEditBooking = HasPermissionToEditNotOwnBooking(resource?.resourceType, permissions);

        if (!(hasPermissionToEditBooking || userProfileId === args.e.data.createdByUserId)) {
            args.preventDefault();
            setDialogMessage(t('Booking_NoPermission'));
            setDialogOpen(true);
            return;
        }

        let specificConfiguration = resourceTypeSiteConfiguration;
        if (resource) {
            specificConfiguration = allSiteConfigurations[resource.resourceType];
        }

        const startOfDayBookingHours = selectedDate
            .startOf('day')
            .plus({ minutes: specificConfiguration.bookableDays[selectedDay].bookingWindowStartMinutesFromMidnight });
        const endOfDayBookingHours = selectedDate
            .startOf('day')
            .plus({ minutes: specificConfiguration.bookableDays[selectedDay].bookingWindowEndMinutesFromMidnight });

        const bookingWindowStartTime = startOfDayBookingHours.toFormat('HH:mm');
        const bookingWindowEndTime = endOfDayBookingHours.toFormat('HH:mm');
        const dayOfBooking = selectedDate.toFormat('cccc');

        const day = t(`Day_${dayOfBooking}`);

        if (DateTime.fromISO(args.newStart.value).setZone(timezone, { keepLocalTime: true }) < startOfDayBookingHours) {
            args.preventDefault();

            const dialogTranslation = t('Booking_GridStart');
            const message = dialogTranslation.replace('**beforeTime**', bookingWindowStartTime).replace('**day**', day);

            setDialogMessage(message);
            setDialogOpen(true);
            return;
        }

        if (args.newStart.value > endOfDayBookingHours.toISO() || args.newEnd.value > endOfDayBookingHours.toISO()) {
            args.preventDefault();

            const dialogTranslation = t('Booking_GridEnd');
            const message = dialogTranslation.replace('**endTime**', bookingWindowEndTime).replace('**day**', day);

            setDialogMessage(message);
            setDialogOpen(true);
            return;
        }

        const argsStart = DateTime.fromISO(args.newStart.value, { zone: timezone });
        const argsEnd = DateTime.fromISO(args.newEnd.value, { zone: timezone });
        const resourceId = args.newResource?.toString() || args.e.data.resource?.toString();

        const item = unavailableTimes[resourceId]?.find((i) => {
            const itemStartTime = DateTime.fromISO(i.startDateTime).setZone(timezone);
            const itemEndTime = DateTime.fromISO(i.endDateTime).setZone(timezone);
            return (
                ((argsStart >= itemStartTime && argsStart < itemEndTime) ||
                    (argsEnd > itemStartTime && argsEnd <= itemEndTime)) &&
                (i.category === UnavailableCategory.Setup || i.category === UnavailableCategory.Cleardown)
            );
        });

        if (!item) {
            const res = isSlotAvailableIncludesSuCd(
                args,
                args.newResource ? args.newResource.toString() : args.e.data.resource,
                args.newStart.value,
                args.newEnd.value
            );
            if (!res) {
                return;
            }
        }

        // eslint-disable-next-line no-param-reassign
        args.async = true;
        let confirm: boolean | null | void = false;
        const { e } = args;
        const { isPartOfRepeat } = e.data;
        const moveBookingMessage = isPartOfRepeat ? 'Booking_MoveOccurrenceMessage' : 'Booking_MoveMessage';

        confirm = await renderConfirmationDialog(t(moveBookingMessage)).then(async (isConfirmed) => {
            if (isConfirmed && isPartOfRepeat) {
                const isUpdated = await renderConfirmationDialog(
                    t('Booking_Repeat_Message'),
                    t('Booking_Repeat_Description'),
                    t('Booking_Repeat_Occurrence_Button'),
                    t('Booking_Repeat_WholeSeries_Button'),
                    'update-buttons'
                ).then((update) => {
                    if (update) {
                        setUpdateMode(UpdateMode.UPDATE_OCCURRENCE);
                    } else {
                        setUpdateMode(UpdateMode.UPDATE_SERIES);
                    }

                    return true;
                });

                return isUpdated;
            }
            return isConfirmed;
        });

        if (confirm === false) {
            args.preventDefault();
        }

        args.loaded();
    };

    const handleEventMoving = (args: any): void => {
        const { start, end, resource } = args;

        const ids: [Guid, Guid] = args.e.data.id.split(':');

        const bookingId = ids[1].toString();
        reDrawDraggingHandle(DraggingBookingType.EXISTING_MOVE, bookingId, resource, start.toString(), end.toString());
    };

    const handleEventMove = async (args: any): Promise<void> => {
        const ids: [Guid, Guid] = args.e.data.id.split(':');
        const resourceId = ids[0].toString();

        if (!args || !args.e || !args.e.data || !args.e.part) {
            disposeDraggingHandle();
            return;
        }
        const nowDateTime = DateTime.now().setZone(timezone).toISO({ includeOffset: false });

        if (args.e.data.checkInStatus === CHECK_IN_STATUS.CHECKED_IN && args.e.data.end > nowDateTime) {
            args.preventDefault();
            setDialogMessage(t('Booking_CheckedIn'));
            setDialogOpen(true);
            disposeDraggingHandle();
            return;
        }

        const { resourceType } = findResourceRowById(mappedResources, args.newResource);

        const { resourceType: oldResourceType } = findResourceRowById(mappedResources, resourceId);

        if (oldResourceType !== resourceType) {
            args.preventDefault();
            setDialogMessage(t('Toast_Different_Resource_Type'));
            setDialogOpen(true);
            disposeDraggingHandle();
            return;
        }

        if (
            args.e.data.start < nowDateTime &&
            args.e.data.end > nowDateTime &&
            args.e.data.resource !== args.newResource
        ) {
            args.preventDefault();
            setDialogMessage(t('Booking_InProgressBookingNewResource'));
            setDialogOpen(true);
            disposeDraggingHandle();
            return;
        }

        await handleEventUpdate(args);
        disposeDraggingHandle();
    };

    const handleEventResize = async (args: any): Promise<void> => {
        disposeDraggingHandle();

        if (!args || !args.e || !args.e.data || !args.e.part) {
            return;
        }

        if (
            args.e.data.checkInStatus === CHECK_IN_STATUS.CHECKED_IN &&
            args.newStart.value !== args.e.part.start.value
        ) {
            args.preventDefault();
            setDialogMessage(t('Booking_StartTimeStatusCheckedIn'));
            setDialogOpen(true);
            return;
        }

        await handleEventUpdate(args);
    };

    const handleEventResizing = (args: any): void => {
        const { start, end } = args;

        const ids: [Guid, Guid] = args.e.data.id.split(':');
        const bookingId = ids[1].toString();
        const resource = ids[0].toString();

        reDrawDraggingHandle(
            DraggingBookingType.EXISTING_RESIZE,
            bookingId,
            resource,
            start.toString(),
            end.toString()
        );
    };

    const handleEventUpdated = (args: any): void => {
        const bookingAndResourceId: [Guid, Guid] = args.e.data.id.split(':');
        const bookingId = bookingAndResourceId[1];
        const existingResourceId = bookingAndResourceId[0];
        const {
            e: {
                part: {
                    start: { value: existingStartDateTime },
                    end: { value: existingEndDateTime },
                },
            },
            newStart: { value: proposedStartDateTime },
            newEnd: { value: proposedEndDateTime },
        } = args;

        const proposedResourceId = args.newResource ? args.newResource : existingResourceId;

        const { name: existingResourceName } = findResourceRowById(mappedResources, existingResourceId.toString());

        const { name: proposedResourceName } = findResourceRowById(mappedResources, proposedResourceId);

        const movedBooking = bookings?.find((booking) => booking.bookingId.toString() === bookingId.toString());

        if (movedBooking?.isPartOfRepeat) {
            const updateOccurrenceArgs: UpdateSingleOccurrence = {
                bookingId,
                originalOccurrenceTimeRange: {
                    start: toTimeZone(existingStartDateTime),
                    end: toTimeZone(existingEndDateTime),
                },
                updatedOccurrenceTimeRange: {
                    start: toTimeZone(proposedStartDateTime),
                    end: toTimeZone(proposedEndDateTime),
                },
                originalResourceId: movedBooking?.resourceId,
                proposedResourceId,
                createdByUserId: movedBooking?.createdByUserId,
                createdByDisplayName: movedBooking?.createdByDisplayName,
                isConfirmed: true,
                timezone,
            };

            if (updateMode === UpdateMode.UPDATE_OCCURRENCE) {
                updateSingleOccurrence(updateOccurrenceArgs);
            } else {
                updateWholeSeries({
                    bookingId: movedBooking?.repeatBookingId ? movedBooking?.repeatBookingId : bookingId,
                    resources: [
                        {
                            originalResourceId: movedBooking?.resourceId,
                            proposedResourceId,
                            proposedStartDateTime: toTimeZone(proposedStartDateTime),
                            proposedEndDateTime: toTimeZone(proposedEndDateTime),
                        },
                    ],
                    isConfirmed: true,
                });
            }
        } else {
            const bookingMovedArgs = {
                bookingId,
                existingResourceId,
                proposedResourceId,
                existingResourceName,
                proposedResourceName,
                existingStartDateTime: toTimeZone(existingStartDateTime),
                proposedStartDateTime: toTimeZone(proposedStartDateTime),
                proposedEndDateTime: toTimeZone(proposedEndDateTime),
                existingAttendeesCount: movedBooking?.attendeesCount,
                proposedAttendeesCount: movedBooking?.attendeesCount,
                isConfirmed: true,
            };

            onBookingMoved(bookingMovedArgs);
        }
    };

    const handleonRowHeaderClick = (args: any): void => {
        const expandedRes = expandedResources;

        args.row.toggle();

        const { id, expanded } = args.resource.data;

        if (expandedRes.some((el: ExpandedResources) => el.id === id)) {
            expandedRes.forEach((element: any, index: number) => {
                if (element.id === id) {
                    expandedRes[index] = { id, expanded };
                }
            });
        } else {
            expandedRes.push({ id, expanded });
        }

        setExpandedResources(expandedRes);
    };

    const handleOnRowClicked = (args: any): void => {
        if (args.row.level < 2) {
            return;
        }

        setInfoSliderResource({ id: args.row.data.id, geoStructure: args.row.parent().parent() });
    };

    const onInfoSliderClose = (): void => {
        setInfoSliderResource(false);
    };

    const onBeforeGridLineRender = (args: any): void => {
        if (args.type !== 'VerticalLine') {
            return;
        }

        const {
            end: { value },
        } = args;

        if (DateTime.fromISO(value.toString()).setZone(timezone).minute === 0) {
            // eslint-disable-next-line no-param-reassign
            args.cssClass = 'scheduler_vertical_divider';
        }
    };

    const onBeforeTimeHeaderRender = (args: any): void => {
        const end = DateTime.fromISO(args.header.end.value.toString()).setZone(timezone).hour;
        if (end === endsHour) {
            // eslint-disable-next-line no-param-reassign
            args.header.cssClass = 'last_time_header';
        }
    };
    const buttons = [createPrimaryButton('close', 'Ok', () => setDialogOpen(false))];

    const onBeforeCellRender = (args: any): void => {
        // eslint-disable-next-line no-param-reassign
        args.cell.areas = [];

        const dataTestIdClass = `${args.cell.y}-${args.cell.start.getHours()}-${args.cell.start.getMinutes()}`;

        const checkBusinessCell = (): boolean => {
            // TODO: use dictionary here
            const resource = resources
                .map((resourceArray) => resourceArray.resources.find((r) => r.id === args.cell.resource))
                .find((x) => !!x);

            if (args.cell.isParent || !resource) {
                return true;
            }

            const specificConfiguration = allSiteConfigurations[resource.resourceType];

            if (!specificConfiguration.bookableDays[selectedDay]) {
                return false;
            }

            return ConfigurationService.isRangeAvailable(
                specificConfiguration,
                DateTime.fromISO(args.cell.start.value.toString()),
                DateTime.fromISO(args.cell.end.value.toString()),
                selectedDay
            );
        };

        const checkMaxNoticePeriodAvailability = (): boolean => {
            const resource = resources
                .map((resourceArray) => resourceArray.resources.find((r) => r.id === args.cell.resource))
                .find((x) => !!x);

            if (args.cell.isParent || !resource) {
                return false;
            }

            const startTime = DateTime.fromISO(args.cell.start.value.toString(), { zone: timezone });
            const todayDate = DateTime.now().setZone(timezone);

            if (resource.noticePeriodDuration && resource.noticePeriodUnit !== null) {
                return (
                    startTime.startOf('day') >
                    todayDate
                        .plus({ [periodUnitEnum[resource.noticePeriodUnit]]: resource.noticePeriodDuration })
                        .startOf('day')
                );
            }

            const configuration = allSiteConfigurations[resource.resourceType];
            if (configuration.noticePeriodDuration && configuration.noticePeriodUnit !== null) {
                return (
                    startTime.startOf('day') >
                    todayDate
                        .plus({
                            [periodUnitEnum[configuration.noticePeriodUnit]]: configuration.noticePeriodDuration,
                        })
                        .startOf('day')
                );
            }

            return false;
        };

        const resource = resourcesDictionary[args.cell.resource];

        const checkCheckIn = (): boolean => {
            // TODO: memoizing dictionaries (lodash keyBy) and getting by index can improve performance
            const canFillCheckedInCells =
                bookings &&
                bookings.find(
                    (item) =>
                        item.resourceId === args.cell.resource &&
                        item.bookingStatus !== BOOKING_STATUS.CURTAILED &&
                        item.checkedInDateTime &&
                        item.checkedInDateTime < toTimeZone(args.cell.end.value) &&
                        item.startDateTime >= toTimeZone(args.cell.end.value)
                );
            if (canFillCheckedInCells) {
                // eslint-disable-next-line no-param-reassign
                args.cell.areas = [{ cssClass: 'checked-in-cells' }];
                return true;
            }

            return false;
        };

        const checkCellAvailability = (): CellFillTypes | null => {
            // TODO: memoizing dictionaries (lodash keyBy) and getting by index can improve performance

            const canFillUnavailableCells =
                resources &&
                resources.find((resourceArray) =>
                    resourceArray.resources.find((r) => r.id === args.cell.resource && r.unavailableUntilFurtherNotice)
                );
            if (canFillUnavailableCells) {
                return CellFillTypes.Unavailable;
            }

            const resourceUnavailableTimes = unavailableTimes[args.cell.resource] || [];
            for (let i = 0; i < resourceUnavailableTimes.length; i++) {
                const resourceUnavailableTime = resourceUnavailableTimes[i];
                const unavailableStart = DateTime.fromISO(resourceUnavailableTime.startDateTime, { zone: timezone });
                const unavailableEnd = DateTime.fromISO(resourceUnavailableTime.endDateTime, { zone: timezone });

                const startDate = selectedDate.set({
                    hour: args.cell.start.getHours(),
                    minute: args.cell.start.getMinutes(),
                    second: 0,
                    millisecond: 0,
                });
                const endDate = selectedDate.set({
                    hour: args.cell.end.getHours(),
                    minute: args.cell.end.getMinutes(),
                    second: 0,
                    millisecond: 0,
                });

                let resultCellType = CellFillTypes.Unavailable;
                if (
                    resourceUnavailableTime.category &&
                    [UnavailableCategory.Setup, UnavailableCategory.Cleardown].includes(
                        resourceUnavailableTime.category
                    )
                ) {
                    resultCellType = CellFillTypes.SetupCleardown;
                }

                if (
                    Interval.fromDateTimes(unavailableStart, unavailableEnd).contains(startDate) &&
                    startDate < unavailableEnd
                ) {
                    return resultCellType;
                }

                if (
                    Interval.fromDateTimes(unavailableStart, unavailableEnd).contains(endDate) &&
                    endDate > unavailableStart
                ) {
                    return resultCellType;
                }
            }

            return null;
        };

        const checkStyles = (): void => {
            let cellType = checkDragging(resource, args.cell.start, args.cell.end);
            if (!cellType && checkCheckIn()) {
                return;
            }

            const availabilityCellType = checkCellAvailability();
            if (
                availabilityCellType === CellFillTypes.SetupCleardown &&
                cellType === CellFillTypes.SetupCleardownPreRender
            ) {
                cellType = CellFillTypes.SetupCleardownIntersect;
            } else if (availabilityCellType && !cellType) {
                cellType = availabilityCellType;
            }

            const noticePeriodUnavailability = checkMaxNoticePeriodAvailability();
            if (noticePeriodUnavailability) {
                cellType = CellFillTypes.Unavailable;
            }

            if (cellType) {
                const areas = getCellFillTypeAreas(cellType);
                // eslint-disable-next-line no-param-reassign
                args.cell.areas = areas;
            }
        };

        if (!checkBusinessCell()) {
            // eslint-disable-next-line no-param-reassign
            args.cell.business = false;
            // eslint-disable-next-line no-param-reassign
            args.cell.areas = [{ cssClass: 'not-working-hours' }];
            return;
        }

        if (resource?.restricted) {
            // eslint-disable-next-line no-param-reassign
            args.cell.areas = [{ cssClass: 'restricted' }];
        }

        checkCellAvailability();
        checkStyles();
        // eslint-disable-next-line no-param-reassign
        args.cell.cssClass = `dataId-cell-${dataTestIdClass}`;

        if (args.cell.resource.includes('subHeaderCell') && includesResourceTypeValue(Number(args.cell.resource[0]))) {
            // eslint-disable-next-line no-param-reassign
            args.cell.cssClass = `subHeaderCell`;
        }
    };

    const onBeforeEventRender = (args: any): void => {
        const { isPrivate, isPartOfRepeat, start, end } = args.data;

        let isPartOfRepeatAreas: any = [];
        let isPrivateBookingAreas: any = [];

        const startDate = DateTime.fromISO(start).toMillis();
        const endDate = DateTime.fromISO(end).toMillis();

        const isOneCellBooking: boolean =
            (endDate - startDate) / TIME_COUNT.MILLISECONDS_IN_ONE_SECOND / TIME_COUNT.SECONDS_IN_ONE_MINUTE <=
            slotDuration;

        if (isPartOfRepeat) {
            isPartOfRepeatAreas = [
                {
                    left:
                        !isOneCellBooking && isPrivate
                            ? BOOKING_GRID_REPEAT_ICON.LEFT_WITH_PRIVATE_ICON
                            : BOOKING_GRID_REPEAT_ICON.LEFT,
                    bottom: BOOKING_GRID_REPEAT_ICON.BOTTOM,
                    width: BOOKING_GRID_REPEAT_ICON.WIDTH,
                    height: BOOKING_GRID_REPEAT_ICON.HEIGHT,
                    image: repeatIcon,
                    cssClass: 'repeat-icon',
                },
            ];
        }

        if (isPrivate) {
            isPrivateBookingAreas =
                isOneCellBooking && isPartOfRepeat
                    ? []
                    : [
                          {
                              left: BOOKING_GRID_IS_PRIVATE_ICON.LEFT,
                              bottom: BOOKING_GRID_IS_PRIVATE_ICON.BOTTOM,
                              width: BOOKING_GRID_IS_PRIVATE_ICON.WIDTH,
                              height: BOOKING_GRID_IS_PRIVATE_ICON.HEIGHT,
                              image: privateBookingIcon,
                              cssClass: 'private-booking-icon',
                          },
                      ];
        }

        // eslint-disable-next-line no-param-reassign
        args.data.areas = [...isPrivateBookingAreas, ...isPartOfRepeatAreas];
    };

    return (
        <>
            {dialogOpen && <Dialog message={dialogMessage} buttons={buttons} />}
            <div className="bookingGridWrap">
                <DayPilotScheduler
                    startDate={selectedDate.setZone(timezone).toFormat(DEFAULT_DATE_FORMAT)}
                    ref={schedulerRef}
                    businessBeginsHour={beginsHour}
                    businessEndsHour={endsHour}
                    businessWeekends
                    showNonBusiness={false}
                    cellDuration={slotDuration}
                    scale="CellDuration"
                    cellGroupBy="Hour"
                    timeFormat={is24HourFormat ? 'Clock24Hours' : 'Clock12Hours'}
                    timeHeaders={[
                        {
                            groupBy: 'Hour',
                            format: is24HourFormat ? TWENTY_FOUR_HOUR_FORMAT : GRID_HEADER_TWELWE_HOUR_FORMAT,
                        },
                        { groupBy: 'Cell' },
                    ]}
                    resources={mappedResources}
                    events={events}
                    treeEnabled
                    heightSpec="Parent100Pct"
                    hideBorderFor100PctHeight
                    cornerHtml=""
                    loadingLabelVisible={false}
                    eventHoverHandling="Disabled"
                    messageHideAfter={2500}
                    allowMultiSelect={false}
                    autoRefreshEnabled={false}
                    eventResizingStartEndFormat={hourFormat}
                    eventResizingStartEndEnabled
                    eventMovingStartEndFormat={hourFormat}
                    eventMovingStartEndEnabled
                    treePreventParentUsage
                    headerHeight={50}
                    rowHeaderColumnDefaultWidth={296}
                    rowHeaderWidth={296}
                    rowHeaderWidthAutoFit
                    rowMinHeight={30}
                    eventHeight={50}
                    cellWidth={cellWidth}
                    cellWidthMin={cellWidth}
                    cellWidthSpec="Auto"
                    moveBy="Full"
                    eventDeleteHandling="Disabled"
                    eventEditHandling="Disabled"
                    timeRangeSelectingStartEndEnabled
                    onTimeRangeSelecting={timeRangeSelecting}
                    timeRangeSelectedHandling="Enabled"
                    onTimeRangeSelected={timeRangeSelected}
                    onBeforeCornerDomAdd={onBeforeCornerDomAdd}
                    onBeforeRowHeaderRender={onBeforeRowHeaderRender}
                    separators={[{ color: 'red', location: now }]}
                    onBeforeEventDomAdd={onBeforeEventDomAdd}
                    onEventClicked={handleEventClicked}
                    onEventMove={handleEventMove}
                    onEventMoving={handleEventMoving}
                    onEventMoved={handleEventUpdated}
                    onEventResize={handleEventResize}
                    onEventResizing={handleEventResizing}
                    onEventResized={handleEventUpdated}
                    onRowClick={handleonRowHeaderClick}
                    onRowClicked={handleOnRowClicked}
                    showToolTip
                    bubble={undefined}
                    onBeforeGridLineRender={onBeforeGridLineRender}
                    onBeforeTimeHeaderRender={onBeforeTimeHeaderRender}
                    onBeforeCellRender={onBeforeCellRender}
                    onBeforeEventRender={onBeforeEventRender}
                    eventMarginRight={1}
                    eventMarginBottom={1}
                    eventMarginLeft={1}
                />
                <InfoSlider
                    resource={infoSliderResource}
                    resources={resources as ResourcesGS[]}
                    onClose={onInfoSliderClose}
                    title={t('Resource_About_Label')}
                    getResourceImage={getResourceImage}
                    resetResourceImage={resetResourceImage}
                    infoSliderData={infoSliderData}
                    getUsers={getUsers}
                    getRoles={getRoles}
                    getAttributes={getAttributes}
                    resetUsers={resetUsers}
                    resetRoles={resetRoles}
                    resetAttributes={resetAttributes}
                    tenantCompanyAttributes={tenantCompanyAttributes}
                />
            </div>
        </>
    );
};

export default memo(BookingGrid);
