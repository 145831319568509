import { combineReducers } from 'redux';

import history, { HistoryState } from './history';
import info, { InfoState } from './info';

export interface CombinedState {
    info: InfoState;
    history: HistoryState;
}

export default combineReducers<CombinedState>({
    info,
    history,
});
