import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import VisitorBookingComponent from '../../features/VisitorBooking';

const VisitorBooking = (): JSX.Element => {
    return (
        <MainLayout>
            <VisitorBookingComponent />
        </MainLayout>
    );
};

export default VisitorBooking;
