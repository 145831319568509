import { STATIC_FILTERS } from 'features/constants';
import { CustomFilter, Equipment } from 'services/ApiClients/OrganisationStructure';
import { findResourceValueByName } from 'services/ApiClients/Resource';

export const getMultipleFilters = (
    multipleFilters: { [k: string]: CustomFilter } = {},
    onClose: (param: string) => void = () => {}
): any[] => Object.keys(multipleFilters).map((id) => ({ ...multipleFilters[id], onClose, id }));

export const getStaticFilter = (
    key: string,
    isChecked: boolean,
    onClose: (param: boolean) => void = () => {}
): any => ({
    name: STATIC_FILTERS[key].name,
    checked: isChecked,
    onClose,
    id: '',
});

export const getActiveFilters = (filters: any[] = []): any[] => filters.filter((filter) => filter.checked);

export const getActiveMultipleFilters = (multipleFilters: { [k: string]: CustomFilter }): any =>
    Object.keys(multipleFilters)
        .filter((key) => multipleFilters[key].checked)
        .map((filter) => filter);

export const formatMultipleFilters = (
    filters: Equipment[] = [],
    resourceTypes: string[]
): { [key: string]: CustomFilter } =>
    filters
        .filter((filter) => resourceTypes.some((type) => filter.resourceTypes.includes(type)))
        .reduce(
            (acc, { id, name, resourceTypes: types }) => ({
                ...acc,
                [id]: { name, checked: false, types: types.map((type: string) => findResourceValueByName(type)) },
            }),
            {}
        );
