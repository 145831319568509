import React from 'react';
import Select, { OnChangeValue } from 'react-select';
import { ListItem } from 'components/Forms';
import i18n from 'i18n';
import { Language } from 'services/ApiClients/Language/Models';
import { isNil } from 'utilities/ts';

import './LanguageSelector.scss';

interface LanguageSelectorProps {
    value: ListItem;
    availableLanguages: ListItem[];
    onLanguageSelect: (value: Language) => void;
}

const LanguageSelector = ({ value, availableLanguages, onLanguageSelect }: LanguageSelectorProps): JSX.Element => {
    const handleChange = (selected: OnChangeValue<ListItem, false>): void => {
        if (isNil(selected)) {
            return;
        }

        const selectedLanguage = selected as ListItem;
        i18n.changeLanguage(selectedLanguage.value);
        onLanguageSelect({ locale: selectedLanguage.value, displayName: selectedLanguage.label });
    };

    return (
        <div className="languageSelector">
            <label htmlFor="languageSelect">Language</label>
            <Select
                id="languageSelect"
                value={value}
                onChange={handleChange}
                options={availableLanguages}
                className="react-select-container"
                classNamePrefix="react-select"
            />
        </div>
    );
};

export default LanguageSelector;
