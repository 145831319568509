import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import SingleBookingContainer from 'features/Resources/Combined/SingleBooking/SingleBookingContainer';
import { RootState } from 'PortalTypes';
import {
    CancelOrCurtailBookingCommand,
    CheckInOrCheckOutBookingCommand,
    ConfirmCommand,
    UpdateCommand,
} from 'services/ApiClients/Booking';
import { SearchBookedResourcesQuery, UpdateBookedResourceCommand } from 'services/ApiClients/Booking/Models';

import { AbandonSingleBooking } from '../../../../services/ApiClients/Booking/Models/CancelOrCurtailBookingCommand';
import * as actions from '../../Common/EditBooking/Redux/actions/info';
import { getContainerData } from '../../Common/EditBooking/Redux/selectors';
import {
    getBookingsByResourcesIdsAsync,
    resetBookingsByResourcesIds,
    updateBookedResourceForBookingAsync,
} from '../Redux/actions';

const mapStateToProps = (state: RootState): any => ({
    ...getContainerData(state),
});

const dispatchProps = {
    confirmBooking: (bookingRequest: ConfirmCommand) =>
        actions.bookACombinedConfirmFromBookingInfoAsync.request(bookingRequest),
    checkIn: (bookingRequest: CheckInOrCheckOutBookingCommand) => actions.checkInACombinedAsync.request(bookingRequest),
    checkOut: (bookingRequest: CheckInOrCheckOutBookingCommand) =>
        actions.checkOutACombinedAsync.request(bookingRequest),
    cancelPendingBooking: (cancelPendingBooking: CancelOrCurtailBookingCommand) =>
        actions.cancelPendingBookingAsync.request(cancelPendingBooking),
    dismissError: () => actions.combinedBookingDismissError(),
    cancelResource: (bookingRequest: CancelOrCurtailBookingCommand) =>
        actions.cancelACombinedAsync.request(bookingRequest),
    updateDateAndTime: (updateBookedRequest: UpdateBookedResourceCommand) =>
        updateBookedResourceForBookingAsync.request(updateBookedRequest),
    updateBooking: (bookingRequest: UpdateCommand) => actions.updateCombinedBookingAsync.request(bookingRequest),
    handleClose: () => actions.navigateFromInfoPage(),
    resetNotSavedBookingInfo: () => actions.resetNotSavedBookingInfo(),
    abandonBooking: (bookingRequest: AbandonSingleBooking) => actions.abandonBookingAsync.request(bookingRequest),
    getBookingsByResourcesIds: (params: SearchBookedResourcesQuery) => getBookingsByResourcesIdsAsync.request(params),
    resetBookingsByResourcesIds,
};

export type SingleEditProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

export default connect(mapStateToProps, dispatchProps)(SingleBookingContainer);
