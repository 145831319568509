import { DateTime } from 'luxon';

export const getDayInMonthNumber = (date: DateTime): number => {
    let dayInMonthCount = Number(date.toFormat('d')) / 7;
    if (!Number.isInteger(dayInMonthCount)) {
        dayInMonthCount = Math.floor(dayInMonthCount) + 1;
    }
    return dayInMonthCount;
};

export const toTimeZoneLuxon = (dateTime: string, timezone: string): DateTime => {
    return DateTime.fromISO(dateTime).setZone(timezone);
};

export const toTimeZoneString = (dateTime: string, timezone: string, format: string): string => {
    return toTimeZoneLuxon(dateTime, timezone).toFormat(format);
};
