import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { SearchRequestQuery, SearchResponse } from 'services/ApiClients/Search/Models/SearchResponse';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getFloorPlanSearchData = createAsyncAction(
    'GET_FLOOR_PLAN_SEARCH_REQUEST',
    'GET_FLOOR_PLAN_SEARCH_SUCCESS',
    'GET_FLOOR_PLAN_SEARCH_FAILURE'
)<SearchRequestQuery, ApiResponse<SearchResponse>, AxiosError | ApiResponse>();

export const floorPlanSearchLoadStatus = createAction('FLOOR_PLAN_SEARCH_LOAD_STATUS')<string>();

export const resetFloorPlanSearchData = createAction('RESET_FLOOR_PLAN_SEARCH_DATA')();

export const setSearchResultItem = createAction('SET_SEARCH_RESULT_ITEM')<SearchResponse | null>();

export const setSearchTrigger = createAction('SET_SEARCH_TRIGGER')<boolean>();

export default {
    getFloorPlanSearchData,
    floorPlanSearchLoadStatus,
    resetFloorPlanSearchData,
    setSearchResultItem,
    setSearchTrigger,
};
