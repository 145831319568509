import { FunctionComponent } from 'react';

export default interface Tab {
    id: string;
    icon: FunctionComponent | null;
    title: string | null;
    dataTestId: string;
    content: JSX.Element;
    tabButton?: JSX.Element;
}

export const createTab = (
    id: string,
    title: string | null,
    dataTestId: string,
    icon: FunctionComponent | null,
    content: JSX.Element,
    tabButton?: JSX.Element
): Tab => ({
    id,
    title,
    dataTestId,
    icon,
    content,
    tabButton,
});
