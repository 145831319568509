import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from 'components/ActionButtons/Models/ActionButton';
import DateTimePickerValue from 'components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import PromptDialog from 'components/PromptDialog/PromptDialog';
import Tab from 'components/Tabs/Models/Tab';
import { BOOKING_STATUS, CHECK_IN_STATUS, INITIAL_BOOKING_DATA_STATE, LOAD_STATUSES } from 'features/constants';
// eslint-disable-next-line import/no-cycle
import { SingleEditProps } from 'features/Resources/Combined/SingleBooking';
import { getIsShowCheckInOutBookingButtons } from 'features/Resources/Common/EditBooking/BookingInfo/utils';
import Editor from 'features/Resources/Common/EditBooking/Editor';
import { BookingInfoDataState } from 'features/Resources/Common/EditBooking/Editor/Models/BookingInfoDataState';
import BookMode from 'features/Resources/Common/Models/BookMode';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { debounce } from 'lodash';
import { Interval } from 'luxon';
import { SearchBookedResourcesQuery } from 'services/ApiClients/Booking';
import { renderConfirmationDialog } from 'utilities/renderConfirmationDialog';
import { isNil } from 'utilities/ts';

import { getOnBehalfOfDetails, singleBookingButtons, singleBookingTabs } from './utils';

import '../../Common/EditBooking/Editor/Editor.scss';

const SingleBookingContainer = ({
    now,
    operationInProgress,
    isCurrentUserBooking,
    canAddOnBehalfOfUser,
    canEditNotOwnBooking,
    canEditPastBooking,
    isOnBehalfOfUser,
    areaName,
    timezone,
    bookingInfo,
    hasUpdateError,
    isRepeatsEnabled,
    cancelResource,
    checkIn,
    checkOut,
    cancelPendingBooking,
    dismissError,
    updateDateAndTime,
    confirmBooking,
    updateBooking,
    handleClose,
    abandonBooking,
    resetNotSavedBookingInfo,
    resourceTypeTenantConfiguration,
    resourceTypeSiteConfiguration,
    getBookingsByResourcesIds,
    areaId,
    selectedDate,
    resetBookingsByResourcesIds,
    bookingsByResource,
    bookingsByResourceLoadStatus,
}: SingleEditProps): JSX.Element => {
    const { t } = useTranslation();
    const [showPromptDialog, setShowPromptDialog] = useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showPromptDialog);
    const [bookingInfoDataState, setBookingInfoDataState] = useState<BookingInfoDataState>(INITIAL_BOOKING_DATA_STATE);

    const {
        bookingId,
        resourceId,
        resourceName,
        dateTime,
        bookingStatus,
        checkInStatus,
        resourceType,
        bookMode,
        onBehalfOfPerson,
    } = bookingInfo;

    const earlyCheckedInBookingStartTime = useMemo(
        () => dateTime.startDateTime.minus({ minutes: 30 }),
        [dateTime.startDateTime]
    );
    const isEarlyCheckedInBooking = useMemo(
        () => Interval.fromDateTimes(earlyCheckedInBookingStartTime, dateTime.startDateTime).contains(now),
        [now, dateTime.startDateTime, earlyCheckedInBookingStartTime]
    );
    const isBookingInProgress = useMemo(
        () =>
            Interval.fromDateTimes(
                dateTime.startDateTime,
                dateTime.endDateTime.plus({ seconds: bookingInfo?.cleardownTimeInSeconds || 0 })
            ).contains(now),
        [now, dateTime]
    );

    const isRepeatBookingsEnabled = useMemo(
        () =>
            resourceTypeTenantConfiguration?.isRepeatBookingsEnabled &&
            resourceTypeSiteConfiguration?.isRepeatBookingsEnabled,
        [resourceTypeTenantConfiguration, resourceTypeSiteConfiguration]
    );

    const isShowCheckInOutButtons = useMemo(
        () =>
            getIsShowCheckInOutBookingButtons(
                isEarlyCheckedInBooking,
                isBookingInProgress,
                bookingsByResource,
                now,
                timezone,
                bookingId,
                dateTime.startDateTime
            ),
        [bookingsByResource, bookingInfo, now]
    );

    useEffect(() => {
        setShowPromptDialog(
            bookingStatus === BOOKING_STATUS.UPDATE_PENDING || bookingStatus === BOOKING_STATUS.PENDING
        );
    }, [bookingStatus]);

    useLayoutEffect(() => {
        setBookingInfoDataState(INITIAL_BOOKING_DATA_STATE);
        return () => {
            resetNotSavedBookingInfo();
        };
    }, []);

    useEffect(() => {
        const getBookingsByResourcesIdsQuery: SearchBookedResourcesQuery = {
            geographicStructureIds: [areaId],
            fromDateTime: selectedDate.startOf('day').toUTC().toISO(),
            toDateTime: selectedDate.endOf('day').toUTC().toISO(),
            resourcesIds: [resourceId],
        };

        if (resourceId && areaId && (isEarlyCheckedInBooking || isBookingInProgress)) {
            getBookingsByResourcesIds(getBookingsByResourcesIdsQuery);
        }
        return () => {
            resetBookingsByResourcesIds();
        };
    }, [resourceId, areaId]);

    const handleCancel = async (): Promise<void> => {
        setShowPromptDialog(false);
        const confirm = await renderConfirmationDialog(t('Booking_CancelConfirmation'));
        if (confirm) {
            cancelResource({
                bookingId,
            });
        }
    };

    const handleCancelPendingBooking = async (): Promise<void> => {
        setShowPromptDialog(false);
        const confirm = await renderConfirmationDialog(t('Booking_CancelConfirmation'));
        if (confirm) {
            cancelPendingBooking({
                bookingId,
            });
        }
    };

    const handleDateTimeChange = (value: DateTimePickerValue): void => {
        updateDateAndTime({
            bookingId,
            existingResourceId: resourceId,
            proposedResourceId: resourceId,
            existingResourceName: resourceName,
            proposedResourceName: resourceName,
            isConfirmed: false,
            existingStartDateTime: dateTime?.startDateTime.setZone(timezone).toUTC().toISO(),
            proposedStartDateTime: value.startDateTime.setZone(timezone).toUTC().toISO(),
            proposedEndDateTime: value.endDateTime.setZone(timezone).toUTC().toISO(),
        });
    };

    const handleCheckIn = (): void => {
        checkIn({
            bookingId,
            resourceId,
        });
    };

    const handleCheckOut = (): void => {
        checkOut({
            bookingId,
            resourceId,
        });
    };

    const handleExitWithoutSaving = async (): Promise<void> => {
        setShowPromptDialog(false);
        const confirm = await renderConfirmationDialog(t('Booking_LeavePage'), t('Booking_LeavePage_Description'));
        if (confirm) {
            abandonBooking({ abandonData: { bookingId, resourceId } });
        }
    };

    const handleExitWithPromptDialog = (): void => {
        confirmNavigation();
        abandonBooking({ abandonData: { bookingId, resourceId }, navigateTo: true });
    };

    const renderPromptDialog = useMemo(() => {
        let message = '';
        let description = '';
        let confirm = confirmNavigation;

        if (bookingStatus === BOOKING_STATUS.UPDATE_PENDING) {
            message = t('Booking_LeavePage');
            description = t('Booking_LeavePage_Description');
            confirm = handleExitWithPromptDialog;
        }

        if (bookingStatus === BOOKING_STATUS.PENDING) {
            message = t('Booking_LeavePendingStatusPage');
            description = t('Booking_LeavePendingStatusPage_Description');
        }

        return (
            <PromptDialog
                message={message}
                descriptionMessage={description}
                showDialog={showPrompt}
                confirmNavigation={confirm}
                cancelNavigation={cancelNavigation}
            />
        );
    }, [bookingStatus, showPrompt]);

    const debounceSetBookingInfoDataState = debounce((bookingInfoData) => {
        setBookingInfoDataState(bookingInfoData);
    }, 400);

    const handleSetBookingInfoDataState = useCallback(
        (bookingInfoData: BookingInfoDataState, debounceTrigger = false): void => {
            if (debounceTrigger) {
                debounceSetBookingInfoDataState(bookingInfoData);
            } else {
                setBookingInfoDataState(bookingInfoData);
            }
        },
        []
    );

    const tabs: Tab[] = useMemo(
        () =>
            singleBookingTabs(
                bookingInfo,
                resourceType,
                isRepeatsEnabled,
                handleDateTimeChange,
                handleSetBookingInfoDataState,
                bookingInfoDataState,
                isRepeatBookingsEnabled
            ),
        [bookingInfo.dateTime, bookingInfoDataState, isRepeatBookingsEnabled]
    );

    const handleSubmit = (values: any): void => {
        setShowPromptDialog(false);
        const onBehalfOfDetails = getOnBehalfOfDetails(bookingInfoDataState.selectedPerson, onBehalfOfPerson);

        const bookingData = {
            bookingId,
            displayName: values.displayName,
            notes: values.notes,
            attendeesCount: values.attendeesCount,
            onBehalfOfDetails,
            isPrivate: values.isPrivate,
        };

        if (bookMode === BookMode.Create || bookMode === BookMode.UpdatePending) {
            confirmBooking({
                bookingData: { ...bookingData, bookingStatus: bookMode },
            });
        } else if (bookMode === BookMode.Edit) {
            if (
                ((values.dateTime?.startDateTime?.equals(dateTime.startDateTime) &&
                    values.dateTime?.endDateTime?.equals(dateTime.endDateTime)) ||
                    checkInStatus === CHECK_IN_STATUS.CHECKED_OUT) &&
                isNil(bookingData.attendeesCount) &&
                isNil(bookingData.notes) &&
                isNil(bookingData.onBehalfOfDetails) &&
                isNil(bookingData.displayName) &&
                isNil(bookingData.isPrivate)
            ) {
                handleClose();
            } else {
                updateBooking({ bookingData });
            }
        }
    };

    const buttons: ActionButton[] = useMemo(() => {
        if (bookingsByResourceLoadStatus === LOAD_STATUSES.LOADING) {
            return [];
        }
        return singleBookingButtons(
            bookMode,
            bookingStatus,
            now,
            dateTime.startDateTime,
            dateTime.endDateTime,
            operationInProgress,
            isCurrentUserBooking,
            isOnBehalfOfUser,
            canAddOnBehalfOfUser,
            canEditNotOwnBooking,
            canEditPastBooking,
            checkInStatus,
            isEarlyCheckedInBooking,
            handleCancel,
            handleCancelPendingBooking,
            handleCheckIn,
            handleCheckOut,
            handleClose,
            handleExitWithoutSaving,
            isShowCheckInOutButtons
        );
    }, [operationInProgress, checkInStatus, bookMode, dateTime, bookingsByResourceLoadStatus]);

    return (
        <>
            {showPrompt && renderPromptDialog}
            <Editor
                operationInProgress={operationInProgress}
                tabs={tabs}
                actionButtons={buttons}
                dismissError={dismissError}
                hasUpdateError={hasUpdateError}
                areaName={areaName}
                bookingInfo={bookingInfo}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default SingleBookingContainer;
