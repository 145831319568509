import { connect } from 'react-redux';
import { DEFAULT_TIMEZONE } from 'features/constants';
// eslint-disable-next-line import/no-cycle
import { ResourceInfoSliderState } from 'features/Resources/Common/InfoSlider/InfoSlider';
import { ConfigurationSettings } from 'features/Resources/Common/Redux/Reducer/configuratioReducer';
import { RootState } from 'PortalTypes';

// eslint-disable-next-line import/no-cycle
import InfoSliderTimeLine from './InfoSliderTimeLine';

interface PassedProps {
    resource: ResourceInfoSliderState;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps: PassedProps) => ({
    allSiteConfigurations: state.commonResources.configuration.siteSettings as ConfigurationSettings,
    allTenantConfigurations: state.commonResources.configuration.tenantSettings as ConfigurationSettings,
    selectedDate: state.filters.combined.selectedDate,
    timezone: state?.filters?.global?.site?.timezone || DEFAULT_TIMEZONE,
    currentResourceBookings: state.combined.bookingsByResource.items,
    is24HoursTimeFormat: state.authentication.user?.is_24h_timeFormat,
    user: state.authentication.user,
    ...ownProps,
});

export type InfoSliderTimeLineProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(InfoSliderTimeLine);
