import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { MyBookingCommand } from 'services/ApiClients/Booking/Models';
import { MyBooking } from 'services/ApiClients/Booking/Models/MyBooking';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { SiteVisit } from '../../../services/ApiClients/VisitorManagement/Models/SiteVisit';

export const getMyBookingsAsync = createAsyncAction(
    'MY_BOOKINGS_REQUEST',
    'MY_BOOKINGS_SUCCESS',
    'MY_BOOKINGS_FAILURE'
)<MyBookingCommand, ApiResponse<MyBooking[]>, AxiosError | ApiResponse>();

export const getMySiteVisitsAsync = createAsyncAction(
    'MY_SITE_VISITS_REQUEST',
    'MY_SITE_VISITS_SUCCESS',
    'MY_SITE_VISITS_FAILURE'
)<MyBookingCommand, ApiResponse<{ siteVisits: SiteVisit[] }>, AxiosError | ApiResponse<{ siteVisits: SiteVisit[] }>>();

export const setMyBookingsLoadStatus = createAction('SET_MY_BOOKINGS_LOAD_STATUS')<string>();

export const setMySiteVisitsLoadStatus = createAction('SET_MY_BOOKINGS_LOAD_STATUS')<string>();

export default {
    getMyBookingsAsync,
    getMySiteVisitsAsync,
    setMyBookingsLoadStatus,
    setMySiteVisitsLoadStatus,
};
