import { DateTime } from 'luxon';
import { BookedResource, Booking } from 'services/ApiClients/Booking';
import { Occurrence } from 'services/ApiClients/Booking/Models';

import { getBookMode } from '../Models/BookMode';

import { formatDateTimePickerValue, formatRepeatScheduleRange } from './BookingInfo/utils';

export const formatFormValues = (
    combinedBooking: Booking,
    bookedResource: BookedResource,
    occurrence: Occurrence | undefined,
    timezone: string,
    selectedDate: DateTime
): any => {
    const dateTime = formatDateTimePickerValue(combinedBooking, timezone, selectedDate);
    return {
        bookingId: combinedBooking?.id,
        bookMode: getBookMode(combinedBooking?.bookingStatus),
        isPrivate: combinedBooking?.isPrivate,
        resourceId: bookedResource?.resourceId,
        resourceType: bookedResource?.resourceType,
        resourceName: bookedResource?.displayName,
        dateTime,
        displayName: bookedResource?.title || combinedBooking?.displayName,
        notes: bookedResource?.notes || combinedBooking?.notes,
        bookingStatus: combinedBooking?.bookingStatus,
        checkInStatus: occurrence?.checkInStatus || bookedResource?.checkInStatus,
        createdByUserId: combinedBooking?.createdByUserId,
        createdByDisplayName: combinedBooking?.createdByDisplayName,
        attendeesCount: bookedResource?.attendeesCount || combinedBooking?.attendeesCount || 1,
        repeatBookingId: combinedBooking?.repeatBookingId,
        repeatSchedule: formatRepeatScheduleRange(combinedBooking?.repeatSchedule),
        bookingDateTime: {
            startDateTime: DateTime.fromISO(combinedBooking?.startDateTime).setZone(timezone),
            endDateTime: DateTime.fromISO(combinedBooking?.endDateTime).setZone(timezone),
        },
        onBehalfOfPerson: combinedBooking?.onBehalfOf,
    };
};

export const fortmatFormValuesForSeries = (
    combinedBooking: Booking,
    bookedResource: BookedResource,
    timezone: string,
    selectedDate: DateTime
): any => {
    const bookingDateTime = formatDateTimePickerValue(combinedBooking, timezone, selectedDate);
    return {
        bookingId: combinedBooking?.id,
        resourceType: bookedResource?.resourceType,
        displayName: bookedResource?.title || combinedBooking?.displayName,
        notes: bookedResource?.notes || combinedBooking?.notes,
        attendeesCount: bookedResource?.attendeesCount || combinedBooking?.attendeesCount || 1,
        repeatSchedule: formatRepeatScheduleRange(combinedBooking?.repeatSchedule),
        bookingDateTime,
        onBehalfOfPerson: combinedBooking?.onBehalfOf,
    };
};
