import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

// eslint-disable-next-line import/no-cycle
import AppRoutes from './Routes';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    reportsPermissions: state.authentication.user?.permissions?.ReportsPermissions || 0,
    tenantInfo: state.referenceData.tenantInfo,
    selectedSite: state.filters.global.site,
});

export type RoutesProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(AppRoutes);
