import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as LeftArrow } from 'assets/icons/ArrowLeft.svg';
import { ReactComponent as RightArrow } from 'assets/icons/ArrowRight.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/datePickerMonthlyCalendar.svg';
import { ReactComponent as TodaysDateIcon } from 'assets/icons/datePickerMonthlyEmptyToday.svg';
import classnames from 'classnames';
import Button from 'components/FormElements/Button';
import DatePickerComponent from 'components/FormElements/DatePicker';
import * as constants from 'features/constants';
import { CUSTOM_DURATION } from 'features/constants';
import { FloorPlanTimelineFilterState } from 'features/Filters/Redux/reducer';
import { DateTime } from 'luxon';
import DateTimeService from 'services/DateTimeService';

// This component is being used outside the context of the redux Provider hence why the
// store needs to be accessed directly. This is a limitation of DayPilot as there is a need to add this component
// to the top left hand corner of the grid
import './DatePicker.scss';

export interface DatePickerForHeaderProps {
    timezone: string;
    showTimezone?: boolean;
    viewType?: constants.VIEW_TYPE;
    selectedDate: DateTime;
    setDate: (param: DateTime) => void;
    floorPlanTimelineFilter: FloorPlanTimelineFilterState;
    setFloorPlanTimeLineFilter: (param: any) => void;
    isSelectedDateBookable?: (param: DateTime) => boolean;
}

const datePickerEmptyIconId = 'date-picker-icon-text';

const DatePickerForHeader = ({
    timezone,
    showTimezone = true,
    selectedDate,
    setDate,
    viewType,
    floorPlanTimelineFilter,
    setFloorPlanTimeLineFilter,
    isSelectedDateBookable,
}: DatePickerForHeaderProps): JSX.Element => {
    const bodyElement: HTMLBodyElement | null = document.querySelector('body');
    const modalRootElement = document.createElement('div');

    const initialDate = useMemo(() => {
        return DateTimeService.isSelectedDateBookable(DateTime.utc())
            ? DateTime.utc()
            : DateTimeService.getNextBookableDate(DateTime.utc());
    }, []);

    const date = selectedDate.setZone(timezone) || initialDate.setZone(timezone);

    const [panelOpen, setPanelOpen] = useState<boolean>(false);

    useEffect(() => {
        const datePickerEmptyIcon = document.getElementById(datePickerEmptyIconId);
        if (datePickerEmptyIcon) {
            datePickerEmptyIcon.textContent = initialDate.setZone(timezone).toFormat('dd');
        }
    });

    const onCalendarIconClick = (): void => {
        setPanelOpen(!panelOpen);
    };

    const setSelectedDate = (newDate: DateTime): void => {
        setDate(newDate);
    };

    const setCustomTimeLine = (newDate: DateTime): void => {
        if (floorPlanTimelineFilter?.duration === CUSTOM_DURATION) {
            setFloorPlanTimeLineFilter({
                startDateTime: floorPlanTimelineFilter.timeLine.startDateTime.set({
                    month: newDate.month,
                    day: newDate.day,
                }),
                endDateTime: floorPlanTimelineFilter.timeLine.endDateTime.set({
                    month: newDate.month,
                    day:
                        `${floorPlanTimelineFilter.timeLine.endDateTime.hour}:${floorPlanTimelineFilter.timeLine.endDateTime.minute}` !==
                        '0:0'
                            ? newDate.day
                            : newDate.day + 1,
                }),
            });
        }
    };

    const onSwitchToCurrentDateClick = (): void => {
        if (DateTimeService.isSelectedDateBookable(DateTime.utc())) {
            setSelectedDate(DateTime.utc());
            setCustomTimeLine(DateTime.utc());
            return;
        }

        const nextDate = DateTimeService.getNextBookableDate(DateTime.utc());
        setSelectedDate(nextDate);
        setCustomTimeLine(nextDate);
    };

    const handleDone = (newDate: DateTime): void => {
        setDate(newDate);
        setCustomTimeLine(newDate);
        setPanelOpen(!panelOpen);
    };

    useEffect(() => {
        bodyElement?.appendChild(modalRootElement);

        return () => {
            bodyElement?.removeChild(modalRootElement);
        };
    }, [bodyElement, modalRootElement]);

    const handlePreviousDayClick = (): void => {
        const previousDate = DateTimeService.getPreviousBookableDate(date);
        setSelectedDate(previousDate);
        setCustomTimeLine(previousDate);
    };

    const handleNextDayClick = (): void => {
        const nextDate = DateTimeService.getNextBookableDate(date);
        setSelectedDate(nextDate);
        setCustomTimeLine(nextDate);
    };

    return (
        <>
            <div
                className={classnames('booking__datePicker', {
                    'booking__datePicker--floorplan': viewType === constants.VIEW_TYPE.FLOOR_PLAN,
                })}
            >
                <div className="dateWrapper" data-testid="date-label">
                    <span className="dayName">{selectedDate.setZone(timezone).toFormat('cccc')}</span>
                    <div className="month-n-btns-row">
                        <Button
                            className="chevron"
                            type="button"
                            text="navigate to previous day"
                            onClick={handlePreviousDayClick}
                            dataTestId="bookingGrid-navigateToPreviousDay"
                            icon={LeftArrow}
                            iconOnly
                        />

                        <div className="month"> {selectedDate.setZone(timezone).toFormat('MMM dd')} </div>

                        <Button
                            className="chevron"
                            type="button"
                            text="navigate to next day"
                            onClick={handleNextDayClick}
                            dataTestId="bookingGrid-navigateToNextDay"
                            icon={RightArrow}
                            iconOnly
                        />
                        <Button
                            className="calendar"
                            type="button"
                            text="calendar"
                            onClick={onCalendarIconClick}
                            dataTestId="datePicker-open"
                            icon={CalendarIcon}
                            iconOnly
                        />
                        {!selectedDate
                            .setZone(timezone)
                            .startOf('day')
                            .equals(DateTime.utc().setZone(timezone).startOf('day')) && (
                            <Button
                                className="calendar today"
                                type="button"
                                text="calendar"
                                onClick={onSwitchToCurrentDateClick}
                                dataTestId="datePicker-currentDate"
                                icon={TodaysDateIcon}
                                iconOnly
                            />
                        )}
                    </div>
                    {showTimezone && <span className="timezone">{timezone}</span>}
                </div>
            </div>
            {panelOpen &&
                createPortal(
                    <DatePickerComponent
                        title="Date"
                        value={selectedDate}
                        isOpen={panelOpen}
                        onDone={handleDone}
                        onClose={onCalendarIconClick}
                        isSelectedDateBookable={isSelectedDateBookable}
                        timezone={timezone}
                    />,
                    modalRootElement
                )}
        </>
    );
};

export default DatePickerForHeader;
