import React from 'react';
import MainLayout from 'layouts/MainLayout/MainLayout';

import LoginsReport from '../../features/Reports/LoginsReport';

const Logins = (): JSX.Element => {
    return (
        <MainLayout>
            <LoginsReport />
        </MainLayout>
    );
};

export default Logins;
