import React from 'react';
import classNames from 'classnames';

import './FormGroup.scss';

export interface FormGroupProps {
    disabled?: boolean;
}

const FormGroup = ({ disabled = false, children }: React.PropsWithChildren<FormGroupProps>): JSX.Element => (
    <fieldset className={classNames('formGroup', { disabled })} disabled={disabled}>
        {children}
    </fieldset>
);

export default FormGroup;
