import {
    getSingleBookingDetailsAsync,
    navigateFromInfoPage,
} from 'features/Resources/Common/EditBooking/Redux/actions/info';
import { handleCreateBookingError, mapResources } from 'features/Resources/Common/utils';
import i18n from 'i18n';
import { RootEpic } from 'PortalTypes';
import { push } from 'redux-first-history';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import ToastService from '../../../../services/ToastService';

import * as actions from './actions';
import { SubstringUnitSlotError } from './utils';

export const getCombinedResourcesEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getResources.request)),
        switchMap(({ payload }) => {
            const { geographicStructureIds, resourceTypes } = payload;
            const { user } = state$.value.authentication;
            return from(api.resource.getResourcesByGeographicStructureId(geographicStructureIds, resourceTypes)).pipe(
                map((response) =>
                    actions.getResources.success(
                        mapResources(
                            response,
                            geographicStructureIds,
                            user?.userProfileId,
                            user?.['cb-roles'],
                            user?.companyAttributes
                        )
                    )
                )
            );
        })
    );

export const getCombinedBookingsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getResourcesBookingsAsync.request)),
        switchMap(({ payload }) =>
            from(api.booking.search(payload)).pipe(
                map((response) => actions.getResourcesBookingsAsync.success(response)),
                catchError((error) => of(actions.getResourcesBookingsAsync.failure(error)))
            )
        )
    );

export const getCombinedBookingsByResourcesIdsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.getBookingsByResourcesIdsAsync.request)),
        switchMap(({ payload }) =>
            from(api.booking.search(payload)).pipe(
                map((response) => actions.getBookingsByResourcesIdsAsync.success(response)),
                catchError((error) => of(actions.getBookingsByResourcesIdsAsync.failure(error)))
            )
        )
    );

export const bookAResourceEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(actions.bookAResourceAsync.request)),
        switchMap((action) =>
            from(api.booking.create(action.payload)).pipe(
                concatMap((response) => [
                    actions.bookAResourceAsync.success(response),
                    push({ pathname: `/bookings/${action.payload.bookingId.toString()}` }),
                ]),
                catchError((error) => {
                    const errorTitle = error.response.data.title;
                    const bookingSlotMinutes = errorTitle.substring(
                        errorTitle.length - SubstringUnitSlotError.START,
                        errorTitle.length - SubstringUnitSlotError.END
                    );

                    handleCreateBookingError(error, bookingSlotMinutes);
                    return of(actions.bookAResourceAsync.failure(error));
                })
            )
        )
    );

export const updateBookedResourceFromGridEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.updateBookedResourceAsync.request)),
        switchMap((action) =>
            from(api.booking.updateBookedResource(action.payload)).pipe(
                map((payload) => {
                    // temporary, while do not support multi resources. Not show two messages
                    if (!action.payload?.isBookingInfoUpdate) {
                        ToastService.Success({ message: 'Toast_BookingUpdated' });
                    }
                    return actions.updateBookedResourceAsync.success(payload);
                }),
                catchError((error) => {
                    if (error.response.status === 409) {
                        ToastService.Error({ message: 'Toast_BookingSlotNoLongerAvailable' });
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('ConcurrentBookingException')
                    ) {
                        ToastService.Warning({ message: i18n.t('Toast_CreateBookingOnConcurrentBookingException') });
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('BookingTimeExceedsMaxNoticePeriodException')
                    ) {
                        ToastService.Warning({ message: i18n.t('Toast_UpdateErrorExceedsMaxNoticePeriod') });
                    } else if (
                        error.response.status === 400 &&
                        error.response.data.type
                            .split('.')
                            .includes('BookingTimeDoesNotMatchWithTimeSlotConfigurationException')
                    ) {
                        const errorTitle = error.response.data.title;
                        const bookingSlotMinutes = errorTitle.substring(
                            errorTitle.length - SubstringUnitSlotError.START,
                            errorTitle.length - SubstringUnitSlotError.END
                        );
                        ToastService.Error({
                            message: i18n.t('Toast_ErrorMinBookingSlotUnit', { bookingSlotMinutes }),
                        });
                    } else {
                        ToastService.Error({ message: 'Toast_BookingFailedToUpdate' });
                    }
                    return of(actions.updateBookedResourceAsync.failure(error));
                })
            )
        )
    );
};

export const getSingleBookingDetailsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getSingleBookingDetailsAsync.request)),
        switchMap((action) =>
            from(api.booking.getSingle(action.payload)).pipe(
                map((payload) => getSingleBookingDetailsAsync.success(payload)),
                catchError((error) => of(getSingleBookingDetailsAsync.failure(error)))
            )
        )
    );

export const updateBookedResourceFromBookingInfoEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.updateBookedResourceForBookingAsync.request)),
        switchMap((action) =>
            from(api.booking.updateBookedResource(action.payload)).pipe(
                mergeMap((payload) => {
                    return [
                        actions.updateBookedResourceForBookingAsync.success(payload),
                        getSingleBookingDetailsAsync.request({
                            bookingId: action.payload.bookingId,
                        }),
                    ];
                }),
                catchError((error) => {
                    let message;
                    if (error.response.status === 409) {
                        message = i18n.t('Error_SelectedTimeUnavailable');
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('PastBookingException')
                    ) {
                        message = i18n.t('Error_SelectedPastTime');
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('BookingTimeExceedsMaxNoticePeriodException')
                    ) {
                        message = i18n.t('Toast_CreateBookingOnMaxNoticePeriodException');
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('ConcurrentBookingException')
                    ) {
                        message = i18n.t('Toast_CreateBookingOnConcurrentBookingException');
                    } else {
                        message = i18n.t('Toast_BookingFailedToUpdate');
                    }
                    ToastService.Warning({ message });
                    return of(actions.updateBookedResourceForBookingAsync.failure(message));
                })
            )
        )
    );
};

export const updateSingleOccurrenceFromGridEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.updateSingleOccurrenceAsync.request)),
        switchMap((action) =>
            from(api.booking.updateSingleOccurrence(action.payload)).pipe(
                map((payload) => {
                    return actions.updateSingleOccurrenceAsync.success(payload);
                }),
                catchError((error) => {
                    if (error.response.status === 409) {
                        ToastService.Error({ message: 'Toast_BookingSlotNoLongerAvailable' });
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('ConcurrentBookingException')
                    ) {
                        ToastService.Warning({ message: 'Toast_CreateBookingOnConcurrentBookingException' });
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('BookingTimeExceedsMaxNoticePeriodException')
                    ) {
                        ToastService.Warning({ message: i18n.t('Toast_UpdateErrorExceedsMaxNoticePeriod') });
                    } else {
                        ToastService.Error({ message: 'Toast_BookingFailedToUpdate' });
                    }
                    return of(actions.updateSingleOccurrenceAsync.failure(error));
                })
            )
        )
    );
};

export const updateSingleOccurrenceFromBookingInfoEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.updateSingleOccurrenceForBookingInfoAsync.request)),
        switchMap((action) =>
            from(api.booking.updateSingleOccurrence(action.payload.occurrenceData)).pipe(
                mergeMap((payload) => {
                    return [
                        actions.updateSingleOccurrenceForBookingInfoAsync.success(payload),
                        action.payload.isUpdatingDateAndTime
                            ? getSingleBookingDetailsAsync.request({
                                  bookingId: action.payload.occurrenceData.bookingId,
                              })
                            : navigateFromInfoPage(),
                    ];
                }),
                catchError((error) => {
                    let message;
                    if (error.response.status === 409) {
                        message = i18n.t('Toast_BookingSlotNoLongerAvailable');
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('PastBookingException')
                    ) {
                        message = i18n.t('Error_SelectedPastTime');
                    } else if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('ConcurrentBookingException')
                    ) {
                        message = i18n.t('Toast_CreateBookingOnConcurrentBookingException');
                    } else {
                        message = 'Toast_BookingFailedToUpdate';
                    }
                    ToastService.Warning({ message });
                    return of(actions.updateSingleOccurrenceForBookingInfoAsync.failure(error));
                })
            )
        )
    );
};

export const updateWholeSeriesFromGridEpic: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf(actions.updateWholeSeriesFromGrid.request)),
        switchMap((action) =>
            from(api.booking.updateWholeSeries(action.payload)).pipe(
                map(() => actions.updateWholeSeriesFromGrid.success()),
                catchError((error) => {
                    if (
                        error.response.status === 403 &&
                        error.response.data.type.split('.').includes('BookingTimeExceedsMaxNoticePeriodException')
                    ) {
                        ToastService.Warning({ message: i18n.t('Toast_UpdateErrorExceedsMaxNoticePeriod') });
                    } else {
                        ToastService.Error({ message: 'Toast_BookingFailedToUpdate' });
                    }
                    return of(actions.updateWholeSeriesFromGrid.failure(error));
                })
            )
        )
    );
};
