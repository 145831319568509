import React, { memo, SyntheticEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { MenuItem } from '@mui/material';
import { ReactComponent as PencilIcon } from 'assets/icons/edit-pencil-icon.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeats-button-icon.svg';
import classNames from 'classnames';
import { Loading } from 'components';
import { Button, DateTimePicker, DropDown, FormGroup, TextBox, Toggle } from 'components/FormElements';
import DateTimePickerValue from 'components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import InputWrapper from 'components/Forms/InputWrapper';
import { formatVisitorData, getSearchDataConditions } from 'components/PersonSearchPanels/OboPersonSearch/utils';
import {
    BOOKING_STATUS,
    CHARACTER_LIMIT,
    CHECK_IN_STATUS,
    DEFAULT_DATE_FORMAT,
    LOAD_STATUSES,
    periodUnitEnum,
} from 'features/constants';
// eslint-disable-next-line import/no-cycle
import { BookingInfoProps } from 'features/Resources/Combined/SingleBooking/Components/BookingInfo';
import { DateTime } from 'luxon';
import { RepeatSchedule } from 'services/ApiClients/Booking/Models/UpdateBookingCommand';
import { ResourceType } from 'services/ApiClients/Resource';
import { findResourceNameByValue } from 'services/ApiClients/Resource/Models';
import DateTimeService from 'services/DateTimeService';
import { isNil } from 'utilities/ts';

import PersonDetailsPanel from '../../../../../components/PersonDetailsPanel/PersonDetailsPanel';
import { PersonSearchData } from '../../../../../components/PersonSearchPanels/interfaces/PersonSearchData';
import { OboPersonSearch } from '../../../../../components/PersonSearchPanels/OboPersonSearch/OboPersonSearch';
import { VisitorPanel } from '../../../../../components/VisitorPanel/VisitorPanel';
import { PersonType } from '../../../../../enums/PersonType';

import RepeatSlider from './Repeat/RepeatSlider';

import './BookingInfo.scss';

const BookingInfo = ({
    disabled,
    commonInfo,
    bookingInfo,
    visitorData,
    updateRepeatBooking,
    searchExternalOboPersons,
    searchInternalOboPersons,
    resetSearchOboPersonsResult,
    createVisitor,
    editVisitor,
    updateDateAndTime,
    editMode,
    handleSetBookingInfoDataState,
    bookingInfoDataState,
    bookingsByResourceLoadStatus,
}: BookingInfoProps): JSX.Element => {
    const { t } = useTranslation();
    const { setValue, register } = useFormContext();
    const [isSliderOpen, setSliderOpen] = useState<boolean>(false);
    const [personSearchData, setPersonSearchData] = useState<PersonSearchData>({} as PersonSearchData);
    const [isNewVisitorPanelOpen, setNewVisitorPanelOpen] = useState<boolean>(false);
    const [isVisitorDetailsPanelOpen, setVisitorDetailsPanelOpen] = useState<boolean>(false);
    const [personType, setPersonType] = useState<PersonType>(
        visitorData.onBehalfOfPerson?.visitorId ? PersonType.External : PersonType.Internal
    );

    const {
        resourceTypeTenantConfiguration,
        resourceTypeSiteConfiguration,
        isCurrentUserBooking,
        canEditNotOwnBooking,
        canViewPrivateBookings,
        isOnBehalfOfUserBooking,
        canEditPastBooking,
        dateTimeNow,
        selectedDate,
        canAddOnBehalfOf,
        dateNow,
        hourFormat,
        timezone,
        isRepeatsEnabled,
        resource,
        selectedSite,
    } = commonInfo;

    const {
        bookingStatus,
        bookingDateTime,
        dateTime,
        resourceType,
        resourceName,
        createdByDisplayName,
        displayName,
        checkInStatus,
        repeatBookingId,
        repeatSchedule,
        notes,
        isPrivate,
    } = bookingInfo;

    const {
        searchOboPersonsResult,
        isSearchOboPersonInProgress,
        isVisitorCreationOrEditingInProgress,
        isVisitorCreated,
        isVisitorUpdated,
    } = visitorData;

    const { selectedPerson, isPrivateBooking, attendeesCount, bookingInfoNotes, bookingInfoTitle, isOBODeleted } =
        bookingInfoDataState;

    const handleUpdateRepeatBooking = (schedule?: RepeatSchedule): void => {
        if (!isNil(schedule)) {
            updateRepeatBooking({ bookingData: { schedule, bookingId: bookingInfo.bookingId } });
        }
        setSliderOpen(false);
    };

    useEffect(() => {
        setPersonSearchData({} as PersonSearchData);
        resetSearchOboPersonsResult();
    }, [personType, visitorData.onBehalfOfPerson]);

    useLayoutEffect(() => {
        handleSetBookingInfoDataState({
            ...bookingInfoDataState,
            selectedPerson: isOBODeleted ? null : selectedPerson || visitorData.onBehalfOfPerson,
            isPrivateBooking: isPrivateBooking ?? isPrivate,
        });
    }, [visitorData.onBehalfOfPerson, isPrivate]);

    useEffect(() => {
        if (!isVisitorCreationOrEditingInProgress && (isVisitorCreated || isVisitorUpdated)) {
            setNewVisitorPanelOpen(false);
            setVisitorDetailsPanelOpen(false);
        }
    }, [isVisitorCreationOrEditingInProgress]);

    const onVisitorSave = useCallback(
        (vsData: any): void => {
            const visitor = formatVisitorData(vsData, bookingInfo.onBehalfOfPerson?.id, selectedSite?.id.toString());

            if (isNewVisitorPanelOpen) {
                createVisitor(visitor);
            }

            if (isVisitorDetailsPanelOpen) {
                editVisitor(visitor);
            }
            if (isVisitorCreated) {
                handleSetBookingInfoDataState({
                    ...bookingInfoDataState,
                    selectedPerson: visitor,
                });
            }
        },
        [isNewVisitorPanelOpen, isVisitorDetailsPanelOpen]
    );

    const onOboPersonSearch = useCallback(
        (searchData: PersonSearchData): void => {
            const searchDataConditions = getSearchDataConditions(searchData);

            if (personType === PersonType.Internal) {
                searchInternalOboPersons(searchDataConditions);
            } else {
                searchExternalOboPersons(searchDataConditions);
            }
        },
        [personSearchData, personType]
    );

    const handleOnDateTimeChange = (value: DateTimePickerValue): void => {
        if (!value.startDateTime.equals(dateTime?.startDateTime) || !value.endDateTime.equals(dateTime?.endDateTime)) {
            updateDateAndTime(value);
        }
    };

    const checkMaxNoticePeriodAvailability = (current: any): boolean => {
        const todayDate = DateTime.now().setZone(commonInfo?.timezone);

        if (commonInfo) {
            if (resource) {
                if (resource.noticePeriodDuration && resource.noticePeriodUnit !== null) {
                    return (
                        current <=
                        todayDate.plus({ [periodUnitEnum[resource.noticePeriodUnit]]: resource.noticePeriodDuration })
                    );
                }
            }
            if (resourceTypeSiteConfiguration) {
                if (
                    resourceTypeSiteConfiguration.noticePeriodDuration &&
                    resourceTypeSiteConfiguration.noticePeriodUnit !== null
                ) {
                    return (
                        current <=
                        todayDate.plus({
                            [periodUnitEnum[resourceTypeSiteConfiguration.noticePeriodUnit]]:
                                resourceTypeSiteConfiguration.noticePeriodDuration,
                        })
                    );
                }
            }
        }
        return false;
    };

    const handleSetPrivateBooking = (e: SyntheticEvent): void => {
        const target = e.target as HTMLInputElement;
        handleSetBookingInfoDataState({ ...bookingInfoDataState, isPrivateBooking: target.checked });
        setValue('isPrivate', target.checked);
    };

    const privateBookingCondition =
        isCurrentUserBooking || (canEditNotOwnBooking && canViewPrivateBookings) || isOnBehalfOfUserBooking;

    const isShowIsPrivateToggle: boolean =
        isNil(editMode) &&
        privateBookingCondition &&
        resourceTypeTenantConfiguration?.allowPrivateBookings &&
        !resourceTypeTenantConfiguration?.setAllBookingsAsPrivate;

    const isAttendeesTitleNotesDisabled: boolean =
        (!isCurrentUserBooking && !canEditNotOwnBooking) ||
        bookingStatus === BOOKING_STATUS.CURTAILED ||
        (dateTime.endDateTime.toISO() < dateTimeNow && !canEditPastBooking) ||
        disabled;

    const isDateTimePickerDisabled: boolean =
        (!isCurrentUserBooking && !canEditNotOwnBooking) ||
        dateTime.endDateTime.toISO() < dateTimeNow ||
        bookingStatus === BOOKING_STATUS.CURTAILED ||
        disabled;

    const isCanEditOboUserInOwnBooking =
        canAddOnBehalfOf && !canEditNotOwnBooking && (isCurrentUserBooking || isOnBehalfOfUserBooking);

    const isCanEditOboUserForAllBookings = canAddOnBehalfOf && canEditNotOwnBooking;

    const isRepeatBookingsEnabled =
        resourceTypeTenantConfiguration?.isRepeatBookingsEnabled &&
        resourceTypeSiteConfiguration?.isRepeatBookingsEnabled;

    const isLoading =
        !resourceTypeSiteConfiguration ||
        commonInfo?.operationInProgress ||
        bookingsByResourceLoadStatus === LOAD_STATUSES.LOADING;

    return isLoading ? (
        <Loading />
    ) : (
        <div className="booking-info">
            <FormGroup disabled={disabled}>
                <input type="hidden" id="resourceId" value={bookingInfo?.resourceId?.toString()} />

                <TextBox
                    label={t('BookResourceInfo_Title', { type: findResourceNameByValue(resourceType) })}
                    inputName="resourceName"
                    disabled
                    value={resourceName}
                    dataTestId="resourceName-text"
                />

                <TextBox
                    label={t('Resource_BookedBy_Label')}
                    inputName="bookedByName"
                    disabled
                    value={createdByDisplayName}
                    dataTestId="bookedByName-text"
                />
                <OboPersonSearch
                    dataTestId="person-search"
                    buttonLabel={t('Resource_OnBehalfOf_Label')}
                    panelLabel={t('Search')}
                    resultPlaceHolder={
                        personType === PersonType.Internal
                            ? t('Resource_SearchByName')
                            : t('Resource_SearchByNameAndCompany')
                    }
                    {...(typeof register('onBehalfOfPerson') === 'object' ? register('onBehalfOfPerson') : {})}
                    onSelect={(value: any) => {
                        setValue('onBehalfOfPerson', isNil(value) ? null : value);
                        handleSetBookingInfoDataState({
                            ...bookingInfoDataState,
                            selectedPerson: value,
                            isOBODeleted: false,
                        });
                    }}
                    onSearch={onOboPersonSearch}
                    onSearchDataChange={(searchValue: object) => {
                        setPersonSearchData({ ...personSearchData, ...searchValue });
                    }}
                    searchResult={searchOboPersonsResult}
                    selectedItem={selectedPerson}
                    iaSearchInProgress={isSearchOboPersonInProgress}
                    resetSearchResult={resetSearchOboPersonsResult}
                    searchData={personSearchData}
                    personType={personType}
                    onNewVisitor={() => {
                        setNewVisitorPanelOpen(true);
                    }}
                    setNewVisitorPanelOpen={setNewVisitorPanelOpen}
                    onPersonTypeChange={setPersonType}
                    setPersonDetailsPanelOpen={setVisitorDetailsPanelOpen}
                    isDisabled={disabled}
                    isPrivate={isPrivateBooking}
                    editMode={editMode}
                    isCanEditOboUserInOwnBooking={isCanEditOboUserInOwnBooking}
                    isCanEditOboUserForAllBookings={isCanEditOboUserForAllBookings}
                    canViewPrivateBookings={canViewPrivateBookings}
                    canAddOnBehalfOf={canAddOnBehalfOf}
                    handleSetBookingInfoDataState={handleSetBookingInfoDataState}
                    bookingInfoDataState={bookingInfoDataState}
                />
                <InputWrapper name="attendeesAndPrivateBooking" horizontal>
                    {resourceType === ResourceType.ROOM.value && (
                        <DropDown
                            required
                            label={t('Resource_Attendees')}
                            inputName="attendees"
                            className="attendees"
                            disabled={isAttendeesTitleNotesDisabled || (isPrivateBooking && !privateBookingCondition)}
                            value={attendeesCount || bookingInfo.attendeesCount.toString()}
                            {...(typeof register('attendeesCount') === 'object' ? register('attendeesCount') : {})}
                            onChange={(value) => {
                                setValue('attendeesCount', value);
                                handleSetBookingInfoDataState({ ...bookingInfoDataState, attendeesCount: value });
                            }}
                            dataTestId="Attendees_BookingInfo"
                        >
                            {Array.from(Array(commonInfo?.resource?.capacity ?? 1)).map((x, index) => {
                                const value = index + 1;
                                return (
                                    <MenuItem value={value} key={value}>
                                        {value}
                                    </MenuItem>
                                );
                            })}
                        </DropDown>
                    )}
                    {isShowIsPrivateToggle && (
                        <Toggle
                            label={t('Combined_BookingInfo_PrivateBooking')}
                            inputId="isPrivateBooking"
                            inputName="privateBookingToggle"
                            {...(typeof register('isPrivate') === 'object' ? register('isPrivate') : {})}
                            onChange={handleSetPrivateBooking}
                            dataTestId="toggle_privateBooking"
                            checked={isPrivateBooking}
                            value={`${isPrivateBooking}`}
                            type="checkbox"
                        />
                    )}
                </InputWrapper>
                <TextBox
                    label={t('Resource_DisplayName_Label')}
                    inputName="displayName"
                    disabled={isAttendeesTitleNotesDisabled || (isPrivateBooking && !privateBookingCondition)}
                    value={bookingInfoTitle ?? displayName}
                    {...(typeof register('displayName') === 'object' ? register('displayName') : {})}
                    onChange={(value: string) => {
                        setValue('displayName', value);
                        handleSetBookingInfoDataState({ ...bookingInfoDataState, bookingInfoTitle: value }, true);
                    }}
                    dataTestId="displayName-text"
                    maxLength={CHARACTER_LIMIT}
                />
            </FormGroup>
            <FormGroup disabled={disabled}>
                <Controller
                    name="dateTime"
                    defaultValue={DateTimeService.setDateTimePeriodFromDateTimeValue(
                        dateTime,
                        resourceTypeSiteConfiguration,
                        selectedDate.toFormat('cccc').toLowerCase()
                    )}
                    render={(props) => (
                        <DateTimePicker
                            name="dateTime"
                            label={t('Resource_DateAndTime_Label')}
                            title={t('Resource_DateAndTime_Label')}
                            dateTimeRangeValue={DateTimeService.setDateTimePeriodFromDateTimeValue(
                                dateTime,
                                resourceTypeSiteConfiguration,
                                selectedDate.toFormat('cccc').toLowerCase()
                            )}
                            supportEndTime
                            dataTestId="dateTime-dateTimePicker"
                            onChange={(value) => {
                                handleOnDateTimeChange(value);
                                props.onChange(value);
                            }}
                            disabled={isDateTimePickerDisabled}
                            timeFromDisabled={
                                dateTime.endDateTime.toISO() < dateTimeNow ||
                                checkInStatus === CHECK_IN_STATUS.CHECKED_IN ||
                                checkInStatus === CHECK_IN_STATUS.CHECKED_OUT
                            }
                            isToggleDisabled={
                                dateTime.endDateTime.toISO() < dateTimeNow ||
                                checkInStatus === CHECK_IN_STATUS.CHECKED_IN
                            }
                            resourceTypeSiteConfiguration={resourceTypeSiteConfiguration}
                            selectedDate={selectedDate}
                            isSelectedDateBookable={(current: DateTime) => {
                                return (
                                    checkMaxNoticePeriodAvailability(current) &&
                                    DateTimeService.isSelectedDateBookable(current) &&
                                    checkInStatus !== CHECK_IN_STATUS.CHECKED_IN &&
                                    current.toFormat(DEFAULT_DATE_FORMAT) >= dateNow
                                );
                            }}
                            hourFormat={hourFormat}
                            timezone={timezone}
                        />
                    )}
                />

                {isNil(repeatBookingId) && isRepeatsEnabled && (
                    <>
                        {!repeatSchedule ? (
                            isNil(editMode) &&
                            isRepeatBookingsEnabled && (
                                <InputWrapper label={t('Repeat_Label')} name="repeats">
                                    <Button
                                        text={t('Repeat_Button_Title')}
                                        className="repeat-button"
                                        dataTestId="repeat-button"
                                        icon={RepeatIcon}
                                        onClick={() => setSliderOpen(true)}
                                        disabled={dateTime.endDateTime.toISO() < dateTimeNow}
                                    />
                                </InputWrapper>
                            )
                        ) : (
                            <InputWrapper label={t('Repeat_Label')} name="repeats">
                                <div className="edit-repeat-container">
                                    {isNil(editMode) && (
                                        <Button
                                            className="edit-repeat-button"
                                            dataTestId="edit-repeat-button"
                                            icon={PencilIcon}
                                            onClick={() => {
                                                setSliderOpen(true);
                                            }}
                                            disabled={dateTime.endDateTime.toISO() < dateTimeNow}
                                        />
                                    )}

                                    <div
                                        className={classNames('repeat-info', {
                                            isDisabled: !isNil(editMode),
                                        })}
                                    >
                                        <ReactMarkdown
                                            source={bookingInfo?.repeatSchedule?.pattern?.friendlyScheduleText}
                                        />
                                    </div>
                                </div>
                            </InputWrapper>
                        )}
                    </>
                )}
                <TextBox
                    label={t('Resource_Notes_Label')}
                    inputName="notes"
                    value={bookingInfoNotes ?? notes}
                    {...(typeof register('notes') === 'object' ? register('notes') : {})}
                    onChange={(value: string) => {
                        setValue('notes', value);
                        handleSetBookingInfoDataState({ ...bookingInfoDataState, bookingInfoNotes: value }, true);
                    }}
                    dataTestId="notes-textarea"
                    maxLength={4000}
                    disabled={isAttendeesTitleNotesDisabled}
                    rows={6}
                />
            </FormGroup>
            <Controller
                name="repeatSchedule"
                defaultValue={repeatSchedule}
                render={(props) => (
                    <RepeatSlider
                        isOpen={isSliderOpen}
                        onClose={(value: any) => {
                            handleUpdateRepeatBooking(value);
                            props.onChange(value);
                        }}
                        dateTimeRangeValue={{
                            startDate: bookingDateTime.startDateTime,
                            endDate: bookingDateTime.endDateTime,
                        }}
                        selectedDate={selectedDate}
                        resourceTypeSiteConfiguration={resourceTypeSiteConfiguration}
                        resourceConfiguration={resource}
                        repeatSchedule={repeatSchedule}
                        isSelectedDateBookable={(current: DateTime) =>
                            !isNil(
                                commonInfo?.resourceTypeSiteConfiguration?.bookableDays[
                                    current.toFormat('cccc').toLowerCase()
                                ]
                            ) && checkMaxNoticePeriodAvailability(current)
                        }
                        timezone={timezone}
                        hourFormat={hourFormat}
                    />
                )}
            />

            <VisitorPanel
                isOpen={isNewVisitorPanelOpen}
                setPanelOpen={setNewVisitorPanelOpen}
                creationOrUpdatingInProgress={isVisitorCreationOrEditingInProgress}
                onSave={onVisitorSave}
            />

            <PersonDetailsPanel
                title={t('PersonDetails_StaffDetails')}
                isOpen={isVisitorDetailsPanelOpen}
                setPanelOpen={setVisitorDetailsPanelOpen}
                person={selectedPerson || visitorData.onBehalfOfPerson}
                nameTitle={t('PersonDetails_Name')}
            />
        </div>
    );
};

export default memo(BookingInfo);
