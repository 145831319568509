import React from 'react';
import { ReactComponent as NewBookingOFF } from 'assets/icons/reception/NewBookingOFF.svg';
import { ReactComponent as NewBookingON } from 'assets/icons/reception/NewBookingON.svg';

import BaseHeaderIconButton from '../BaseHeaderIconButton';

export interface SearchIconButtonProps {
    onClick: Function;
    label: string;
    isDisabled?: boolean;
    dataTestId?: string;
}

const BookingIconButton = (props: SearchIconButtonProps): JSX.Element => {
    const { onClick, label, dataTestId, isDisabled = false } = props;

    return (
        <BaseHeaderIconButton
            className="booking-icon-button"
            onClick={onClick}
            isDisabled={isDisabled}
            dataTestId={dataTestId}
            icon={<NewBookingOFF />}
            onHoverIcon={<NewBookingON />}
            label={label}
        />
    );
};

export default BookingIconButton;
