export const BOOKING_SLOT_UNITS_MULTIPLIER = 5;
export const DEFAULT_FIFTEEN_MIN_SLOT_DURATION = 15;

export const ONE_HOUR_GRID_HEADER_WIDTH = 100;

export const DEFAULT_TIMEZONE = 'Etc/UTC';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const MOMENT_DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const GRID_DATE_FORMAT = 'dd MMM yyyy';
export const GRID_TIME_24H_FORMAT = 'HH:mm';
export const GRID_TIME_12H_FORMAT = 'h:mm a';
export const SLASH_DATE_FORMAT = 'DD/MM/YYYY';
export const LUXON_DEFAULT_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const TIME_FORMAT = 'HH:mm:ss';

export enum EditMode {
    DISABLED_OCCURRENCE,
    ENABLE_OCCURRENCE,
    ENABLE_SERIES,
}

export const INITIAL_BOOKING_DATA_STATE = {
    isPrivateBooking: null,
    selectedPerson: null,
    bookingInfoNotes: null,
    bookingInfoTitle: null,
    attendeesCount: '',
    isOBODeleted: false,
};

export const BOOKING_GRID_REPEAT_ICON = {
    LEFT_WITH_PRIVATE_ICON: 20,
    LEFT: 3,
    BOTTOM: 5,
    WIDTH: 12,
    HEIGHT: 12,
};

export const BOOKING_GRID_IS_PRIVATE_ICON = {
    LEFT: 3,
    BOTTOM: 5,
    WIDTH: 12,
    HEIGHT: 12,
};

export const TIME_COUNT = {
    MILLISECONDS_IN_ONE_SECOND: 1000,
    SECONDS_IN_ONE_MINUTE: 60,
    MINUTES_IN_ONE_HOUR: 60,
};

export const REDUCERS = {
    ROUTER: 'router',
    CONFIGURATION: 'configuration',
    GEOGRAPHIC_STRUCTURES: 'geographicStructures',
    NAVIGATION: 'navigation',
    DESKS: 'desks',
    ACCOUNT: 'account',
    LANGUAGES: 'languages',
    REFERENCE_DATA: 'referenceData',
};

export const GEOGRAPHIC_STRUCTURE_TYPES = {
    SITE_GROUP: 'site-group',
    SITE: 'site',
    AREA: 'area',
};

export const RESOURCE_CONFIGURATION_TYPES = {
    DESK: 'desk',
    ROOM: 'room',
    PARKING: 'parking',
};

export const LOAD_STATUSES = {
    REQUIRED: 'REQUIRED',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    FAILED: 'FAILED',
};

export const BOOKING_STATUS = {
    CURTAILED: 'curtailed',
    CONFIRMED: 'confirmed',
    PENDING: 'pending',
    ABANDONED: 'abandoned',
    CANCELLED: 'cancelled',
    UPDATE_PENDING: 'updatepending',
};

export const CHECK_IN_STATUS = {
    CHECKED_IN: 'checkedin',
    CHECKED_OUT: 'checkedout',
    PENDING: 'pending',
};

export const LANGUAGE = {
    DEFAULT: { locale: 'en-GB', displayName: 'English' },
    FALLBACK_OPTIONS: [
        {
            locale: 'en-GB',
            displayName: 'English',
        },
    ],
    NAMESPACES: 'portal',
};

export enum VIEW_TYPE {
    GRID = 'GRID',
    FLOOR_PLAN = 'FLOOR_PLAN',
}

export const STATIC_FILTERS: StaticFiltersConstType = {
    disabledAccess: {
        name: 'Disabled Access',
    },
};

export interface StaticFiltersConstType {
    [k: string]: StaticFiltersLabelType;
}

export interface StaticFiltersLabelType {
    name: string;
}

export enum MONTH_REPEAT_TYPE {
    RELATIVE = 2,
    ABSOLUTE = 3,
}

export enum MONTH_REPEAT_INDEXES {
    first = 'first',
    second = 'second',
    third = 'third',
    fourth = 'fourth',
    last = 'last',
}

export const REPEAT: { [key: string]: any } = {
    DAY: { option: 'Daily', every: 'day', type: '0', maxRepeats: 30 },
    WEEK: { option: 'Weekly', every: 'week', type: '1', maxRepeats: 50 },
    MONTH: {
        option: 'Monthly',
        every: 'month',
        type: [MONTH_REPEAT_TYPE.RELATIVE.toString(), MONTH_REPEAT_TYPE.ABSOLUTE.toString()],
        maxRepeats: 11,
    },
};

export const REPEAT_ON_DAY: { [key: string]: string } = {
    Monday: 'M',
    Tuesday: 'T',
    Wednesday: 'W',
    Thursday: 'T',
    Friday: 'F',
    Saturday: 'S',
    Sunday: 'S',
};

export const AVAILABLE_ICONS = [
    'info',
    'audioConferencing',
    'networking',
    'other',
    'pc',
    'restricted',
    'restrictedTo',
    'settings',
    'telephone',
    'videoConferencing',
    'wheelchair',
];

export const UkCountryCode = '+44';
export const UkCountryCodeFull = 'United Kingdom (+44)';
export const EnterKey = 'Enter';

export const MAX_CELL_TEXT_LENGTH = 24;

export const AllListItemOption = { label: 'All', value: 'all' };

export const DEFAULT_TIME_SLOT = 15;

export const TWENTY_FOUR_HOUR_FORMAT = 'HH:mm';
export const TWELWE_HOUR_FORMAT = 'h:mm';
export const EXTENDED_TWELWE_HOUR_FORMAT = 'h:mm a';
export const GRID_TWELWE_HOUR_FORMAT = 'h:mm tt';
export const GRID_HEADER_TWELWE_HOUR_FORMAT = 'h tt';

export const MY_ACCOUNT_24_FORMAT_VALUE = '24hour';
export const MY_ACCOUNT_12_FORMAT_VALUE = '12hour';

export const TIME_FORMATS = [
    { label: '24-hour clock', value: MY_ACCOUNT_24_FORMAT_VALUE },
    { label: '12-hour clock', value: MY_ACCOUNT_12_FORMAT_VALUE },
];

export const CHARACTER_LIMIT = 100;

export enum periodUnitEnum {
    day,
    week,
    month,
}

export const FIRST_DAY_OF_WEEK_INDEX = 0;
export const LAST_DAY_OF_WEEK_INDEX = 6;

export const enum EXCLUSION_TYPE {
    EXCLUSION = 0,
    NOT_AVAILABLE,
    CANCELLED,
}

export const MIN_CARD_WIDTH = 120;
export const ALL_DAY = 'ALL_DAY';
export const AM_PM = 'AM_PM';
export const CUSTOM_DURATION = 'CUSTOM';
export const START_TIME = 'startTime';
export const HOUR = 'hour';
export const MINUTE = 'minute';
export const AM = 'AM';
export const PM = 'PM';

// 1 min
export const TIMELINE_TIME_UPDATE_IN_MILLISECONDS = 60000;
