import React from 'react';
import { useSelector } from 'react-redux';
import defaultSiteImage from 'assets/default-home-image.png';
import { isEmpty } from 'lodash';
import { RootState } from 'PortalTypes';

import WelcomeMessage from './Components/WelcomeMessage';

import './Home.scss';
// TODO: Add the siteText when if presents
// import GeographicStructureMessage from './Components/GeographicStructureMessage';

const Home = (): JSX.Element => {
    const defaultSite = useSelector((state: RootState) => state.filters.global.site);

    return (
        <main
            className="page-home"
            style={{
                backgroundImage: isEmpty(defaultSite?.siteImage)
                    ? `url(${defaultSiteImage})`
                    : `url(${defaultSite?.siteImage})`,
                backgroundSize: 'cover',
            }}
        >
            <WelcomeMessage />
            {/* TODO: Add the siteText when if presents */}
            {/* <GeographicStructureMessage /> */}
        </main>
    );
};

export default Home;
