import React from 'react';
import { ReactComponent as ToastTime } from 'assets/icons/ToastTime.svg';

import { SlotDuration } from '../utils';

import './DurationSelector.scss';

interface DurationSelectorProps {
    handleOpenSlider: () => void;
    durationValue?: string | number;
    slotDurations: SlotDuration[];
}

const DurationSelector = ({ handleOpenSlider, durationValue, slotDurations }: DurationSelectorProps): JSX.Element => {
    const duration = slotDurations.find((item) => item.value === durationValue) || ({} as SlotDuration);

    return (
        <div className="duration-selector" onClick={handleOpenSlider}>
            <div className="duration-container">
                <ToastTime className="time-icon" />
                <span className="duration">{duration?.label}</span>
            </div>
            <span className="arrow-selector" />
        </div>
    );
};

export default DurationSelector;
