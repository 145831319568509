import DateTimePickerValue from 'components/FormElements/DateTimePicker/Models/DateTimePickerValue';
import * as constants from 'features/constants';
// eslint-disable-next-line import/no-cycle
import { getCombinedSiteConfiguration } from 'features/Resources/Combined/Redux/selectors';
import { DateTime } from 'luxon';
import { Configuration } from 'services/ApiClients/Configuration';

import { store } from '../../store';

export interface TimeSlotOption {
    label: string;
    value: number;
}

export default class DateTimeService {
    /**
     * Retrieves a list of TimeSlotOptions given a start and end timeslot and timeslot interval
     *
     * @static
     * @param {number} [startTimeSlot=32] The timeslot at which to start generating timeslot opens
     * @param {number} [endTimeSlot=80] The timeslot at which to stop generating timeslot options
     * @param {number} [slotInterval=15] Time in minutes for each timeslot
     * @param {string} [hourFormat] The format of the time slot
     * @returns {TimeSlotOption[]}
     * @memberof DateTimeSlots
     */
    public static getTimeSlots(
        startTimeSlot = 32,
        endTimeSlot = 80,
        slotInterval = 15,
        hourFormat: string
    ): TimeSlotOption[] {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        const maxSlotsInADay = Math.floor((60 / slotInterval) * 24);

        // Get a standard day and reset the time to midnight, move forward to the time at which the startTimeSlot occurs
        let tempDate = DateTime.now().setZone(timezone);
        tempDate = tempDate.startOf('day');

        tempDate = tempDate.plus({ minutes: startTimeSlot * slotInterval || 0 });

        const result: TimeSlotOption[] = [];

        // Loop thorugh each timeslot by incrementing by slotInterval minutes and adding a timeSlotOption to the result array
        for (let i = startTimeSlot; i <= maxSlotsInADay; i += 1) {
            if (i > endTimeSlot) break;

            result.push({
                label: tempDate.toFormat(hourFormat),
                value: i,
            });

            tempDate = tempDate.plus({ minutes: slotInterval });
        }

        return result;
    }

    /**
     * Gieven a datetime, and the slot interval, retrieve the next time slot
     *
     * @static
     * @param {moment.Moment} [currentDateTime]
     * @param {number} [slotInterval=15]
     * @returns {number} The next timeslot in the day
     * @memberof DateTimeSlots
     */
    public static getNextTimeSlot(currentDateTime: DateTime, slotInterval = 15): number {
        const elapsedTimeInMins = currentDateTime.hour * 60 + currentDateTime.minute;
        const elapsedTimeSlots = Math.floor(elapsedTimeInMins / slotInterval);

        // @todo: We need to do some error check about the end of the day

        return elapsedTimeSlots + 1;
    }

    /**
     * Given a datetime, return the timeslot it belongs to
     *
     * @static
     * @param {moment.Moment} [date]
     * @param {number} [slotInterval=15]
     * @param {boolean} [isEndTimeSlot=false]
     * @returns {number}
     * @memberof DateTimeService
     */
    public static getTimeSlotFromDate(date: DateTime, slotInterval = 15, isEndTimeSlot = false): number {
        // Handle an end datetime of midnight which represents the last slot of the previous day
        const midnight = date.startOf('day');
        const elapsedTimeInMins = isEndTimeSlot && date.equals(midnight) ? 24 * 60 : date.hour * 60 + date.minute;
        return Math.floor(elapsedTimeInMins / slotInterval);
    }

    /**
     * Get the datetime at the start of a given timeslot
     *
     * @static
     * @param {moment.Moment} dateTime
     * @param {number} timeSlot
     * @param {number} [slotInterval=15]
     * @returns {moment.Moment}
     * @memberof DateTimeService
     */
    public static getDateFromTimeSlot(dateTime: DateTime, timeSlot: number, slotInterval = 15): DateTime {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        const isoDateFormat = DateTime.fromISO(dateTime.toJSDate().toISOString(), { zone: timezone }).toISO();
        const newDate = DateTime.fromISO(isoDateFormat, { setZone: true });

        const midnight = newDate.startOf('day');

        return midnight.plus({ minutes: timeSlot * slotInterval });
    }

    /**
     * Retrieves thet datetime at the start of the next timeslot
     *
     * @static
     * @param {moment.Moment} [currentDateTime]
     * @param {number} [slotInterval=15]
     * @returns {moment.Moment}
     * @memberof DateTimeService
     */
    public static getNextTimeSlotAsDate(currentDateTime: DateTime, slotInterval = 15): DateTime {
        const nextTimeSlot = this.getNextTimeSlot(currentDateTime, slotInterval);
        return DateTimeService.getDateFromTimeSlot(currentDateTime, nextTimeSlot, slotInterval);
    }

    /**
     * Returns time slot option when given a moment date time object
     *
     * @param {moment.Moment} dateTime
     * @param {number} timeSlotInterval
     * @param {boolean} [isEndTimeSlot=false]
     * @returns TimeSlotOption
     */
    public static getTimeSlotOptionFromDateTime(
        dateTime: DateTime,
        timeSlotInterval: number,
        isEndTimeSlot = false
    ): TimeSlotOption {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        return {
            label: dateTime.setZone(timezone).toFormat('HH:mm'),
            value: DateTimeService.getTimeSlotFromDate(dateTime.setZone(timezone), timeSlotInterval, isEndTimeSlot),
        };
    }

    /**
     * @param  {moment.Moment} startDateTime
     * @param  {moment.Moment} endDateTime
     * @param  {number} resourceConfigStartTime
     * @param  {} middayTime=12
     */
    public static checkIfBookingIsAM(
        startDateTime: DateTime,
        endDateTime: DateTime,
        resourceConfigStartTime: number,
        middayTime = 12
    ): boolean {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        return (
            startDateTime.equals(
                startDateTime.setZone(timezone).set({ hour: resourceConfigStartTime / 60, minute: 0 })
            ) &&
            endDateTime.equals(
                DateTimeService.getEndDateTimeStartOfDay(endDateTime).set({ hour: middayTime, minute: 0 })
            )
        );
    }

    /**
     * @param  {moment.Moment} startDateTime
     * @param  {moment.Moment} endDateTime
     * @param  {number} resourceConfigEndTime
     * @param  {} middayTime=12
     */
    public static checkIfBookingIsPM(
        startDateTime: DateTime,
        endDateTime: DateTime,
        resourceConfigEndTime: number,
        middayTime = 12
    ): boolean {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        return (
            startDateTime.equals(startDateTime.setZone(timezone).startOf('day').set({ hour: middayTime, minute: 0 })) &&
            endDateTime.equals(
                DateTimeService.getEndDateTimeStartOfDay(endDateTime).set({
                    hour: resourceConfigEndTime / 60,
                    minute: 0,
                })
            )
        );
    }

    /**
     * @param  {moment.Moment} startDateTime
     * @param  {moment.Moment} endDateTime
     * @param  {number} resourceConfigStartTime
     * @param  {number} resourceConfigEndTime
     */
    public static checkIfBookingIsAllDay(
        startDateTime: DateTime,
        endDateTime: DateTime,
        resourceConfigStartTime: number,
        resourceConfigEndTime: number
    ): boolean {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        return (
            startDateTime.equals(
                startDateTime.setZone(timezone).set({ hour: resourceConfigStartTime / 60, minute: 0 })
            ) &&
            endDateTime.equals(
                DateTimeService.getEndDateTimeStartOfDay(endDateTime).set({
                    hour: resourceConfigEndTime / 60,
                    minute: 0,
                })
            )
        );
    }

    /**
     * Set appropriate time periods given a DateTimePickerValue object
     *
     * @param  {moment.Moment} value
     * @param  {Configuration} resourceTypeSiteConfiguration
     * @param selectedDay
     * @returns DateTimePickerValue
     */
    public static setDateTimePeriodFromDateTimeValue(
        value: DateTimePickerValue,
        resourceTypeSiteConfiguration: Configuration,
        selectedDay: string
    ): DateTimePickerValue {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;

        return {
            ...value,
            startDateTime: value.startDateTime.setZone(timezone, { keepLocalTime: true }),
            endDateTime: value.endDateTime.setZone(timezone, { keepLocalTime: true }),
            isAM:
                value.startDateTime && value.endDateTime
                    ? DateTimeService.checkIfBookingIsAM(
                          value.startDateTime,
                          value.endDateTime,
                          resourceTypeSiteConfiguration.bookableDays[selectedDay]
                              ?.bookingWindowStartMinutesFromMidnight || 0
                      )
                    : false,
            isPM:
                value.startDateTime && value.endDateTime
                    ? DateTimeService.checkIfBookingIsPM(
                          value.startDateTime,
                          value.endDateTime,
                          resourceTypeSiteConfiguration.bookableDays[selectedDay]
                              ?.bookingWindowEndMinutesFromMidnight || 0
                      )
                    : false,
            isAllDay:
                value.startDateTime && value.endDateTime
                    ? DateTimeService.checkIfBookingIsAllDay(
                          value.startDateTime,
                          value.endDateTime,
                          resourceTypeSiteConfiguration.bookableDays[selectedDay]
                              ?.bookingWindowStartMinutesFromMidnight || 0,
                          resourceTypeSiteConfiguration.bookableDays[selectedDay]
                              ?.bookingWindowEndMinutesFromMidnight || 0
                      )
                    : false,
        };
    }

    private static getEndDateTimeStartOfDay(dateTime: DateTime): DateTime {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        const midnight = dateTime.setZone(timezone).startOf('day');
        // Handle an end datetime of midnight which represents the last slot of the previous day
        return dateTime.equals(midnight) ? midnight.minus({ days: 1 }) : midnight;
    }

    /**
     * Check if a selected date is bookable from the grid.
     *
     * @param  {moment.Moment} value
     * @returns boolean
     */
    public static isSelectedDateBookable(current: DateTime, configuration?: Configuration): boolean {
        // TODO: pass configuration as a parameter
        const resourceTypeSiteConfigurationFallback = getCombinedSiteConfiguration(store.getState()) as Configuration;
        const resourceTypeSiteConfiguration = configuration || resourceTypeSiteConfigurationFallback;

        if (!resourceTypeSiteConfiguration) {
            return true;
        }

        const validDays = Object.keys(resourceTypeSiteConfiguration.bookableDays);
        return validDays.includes(current.toFormat('cccc').toLowerCase());
    }

    /**
     * Check the next valid bookable date given the current date
     *
     * @param  {moment.Moment} value
     * @returns moment.Moment
     */
    public static getNextBookableDate(current: DateTime): DateTime {
        // TODO: pass configuration as a parameter
        const resourceTypeSiteConfiguration = getCombinedSiteConfiguration(store.getState()) as Configuration;

        let nextAvailableBookableDay = current.plus({ days: 1 });

        if (!resourceTypeSiteConfiguration) {
            return nextAvailableBookableDay;
        }

        const validDays = Object.keys(resourceTypeSiteConfiguration.bookableDays);
        if (!validDays.length) {
            return current;
        }

        let i = 0;

        while (!validDays.includes(nextAvailableBookableDay.toFormat('cccc').toLowerCase()) && i < 7) {
            nextAvailableBookableDay = nextAvailableBookableDay.plus({ days: 1 });
            i += 1;
        }
        return nextAvailableBookableDay;
    }

    /**
     * Check the previous valid bookable date given the current date
     *
     * @param  {moment.Moment} value
     * @returns moment.Moment
     */
    public static getPreviousBookableDate(current: DateTime): DateTime {
        // TODO: pass configuration as a parameter
        const resourceTypeSiteConfiguration = getCombinedSiteConfiguration(store.getState()) as Configuration;

        let prevDay = current.minus({ days: 1 });
        if (!resourceTypeSiteConfiguration) {
            return prevDay;
        }
        const validDays = Object.keys(resourceTypeSiteConfiguration.bookableDays);

        if (!validDays.length) {
            return current;
        }

        let i = 0;

        while (!validDays.includes(prevDay.toFormat('cccc').toLowerCase()) && i < 7) {
            prevDay = prevDay.minus({ days: 1 });
            i -= 1;
        }

        return prevDay;
    }
}
