// NOTE: Based on https://github.com/andreoav/click-outside-hook
import { RefObject, useEffect, useRef, useState } from 'react';

export default function useOutsideClick(
    callback: EventListener,
    container?: RefObject<HTMLDivElement>
): RefObject<HTMLDivElement> {
    /* eslint-disable react-hooks/rules-of-hooks */
    const localContainer = container ?? useRef<HTMLDivElement>(null);
    /* eslint-enable react-hooks/rules-of-hooks */

    const [isTouchEvent, setTouchEvent] = useState(false);
    const eventType = isTouchEvent ? 'touchend' : 'click';

    function handleEvent(e: Event): void {
        if (e.type === 'click' && isTouchEvent) {
            return;
        }

        if (localContainer.current && e.target !== null) {
            if (!localContainer.current.contains(e.target as Node)) {
                callback(e);
            }
        }
    }

    useEffect(() => {
        document.addEventListener(eventType, handleEvent, true);

        return () => {
            document.removeEventListener(eventType, handleEvent, true);
        };
    });

    useEffect(() => {
        setTouchEvent('ontouchstart' in document.documentElement);
    }, []);

    return localContainer;
}
