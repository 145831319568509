import { connect } from 'react-redux';
import { LOAD_STATUSES } from 'features/constants';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import { EditVisitorsLocationState } from 'services/ApiClients/Models';

import { DaysConfigurationResponse } from '../../../services/ApiClients/Configuration';
import { Visitor } from '../../../services/ApiClients/VisitorManagement/Models/Visitor';
import { SearchData } from '../../../utilities/searchUtils';
import {
    createExternalVisitor,
    editExternalVisitor,
    resetSearchHostsResult,
    resetSearchVisitorsResult,
    searchExternalVisitors,
    searchHosts,
    searchInternalVisitors,
} from '../Redux/actions';
// eslint-disable-next-line import/no-cycle
import { BookingData } from '../VisitorBooking';

// eslint-disable-next-line import/no-cycle
import VisitorBookingForm from './VisitorBookingForm';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    filteredSitesGeoStructure: state.referenceData.sites.items.filter((site) => site.isVisitorManagementAvailable),
    selectedSite: state.filters.global.site,
    selectedSiteGroup: state.filters.global.siteGroup,
    sitesGeoStructure: state.referenceData.sites.items,
    siteGroupsGeoStructure: state.referenceData.siteGroup.items,
    isSitesLoading:
        state.referenceData.site.loadStatus === LOAD_STATUSES.LOADING ||
        state.referenceData.siteGroup.loadStatus === LOAD_STATUSES.LOADING,
    tenantId: state.authentication.user?.tenantId,
    isSearchVisitorsInProgress: state.visitorBooking.isSearchVisitorsInProgress,
    isSearchHostsInProgress: state.visitorBooking.isSearchHostsInProgress,
    searchVisitorsResult: state.visitorBooking.searchVisitorsResult,
    searchHostsResult: state.visitorBooking.searchHostsResult,
    isVisitorCreationOrEditingInProgress: state.visitorBooking.isVisitorCreationOrEditingInProgress,
    isVisitorCreated: state.visitorBooking.isVisitorCreated,
    isVisitorUpdated: state.visitorBooking.isVisitorUpdated,
    bookableDaysConfig: state.visitorBooking.bookableDays || ({} as DaysConfigurationResponse),
    hourFormat: state.authentication.user?.extendedHourFormat,
    is24HourFormat: state.authentication.user?.is_24h_timeFormat,
});

const mapDispatchToProps = {
    searchInternalVisitors: (searchPersonsRequest: SearchData) => searchInternalVisitors.request(searchPersonsRequest),
    searchExternalVisitors: (searchPersonsRequest: SearchData) => searchExternalVisitors.request(searchPersonsRequest),
    searchHosts: (searchPersonsRequest: SearchData) => searchHosts.request(searchPersonsRequest),
    resetSearchVisitorsResult: () => resetSearchVisitorsResult(),
    resetSearchHostsResult: () => resetSearchHostsResult(),
    createExternalVisitor: (visitor: Visitor) => createExternalVisitor.request(visitor),
    editExternalVisitor: (visitor: Visitor) => editExternalVisitor.request(visitor),
};

export type VisitorBookingFormProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & {
        visitorType: number | null;
        editVisitorBookings: EditVisitorsLocationState;
        bookingData: BookingData;
        setBookingData: (bookingData: BookingData) => void;
        defaultDueDateTime: DateTime;
        timezone: string;
        dueTimeErrorMessage: string;
    };
export default connect(mapStateToProps, mapDispatchToProps)(VisitorBookingForm);
