import React, { useState } from 'react';
import Calendar from 'react-datetime';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import BackgroundMask from 'components/BackgroundMask';
import Button from 'components/FormElements/Button';
import SlidingPanel from 'components/SlidingPanel';
import useKeyPress from 'hooks/useKeyPress';
import { DateTime } from 'luxon';
import DateTimeService from 'services/DateTimeService';

import 'moment/locale/en-gb';

import 'scss/components/DateTimePicker.scss';

export interface DatePickerProps {
    title: string;
    value: DateTime;
    isOpen: boolean;
    onDone: (date: DateTime) => void;
    onClose: () => void;
    isSelectedDateBookable?: (date: DateTime) => boolean;
    timezone?: string;
}

const DatePicker = ({
    title,
    value,
    isOpen,
    onDone,
    onClose,
    isSelectedDateBookable,
    timezone,
}: DatePickerProps): JSX.Element => {
    const [date, setDate] = useState<any>(value);
    const { t } = useTranslation();

    const handleDone = (): void => {
        onDone(date);
    };
    const handleDateChange = (dateValue: any): void => {
        const isoDateFormat = DateTime.fromISO(dateValue.toISOString(), { zone: timezone }).toISO();
        const newDate = DateTime.fromISO(isoDateFormat, { setZone: true });

        setDate(newDate);
    };

    const backgroundMaskClass = classNames({ open: isOpen });

    useKeyPress(27, () => onDone(date));

    return (
        <>
            <BackgroundMask className={backgroundMaskClass} onClick={onClose} />

            <SlidingPanel title={title} open={isOpen} onClose={onClose}>
                {isOpen && (
                    <>
                        <Calendar
                            value={date.setZone('local', { keepLocalTime: true }).toJSDate()}
                            timeFormat={false}
                            onChange={handleDateChange}
                            closeOnSelect={false}
                            locale={DateTime.local().locale}
                            input={false}
                            isValidDate={(current: any) => {
                                const dateTime = DateTime.fromISO(current.toISOString());
                                return isSelectedDateBookable
                                    ? isSelectedDateBookable(dateTime)
                                    : DateTimeService.isSelectedDateBookable(dateTime);
                            }}
                        />
                        <Button
                            type="button"
                            className="completeAction"
                            dataTestId="done-button"
                            onClick={handleDone}
                            text={t('Button_Done')}
                        />
                    </>
                )}
            </SlidingPanel>
        </>
    );
};

export default DatePicker;
