import { connect } from 'react-redux';
import * as actions from 'features/Filters/Redux/actions';
// eslint-disable-next-line import/no-cycle
import ResourceView from 'features/Resources/Common/ResourceView';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import { push } from 'redux-first-history';
import { CreateBookingCommand, UpdateBookedResourceCommand } from 'services/ApiClients/Booking';
import {
    SearchBookedResourcesQuery,
    UpdateSingleOccurrence,
    UpdateWholeSeries,
} from 'services/ApiClients/Booking/Models';
import { ExpandedResources } from 'services/ApiClients/OrganisationStructure';
import Guid from 'utilities/guid';

import { Permissions } from '../../Authentication/types';
import {
    getPersonsByIds,
    getResourceImage,
    getRolesByIds,
    getSpecifiedAttributes,
    getTenantCompanyAttributes,
    resetAttributes,
    resetResourceImage,
    resetRoles,
    resetUsers,
} from '../Common/EditBooking/Redux/actions/info';
import { setSearchTrigger } from '../Common/FloorPlan/Redux/actions';

import {
    bookAResourceAsync,
    getBookingsByResourcesIdsAsync,
    resetBookingsByResourcesIds,
    updateBookedResourceAsync,
    updateSingleOccurrenceAsync,
    updateWholeSeriesFromGrid,
} from './Redux/actions';
import { getFloorPlanData, getGridData, getHeaderData } from './Redux/selectors';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    viewType: state.filters.combined.viewType,
    permissions: state.authentication.user?.permissions || ({} as Permissions),
    userProfileId: state.authentication.user?.userProfileId || '',
    headerData: getHeaderData(state),
    gridData: getGridData(state),
    floorPlanData: getFloorPlanData(state),
    expandedResources: state.filters.global?.expandedResources || [],
    bookingsByResourcesIdsLoadStatus: state.combined.bookingsByResource.loadStatus,
    tenantCompanyAttributes: state.combined.edit.info.tenantCompanyAttributes,
});

const mapDispatchToProps = {
    setSelectedArea: actions.setArea,
    onViewSelect: actions.setCombinedViewType,
    setDisabledAccess: actions.setCombinedDisabledAccess,
    setExpandedResources: (expandedResources: ExpandedResources[]) => actions.setExpandedResources(expandedResources),
    setEquipmentsFilter: actions.setCombinedEquipments,
    resetAdvancedFilters: actions.resetCombinedAdvancedFilters,
    setDate: (selectedDate: DateTime) => actions.setCombinedDate(selectedDate),
    setToggleFilter: (value: number) => actions.setCombinedToggles(value),
    bookAResource: (bookingRequest: CreateBookingCommand) => bookAResourceAsync.request(bookingRequest),
    navigateToEditBooking: (bookingId: Guid, pathname: string, search: string) =>
        push({ pathname: `/bookings${pathname}/${bookingId.toString()}`, search }),
    updateBookedResource: (updateBookedRequest: UpdateBookedResourceCommand) =>
        updateBookedResourceAsync.request(updateBookedRequest),
    updateSingleOccurrence: (updateRequest: UpdateSingleOccurrence) =>
        updateSingleOccurrenceAsync.request(updateRequest),
    getResourceImage: (imageId: Guid) => getResourceImage.request(imageId),
    resetResourceImage,
    getUsers: getPersonsByIds.request,
    getRoles: getRolesByIds.request,
    getAttributes: getSpecifiedAttributes.request,
    resetUsers,
    resetRoles,
    resetAttributes,
    getBookingsByResourcesIds: (params: SearchBookedResourcesQuery) => getBookingsByResourcesIdsAsync.request(params),
    resetBookingsByResourcesIds,
    updateWholeSeries: (param: UpdateWholeSeries) => updateWholeSeriesFromGrid.request(param),
    setFloorPlanTimeLineDurationFilter: actions.setFloorPlanTimeLineDurationFilter,
    setFloorPlanTimeLineFilter: actions.setFloorPlanTimeLineFilter,
    setSearchTrigger,
    getTenantCompanyAttributes: getTenantCompanyAttributes.request,
};

export type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ResourceView);
