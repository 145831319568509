import React, { useRef } from 'react';
import { DayPilotScheduler } from 'daypilot-pro-react';
import { Dictionary } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { UnavailableTime } from 'services/ApiClients/Booking/Models';
import { Resource } from 'services/ApiClients/Resource/Models';

import { CellFillTypes } from '../Models/CellFillTypes';

export enum DraggingBookingType {
    NEW,
    EXISTING_MOVE,
    EXISTING_RESIZE,
}

export interface DraggingBooking {
    type: DraggingBookingType;

    bookingId: string | null;
    resourceId: string;

    start: string;
    end: string;

    reset: () => void;
    refresh?: () => void;
    dispose?: () => void;
}

export type GridRangeTimes = Array<{ start: any; end: any }>;
export type GridRanges = Array<{ row: any; times: GridRangeTimes }>;

type HookResult = {
    reDrawDraggingHandle: (
        type: DraggingBookingType,
        bookingId: string | null,
        resourceId: string,
        start: string,
        end: string
    ) => void;
    checkDragging: (resource: Resource, start: any, end: any) => CellFillTypes | null;
    disposeDraggingHandle: () => void;
};

const disabledResult = (): HookResult => {
    const reDrawDraggingHandle = (
        type: DraggingBookingType,
        bookingId: string | null,
        resourceId: string,
        start: string,
        end: string
    ): void => {};

    const disposeDraggingHandle = (): void => {};

    const checkDragging = (resource: Resource, start: any, end: any): CellFillTypes | null => {
        return null;
    };

    return { reDrawDraggingHandle, disposeDraggingHandle, checkDragging };
};

export const useSetupCleardownPreRender = (
    schedulerRef: React.RefObject<DayPilotScheduler>,
    selectedDate: DateTime,
    timezone: string,
    resourcesDictionary: Dictionary<Resource>,
    unavailableTimes: Dictionary<UnavailableTime[]>,
    beginsHour: number,
    endsHour: number,
    enabled: boolean
): HookResult => {
    const draggingHandle = useRef<DraggingBooking | null>(null);

    if (!enabled) {
        return disabledResult();
    }

    const getWorkingDayRanges = (): [DateTime, DateTime] => {
        const startOfWorkingDay = selectedDate.set({ hour: beginsHour, minute: 0, second: 0, millisecond: 0 });
        const endOfWorkingDay = selectedDate.set({ hour: endsHour, minute: 0, second: 0, millisecond: 0 });
        return [startOfWorkingDay, endOfWorkingDay];
    };

    const getResourceTimeRanges = (resourceId: string, start: string, end: string): GridRangeTimes => {
        const resourceToUse = resourcesDictionary[resourceId];
        const setup = resourceToUse.setupTimeInSeconds;
        const cleardown = resourceToUse.cleardownTimeInSeconds;

        const [startOfWorkingDay, endOfWorkingDay] = getWorkingDayRanges();
        const startTimeLuxon = DateTime.fromISO(start, { zone: timezone });
        let setupTimeLuxon = startTimeLuxon.plus({ seconds: -setup });
        if (setupTimeLuxon <= startOfWorkingDay) {
            setupTimeLuxon = startOfWorkingDay;
        }
        const endTimeLuxon = DateTime.fromISO(end, { zone: timezone });
        let cleardownTime = endTimeLuxon.plus({ seconds: cleardown });
        if (endOfWorkingDay <= cleardownTime) {
            cleardownTime = endOfWorkingDay;
        }

        const ranges: GridRangeTimes = [];

        if (startTimeLuxon.hour > beginsHour || (startTimeLuxon.hour === beginsHour && startTimeLuxon.minute > 0)) {
            ranges.push({
                start: setupTimeLuxon.toISO(),
                end: DateTime.fromISO(start).toISO(),
            });
        }

        if (endTimeLuxon.hour < endsHour || (endTimeLuxon.hour === endsHour && endTimeLuxon.minute === 0)) {
            ranges.push({
                start: DateTime.fromISO(end).toISO(),
                end: cleardownTime.toISO(),
            });
        }
        const filledRanges = ranges.filter((x) => x.start !== x.end);

        return filledRanges;
    };

    const getBookingTimeRanges = (resourceId: string, bookingId: string): GridRangeTimes => {
        const resourceUnavailability = unavailableTimes[resourceId] || [];
        const bookingUnavailability = resourceUnavailability.filter((x) => x.bookingId === bookingId);
        const result = [];

        const [startOfWorkingDay, endOfWorkingDay] = getWorkingDayRanges();

        for (let i = 0; i < bookingUnavailability.length; i++) {
            const unavailabilityObject = bookingUnavailability[i];
            let start = DateTime.fromISO(unavailabilityObject.startDateTime, { zone: timezone });
            let end = DateTime.fromISO(unavailabilityObject.startDateTime, { zone: timezone });

            if (start <= startOfWorkingDay) {
                start = startOfWorkingDay;
            }

            if (endOfWorkingDay <= start) {
                start = endOfWorkingDay;
            }

            if (end <= startOfWorkingDay) {
                end = startOfWorkingDay;
            }

            if (endOfWorkingDay <= end) {
                end = endOfWorkingDay;
            }

            if (start.equals(end)) {
                // eslint-disable-next-line no-continue
                continue;
            }

            result.push({
                start: start.toISO(),
                end: end.toISO(),
            });
        }
        return result;
    };

    const forceRangesReRender = (ranges: GridRanges): void => {
        if (!ranges) {
            return;
        }

        for (let i = 0; i < ranges.length; i++) {
            const range = ranges[i];
            const { row, times } = range;

            if (!row) {
                // eslint-disable-next-line no-continue
                continue;
            }

            let hasUpdates = false;
            for (let j = 0; j < times.length; j++) {
                const time = times[j];
                const { start, end } = time;

                const cells = range.row.cells.forRange(start, end);
                if (!cells || !cells.length) {
                    // eslint-disable-next-line no-continue
                    continue;
                }
                cells.invalidate();
                hasUpdates = true;
            }

            if (hasUpdates) {
                schedulerRef.current?.control.rows.update(row);
            }
        }
    };

    const reDrawBooking = (resourceId: string, bookingId: string): void => {
        const row = schedulerRef.current?.control.rows.find((x) => x.data.id === resourceId);
        const times = getBookingTimeRanges(resourceId, bookingId);
        const ranges: GridRanges = [];
        if (times.length) {
            ranges.push({
                row,
                times,
            });
        }
        forceRangesReRender(ranges);
    };

    const reDrawDraggingHandle = (
        type: DraggingBookingType,
        bookingId: string | null,
        resourceId: string,
        start: string,
        end: string
    ): void => {
        if (!draggingHandle.current) {
            let refresh = (): void => {};
            let dispose = (): void => {};

            if (bookingId) {
                refresh = () => reDrawBooking(resourceId, bookingId);
                dispose = () => {
                    reDrawBooking(resourceId, bookingId);
                };
            }

            draggingHandle.current = {
                type,
                resourceId,
                start,
                end,
                bookingId,

                reset: () => {},
                refresh,
                dispose,
            };
        }
        draggingHandle.current.reset();
        const timeout: any = setTimeout(() => {
            if (!draggingHandle.current) {
                return;
            }

            const cleanup = draggingHandle.current?.refresh;

            const row = schedulerRef.current?.control.rows.find((x) => x.data.id === resourceId);

            const times = getResourceTimeRanges(resourceId, start, end);
            const ranges: GridRanges = [];

            if (times.length) {
                ranges.push({
                    row,
                    times,
                });
            }

            draggingHandle.current = {
                ...draggingHandle.current,
                resourceId,
                start,
                end,
                bookingId,

                reset: () => {},
                refresh: () => forceRangesReRender(ranges),
            };

            forceRangesReRender(ranges);

            if (cleanup) {
                cleanup();
            }
        }, 250);

        draggingHandle.current.reset = () => clearTimeout(timeout);
    };

    const disposeDraggingHandle = (): void => {
        if (!draggingHandle.current) {
            return;
        }

        draggingHandle.current.reset();

        const { refresh, dispose } = draggingHandle.current;

        draggingHandle.current = null;

        if (refresh) {
            refresh();
        }

        if (dispose) {
            dispose();
        }
    };

    const checkDragging = (resource: Resource | null, start: any, end: any): CellFillTypes | null => {
        if (!resource) {
            return null;
        }
        if (!draggingHandle.current) {
            return null;
        }

        let dragPrerender = false;
        if (draggingHandle.current.resourceId === resource.id.toString()) {
            if (
                DateTime.fromISO(draggingHandle.current.start.toString(), { zone: timezone })
                    .plus({ seconds: -resource?.setupTimeInSeconds || 0 })
                    .toISO() < DateTime.fromISO(end.value.toString(), { zone: timezone }).toISO() &&
                DateTime.fromISO(draggingHandle.current.end.toString(), { zone: timezone })
                    .plus({ seconds: resource?.cleardownTimeInSeconds || 0 })
                    .toISO() >= DateTime.fromISO(end.value.toString(), { zone: timezone }).toISO() &&
                (DateTime.fromISO(draggingHandle.current.start, { zone: timezone }).toISO() >=
                    DateTime.fromISO(end.value.toString(), { zone: timezone }).toISO() ||
                    DateTime.fromISO(draggingHandle.current.end.toString(), { zone: timezone }).toISO() <
                        DateTime.fromISO(end.value, { zone: timezone }).toISO())
            ) {
                dragPrerender = true;
            }
        }

        let currentBookingSetupCleardown = false;
        if (
            [DraggingBookingType.EXISTING_MOVE, DraggingBookingType.EXISTING_RESIZE].includes(
                draggingHandle.current.type
            ) &&
            draggingHandle.current.bookingId
        ) {
            const resourceUnavailableTimes = unavailableTimes[resource.id.toString()] || [];
            const bookingUnavailableTimes = resourceUnavailableTimes.filter(
                (x) => x.bookingId === draggingHandle.current?.bookingId
            );

            for (let i = 0; i < bookingUnavailableTimes.length; i++) {
                const bookingUnavailableTime = bookingUnavailableTimes[i];
                const unavailableStart = DateTime.fromISO(bookingUnavailableTime.startDateTime);
                const unavailableEnd = DateTime.fromISO(bookingUnavailableTime.endDateTime);

                const startDate = selectedDate.set({ hour: start.getHours(), minute: start.getMinutes() });
                const endDate = selectedDate.set({ hour: end.getHours(), minute: end.getMinutes() });

                if (
                    Interval.fromDateTimes(unavailableStart, unavailableEnd).contains(startDate) &&
                    startDate < unavailableEnd
                ) {
                    currentBookingSetupCleardown = true;
                    break;
                }

                if (
                    Interval.fromDateTimes(unavailableStart, unavailableEnd).contains(endDate) &&
                    endDate > unavailableStart
                ) {
                    currentBookingSetupCleardown = true;
                    break;
                }
            }
        }

        if (dragPrerender && currentBookingSetupCleardown) {
            return CellFillTypes.SetupCleardown;
        }

        if (dragPrerender) {
            return CellFillTypes.SetupCleardownPreRender;
        }

        if (currentBookingSetupCleardown) {
            return CellFillTypes.Free;
        }

        return null;
    };

    return { reDrawDraggingHandle, disposeDraggingHandle, checkDragging };
};
