import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

import { SearchData } from '../../../utilities/searchUtils';
import {
    generateReport,
    generateReportForExport,
    reportsGetCompanies,
    reportsResetSearchCompaniesResult,
    reportsResetSearchVisitorsResult,
    reportsSearchCompanies,
    reportsSearchExternalVisitors,
    reportsSearchInternalVisitors,
} from '../Redux/actions';

// eslint-disable-next-line import/no-cycle,
import VisitorProfileReport from './VisitorProfileReport';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    searchVisitorsResult: state.reports.searchVisitorsResult,
    isSearchVisitorsInProgress: state.reports.isSearchVisitorsInProgress,
    searchCompaniesResult: state.reports.searchCompaniesResult,
    companiesResult: state.reports.companiesResult,
    isSearchCompaniesInProgress: state.reports.isSearchCompaniesInProgress,
    isGetCompaniesInProgress: state.reports.isGetCompaniesInProgress,
    isReportGenerationInProgress: state.reports.isReportGenerationInProgress,
    visitorProfileReportData: state.reports.reportData?.visitors,
    isGenerationReportForExportInProgress: state.reports.isGenerationReportForExportInProgress,
    reportDataForExport: state.reports.reportDataForExport?.visitors,
});

const mapDispatchToProps = {
    searchInternalVisitors: (searchPersonsRequest: SearchData) =>
        reportsSearchInternalVisitors.request(searchPersonsRequest),
    searchExternalVisitors: (searchPersonsRequest: SearchData) =>
        reportsSearchExternalVisitors.request(searchPersonsRequest),
    searchCompanies: (companyName: string) => reportsSearchCompanies.request(companyName),
    resetVisitorSearch: () => reportsResetSearchVisitorsResult(),
    resetCompaniesSearch: () => reportsResetSearchCompaniesResult(),
    getCompanies: reportsGetCompanies.request,
    generateReport: (query: string) => generateReport.request(query),
    generateReportForExport: generateReportForExport.request,
};

export type VisitorProfileReportProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(VisitorProfileReport);
