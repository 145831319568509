import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/FormElements/Button';
import SlidingPanel from 'components/SlidingPanel';
import { Person } from 'services/ApiClients/Models';
import { Visitor } from 'services/ApiClients/VisitorManagement/Models/Visitor';

import './CheckInPanel.scss';

interface CheckInPanelProps {
    onCheckIn: (siteVisitId: string) => void;
    setPanelOpen: Function;
    isOpen: boolean;
    visitor: Visitor | null;
    host: Visitor | Person | null;
    siteVisitId: string;
}

const CheckInPanel = ({
    onCheckIn,
    setPanelOpen,
    isOpen,
    visitor,
    host,
    siteVisitId,
}: CheckInPanelProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="check-in-panel">
            <SlidingPanel
                title={t('Reception_VisitorCheckIn')}
                className="check-in-panel"
                open={isOpen}
                onClose={() => {
                    setPanelOpen(false);
                }}
                showBackgroundMask
            >
                <div className="fields">
                    {visitor && (
                        <>
                            <div className="title-value">
                                <span className="title">{t('Reception_Visitor')}</span>
                                <span className="value">
                                    {visitor.firstname} {visitor.surname}
                                </span>
                            </div>

                            {visitor.company && (
                                <div className="title-value">
                                    <span className="title">{t('Reception_Company')}</span>
                                    <span className="value">{visitor.company}</span>
                                </div>
                            )}
                        </>
                    )}

                    {host && (
                        <>
                            <div className="title-value">
                                <span className="title">{t('Reception_Host')}</span>
                                <span className="value">
                                    {host.firstname} {host.surname}
                                </span>
                            </div>

                            {host.mobile && (
                                <div className="title-value">
                                    <span className="title">{t('Reception_HostTelephone')}</span>
                                    <span className="value">{host.mobile}</span>
                                </div>
                            )}
                            {host.email && (
                                <div className="title-value">
                                    <span className="title">{t('Reception_HostEmail')}</span>
                                    <span className="value">{host.email}</span>
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="action-buttons ">
                    <Button
                        text={t('Button_Cancel')}
                        type="submit"
                        className="cancel-btn"
                        dataTestId="cancel-btn"
                        onClick={() => setPanelOpen(false)}
                        secondary
                    />
                    <Button
                        text={t('Button_CheckIn')}
                        type="submit"
                        className="checkin-btn"
                        dataTestId="checkin-btn"
                        onClick={() => {
                            onCheckIn(siteVisitId);
                            setPanelOpen(false);
                        }}
                        success
                    />
                </div>
            </SlidingPanel>
        </div>
    );
};

export default CheckInPanel;
