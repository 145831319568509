import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { ListItem, Select } from 'components/Forms';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';

import ActionButtonsPanel from '../../../components/ActionButtonsPanel';
import DataTable from '../../../components/DataTable';
import Pagination from '../../../components/DataTable/Pagination';
import Button from '../../../components/FormElements/Button';
import DateRangePicker from '../../../components/FormElements/DatePicker/DateRangePicker';
import EditColumnsForm from '../../../components/Forms/EditColumnsForm';
import WithLoading from '../../../components/HOC/WithLoading';
import ReportFilterPanel from '../../../components/ReportFilterPanel/ReportFilterPanel';
import Header from '../../../components/SiteSubHeader';
import SlidingPanel from '../../../components/SlidingPanel';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { ResourceType } from '../../../services/ApiClients/Resource';
import { AllListItemOption, LOAD_STATUSES } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface, ReportsActiveColumn } from '../interfaces';
import {
    capitalizeFirstLetter,
    checkEnabling,
    convertDateForExport,
    convertTimeForExport,
    createValuesArray,
    customReportSelectStyles,
    filterArrayByAll,
    generateAndDownloadReportExportFile,
    getAreaListItemsOfSelectedSite,
    getDefaultColumnsValues,
    getSiteGroupListItems,
    getSiteListItems,
    resultPerPageListItems,
} from '../utils';

// eslint-disable-next-line import/no-cycle
import { ResourceBookingsReportProps } from './index';
import { buildQuery, getColumns } from './ResourceBookingsReportUtils';

import './ResourceBookingsReport.scss';

interface GridSettings {
    sortOptions?: GridSortModel;
    resultPerPage: ListItem;
    page: string;
}

const ResourceBookingsReport = ({
    isReportGenerationInProgress,
    generateReport,
    reportData,
    getAreas,
    tenantId,
    siteGroups,
    sites,
    areas,
    areasLoadStatus,
    getDepartments,
    departments,
    isGetDepartmentsInProgress,
    is24HoursTimeFormat,
    isGenerationReportForExportInProgress,
    reportDataForExport,
    generateReportForExport,
}: ResourceBookingsReportProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [gridSettings, setGridSettings] = useState<GridSettings>({
        page: '1',
        resultPerPage: resultPerPageListItems[2],
        sortOptions: [{ field: 'startDateTime', sort: 'asc' }],
    });
    const defaultFilter = {
        selectedSiteGroup: AllListItemOption,
        selectedSite: AllListItemOption,
        selectedArea: AllListItemOption,
        selectedResourceType: AllListItemOption,
        dateRange: { from: DateTime.now(), to: DateTime.now() },
    };

    const dateRange = location.state
        ? {
              from: DateTime.fromISO(location.state.dateRange.from),
              to: DateTime.fromISO(location.state.dateRange.to),
          }
        : null;
    const [appliedReportsFilter, setAppliedReportsFilter] = useState<any>(
        location.state ? { ...location.state, dateRange } : defaultFilter
    );
    const [reportsFilter, setReportsFilter] = useState<any>(
        location.state ? { ...location.state, dateRange } : defaultFilter
    );

    const siteGroupListItems = useMemo(() => getSiteGroupListItems(siteGroups[tenantId], sites), [siteGroups]);

    const siteListItems = useMemo(
        () => getSiteListItems(sites, reportsFilter.selectedSiteGroup),
        [sites, reportsFilter.selectedSiteGroup]
    );

    const areaListItems = useMemo(
        () => getAreaListItemsOfSelectedSite(areas, reportsFilter.selectedSite),
        [areas, reportsFilter.selectedSite]
    );

    const resourceTypeListItems = [
        AllListItemOption,
        { label: ResourceType.DESK.name, value: ResourceType.DESK.value.toString() },
        { label: ResourceType.ROOM.name, value: ResourceType.ROOM.value.toString() },
    ];

    const bookingStatusListItems = [
        { label: 'Confirmed', value: 'Confirmed' },
        { label: 'Curtailed', value: 'Curtailed' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Abandoned', value: 'Abandoned' },
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Update pending', value: 'Updatepending' },
    ].sort((a, b) => a.label.localeCompare(b.label));

    const [isFiltersOpened, setIsFiltersOpened] = useState(false);
    const [columnsFilter, setColumnsFilter] = useState<ColumnFilterInterface>({});
    const [addColumnsFilterAfterApply, setAddColumnsFilterAfterApply] = useState<boolean>(false);

    const [activeColumnsFilter, setActiveColumnsFilter] = useState<ActiveColumnFilterInterface>({});

    const [activeFilter, setActiveFilter] = useState('');
    const [activeFilterHeaderName, setActiveFilterHeaderName] = useState('');
    const [activeColumnsFilterArray, setActiveColumnsFilterArray] = useState<any[]>([]);

    const defaultColumnsFilter: ColumnFilterInterface = {
        resourceType: {
            values: [...getDefaultColumnsValues(resourceTypeListItems)],
            enabled: checkEnabling(resourceTypeListItems, reportsFilter.selectedResourceType.value),
        },
        siteGroupName: {
            values: [...getDefaultColumnsValues(siteGroupListItems)],
            enabled: checkEnabling(siteGroupListItems, reportsFilter.selectedSiteGroup.value),
        },
        siteName: {
            values: [...getDefaultColumnsValues(siteListItems)],
            enabled: checkEnabling(siteListItems, reportsFilter.selectedSite.value),
        },
        areaName: {
            values: [...getDefaultColumnsValues(areaListItems)],
            enabled: checkEnabling(areaListItems, reportsFilter.selectedArea.value, addColumnsFilterAfterApply),
        },
        department: { values: departments?.length ? departments : [], enabled: true },
        bookingStatus: {
            values: [...createValuesArray(bookingStatusListItems)],
            enabled: true,
        },
        attendeesCount: {
            values: [{ value: 1, option: 'equal' }],
            enabled: true,
        },
        isPrivate: { values: ['0', '1'], enabled: true },
    };

    useEffect(() => {
        if (reportsFilter.selectedSite.label !== 'All') {
            setAddColumnsFilterAfterApply(false);
        } else {
            setAddColumnsFilterAfterApply(true);
        }
    }, [reportsFilter.selectedSite]);

    useEffect(() => {
        setColumnsFilter({
            ...columnsFilter,
            ...defaultColumnsFilter,
        });
    }, [siteListItems, areaListItems, siteGroupListItems, reportsFilter, departments, addColumnsFilterAfterApply]);

    const updateColumnsFilter = (columnName: string, values: any[]): void => {
        setColumnsFilter({
            ...columnsFilter,
            [columnName]: {
                ...columnsFilter[columnName],
                values,
            },
        });
    };

    const handleFilterClick = (columnName: string, headerName: string): void => {
        if (columnName === 'department' && !departments?.length) {
            getDepartments();
        }
        setIsFiltersOpened(true);
        setActiveFilter(columnName);
        setActiveFilterHeaderName(headerName);

        if (columnName in activeColumnsFilter) {
            updateColumnsFilter(columnName, [...activeColumnsFilter[columnName].values]);
        } else {
            updateColumnsFilter(columnName, [...defaultColumnsFilter[columnName].values]);
        }
    };

    const renderSideBar = (): void => {
        switch (activeFilter) {
            case 'resourceType':
                setActiveColumnsFilterArray(filterArrayByAll(resourceTypeListItems));
                break;
            case 'siteGroupName':
                setActiveColumnsFilterArray(filterArrayByAll(siteGroupListItems));
                break;
            case 'siteName':
                setActiveColumnsFilterArray(filterArrayByAll(siteListItems));
                break;
            case 'areaName':
                setActiveColumnsFilterArray(filterArrayByAll(areaListItems));
                break;
            case 'department':
                setActiveColumnsFilterArray(
                    departments?.length
                        ? [
                              ...departments
                                  ?.map((item) => ({ value: item, label: item }))
                                  .sort((a, b) => a.label.localeCompare(b.label)),
                          ]
                        : []
                );
                break;
            case 'bookingStatus':
                setActiveColumnsFilterArray(bookingStatusListItems);
                break;
            case 'isPrivate':
                setActiveColumnsFilterArray([
                    { value: '0', label: 'False' },
                    { value: '1', label: 'True' },
                ]);
                break;
            default:
                setActiveColumnsFilterArray([]);
        }
    };

    useEffect(() => {
        if (activeFilter) {
            renderSideBar();
        }
    }, [activeFilter, siteListItems, areaListItems, siteGroupListItems, departments]);

    const generateReportWithColumnsFilter = (): void => {
        setIsFiltersOpened(!isFiltersOpened);
        const newActiveColumnFilter = {
            ...activeColumnsFilter,
            [activeFilter]: { values: columnsFilter[activeFilter].values },
        };
        setActiveColumnsFilter(newActiveColumnFilter);
        generateReport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                newActiveColumnFilter
            )
        );
    };

    const applyFilterAndGenerateReport = useCallback(
        (reportsFilterParam?: any) => {
            setAppliedReportsFilter(reportsFilterParam || reportsFilter);
            setGridSettings({ ...gridSettings, page: '1' });
            generateReport(
                buildQuery(
                    Number(gridSettings.resultPerPage.value),
                    1,
                    gridSettings.sortOptions,
                    reportsFilterParam || reportsFilter
                )
            );
            setAddColumnsFilterAfterApply(true);
        },
        [reportsFilter, gridSettings, addColumnsFilterAfterApply]
    );

    const loadPage = (gridState: GridSettings): void => {
        generateReport(
            buildQuery(
                Number(gridState.resultPerPage.value),
                Number(gridState.page),
                gridState.sortOptions,
                appliedReportsFilter,
                activeColumnsFilter
            )
        );
    };

    useEffect(() => {
        loadPage(gridSettings);
    }, []);

    const setGridStateAndLoadPage = (newGridState: GridSettings): void => {
        setGridSettings(newGridState);
        loadPage(newGridState);
    };

    const defaultActiveColumns = getColumns(
        !!siteGroups[tenantId],
        handleFilterClick,
        columnsFilter,
        activeColumnsFilter,
        is24HoursTimeFormat
    ).map((item) => ({
        name: item.headerName,
        active: true,
    }));

    const [isEditColumnsPanelOpen, setEditColumnsPanelOpen] = useState<boolean>(false);
    const [activeColumns, setActiveColumns] = useState<ReportsActiveColumn[]>(defaultActiveColumns);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    useEffect(() => {
        setColumns(
            getColumns(
                !!siteGroups[tenantId],
                handleFilterClick,
                columnsFilter,
                activeColumnsFilter,
                is24HoursTimeFormat
            )
        );
    }, [columnsFilter, activeColumnsFilter]);

    const applyNewActiveColumns = (newColumns: ReportsActiveColumn[]): void => {
        setActiveColumns(newColumns);
    };

    const generateReportForExportCallBack = useCallback(() => {
        const visibleColumns = columns.map((c) => c.field);

        generateReportForExport(
            buildQuery(
                Number(gridSettings.resultPerPage.value),
                1,
                gridSettings.sortOptions,
                reportsFilter,
                activeColumnsFilter,
                true,
                reportData?.totalCount,
                visibleColumns
            )
        );
    }, [gridSettings, reportData, gridSettings, columns]);

    const formatReportForExport = (report?: any[]): any => {
        if (!report) {
            return [];
        }
        const formattedReport = report.map((i) => {
            return {
                ...('friendlyBookingId' in i && { friendlyBookingId: i.friendlyBookingId }),
                ...('resourceType' in i && { resourceType: i.resourceType }),
                ...('resourceName' in i && { resourceName: i.resourceName }),
                ...(!!siteGroups[tenantId] && 'siteGroupName' in i && { siteGroupName: i.siteGroupName }),
                ...('siteName' in i && { siteName: i.siteName }),
                ...('areaName' in i && { areaName: i.areaName }),
                ...('timezone' in i && { timezone: i.timezone }),
                ...('createdDateTime' in i && {
                    createdDateTime: convertDateForExport(i.createdDateTime, i.timezone),
                }),
                ...('createdTime' in i && {
                    createdTime: convertTimeForExport(i.createdDateTime, i.timezone),
                }),
                ...('startDateTime' in i && { startDateTime: convertDateForExport(i.startDateTime, i.timezone) }),
                ...('startTime' in i && { startTime: convertTimeForExport(i.startDateTime, i.timezone) }),
                ...('endDateTime' in i && { endDateTime: convertDateForExport(i.endDateTime, i.timezone) }),
                ...('endTime' in i && { endTime: convertTimeForExport(i.endDateTime, i.timezone) }),
                ...('bookingTitle' in i && { bookingTitle: i.bookingTitle }),
                ...('createdBy' in i && { createdBy: i.createdBy }),
                ...('createdByEmail' in i && { createdByEmail: i.createdByEmail }),
                ...('isPrivate' in i && { isPrivate: capitalizeFirstLetter(i.isPrivate?.toString()) }),
                ...('department' in i && { department: i.department }),
                ...('attendeesCount' in i && { attendeesCount: i.attendeesCount }),
                ...('onBehalfOf' in i && { onBehalfOf: i.onBehalfOf }),
                ...('repeatDetails' in i && { repeatDetails: i.repeatDetails }),
                ...('bookingStatus' in i && { bookingStatus: i.bookingStatus }),
                ...('checkedInDateTime' in i && {
                    checkedInDateTime: convertTimeForExport(i.checkedInDateTime, i.timezone),
                }),
                ...('checkedOutDateTime' in i && {
                    checkedOutDateTime: convertTimeForExport(i.checkedOutDateTime, i.timezone),
                }),
                ...('lastModifiedBy' in i && { lastModifiedBy: i.lastModifiedBy }),
                ...('lastModifiedDate' in i && {
                    lastModifiedDate: convertDateForExport(i.lastModifiedDate, i.timezone),
                }),
                ...('lastModifiedTime' in i && {
                    lastModifiedTime: convertTimeForExport(i.lastModifiedDate, i.timezone),
                }),
                ...('checkedOutBy' in i && { checkedOutBy: i.checkedOutBy }),
            };
        });

        return formattedReport;
    };

    useDidMountEffect(() => {
        if (!isGenerationReportForExportInProgress) {
            const formattedData = formatReportForExport(reportDataForExport?.items);
            generateAndDownloadReportExportFile('Resource Bookings Report', columns, formattedData);
        }
    }, [isGenerationReportForExportInProgress]);

    useEffect(() => {
        const gridColumns = getColumns(
            !!siteGroups[tenantId],
            handleFilterClick,
            columnsFilter,
            activeColumnsFilter,
            is24HoursTimeFormat
        );
        const filteredColumns = gridColumns.filter(
            (item) => activeColumns.find((e) => e.name === item.headerName)?.active
        );
        setColumns(filteredColumns);
    }, [activeColumns, columnsFilter, activeColumnsFilter]);

    return (
        <main className="resource-booking-report">
            <WithLoading
                isLoading={
                    areasLoadStatus === LOAD_STATUSES.LOADING ||
                    isReportGenerationInProgress ||
                    isGenerationReportForExportInProgress
                }
            >
                <Header
                    title={t('ResourceBookingsReport_Title')}
                    dataTestId="resource-booking-title"
                    className="resource-booking-title"
                    rightSideItems={[
                        <div className="edit-btn-n-date-range">
                            <DateRangePicker
                                className="date-range-picker"
                                dataTestId="date-range-picker"
                                dateRange={reportsFilter.dateRange}
                                onChange={(newDateRange) => {
                                    const newFilter = { ...reportsFilter, dateRange: newDateRange };
                                    setReportsFilter(newFilter);
                                    setActiveColumnsFilter({});
                                    applyFilterAndGenerateReport(newFilter);
                                }}
                                name="date-range-picker"
                                title={t('Reports_BookingDateRange')}
                                required={false}
                            />
                            <Button
                                dataTestId="edit-columns-btn"
                                text={t('Reports_EditColumns')}
                                className="edit-btn"
                                onClick={() => {
                                    setEditColumnsPanelOpen(true);
                                }}
                            />
                        </div>,
                    ]}
                />

                <div className="filter-options">
                    {siteGroups[tenantId] && (
                        <Select
                            label={t('Reports_SiteGroups')}
                            name="siteGroup"
                            items={siteGroupListItems}
                            className="site-select"
                            styles={customReportSelectStyles}
                            onChange={(selectedSiteGroup) => {
                                setReportsFilter({
                                    ...reportsFilter,
                                    selectedSiteGroup,
                                    selectedSite: AllListItemOption,
                                });
                                setActiveColumnsFilter({});
                            }}
                            value={reportsFilter.selectedSiteGroup}
                        />
                    )}

                    <Select
                        label={t('Reports_Sites')}
                        name="site"
                        items={siteListItems}
                        styles={customReportSelectStyles}
                        onChange={(selectedSite) => {
                            if (selectedSite?.value !== AllListItemOption.value) {
                                getAreas(selectedSite.value);
                            }
                            setReportsFilter({ ...reportsFilter, selectedSite, selectedArea: AllListItemOption });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedSite}
                    />

                    <Select
                        label={t('Reports_Area')}
                        name="area"
                        items={areaListItems}
                        styles={customReportSelectStyles}
                        isDisabled={reportsFilter.selectedSite?.value === AllListItemOption.value}
                        onChange={(selectedArea) => {
                            setReportsFilter({ ...reportsFilter, selectedArea });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedArea}
                    />

                    <Select
                        label={t('Reports_ResourceType')}
                        name="area"
                        items={resourceTypeListItems}
                        styles={customReportSelectStyles}
                        onChange={(selectedResourceType) => {
                            setReportsFilter({ ...reportsFilter, selectedResourceType });
                            setActiveColumnsFilter({});
                        }}
                        value={reportsFilter.selectedResourceType}
                    />

                    <Button
                        text={t('Button_Apply')}
                        type="submit"
                        className="apply-btn"
                        dataTestId="apply-btn-btn"
                        disabled={isEqual(reportsFilter, appliedReportsFilter)}
                        onClick={applyFilterAndGenerateReport}
                    />
                </div>

                <DataTable
                    columns={columns}
                    rowsData={(activeColumns.some((column) => column.active) && reportData?.items) || []}
                    onSort={(sortOptions: any) => setGridStateAndLoadPage({ ...gridSettings, sortOptions })}
                    sortModel={gridSettings.sortOptions}
                    page={gridSettings.page}
                />

                <ActionButtonsPanel
                    leftSideElement={
                        <Pagination
                            pageString={gridSettings.page}
                            total={reportData?.totalCount || 0}
                            onPageChange={(page: string) => setGridStateAndLoadPage({ ...gridSettings, page })}
                            onPerPageChange={(perPage: ListItem) =>
                                setGridStateAndLoadPage({ ...gridSettings, resultPerPage: perPage, page: '1' })
                            }
                            resultPerPageOptions={resultPerPageListItems}
                            selectedResultPerPage={gridSettings.resultPerPage}
                        />
                    }
                >
                    <>
                        <Button
                            text={t('Button_Back')}
                            className="back-btn"
                            dataTestId="back-btn"
                            onClick={() => navigate(`/reports`)}
                        />
                        <Button
                            primary
                            text={t('Button_ExportReport')}
                            className="export-btn"
                            dataTestId="export-btn"
                            onClick={() => generateReportForExportCallBack()}
                            disabled={reportData?.items?.length === 0}
                        />
                    </>
                </ActionButtonsPanel>

                <SlidingPanel
                    title={t('Reports_CustomColumns')}
                    open={isEditColumnsPanelOpen}
                    showBackgroundMask
                    className="sliding-panel"
                    onClose={() => {
                        setEditColumnsPanelOpen(false);
                    }}
                >
                    <EditColumnsForm
                        activeColumns={activeColumns}
                        applyNewActiveColumns={applyNewActiveColumns}
                        isEditColumnsPanelOpen={isEditColumnsPanelOpen}
                        setEditColumnsPanelOpen={setEditColumnsPanelOpen}
                    />
                </SlidingPanel>

                <ReportFilterPanel
                    isFiltersOpened={isFiltersOpened}
                    activeFilterHeaderName={activeFilterHeaderName}
                    setIsFiltersOpened={setIsFiltersOpened}
                    activeFilter={activeFilter}
                    activeColumnsFilterArray={activeColumnsFilterArray}
                    setColumnsFilter={setColumnsFilter}
                    columnsFilter={columnsFilter}
                    generateReportWithColumnsFilter={generateReportWithColumnsFilter}
                    isLoading={isGetDepartmentsInProgress}
                    countFieldName="attendeesCount"
                />
            </WithLoading>
        </main>
    );
};

export default ResourceBookingsReport;
