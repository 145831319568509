import React, { useEffect } from 'react';
import GeographicStructureSelectorComponent from 'components/SiteHeader/Components/GeographicStructureSelector';
import { GEOGRAPHIC_STRUCTURE_TYPES } from 'features/constants';
import Guid from 'utilities/guid';

// eslint-disable-next-line import/no-cycle
import { GeographicStructureSelectorProps } from '.';

export interface GeographicStructureOption {
    label: string;
    value: string;
}

const GeographicStructureSelector = ({
    setSite,
    selectedSite,
    selectedSiteGroup,
    sitesGeoStructure,
    siteGroupsGeoStructure,
    tenantId,
    getSites,
    getSiteGroups,
    isLoading,
}: GeographicStructureSelectorProps): JSX.Element => {
    useEffect(() => {
        if (!selectedSite) {
            return;
        }

        const siteParentId = selectedSite?.parentId as Guid;
        if (!sitesGeoStructure) {
            getSites(siteParentId);
        }

        if (siteParentId.toString() !== tenantId) {
            getSiteGroups(new Guid(tenantId));
        }
    }, [selectedSite]);

    // view sites
    const onGeographicStructureClick = (geoStructureParentId: string, geoStructureType: string): void => {
        if (geoStructureType === GEOGRAPHIC_STRUCTURE_TYPES.SITE_GROUP) {
            const availableSites = sitesGeoStructure;
            if (!availableSites) {
                getSites(new Guid(geoStructureParentId));
            }
        }
    };

    // view site groups
    const onBreadcrumbClick = (geoStructureParentId: string): void => {
        if (!siteGroupsGeoStructure[geoStructureParentId]) {
            getSiteGroups(new Guid(geoStructureParentId));
        }
    };

    return selectedSite ? (
        <GeographicStructureSelectorComponent
            selectedSite={selectedSite}
            selectedSiteGroup={selectedSiteGroup}
            sitesGeoStructure={sitesGeoStructure}
            siteGroupsGeoStructure={siteGroupsGeoStructure}
            tenantId={tenantId as string}
            isLoading={isLoading}
            onBreadcrumbClick={onBreadcrumbClick}
            onGeographicStructureClick={onGeographicStructureClick}
            onSiteSelect={setSite}
        />
    ) : (
        <div />
    );
};

export default GeographicStructureSelector;
