import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ViewPanelButton, { ViewsPanelButtonProps } from '../ViewsPanelButton';

import './ViewsPanel.scss';

interface ViewsPanelProps {
    controls: ViewsPanelButtonProps[];
}

const ViewsPanel = ({ controls }: ViewsPanelProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="viewsPanel">
            <span className="panelTitle">{t('View_Types')}</span>
            <div className="panelButtons">
                {controls.map((control) => (
                    <ViewPanelButton
                        key={`view-panel-button-${control.name}`}
                        name={control.name}
                        icon={control.icon}
                        onClick={control.onClick}
                        active={control.active}
                        disabled={control.disabled}
                        dataTestId={`view-panel-button-${control.name}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default memo(ViewsPanel);
