import React from 'react';
import { ReactComponent as ReportsOFF } from 'assets/icons/reception/ReportsOFF.svg';
import { ReactComponent as ReportsON } from 'assets/icons/reception/ReportsON.svg';

import BaseHeaderIconButton from '../BaseHeaderIconButton';

export interface SearchIconButtonProps {
    onClick: Function;
    label: string;
    isDisabled?: boolean;
    dataTestId?: string;
}

const ReportsIconButton = (props: SearchIconButtonProps): JSX.Element => {
    const { onClick, label, dataTestId, isDisabled = false } = props;

    return (
        <BaseHeaderIconButton
            className="reports-icon-button"
            onClick={onClick}
            isDisabled={isDisabled}
            dataTestId={dataTestId}
            icon={<ReportsOFF />}
            onHoverIcon={<ReportsON />}
            label={label}
        />
    );
};

export default ReportsIconButton;
