import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'components/Forms';
import {
    CompanyAttributesErrorModel,
    CompanyAttributesModel,
} from 'services/ApiClients/Identity/Models/UpdateUserProfileCommand';
import { SpecifiedCompanyAttributesResponse } from 'services/ApiClients/Organization/Models/CompanyAttributes';

// eslint-disable-next-line import/no-cycle
import { CompanyAttributesProps } from '.';

import './CompanyAttributes.scss';

const customStyles = {
    control: (base: any) => ({
        ...base,
        padding: '2px',
        border: `1px solid #ff0000`,
    }),
    multiValue: (base: any) => ({
        ...base,
        border: `1px solid #1996c7`,
        backgroundColor: '#1996c7',
        color: '#FFF',
        margin: '4px',
        height: '2rem',
        alignItems: 'center',
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        color: '#FFF',
    }),
    multiValueRemove: (base: any) => ({
        ...base,
        color: '#FFF',
        height: '100%',
        marginLeft: '2px',
        ':hover': {
            backgroundColor: '#ebf5fa',
            color: '#1996c7',
        },
    }),
};

const CompanyAttributes = ({
    assignedCompanyAttributes,
    allowEditCompanyAttributes,
    availableCompanyAttributes,
    selectedCompanyAttributes,
    setSelectedCompanyAttributes,
    setCompanyAttributeErrors,
    companyAttributeErrors,
}: CompanyAttributesProps): JSX.Element => {
    const { t } = useTranslation();

    const sortedAssignedAttributes: SpecifiedCompanyAttributesResponse[] = useMemo(() => {
        return assignedCompanyAttributes.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }, [assignedCompanyAttributes]);

    const assignedAttributeIds: string[] = useMemo(() => {
        return selectedCompanyAttributes
            ?.map(({ companyAttributeValues }) => companyAttributeValues)
            .reduce((acc, el) => acc.concat(el), []);
    }, [selectedCompanyAttributes]);

    const handleChange = (event: any, attributeId: any): any => {
        if (event.length > 10) {
            const error: CompanyAttributesErrorModel = {
                companyAttributeId: attributeId.toString(),
                errorMessage: t('MyAccount_CompanyAttributeErrorMessage'),
            };
            setCompanyAttributeErrors([...companyAttributeErrors, error]);
        } else {
            const index = companyAttributeErrors.findIndex((e) => e.companyAttributeId === attributeId.toString());
            // eslint-disable-next-line no-nested-ternary
            const tempErrArr = index > 0 ? companyAttributeErrors.splice(index, 1) : [];
            setCompanyAttributeErrors(tempErrArr);
        }
        const newAttribute: CompanyAttributesModel = {
            companyAttributeId: attributeId,
            companyAttributeValues: event.map((e) => e.value),
        };
        const newArr = selectedCompanyAttributes.filter((att) => att.companyAttributeId !== attributeId);
        if (
            selectedCompanyAttributes.find((item) =>
                item.companyAttributeValues.find((item2) => event.includes(item2))
            ) ||
            !event.length
        ) {
            setSelectedCompanyAttributes([...newArr]);
        } else {
            setSelectedCompanyAttributes([...newArr, newAttribute]);
        }
    };

    const getItems = (attributeId: string): any => {
        return availableCompanyAttributes
            ?.filter((a) => a.id === attributeId)[0]
            .values.map(({ name: valueName, id: valueId }) => {
                return { label: valueName, value: valueId };
            });
    };

    const getInitialValues = (attributeId: string): any => {
        return availableCompanyAttributes
            ?.filter((a) => a.id === attributeId)[0]
            .values.filter((b) => assignedAttributeIds.includes(b.id))
            .map(({ name: valueName, id: valueId }) => {
                return {
                    label: valueName,
                    value: valueId,
                };
            });
    };

    return (
        <div className="account-companyAttributes">
            <h2 className="account-companyAttributes_title">{t('MyAccount_CompanyAttributeSubTitle')}</h2>
            {!allowEditCompanyAttributes &&
                sortedAssignedAttributes.map(({ name: attributeName, id: attributeId, values }) => {
                    return (
                        <div className="row" key={attributeId}>
                            <>
                                <p className="account-attribute">{attributeName}</p>
                                <div className="values-container">
                                    {values.length &&
                                        values.map(({ name: valueName, id: valueId }) => {
                                            return (
                                                <p className="account-attributeValue" key={valueId}>
                                                    {valueName}
                                                </p>
                                            );
                                        })}
                                </div>
                            </>
                        </div>
                    );
                })}
            {allowEditCompanyAttributes &&
                availableCompanyAttributes?.map(({ name: attributeName, id: attributeId }) => {
                    return (
                        <div className="row" key={attributeId}>
                            <MultiSelect
                                label={attributeName}
                                name={`${attributeName}Multi`}
                                items={getItems(attributeId)}
                                initialValue={getInitialValues(attributeId)}
                                placeholder={t('MyAccount_CompanyAttributePlaceholder')}
                                onChange={(event) => handleChange(event, attributeId)}
                                isSearchable
                                id={`myAccount-Attribute${attributeName}`}
                                styles={customStyles}
                                error={
                                    companyAttributeErrors?.filter((e) => e.companyAttributeId === attributeId).length >
                                    0
                                        ? companyAttributeErrors?.filter((e) => e.companyAttributeId === attributeId)[0]
                                              .errorMessage
                                        : 0
                                }
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default CompanyAttributes;
