import { ReactComponent as PinDesk } from 'assets/icons/floorPlan/pins/Pin.svg';
import { ReactComponent as PinRoom } from 'assets/icons/floorPlan/pins/PinRoom.svg';
import { ReactComponent as RestrictedPinDesk } from 'assets/icons/floorPlan/pins/RestrictedPin.svg';
import { ReactComponent as RestrictedPinRoom } from 'assets/icons/floorPlan/pins/RestrictedPinRoom.svg';
import { UserProfile } from 'features/Authentication/types';
import { AM, AM_PM, BOOKING_SLOT_UNITS_MULTIPLIER, DEFAULT_TIME_SLOT, PM } from 'features/constants';
import { SearchDiscriminator } from 'features/Resources/Common/FloorPlan/FloorPlanHeader/FloorPlanSearch/utils';
import { CardProps } from 'features/Resources/Common/FloorPlan/FloorPlanHeader/Timeline/TimeSlotCard';
import { ConfigurationSettings } from 'features/Resources/Common/Redux/Reducer/configuratioReducer';
import { Dictionary, sortBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { BookedResourceSummary } from 'services/ApiClients/Booking';
import { UnavailableTime } from 'services/ApiClients/Booking/Models';
import { BookableDays, Configuration } from 'services/ApiClients/Configuration';
import { findResourceNameByValue, Resource, ResourceType, UnavailableCategory } from 'services/ApiClients/Resource';
import { FloorPin } from 'services/ApiClients/Resource/Models/Resource';
import { SearchResponse } from 'services/ApiClients/Search/Models/SearchResponse';
import ConfigurationService from 'services/ConfigurationService';
// eslint-disable-next-line import/no-cycle
import DateTimeService from 'services/DateTimeService';

import { periodUnitEnum } from '../components/BookingGrid/Models/CellFillTypes';

import { HasPermissionToViewPrivateBookings } from './authorizationUtils';

export const getStartDateByConfigurationType = (
    gridTimeZoneDate: DateTime,
    bookableDays: BookableDays | null,
    selectedDay: string
): DateTime =>
    gridTimeZoneDate
        .startOf('day')
        .plus({
            minutes: bookableDays !== null ? bookableDays[selectedDay]?.bookingWindowStartMinutesFromMidnight : 0,
        })
        .toUTC();

export const getEndDateByConfigurationType = (
    gridTimeZoneDate: DateTime,
    bookableDays: BookableDays | null,
    selectedDay: string
): DateTime =>
    gridTimeZoneDate
        .startOf('day')
        .plus({
            minutes: bookableDays !== null ? bookableDays[selectedDay]?.bookingWindowEndMinutesFromMidnight : 0,
        })
        .toUTC();

export const getStartEndTimeCompareToResourceConfigurations = (
    allSiteConfigurations: ConfigurationSettings,
    resourceType: number,
    selectedDay: string,
    selectedDate: DateTime,
    timeSlotStartTime: DateTime,
    timeSlotEndTime: DateTime,
    timezone: string
): DateTime[] => {
    const startTimeFromConfiguration = selectedDate.startOf('day').plus({
        minutes: allSiteConfigurations[resourceType]?.bookableDays[selectedDay]?.bookingWindowStartMinutesFromMidnight,
    });

    const endTimeFromConfiguration = selectedDate.startOf('day').plus({
        minutes: allSiteConfigurations[resourceType]?.bookableDays[selectedDay]?.bookingWindowEndMinutesFromMidnight,
    });

    const startTimeByConfiguration =
        startTimeFromConfiguration > timeSlotStartTime.setZone(timezone)
            ? startTimeFromConfiguration
            : timeSlotStartTime.setZone(timezone);

    const endTimeByConfiguration =
        endTimeFromConfiguration < timeSlotEndTime.setZone(timezone)
            ? endTimeFromConfiguration
            : timeSlotEndTime.setZone(timezone);

    if (
        (timeSlotStartTime.setZone(timezone) <= startTimeFromConfiguration &&
            timeSlotEndTime.setZone(timezone) <= startTimeFromConfiguration) ||
        (timeSlotStartTime.setZone(timezone) >= endTimeFromConfiguration &&
            timeSlotEndTime.setZone(timezone) >= endTimeFromConfiguration)
    ) {
        return [timeSlotStartTime, timeSlotEndTime];
    }
    return [startTimeByConfiguration, endTimeByConfiguration];
};

export const getIsSameDayStartDate = (
    currentDateTime: DateTime,
    bookingSlotUnits: number | null | undefined
): DateTime => {
    return bookingSlotUnits
        ? DateTimeService.getNextTimeSlotAsDate(
              currentDateTime,
              bookingSlotUnits * BOOKING_SLOT_UNITS_MULTIPLIER
          ).toUTC()
        : DateTime.now();
};

export enum FLOORPIN_STATUSES {
    AVAILABLE = 'available',
    BOOKED = 'booked',
    MY_BOOKING = 'mybooking',
    ON_BEHALF_OF_ME = 'on-behalf-of-me',
    ON_BEHALF_OF_INTERNAL = 'on-behalf-of-internal',
    UNAVAILABLE = 'unavailable',
    UNAVAILABLE_COVID = 'unavailable_COVID',
    NOT_WORKING_TIME = 'not-working-time',
    RESTRICTED = 'restricted',
    BOOKED_IN_THE_PAST = 'booked-in-the-past',
    IS_PRIVATE = 'is-private',
}

const mapUnavailableCategoryToFloorPinStatus = (category?: UnavailableCategory): FLOORPIN_STATUSES => {
    if (category === UnavailableCategory.Covid) {
        return FLOORPIN_STATUSES.UNAVAILABLE_COVID;
    }
    return FLOORPIN_STATUSES.UNAVAILABLE;
};

export const isBookingInTimeSlot = (
    bookingStartTime: DateTime,
    bookingEndTime: DateTime,
    timeSlotStart: DateTime,
    timeSlotEnd: DateTime
): boolean => {
    return (
        (timeSlotStart <= bookingStartTime && bookingStartTime < timeSlotEnd) ||
        (timeSlotStart < bookingEndTime && bookingEndTime <= timeSlotEnd) ||
        (bookingStartTime < timeSlotStart && timeSlotEnd < bookingEndTime)
    );
};

const resourceTypeFromNumber = (resourceTypeNumber: number): string => {
    switch (resourceTypeNumber) {
        case ResourceType.DESK.value:
            return ResourceType.DESK.name;
        case ResourceType.ROOM.value:
            return ResourceType.ROOM.name;
        default:
            return ResourceType.PARKING.name;
    }
};

export const getFloorPinStatus = (
    floorPin: FloorPin,
    selectedDate: DateTime,
    user: UserProfile,
    unavailabilityTimes: Dictionary<UnavailableTime[]>,
    timezone: string,
    allResourceTypeConfigurations: Configuration[] | null,
    allTenantConfigurations: ConfigurationSettings,
    allSiteConfigurations: ConfigurationSettings,
    timeSlot: CardProps,
    duration: any
): FLOORPIN_STATUSES => {
    const resourceTypeString = resourceTypeFromNumber(floorPin.resourceType).toLowerCase();
    const pinConfiguration: Configuration | null =
        allResourceTypeConfigurations?.filter((x) => x.configurationType === resourceTypeString)[0] ?? null;

    const configuration: Configuration = {
        bookableDays: pinConfiguration?.bookableDays ?? {},
        bookingSlotUnits: pinConfiguration?.bookingSlotUnits || DEFAULT_TIME_SLOT,
        noticePeriodDuration: pinConfiguration?.noticePeriodDuration ?? 0,
        noticePeriodUnit: pinConfiguration?.noticePeriodUnit ?? 0,
        allowPrivateBookings: pinConfiguration?.allowPrivateBookings ?? false,
        configurationType: '',
        reservationWindowInSeconds: pinConfiguration?.reservationWindowInSeconds ?? 0,
        setAllBookingsAsPrivate: pinConfiguration?.setAllBookingsAsPrivate ?? false,
        isRepeatBookingsEnabled: pinConfiguration?.isRepeatBookingsEnabled ?? false,
    };

    const { startTime: timeSlotStartTime, endTime: timeSlotEndTime, label } = timeSlot || ({} as CardProps);
    const midnight = timeSlotStartTime.startOf('day');
    const noon = midnight.plus({ hours: 12 });
    const isAMEnabled = midnight <= timeSlotStartTime && timeSlotStartTime < noon;
    const isPMEnabled = noon <= timeSlotEndTime && timeSlotEndTime <= midnight.plus({ day: 1 });
    const selectedDay = selectedDate.setZone(timezone).toFormat('cccc').toLowerCase();

    const nextBooking = floorPin.bookings.find((booking) => {
        const start = DateTime.fromISO(booking.startDateTime).setZone(timezone);
        const end = DateTime.fromISO(booking.endDateTime).setZone(timezone);

        return isBookingInTimeSlot(start, end, timeSlotStartTime, timeSlotEndTime);
    });
    if (nextBooking) {
        const isCurrentUserBooking = user?.userProfileId?.toString() === nextBooking?.createdByUserId?.toString();
        const isOnBehalfOfUserBooking = user?.userProfileId === nextBooking?.onBehalfOf?.personId;

        if (
            (nextBooking?.isPrivate || allTenantConfigurations?.[floorPin?.resourceType].setAllBookingsAsPrivate) &&
            (!isCurrentUserBooking || !isOnBehalfOfUserBooking)
        ) {
            return FLOORPIN_STATUSES.IS_PRIVATE;
        }

        if (isOnBehalfOfUserBooking) {
            return FLOORPIN_STATUSES.ON_BEHALF_OF_ME;
        }

        if (nextBooking?.onBehalfOf?.personId) {
            return FLOORPIN_STATUSES.ON_BEHALF_OF_INTERNAL;
        }

        if (isCurrentUserBooking) {
            return FLOORPIN_STATUSES.MY_BOOKING;
        }

        return FLOORPIN_STATUSES.BOOKED;
    }

    const currentDateTime = DateTime.utc();
    const startCurrentDate = currentDateTime.setZone(timezone).plus({ days: 1 }).startOf('day');
    const endDate = selectedDate.setZone(timezone).plus({ days: 1 }).startOf('day');

    if (floorPin.restricted) {
        return FLOORPIN_STATUSES.RESTRICTED;
    }

    const [startTimeCompareToConfiguration, endTimeCompareToConfiguration] =
        getStartEndTimeCompareToResourceConfigurations(
            allSiteConfigurations,
            floorPin.resourceType,
            selectedDay,
            selectedDate,
            timeSlotStartTime,
            timeSlotEndTime,
            timezone
        );

    if (
        !ConfigurationService.isRangeAvailable(
            allSiteConfigurations[floorPin.resourceType],
            startTimeCompareToConfiguration.setZone(timezone),
            endTimeCompareToConfiguration.setZone(timezone),
            selectedDay
        )
    ) {
        return FLOORPIN_STATUSES.NOT_WORKING_TIME;
    }

    if (
        endDate.toMillis() < startCurrentDate.toMillis() ||
        (timeSlotStartTime.toMillis() < currentDateTime.toMillis() &&
            timeSlotEndTime.toMillis() < currentDateTime.toMillis())
    ) {
        return FLOORPIN_STATUSES.BOOKED_IN_THE_PAST;
    }

    if (floorPin.unavailableUntilFurtherNotice) {
        return mapUnavailableCategoryToFloorPinStatus(floorPin.unavailableUntilFurtherNoticeCategory);
    }

    const todayDate = DateTime.now().setZone(timezone);

    if (floorPin.noticePeriodDuration && floorPin.noticePeriodUnit !== null) {
        if (
            timeSlotStartTime.startOf('day') >
            todayDate
                .plus({ [periodUnitEnum[floorPin.noticePeriodUnit]]: floorPin.noticePeriodDuration })
                .startOf('day')
        ) {
            return FLOORPIN_STATUSES.UNAVAILABLE;
        }
    }

    if (configuration?.noticePeriodDuration && configuration?.noticePeriodUnit !== null) {
        if (
            timeSlotStartTime.startOf('day') >
            todayDate
                .plus({
                    [periodUnitEnum[configuration?.noticePeriodUnit ?? 0]]: configuration?.noticePeriodDuration,
                })
                .startOf('day')
        ) {
            return FLOORPIN_STATUSES.UNAVAILABLE;
        }
    }

    if (((!isAMEnabled && label === AM) || (!isPMEnabled && label === PM)) && duration === AM_PM) {
        return FLOORPIN_STATUSES.UNAVAILABLE;
    }

    const resourceUnavailableTimes = unavailabilityTimes[floorPin.id.toString()] || [];

    for (let i = 0; i < resourceUnavailableTimes.length; i++) {
        const resourceUnavailableTime = resourceUnavailableTimes[i];
        const unavailableStart = DateTime.fromISO(resourceUnavailableTime.startDateTime);
        const unavailableEnd = DateTime.fromISO(resourceUnavailableTime.endDateTime);

        let unavailable = false;
        if (Interval.fromDateTimes(unavailableStart, unavailableEnd).contains(timeSlotStartTime)) {
            unavailable = true;
        }

        if (Interval.fromDateTimes(unavailableStart, unavailableEnd).contains(timeSlotEndTime)) {
            unavailable = true;
        }

        if (unavailableStart.toMillis() === timeSlotEndTime.toMillis()) {
            unavailable = false;
        }

        if (unavailable) {
            return mapUnavailableCategoryToFloorPinStatus(resourceUnavailableTime.category);
        }
    }

    return FLOORPIN_STATUSES.AVAILABLE;
};

const mapFloorPinsToBookedStatus = (floorPins: FloorPin[]): FloorPin[] => {
    return floorPins.map((floorPin) => {
        return { ...floorPin, isPartOfRepeat: floorPin.bookings[0]?.isPartOfRepeat };
    });
};

const getFloorPinBookings = (
    selectedDate: DateTime,
    resource: Resource,
    bookings: BookedResourceSummary[]
): BookedResourceSummary[] => {
    const currentDateTime = DateTime.utc();
    const isSameDay = currentDateTime.hasSame(selectedDate, 'day');

    const floorPinBookings = sortBy(
        bookings.filter((item) => item.resourceId === resource.id),
        'startDateTime'
    );

    if (!isSameDay) {
        return floorPinBookings;
    }

    return floorPinBookings.filter(
        (item) =>
            DateTime.fromISO(item.startDateTime) > currentDateTime ||
            DateTime.fromISO(item.endDateTime) > currentDateTime
    );
};

export const mapBookingsToFloorPins = (
    resources: Resource[] = [],
    bookings: BookedResourceSummary[],
    selectedDate: DateTime
): FloorPin[] => {
    return mapFloorPinsToBookedStatus(
        resources.map((resource) => ({
            ...resource,
            bookings: getFloorPinBookings(selectedDate, resource, bookings),
        }))
    );
};

export const getFloorPinTooltipText = (
    name: string,
    booking: BookedResourceSummary,
    status: string | undefined,
    user: UserProfile,
    resourceType: number
): string => {
    const canViewPrivateBookings =
        user?.permissions && HasPermissionToViewPrivateBookings(resourceType, user.permissions);
    const isCurrentUserBooking = booking?.createdByUserId.toString() === user?.userProfileId?.toString();
    const isOnBehalfOfUserBooking = user?.userProfileId === booking?.onBehalfOf?.personId;

    switch (status) {
        case FLOORPIN_STATUSES.MY_BOOKING:
        case FLOORPIN_STATUSES.ON_BEHALF_OF_ME:
            return `${name}${booking.bookingDisplayName ? `: ${booking.bookingDisplayName}` : ''}`;
        case FLOORPIN_STATUSES.ON_BEHALF_OF_INTERNAL:
            return `${name}: ${booking.onBehalfOf?.displayName}`;
        case FLOORPIN_STATUSES.BOOKED:
            return `${name}: ${booking.createdByDisplayName}`;
        case FLOORPIN_STATUSES.IS_PRIVATE:
            if (canViewPrivateBookings || isCurrentUserBooking) {
                return `${name}: ${booking.createdByDisplayName}`;
            }
            if (isOnBehalfOfUserBooking) {
                return `${name}: ${booking.onBehalfOf?.displayName}`;
            }
            return `${name}: Private`;
        default:
            return `${name}`;
    }
};

export const getFloorPinTooltipTextFromSearch = (searchResultItem: SearchResponse | null, t: any): string => {
    if (searchResultItem) {
        const { discriminator, onBehalfOf, createdBy, resourceName, restrictedTo } = searchResultItem;

        const isResourceDiscriminator = discriminator === SearchDiscriminator.RESOURCE;
        const isBookingDiscriminator = discriminator === SearchDiscriminator.BOOKING;

        const isRestrictedToSingleUser = restrictedTo?.firstname && restrictedTo?.surname;
        const restrictedToText = `${t('FloorPlan_Search_RestrictedTo')} ${restrictedTo?.surname}, ${
            restrictedTo?.firstname
        }`;

        if (isResourceDiscriminator) {
            return isRestrictedToSingleUser ? `${restrictedToText}` : `${resourceName}`;
        }

        if (isBookingDiscriminator) {
            const bookerName = onBehalfOf?.oboType
                ? `${onBehalfOf?.surname}, ${onBehalfOf?.firstname}`
                : `${createdBy?.surname}, ${createdBy?.firstname}`;

            return `${resourceName}: ${bookerName}`;
        }
    }

    return '';
};

export const getTooltipOrientation = (
    xCoordinate: number,
    yCoordinate: number,
    maxWidth: number,
    width = 0,
    height = 0
): string => {
    if (xCoordinate + maxWidth / 2 > width) {
        return 'left';
    }
    if (xCoordinate - maxWidth / 2 < 0) {
        return 'right';
    }
    // only show tooltip below if the pin is in the top 10% of the image height
    if (yCoordinate < height / 10) {
        return 'bottom';
    }
    return 'top';
};

export const getTooltipXYPosition = (position: string, pinSize: number): {} => {
    switch (position) {
        case 'right':
            return { left: '100%', top: pinSize / 2 };
        case 'left':
            return { right: '100%', top: pinSize / 2 };
        case 'bottom':
            return { top: pinSize, left: pinSize / 2 };
        default:
            return { bottom: pinSize, left: pinSize / 2 };
    }
};

const getFloorPinIconsByResourceType = (resourceType: string): any => {
    switch (resourceType) {
        case ResourceType.DESK.name: {
            return {
                default: PinDesk,
                restricted: RestrictedPinDesk,
            };
        }
        case ResourceType.ROOM.name: {
            return { default: PinRoom, restricted: RestrictedPinRoom };
        }
        default:
            return {};
    }
};

export const getFloorPinIconByStatus = (status = '', resourceType: number): any => {
    const pinObject = getFloorPinIconsByResourceType(findResourceNameByValue(resourceType));
    return status === FLOORPIN_STATUSES.AVAILABLE ||
        status === FLOORPIN_STATUSES.MY_BOOKING ||
        status === FLOORPIN_STATUSES.ON_BEHALF_OF_ME ||
        status === FLOORPIN_STATUSES.BOOKED_IN_THE_PAST
        ? pinObject.default
        : pinObject.restricted;
};

export const roundTimeToNearestTimeSlot = (date: DateTime, timeSlotInMinutes = 15): DateTime => {
    const rounded = Math.round(date.minute / timeSlotInMinutes) * timeSlotInMinutes;
    const roundedDate = date.set({ minute: rounded, second: 0, millisecond: 0 });

    return roundedDate;
};
