import { DateTime } from 'luxon';

import { DEFAULT_DATE_FORMAT, TWENTY_FOUR_HOUR_FORMAT } from '../features/constants';
import { BookableDays, DaysConfigurationResponse } from '../services/ApiClients/Configuration/Models';
import DateTimeService, { TimeSlotOption } from '../services/DateTimeService';

export const formatBookableDays = (daysConfiguration: DaysConfigurationResponse): BookableDays => {
    const bookableDays: BookableDays = {
        monday: {
            bookingWindowStartMinutesFromMidnight: daysConfiguration.mondayReservationWindowStartMinutesFromMidnight,
            bookingWindowEndMinutesFromMidnight: daysConfiguration.mondayReservationWindowEndMinutesFromMidnight,
        },
        tuesday: {
            bookingWindowStartMinutesFromMidnight: daysConfiguration.tuesdayReservationWindowStartMinutesFromMidnight,
            bookingWindowEndMinutesFromMidnight: daysConfiguration.tuesdayReservationWindowEndMinutesFromMidnight,
        },
        wednesday: {
            bookingWindowStartMinutesFromMidnight: daysConfiguration.wednesdayReservationWindowStartMinutesFromMidnight,
            bookingWindowEndMinutesFromMidnight: daysConfiguration.wednesdayReservationWindowEndMinutesFromMidnight,
        },
        thursday: {
            bookingWindowStartMinutesFromMidnight: daysConfiguration.thursdayReservationWindowStartMinutesFromMidnight,
            bookingWindowEndMinutesFromMidnight: daysConfiguration.thursdayReservationWindowEndMinutesFromMidnight,
        },
        friday: {
            bookingWindowStartMinutesFromMidnight: daysConfiguration.fridayReservationWindowStartMinutesFromMidnight,
            bookingWindowEndMinutesFromMidnight: daysConfiguration.fridayReservationWindowEndMinutesFromMidnight,
        },
        saturday: {
            bookingWindowStartMinutesFromMidnight: daysConfiguration.saturdayReservationWindowStartMinutesFromMidnight,
            bookingWindowEndMinutesFromMidnight: daysConfiguration.saturdayReservationWindowEndMinutesFromMidnight,
        },
        sunday: {
            bookingWindowStartMinutesFromMidnight: daysConfiguration.sundayReservationWindowStartMinutesFromMidnight,
            bookingWindowEndMinutesFromMidnight: daysConfiguration.sundayReservationWindowEndMinutesFromMidnight,
        },
    };

    const keys = Object.keys(bookableDays);

    keys.forEach((k) => {
        if (
            bookableDays[k].bookingWindowEndMinutesFromMidnight === -1 ||
            bookableDays[k].bookingWindowStartMinutesFromMidnight === -1
        ) {
            delete bookableDays[k];
        }
    });

    return bookableDays;
};

export const getBookingTimeSlots = (
    bookableDays: BookableDays,
    selectedDay: string,
    timeInterval: number
): TimeSlotOption[] => {
    if (bookableDays[selectedDay]) {
        const timeSlots: TimeSlotOption[] = DateTimeService.getTimeSlots(
            Math.floor(bookableDays[selectedDay].bookingWindowStartMinutesFromMidnight / timeInterval),
            Math.ceil(bookableDays[selectedDay].bookingWindowEndMinutesFromMidnight / timeInterval),
            timeInterval,
            TWENTY_FOUR_HOUR_FORMAT
        );

        return timeSlots;
    }
    return [];
};

export const getValidBookingWindowStartMinutes = (
    nowDateTime: DateTime,
    dateTime: DateTime,
    timeSlotInterval: number,
    bookingWindowStartMinutes: number
): number => {
    let startTimeInMinutes = 0;

    if (nowDateTime && nowDateTime?.toFormat(DEFAULT_DATE_FORMAT) === dateTime.toFormat(DEFAULT_DATE_FORMAT)) {
        // first available time slot in minutes for today
        const nearestSlotStartTime = nowDateTime.set({
            minute: Math.ceil(DateTime.now().minute / timeSlotInterval) * timeSlotInterval,
        });
        const nearestSlotStartTimeInMinutes = nearestSlotStartTime.hour * 60 + nearestSlotStartTime.minute;

        startTimeInMinutes =
            nearestSlotStartTimeInMinutes > bookingWindowStartMinutes
                ? nearestSlotStartTimeInMinutes
                : bookingWindowStartMinutes;
    } else {
        startTimeInMinutes = bookingWindowStartMinutes;
    }

    return startTimeInMinutes;
};

export const getBookingTimeSlotsConsideringCurrentTime = (
    bookableDays: BookableDays,
    date: DateTime,
    nowDateTime: DateTime,
    timeInterval: number,
    hourFormat: string
): TimeSlotOption[] => {
    const selectedDay = date.toFormat('cccc').toLowerCase();

    const startTimeInMinutes = getValidBookingWindowStartMinutes(
        nowDateTime,
        date,
        timeInterval,
        bookableDays[selectedDay].bookingWindowStartMinutesFromMidnight
    );

    const timeSlots = bookableDays[selectedDay]
        ? DateTimeService.getTimeSlots(
              Math.floor(startTimeInMinutes / timeInterval),
              Math.ceil((bookableDays[selectedDay].bookingWindowEndMinutesFromMidnight - timeInterval) / timeInterval),
              timeInterval,
              hourFormat
          )
        : [];

    return timeSlots;
};

export const getAvailableDateTimeSlot = (
    startDateTimeSlot: DateTime,
    bookableDays: BookableDays,
    timeInterval: number,
    nowDateTime: DateTime
): DateTime => {
    const timeSlots: TimeSlotOption[] = getBookingTimeSlots(
        bookableDays,
        startDateTimeSlot.toFormat('cccc').toLowerCase(),
        timeInterval
    );
    const timeSlot = timeSlots.filter((ts) => ts.label === startDateTimeSlot.toFormat('HH:mm'));

    if (timeSlot.length > 0) {
        // slot is available for current day, so return this
        return startDateTimeSlot;
    }

    // get first available time slot for current day
    if (
        startDateTimeSlot.toFormat(DEFAULT_DATE_FORMAT) === nowDateTime.toFormat(DEFAULT_DATE_FORMAT) &&
        startDateTimeSlot.toFormat('HH:mm') < timeSlots[0]?.label
    ) {
        const time = timeSlots[0].label.split(':');

        const firstAvailableDateTimeSlot = nowDateTime.set({
            hour: parseInt(time[0], 10),
            minute: parseInt(time[1], 10),
        });
        return firstAvailableDateTimeSlot;
    }

    // checking of next days
    const daysCountToCheck = 7;
    for (
        let date: DateTime = startDateTimeSlot.plus({ days: 1 });
        date < startDateTimeSlot.plus({ days: daysCountToCheck });
        date = date.plus({ days: 1 })
    ) {
        const nextDayTimeSlots: TimeSlotOption[] = getBookingTimeSlots(
            bookableDays,
            date.toFormat('cccc').toLowerCase(),
            timeInterval
        );

        if (nextDayTimeSlots && nextDayTimeSlots.length > 0) {
            const time = nextDayTimeSlots[0].label.split(':');
            const firstAvailableDateTimeSlot = date.set({
                hour: parseInt(time[0], 10),
                minute: parseInt(time[1], 10),
            });
            return firstAvailableDateTimeSlot;
        }
    }

    return startDateTimeSlot;
};
