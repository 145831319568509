import * as constants from 'features/constants';
import { getBookedResource } from 'features/Resources/Common/EditBooking/BookingInfo/utils';
import { DateTime } from 'luxon';
import { RootState } from 'PortalTypes';
import { createSelector } from 'reselect';
import { BookedResource, Booking } from 'services/ApiClients/Booking';
import { Configuration } from 'services/ApiClients/Configuration';
import { Person } from 'services/ApiClients/Models';
import { ResourceType } from 'services/ApiClients/Resource';
import {
    HasPermissionToAddOnBehalfOf,
    HasPermissionToEditNotOwnBooking,
    HasPermissionToEditPastBooking,
    HasPermissionToViewPrivateBookings,
} from 'utilities/authorizationUtils';

import { Access } from '../../../../../enums/authorization';
import { Permissions } from '../../../../Authentication/types';
import { ConfigurationSettings } from '../../Redux/Reducer/configuratioReducer';
import { formatAuditLogs } from '../History/utils';
import { formatFormValues } from '../utils';

export const getHistoryData = createSelector(
    (state: RootState) => state.combined.edit,
    (state: RootState) => state.filters,
    (state: RootState) => state.authentication.user,
    (edit, filters, user) => ({
        requiresLoading: edit.history.loadStatus === constants.LOAD_STATUSES.REQUIRED,
        isLoading: edit.history.loadStatus === constants.LOAD_STATUSES.LOADING,
        auditLogs: formatAuditLogs(
            edit.history.logs,
            filters.global.site?.timezone || constants.DEFAULT_TIMEZONE,
            user?.extendedHourFormat || constants.TWENTY_FOUR_HOUR_FORMAT
        ),
        booking: edit.info.item as Booking,
        title: edit.info.resource?.name,
    })
);

export const getProviderData = createSelector(
    (state: RootState) => state.combined.edit,
    (state: RootState) => state.filters,
    (state: RootState) => state.referenceData,
    (state: RootState) => state.commonResources,
    (state: RootState) => state.authentication.user?.permissions,

    (edit, filters, referenceData, commonResources, permissions) => {
        const accessLevel = permissions?.Access || 0;
        const selectedSite = filters.global.site;
        const areDesksAvailable =
            referenceData.tenantInfo?.areDesksAvailable &&
            selectedSite?.areDesksAvailable &&
            accessLevel & Access.DeskBooking;
        const areRoomsAvailable =
            referenceData.tenantInfo?.areRoomsAvailable &&
            selectedSite?.areRoomsAvailable &&
            accessLevel & Access.RoomBooking;

        return {
            booking: edit.info.item,
            resources: referenceData.combinedResource.items,
            site: filters.global.site,
            areas: referenceData.area.items,
            selectedDate: filters.combined.selectedDate || DateTime.utc(),
            resourceTypes: [
                ...(areDesksAvailable ? [ResourceType.DESK.value] : []),
                ...(areRoomsAvailable ? [ResourceType.ROOM.value] : []),
            ],
            isLoadingError: edit.info.failedStatus === constants.LOAD_STATUSES.FAILED,
            operationInProgress: edit.info.operationInProgress,
            resource: edit.info.resource,
        };
    }
);

export const getContainerData = createSelector(
    (state: RootState) => state.combined.edit,
    (state: RootState) => state.filters,
    (state: RootState) => state.authentication.user,
    (state: RootState) => state.router,
    (state: RootState) => state.referenceData,
    (state: RootState) => state.commonResources.configuration.tenantSettings,
    (state: RootState) => state.commonResources.configuration.siteSettings,
    (state: RootState) => state.combined.bookingsByResource,
    (edit, filters, user, router, referenceData, tenantSettings, siteSettings, bookingsByResource) => {
        const searchParams = new URLSearchParams(router.location?.search);
        const selectedSite = filters.global.site;
        const { resource } = edit.info;
        const timezone = (selectedSite && selectedSite.timezone) || constants.DEFAULT_TIMEZONE;
        const combinedBooking = edit.info.item as Booking;
        const selectedDate = searchParams.get('selectedDate') || '';
        const permissions = user?.permissions || ({} as Permissions);
        const bookedResource = getBookedResource(
            combinedBooking,
            DateTime.fromISO(selectedDate),
            timezone
        ) as BookedResource;
        const areaId = bookedResource?.geographicStructures
            ? bookedResource.geographicStructures[0].geographicStructureId
            : null;
        const siteId = selectedSite?.id.toString() as string;
        const area = referenceData.area.items[siteId]?.find((gs) => gs.id === areaId);
        const occurrence = edit.info.occurrences.find((item) =>
            DateTime.fromISO(item.startDateTime).hasSame(DateTime.fromISO(selectedDate), 'day')
        );

        return {
            now: DateTime.now().setZone(timezone),
            permissions,
            currentUserId: user?.userProfileId,
            timezone,
            operationInProgress: edit.info.operationInProgress,
            isCurrentUserBooking: combinedBooking?.createdByUserId.toString() === user?.userProfileId,
            isOnBehalfOfUser: user?.userProfileId === combinedBooking?.onBehalfOf?.personId,
            canEditNotOwnBooking: !!(
                bookedResource && HasPermissionToEditNotOwnBooking(bookedResource.resourceType, permissions)
            ),
            canEditPastBooking: !!(
                bookedResource && HasPermissionToEditPastBooking(bookedResource.resourceType, permissions)
            ),
            canAddOnBehalfOfUser: !!(
                bookedResource && HasPermissionToAddOnBehalfOf(bookedResource.resourceType, permissions)
            ),
            hasUpdateError: edit.info.error,
            areaName: area?.name,
            areaId: area?.id,
            bookingInfo: formatFormValues(
                combinedBooking,
                bookedResource,
                occurrence,
                timezone,
                DateTime.fromISO(selectedDate)
            ),
            isRepeatsEnabled: referenceData?.tenantInfo?.isRepeatsEnabled,
            resourceTypeTenantConfiguration: (tenantSettings &&
                resource?.resourceType !== undefined &&
                (tenantSettings[resource?.resourceType] as Configuration)) as unknown as ConfigurationSettings,
            resourceTypeSiteConfiguration: (siteSettings &&
                resource?.resourceType !== undefined &&
                (siteSettings[resource?.resourceType] as Configuration)) as unknown as ConfigurationSettings,
            bookingsByResource: bookingsByResource.items,
            selectedDate: filters.combined.selectedDate,
            bookingsByResourceLoadStatus: bookingsByResource.loadStatus,
        };
    }
);

export const getRepeatContainerData = createSelector(
    (state: RootState) => state.combined,
    (state: RootState) => state.router,
    (state: RootState) => state.filters,
    (combined, router, filters) => {
        const searchParams = new URLSearchParams(router.location?.search);
        const combinedBooking = combined.edit.info.item as Booking;
        const selectedDate = searchParams.get('selectedDate') || '';
        const selectedDateLuxon = DateTime.fromISO(selectedDate);
        const selectedSite = filters.global.site;
        const timezone = (selectedSite && selectedSite.timezone) || constants.DEFAULT_TIMEZONE;

        const currentExclusion = combinedBooking?.repeatSchedule?.exclusions?.find(
            (item) =>
                (DateTime.fromISO(item?.timeRange?.start).setZone(timezone).hasSame(selectedDateLuxon, 'day') ||
                    (item?.updatedTimeRange &&
                        DateTime.fromISO(item?.updatedTimeRange?.start)
                            .setZone(timezone)
                            .hasSame(selectedDateLuxon, 'day'))) &&
                item.isPending
        );

        return {
            isUpdatePending: !!currentExclusion,
            currentExclusion,
        };
    }
);

export const getCommonInfo = createSelector(
    (state: RootState) => state.combined,
    (state: RootState) => state.filters,
    (state: RootState) => state.authentication.user,
    (state: RootState) => state.commonResources.configuration.siteSettings,
    (state: RootState) => state.commonResources.configuration.tenantSettings,
    (state: RootState) => state.router,
    (state: RootState) => state.referenceData,
    (combined, filters, user, siteSettings, tenantSettings, router, referenceData) => {
        const searchParams = new URLSearchParams(router.location?.search);
        const selectedSite = filters.global.site;
        const selectedDate = searchParams.get('selectedDate') || '';
        const combinedBooking = combined.edit.info.item as Booking;
        const timezone = (selectedSite && selectedSite.timezone) || constants.DEFAULT_TIMEZONE;
        const { resource } = combined.edit.info;
        const permissions = user?.permissions || ({} as Permissions);
        const canEditNotOwnBooking =
            resource?.resourceType !== undefined &&
            HasPermissionToEditNotOwnBooking(resource?.resourceType, permissions);
        const canEditPastBooking =
            resource?.resourceType !== undefined && HasPermissionToEditPastBooking(resource?.resourceType, permissions);
        const canAddOnBehalfOf =
            resource?.resourceType !== undefined && HasPermissionToAddOnBehalfOf(resource?.resourceType, permissions);
        const canViewPrivateBookings =
            resource?.resourceType !== undefined &&
            HasPermissionToViewPrivateBookings(resource?.resourceType, permissions);
        const isOnBehalfOfUserBooking = user?.userProfileId === combinedBooking?.onBehalfOf?.personId;

        return {
            commonInfo: {
                resourceTypeSiteConfiguration: (siteSettings &&
                    resource?.resourceType !== undefined &&
                    (siteSettings[resource?.resourceType] as Configuration)) as unknown as ConfigurationSettings,
                resourceTypeTenantConfiguration: (tenantSettings &&
                    resource?.resourceType !== undefined &&
                    (tenantSettings[resource?.resourceType] as Configuration)) as unknown as ConfigurationSettings,
                resource: combined.edit.info.resource,
                selectedDate: DateTime.fromISO(selectedDate),
                selectedSite,
                hourFormat: user?.extendedHourFormat || constants.TWENTY_FOUR_HOUR_FORMAT,
                canEditNotOwnBooking,
                isOnBehalfOfUserBooking,
                canViewPrivateBookings,
                canEditPastBooking,
                canAddOnBehalfOf,
                dateTimeNow: DateTime.utc().setZone(timezone).toISO(),
                dateNow: DateTime.utc().setZone(timezone).toFormat(constants.DEFAULT_DATE_FORMAT),
                isCurrentUserBooking: combinedBooking?.createdByUserId.toString() === user?.userProfileId,
                timezone,
                isRepeatsEnabled: referenceData?.tenantInfo?.isRepeatsEnabled,
                operationInProgress: combined.edit.info.operationInProgress,
            },
            visitorData: {
                searchOboPersonsResult: combined.edit.info.searchOboPersonsResult as Person[],
                isSearchOboPersonInProgress: combined.edit.info.isSearchOboPersonInProgress,
                isVisitorCreationOrEditingInProgress: combined.edit.info.isVisitorCreationOrEditingInProgress,
                isVisitorCreated: combined.edit.info.isVisitorCreated,
                isVisitorUpdated: combined.edit.info.isVisitorUpdated,
                onBehalfOfPerson: combinedBooking?.onBehalfOf as any,
            },
        };
    }
);
