import * as constants from 'features/constants';
import { DateTime } from 'luxon';
import Guid from 'utilities/guid';

import { store } from '../../../store';
import { BookingCreatedArgs } from '../Models/BookingCreatedArgs';

class DayPilotArgsMapper {
    public static MapTimeRangeSelectedToBookingCreated = (
        args: any,
        resourceType: number
    ): BookingCreatedArgs | undefined => {
        const timezone = store.getState().filters.global.site?.timezone || constants.DEFAULT_TIMEZONE;
        if (!args) {
            return undefined;
        }

        return {
            resourceId: new Guid(args.resource),
            startTime: DateTime.fromISO(args.start.value, { zone: timezone }),
            endTime: DateTime.fromISO(args.end.value, { zone: timezone }),
            resourceType,
        };
    };
}

export default DayPilotArgsMapper;
