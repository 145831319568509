import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundMask from 'components/BackgroundMask';
import CheckInPanel from 'components/CheckInPanel';
import Loading from 'components/Loading';
import PersonDetailsPanel from 'components/PersonDetailsPanel/PersonDetailsPanel';
import { VisitorPanel } from 'components/VisitorPanel/VisitorPanel';
import { Person } from 'services/ApiClients/Models';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { SiteVisit } from 'services/ApiClients/VisitorManagement/Models/SiteVisit';

import BookingsHeader, { BookingsHeaderItems } from './BookingsHeader/BookingsHeader';
import BookingViewItem from './BookingViewItem/BookingViewItem';

import './Bookings.scss';

interface BookingsProps {
    headerItems: BookingsHeaderItems[];
    isToday: boolean;
    isLoading: boolean;
    siteVisits: SiteVisit[] | null;
    checkInSiteVisit?: ((siteVisitId: string) => void) | null;
    checkOutSiteVisit?: ((siteVisitId: string, cancelFutureSiteVisits: boolean) => void) | null;
    cancelSiteVisit?: ((siteVisitId: string, isMultiDay?: boolean) => void) | null;
    timezone: string;
    noItemsMessage?: string;
    isVisitorEditingInProgress: boolean;
    onExternalVisitorSave: Function;
    hourFormat: string;
    sites?: GeographicStructureItem[];
}

const Bookings = ({
    headerItems,
    isToday,
    isLoading,
    siteVisits,
    checkInSiteVisit = null,
    checkOutSiteVisit = null,
    cancelSiteVisit = null,
    timezone,
    noItemsMessage = '',
    isVisitorEditingInProgress,
    onExternalVisitorSave,
    hourFormat,
    sites,
}: BookingsProps): JSX.Element => {
    const { t } = useTranslation();

    const [isCheckInPanelOpen, setCheckInPanelOpen] = useState<boolean>(false);
    const [isIntVisitorDetailsPanelOpen, setIntVisitorDetailsPanelOpen] = useState<boolean>(false);
    const [isExtVisitorDetailsPanelOpen, setExtVisitorDetailsPanelOpen] = useState<boolean>(false);
    const [isHostDetailsPanelOpen, setHostDetailsPanelOpen] = useState<boolean>(false);
    const [currentSiteVisit, setCurrentSiteVisit] = useState<SiteVisit>({} as SiteVisit);

    useEffect(() => {
        if (isLoading && isExtVisitorDetailsPanelOpen) {
            setExtVisitorDetailsPanelOpen(false);
        }
    }, [isLoading]);

    const openCheckInPanel = useCallback((siteVisit: SiteVisit): void => {
        setCheckInPanelOpen(true);
        setCurrentSiteVisit(siteVisit);
    }, []);

    const openVisitorPanel = useCallback((siteVisit: SiteVisit): void => {
        if (siteVisit.visitor.visitorType === 'Internal') {
            setIntVisitorDetailsPanelOpen(true);
        } else {
            setExtVisitorDetailsPanelOpen(true);
        }
        setCurrentSiteVisit(siteVisit);
    }, []);

    const openHostPanel = (siteVisit: SiteVisit): void => {
        setHostDetailsPanelOpen(true);
        setCurrentSiteVisit(siteVisit);
    };

    return (
        <div className="reception-bookings">
            {isLoading && (
                <>
                    <BackgroundMask className={isLoading ? 'open' : ''} />
                    <Loading />
                </>
            )}

            <BookingsHeader headerItems={headerItems} />

            <div className="bookings-list">
                {siteVisits &&
                    siteVisits.map((item: SiteVisit) => {
                        return (
                            <BookingViewItem
                                key={item.id}
                                siteVisitItem={item}
                                isToday={isToday}
                                openCheckInPanel={openCheckInPanel}
                                onCheckOut={(siteVisitId, cancelFutureSiteVisits) =>
                                    checkOutSiteVisit && checkOutSiteVisit(siteVisitId, cancelFutureSiteVisits)
                                }
                                onCancelBooking={(siteVisitId, isMultiDay) =>
                                    cancelSiteVisit && cancelSiteVisit(siteVisitId, isMultiDay)
                                }
                                onVisitorClick={openVisitorPanel}
                                onHostClick={openHostPanel}
                                timezone={timezone}
                                hourFormat={hourFormat}
                                sites={sites}
                            />
                        );
                    })}
                {!isLoading && (!siteVisits || siteVisits.length === 0) && (
                    <div className="no-items-message">{noItemsMessage}</div>
                )}
            </div>

            {checkInSiteVisit && (
                <CheckInPanel
                    isOpen={isCheckInPanelOpen}
                    setPanelOpen={setCheckInPanelOpen}
                    onCheckIn={(siteVisitId) => {
                        checkInSiteVisit(siteVisitId);
                    }}
                    visitor={currentSiteVisit.visitor}
                    host={currentSiteVisit.host}
                    siteVisitId={currentSiteVisit.id}
                />
            )}

            <VisitorPanel
                isOpen={isExtVisitorDetailsPanelOpen}
                setPanelOpen={setExtVisitorDetailsPanelOpen}
                creationOrUpdatingInProgress={isVisitorEditingInProgress}
                person={currentSiteVisit.visitor as unknown as Person}
                onSave={(updatedVisitor) => {
                    onExternalVisitorSave(currentSiteVisit, updatedVisitor);
                }}
                showBackgroundMask
                isEditMode
            />
            <PersonDetailsPanel
                isOpen={isIntVisitorDetailsPanelOpen}
                setPanelOpen={setIntVisitorDetailsPanelOpen}
                person={currentSiteVisit.visitor as unknown as Person}
                title={t('PersonDetails_VisitorDetails')}
                nameTitle={t('PersonDetails_VisitorName')}
            />
            <PersonDetailsPanel
                isOpen={isHostDetailsPanelOpen}
                setPanelOpen={setHostDetailsPanelOpen}
                person={currentSiteVisit.host as unknown as Person}
                title={t('PersonDetails_HostDetails')}
                nameTitle={t('PersonDetails_HostName')}
            />
        </div>
    );
};

export default Bookings;
