import { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import LoggingService from 'services/LoggingService';

export type UseSignalRProps = {
    baseApiUrl: string;
    onConnected(connectionId: string | null): void;
    onReconnecting(error?: Error): void;
    onReconnected(connectionId?: string): void;
    onDisconnected(): void;
    onClosed(): void;
    onUnmount(): void;
    onError(error: Error): void;
};

const useSignalR = (props: UseSignalRProps): signalR.HubConnection => {
    let isUnmounting = false;

    const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(props.baseApiUrl)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

    useEffect(() => {
        hubConnection
            .start()
            .then(() => {
                LoggingService.Debug('SignalR Connection Established');

                if (props.onConnected) props.onConnected(hubConnection.connectionId);
            })
            .catch((error) => {
                LoggingService.Error('SignalR connection error whilst establishing connection', error);
                if (props.onError) props.onError(error);
            });

        hubConnection.onreconnecting((error) => {
            LoggingService.Warn('SignalR Connection Reconnecting', error);
            if (props.onReconnecting) props.onReconnecting(error);
        });

        hubConnection.onreconnected((connectionId) => {
            LoggingService.Warn('SignalR Connection Reconnected', connectionId);
            if (props.onReconnected) props.onReconnected(connectionId);
        });

        hubConnection.onclose((error?: Error) => {
            if (isUnmounting) {
                LoggingService.Debug('SignalR is unmounting and connection is closed');
                return;
            }

            LoggingService.Warn('SignalR Connection Closed', error);
            if (props.onClosed) props.onClosed();
        });

        return () => {
            isUnmounting = true;

            if (props.onUnmount && hubConnection.connectionId) {
                props.onUnmount();
            }
        };
    }, []);

    return hubConnection;
};

export default useSignalR;
