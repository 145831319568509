import * as constants from 'features/constants';
import { combineReducers } from 'redux';
import { CompanyAttributesModel, UserProfile } from 'services/ApiClients/Identity/Models';
import { SpecifiedCompanyAttributesResponse } from 'services/ApiClients/Organization/Models/CompanyAttributes';
import { createReducer } from 'typesafe-actions';

import {
    getMyAccountUserProfileAsync,
    getTenantCompanyAttributesAsync,
    myAccountUserProfileReset,
    updateMyAccountUserProfileAsync,
    updateUserProfileCompanyAttributesAsync,
} from './actions';

interface AccountState {
    userProfile: UserProfile | null;
    loadStatus: string;
    operationInProgress: boolean;
    availableCompanyAttributes: SpecifiedCompanyAttributesResponse[] | null;
    updateUserCompanyAttributes: boolean;
}

export default combineReducers<AccountState>({
    loadStatus: createReducer<string>(constants.LOAD_STATUSES.REQUIRED)
        .handleAction(
            [myAccountUserProfileReset, updateMyAccountUserProfileAsync.success],
            () => constants.LOAD_STATUSES.REQUIRED
        )
        .handleAction([getMyAccountUserProfileAsync.request], () => constants.LOAD_STATUSES.LOADING)
        .handleAction([getMyAccountUserProfileAsync.success], () => constants.LOAD_STATUSES.LOADED)
        .handleAction([getMyAccountUserProfileAsync.failure], () => constants.LOAD_STATUSES.FAILED),

    userProfile: createReducer<UserProfile | null>(null)
        .handleAction([getMyAccountUserProfileAsync.success], (state, action) => action.payload.payload as UserProfile)
        .handleAction([getMyAccountUserProfileAsync.failure], () => null),

    operationInProgress: createReducer<boolean>(false)
        .handleAction([updateMyAccountUserProfileAsync.request], () => true)
        .handleAction([updateMyAccountUserProfileAsync.success, updateMyAccountUserProfileAsync.failure], () => false),

    availableCompanyAttributes: createReducer<any[] | null>(null)
        .handleAction([getTenantCompanyAttributesAsync.success], (state, action) => action.payload.payload as any[])
        .handleAction([getTenantCompanyAttributesAsync.failure], () => null),

    updateUserCompanyAttributes: createReducer<boolean>(false)
        .handleAction([updateUserProfileCompanyAttributesAsync.success], () => true)
        .handleAction([updateUserProfileCompanyAttributesAsync.failure], () => false),
});
