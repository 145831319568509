import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/ClosePanel.svg';
import classNames from 'classnames';
import Button from 'components/FormElements/Button';

import BackgroundMask from '../BackgroundMask';

import './SlidingPanel.scss';

export interface SlidingPanelProps {
    title?: string;
    subTitle?: string;
    className?: string;
    backButtonClass?: string;
    backButtonText?: string;
    backButtonId?: string;
    open?: boolean;
    showBackgroundMask?: boolean;
    onClose: (param?: any) => void;
    customAngleButton?: JSX.Element | null;
    titleIcon?: JSX.Element;
    dataTestId?: string;
}

export const SlidingPanelContext = createContext<any>({
    validationInProgress: false,
    setValidationInProgress: () => {},
});

const SlidingPanel = ({
    titleIcon,
    title,
    subTitle,
    className,
    backButtonClass,
    backButtonText = 'Close',
    backButtonId,
    open = false,
    onClose,
    children,
    customAngleButton,
    showBackgroundMask = false,
    dataTestId = '',
}: React.PropsWithChildren<SlidingPanelProps>): JSX.Element => {
    const { t } = useTranslation();
    const [validationInProgress, setValidationInProgress] = useState<boolean>(false);

    return (
        <>
            <BackgroundMask open={open && showBackgroundMask} onClick={onClose} />
            <aside
                className={classNames('slidingPanel', className, { open })}
                style={{ zIndex: validationInProgress ? 5 : 6 }}
                aria-hidden={!open}
                data-testid={dataTestId}
            >
                {titleIcon && <div className="title-icon">{titleIcon}</div>}
                {title && <h1>{title}</h1>}
                {subTitle && (
                    <h3>
                        {t('Reports_Filter_SubTitle')} <span className="subTitle-mark">{subTitle}</span>
                    </h3>
                )}
                {customAngleButton || (
                    <Button
                        className={classNames('close', backButtonClass)}
                        type="button"
                        iconOnly
                        icon={CloseIcon}
                        onClick={onClose}
                        text={backButtonText}
                        dataTestId={backButtonId || 'back-button'}
                    />
                )}
                <SlidingPanelContext.Provider value={{ validationInProgress, setValidationInProgress }}>
                    <div className="content">{children}</div>
                </SlidingPanelContext.Provider>
            </aside>
        </>
    );
};

export default SlidingPanel;
