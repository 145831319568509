import { getConfiguration } from 'utilities/appConfigsUtils';

import { ApiResponse } from '../@Models/ApiResponse';
import BaseApiClient from '../BaseApiClient';
import { PersonsResponse } from '../Models';

import { UserReportFilterOptions } from './Models/UserReportFilterOptions';

export class ReportsApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().REPORTING_API;

        return baseUrl;
    };

    public static generateReport = async (query: string): Promise<ApiResponse<PersonsResponse>> => {
        return ReportsApi.post(`${this.BuildBaseUrl()}/graphql`, { query: `{${query}}` });
    };

    public static getUserReportFilterOptions = async (): Promise<ApiResponse<UserReportFilterOptions>> => {
        return ReportsApi.get(`${this.BuildBaseUrl()}/api/v1/SearchFilters/userprofile-report`);
    };

    public static getDepartments = async (): Promise<ApiResponse<{ departments: string[] }>> => {
        return ReportsApi.get(`${this.BuildBaseUrl()}/api/v1/SearchFilters/user-departments`);
    };
}
