import { VIEW_TYPE } from 'features/constants';
import { DateTime } from 'luxon';
import { Language } from 'services/ApiClients/Language/Models';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { createAction } from 'typesafe-actions';
import Guid from 'utilities/guid';

import { ExpandedResources } from '../../../services/ApiClients/OrganisationStructure/Models/GeographicStructureItem';

export const setFilters = createAction('SET_FILTERS')<Guid | null>();
export const setCachedSiteImage = createAction('SET_CACHED_IMAGE')<{ [key: string]: string } | {}>();
export const setFiltersComplete = createAction('SET_FILTERS_COMPLETE')<void>();
export const setSite = createAction('SET_SITE_FILTER')<GeographicStructureItem | null>();
export const updateSite = createAction('UPDATE_SITE_FILTER')<GeographicStructureItem>();
export const setSiteGroup = createAction('SET_SITE_GROUP_FILTER')<GeographicStructureItem | null>();
export const setArea = createAction('SET_AREA_FILTER')<GeographicStructureItem | null>();
export const setFloorPlanTimeLineDurationFilter = createAction('SET_DURATION_FILTER')<string | number>();
export const setFloorPlanTimeLineFilter = createAction('SET_TIME_LINE_FILTER')<any>();
export const setLanguage = createAction('SET_LANGUAGE_FILTER')<Language>();
export const setCombinedDate = createAction('SET_COMBINED_DATE')<DateTime>();
export const setCombinedViewType = createAction('SET_COMBINED_VIEW_TYPE_FILTER')<VIEW_TYPE>();
export const setCombinedDisabledAccess = createAction('SET_COMBINED_DISABLED_ACCESS')<boolean>();
export const setCombinedEquipments = createAction('SET_COMBINED_EQUIPMENTS')<{}>();
export const resetCombinedAdvancedFilters = createAction('RESET_COMBINED_FILTERS')<void>();
export const setCombinedToggles = createAction('SET_COMBINED_TOGGLE_FILTER')<number>();
export const setExpandedResources = createAction('SET_EXPANDED_RESOURCES')<ExpandedResources[]>();

export default {
    setSite,
    updateSite,
    setSiteGroup,
    setLanguage,
    setFilters,
    setFiltersComplete,
    setArea,
    setCombinedDate,
    setCombinedViewType,
    setCombinedDisabledAccess,
    setCombinedEquipments,
    resetCombinedAdvancedFilters,
    setCombinedToggles,
    setExpandedResources,
    setCachedSiteImage,
    setFloorPlanTimeLineDurationFilter,
    setFloorPlanTimeLineFilter,
};
