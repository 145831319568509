import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/Forms';
import { AllListItemOption, LOAD_STATUSES } from 'features/constants';
import { ResourceTypeListItems } from 'services/ApiClients/Resource/Models';

import WithLoading from '../../../components/HOC/WithLoading';
import { GeographicStructureItem } from '../../../services/ApiClients/OrganisationStructure';
import { getAreaListItemsOfSelectedSite, getSiteGroupListItems, getSiteListItems } from '../utils';

interface ResourceFilterFormProps {
    setReportsFilter: Function;
    reportsFilter: any;
    siteGroups: GeographicStructureItem[];
    sites: GeographicStructureItem[];
    getAreas: Function;
    areas: { [parentId: string]: GeographicStructureItem[] };
    areasLoadStatus: string;
}

const ResourceFilterForm = ({
    siteGroups,
    sites,
    setReportsFilter,
    reportsFilter,
    getAreas,
    areas,
    areasLoadStatus,
}: ResourceFilterFormProps): JSX.Element => {
    const { t } = useTranslation();
    const siteGroupListItems = useMemo(() => getSiteGroupListItems(siteGroups, sites), [siteGroups]);

    const siteListItems = useMemo(
        () => getSiteListItems(sites, reportsFilter.selectedSiteGroup),
        [sites, reportsFilter.selectedSiteGroup]
    );
    const areaListItems = useMemo(
        () => getAreaListItemsOfSelectedSite(areas, reportsFilter.selectedSite),
        [areas, reportsFilter.selectedSite]
    );

    useEffect(() => {
        if (reportsFilter.selectedSite && reportsFilter.selectedSite?.value !== AllListItemOption.value) {
            getAreas(reportsFilter.selectedSite.value);
        }

        setReportsFilter({ ...reportsFilter, selectedArea: AllListItemOption });
    }, [reportsFilter.selectedSite?.value]);

    useEffect(() => {
        setReportsFilter({ ...reportsFilter, selectedSite: AllListItemOption });
    }, [reportsFilter.selectedSiteGroup]);

    useEffect(() => {
        setReportsFilter({
            selectedSiteGroup: AllListItemOption,
            selectedSite: AllListItemOption,
            selectedArea: AllListItemOption,
            selectedResourceType: AllListItemOption,
        });
    }, []);

    return (
        <WithLoading isLoading={areasLoadStatus === LOAD_STATUSES.LOADING}>
            <div className="site-groups-n-sites-n-areas">
                {siteGroups && (
                    <Select
                        label={t('Reports_SiteGroups')}
                        name="siteGroup"
                        dataTestId="site-group"
                        items={siteGroupListItems}
                        className="site-select"
                        onChange={(selectedSiteGroup) => {
                            setReportsFilter({ ...reportsFilter, selectedSiteGroup });
                        }}
                        value={reportsFilter.selectedSiteGroup}
                    />
                )}

                <Select
                    label={t('Reports_Sites')}
                    name="site"
                    dataTestId="site"
                    items={siteListItems}
                    className="defaultSite"
                    onChange={(selectedSite) => {
                        setReportsFilter({ ...reportsFilter, selectedSite });
                    }}
                    value={reportsFilter.selectedSite}
                />
                <div />

                <Select
                    label={t('Reports_Area')}
                    name="area"
                    dataTestId="area"
                    items={areaListItems}
                    isDisabled={reportsFilter.selectedSite?.value === AllListItemOption.value}
                    className="defaultSite"
                    onChange={(selectedArea) => {
                        setReportsFilter({ ...reportsFilter, selectedArea });
                    }}
                    value={reportsFilter.selectedArea}
                />
            </div>

            <Select
                label={t('Reports_ResourceType')}
                name="resource-type"
                dataTestId="resource-type"
                items={ResourceTypeListItems}
                className="defaultSite"
                onChange={(selectedResourceType) => {
                    setReportsFilter({ ...reportsFilter, selectedResourceType });
                }}
                value={reportsFilter.selectedResourceType}
            />
        </WithLoading>
    );
};

export default ResourceFilterForm;
