import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReportsActiveColumn } from '../../../features/Reports/interfaces';
import Button from '../../FormElements/Button';

import './EditColumnsForm.scss';

interface EditColumnsFormProps {
    activeColumns: ReportsActiveColumn[];
    applyNewActiveColumns: (columns: ReportsActiveColumn[]) => void;
    isEditColumnsPanelOpen: boolean;
    setEditColumnsPanelOpen: (arg: boolean) => void;
}

const EditColumnsForm = ({
    activeColumns,
    applyNewActiveColumns,
    isEditColumnsPanelOpen,
    setEditColumnsPanelOpen,
}: EditColumnsFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [currentColumns, setCurrentColumns] = useState<ReportsActiveColumn[]>(activeColumns);

    useEffect(() => {
        setCurrentColumns(activeColumns);
    }, [activeColumns]);

    const handleCheck = (name: string): void => {
        setCurrentColumns(
            currentColumns.map((item) => (item.name === name ? { ...item, active: !item.active } : item))
        );
    };

    const toggleAllColumns = (): void => {
        if (currentColumns.some((column) => !column.active)) {
            setCurrentColumns(currentColumns.map((column) => ({ ...column, active: true })));
        } else {
            setCurrentColumns(currentColumns.map((column) => ({ ...column, active: false })));
        }
    };

    const applyAndSaveNewActiveColumns = (): void => {
        setEditColumnsPanelOpen(false);
        applyNewActiveColumns(currentColumns);
    };

    useEffect(() => {
        if (!isEditColumnsPanelOpen) {
            setCurrentColumns(activeColumns);
        }
    }, [isEditColumnsPanelOpen]);

    return (
        <div className="edit-columns-form">
            <Button
                dataTestId="toggle-all-columns"
                text={t('Reports_ToggleAll')}
                className="toggle-btn"
                onClick={toggleAllColumns}
            />
            <div className="columns-container">
                {currentColumns.map((column) => {
                    const { name, active } = column;
                    return (
                        <div className="column-item" key={name}>
                            <div className="option" key={name}>
                                <input type="checkbox" checked={active} onChange={() => handleCheck(name || '')} />
                                <span
                                    className={classNames('optionName', {
                                        active,
                                    })}
                                >
                                    {name}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="btn-container">
                <Button
                    dataTestId="apply-columns"
                    text={t('Button_Apply')}
                    className="apply-btn-columns"
                    onClick={applyAndSaveNewActiveColumns}
                />
            </div>
        </div>
    );
};

export default EditColumnsForm;
