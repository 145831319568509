import React, { useState } from 'react';
import { ReactComponent as ChevronDown } from 'assets/icons/DropdownChevron.svg';
import classNames from 'classnames';

import './Accordion.scss';

interface AccordionProps {
    items: AccordionItemProps[];
}

export interface AccordionItemProps {
    key: string;
    header: JSX.Element;
    content: JSX.Element;
    isExpanded?: boolean;
}

const AccordionItem = ({ key, header, content, isExpanded = false }: AccordionItemProps): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(isExpanded);

    return (
        <div className="accordion-item" key={key}>
            <div className={classNames('item-header', { expanded })} onClick={() => setExpanded(!expanded)}>
                {header}
                <div className="chevron-down">
                    <ChevronDown />
                </div>
            </div>
            {expanded && <div className="item-content">{content}</div>}
        </div>
    );
};

const Accordion = ({ items }: AccordionProps): JSX.Element => {
    return (
        <div className="accordion">
            {items.map((i) => (
                <AccordionItem key={i.key} header={i.header} content={i.content} isExpanded={i.isExpanded} />
            ))}
        </div>
    );
};

export default Accordion;
