import React from 'react';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import i18n from 'i18next';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

import ReportFilterColumnHeader from '../../../components/ReportsFilterColumnHeader/ReportFilterColumnHeader';
import { AllListItemOption } from '../../constants';
import { ActiveColumnFilterInterface, ColumnFilterInterface } from '../interfaces';

const items = {
    id: true,
    name: true,
    email: true,
    company: true,
    visitorType: true,
    mobile: true,
    cumulativeVisits: true,
};

export const getColumns = (
    handleFilterClick: (field: string, headerName: string) => void,
    sideBarFilter: ColumnFilterInterface,
    activeSideBarFilter: ActiveColumnFilterInterface
): GridColDef[] => [
    {
        field: 'name',
        headerName: i18n.t('Reports_Visitor'),
        minWidth: 200,
        flex: 0.2,
    },
    {
        field: 'email',
        headerName: i18n.t('Reports_Email'),
        minWidth: 200,
        flex: 0.2,
    },
    {
        field: 'company',
        headerName: i18n.t('Reports_Company'),
        minWidth: 200,
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.company.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Company')}</p>
            ),
    },
    {
        field: 'visitorType',
        headerName: i18n.t('Reports_Type'),
        minWidth: 200,
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.visitorType.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_Type')}</p>
            ),
    },
    {
        field: 'mobile',
        headerName: i18n.t('Reports_Mobile'),
        minWidth: 200,
        flex: 0.1,
        sortable: false,
    },
    {
        field: 'cumulativeVisits',
        headerName: i18n.t('Reports_CumulativeVisits'),
        minWidth: 175,
        flex: 0.1,
        renderHeader: (params: GridColumnHeaderParams) =>
            sideBarFilter.cumulativeVisits?.enabled ? (
                <ReportFilterColumnHeader
                    handleFilterClick={handleFilterClick}
                    field={params.colDef.field}
                    headerName={params.colDef.headerName || ''}
                    activeSideBarFilter={activeSideBarFilter}
                />
            ) : (
                <p>{i18n.t('Reports_CumulativeVisits')}</p>
            ),
    },
];

const getSkipItemsAmount = (itemsPerPageAmount: number, activePage: number): number => {
    return itemsPerPageAmount * (activePage - 1);
};

export const buildQuery = (
    itemsPerPageAmount: number,
    activePage: number | null,
    sortOptions: any,
    reportsFilter: any,
    activeSideBarFilter?: ActiveColumnFilterInterface,
    isForExport = false,
    totalCount = 0,
    visibleColumns?: string[]
): string => {
    const graphQLItems: { [key: string]: any } = { ...items };
    if (visibleColumns) {
        Object.keys(graphQLItems).forEach((key: string) => {
            if (!visibleColumns.includes(key)) {
                delete graphQLItems[key];
            }
        });
    }
    const query = {
        visitors: {
            __args: {
                skip: isForExport ? 0 : getSkipItemsAmount(itemsPerPageAmount, activePage || 1),
                take: isForExport ? totalCount : itemsPerPageAmount,
                order: {},
                where: {},
            },
            items: graphQLItems,
            totalCount: true,
        },
    };

    if (sortOptions?.length) {
        query.visitors.__args.order = { [sortOptions[0].field]: new EnumType(sortOptions[0].sort.toUpperCase()) };
    }

    let where = {};

    if (reportsFilter?.companyName !== AllListItemOption.label) {
        where = {
            ...where,
            company: { eq: reportsFilter.companyName },
        };
    }

    if (reportsFilter?.visitor !== AllListItemOption.label) {
        where = {
            ...where,
            name: { eq: `${reportsFilter.visitor.surname}, ${reportsFilter.visitor.firstname}` },
        };
    }

    if (activeSideBarFilter?.company?.values) {
        where = {
            ...where,
            company: { in: activeSideBarFilter.company.values },
        };
    }

    if (activeSideBarFilter?.visitorType?.values) {
        where = {
            ...where,
            visitorType: { in: activeSideBarFilter.visitorType.values },
        };
    }

    if (activeSideBarFilter?.cumulativeVisits?.values[0]) {
        switch (activeSideBarFilter.cumulativeVisits.values[0].option) {
            case 'less': {
                where = {
                    ...where,
                    cumulativeVisits: { lt: activeSideBarFilter.cumulativeVisits.values[0].value },
                };
                break;
            }
            case 'more': {
                where = {
                    ...where,
                    cumulativeVisits: { gt: activeSideBarFilter.cumulativeVisits.values[0].value },
                };
                break;
            }
            default: {
                where = {
                    ...where,
                    cumulativeVisits: { eq: activeSideBarFilter.cumulativeVisits.values[0].value },
                };
                break;
            }
        }
    }

    if (Object.keys(where).length === 0) {
        delete query.visitors.__args.where;
    } else {
        query.visitors.__args.where = where;
    }

    return jsonToGraphQLQuery(query);
};
