import { RootEpic } from 'PortalTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getMyBookingsAsync, getMySiteVisitsAsync } from './actions';

export const getMyBookingsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getMyBookingsAsync.request)),
        switchMap((response) =>
            from(api.booking.myBookings(response.payload)).pipe(
                map((payload) => getMyBookingsAsync.success(payload)),
                catchError((error) => of(getMyBookingsAsync.failure(error)))
            )
        )
    );

export const getMySiteVisitsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getMySiteVisitsAsync.request)),
        switchMap((action) =>
            from(api.visitorManagement.getMySiteVisits(action.payload)).pipe(
                map((payload) => getMySiteVisitsAsync.success(payload)),
                catchError((error) => of(getMySiteVisitsAsync.failure(error)))
            )
        )
    );
