export interface SearchItem {
    field: string;
    value: string;
}

export interface SearchData {
    searchFieldsConditionAND?: Array<SearchItem>;
    searchFieldsConditionOR?: Array<SearchItem>;
    searchFieldsConditionsOrUnitedByAnd?: Array<Array<SearchItem>>;
    top: number;
    orderBy: string;
}

const buildConditionsAND = (searchFieldsConditionAND: Array<SearchItem>): string => {
    let searchQueryConditionsAND = '';
    for (let i = 0; i < searchFieldsConditionAND.length; i++) {
        const searchFieldValue = searchFieldsConditionAND[i];
        const fieldValueQuery = `${searchFieldValue.field}:${searchFieldValue.value}*`;

        if (!searchQueryConditionsAND) {
            searchQueryConditionsAND = fieldValueQuery;
        } else {
            searchQueryConditionsAND = `${searchQueryConditionsAND} AND ${fieldValueQuery}`;
        }
    }

    return searchQueryConditionsAND;
};

const buildConditionsOR = (searchFieldsConditionOR: Array<SearchItem>): string => {
    let searchQueryConditionsOR = '';
    for (let i = 0; i < searchFieldsConditionOR.length; i++) {
        const searchFieldValue = searchFieldsConditionOR[i];
        const fieldValueQuery = `${searchFieldValue.field}:${searchFieldValue.value}*`;

        if (!searchQueryConditionsOR) {
            searchQueryConditionsOR = fieldValueQuery;
        } else {
            searchQueryConditionsOR = `${searchQueryConditionsOR} OR ${fieldValueQuery}`;
        }
    }

    return searchQueryConditionsOR;
};

const buildConditionsOrUnitedByAnd = (searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>>): string => {
    let searchQueryConditionsOrUnitedByAnd = '';

    for (let i = 0; i < searchFieldsConditionsOrUnitedByAnd.length; i++) {
        const searchQueryConditionsOR = buildConditionsOR(searchFieldsConditionsOrUnitedByAnd[i]);

        if (!searchQueryConditionsOrUnitedByAnd) {
            searchQueryConditionsOrUnitedByAnd = searchQueryConditionsOR;
        } else {
            searchQueryConditionsOrUnitedByAnd = `(${searchQueryConditionsOrUnitedByAnd}) AND (${searchQueryConditionsOR})`;
        }
    }

    return searchQueryConditionsOrUnitedByAnd;
};

export const BuildAzureSearchQuery = (searchData: SearchData): string => {
    let fullSearchQuery = '';

    // build query with AND condition
    if (searchData.searchFieldsConditionAND) {
        fullSearchQuery = buildConditionsAND(searchData.searchFieldsConditionAND);
    }

    // build query with OR condition.
    if (searchData.searchFieldsConditionOR) {
        const searchQueryConditionsOR = buildConditionsOR(searchData.searchFieldsConditionOR);

        if (searchQueryConditionsOR) {
            fullSearchQuery = !fullSearchQuery
                ? searchQueryConditionsOR
                : `${fullSearchQuery} AND (${searchQueryConditionsOR})`;
        }
    }

    if (searchData.searchFieldsConditionsOrUnitedByAnd) {
        const searchQueryConditionsOrUnitedByAnd = buildConditionsOrUnitedByAnd(
            searchData.searchFieldsConditionsOrUnitedByAnd
        );

        if (searchQueryConditionsOrUnitedByAnd) {
            fullSearchQuery = !fullSearchQuery
                ? searchQueryConditionsOrUnitedByAnd
                : `${fullSearchQuery} AND (${searchQueryConditionsOrUnitedByAnd})`;
        }
    }

    return fullSearchQuery;
};

export const buildNameSearchQueryItems = (searchValue: string): Array<Array<SearchItem>> => {
    const searchFieldsConditionsOrUnitedByAnd: Array<Array<SearchItem>> = [];

    searchValue
        .trim()
        .split(' ')
        .forEach((i) => {
            const searchFieldsConditionOR: Array<SearchItem> = [];
            const value = i.trim();
            if (value) {
                searchFieldsConditionOR.push({ field: 'Firstname', value });
                searchFieldsConditionOR.push({ field: 'Surname', value });

                searchFieldsConditionsOrUnitedByAnd.push(searchFieldsConditionOR);
            }
        });

    return searchFieldsConditionsOrUnitedByAnd;
};
