import { AxiosError } from 'axios';
import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import { Languages } from 'services/ApiClients/Language/Models';
import { createAsyncAction } from 'typesafe-actions';

export const getLanguages = createAsyncAction(
    'GET_LANGUAGES_REQUEST',
    'GET_LANGUAGES_SUCCESS',
    'GET_LANGUAGES_FAILURE'
)<void, ApiResponse<Languages>, AxiosError | ApiResponse>();

export default { getLanguages };
