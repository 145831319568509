import { connect } from 'react-redux';
import { LOAD_STATUSES } from 'features/constants';
import { updateSite } from 'features/Filters/Redux/actions';
import {
    getGeographicStructureSiteGroups,
    getGeographicStructureSites,
} from 'features/GeographicStructures/Redux/actions';
import { RootState } from 'PortalTypes';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import Guid from 'utilities/guid';

// eslint-disable-next-line import/no-cycle
import GeographicStructureSelector from './GeographicStructureSelector';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    selectedSite: state.filters.global.site,
    selectedSiteGroup: state.filters.global.siteGroup,
    sitesGeoStructure: state.referenceData.sites.items,
    siteGroupsGeoStructure: state.referenceData.siteGroup.items,
    isLoading:
        state.referenceData.site.loadStatus === LOAD_STATUSES.LOADING ||
        state.referenceData.siteGroup.loadStatus === LOAD_STATUSES.LOADING,
    tenantId: state.authentication.user?.tenantId,
});

const mapDispatchToProps = {
    getSites: (gsId: Guid) => getGeographicStructureSites.request(gsId),
    getSiteGroups: (gsId: Guid) => getGeographicStructureSiteGroups.request(gsId),
    setSite: (site: GeographicStructureItem) => updateSite(site),
};

export type GeographicStructureSelectorProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(GeographicStructureSelector);
