import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as ClosePanel } from 'assets/icons/ClosePanel.svg';
import classNames from 'classnames';
import Button from 'components/FormElements/Button';
import UserProfileBadge from 'components/UserProfileBadge';
import authService from 'features/Authentication/Services/AuthorizeService';
import { UserProfile } from 'features/Authentication/types';
import useOutsideClick from 'hooks/useOutsideClick';

import './UserProfileSummary.scss';

const UserProfileSummary = (): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [userProfile, setUserProfile] = useState<UserProfile>(null);
    const menuClass = classNames('userProfileMenu', { open: isOpen });
    const { t } = useTranslation();

    const handleClick = (): void => {
        setIsOpen((value) => !value);
    };

    const ref = useOutsideClick(() => {
        setIsOpen(false);
    });

    const getUserProfile = async (): Promise<void> => {
        const user = await authService.getUser();
        setUserProfile(user);
    };

    const getInitials = (): string => {
        if (!userProfile) {
            return '';
        }

        return `${userProfile.firstName ? userProfile.firstName.substring(0, 1) : ''}${
            userProfile.lastName ? userProfile.lastName.substring(0, 1) : ''
        }`;
    };

    useEffect(() => {
        getUserProfile();
    }, []);

    return (
        <div className="userProfileSummary" ref={ref}>
            <UserProfileBadge
                color="#cccc33"
                initials={getInitials()}
                onClick={handleClick}
                dataTestId="userprofilebadge-button"
            />
            <aside className={menuClass} role="dialog" aria-label="user profile summary" aria-hidden={!isOpen}>
                <Button
                    type="button"
                    text="close"
                    onClick={handleClick}
                    dataTestId="userProfile-close"
                    icon={ClosePanel}
                    iconOnly
                />
                <UserProfileBadge color="#cccc33" initials={getInitials()} />
                <h1 className="name">
                    <span className="firstName">{userProfile?.firstName || ''}</span>
                    <span className="lastName">{userProfile?.lastName || ''}</span>
                </h1>
                <p className="tenant">{userProfile?.company ? userProfile.company : ''}</p>
                <Link className="linkAsButton myAccount" to="/myaccount" data-testid="myaccount-button">
                    {t('UserProfileSummary_MyAccount')}
                </Link>
                <Link className="linkAsButton logout" to="/signout" data-testid="signout-button">
                    {t('UserProfileSummary_LogOut')}
                </Link>
            </aside>
        </div>
    );
};

export default UserProfileSummary;
