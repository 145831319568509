import { useEffect } from 'react';

const useKeyPress = (targetKey: number, handler: () => void): void => {
    const upHandler = ({ which }: KeyboardEvent): void => {
        if (+which === targetKey) {
            handler();
        }
    };

    useEffect(() => {
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keyup', upHandler);
        };
    }, []);
};

export default useKeyPress;
