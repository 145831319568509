import React from 'react';
import { PopoverOrigin, Select } from '@mui/material';
import { ReactComponent as DropDownChevron } from 'assets/icons/DropdownChevron.svg';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';

import './DropDown.scss';

interface PapepProps {
    large: boolean;
}

export interface DropDownProps extends InputWrapperProps {
    value: any;
    onChange?: (value: any) => void;
    disabled?: boolean;
    children: JSX.Element[];
    anchorOrigin?: PopoverOrigin;
    paperProps?: PapepProps;
    dropDownClassName?: string;
    dataTestId: string;
}

const DropDown = (props: DropDownProps): JSX.Element => {
    const {
        label,
        inputName,
        className,
        hideLabel,
        required,
        errorText,
        isError,
        dataTestId,
        value,
        onChange,
        disabled,
        children,
        anchorOrigin,
        paperProps,
        dropDownClassName,
    } = props;

    return (
        <InputWrapper
            label={label}
            inputName={inputName}
            className={className}
            hideLabel={hideLabel}
            required={required}
            errorText={errorText}
            isError={isError}
        >
            <Select
                className={`drop-down ${dropDownClassName}`}
                disabled={disabled}
                value={value}
                variant="standard"
                inputProps={{
                    'data-testid': dataTestId,
                }}
                onChange={(e) => onChange?.(e.target.value)}
                MenuProps={{
                    PaperProps: {
                        className: `paper-container ${paperProps?.large ? 'large' : ''}`,
                    },
                }}
                IconComponent={DropDownChevron}
            >
                {children}
            </Select>
        </InputWrapper>
    );
};

export default DropDown;

DropDown.defaultProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
};
