import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudbookingLogoWhite } from 'assets/logo-white.svg';

// eslint-disable-next-line import/no-cycle
import { WelcomeMessageProps } from '.';

const WelcomeMessage = ({ firstName }: WelcomeMessageProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <section className="welcomeMessage">
            <h1>
                {t('Header_Welcome')} {firstName}
            </h1>
            <div className="welcomeTo">
                <span>{t('Header_WelcomeTo')}</span>
                <CloudbookingLogoWhite />
            </div>
        </section>
    );
};

export default WelcomeMessage;
