import { ApiResponse } from 'services/ApiClients/@Models/ApiResponse';
import LoggingService from 'services/LoggingService';
import { getConfiguration } from 'utilities/appConfigsUtils';

import BaseApiClient from '../BaseApiClient';

import { AuditLogs, GetAuditLogsQuery } from './Models';

export class AuditApi extends BaseApiClient {
    public static BuildBaseUrl = (): string => {
        const baseUrl = getConfiguration().AUDIT_API;

        return `${baseUrl}/api`;
    };

    public static getAuditLogs = async (query: GetAuditLogsQuery): Promise<ApiResponse<AuditLogs>> => {
        if (!query) throw new Error('You must provide a valid query');

        LoggingService.Debug('Sending Audit.getAuditLogs query. Query: ', query);

        let url = `${this.BuildBaseUrl()}/auditlogs?partitionKey=${query.partitionKey}`;
        if (query.limit) {
            url += `&limit=${query.limit}`;
        }
        if (query.after) {
            url += `&after=${query.after}`;
        }

        return AuditApi.get<AuditLogs>(url);
    };
}
