import { isEmpty } from 'lodash';
import { GeographicStructureItem } from 'services/ApiClients/OrganisationStructure';
import { Resource, ResourcesGS, ResourceTypeInterface } from 'services/ApiClients/Resource/Models';
import { getActiveMultipleFilters } from 'utilities/filterUtils';

import { ExpandedResources } from '../../../services/ApiClients/OrganisationStructure/Models/GeographicStructureItem';
import { BookingGridResource } from '../Models/Resource';

const SUB_HEADER_ROW_HEIGHT = 32;

const mapResourceDetails = (resources: Resource[]): BookingGridResource[] | [] =>
    resources.map((resource, index) => ({
        id: resource.id.toString(),
        name: resource.name,
        expanded: false,
        children: [],
        ariaLabel: resource.name,
        disabledAccess: resource.disabledAccess,
        cssClass: `resourceHeader dataId-${resource.name.toLowerCase().replace(' ', '-')}-${index}`,
        equipment: resource.equipment,
        unavailableUntilFurtherNotice: resource.unavailableUntilFurtherNotice,
        unavailableUntilFurtherNoticeCategory: resource.unavailableUntilFurtherNoticeCategory,
        resourceType: resource.resourceType,
        restricted: resource.restricted,
        attributes: resource.attributes,
    }));

export const filterResources = (resources: Resource[], advancedFilters: any): Resource[] => {
    const { disabledAccess, equipment } = advancedFilters;
    const activeEquipmentsFilter = getActiveMultipleFilters(equipment);

    const filteredResourcesByDisabledAccess = disabledAccess
        ? resources.filter((resource: Resource) => resource.disabledAccess)
        : resources;

    const filteredByEquipment = !isEmpty(activeEquipmentsFilter)
        ? filteredResourcesByDisabledAccess.reduce(
              (acc: any, resource: Resource) =>
                  activeEquipmentsFilter.every((item: string) => resource.equipment.find((equip) => equip.id === item))
                      ? [...acc, resource]
                      : acc,
              []
          )
        : filteredResourcesByDisabledAccess;

    return filteredByEquipment;
};

const mapResourcesByType = (
    resources: Resource[],
    types: ResourceTypeInterface[],
    advancedFilters: any,
    geographicStructuresId: string
): any => {
    return types.reduce((acc: any, { value, name }, index: number) => {
        const filterByType = resources.filter(({ resourceType }) => resourceType === value);
        const mappedResource = mapResourceDetails(filterResources(filterByType, advancedFilters));
        return isEmpty(mappedResource)
            ? acc
            : [
                  ...acc,
                  {
                      id: `${value}-${geographicStructuresId}-subHeaderCell`,
                      name: `${name} (${mappedResource.length})`,
                      expanded: true,
                      children: mappedResource,
                      ariaLabel: name,
                      cssClass: `dataId-${name}-${index} areaSubHeader `,
                      eventHeight: SUB_HEADER_ROW_HEIGHT,
                  },
              ];
    }, []);
};

const mapResourcesForGeographicStructures = (
    resources: ResourcesGS[],
    advancedFilters: any,
    areas: GeographicStructureItem[],
    types: ResourceTypeInterface[],
    expandedResources: ExpandedResources[]
): BookingGridResource[] =>
    resources.reduce((acc: BookingGridResource[], resource: ResourcesGS, index: number) => {
        const geoStructureName = areas.find(({ id }) => id === resource.geographicStructureId)?.name;
        const sortOrder = areas.find(({ id }) => id === resource.geographicStructureId)?.displayOrder;
        const isExpandedGeographicStructures = expandedResources.find(
            ({ id }: any) => id === resource.geographicStructureId
        )?.expanded;

        const mappedByType = mapResourcesByType(
            resource.resources,
            types,
            advancedFilters,
            resource.geographicStructureId.toString()
        ).map((el: any) => {
            const isExpandedResource = expandedResources?.find((item: ExpandedResources) => item.id === el.id);
            if (isExpandedResource?.id === el.id) {
                return { ...el, expanded: isExpandedResource?.expanded };
            }

            return el;
        });
        return isEmpty(mappedByType)
            ? acc
            : [
                  ...acc,
                  {
                      id: resource.geographicStructureId.toString(),
                      name: geoStructureName,
                      expanded: isExpandedGeographicStructures ?? true,
                      children: mappedByType,
                      ariaLabel: geoStructureName,
                      cssClass: `dataId-${geoStructureName?.toLowerCase().replace(' ', '-')}-${index} areaHeader `,
                      sortOrder,
                  },
              ];
    }, []);

export default mapResourcesForGeographicStructures;
