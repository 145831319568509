import { EXTENDED_TWELWE_HOUR_FORMAT, TWELWE_HOUR_FORMAT, TWENTY_FOUR_HOUR_FORMAT } from 'features/constants';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { UserProfile } from '../types';

import { setUser } from './actions';

interface State {
    user: UserProfile;
}

export default combineReducers<State>({
    user: createReducer<UserProfile | null>(null).handleAction([setUser], (state, action) => {
        return {
            ...action.payload,
            hourFormat: action.payload?.is_24h_timeFormat ? TWENTY_FOUR_HOUR_FORMAT : TWELWE_HOUR_FORMAT,
            extendedHourFormat: action.payload?.is_24h_timeFormat
                ? TWENTY_FOUR_HOUR_FORMAT
                : EXTENDED_TWELWE_HOUR_FORMAT,
        };
    }),
});
