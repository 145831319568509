import { createAction, createAsyncAction } from 'typesafe-actions';

import { ApiResponse } from '../../../services/ApiClients/@Models/ApiResponse';
import { TenantInfo } from '../../../services/ApiClients/Organization/Models/TenantInfo';

export const loadReferenceDataBegin = createAction('LOAD_REFERENCE_DATA_BEGIN')<void>();
export const loadReferenceDataEnd = createAction('LOAD_REFERENCE_DATA_END')<void>();
export const loadReferenceDataFail = createAction('LOAD_REFERENCE_DATA_FAIL')<void>();
export const setReferenceDataLoadStatus = createAction('SET_REFERENCE_DATA_LOAD_STATUS')<string>();
export const setReferenceDataAreaLoadStatus = createAction('SET_REFERENCE_DATA_AREA_LOAD_STATUS')<string>();

export const getTenantInfo = createAsyncAction(
    'GET_TENANT_INFO_REQUEST',
    'GET_TENANT_INFO_SUCCESS',
    'GET_TENANT_INFO_FAILURE'
)<string, ApiResponse<TenantInfo>, null>();

export default {
    loadReferenceDataBegin,
    loadReferenceDataEnd,
    loadReferenceDataFail,
    setReferenceDataLoadStatus,
    setReferenceDataAreaLoadStatus,
    getTenantInfo,
};
