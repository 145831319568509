import React from 'react';
import classNames from 'classnames';

import './BackgroundMask.scss';

export interface BackgroundMaskProps {
    className?: string;
    onClick?: () => void;
    open?: boolean;
}

const BackgroundMask = ({ className, onClick, open }: BackgroundMaskProps): JSX.Element => {
    return <div className={classNames('backgroundMask', className, { open })} onClick={onClick} role="none" />;
};

export default BackgroundMask;
