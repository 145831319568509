import { connect } from 'react-redux';
import { RootState } from 'PortalTypes';

import {
    generateReport,
    generateReportForExport,
    getUserReportFilterOptions,
    reportsGetDepartments,
} from '../Redux/actions';

// eslint-disable-next-line import/no-cycle,
import UserReport from './UserReport';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState) => ({
    isReportGenerationInProgress: state.reports.isReportGenerationInProgress,
    reportData: state.reports.reportData?.users,
    reportDataForExport: state.reports.reportDataForExport?.users,
    userReportFilterOptions: state.reports.userReportFilterOptions,
    isGetFiltersInProgress: state.reports.isGetFiltersInProgress,
    is24HoursTimeFormat: state.authentication.user?.is_24h_timeFormat,
    isGenerationReportForExportInProgress: state.reports.isGenerationReportForExportInProgress,
    sites: state.referenceData.sites.items,
    departments: state.reports.departmentsResult?.departments,
    isGetDepartmentsInProgress: state.reports.isGetDepartmentsInProgress,
});

const mapDispatchToProps = {
    generateReport: generateReport.request,
    generateReportForExport: generateReportForExport.request,
    getUserReportFilterOptions: getUserReportFilterOptions.request,
    getDepartments: reportsGetDepartments.request,
};

export type UserReportProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(UserReport);
